import AlerteUI from '@components/AlerteUI/AlerteUI';
import ButtonBase from '@components/ButtonBase/ButtonBase';
import HeaderCollapsible, {
  HEADER_MAX_HEIGHT,
  HEADER_MIN_HEIGHT,
} from '@components/HeaderCollapsible/HeaderCollapsible';
import Loading from '@components/Loading/Loading';
import SelectEventType from '@components/SelectEventType/SelectEventType';
import SelectRecipientsType from '@components/SelectRecipientsType/SelectRecipientsType';
import Colors from '@configs/colors';
import { gMaxWidth } from '@configs/styles';
import AgendaCard from '@containers/AgendaStack/AgendaCard/AgendaCard';
import { useIsFocused } from '@react-navigation/native';
import * as actions from '@redux/actions';
import { deleteDiary, getClubComposition, handleBadgeNotification } from '@redux/actions';
import { SET_VISIBLE_FUTURE_DIARIES, SET_VISIBLE_PAST_DIARIES } from '@redux/reducers';
import alert from '@utils/alert';
import { checkIsAdmin, checkIsModeratorOrAdmin } from '@utils/checkUserRoles';
import I18n from '@utils/i18n';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dimensions, Image, Platform, Pressable, StyleSheet, Text, View } from 'react-native';
import { FloatingAction } from 'react-native-floating-action';
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import { useDispatch, useSelector } from 'react-redux';

const { width: sw } = Dimensions.get('window');

const TabAgenda = ({ navigation, route }) => {
  const debug = false;

  const dispatch = useDispatch();

  const isFocused = useIsFocused();

  const floatingAction = useRef(null);

  const [currentClub, setCurrentClub] = useState(null);

  const { tokens, constAgendaFilters } = useSelector((state) => state.app);
  const { session, affiliatedClubInformation, allAffiliatedClubs } = useSelector((state) => state.session);
  const { members, teams } = useSelector((state) => state.club);
  const { pastFilteredDiaries, futureFilteredDiaries } = useSelector((state) => state.agenda);

  const { loading, agenda } = useSelector((state) => state.agenda);
  const { futureLimit, futureHasNextPage } = useSelector((state) => state.agenda);
  const { pastLimit, pastHasNextPage } = useSelector((state) => state.agenda);

  const [pastAgenda, setPastAgenda] = useState(undefined);
  const [futureAgenda, setFutureAgenda] = useState(undefined);

  const [hasAccess, setHasAccess] = useState(false);
  const [hasAdmin, setHasAdmin] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertButtons, setAlertButtons] = useState(null);

  const [refreshPage, setRefreshPage] = useState(false);
  const [refreshDisplay, setRefreshDisplay] = useState(false);

  // const [futureDiaries, setFutureDiaries] = useState([]);
  const [futurePageNumber, setFuturePageNumber] = useState(1);

  // const [pastDiaries, setPastDiaries] = useState([]);
  const [pastPageNumber, setPastPageNumber] = useState(1);

  const [leftPage, setLeftPage] = useState(true);
  const [currentTab, setCurrentTab] = useState(null);

  const [showEventsFilter, setShowEventsFilter] = useState(false);
  const [mySelectedEvents, setMySelectedEvents] = useState(constAgendaFilters);
  const [allSelectedEvents, setAllSelectedEvents] = useState(true);
  const [showTeamsFilter, setShowTeamsFilter] = useState(false);
  const [mySelectedTeams, setMySelectedTeams] = useState(teams);
  const [allSelectedTeams, setAllSelectedTeams] = useState(true);

  const scrollY = useSharedValue(0);
  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (event) => {
      scrollY.value = event.contentOffset.y;
    },
  });
  const listStyle = useAnimatedStyle(() => {
    return {
      marginTop: interpolate(
        scrollY.value,
        [0, HEADER_MAX_HEIGHT],
        [HEADER_MAX_HEIGHT, HEADER_MIN_HEIGHT],
        Extrapolation.CLAMP
      ),
    };
  });

  useEffect(() => {
    // Club data are not yet loaded
    if (mySelectedTeams === undefined) {
      debug && console.debug('Agenda getting club composition...');
      dispatch(getClubComposition(tokens.wsToken, session.club.id));
    }
  }, []);

  useEffect(() => {
    debug && console.debug('Agenda club members:', members);
    if (!members || !teams) return;

    setMySelectedTeams(teams);
  }, [members]);

  useEffect(() => {
    debug && console.debug('Agenda got focus:', isFocused, route);
    if (!isFocused || !currentClub) return;

    handleAffiliation();
  }, [isFocused]);

  useEffect(() => {
    debug && console.debug('TabAgenda update current club', affiliatedClubInformation);

    setCurrentClub(affiliatedClubInformation ?? session?.club ?? null);
  }, [session, affiliatedClubInformation]);

  useEffect(() => {
    if (!currentClub) return;
    debug && console.debug('TabAgenda updated currentClub');

    if (currentClub.isAffiliated !== '1') {
      navigation.navigate('NotAffiliated');
    } else {
      setHasAccess(checkIsModeratorOrAdmin(currentClub));
      setHasAdmin(checkIsAdmin(currentClub));

      isFocused && handleAffiliation();
    }
  }, [currentClub]);

  useEffect(() => {
    debug && console.debug('TabAgenda route parameters:', route.params);
    if (!route?.params?.tab) {
      console.debug('TabAgenda no route parameters!');
      return;
    }

    if (route.params.tab === 'future') {
      debug && console.debug('TabAgenda route parameters, update an existing conversation');
      setCurrentTab(route.params.tab);
      setLeftPage(true);
    }

    if (route.params.tab === 'past') {
      debug && console.debug('TabAgenda route parameters, create a new conversation');
      setCurrentTab(route.params.tab);
      setLeftPage(false);
    }
  }, [route && route.params]);

  useEffect(() => {
    if (!mySelectedEvents) return;
    const selectedEventIds = mySelectedEvents.map((event) => event.id);
    setAllSelectedEvents(selectedEventIds.length >= constAgendaFilters.length);
  }, [mySelectedEvents]);

  useEffect(() => {
    if (!mySelectedTeams) return;
    const selectedTeamIds = mySelectedTeams.map((team) => team.id);
    setAllSelectedTeams(selectedTeamIds.length >= teams.length);
  }, [mySelectedTeams]);

  useEffect(() => {
    if (!pastAgenda) return;
    debug && console.debug('TabAgenda updated pastAgenda');

    dispatch({ type: SET_VISIBLE_PAST_DIARIES, diaries: pastAgenda });
  }, [pastAgenda]);

  useEffect(() => {
    if (!futureAgenda) return;
    debug && console.debug('TabAgenda updated futureAgenda');

    dispatch({ type: SET_VISIBLE_FUTURE_DIARIES, diaries: futureAgenda });
  }, [futureAgenda]);

  useEffect(() => {
    if (!agenda || !mySelectedEvents || !mySelectedTeams) return;

    const selectedEventIds = mySelectedEvents.map((event) => event.id);
    const selectedAllEvents = selectedEventIds.length >= constAgendaFilters.length;
    const selectedTeamIds = mySelectedTeams.map((team) => team.id);
    const selectedAllTeams = selectedTeamIds.length >= teams.length;

    debug &&
      console.debug(`TabAgenda, filters:`, selectedAllEvents, selectedEventIds, selectedAllTeams, selectedTeamIds);

    setPastAgenda(
      agenda
        .filter((diary) => {
          if (!diary || diary.isFuture) {
            return null;
          }

          if (!selectedAllEvents) {
            if (!selectedEventIds.includes(diary.DiaryType_id)) {
              return null;
            }
          }

          if (!selectedAllTeams) {
            if (!selectedTeamIds.includes(diary.homeTeamId) && !selectedTeamIds.includes(diary.awayTeamId)) {
              return null;
            }
          }

          return diary;
        })
        .reverse()
    );

    setFutureAgenda(
      agenda.filter((diary) => {
        if (!diary || !diary.isFuture) {
          return null;
        }

        if (!selectedAllEvents) {
          if (!selectedEventIds.includes(diary.DiaryType_id)) {
            return null;
          }
        }

        if (!selectedAllTeams) {
          if (!selectedTeamIds.includes(diary.homeTeamId) && !selectedTeamIds.includes(diary.awayTeamId)) {
            return null;
          }
        }

        return diary;
      })
    );

    debug && console.debug(`TabAgenda, updated past and future agenda.`);
  }, [agenda, mySelectedEvents, mySelectedTeams, refreshDisplay]);

  const handleAffiliation = () => {
    debug && console.debug('TabAgenda, handleAffiliation');

    dispatch(handleBadgeNotification('agenda', false));

    refreshAgenda(true);
    refreshAgenda(false);
  };

  const refreshAgenda = (isFuture = true) => {
    debug && console.debug('TabAgenda, refreshAgenda:', isFuture);
    // if (loading) {
    //   console.debug('TabAgenda, refreshAgenda, loading in progress, come back later!');
    //   return;
    // }

    let pageLength = 0;
    if (isFuture && futureAgenda) {
      pageLength = Math.max(futureAgenda.length, futureLimit);
    } else if (!isFuture && pastAgenda) {
      pageLength = Math.max(pastAgenda.length, pastLimit);
    }
    if (pageLength <= 0) {
      // If we never got anything... refresh is probably a very first call!
      pageLength = isFuture ? futureLimit : pastLimit;
    }

    setRefreshPage(true);

    dispatch(
      actions.getAgenda(tokens.wsToken, currentClub.id, 1, pageLength, isFuture, [], [], (success, result) => {
        setRefreshPage(false);
        if (success) {
          if (isFuture) {
            setFuturePageNumber(1);
          } else {
            setPastPageNumber(1);
          }

          filterAgenda(isFuture);
        }
      })
    );
  };

  const getAgenda = (isFuture = true, pageNumber = 1, limit = 10) => {
    debug && console.debug('TabAgenda, getAgenda:', isFuture);

    setRefreshPage(true);

    dispatch(
      actions.getAgenda(tokens.wsToken, currentClub.id, pageNumber, limit, isFuture, [], [], (success, result) => {
        setRefreshPage(false);
        if (success) {
          if (isFuture) {
            setFuturePageNumber(pageNumber);
          } else {
            setPastPageNumber(pageNumber);
          }

          filterAgenda(isFuture);
        }
      })
    );
  };

  /**
   * function to get next page for future/past agenda
   */
  const loadNextPage = (isFuture) => {
    if (loading) {
      console.debug('TabAgenda loadNextPage, loading in progress, come back later!');
      return;
    }

    const hasNextPage = isFuture ? futureHasNextPage : pastHasNextPage;
    debug && console.debug(`TabAgenda loadNextPage, future: ${isFuture}, hasNextPage: ${hasNextPage}`);
    if (!hasNextPage) {
      return;
    }

    getAgenda(isFuture, isFuture ? futurePageNumber + 1 : pastPageNumber + 1, isFuture ? futureLimit : pastLimit);
  };

  const filterAgenda = (isFuture) => {
    if (!agenda || !mySelectedEvents || !mySelectedTeams) return;

    const selectedEventIds = mySelectedEvents.map((team) => team.id);
    const selectedAllEvents = selectedEventIds.length >= constAgendaFilters.length;
    const selectedTeamIds = mySelectedTeams.map((team) => team.id);
    const selectedAllTeams = selectedTeamIds.length >= teams.length;

    debug &&
      console.debug(
        `TabAgenda, future: ${isFuture}, filter:`,
        selectedAllEvents,
        selectedEventIds,
        selectedAllTeams,
        selectedTeamIds
      );
  };

  /**
   * Function to select teams for filtering
   * @param {*} choice
   */
  const selectTeamFilter = (choice) => {
    debug && console.debug('TabAgenda, selectTeamFilter:', choice);

    const selectedTeamIds = mySelectedTeams.map((item) => item.id);
    const selectedAllTeams = selectedTeamIds.length >= teams.length;

    let selectedTeams = [...mySelectedTeams];
    if (choice.id === 0) {
      selectedTeams = selectedAllTeams ? [] : [...teams];
    } else {
      const foundIndex = selectedTeams.findIndex((item) => item.id === choice.id);
      if (foundIndex >= 0) {
        selectedTeams.splice(foundIndex, 1);
      } else {
        selectedTeams.push(choice);
      }
    }

    debug && console.debug('TabAgenda, selectTeamFilter, selected:', selectedTeams);
    setMySelectedTeams(selectedTeams);
  };

  /**
   * Function to select events for filtering
   * @param {*} choice
   */
  const selectEventFilter = (choice) => {
    debug && console.debug('TabAgenda, selectEventFilter:', choice);

    const selectedEventIds = mySelectedEvents.map((item) => item.id);
    const selectedAllEvents = selectedEventIds.length >= constAgendaFilters.length;

    let selectedEvents = [...mySelectedEvents];
    if (choice.id === 0) {
      selectedEvents = selectedAllEvents ? [] : [...constAgendaFilters];
    } else {
      const foundIndex = selectedEvents.findIndex((item) => item.id === choice.id);
      if (foundIndex >= 0) {
        selectedEvents.splice(foundIndex, 1);
      } else {
        selectedEvents.push(choice);
      }
    }

    debug && console.debug('TabAgenda, selectEventFilter, selected:', selectedEvents);
    setMySelectedEvents(selectedEvents);
  };

  const ActionButton = () => {
    if (!hasAccess) {
      return null;
    }

    return (
      <FloatingAction
        ref={floatingAction}
        floatingIcon={
          <Image
            source={require('@assets/icons/fabMenu/menu.png')}
            style={{
              tintColor: currentClub?.inactiveIconColor ?? Colors.whiteCorporate,
              width: 20,
              height: 20,
              resizeMode: 'contain',
            }}
          />
        }
        color={currentClub?.mainColorBottom ?? Colors.blueCorporate}
        distanceToEdge={{ vertical: 70, horizontal: 30 }}
        visible
        position="right"
        showBackground={false}
        onOpen={() => {
          floatingAction.current.reset();
          // No params to use the default 'add' mode
          navigation.navigate('CreateEvent', { mode: 'add' });
        }}
      />
    );
  };

  const alertDeleteDiary = (diaryId) => {
    if (!hasAdmin) {
      return null;
    }

    alert(I18n.t('app.confirm'), I18n.t('app.confirmDeleteAgenda'), [
      {
        text: 'Annuler',
        onPress: () => null,
      },
      {
        text: 'Ok',
        onPress: () => {
          dispatch(
            deleteDiary(tokens.wsToken, diaryId, (success, result) => {
              if (!success) {
                alert(I18n.t('app.oups'), "Une erreur est survenue lors de la suppression de l'événement");
              } else {
                setRefreshDisplay(true);
              }
            })
          );
        },
      },
    ]);
  };

  const renderItem = useCallback(({ item, index }) => {
    return (
      <AgendaCard
        index={index}
        diary={item}
        reloadAgenda={(isFuture) => refreshAgenda(leftPage)}
        onDeleteDiary={(id) => alertDeleteDiary(id)}
      />
    );
  }, []);

  if (!currentClub) {
    return;
  }

  return (
    <View style={[{ flex: 1, marginBottom: 60 }, Platform.OS === 'web' ? { width: '100%', maxWidth: gMaxWidth } : {}]}>
      <HeaderCollapsible
        navigation={navigation}
        route={route}
        headerTitle={session && (currentClub.name ?? 'Guest')}
        contentScrolled={scrollY}
        displayExchangeIcon={allAffiliatedClubs.length > 1}
        onExchange={(clubId) => handleAffiliation(clubId)}
      />

      {currentClub.isAffiliated === '1' && (
        <>
          <Animated.View style={[styles.swiperButtons, listStyle]}>
            <View style={[styles.swipeBar, { backgroundColor: currentClub.mainColorBottom ?? Colors.blueCorporate }]}>
              <Pressable
                style={({ pressed }) => [
                  styles.swipeBarButton,
                  { backgroundColor: leftPage ? Colors.grayPage : null },
                  pressed ? { opacity: 0.7 } : {},
                ]}
                onPress={() => {
                  setLeftPage(true);
                  // pagerRef.current.goToPrev();
                  navigation.navigate('AgendaStack', { screen: 'Agenda', params: { tab: 'future' } });
                }}>
                <Text
                  style={[
                    leftPage ? styles.topMenuSelected : styles.topMenuNotSelected,
                    leftPage ? { color: Colors.blueCorporate ?? Colors.whiteCorporate } : null,
                  ]}>
                  {I18n.t('agenda.future')}
                </Text>
              </Pressable>

              <Pressable
                style={({ pressed }) => [
                  styles.swipeBarButton,
                  { backgroundColor: !leftPage ? Colors.grayPage : null },
                  pressed ? { opacity: 0.7 } : {},
                ]}
                onPress={() => {
                  setLeftPage(false);
                  // pagerRef.current.goToNext();
                  navigation.navigate('AgendaStack', { screen: 'Agenda', params: { tab: 'past' } });
                }}>
                <Text
                  style={[
                    !leftPage ? styles.topMenuSelected : styles.topMenuNotSelected,
                    !leftPage ? { color: Colors.blueCorporate ?? Colors.whiteCorporate } : null,
                  ]}>
                  {I18n.t('agenda.all')}
                </Text>
              </Pressable>
            </View>

            <View style={styles.filterBar}>
              <ButtonBase
                style={styles.filterBarButton}
                label={I18n.t('agenda.filterEvents') + (allSelectedEvents ? ' ✔' : '')}
                onPress={() => {
                  setShowEventsFilter(true);
                }}
              />
              <ButtonBase
                style={styles.filterBarButton}
                label={I18n.t('agenda.filterTeams') + (allSelectedTeams ? ' ✔' : '')}
                onPress={() => {
                  setShowTeamsFilter(true);
                }}
              />
            </View>
          </Animated.View>

          <Animated.FlatList
            estimatedItemSize={320}
            initialNumToRender={5}
            maxToRenderPerBatch={3}
            windowSize={10}
            removeClippedSubviews={false}
            data={leftPage ? futureFilteredDiaries : pastFilteredDiaries}
            keyExtractor={(item, index) => `Diary-${leftPage}-${item.uniqueId}`}
            ListEmptyComponent={
              <Text style={{ alignSelf: 'center', color: Colors.blueCorporate }}>
                {leftPage ? I18n.t('agenda.noFutureEvents') : I18n.t('agenda.noPastEvents')}
              </Text>
            }
            renderItem={renderItem}
            scrollEnabled
            scrollEventThrottle={1}
            showsVerticalScrollIndicator
            onScroll={scrollHandler}
            refreshing={refreshPage}
            onRefresh={() => {
              refreshAgenda(true);
            }}
            onEndReachedThreshold={0.5}
            onEndReached={({ distanceFromEnd }) => {
              if (distanceFromEnd < 0) return;
              loadNextPage(leftPage);
            }}
            onLoad={(info) => {
              console.log('AgendaDiaries onLoad', info);
            }}
            onBlankArea={(event) => {
              console.log('AgendaDiaries onBlank', event);
            }}
          />

          <SelectEventType
            visible={showEventsFilter}
            events={constAgendaFilters}
            selectable
            allSelectable
            multiSelection
            selected={mySelectedEvents}
            onSelected={(choice) => selectEventFilter(choice)}
            onClose={() => {
              setShowEventsFilter(false);
            }}
          />

          <SelectRecipientsType
            visible={showTeamsFilter}
            recipients={teams}
            selectable
            allSelectable
            multiSelection
            selected={mySelectedTeams}
            onSelected={(choice) => selectTeamFilter(choice)}
            onClose={() => {
              setShowTeamsFilter(false);
            }}
          />

          <Loading loading={loading} />

          <AlerteUI
            showAlert={showAlert}
            onClose={() => {
              setShowAlert(false);
            }}
            title={alertTitle}
            message={alertMessage}
            buttons={alertButtons}
          />

          <ActionButton />
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    alignItems: 'center',
  },
  appFontRegular: {
    fontFamily: 'appFontRegular',
  },
  whiteText: {
    color: Colors.whiteCorporate,
  },
  topMenuNotSelected: {
    color: Colors.whiteCorporate,
    fontFamily: 'appFontRegular',
  },
  topMenuSelected: {
    color: Colors.whiteCorporate,
    fontFamily: 'appFontMedium',
    fontWeight: 'bold',
  },
  redText: {
    color: Colors.redCorporate,
  },
  buttonCenterLabel: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  shadowEffect: {
    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },

  // --- Sub-header swipe
  swiperButtons: {
    width: Platform.OS === 'web' ? gMaxWidth : sw,
    // width: '100%',
    height: 100,
    alignItems: 'center',
    marginBottom: 20,
  },

  swipeBar: {
    width: Platform.OS === 'web' ? gMaxWidth : sw,
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  swipeBarButton: {
    width: '50%',
    height: 40,
    marginTop: 5,
    alignItems: 'center',
    paddingTop: 10,
    borderTopStartRadius: 16,
    borderTopEndRadius: 16,
  },
  filterBarButton: {
    width: '40%',
    height: 40,
    marginTop: 5,
    alignItems: 'center',
    paddingTop: 10,
    borderTopStartRadius: 16,
    borderTopEndRadius: 16,
  },

  filterBar: {
    width: Platform.OS === 'web' ? gMaxWidth : sw,
    height: 50,
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  filterButton: {
    height: 32,
    padding: 5,
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 16,
    width: 100,
    elevation: 2,

    flex: 0.5,
    alignItems: 'center',
    paddingVertical: 10,
  },
});

export default TabAgenda;
