export const RAZ_CLUB = 'RAZ_CLUB';
export const SET_CLUB_ERROR = 'SET_CLUB_ERROR';
export const SET_CLUB_LOADING = 'SET_CLUB_LOADING';
export const SET_CLUB_NOT_LOADING = 'SET_CLUB_NOT_LOADING';

export const SET_ALL_CLUBS = 'SET_ALL_CLUBS';
export const SET_CLUB_COMPOSITION = 'SET_CLUB_COMPOSITION';
export const SET_CLUB_MEMBERS = 'SET_CLUB_MEMBERS';
export const SET_CLUB_UNIQUE_MEMBERS = 'SET_CLUB_UNIQUE_MEMBERS';
export const SET_CLUB_PARTNERS = 'SET_CLUB_PARTNERS';

export const SET_CLUB_TEAM_PLAYERS = 'SET_CLUB_TEAM_PLAYERS';

export const CHECK_CLUB_MEMBER = 'CHECK_CLUB_MEMBER';
export const CHECK_CLUB_PARTNER = 'CHECK_CLUB_PARTNER';
export const UNCHECK_CLUB_MEMBERS = 'UNCHECK_CLUB_MEMBERS';
export const UNCHECK_CLUB_PARTNERS = 'UNCHECK_CLUB_PARTNERS';
export const UPDATE_TEAM = 'UPDATE_TEAM';

const initialState = {
  loading: false,
  errorCode: '',

  allClubs: [],

  id: null,
  composition: null,
  members: {},
  uniqueMembers: [],
  partners: [],
};

const debug = false;

const clubReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case 'RESET_FOR_LOGOUT':
      debug && console.debug('ClubReducer RESET_FOR_LOGOUT');
      return initialState;

    case RAZ_CLUB:
      return {
        ...initialState,
      };

    case SET_CLUB_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SET_CLUB_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };

    case SET_CLUB_ERROR:
      return {
        ...state,
        errorCode: payload.errorCode,
      };

    case SET_ALL_CLUBS: {
      return {
        ...state,
        allClubs: payload.list,
      };
    }

    case SET_CLUB_TEAM_PLAYERS:
      return {
        ...state,
        teams: state.teams.map((team) => {
          if (team.id !== payload.id) return team;
          return { ...team, players: payload.team };
        }),
      };

    case UPDATE_TEAM:
      return {
        ...state,
        teams: state.teams.map((team) => {
          if (team.id !== payload.id) return team;
          return { ...team,
            name: payload.team.name ?? team.name,
            photo: payload.team.photo ?? team.photo,
          };
        }),
      };

    case SET_CLUB_COMPOSITION:
      payload.data?.teams.map((team) => {
        if (!team.players && team.users) {
          team.players = [...team.users];
        }
      });

      return {
        ...state,
        id: payload.data.id,
        composition: payload.data,
      };

    case SET_CLUB_MEMBERS:
      return {
        ...state,
        members: payload.data.members,
        teams: payload.data.teams,
      };

    case SET_CLUB_UNIQUE_MEMBERS:
      return {
        ...state,
        uniqueMembers: payload.data,
      };

    case SET_CLUB_PARTNERS:
      return {
        ...state,
        partners: payload.data,
      };

    case UNCHECK_CLUB_PARTNERS: {
      debug && console.debug('clubReducer UNCHECK_CLUB_PARTNERS');

      return {
        ...state,
        partners: state.partners.map((partner) => {
          return { ...partner, selected: false };
        }),
      };
    }

    case CHECK_CLUB_PARTNER: {
      debug && console.debug('clubReducer CHECK_CLUB_PARTNER, member:', payload.partnerId, payload.checkValue);

      return {
        ...state,
        partners: state.partners.map((partner) => {
          if (payload.partnerId !== partner.id) return partner;
          return { ...partner, selected: payload.checkValue };
        }),
      };
    }

    case UNCHECK_CLUB_MEMBERS: {
      debug && console.debug('clubReducer UNCHECK_CLUB_MEMBERS');

      return {
        ...state,
        members: {
          players: state.members.players.map((member) => {
            return { ...member, selected: false };
          }),
          coaches: state.members.coaches.map((member) => {
            return { ...member, selected: false };
          }),
          referees: state.members.referees.map((member) => {
            return { ...member, selected: false };
          }),
          staff: state.members.staff.map((member) => {
            return { ...member, selected: false };
          }),
          board: state.members.board.map((member) => {
            return { ...member, selected: false };
          }),
          parents: state.members.parents.map((member) => {
            return { ...member, selected: false };
          }),
          volunteers: state.members.volunteers.map((member) => {
            return { ...member, selected: false };
          }),
          others: state.members.others.map((member) => {
            return { ...member, selected: false };
          }),
          teams: state.teams.map((team) => {
            return {
              ...team,
              players: team.players.map((member) => {
                return { ...member, selected: false };
              }),
              coaches: team.coaches.map((member) => {
                return { ...member, selected: false };
              }),
              parents: team.parents.map((member) => {
                return { ...member, selected: false };
              }),
              managers: team.managers.map((member) => {
                return { ...member, selected: false };
              }),
            };
          }),
        },
      };
    }

    case CHECK_CLUB_MEMBER: {
      debug && console.debug('clubReducer CHECK_CLUB_MEMBER, member:', payload.userId, payload.checkValue);

      return {
        ...state,
        members: {
          players: state.members.players.map((member) => {
            if (payload.userId !== member.id) return member;
            return { ...member, selected: payload.checkValue };
          }),
          coaches: state.members.coaches.map((member) => {
            if (payload.userId !== member.id) return member;
            return { ...member, selected: payload.checkValue };
          }),
          referees: state.members.referees.map((member) => {
            if (payload.userId !== member.id) return member;
            return { ...member, selected: payload.checkValue };
          }),
          staff: state.members.staff.map((member) => {
            if (payload.userId !== member.id) return member;
            return { ...member, selected: payload.checkValue };
          }),
          board: state.members.board.map((member) => {
            if (payload.userId !== member.id) return member;
            return { ...member, selected: payload.checkValue };
          }),
          parents: state.members.parents.map((member) => {
            if (payload.userId !== member.id) return member;
            return { ...member, selected: payload.checkValue };
          }),
          volunteers: state.members.volunteers.map((member) => {
            if (payload.userId !== member.id) return member;
            return { ...member, selected: payload.checkValue };
          }),
          others: state.members.others.map((member) => {
            return { ...member, selected: payload.checkValue };
          }),
          teams: state.teams.map((team) => {
            return {
              ...team,
              players: team.players.map((member) => {
                if (payload.userId !== member.id) return member;
                return { ...member, selected: payload.checkValue };
              }),
              coaches: team.coaches.map((member) => {
                if (payload.userId !== member.id) return member;
                return { ...member, selected: payload.checkValue };
              }),
              parents: team.parents.map((member) => {
                if (payload.userId !== member.id) return member;
                return { ...member, selected: payload.checkValue };
              }),
              managers: team.managers.map((member) => {
                if (payload.userId !== member.id) return member;
                return { ...member, selected: payload.checkValue };
              }),
            };
          }),
        },
      };
    }

    default:
      return state;
  }
};

export default clubReducer;
