import CA1 from '@containers/CreateAccount/CA1';
import CA2 from '@containers/CreateAccount/CA2';
import ClubFilters from '@containers/HomeStack/ClubFilters/ClubFilters';
import Login from '@containers/Login/Login';
import ChooseProfile from '@containers/ProfileStack/ChooseProfile';
import LostPassword from '@containers/ProfileStack/LostPassword';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Stack = createNativeStackNavigator();

const SignedOutNavigator = ({ navigation, route }) => {
  const debug = false;

  const { signedIn, userStatus } = useSelector((state) => state.app);

  useEffect(() => {
    debug && console.debug(`SignedOutNavigator, route '${route.name}', user signed-in: ${signedIn}`);
    // if (signedIn) {
    //   // Let the LocalCredentials do the job...
    //   // // Navigate to the signed-in stack!
    //   // navigation.navigate('SignedIn');
    //   dispatch({ type: SET_USER_STATUS, value: 're-signing-in' });
    // }
  }, []);

  useEffect(() => {
    debug &&
      console.debug(
        `SignedOutNavigator, route '${route.name}', user signed-in: ${signedIn}, user status: ${userStatus}`
      );
    if (userStatus === 'signed-in') {
      navigation.navigate('SignedIn', { screen: 'HomeScreen' });
    }

    if (userStatus === 'signing-in') {
      navigation.navigate('SignIn');
    }
  }, [userStatus]);

  return (
    <Stack.Navigator id="signedOutNavigator" initialRouteName="SignIn" screenOptions={{ headerShown: false }}>
      <Stack.Group>
        {/*<Stack.Screen name="LocalCredentials" component={LocalCredentials} />*/}
        <Stack.Screen name="SignIn" component={Login} />
        <Stack.Screen name="SignUp" component={CA1} />
        <Stack.Screen name="SignUpNext" component={CA2} />
        <Stack.Screen name="ChooseProfile" component={ChooseProfile} />
        <Stack.Screen name="SignUpClub" component={ClubFilters} initialParams={{ chooseClubOnSelect: true }} />
        <Stack.Screen name="LostPassword" component={LostPassword} />
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default SignedOutNavigator;
