// Legacy behaviour
// - recurring events are expanded by the backend!
// const PAST_DIARIES = 0;
// const FUTURE_DIARIES = 1;

// New behaviour
// - recurring events are not expanded by the backend!
// const PAST_DIARIES = -1;
// const FUTURE_DIARIES = 2;

export const PAST_DIARIES = -1;
export const FUTURE_DIARIES = 2;

export const RAZ_AGENDA = 'RAZ_AGENDA';
export const SET_AGENDA_ERROR = 'SET_AGENDA_ERROR';
export const SET_AGENDA_LOADING = 'SET_AGENDA_LOADING';
export const SET_AGENDA_NOT_LOADING = 'SET_AGENDA_NOT_LOADING';
export const SET_AGENDA = 'SET_AGENDA';
export const SORT_AGENDA = 'SORT_AGENDA';
export const SET_NEW_DIARY = 'SET_NEW_DIARY';
export const UPDATE_DIARY = 'UPDATE_DIARY';
export const DELETE_DIARY = 'DELETE_DIARY';

// export const SET_VISIBLE_DIARIES = 'SET_VISIBLE_DIARIES';
export const SET_VISIBLE_FUTURE_DIARIES = 'SET_VISIBLE_FUTURE_DIARIES';
export const SET_VISIBLE_PAST_DIARIES = 'SET_VISIBLE_PAST_DIARIES';

const initialState = {
  loading: false,
  errorCode: '',

  agenda: [],
  futureFilteredDiaries: [],
  pastFilteredDiaries: [],

  futureLimit: 10,
  futureHasNextPage: false,
  pastLimit: 10,
  pastHasNextPage: false,
};

const debug = false;

const agendaReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case 'RESET_FOR_LOGOUT':
      debug && console.debug('AgendaReducer RESET_FOR_LOGOUT');
      return initialState;

    case RAZ_AGENDA:
      return {
        ...initialState,
      };

    case SET_AGENDA_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SET_AGENDA_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };

    case SET_AGENDA_ERROR:
      return {
        ...state,
        errorCode: payload.errorCode,
      };

    case SET_AGENDA: {
      // Right difference
      const newDiaries = payload.data.filter(
        (newDiary) =>
          !state.agenda || !state.agenda.some((diary) => (diary ? diary.uniqueId === newDiary.uniqueId : false))
      );

      if (newDiaries.length) {
        debug && console.debug('AgendaReducer SET_AGENDA, add:', newDiaries);
        return {
          ...state,
          pastHasNextPage: payload.isFuture ? state.pastHasNextPage : payload.hasNextPage,
          futureHasNextPage: payload.isFuture ? payload.hasNextPage : state.futureHasNextPage,
          agenda: [...(state.agenda || []), ...newDiaries],
        };
      }
      debug && console.debug('AgendaReducer SET_AGENDA, no new item');
      return {
        ...state,
        pastHasNextPage: payload.isFuture ? state.pastHasNextPage : payload.hasNextPage,
        futureHasNextPage: payload.isFuture ? payload.hasNextPage : state.futureHasNextPage,
      };
    }

    case SORT_AGENDA:
      return {
        ...state,
        agenda: state.agenda.slice().sort((a, b) => {
          if (!a?.timestamp || !b?.timestamp) return 0;
          return a.timestamp - b.timestamp;
        }),
      };

    case SET_NEW_DIARY:
      debug && console.debug('AgendaReducer SET_NEW_DIARY:', payload.diary);

      return {
        ...state,
        agenda: [...state.agenda, payload.diary],
      };

    case UPDATE_DIARY:
      debug && console.debug('AgendaReducer UPDATE_DIARY:', payload.data);
      return {
        ...state,
        agenda: state.agenda.map((diary) => {
          if (diary.uniqueId !== payload.data.uniqueId) {
            return diary;
          }
          return payload.data;
        }),
      };

    case DELETE_DIARY: {
      debug && console.debug('AgendaReducer DELETE_DIARY, remove:', payload.diaryId);

      const diaryIndex = state.agenda.findIndex((diary) => diary.id === payload.diaryId);
      if (diaryIndex >= 0) {
        return {
          ...state,
          agenda: [...state.agenda.slice(0, diaryIndex), ...state.agenda.slice(diaryIndex + 1)],
        };
      }
      return state;
    }

    case SET_VISIBLE_FUTURE_DIARIES: {
      debug && console.debug('AgendaReducer SET_VISIBLE_FUTURE_DIARIES:', payload.diaries);
      return {
        ...state,
        futureFilteredDiaries: payload.diaries,
      };
    }
    case SET_VISIBLE_PAST_DIARIES: {
      debug && console.debug('AgendaReducer SET_VISIBLE_PAST_DIARIES:', payload.diaries);
      return {
        ...state,
        pastFilteredDiaries: payload.diaries,
      };
    }

    default:
      return state;
  }
};
export default agendaReducer;
