import { DocumentAPI } from '@apis/document';
import DocumentListCard from '@components/DocumentListCard/DocumentListCard';
import HeaderBanner from '@components/HeaderBanner/HeaderBanner';
import Colors from '@configs/colors';
import React, { Component } from 'react';
import { ActivityIndicator, BackHandler, Dimensions, FlatList, ScrollView, Text, View } from 'react-native';
import { connect } from 'react-redux';

import styles from './styles';
const sh = Dimensions.get('window').height;

class ClubDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentClub: this.props.foundOtherClub ?? this.props.affiliatedClubInformation ?? this.props.session.club ?? null,
      page: 1,
      limit: 100,
      documentData: [],
      noDocuments: true,
      displayActivityIndicator: false,
    };
  }

  componentDidMount() {
    this.getAllDocuments(this.state.page, this.state.limit);
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      this.props.navigation.pop();
      return true;
    });
    this.focusListener = this.props.navigation.addListener('focus', () => {
      this.handleAffiliation();
    });
  }

  componentWillUnmount() {
    this.backHandler.remove();
    this.focusListener();
  }

  /**
   * Function used to update the current club of this screen
   */
  handleAffiliation() {
    if (this.props.foundOtherClub) {
      this.setState(
        {
          currentClub: this.props.foundOtherClub,
          page: 1,
          limit: 100,
          documentData: [],
          noDocuments: true,
        },
        () => this.getAllDocuments(this.state.page, this.state.limit)
      );
    } else if (
      this.props.affiliatedClubInformation &&
      this.state.currentClub?.id !== this.props.affiliatedClubInformation.id
    ) {
      this.setState(
        {
          currentClub: this.props.affiliatedClubInformation
            ? this.props.affiliatedClubInformation
            : this.props.session.club,
          page: 1,
          limit: 100,
          documentData: [],
          noDocuments: true,
        },
        () => this.getAllDocuments(this.state.page, this.state.limit)
      );
    } else if (!this.props.foundOtherClub && !this.props.affiliatedClubInformation) {
      this.setState(
        {
          currentClub: this.props.session.club,
          page: 1,
          limit: 100,
          documentData: [],
          noDocuments: true,
        },
        () => this.getAllDocuments(this.state.page, this.state.limit)
      );
    }
  }

  getAllDocuments = async (page, limit) => {
    this.setState({
      displayActivityIndicator: true,
    });

    const getAllDocumentResponse = await DocumentAPI.getAllDocumentsByClubId(
      this.props.tokens.wsToken,
      page,
      limit,
      1,
      this.state.currentClub?.id
    );
    if (getAllDocumentResponse && getAllDocumentResponse.success) {
      // Filter has model only
      const documentMenuDocuments = [];
      getAllDocumentResponse.result.documents.map((doc, index) => {
        if (doc.model !== undefined && doc.model !== null) {
          documentMenuDocuments.push(doc);
        }
      });

      this.setState({ documentData: documentMenuDocuments });
    } else if (getAllDocumentResponse.error_code === 10) {
      console.warn('Session expired!');
    } else {
      // Handle error
    }
    this.setState({
      displayActivityIndicator: false,
      noDocuments: false,
    });
  };

  renderDataPage = () => {
    return (
      <>
        {this.state.noDocuments && (
          <View
            style={{
              height: sh - 160,
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <ActivityIndicator
              size="large"
              animating={this.state.displayActivityIndicator}
              style={{ position: 'absolute', top: '45%', left: '45%' }}
              color="white"
            />
          </View>
        )}

        {this.state.documentData.length === 0 && (
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Text
              style={{
                marginTop: 20,
                color: Colors.blueCorporate + 'CC',
                fontFamily: 'appFontRegular',
                fontSize: 16,
              }}>
              Il n'y a aucun document à consulter
            </Text>
          </View>
        )}

        {this.state.documentData.length > 0 && (
          <View style={styles.body}>
            <FlatList
              style={styles.flatListeDocuments}
              data={this.state.documentData}
              extraData={this.state.documentData}
              keyExtractor={(item) => item.id}
              renderItem={({ item }) => <DocumentListCard itemDocument={item} navigation={this.props.navigation} />}
              onEndReachedThreshold={0.5}
            />
          </View>
        )}
      </>
    );
  };

  render() {
    return (
      <View style={[styles.mainContainer]}>
        <HeaderBanner
          leftIcon="md-arrow-back"
          onLeftButton={() => this.props.navigation.goBack()}
          rightIcon="md-menu"
          onRightButton={async () => {
            this.props.navigation.goBack();
            this.props.navigation.openDrawer();
          }}
        />

        <View style={{ flex: 1, marginTop: 0 }}>
          <this.renderDataPage />
        </View>
      </View>
    );
  }
}

// export default CreateEvent
const mapStateToProps = (state) => {
  const { tokens } = state.app;
  const { session, affiliatedClubInformation, foundOtherClub } = state.session;
  return {
    tokens,
    session,
    affiliatedClubInformation,
    foundOtherClub,
  };
};

export default connect(mapStateToProps)(ClubDocuments);
