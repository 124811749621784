import { DIARY_MATCH } from '@apis/diaries';
import { REPLY_MAY_BE, REPLY_NO, REPLY_YES } from '@apis/message';
import Images from '@assets/images';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import BottomUpSheet from '@components/BottomUpSheet/BottomUpSheet';
import ButtonBase from '@components/ButtonBase/ButtonBase';
import CardReply from '@components/CardReply/CardReply';
import MapViewer from '@components/MapViewer/MapViewer';
import ShadowCard from '@components/ShadowCard/ShadowCard';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import I18n from '@utils/i18n';
import { openMap } from '@utils/openLinks';
import moment from 'moment/moment';
import React, { Component } from 'react';
import {
  Dimensions,
  Image,
  KeyboardAvoidingView,
  Platform,
  Pressable,
  ScrollView,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { Autolink } from 'react-native-autolink';
import Icon from 'react-native-vector-icons/Ionicons';

import styles from './styles';

const { width: sw, height: sh } = Dimensions.get('window');

class PopupAgenda extends Component {
  debug = false;

  popupRef = null;

  scrollToBottom = 0;

  clubLogo =
    this.props.diary.clubLogo !== ''
      ? { uri: getImageApiHostOrUrl(this.props.diary.clubLogo) }
      : require('@assets/logo.png');

  homeClubLogo = this.props.diary.homeClubLogo
    ? { uri: getImageApiHostOrUrl(this.props.diary.homeClubLogo) }
    : Images.imagePlaceholderRounded;
  awayClubLogo = this.props.diary.awayClubLogo
    ? { uri: getImageApiHostOrUrl(this.props.diary.awayClubLogo) }
    : Images.imagePlaceholderRounded;

  state = {
    loadingYes: false,
    loadingMayBe: false,
    loadingNo: false,

    showAlert: false,
    alertTitle: '',
    alertMessage: '',
    alertButtons: null,

    commentText: '',
    write: false,
    showActionReport: false,

    isMapOpened: false,

    currentSelectedComment: null,
  };

  async componentDidMount() {
    if (this.props.popupRef) {
      await this.props.popupRef(this);
    }

    if (this.props.action === 'openDetails') {
      this.open();
    }
  }

  open = (isFromComment = false) => {
    this.setState({ visible: true });

    setTimeout(() => {
      this.popupRef.current.open();
      this.props.onOpen();
    }, 500);
  };

  close = () => {
    this.popupRef.current.close();
    this.setState({ visible: false });
  };

  replyConvocation = (reply) => {
    reply === REPLY_YES && this.setState({ loadingYes: true, myReply: reply });
    reply === REPLY_MAY_BE && this.setState({ loadingMayBe: true, myReply: reply });
    reply === REPLY_NO && this.setState({ loadingNo: true, myReply: reply });

    this.props.onConvocationReply(reply, () => {
      this.setState({ loadingYes: false, loadingMayBe: false, loadingNo: false });
    });
  };

  renderClubTeams() {
    const homeTeamLogo = this.props.diary.homeClubLogo
      ? {
          uri: getImageApiHostOrUrl(this.props.diary.homeClubLogo),
        }
      : Images.imagePlaceholderRounded;

    const awayTeamLogo = this.props.diary.awayClubLogo
      ? {
          uri: getImageApiHostOrUrl(this.props.diary.awayClubLogo),
        }
      : Images.imagePlaceholderRounded;

    return (
      <ShadowCard overflowStyle={styles.scoreBody}>
        <View style={styles.scoreColumn}>
          <Text style={styles.scoreTeamName}>
            {this.props.diary.homeTeamName || this.props.diary.selectedHomeTeamName}
          </Text>

          <Image
            source={homeTeamLogo}
            alt="Home team logo"
            loadingIndicatorSource={Images.loadingSpinner}
            onError={(error) => {
              console.debug(`Loading failed for home team logo ${this.homeClubLogo}`, error.nativeEvent);
            }}
            style={{ width: 64, height: 64 }}
          />

          <Text style={styles.scoreClubName} numberOfLines={1} ellipsizeMode="tail">
            {this.props.diary.homeClubName || this.props.diary.selectedHomeClubName}
          </Text>
        </View>
        <View style={{ flex: 0.5, xDirection: 'column', alignItems: 'center', paddingTop: 10 }}>
          <Text
            style={{
              alignSelf: 'center',
              marginVertical: '15%',
              fontFamily: 'appFontMedium',
              fontSize: 26,
              color: Colors.blueCorporate,
            }}
            numberOfLines={1}
            ellipsizeMode="tail">
            {' - '}
          </Text>
        </View>
        <View style={styles.scoreColumn}>
          <Text style={styles.scoreTeamName}>
            {this.props.diary.awayTeamName || this.props.diary.selectedAwayTeamName}
          </Text>
          <Image
            source={awayTeamLogo}
            alt="Away team logo"
            loadingIndicatorSource={Images.loadingSpinner}
            onError={(error) => {
              console.debug(`Loading failed for away team logo ${this.awayClubLogo}`, error.nativeEvent);
            }}
            style={{ width: 64, height: 64 }}
          />
          <Text style={styles.scoreClubName} numberOfLines={1} ellipsizeMode="tail">
            {this.props.diary.awayClubName || this.props.diary.selectedAwayClubName}
          </Text>
        </View>
      </ShadowCard>
    );
  }

  render() {
    if (!this.state.visible) {
      return;
    }

    this.debug && console.debug('PopupAgenda diary:', this.props.diary);

    return (
      <BottomUpSheet
        onPopupRef={(ref) => (this.popupRef = ref)}
        onClose={() => {
          this.setState({ visible: false });
          this.props.onClose();
        }}
        onOpen={() => {
          this.props.onOpen();
        }}>
        <View style={{ flex: 1 }}>
          {/*Header*/}
          <View style={styles.headerContainer}>
            <Image style={styles.headerLogo} source={this.clubLogo} />

            <View>
              <Text style={[styles.headerTitle]} numberOfLines={2} ellipsizeMode="tail">
                {this.props.diary.title}
              </Text>
              {/*<Text style={[styles.headerDate]}>{getTimeAgo(this.props.diary, 'releaseDate')}</Text>*/}
            </View>
          </View>

          <KeyboardAvoidingView
            style={{ flex: 1 }}
            behavior={Platform.OS === 'ios' ? 'position' : 'position'}
            contentContainerStyle={{ marginBottom: 60 }}>
            <ScrollView
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{ padding: 20, paddingTop: 0 }}
              onContentSizeChange={(contentWidth, contentHeight) => {
                this.scrollToBottom = contentHeight;
              }}>
              {/*Main content*/}
              <TouchableWithoutFeedback style={{ flex: 1 }} accessible={false} onPress={() => {}}>
                <View style={{ flex: 1 }}>
                  <View style={[styles.actuContainer]}>
                    <Text style={[styles.actuTitle]}>{this.props.diary.title}</Text>
                    {this.props.diary.description && this.props.diary.description !== '' && (
                      <Autolink
                        style={styles.actuDescription}
                        linkStyle={{ padding: 2, margin: 0, color: Colors.textLink, fontWeight: 'bold' }}
                        text={this.props.diary.description}
                        email
                        hashtag="instagram"
                        mention="twitter"
                        phone
                        url
                      />
                    )}
                  </View>

                  {this.props.diary.DiaryType_id === DIARY_MATCH && (
                    <View style={[styles.actuContainer]}>{this.renderClubTeams()}</View>
                  )}

                  <View style={[styles.actuContainer]}>
                    <Text style={[styles.actuTitle]}>{I18n.t('createActu.place')}</Text>

                    <Text style={styles.actuEventDateTime}>
                      {'Le ' + moment(this.props.diary.eventDate).format('dddd Do MMMM YYYY à HH[h]mm')}
                    </Text>
                    {this.props.diary.isRecurrent === '1' ? (
                      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Icon name="refresh-circle-outline" size={32} color={Colors.blueCorporate} />
                        <Text
                          style={
                            styles.diaryEventFormattedDate
                          }>{`Chaque ${this.props.diary.dayOfWeek} à ${this.props.diary.time}`}</Text>
                      </View>
                    ) : (
                      <></>
                    )}

                    <Pressable
                      style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10, marginHorizontal: 20 }}
                      onPress={() => {
                        this.setState({ isMapOpened: !this.state.isMapOpened });
                      }}
                      onLongPress={() => {
                        if (!this.props.diary.latitude && !this.props.diary.longitude) {
                          openMap(this.props.diary.address);
                        } else {
                          openMap(this.props.diary.address, this.props.diary.latitude, this.props.diary.longitude);
                        }
                      }}>
                      <View style={[{ marginRight: 10 }, styles.actionButton]}>
                        <Icon name="map" size={25} color={Colors.blueCorporate} />
                      </View>
                      <View style={{ width: sw - 130 }}>
                        <Text
                          style={{
                            fontFamily: 'appFontMedium',
                            color: Colors.blueCorporate,
                            fontSize: 12,
                          }}
                          numberOfLines={3}
                          ellipsizeMode="tail">
                          {`À ${this.props.diary.addressSimple}`}
                        </Text>
                        <Text
                          style={{
                            fontFamily: 'appFontRegular',
                            fontStyle: 'italic',
                            color: Colors.blueCorporate,
                            fontSize: 11,
                          }}
                          numberOfLines={3}
                          ellipsizeMode="tail">
                          {this.props.diary.addressExtended}
                        </Text>
                      </View>
                    </Pressable>

                    {this.state.isMapOpened && (
                      <MapViewer
                        marker={{
                          latitude: parseFloat(this.props.diary.latitude),
                          longitude: parseFloat(this.props.diary.longitude),
                          title: this.props.diary.title,
                          address: this.props.diary.description,
                        }}
                        address={{
                          latitude: parseFloat(this.props.diary.latitude),
                          longitude: parseFloat(this.props.diary.longitude),
                          title: this.props.diary.title,
                          description: this.props.diary.description,
                        }}
                        style={{ height: sh / 2 }}
                      />
                    )}
                  </View>

                  {this.props.diary.isFuture && this.props.diary.askReply === '1' && (
                    <>
                      <ShadowCard style={[styles.presenceReplyContainer]}>
                        <Text style={{ color: Colors.blueCorporate, opacity: 0.7 }}>
                          {I18n.t('convocation.indicatePresent') + ' :'}
                        </Text>

                        <View style={[styles.replyButtonsContainer]}>
                          <ButtonBase
                            disabled={this.state.loadingYes}
                            loading={this.state.loadingYes}
                            label={I18n.t('actuList.replyYes')}
                            style={[
                              styles.replyButton,
                              {
                                backgroundColor:
                                  this.props.diary.myReply === REPLY_YES ? Colors.blueCorporate : Colors.white,
                              },
                            ]}
                            textStyle={{
                              color: this.props.diary.myReply === REPLY_YES ? Colors.white : Colors.blueCorporate,
                            }}
                            onPress={() => {
                              this.replyConvocation(REPLY_YES);
                              this.setState({ myReply: REPLY_YES });
                            }}
                          />
                          <ButtonBase
                            disabled={this.state.loadingMayBe}
                            loading={this.state.loadingMayBe}
                            label={I18n.t('actuList.replyMayBe')}
                            style={[
                              styles.replyButton,
                              {
                                backgroundColor:
                                  this.props.diary.myReply === REPLY_MAY_BE ? Colors.blueCorporate : Colors.white,
                              },
                            ]}
                            textStyle={{
                              color: this.props.diary.myReply === REPLY_MAY_BE ? Colors.white : Colors.blueCorporate,
                            }}
                            onPress={() => {
                              this.replyConvocation(REPLY_MAY_BE);
                              this.setState({ myReply: REPLY_MAY_BE });
                            }}
                          />
                          <ButtonBase
                            disabled={this.state.loadingNo}
                            loading={this.state.loadingNo}
                            label={I18n.t('actuList.replyNo')}
                            style={[
                              styles.replyButton,
                              {
                                backgroundColor:
                                  this.props.diary.myReply === REPLY_NO ? Colors.blueCorporate : Colors.white,
                              },
                            ]}
                            textStyle={{
                              color: this.props.diary.myReply === REPLY_NO ? Colors.white : Colors.blueCorporate,
                            }}
                            onPress={() => {
                              this.replyConvocation(REPLY_NO);
                              this.setState({ myReply: REPLY_NO });
                            }}
                          />
                        </View>
                      </ShadowCard>

                      {this.props.diary.replies && this.props.diary.replies.length > 0 && (
                        <>
                          <View>
                            <View style={{ borderBottomColor: Colors.blueCorporate, borderBottomWidth: 2 }} />

                            <Text style={[styles.actuTitle]}>{I18n.t('agenda.participants')}</Text>

                            <CardReply diary={this.props.diary} type={REPLY_YES} />
                            {/*<CardReply diary={this.props.diary} type={REPLY_MAY_BE} />*/}
                            {/*<CardReply diary={this.props.diary} type={REPLY_NO} />*/}

                            <View style={{ borderBottomColor: Colors.blueCorporate, borderBottomWidth: 2 }} />
                          </View>
                        </>
                      )}
                    </>
                  )}
                </View>
              </TouchableWithoutFeedback>
              {/*<View style={{ height: 120 }} />*/}
            </ScrollView>
          </KeyboardAvoidingView>
        </View>

        <AlerteUI
          showAlert={this.state.showAlert}
          onClose={() => {
            this.setState({ showAlert: false });
          }}
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          buttons={this.state.alertButtons}
        />
      </BottomUpSheet>
    );
  }
}

export default PopupAgenda;
