import CardPartner from '@components/CardPartner/CardPartner';
import CardSimpleCategory from '@components/CardSimpleCategory/CardSimpleCategory';
import CardUser from '@components/CardUser/CardUser';
import Colors from '@configs/colors';
import I18n from '@utils/i18n';
import { useDimensions } from '@utils/useDimensions';
import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, FlatList, Pressable } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { useSelector } from 'react-redux';

/**
 * A club members category as a FlatList element
 *
 * @param club
 * @param category
 * @param members - a list of club members
 * @param content - teams, users or partners
 * @param team - the current team, null for the main categories
 * @param opened - create the element in opened mode
 * @param selected - whether the category is selected or not
 * @param allSelectable - true if all the category is selectable
 * @param selectable - to make the category content items selectable or not
 * @param callable - to show and activate the phone number if any
 * @param chattable - to make the user chattable (create a conversation with)
 * @param pendingStatus
 * @param isEditing
 * @param onDeleteUserTeam
 * @param onOpenChat
 * @param onConfirmUserTeam
 * @param onSelectMember
 * @param onSelectCategory
 * @param onDeselectCategory
 * @returns {JSX.Element}
 * @constructor
 */
export default function CardCategory({
  club,
  category,
  members,
  content = 'users',
  team = null,
  opened = false,
  selected = false,
  allSelectable = true,
  selectable = true,
  callable = true,
  chattable = false,
  pendingStatus = false,
  isEditing = false,
  onDeleteUserTeam = () => {},
  onOpenChat = () => {},
  onConfirmUserTeam = () => {},
  onSelectMember = () => {},
  onSelectCategory,
  onDeselectCategory,
}) {
  const debug = false;

  const [isBlockOpened, setIsBlockOpened] = useState(opened);
  const [categorySelected, setCategorySelected] = useState(selected);

  const { width } = useDimensions().window;
  const [numColumns, setNumColumns] = useState(1);
  useEffect(() => {
    setNumColumns(width >= 768 ? 2 : 1);
  }, [width]);

  const { selectedMembers, selectedCategories, selectedTeams } = useSelector((state) => state.chat);

  useEffect(() => {
    debug && console.debug('CardCategory:', category, content);
  }, []);

  useEffect(() => {
    if (category === 'found' && content === 'found-users') {
      for (const member of members) {
        const foundIndex = selectedMembers.findIndex((item) => item.id === member.id);
        member.selected = foundIndex >= 0;
      }
    }
    const foundIndex = selectedCategories.findIndex((item) => item.title === category);
    setCategorySelected(foundIndex >= 0);
  }, [selectedCategories]);

  if (members.length <= 0) {
    return (
      <View style={styles.membersCategory}>
        <View style={styles.blockHeader}>
          <Text style={styles.closerLabel} numberOfLines={1} ellipsizeMode="tail">
            {`${I18n.t('message.categories.' + category)} (pas de membres !)`}
          </Text>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.membersCategory}>
      <View style={styles.blockHeader}>
        <Pressable
          style={({ pressed }) => [styles.closerRow, { width: width - 80 }, pressed ? { opacity: 0.7 } : {}]}
          onPress={() => {
            setIsBlockOpened(!isBlockOpened);
          }}>
          <Icon
            style={styles.closerIcon}
            name={isBlockOpened ? 'chevron-up' : 'chevron-down'}
            color={club?.mainColorBottom ?? Colors.black}
            size={25}
          />
          <Text style={[styles.closerLabel, { color: club.mainColorBottom, width: '100%' }]}>
            {I18n.t('message.categories.' + category)}
          </Text>
        </Pressable>

        {allSelectable && (
          <Pressable
            disabled={!selectable}
            style={styles.categorySelection}
            onPress={() => {
              if (categorySelected) {
                onDeselectCategory(
                  category,
                  team,
                  team
                    ? `${team.name} (${I18n.t('message.categories.' + category)})`
                    : I18n.t('message.categories.' + category)
                );
              } else {
                onSelectCategory(category, team);
              }
              setCategorySelected(!categorySelected);
            }}>
            <Text style={styles.categorySelectionLabel}>
              {categorySelected
                ? I18n.t('message.deselect', { all: I18n.t('message.categories.' + category + 'All') })
                : I18n.t('message.select', { all: I18n.t('message.categories.' + category + 'All') })}
            </Text>
            {selectable && (
              <Icon
                style={styles.categorySelectionIcon}
                size={25}
                color={club.mainColorBottom ?? Colors.black}
                name={categorySelected ? 'checkbox-outline' : 'square-outline'}
              />
            )}
          </Pressable>
        )}
      </View>

      {isBlockOpened && content === 'teams' && (
        <View style={[styles.blockContainer]}>
          <FlatList
            data={members}
            key={numColumns}
            numColumns={numColumns}
            keyExtractor={(item, index) => item.id + index}
            renderItem={({ item, index }) => (
              <CardSimpleCategory
                key={`${category}-${item.id}-${index}`}
                club={club}
                team={item}
                category={item}
                content={content}
                selectable={selectable}
                insideTeam={team !== null}
                onSelectCategory={onSelectCategory}
                onDeselectCategory={onDeselectCategory}
              />
            )}
          />
        </View>
      )}

      {isBlockOpened && content === 'categories' && (
        <View style={[styles.blockContainer]}>
          <FlatList
            data={members}
            key={numColumns}
            numColumns={numColumns}
            keyExtractor={(item, index) => numColumns + index}
            renderItem={({ item, index }) => (
              <CardSimpleCategory
                key={`${category}-${item.id}-${index}`}
                club={club}
                team={team}
                category={item}
                content={content}
                selectable={selectable}
                insideTeam={team !== null}
                onSelectCategory={onSelectCategory}
                onDeselectCategory={onDeselectCategory}
              />
            )}
          />
        </View>
      )}

      {isBlockOpened && content === 'found-users' && (
        <View style={[styles.blockContainer]}>
          <FlatList
            data={members}
            key={numColumns}
            numColumns={numColumns}
            keyExtractor={(item, index) => item.id + index}
            renderItem={({ item, index }) => (
              <CardUser
                key={`${category}-${item.id}-${index}`}
                club={club}
                member={item}
                selectable={selectable}
                callable={callable}
                chattable={chattable}
                insideTeam={team !== null}
                onSelectMember={(member) => {
                  onSelectMember(category, member, team);
                }}
                onOpenChat={onOpenChat}
              />
            )}
          />
        </View>
      )}

      {isBlockOpened && content === 'users' && (
        <View style={[styles.blockContainer]}>
          <FlatList
            data={members}
            key={numColumns}
            numColumns={numColumns}
            keyExtractor={(item, index) => item.id + index}
            renderItem={({ item, index }) => (
              <CardUser
                key={`${category}-${item.id}-${index}`}
                club={club}
                member={item}
                selectable={selectable}
                callable={callable}
                chattable={chattable}
                insideTeam={team !== null}
                pendingStatus={pendingStatus}
                isEditing={isEditing}
                onConfirmUserTeam={onConfirmUserTeam}
                onDeleteUserTeam={onDeleteUserTeam}
                onSelectMember={(member) => {
                  onSelectMember(category, member, team);
                }}
                onOpenChat={onOpenChat}
              />
            )}
          />
        </View>
      )}

      {isBlockOpened && content === 'partners' && (
        <View style={styles.blockContainer}>
          <FlatList
            data={members}
            key={1}
            numColumns={1}
            keyExtractor={(item, index) => item.id + index}
            renderItem={({ item, index }) => (
              <CardUser
                key={`${category}-${item.id}-${index}`}
                club={club}
                member={item}
                selectable={selectable}
                callable={callable}
                chattable={chattable}
                insideTeam={team !== null}
                onSelectMember={(member) => {
                  onSelectMember(category, member, team);
                }}
                onOpenChat={onOpenChat}
              />
            )}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  membersCategory: {},
  blockHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    paddingVertical: 0,
    paddingHorizontal: 5,
    borderBottomColor: Colors.gray,
    borderBottomWidth: 1,
  },

  closerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    margin: 0,
    padding: 0,
  },
  closerIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  closerLabel: {
    fontFamily: 'appFontRegular',
    fontSize: 18,
  },
  closerLabelWithSelection: {
    fontFamily: 'appFontMedium',
    fontSize: 18,
  },

  blockContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',

    borderBottomWidth: 1,
    borderBottomColor: Colors.gray,
  },

  categorySelection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    marginRight: 10,

    borderBottomWidth: 1,
    borderBottomColor: Colors.gray,
  },

  categorySelectionLabel: {
    // alignSelf: 'center',
    color: Colors.iconGray,
    display: 'none',
    marginRight: 10,
  },

  categorySelectionIcon: {
    // alignSelf: 'center',
    color: Colors.iconGray,
  },
});
