import { getApiHost } from '@configs/host';
import createFormData from '@utils/formData';
import * as Application from 'expo-application';
import Constants from 'expo-constants';
import * as ImageManipulator from 'expo-image-manipulator';
import md5 from 'md5';

import { Request } from './Request';
import { URLs } from './apisConstants';

const debug = false;

export const AccountAPI = {
  getAllProfileTypes: () => {
    return Request.get(getApiHost() + URLs.getAllProfileTypes);
  },

  /**
   *  Note that language is not considered by the backend!
   *  while it expects a `showPhoneNumber` (0/1) that is always on.
   *
   * @param newUser
   * @param selectedClub
   * @param profileTypes
   * @param selectedTeam
   * @param licenseNumber
   * @param language
   * @param avatar
   * @param showPhoneNumber
   * @returns {Promise<Response | void>}
   */
  register: (
    newUser,
    selectedClub,
    profileTypes,
    selectedTeam = null,
    licenseNumber = null,
    language = 'fr',
    avatar = null,
    showPhoneNumber = true
  ) => {
    let data = {
      firstname: newUser.firstname,
      lastname: newUser.lastname,
      birthdate: newUser.birthdate,
      email: newUser.email,
      password: md5(newUser.password),
      profileTypes,
      clubReference: selectedClub.id,
      licenseNumber,
      language,
      showPhoneNumber,
    };

    if (avatar) {
      data = {
        ...data,
        avatar,
      };
    }

    if (selectedTeam) {
      data = {
        ...data,
        teams: [selectedTeam.id],
      };
    }
    return Request.post(getApiHost() + URLs.register, data);
  },

  /**
   * @param {string} login
   * @param {string} password
   * @param {string} languageCode
   */
  authenticate: async (login, password, languageCode) => {
    return await Request.post(getApiHost() + URLs.authenticate, {
      login,
      password,
      language: languageCode,
    });
  },

  editMyInfo: (
    wsToken,
    profileTypeIds,
    lastname,
    firstname,
    phoneNumber,
    email,
    birthdate,
    address,
    city,
    postalCode,
    strongHand,
    position,
    height,
    weight,
    licenseNumber,
    clubId
  ) => {
    return Request.post(getApiHost() + URLs.editMyInfo, {
      wsToken,
      profileTypeIds,
      lastname,
      firstname,
      phoneNumber,
      email,
      birthdate,
      address,
      city,
      postalCode,
      strongHand,
      position,
      height,
      weight,
      licenseNumber,
      showPhoneNumber: 1,
      clubId,
    });
  },

  editPassword: (wsToken, oldPassword, newPassword) => {
    return Request.post(getApiHost() + URLs.editPassword, {
      wsToken,
      oldPassword,
      newPassword,
    });
  },

  forgetPassword: (email) => {
    return Request.post(getApiHost() + URLs.forgotPassword, {
      email,
    });
  },

  authRefresh: (wsRefreshToken, deviceUuid, expoToken) => {
    return Request.post(getApiHost() + URLs.authRefresh, {
      wsRefreshToken,
      deviceUuid,
      expoToken,
    });
  },

  logout: (wsToken) => {
    return Request.post(getApiHost() + URLs.logout, {
      wsToken,
      // Elipce/Fred: deprecated: installationId
      deviceUuids: Constants.installationId,
    });
  },

  deleteAccount: (wsToken) => {
    return Request.post(getApiHost() + URLs.deleteAccount, {
      wsToken,
    });
  },

  userReportComment: (wsToken, commentId) => {
    return Request.post(getApiHost() + URLs.reportUserComment, {
      wsToken,
      commentId,
    });
  },

  blockUserPost: (wsToken, postId) => {
    return Request.post(getApiHost() + URLs.blockUserPost, {
      wsToken,
      actuId: postId,
    });
  },

  userReportUser: (wsToken, userId) => {
    return Request.post(getApiHost() + URLs.reportUser, {
      wsToken,
      userId,
    });
  },

  blockUser: (wsToken, userId) => {
    return Request.post(getApiHost() + URLs.blockUser, {
      wsToken,
      userId,
    });
  },

  updateUserAcceptedAgreements: (wsToken) => {
    return Request.post(getApiHost() + URLs.updateUserAcceptedAgreements, {
      wsToken,
    });
  },

  getMyAccount: (wsToken) => {
    return Request.get(getApiHost() + URLs.getMyAccount + '?wsToken=' + wsToken);
  },

  editAvatar: (wsToken, avatar) => {
    return Request.post(getApiHost() + URLs.editAvatar, {
      wsToken,
      avatar,
    });
  },

  uploadAvatar: async (file, wsToken, apiConfiguration = null) => {
    // Resize file to have maximum 512x512 (enough for an avatar!)
    if (file.type === 'image' && (file.width > 512 || file.height > 512)) {
      file = await ImageManipulator.manipulateAsync(file.localUri || file.uri, [{ resize: { width: 512 } }], {
        compress: 0.5,
        format: ImageManipulator.SaveFormat.JPEG,
      });
    }

    if (Constants.expoConfig.extra.mediaS3 ?? false) {
      debug && console.debug('uploadAvatar, API configuration:', apiConfiguration);

      const body = createFormData(file, wsToken, 'file');
      debug && console.debug('uploadAvatar, API configuration:', body);
      const objectLocation = apiConfiguration?.imagesLocations ? apiConfiguration.imagesLocations?.users.avatars : '';
      debug && console.debug('uploadAvatar, object location:', objectLocation);
      try {
        const objectName = body.get('file_name') ?? 'unknown';
        debug && console.debug('uploadAvatar, object name:', objectName);
      } catch (e) {
        console.warn('Exception', e);
        const objectName = body._parts[0].get('file');
        debug && console.debug('uploadAvatar, object name:', objectName);
      }

      // const response = await Request.get(
      //   getApiHost() +
      //     URLs.s3GetPreSignedUrl +
      //     '?requestType=post&wsToken=' +
      //     wsToken +
      //     '&objectKey=' +
      //     objectLocation +
      //     '/' +
      //     objectName
      // );
      // debug && console.debug('uploadAvatar, S3 server response:', response);
      //
      // const data = new FormData();
      // // Data from the pre-signed request
      // Object.keys(response.result.formInputs).forEach((key) => {
      //   data.append(key, response.result.formInputs[key]);
      // });
      // // Data from the selected file - after the form inputs!
      // for (const entry of body.entries()) {
      //   data.append(entry[0], entry[1]);
      // }
      //
      // const headers = {}; // response.result.formInputs;
      // // headers['Content-Type'] = response.result.formAttributes['enctype'];
      // headers[
      //   'X-User-Agent'
      // ] = `${Constants.expoConfig.slug}/${Constants.expoConfig.version}/${Application.nativeBuildVersion}`;
      //
      // return await fetch(response.result.formAttributes['action'], {
      //   method: response.result.formAttributes['method'],
      //   body: data,
      //   headers,
      // })
      //   .then((response) => response)
      //   .catch((error) => {
      //     console.warn('uploadAvatar, error', error);
      //   });
    } else {
      const body = createFormData(file, wsToken, 'file_data');

      return await fetch(getApiHost() + URLs.uploadAvatar, {
        method: 'POST',
        body,
        headers: {
          // 'Content-Type': 'multipart/form-data',
          // 'boundary': Math.random().toString().substr(2),
          'X-User-Agent': `${Constants.expoConfig.slug}/${Constants.expoConfig.version}/${Application.nativeBuildVersion}`,
        },
      })
        .then((response) => response.json())
        .catch((error) => {
          console.warn('uploadAvatar, error', error);
        });
    }
  },
};
