import { Types } from '@apis/apisConstants';
import resultAPI from '@apis/result';
import HeaderCollapsible from '@components/HeaderCollapsible/HeaderCollapsible';
import Loading from '@components/Loading/Loading';
import Colors from '@configs/colors';
import * as actions from '@redux/actions';
import React, { Component } from 'react';
import { View, Text, Platform, StyleSheet, Dimensions, Pressable } from 'react-native';
import { FloatingAction } from 'react-native-floating-action';
import Icon from 'react-native-vector-icons/Ionicons';
import { WebView } from 'react-native-webview';
import { connect } from 'react-redux';

const { width, height } = Dimensions.get('window');

const defaultName = 'ResultsWebView';
const defaultUrl = 'http://e-appli.fr/';
const defaultType = Types.COMPETITION_TYPE.NATIONAL;

class ResultsWebView extends Component {
  debug = false;
  refWebView = null;

  constructor(props) {
    super(props);

    this.debug && console.debug(`ResultsWebView, props`, props);
    Object.keys(props).map((key, value) => {
      this.debug && console.debug(`- prop '${key}':`, props[key]);
    });

    this.state = {
      currentClub: this.props.affiliatedClubInformation ?? this.props.session.club ?? null,

      pageName: this.props.route.params.title ?? defaultName,
      pageUrl: this.props.route.params.url ?? defaultUrl,
      pageType: this.props.route.params.type ?? defaultType,

      hasToDisplayFavFab: false,
      isCurrentPageFav: false,
      currentPageTitle: '',
      currentPageUrl: '',

      showWebViewLoader: true,

      openNotification: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.getFavoriteCompetitions().then(() => {
      // Map the redux action!
      this.setState({ loading: false });
    });

    // BackHandler.addEventListener('hardwareBackPress', () => {
    //   this.props.navigation.goBack();
    //   return true;
    // });
  }

  getFavoriteCompetitions = async () => {
    this.props.tokens &&
      (await resultAPI.getFavoriteCompetitions(this.props.tokens.wsToken, this.state.pageType, 1).then((response) => {
        if (response && response.success && response.result.length > 0) {
          this.debug && console.debug(`ResultsWebView, got some favorites:`, response.result);

          this.setState({ listLiked: response.result });
        } else if (response.error_code === 10) {
          console.warn('Session expired!');
          // Session expired redirect to Login
        } else {
          // TODO handle error
        }
      }));
  };

  likeDislikeCompetition = (competition) => {
    resultAPI
      .likeDislikeCompetition(
        this.props.tokens.wsToken,
        competition.competitionId,
        competition.competitionName,
        competition.competitionType
      )
      .then((response) => {
        const newListLiked = [...this.state.listLiked];
        const alreadyLiked = this.state.listLiked.find((id, index) => {
          return competition.competitionId === id;
        });
        if (!alreadyLiked) {
          newListLiked.push('' + competition.competitionId + '');
        } else {
          this.state.listLiked.map((id, index) => {
            if (competition.competitionId === id) {
              newListLiked.splice(index, 1);
            }
          });
        }

        this.setState({ listLiked: newListLiked });
      });
  };

  backSubHeader = () => {
    return (
      <View style={styles.backSubHeader}>
        <Pressable style={styles.backBar} onPress={() => this.props.navigation.goBack()}>
          <Icon style={styles.backIcon} size={25} name="arrow-back" color={Colors.blueCorporate} />
          <Text style={styles.backText}>Retour au choix des compétitions</Text>
        </Pressable>
      </View>
    );
  };

  renderFavoritesFAB = () => {
    return (
      <FloatingAction
        ref={(ref) => {
          this.floatingAction = ref;
        }}
        floatingIcon={
          <Icon
            name={this.state.isCurrentPageFav ? 'md-star' : 'md-star-outline'}
            size={25}
            color={this.state.currentClub?.inactiveIconColor ?? Colors.white}
          />
        }
        color={this.state.currentClub?.mainColorBottom ?? Colors.blueCorporate}
        distanceToEdge={{ vertical: 70, horizontal: 30 }}
        visible
        position="right"
        showBackground={false}
        onOpen={() => {
          this.floatingAction.reset();
          this.likeDislikeCompetition();

          const favorite = {
            currentPageTitle: this.state.currentPageTitle,
            currentPageUrl: this.state.currentPageUrl,
          };

          const data = this.props.favoriteResults.filter(
            (item) => item?.currentPageTitle && item.currentPageTitle === this.state.currentPageTitle
          );
          if (data?.length > 0) {
            this.setState({
              isCurrentPageFav: false,
            });
            const updatedData = this.props.favoriteResults.filter(
              (item) => item?.currentPageTitle !== this.state.currentPageTitle
            );
            this.props.updateFavoriteLink(updatedData);
          } else {
            this.setState({ isCurrentPageFav: true });
            this.props.addFavoriteLink(favorite);
          }
        }}
      />
    );
  };

  handleWebViewNavigationStateChange = () => {
    const jsCode =
      '' +
      'try {\n' +
      "  var title = document.getElementsByClassName('m-standings__title')[0].innerHTML;\n" +
      '  window.ReactNativeWebView.postMessage(title)\n' +
      '} catch (error) {\n' +
      '  window.ReactNativeWebView.postMessage("NOK")\n' +
      '}';

    if (Platform.OS === 'ios') {
      this.setState({ isCurrentPageFav: false, hasToDisplayFavFab: false });
    }
    this.refWebView.injectJavaScript(jsCode);
  };

  showCompetition = () => {
    this.debug && console.debug(`ResultsWebView, showCompetition:`);

    const self = this;

    const jsScriptCleanUI = `
      setTimeout(function () {
        // DELETE RGPD BOX
        try {
          let elementR = document.getElementsByClassName('didomi-continue-without-agreeing');
          if (elementR && elementR.length > 0) {
            for (let i = 0; i < elementR.length; i++) {
              elementR[i].click();
            }
          }
        } catch (error) {
          console.error(error);
        }
            
        // DELETE HEADER
        try {
          let elementHeader = document.getElementsByClassName('m-navigation-tool');
          if (elementHeader && elementHeader.length > 0) {
            for (let i = 0; i < elementHeader.length; i++) {
              elementHeader[i].remove();
            }
          }
        } catch (error) {
          console.error(error);
        }
            
        // DELETE FOOTER
        try {
          let elementFooter = document.getElementsByClassName('m-footer');
          if (elementFooter && elementFooter.length > 0) {
            for (let i = 0; i < elementFooter.length; i++) {
              elementFooter[i].remove();
            }
          }
        } catch (error) {
          console.error(error);
        }
            
        // DELETE PUB
        try {
          let elementPub = document.getElementsByClassName('optidigital-wrapper-div');
          if (elementPub && elementPub.length > 0) {
            for (let i = 0; i < elementPub.length; i++) {
              elementPub[i].remove();
            }
          }
        } catch (error) {
          console.error(error);
        }
      }, 500);
    `;

    // Create and display a list of competitions
    if (Platform.OS === 'web') {
      return <iframe src={this.state.pageUrl} style={styles.iframe} />;
    }

    return (
      <WebView
        style={{ height, width }}
        ref={(ref) => (this.refWebView = ref)}
        source={{ uri: this.state.pageUrl }}
        onError={(syntheticEvent) => {
          const { nativeEvent } = syntheticEvent;
          console.warn(`ResultsWebView, web view error: `, nativeEvent);
        }}
        onLoadStart={() => {
          this.debug && console.debug(`ResultsWebView, web view loading...`);

          this.setState({ showWebViewLoader: true });
        }}
        onLoadEnd={(syntheticEvent) => {
          const { nativeEvent } = syntheticEvent;
          this.debug && console.debug(`ResultsWebView, web view loaded`, nativeEvent);

          this.handleWebViewNavigationStateChange();

          setTimeout(function () {
            self.setState({ showWebViewLoader: false });
          }, 1000);
        }}
        injectedJavaScript={jsScriptCleanUI}
        onMessage={(syntheticEvent) => {
          const { nativeEvent } = syntheticEvent;
          this.debug && console.debug(`ResultsWebView, web view message`, nativeEvent);
          if (nativeEvent.data && nativeEvent.data !== 'NOK') {
            const isAlreadyFav = this.props.favoriteResults.filter(
              (item) => item.currentPageTitle === nativeEvent.data
            );
            if (isAlreadyFav.length > 0) {
              this.setState({
                isCurrentPageFav: true,
                hasToDisplayFavFab: true,
                currentPageTitle: nativeEvent.data,
                currentPageUrl: nativeEvent.url,
              });
            } else {
              this.setState({
                isCurrentPageFav: false,
                hasToDisplayFavFab: true,
                currentPageTitle: nativeEvent.data,
                currentPageUrl: nativeEvent.url,
              });
            }
          } else {
            // Could not get the page title! HTML changed ?
            this.setState({
              isCurrentPageFav: false,
              hasToDisplayFavFab: false,
              currentPageTitle: '',
              currentPageUrl: '',
            });
          }
        }}
      />
    );
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        <HeaderCollapsible
          navigation={this.props.navigation}
          route={this.props.route}
          headerTitle={this.state.externalUrl ? 'Résultats' : this.props.route.params.title}
          headerScrollView={<this.backSubHeader />}
          contentScrollView={[<this.showCompetition />]}
        />

        <Loading
          loading={this.state.showWebViewLoader}
          requestCloseLoading={() => {
            this.setState({ loading: false });
          }}
        />

        {this.state.hasToDisplayFavFab && this.renderFavoritesFAB()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  backSubHeader: {
    width,
    height: 60,
    alignItems: 'center',
    zIndex: 99999,
  },
  backBar: {
    flex: 1,
    width,
    height: 50,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  backIcon: {
    alignItems: 'center',
    marginLeft: 20,
  },
  backText: {
    fontFamily: 'appFontMedium',
    fontSize: 18,
    color: Colors.blueCorporate,
    padding: 5,
  },
  titleText: {
    fontFamily: 'appFontRegular',
    fontSize: 16,
    alignItems: 'center',
    height: 20,
    padding: 5,
    paddingTop: 0,
  },

  iframe: {
    width: 800,
    height: 600,
  },
});

const mapStateToProps = (state) => {
  const { favoriteResults } = state.favorites;
  const { tokens } = state.app;
  const { session, affiliatedClubInformation } = state.session;
  return {
    tokens,
    session,
    favoriteResults: favoriteResults ?? [],
    affiliatedClubInformation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFavoriteLink: (favorite) => dispatch(actions.addFavoriteLink(favorite)),
    updateFavoriteLink: (favorite) => dispatch(actions.updateFavoriteLink(favorite)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsWebView);
