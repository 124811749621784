import { getApiHost } from '@configs/host';
import Constants from 'expo-constants';

import { Request } from './Request';
import { URLs } from './apisConstants';

export const DeviceAPI = {
  registerTempPushToken: (pushToken) => {
    return Request.post(getApiHost() + URLs.registerTempPushToken, {
      pushToken,
    });
  },
  registerDevice: (pushToken, wsToken) => {
    return Request.post(getApiHost() + URLs.registerDevice, {
      wsToken,
      pushToken,
      // Elipce/Fred: deprecated
      deviceUuid: Constants.installationId,
    });
  },
};
