import ShadowCard from '@components/ShadowCard/ShadowCard';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import { getTimeAgo } from '@utils/time';
import React from 'react';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';

const NotificationItem = ({ notification, onPress = () => {} }) => {
  if (!notification) {
    return;
  }

  const logo =
    notification.data && notification.data.clubLogo
      ? {
          uri: getImageApiHostOrUrl(notification.data.clubLogo),
        }
      : require('@assets/logo.png') ?? '';

  return (
    <View
      style={{
        marginTop: -5,
      }}>
      <ShadowCard style={{ marginVertical: 5 }}>
        <Pressable
          style={({ pressed }) => [styles.mainContainer, pressed ? { opacity: 0.7 } : {}]}
          onPress={() => {
            onPress();
          }}>
          <View style={styles.containerImage}>
            <Image source={logo} style={styles.imageLogo} />
          </View>

          <View style={[styles.notifDataCtn]}>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Text style={[styles.notifTitle, { maxWidth: '80%' }]} numberOfLines={1}>
                {notification.id + ' / ' + notification.title + ' '}
              </Text>
              <Text style={[styles.notifTimeAgo]}>{' - ' + getTimeAgo(notification, 'eventDate')}</Text>
            </View>

            <Text style={[styles.notifDesc]} numberOfLines={1}>
              {notification.content}
            </Text>
          </View>

          <View style={[styles.buttonShowDetail]}>
            <Icon name="md-arrow-forward" color={Colors.red} size={25} />
          </View>
        </Pressable>
      </ShadowCard>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 5,
    paddingVertical: 5,
  },
  notifDataCtn: {
    flex: 4,
  },
  buttonShowDetail: {
    width: 40,
    height: 40,
    resizeMode: 'contain',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    maxWidth: 40,
    borderRadius: 100,
  },
  notifTitle: {
    color: Colors.red,
    fontFamily: 'appFontMedium',
  },
  notifTimeAgo: {
    fontSize: 10,
    color: Colors.blueBgGradient1 + '99',
    fontFamily: 'appFontRegular',
  },
  notifDesc: {
    fontSize: 12,
    color: Colors.blueCorporate,
    fontFamily: 'appFontRegular',
    lineHeight: 16,
  },
  imageLogo: {
    width: 40,
    height: 40,
    borderRadius: 100,
  },
  containerImage: {
    marginRight: 5,
  },
});

export default NotificationItem;
