import { DiariesAPI } from '@apis/diaries';
import {
  RAZ_AGENDA,
  FUTURE_DIARIES,
  PAST_DIARIES,
  SET_AGENDA_ERROR,
  SET_AGENDA_LOADING,
  SET_AGENDA_NOT_LOADING,
  SET_AGENDA,
  SORT_AGENDA,
  UPDATE_DIARY,
  DELETE_DIARY,
  SET_NEW_DIARY,
} from '@redux/reducers/AgendaReducer';
import moment from 'moment';

const debug = false;

export function resetStateAgenda() {
  return async (dispatch) => {
    dispatch({ type: RAZ_AGENDA });
  };
}

export function updateDiary(diary) {
  if (!diary) return diary;

  const updatedDiary = { ...diary };

  const splitAddress = diary.address && diary.address.split('|');
  updatedDiary.addressSimple = splitAddress && splitAddress.length > 1 ? splitAddress[0] : diary.address;
  updatedDiary.addressExtended = splitAddress && splitAddress.length > 1 ? splitAddress[1] : '';

  updatedDiary.myReply = (diary.userResponse && diary.userResponse.PresenceReplyType_id) || null;
  updatedDiary.comments = diary.comments ? diary.comments.reverse() : [];

  // Date/time
  updatedDiary.eventDate = moment(diary.date + ' ' + diary.time, 'DD/MM/YYYY HH:mm');
  updatedDiary.isFuture = updatedDiary.eventDate.isAfter(moment());
  updatedDiary.timestamp = updatedDiary.eventDate.unix();
  updatedDiary.dayOfWeek = updatedDiary.eventDate.format('dddd');
  updatedDiary.theDate = updatedDiary.eventDate.format('ddd Do MMM, HH[h]mm');

  // Unique id
  updatedDiary.uniqueId = `${updatedDiary.id}-${updatedDiary.timestamp}`;

  // Logos des clubs/équipes pour les matches
  updatedDiary.homeTeamName = diary.selectedHomeTeamName ?? diary.homeTeamName;
  updatedDiary.homeClubLogo = diary.selectedHomeTeamLogo ?? diary.homeTeamLogo;
  updatedDiary.awayTeamName = diary.selectedAwayTeamName ?? diary.awayTeamName;
  updatedDiary.awayClubLogo = diary.selectedAwayTeamLogo ?? diary.awayTeamLogo;
  delete updatedDiary.selectedHomeTeamName;
  delete updatedDiary.selectedHomeTeamLogo;
  delete updatedDiary.selectedAwayTeamName;
  delete updatedDiary.selectedAwayTeamLogo;

  delete updatedDiary.createdAtSql;

  return updatedDiary;
}

/**
 * function used to get all agenda (future or past diaries)
 * @param {*} token
 * @param {*} clubId
 * @param {*} page
 * @param {*} limit
 * @param {*} typeIds
 * @param {*} recipientIds
 * @param isFuture - 0 / -1: past, 1 / 2: future
 * @param callback
 * @returns
 */
export function getAgenda(token, clubId, page, limit, isFuture = true, typeIds = [], recipientIds = [], callback) {
  return async (dispatch, getState) => {
    try {
      const start = Date.now();
      dispatch({ type: SET_AGENDA_LOADING });

      const agendaType = isFuture ? FUTURE_DIARIES : PAST_DIARIES;

      DiariesAPI.getAllDiariesByClubId(token, clubId, page, limit, agendaType, typeIds, recipientIds).then(
        (response) => {
          debug && console.debug(`AgendaActions getAgenda ${isFuture}, got:`, response);

          if (response && response.success) {
            // Update existing items
            const currentDiaries = getState().agenda.agenda;
            if (currentDiaries) {
              for (const diary of currentDiaries) {
                if (!diary) {
                  console.warn(`AgendaActions getAgenda, current agenda has an empty diary!`);
                  continue;
                }
                debug && console.debug(`AgendaActions getAgenda, current diary:`, diary.uniqueId);

                const updatedDiary = updateDiary(diary);
                const foundIndex = response.result.diaries.findIndex(
                  (agendaItem) => agendaItem.uniqueId === updatedDiary.uniqueId
                );
                if (foundIndex >= 0) {
                  dispatch({
                    type: UPDATE_DIARY,
                    data: updatedDiary,
                    isFuture: updatedDiary.isFuture,
                  });
                }
              }
            }

            dispatch({
              type: SET_AGENDA,
              data: response.result.diaries.map((item) => updateDiary(item)),
              hasNextPage: response.result.hasNextPage,
              isFuture,
            });

            dispatch({
              type: SORT_AGENDA,
            });

            callback(response.success, response.result);
          } else {
            callback(false);

            dispatch({ type: SET_AGENDA_ERROR });
          }

          const duration = Date.now() - start;
          setTimeout(() => {
            dispatch({ type: SET_AGENDA_NOT_LOADING });
          }, Math.max(1000, duration));
        }
      );
    } catch (error) {
      console.warn('getAgenda, catch:', error);
      if (error) {
        dispatch({
          type: SET_AGENDA_ERROR,
          errorCode: error,
        });
      }
    }
  };
}

/**
 * Function to add a new diary to the agenda
 * @param {*} token
 * @param clubId
 * @param diaryType
 * @param data
 * @param callback
 */
export function addDiary(token, clubId, diaryType, data, callback = () => {}) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_AGENDA_LOADING });

      DiariesAPI.addDiaryByClubId(token, clubId, diaryType, data).then((response) => {
        debug && console.debug('AgendaActions addDiary, got:', response);

        if (response && response.success) {
          dispatch({
            type: SET_NEW_DIARY,
            message: response.result ?? null,
          });

          callback(response.success, response.result.message);
        } else {
          callback(response.success, response);

          dispatch({ type: SET_AGENDA_ERROR });
        }
        dispatch({ type: SET_AGENDA_NOT_LOADING });
      });
    } catch (error) {
      console.warn('AgendaActions addDiary, catch:', error);
      if (error) {
        dispatch({
          type: SET_AGENDA_ERROR,
          errorCode: error,
        });
      }
    }
  };
}
/**
 * function used to get one diary
 * @param {*} token
 * @param {*} diaryId
 * @param callback
 * @returns
 */
export function getDiary(token, diaryId, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_AGENDA_LOADING });

      DiariesAPI.getDiary(this.props.tokens.wsToken, this.props.diary.id).then((response) => {
        debug && console.debug(`AgendaActions getDiary, got:`, response);
        dispatch({ type: SET_AGENDA_NOT_LOADING });

        if (response && response.success) {
          const updatedDiary = updateDiary(response.result);
          dispatch({
            type: UPDATE_DIARY,
            data: updatedDiary,
            isFuture: updatedDiary.isFuture,
          });

          callback(response.success, response.result);
        } else {
          callback(false);

          dispatch({ type: SET_AGENDA_ERROR });
        }
      });
    } catch (error) {
      console.warn('getDiary, catch:', error);
      if (error) {
        dispatch({
          type: SET_AGENDA_ERROR,
          errorCode: error,
        });
      }
    }
  };
}

/**
 * Function to remove a diary from the agenda
 * @param {string} token
 * @param {string} diaryId
 * @param callback
 */
export function deleteDiary(token, diaryId, callback = () => {}) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_AGENDA_LOADING });

      DiariesAPI.deleteDiary(token, diaryId).then((response) => {
        debug && console.debug('AgendaActions deleteDiary, got:', response);
        dispatch({ type: SET_AGENDA_NOT_LOADING });

        if (response && response.success) {
          dispatch({
            type: DELETE_DIARY,
            diaryId,
          });

          dispatch({
            type: SORT_AGENDA,
          });

          callback(response.success);
        } else {
          callback(false);

          dispatch({ type: SET_AGENDA_ERROR });
        }
      });
    } catch (error) {
      console.warn('deleteDiary, catch:', error);
      if (error) {
        dispatch({
          type: SET_AGENDA_ERROR,
          errorCode: error,
        });
      }
    }
  };
}
