import { clubCategories, teamCategories } from '@apis/clubs';
import ButtonChoice from '@components/ButtonChoice/ButtonChoice';
import CardCategory from '@components/CardCategory/CardCategory';
import CardTeams from '@components/CardTeams/CardTeams';
import HeaderBanner from '@components/HeaderBanner/HeaderBanner';
import Loading from '@components/Loading/Loading';
import SearchBar from '@components/SearchBar/SearchBar';
import Colors from '@configs/colors';
import { useIsFocused } from '@react-navigation/native';
import {
  selectCategory,
  selectMember,
  selectPartner,
  selectTeam,
  unselectCategory,
  unselectTeam,
} from '@redux/actions';
import alert from '@utils/alert';
import { checkIsModeratorOrAdmin } from '@utils/checkUserRoles';
import I18n from '@utils/i18n';
import { useDimensions } from '@utils/useDimensions';
import React, { useEffect, useState } from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

export default function MembersSelection({ navigation, route }) {
  const debug = false;

  const isFocused = useIsFocused();

  const { width } = useDimensions().window;

  const [initialized, setInitialized] = useState(false);
  const [edition, setEdition] = useState(false);

  const [currentClub, setCurrentClub] = useState(null);

  const [hasAccess, setHasAccess] = useState(false);

  const { session, affiliatedClubInformation } = useSelector((state) => state.session);
  const { loading, members, uniqueMembers, partners, teams } = useSelector((state) => state.club);
  const {
    indirectMembers: theIndirectMembers,
    selectedPartners: theSelectedPartners,
    selectedMembers: theSelectedMembers,
    selectedCategories: theSelectedCategories,
    selectedTeams: theSelectedTeams,
  } = useSelector((state) => state.chat);

  const [clubMembers, setClubMembers] = useState(null);

  const [chatId, setChatId] = useState(null);
  const [chatName, setChatName] = useState('');

  const [selectedAllTeams, setSelectedAllTeams] = useState(false);

  const [textFilter, setTextFilter] = useState('');
  const [searchableMembers, setSearchableMembers] = useState({});
  const [foundMembers, setFoundMembers] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    debug && console.debug('MembersSelection focused:', isFocused);
    isFocused && initialisation();
  }, [isFocused]);

  useEffect(() => {
    debug && console.debug('MembersSelection update current club');

    setCurrentClub(affiliatedClubInformation ?? session.club ?? null);
    setHasAccess(checkIsModeratorOrAdmin(session.user));
  }, [session, affiliatedClubInformation]);

  useEffect(() => {
    debug && console.debug('MembersSelection got route parameters', initialized, route.params);
    // No route parameters for a new conversation creation
    if (initialized || !route.params || !clubMembers) {
      return;
    }

    debug && console.debug('MembersSelection, current conversation', route.params.chat);
    const membersRoles = route.params.chat.membersRoles;

    setChatId(route.params.chat.id);
    setChatName(route.params.chat.name);

    let members = [];
    if (membersRoles) {
      // New conversation membership
      debug && console.debug('MembersSelection, members roles', membersRoles);
      setSelectedAllTeams(membersRoles.allTeams);
      if (!membersRoles.allTeams) {
        for (const team of membersRoles.teams) {
          onSelectTeam(team);
        }
      }
      for (const category of membersRoles.categories) {
        onSelectCategory(category.title, category.team);
      }
      members = membersRoles.members;
    } else {
      // Old conversation without membership
      const participants = route.params.chat.participants;
      const foundIndex = participants.findIndex((item) => item.id === session.user.id);
      members = [...participants.slice(0, foundIndex), ...participants.slice(foundIndex + 1)];
    }

    debug && console.debug('MembersSelection, members', members);
    for (const member of members) {
      member.name = `${member.firstName} ${member.lastName}`;
      debug && console.debug('MembersSelection, member:', member);

      let categoryTitle = 'unknown';

      let profiles = member.profilesNames ? member.profilesNames.split(', ') : null;
      debug && console.debug('MembersSelection, profilesNames:', profiles);
      if (!profiles) {
        profiles = member.profileIds ?? [];
        if (!Array.isArray(profiles)) {
          // New profiles identifiers are in a string
          categoryTitle = member.selectionCategory;
        } else {
          member.profileLabels = profiles.map((profile) => profile.profil).join(', ');

          // Try to find a matching profile in the member profiles list
          for (const profile of profiles) {
            const foundIndex = clubCategories.findIndex((category) => category.profile === Number(profile.profilId));
            if (foundIndex >= 0) {
              categoryTitle = clubCategories[foundIndex].title;
              break;
            }
          }
        }
      } else {
        // Try to find a matching profile name in the member profiles list
        debug && console.debug('MembersSelection, search profilesNames:');
        for (const profile of profiles) {
          const foundIndex = clubCategories.findIndex((category) => category.profileName === profile);
          if (foundIndex >= 0) {
            categoryTitle = clubCategories[foundIndex].title;
            break;
          }
        }
      }
      debug && console.debug('MembersSelection, category:', categoryTitle);

      const roles = member.roleIds ?? [];
      if (!Array.isArray(roles)) {
        categoryTitle = member.selectionCategory;
      } else {
        // Ignore the back-office-only roles (Super admin, Admin, Moderator)
        member.roleLabels = roles
          .map((role) => (role.roleId === '1' || role.roleId === '2' || role.roleId === '3' ? null : role.role))
          .join(', ');

        // Try to find a matching profile in the member roles list
        for (const mainRole of roles) {
          const foundIndex = clubCategories.findIndex((category) => {
            const index = category.roles.findIndex((role) => role === Number(mainRole.roleId));
            if (index >= 0) {
              categoryTitle = category.title;
            }
          });
          if (foundIndex >= 0) break;
        }
      }

      // // Force select the member
      // debug && console.debug('MembersSelection, category:', categoryTitle);
      // // onSelectMember(categoryTitle, member, null, true);
    }

    setInitialized(true);
  }, [route && route.params, clubMembers]);

  useEffect(() => {
    debug && console.debug('MembersSelection updated club members / partners', members, partners);
    if (Object.keys(members).length > 0 && partners) {
      debug && console.debug('MembersSelection updated club members / partners, setting club members...');
      setClubMembers({ ...members, partners });
    }
  }, [members, partners]);

  useEffect(() => {
    debug && console.debug('MembersSelection updated club unique members / partners', uniqueMembers, partners);
    if (uniqueMembers && partners) {
      // Search in club members and partners
      const searchableMembers = [...uniqueMembers, ...partners];
      debug &&
        console.debug(
          'MembersSelection, setting searchable members...',
          partners.length,
          uniqueMembers.length,
          searchableMembers.length
        );
      setSearchableMembers(searchableMembers);
    }
    setFoundMembers([]);
  }, [uniqueMembers, partners]);

  const initialisation = () => {
    // the current conversation is provided in the route parameters (if any...)
    if (route.params?.chat) {
      // Editing an existing conversation
      setEdition(route.params.edition ?? false);

      setChatId(route.params.chat.id);
      setChatName(route.params.chat.name);
      debug && console.debug('MembersSelection route parameters, update an existing conversation', route.params.chat);
    } else {
      // Create a new conversation
      setEdition(true);
      setInitialized(true);
      debug && console.debug('MembersSelection no route parameters, create a new conversation');
    }
  };

  const searchUsers = (searchText) => {
    debug && console.debug(`MembersSelection, search text: ${searchText} in ${searchableMembers.length} users`);
    if (!searchText) {
      return;
    }

    // ES6 - remove diacritics -)
    const searchedText = searchText.normalize('NFD').replace(/\p{Diacritic}/gu, '');
    setTextFilter(searchedText);

    const foundUsers = [];
    for (const user of searchableMembers) {
      if (!user.name) {
        user.name = `${user.firstName} ${user.lastName}`;
      }
      const userName = user.name.normalize('NFD').replace(/\p{Diacritic}/gu, '');
      if (userName.toLowerCase().includes(searchedText.toLowerCase())) {
        // In individually selected members?
        if (
          theSelectedMembers.find(
            (item) => item.id === user.id && item.Team_id === user.Team_id && item.category === user.category
          )
        ) {
          user.selected = true;
        }
        // In team/category selected members?
        if (
          theIndirectMembers.find(
            (item) => item.id === user.id && item.Team_id === user.Team_id && item.category === user.category
          )
        ) {
          user.selected = true;
        }
        foundUsers.push(user);
      }
    }

    debug && console.debug('MembersSelection, foundUsers, count:', foundUsers.length);
    setFoundMembers(foundUsers);
  };

  const onSelectPartner = (category, partner, team = null, state = null) => {
    debug && console.debug(`MembersSelection, onSelectPartner - ${category} -`, partner);

    dispatch(selectPartner(partner));
  };

  const onSelectMember = (category, member, team = null, state = null, becauseCategorySelected = false) => {
    debug && console.debug(`MembersSelection, onSelectMember - ${category} -`, member);
    team && debug && console.debug('MembersSelection, onSelectMember team', team.name);

    dispatch(selectMember(member.category ?? category, member, team ? team.id : team));
  };

  const onSelectCategory = (category, team = null) => {
    if (!clubMembers) {
      return;
    }
    debug && console.debug('MembersSelection, onSelectCategory', category);
    team && debug && console.debug('MembersSelection, onSelectCategory team', team.name);

    if (team) {
      dispatch(selectCategory(category, team.id));
    } else {
      dispatch(selectCategory(category));
    }

    const label = team
      ? `${team.name} (${I18n.t('message.categories.' + category)})`
      : I18n.t('message.categories.' + category);

    if (edition && !chatName.includes(label)) {
      setChatName(label + (chatName ? ', ' + chatName : ''));
    }
  };

  const onDeselectCategory = (category, team = null) => {
    if (!clubMembers) {
      return;
    }
    debug && console.debug('MembersSelection, onDeselectCategory', category);
    team && debug && console.debug('MembersSelection, onDeselectCategory team', team, team.name);

    if (team) {
      dispatch(unselectCategory(category, team.id));
    } else {
      dispatch(unselectCategory(category));
    }

    if (edition) {
      const label = team
        ? `${team.name} (${I18n.t('message.categories.' + category)})`
        : I18n.t('message.categories.' + category);

      let newChatName = chatName;
      newChatName = newChatName.replace(label + ', ', '');
      newChatName = newChatName.replace(label, '');
      setChatName(newChatName);
    }
  };

  const onSelectTeam = (team) => {
    debug && console.debug('MembersSelection, onSelectTeam', team);

    dispatch(selectTeam(team.id));

    if (edition && !chatName.includes(team.name)) {
      setChatName(team.name + (chatName ? ', ' + chatName : ''));
    }
  };

  const onDeselectTeam = (team) => {
    debug && console.debug('MembersSelection, onDeselectTeam', team);

    dispatch(unselectTeam(team.id));

    let newChatName = chatName;
    newChatName = newChatName.replace(team.name + ', ', '');
    newChatName = newChatName.replace(team.name, '');

    if (edition) {
      setChatName(newChatName);
    }
  };

  const onSelectAllTeams = () => {
    debug && console.debug('MembersSelection, onSelectAllTeams');

    teams.map((team) => {
      // onSelectTeam(team);
      dispatch(selectTeam(team.id));
    });

    setSelectedAllTeams(true);

    const label = I18n.t('message.categories.teamsAll');
    if (edition && !chatName.includes(label)) {
      setChatName(label + (chatName ? ', ' + chatName : ''));
    }
  };

  const onDeselectAllTeams = () => {
    debug && console.debug('MembersSelection, onDeselectAllTeams');

    teams.map((team) => {
      dispatch(unselectTeam(team.id));
    });

    setSelectedAllTeams(false);

    if (edition) {
      const label = I18n.t('message.categories.teamsAll');
      let newChatName = chatName;
      newChatName = newChatName.replace(label + ', ', '');
      newChatName = newChatName.replace(label, '');
      setChatName(newChatName);
    }
  };

  /**
   *  Called when the user clicks on button "Ok" to validate the conversation
   */
  const viewChatDetails = () => {
    if (
      theSelectedTeams.length +
        theSelectedCategories.length +
        theSelectedMembers.length +
        theSelectedPartners.length ===
      0
    ) {
      alert(I18n.t('app.oups'), 'Veuillez sélectionner une ou plusieurs personnes pour commencer une conversation');
      return;
    }
    debug && console.debug('MembersSelection, viewChatDetails, chat name:', chatName);

    let conversationName = chatName;
    if (!conversationName) {
      theSelectedMembers.map((member, index) => {
        let name = member.name || `${member.firstName} ${member.lastName}`;
        if (name.length > 40 || theSelectedMembers.length > 5) {
          // name = name.substring(0, 39) + '...';
          name = member.lastName;
        }

        conversationName += conversationName ? ', ' + name : name;
      });
    }

    let participants = [
      (({ id, firstName, lastName, name, profileIds, profileLabels, roleIds, roleLabels, category }) => ({
        id,
        firstName,
        lastName,
        name,
        profileIds,
        profileLabels,
        roleIds,
        roleLabels,
        category,
      }))(session.user),
    ];
    theSelectedPartners.map((partner) => {
      participants = [
        ...participants,
        (({ id, name, category }) => ({
          id,
          name,
          category,
        }))(partner),
      ];
    });
    theSelectedMembers.map((member) => {
      participants = [
        ...participants,
        (({ id, firstName, lastName, name, profileIds, profileLabels, roleIds, roleLabels, category }) => ({
          id,
          firstName,
          lastName,
          name,
          profileIds,
          profileLabels,
          roleIds,
          roleLabels,
          category,
        }))(member),
      ];
    });
    for (const category of theSelectedCategories) {
      if (category.title === 'partners') {
        partners.map((partner) => {
          participants = [
            ...participants,
            (({ id, name, category }) => ({
              id,
              name,
              category,
            }))(partner),
          ];
        });
      } else {
        members[category.title].map((member) => {
          participants = [
            ...participants,
            (({ id, firstName, lastName, name, profileIds, profileLabels, roleIds, roleLabels, category }) => ({
              id,
              firstName,
              lastName,
              name,
              profileIds,
              profileLabels,
              roleIds,
              roleLabels,
              category,
            }))(member),
          ];
        });
      }
    }
    const uploadTeams = [];
    for (const team of theSelectedTeams) {
      uploadTeams.push({
        id: team.id,
        name: team.name,
      });

      for (const category of teamCategories) {
        if (!team[category.title]) {
          continue;
        }
        // console.log(category, team);
        team[category.title].map((member, index) => {
          participants = [
            ...participants,
            (({ id, firstName, lastName, name, profileIds, profileLabels, roleIds, roleLabels, category }) => ({
              id,
              firstName,
              lastName,
              name,
              profileIds,
              profileLabels,
              roleIds,
              roleLabels,
              category,
            }))(member),
          ];
        });
      }
    }

    navigation.navigate('MessagesStack', {
      screen: 'ChatDetails',
      params: {
        edition,
        chat: {
          id: edition ? chatId : null,
          name: conversationName,
          oneToOne:
            !selectedAllTeams &&
            theSelectedTeams.length === 0 &&
            theSelectedCategories.length === 0 &&
            theSelectedMembers.length === 1,
          membersRoles: {
            allTeams: selectedAllTeams,
            teams: [...uploadTeams],
            categories: [...theSelectedCategories],
            members: [...theSelectedMembers],
            partners: [...theSelectedPartners],
          },
          // Selected members and current user
          participants: [...participants],
        },
      },
    });
  };

  const renderAllComponents = () => {
    if (loading || foundMembers.length > 0) {
      return;
    }

    return (
      <View style={{ flex: 1, marginTop: 10 }}>
        {/* All individual categories */}
        <FlatList
          data={clubCategories}
          keyExtractor={(item, index) => item.title}
          renderItem={({ item, index }) => {
            if (!clubMembers[item.title] || !clubMembers[item.title].length) return;

            if (item.title === 'teams') {
              if (!hasAccess) {
                return null;
              }
              return (
                <CardTeams
                  club={currentClub}
                  category={item.title}
                  showCategories={false}
                  members={clubMembers[item.title]}
                  chattable={false}
                  selectable={edition}
                  selected={item.selected}
                  onSelectMember={onSelectMember}
                  onSelectCategory={onSelectCategory}
                  onDeselectCategory={onDeselectCategory}
                  onSelectTeam={onSelectTeam}
                  onDeselectTeam={onDeselectTeam}
                  onSelectAllTeams={onSelectAllTeams}
                  onDeselectAllTeams={onDeselectAllTeams}
                />
              );
            }

            if (item.title === 'partners') {
              return (
                <CardCategory
                  club={currentClub}
                  category={item.title}
                  members={clubMembers[item.title]}
                  content={item.content ?? 'partners'}
                  chattable={false}
                  selectable={edition}
                  selected={item.selected}
                  onSelectMember={onSelectPartner}
                  onSelectCategory={onSelectCategory}
                  onDeselectCategory={onDeselectCategory}
                  onSelectTeam={onSelectTeam}
                  onDeselectTeam={onDeselectTeam}
                  onSelectAllTeams={onSelectAllTeams}
                  onDeselectAllTeams={onDeselectAllTeams}
                />
              );
            }

            return (
              <CardCategory
                club={currentClub}
                category={item.title}
                members={clubMembers[item.title]}
                content={item.content ?? 'users'}
                chattable={false}
                selectable={edition}
                selected={item.selected}
                onSelectMember={onSelectMember}
                onSelectCategory={onSelectCategory}
                onDeselectCategory={onDeselectCategory}
                onSelectTeam={onSelectTeam}
                onDeselectTeam={onDeselectTeam}
                onSelectAllTeams={onSelectAllTeams}
                onDeselectAllTeams={onDeselectAllTeams}
              />
            );
          }}
        />
        <View style={{ height: 60 }} />
      </View>
    );
  };

  const renderSelectedTeams = () => {
    if (theSelectedTeams.length <= 0) {
      return;
    }

    return (
      <CardCategory
        opened
        selectable={edition}
        allSelectable={false}
        club={currentClub}
        category="selectedTeams"
        content="teams"
        members={theSelectedTeams}
        onSelectMember={(category, member, team) => {
          onSelectMember(category, member, team, false);
        }}
        onSelectCategory={onSelectTeam}
        onDeselectCategory={onDeselectTeam}
      />
    );
  };

  const renderSelectedCategories = () => {
    if (theSelectedCategories.length <= 0) {
      return;
    }

    return (
      <CardCategory
        opened
        selectable={edition}
        allSelectable={false}
        club={currentClub}
        category="selectedCategories"
        content="categories"
        members={theSelectedCategories}
        onSelectMember={(category, member, team) => {
          onSelectMember(category, member, team, false);
        }}
        onSelectCategory={onSelectCategory}
        onDeselectCategory={onDeselectCategory}
      />
    );
  };

  const renderSelectedPartners = () => {
    if (theSelectedPartners.length <= 0) {
      return;
    }

    return (
      <CardCategory
        opened
        selectable={edition}
        allSelectable={false}
        club={currentClub}
        category="selectedPartners"
        content="partners"
        members={theSelectedPartners}
        onSelectMember={(category, member, team) => {
          onSelectPartner(category, member, team, false);
        }}
        onSelectCategory={onSelectCategory}
        onDeselectCategory={onDeselectCategory}
      />
    );
  };

  const renderSelectedMembers = () => {
    if (theSelectedMembers.length <= 0) {
      return;
    }

    return (
      <CardCategory
        opened
        allSelectable={false}
        selectable={edition}
        club={currentClub}
        category="selectedMembers"
        content="users"
        members={theSelectedMembers}
        onSelectMember={(category, member, team) => {
          onSelectMember(category, member, team, false);
        }}
      />
    );
  };

  const renderSearchResults = () => {
    if (foundMembers.length <= 0 && textFilter === '') {
      return;
    }

    return (
      <View style={{ flex: 1 }}>
        <FlatList
          data={[{ title: 'found', content: 'found-users' }]}
          keyExtractor={(item, index) => 'Found-' + index}
          ListEmptyComponent={
            <View
              style={
                loading === false ? { flex: 1, justifyContent: 'center', alignItems: 'center' } : { display: 'none' }
              }>
              <Text style={{ alignSelf: 'center', color: Colors.blueCorporate }}>Pas de résultats</Text>
            </View>
          }
          renderItem={({ item, index }) => {
            return (
              <CardCategory
                opened
                allSelectable={false}
                club={currentClub}
                category={item.title}
                content={item.content ?? 'users'}
                members={foundMembers}
                onSelectMember={(category, member, team) => {
                  onSelectMember(member.category, member, team);
                }}
              />
            );
          }}
        />
        <View style={{ borderBottomColor: currentClub.mainColorBottom, borderBottomWidth: 3 }} />
      </View>
    );
  };

  if (!clubMembers || !initialized) {
    return null;
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {/* header */}
        <HeaderBanner
          leftIcon="md-arrow-back"
          onLeftButton={() => navigation.goBack()}
          title={chatName || I18n.t('message.manageGroup')}
        />

        {/* buttons cancel and add */}
        <View style={styles.buttonsBar}>
          {edition && (
            <ButtonChoice
              disabled={
                theSelectedTeams.length <= 0 && theSelectedCategories.length <= 0 && theSelectedMembers.length <= 0
              }
              label="icon-refresh"
              style={{ justifyContent: 'center', width: 60 }}
              onPress={() => {
                initialisation();
              }}
            />
          )}

          <SearchBar
            style={{ width: width - (edition ? 200 : 120) }}
            onSearchPress={() => {
              // setChatName('');
            }}
            deleteButton
            placeholder={I18n.t('message.searchPlaceholder')}
            buttonColor
            value=""
            onChangeText={(text) => {
              searchUsers(text);
            }}
            onDeleteButton={() => {
              setTextFilter('');
              setFoundMembers([]);
            }}
          />

          {edition && (
            <ButtonChoice
              disabled={
                theSelectedTeams.length <= 1 &&
                theSelectedCategories.length <= 0 &&
                theSelectedMembers.length <= 0 &&
                theSelectedPartners.length <= 0
              }
              label={I18n.t('message.add')}
              style={{ justifyContent: 'center', width: 60 }}
              textStyle={{ color: currentClub.mainColorBottom ?? Colors.black, fontWeight: 'bold' }}
              onPress={() => {
                viewChatDetails();
              }}
            />
          )}
        </View>

        {renderSearchResults()}

        {renderSelectedTeams()}

        {renderSelectedPartners()}

        {renderSelectedCategories()}

        {renderSelectedMembers()}

        {renderAllComponents()}

        <View style={{ height: 60 }} />
      </View>

      <Loading loading={loading} />
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    alignItems: 'center',
  },
  buttonsBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginVertical: 0,
    paddingHorizontal: 10,
    borderBottomColor: Colors.whiteCorporate,
    borderBottomWidth: 1,
  },
  selectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',

    borderBottomWidth: 1,
    borderBottomColor: Colors.gray,

    backgroundColor: Colors.blueSky,

    padding: 0,
    marginHorizontal: 0,
  },
  selectionTitle: {
    fontFamily: 'appFontMedium',
    fontSize: 16,
    marginLeft: 10,
  },
});
