import Colors from '@configs/colors';
import React, { useState } from 'react';
import { Dimensions, Pressable, StyleSheet, TextInput, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';

const { width: sw } = Dimensions.get('window');

export default function SearchBar({
  value,
  deleteButton = true,
  placeholder = '',
  style = {},
  onChangeText = null,
  onDeleteButton = () => {},
  onSearchPress = () => {},
  buttonColor = Colors.gray,
}) {
  const [searchKey, setSearchKey] = useState(value);

  return (
    <View style={[styles.mainContainer, style]}>
      <TextInput
        autoCapitalize="none"
        autoComplete="off"
        autoCorrect={false}
        // autoFocus
        placeholder={placeholder}
        value={searchKey}
        onChangeText={(text) => {
          setSearchKey(text);
          onChangeText(text);
        }}
        style={[styles.textInput]}
      />

      <Pressable
        onPress={() => {
          if (deleteButton) {
            onDeleteButton();
            setSearchKey('');
          }
          onSearchPress();
        }}
        style={[styles.searchButton]}>
        <Icon size={25} name={deleteButton && searchKey.length ? 'md-close' : 'md-search'} color={buttonColor} />
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    width: sw - 100,
    height: 32,
    backgroundColor: Colors.white + '88',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
    alignSelf: 'center',
    borderRadius: 100,
  },
  textInput: {
    fontFamily: 'appFontRegular',
    width: '90%',
    fontSize: 14,
  },
  searchButton: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
