import LocalCredentials from '@containers/LocalCredentials/LocalCredentials';
import SignedInNavigator from '@navigation/SignedInNavigator';
import SignedOutNavigator from '@navigation/SignedOutNavigator';
import AppOffline from '@navigation/modals/AppOffline';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { SET_USER_STATUS } from '@redux/storeReducer';
import Constants from 'expo-constants';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Main application navigation stuff
 *
 *    return <AppNavigation />;
 *
 */
const AppNavigator = () => {
  const debug = false;

  const { online, signedIn, userStatus, tokens, apiConfiguration } = useSelector((state) => state.app);
  const { session } = useSelector((state) => state.session);

  const dispatch = useDispatch();

  const Stack = createNativeStackNavigator();

  useEffect(() => {
    debug && console.debug(`Navigation, user signed-in: ${signedIn}`);
    if (signedIn) {
      // Let the LocalCredentials do the job...
      dispatch({ type: SET_USER_STATUS, value: 're-signing-in' });
    }
  }, []);

  useEffect(() => {
    debug && console.debug(`Navigation, API configuration`, apiConfiguration);
  }, [apiConfiguration]);

  useEffect(() => {
    debug && console.debug(`Navigation, user status: ${userStatus}`);
  }, [userStatus]);

  useEffect(() => {
    debug && console.debug(`Navigation, tokens`, tokens);
  }, [tokens]);

  useEffect(() => {
    debug && console.debug(`Navigation, session`);
    session &&
      Object.keys(session).map((key, value) => {
        debug && console.debug(`- '${key}':`, session[key]);
      });
  }, [session]);

  if (!online) {
    return (
      <NavigationContainer>
        <Stack.Navigator initialRouteName="AppOffline">
          <Stack.Group screenOptions={{ presentation: 'modal' }}>
            <Stack.Screen name="AppOffline" component={AppOffline} options={{ headerShown: false }} />
          </Stack.Group>
        </Stack.Navigator>
      </NavigationContainer>
    );
  }
  return (
    <NavigationContainer
      documentTitle={{
        formatter: (options, route) => `${Constants.expoConfig.name} - ${options?.title ?? route?.name}`,
      }}>
      <Stack.Navigator initialRouteName="SignedIn">
        {userStatus === 're-signing-in' ? (
          <Stack.Screen name="LocalCredentials" component={LocalCredentials} options={{ headerShown: false }} />
        ) : (
          <>
            {userStatus === 'signed-in' ? (
              <Stack.Screen name="SignedIn" component={SignedInNavigator} options={{ headerShown: false }} />
            ) : (
              <Stack.Screen name="SignedOut" component={SignedOutNavigator} options={{ headerShown: false }} />
            )}
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default AppNavigator;
