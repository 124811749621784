import { gMaxWidth } from '@configs/styles';
import { Dimensions, Image, Platform } from 'react-native';

const debug = false;
const { width: sw, height: sh } = Dimensions.get('window');

export const getImageSize = (uri) => {
  return new Promise((resolve, reject) => {
    Image.getSize(
      uri,
      (width, height) => {
        resolve({ width, height });
      },
      () => {
        resolve({ width: Platform.OS === 'web' ? gMaxWidth - 20 : sw, height: sh / 3 });
      }
    );
  });
};

/**
 *
 * @param media
 * @param limitWidth: preferred width
 * @param limitHeight: preferred height
 * @param scale
 * @param mediaCount
 * @returns {{width: number, scale: number, height: number}}
 */
export const updateSize = (media, limitWidth, limitHeight, scale, mediaCount) => {
  debug && console.debug(`updateSize, ${media.Actuality_id}/${media.id} updateSize, screen dimensions: ${sw} x ${sh}`);
  debug &&
    console.debug(
      `updateSize, ${media.Actuality_id}/${media.id} updateSize, limit dimensions: ${limitWidth} x ${limitHeight}, media:`,
      media
    );

  const orientation = media.width && media.height ? (media.width >= media.height ? 'portrait' : 'landscape') : 'none';
  debug && console.debug(`updateSize, ${media.Actuality_id}/${media.id} updateSize, orientation: ${orientation}`);
  let width,
    height,
    margin = 0;

  if (mediaCount === 1) {
    // Individual media configuration
    if (orientation === 'portrait') {
      if (media.width > limitWidth) {
        width = limitWidth;
        height = media.height * (limitWidth / media.width) ?? scale;
      } else {
        width = media.width;
        height = media.height;
        margin = (limitWidth - width) / 2;
      }
    } else if (orientation === 'landscape') {
      if (media.width > limitWidth) {
        width = limitWidth;
        height = media.height * (limitWidth / media.width) ?? scale;
      } else {
        width = media.width;
        height = media.height;
        margin = (limitWidth - width) / 2;
      }
    } else {
      width = limitWidth;
      height = limitHeight;
      margin = (limitWidth - width) / 2;
    }

    debug && console.debug(`updateSize, ${media.Actuality_id}/${media.id} -1-: dimensions: ${width} x ${height}`);
  } else {
    // More than one media - set 2/3 screen width format for all the media
    height = sh / 3;
    width = height * scale;

    debug && console.debug(`updateSize, ${media.Actuality_id}/${media.id} -N-: dimensions: ${width} x ${height}`);
  }

  return { width, height, margin };
};
