/**
 * Should be something like (Redux toolkit 8) but this actually raises many errors because of mutations:
 * -----
 * import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
 * import { configureStore } from '@reduxjs/toolkit';
 * import { combineReducers } from 'redux';
 * import { persistReducer, persistStore } from 'redux-persist';
 *
 * import actualityReducer from './reducers/ActualityReducer';
 * import agendaReducer from './reducers/AgendaReducer';
 * import chatReducer from './reducers/ChatReducer';
 * import clubReducer from './reducers/ClubReducer';
 * import notificationsReducer from './reducers/NotificationsReducer';
 * import resultFavoritesReducer from './reducers/ResultFavoritesReducer';
 * import sessionReducer from './reducers/SessionReducer';
 * import appReducer from './storeReducer';
 *
 * const appSlug = Constants.expoConfig.slug || 'eappli';
 *
 * // function that combine all reducers as one (=> store)
 * const rootReducer = combineReducers({
 *   app: appReducer,
 *   actuality: actualityReducer,
 *   agenda: agendaReducer,
 *   chat: chatReducer,
 *   club: clubReducer,
 *   favorites: resultFavoritesReducer,
 *   notifications: notificationsReducer,
 *   session: sessionReducer,
 * });
 *
 * const persistedReducer = persistReducer(
 *   {
 *     key: `${appSlug}-store`,
 *     version: 1,
 *     storage,
 *     blacklist: [],
 *   },
 *   rootReducer
 * );
 *
 * export const store = configureStore({
 *   // Automatically calls `combineReducers`
 *   reducer: persistedReducer,
 * });
 *
 * export const persistor = persistStore(store);
 *
 */
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import actualityReducer from './reducers/ActualityReducer';
import agendaReducer from './reducers/AgendaReducer';
import chatReducer from './reducers/ChatReducer';
import clubReducer from './reducers/ClubReducer';
import notificationsReducer from './reducers/NotificationsReducer';
import resultFavoritesReducer from './reducers/ResultFavoritesReducer';
import sessionReducer from './reducers/SessionReducer';
import appReducer from './storeReducer';

const appSlug = Constants.expoConfig.slug || 'eappli';

const rootReducer = combineReducers({
  app: appReducer,
  actuality: actualityReducer,
  agenda: agendaReducer,
  chat: chatReducer,
  club: clubReducer,
  favorites: resultFavoritesReducer,
  notifications: notificationsReducer,
  session: sessionReducer,
});

const persistedReducer = persistReducer(
  {
    key: `${appSlug}-store`,
    version: 1,
    storage: AsyncStorage,
    blacklist: ['actuality', 'agenda', 'chat', 'club', 'notifications'],
    // whitelist: [
    //   'actuality',
    //   // 'agenda',
    //   // 'chat',
    //   'club',
    //   'notifications',
    // ],
  },
  rootReducer
);

// export const store = createStore(combineAllReducers, applyMiddleware(thunk));
export const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(thunk)
    // other store enhancers if any
  )
);
export const persistor = persistStore(store);
