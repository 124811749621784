import { TeamAPI } from '@apis/team';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import CardCategory from '@components/CardCategory/CardCategory';
import HeaderBanner from '@components/HeaderBanner/HeaderBanner';
import Loading from '@components/Loading/Loading';
import SearchBar from '@components/SearchBar/SearchBar';
import Colors from '@configs/colors';
import { SET_CLUB_TEAM_PLAYERS } from '@redux/reducers';
import alert from '@utils/alert';
import { checkIsTeamManager } from '@utils/checkUserRoles';
import I18n from '@utils/i18n';
import React, { useEffect, useState } from 'react';
import { FlatList, StyleSheet, Text, useWindowDimensions, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

export default function UserTeamSelection({ navigation, route }) {
  const debug = false;

  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  const [textFilter, setTextFilter] = useState('');
  const [searchableMembers, setSearchableMembers] = useState({});
  const [foundMembers, setFoundMembers] = useState([]);

  const [currentClub, setCurrentClub] = useState(null);
  const [theTeam, setTheTeam] = useState(null);
  const [hasAccess, setHasAccess] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertButtons, setAlertButtons] = useState([]);

  const { session, affiliatedClubInformation } = useSelector((state) => state.session);
  const { loading } = useSelector((state) => state.club);
  const { tokens } = useSelector((state) => state.app);

  useEffect(() => {
    if (route.params?.members) {
      setSearchableMembers(route.params.members);
    }

    if (route.params?.team) {
      setTheTeam(route.params.team);
    }
  }, []);

  useEffect(() => {
    debug && console.debug('userTeamSelection update current club');

    setCurrentClub(affiliatedClubInformation ?? session.club ?? null);
    setHasAccess(checkIsTeamManager(session.user));
  }, [session, affiliatedClubInformation]);

  const searchUsers = (searchText) => {
    if (!searchText) {
      return;
    }
    debug && console.debug(`UserTeamSelection, search text: ${searchText} in ${searchableMembers.length} users`);

    const searchedText = searchText.normalize('NFD').replace(/\p{Diacritic}/gu, '');
    setTextFilter(searchedText);

    const foundUsers = [];
    for (const user of searchableMembers) {
      if (!user.name) {
        user.name = `${user.firstName} ${user.lastName}`;
      }
      const userName = user.name.normalize('NFD').replace(/\p{Diacritic}/gu, '');
      if (userName.toLowerCase().includes(searchedText.toLowerCase())) {
        foundUsers.push(user);
      }
    }

    debug && console.debug('UserTeamSelection, foundUsers, count:', foundUsers.length);
    setFoundMembers(foundUsers);
  };

  const addUserToTeam = (wsToken, action, teamId, userIds) => {
    debug && console.debug('addUserToTeam', userIds);
    TeamAPI.editTeam(wsToken, action, teamId, userIds).then((response) => {
      if (response?.success === true) {
        dispatch({ type: SET_CLUB_TEAM_PLAYERS, team: response.result, id: teamId });
        alert(I18n.t('app.success'), "L'utilisateur a bien été ajouté à l'équipe.");
        navigation.goBack();
      } else {
        alert(I18n.t('app.oups'), "Une erreur est survenue. Vous n'avez peut être pas les droits nécessaires.");
        console.warn('ClubTeam, deleteUserTeam', response);
      }
    });
  };

  const selectMember = (member) => {
    setShowAlert(true);
    setAlertTitle(I18n.t('alerts.teamSelection.title'));
    setAlertMessage(I18n.t('alerts.teamSelection.message'));
    setAlertButtons([
      {
        text: I18n.t('alerts.add'),
        onPress: () => {
          addUserToTeam(tokens.wsToken, 'add', theTeam.id, { [member.profileIds]: member.id });
        },
      },
      {
        text: I18n.t('alerts.cancel'),
      },
    ]);
  };

  const renderSearchResults = () => {
    if (foundMembers.length <= 0 && textFilter === '') {
      return;
    }

    return (
      <View style={{ flex: 1 }}>
        <FlatList
          data={[{ title: 'found', content: 'found-users' }]}
          keyExtractor={(item, index) => 'Found-' + index}
          ListEmptyComponent={
            <View
              style={
                loading === false ? { flex: 1, justifyContent: 'center', alignItems: 'center' } : { display: 'none' }
              }>
              <Text style={{ alignSelf: 'center', color: Colors.blueCorporate }}>Pas de résultats</Text>
            </View>
          }
          renderItem={({ item }) => {
            return (
              <CardCategory
                opened
                allSelectable={false}
                club={currentClub}
                category={item.title}
                content={item.content}
                members={foundMembers}
                onSelectMember={(member) => {
                  selectMember(member);
                }}
              />
            );
          }}
        />
        <View style={{ borderBottomColor: currentClub.mainColorBottom, borderBottomWidth: 3 }} />
      </View>
    );
  };

  const renderAllComponents = () => {
    if (loading || foundMembers.length > 0) {
      return;
    }

    return (
      <View style={{ flex: 1 }}>
        <FlatList
          data={[{ title: 'found', content: 'found-users' }]}
          keyExtractor={(item, index) => item.id + index}
          ListEmptyComponent={
            <View
              style={
                loading === false ? { flex: 1, justifyContent: 'center', alignItems: 'center' } : { display: 'none' }
              }>
              <Text style={{ alignSelf: 'center', color: Colors.blueCorporate }}>Pas de résultats</Text>
            </View>
          }
          renderItem={({ item }) => {
            return (
              <CardCategory
                opened
                allSelectable={false}
                club={currentClub}
                category={item.title}
                content={item.content}
                members={searchableMembers}
                onSelectMember={(category, member, team) => {
                  selectMember(member);
                }}
              />
            );
          }}
        />
        <View style={{ borderBottomColor: currentClub.mainColorBottom, borderBottomWidth: 3 }} />
      </View>
    );
  };

  if (!hasAccess || !currentClub || !theTeam) {
    return null;
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {/* header */}
        <HeaderBanner
          leftIcon="md-arrow-back"
          onLeftButton={() => navigation.goBack()}
          title={I18n.t('clubs.searchBarUserPlaceHolder')}
        />

        <View style={styles.buttonsBar}>
          <SearchBar
            style={{ width: width - 200 }}
            onSearchPress={() => {
              debug && console.debug('UserTeamSelection, onSearchPress');
            }}
            deleteButton
            placeholder={I18n.t('message.searchPlaceholder')}
            buttonColor
            value=""
            onChangeText={(text) => {
              searchUsers(text);
            }}
            onDeleteButton={() => {
              setTextFilter('');
              setFoundMembers([]);
            }}
          />
        </View>

        {renderSearchResults()}
        {renderAllComponents()}

        {showAlert && (
          <AlerteUI
            showAlert={showAlert}
            onClose={() => {
              setShowAlert(false);
            }}
            title={alertTitle}
            message={alertMessage}
            buttons={alertButtons}
          />
        )}
        <View style={{ height: 60 }} />
      </View>
      <Loading loading={loading} />
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    alignItems: 'center',
  },
  buttonsBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginVertical: 0,
    paddingHorizontal: 10,
    borderBottomColor: Colors.whiteCorporate,
    borderBottomWidth: 1,
  },
  selectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',

    borderBottomWidth: 1,
    borderBottomColor: Colors.gray,

    backgroundColor: Colors.blueSky,

    padding: 0,
    marginHorizontal: 0,
  },
  selectionTitle: {
    fontFamily: 'appFontMedium',
    fontSize: 16,
    marginLeft: 10,
  },
});
