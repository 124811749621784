import HeaderBanner from '@components/HeaderBanner/HeaderBanner';
import Colors from '@configs/colors';
import { gMaxWidth } from '@configs/styles';
import { useIsFocused } from '@react-navigation/native';
import { handleBadgeNotification } from '@redux/actions';
import * as ScreenOrientation from 'expo-screen-orientation';
import React, { useEffect, useState } from 'react';
import { Dimensions, Platform, Pressable, StyleSheet, Text, View } from 'react-native';
import {Autolink} from "react-native-autolink";
import Icon from 'react-native-vector-icons/Ionicons';
import { WebView } from 'react-native-webview';
import { useDispatch, useSelector } from 'react-redux';

const { width: sw, height: sh } = Dimensions.get('window');

const TabResults = ({ navigation, route }) => {
  const debug = false;

  const isFocused = useIsFocused();

  const [currentClub, setCurrentClub] = useState(null);

  const { tokens, apiConfiguration } = useSelector((state) => state.app);
  const { session, affiliatedClubInformation } = useSelector((state) => state.session);

  const dispatch = useDispatch();

  useEffect(() => {
    debug && console.debug('TabResults focused:', isFocused, session);

    async function lockOrientation() {
      await ScreenOrientation.unlockAsync();
      await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
      debug && console.debug(`App, screen orientation is locked`);
    }
    async function unlockOrientation() {
      await ScreenOrientation.unlockAsync();
      debug && console.debug(`App, screen orientation is unlocked`);
    }

    if (!isFocused) {
      lockOrientation();
      return;
    }

    unlockOrientation();
    dispatch(handleBadgeNotification('results', false));
  }, [isFocused]);

  useEffect(() => {
    debug && console.debug('TabResults update current club', affiliatedClubInformation);

    setCurrentClub(affiliatedClubInformation ?? session.club ?? null);
  }, [session, affiliatedClubInformation]);

  const ShowCompetitions = () => {
    if (!currentClub) return;

    debug && console.debug('TabResults, showCompetition:', currentClub);

    // 1/ Specific content for the results page defined by the club
    if (currentClub.external_result) {
      // Displays the club external url
      if (Platform.OS === 'web') {
        return <iframe src={currentClub.external_result} style={styles.iframe} />;
      }

      return <WebView style={{ flex: 1, height: sh }} source={{ uri: currentClub.external_result }} />;
    }

    // 2/ Specific content for the results page defined by the club
    if (apiConfiguration && apiConfiguration.results_url === '') {
      return (
        <View style={{ flex: 1 }}>
          <Autolink
            numberOfLines={3}
            ellipsizeMode="tail"
            style={{
              width: sw - 40,
              marginTop: 40,
              marginHorizontal: 20,
              fontFamily: 'appFontRegular',
              color: Colors.blueCorporate,
              textAlign: 'center',
              // backgroundColor: Colors.blueCorporate,
              opacity: 1,
              lineHeight: 40,
            }}
            linkStyle={{ padding: 2, margin: 0, color: Colors.textLink, fontWeight: 'bold' }}
            text={`Votre club n'a pas configuré de lien pour la page de résultats. Vous pouvez demander à l'administrateur du club de configurer une page.`}
            email
            hashtag="instagram"
            mention="twitter"
            phone
            url
          />
        </View>
      );
    }

    if (apiConfiguration && apiConfiguration.results_url) {
      // Displays the club external url
      if (Platform.OS === 'web') {
        return <iframe src={apiConfiguration.results_url} style={styles.iframe} />;
      }

      return <WebView style={{ flex: 1, height: sh }} source={{ uri: apiConfiguration.results_url }} />;
    }

    // 3/ Default behavior (legacy) - nation, regional, ... pages
    const competitionsList = [
      {
        number: 1,
        screen: 'Nation',
        title: 'Championnats nationaux',
        like: false,
      },
      {
        number: 2,
        screen: 'Region',
        title: 'Championnats régionaux',
        like: false,
      },
      {
        number: 3,
        screen: 'Department',
        title: 'Championnats départementaux',
        like: false,
      },
      {
        number: 4,
        screen: 'Cup',
        title: 'Coupe de France',
        like: false,
      },
    ];

    if (tokens) {
      competitionsList.push({
        number: 5,
        screen: 'Favorites',
        title: 'Favoris',
        like: false,
      });
    }

    debug && console.debug('TabResults, competitionsList:', competitionsList);

    // Create and display a list of competitions
    return competitionsList.map((item, id) => {
      return (
        <View
          key={'Choice-' + id}
          style={[styles.itemContainer, { backgroundColor: id % 2 === 1 ? null : Colors.whiteCorporate }]}>
          <Pressable
            style={styles.containerName}
            onPress={() => {
              navigation.navigate(item.screen, { title: item.title });
            }}>
            <Text style={styles.containerNameText}>{item.title}</Text>
            <Icon name="chevron-forward" size={25} color={Colors.red} />
          </Pressable>
        </View>
      );
    });
  };

  if (!currentClub && !isFocused) {
    return;
  }

  return (
    <View style={[{ flex: 1, marginBottom: 60 }, Platform.OS === 'web' ? { width: '100%', maxWidth: gMaxWidth } : {}]}>
      <HeaderBanner leftIcon={null} rightIcon="md-menu" onRightButton={() => navigation.openDrawer()} />

      <View style={{ flex: 1, marginTop: 0 }}>
        <ShowCompetitions />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    // flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '100px' : 100,
    width: Platform.OS === 'web' ? gMaxWidth - 80 : sw - 80,
  },
  containerHeartButton: {
    width: '15%',
    alignSelf: 'center',
    padding: 5,
    marginLeft: 15,
  },
  containerName: {
    flex: 1,
    alignSelf: 'center',
    padding: 5,
    flexDirection: 'row',
    marginHorizontal: 10,
  },
  containerNameText: {
    width: sw - 60,
    fontFamily: 'appFontMedium',
    color: Colors.blueCorporate,
    fontWeight: 'bold',
    fontSize: 22,
  },

  iframe: {
    width: gMaxWidth,
    height: sh - 100,
  },
});

export default TabResults;
