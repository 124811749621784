import { ClubsAPI } from '@apis/clubs';
import { NewsAPI } from '@apis/news';
import { TeamAPI } from '@apis/team';
import ButtonChoice from '@components/ButtonChoice/ButtonChoice';
import HeaderBanner from '@components/HeaderBanner/HeaderBanner';
import Loading from '@components/Loading/Loading';
import SearchBar from '@components/SearchBar/SearchBar';
import Colors from '@configs/colors';
import { razFavorites, setFavorites, setFoundClub } from '@redux/actions';
import { ACCOUNT_CREATION_SET_SELECTED_CLUB } from '@redux/storeReducer';
import alert from '@utils/alert';
import I18n from '@utils/i18n';
import { openMail } from '@utils/openLinks';
import Constants from 'expo-constants';
import React, { useEffect, useRef, useState } from 'react';
import { Dimensions, FlatList, KeyboardAvoidingView, Platform, Pressable, StyleSheet, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { RowCard } from './RowCard';

const { width: sw, height: sh } = Dimensions.get('window');

const ClubFilters = ({ navigation, route }) => {
  const debug = false;

  const [loading, setLoading] = useState(false);

  const { tokens, apiConfiguration } = useSelector((state) => state.app);
  const { favorites } = useSelector((state) => state.session);

  const dispatch = useDispatch();

  const [title, setTitle] = useState('');

  const [selectable, setSelectable] = useState(false);
  const [viewClubOnSelect, setViewClubOnSelect] = useState(false);
  const [chooseClubOnSelect, setChooseClubOnSelect] = useState(false);

  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedClubs, setSelectedClubs] = useState([]);

  const [regionsList, setRegionsList] = useState({ index: -1, regions: [] });
  const [departmentsList, setDepartmentsList] = useState({ index: -1, departments: [] });
  const [clubsList, setClubsList] = useState({ index: -1, clubs: [] });

  const [currentList, setCurrentList] = useState(null);
  // The useRef Hook allows you to persist data between renders
  const prevCurrentListRef = useRef(null);

  const [data, setData] = useState([]);

  const [searchableClubs, setSearchableClubs] = useState([]);
  const [foundClubs, setFoundClubs] = useState([]);

  useEffect(() => {
    debug && console.debug('ClubFilters, initialisation, route parameters:', route.params);
    const { clubs, regions, departments } = favorites;
    setSelectedRegions(regions);
    setSelectedDepartments(departments);
    setSelectedClubs(clubs);

    getRegions();
    getSearchableClubs();

    if (route.params && route.params.selectable) {
      setSelectable(route.params.selectable);
    }

    if (route.params && route.params.viewClubOnSelect) {
      setViewClubOnSelect(route.params.viewClubOnSelect);
    }

    if (route.params && route.params.chooseClubOnSelect) {
      setChooseClubOnSelect(route.params.chooseClubOnSelect);
    }

    setCurrentList('regions');
  }, []);

  useEffect(() => {
    debug &&
      console.debug(
        'ClubFilters, changed selection, updating favorites...',
        selectedRegions,
        selectedDepartments,
        selectedClubs
      );
    if (selectedRegions.length === 0 && selectedDepartments.length === 0 && selectedClubs.length === 0) return;

    dispatch(setFavorites(selectedRegions, selectedDepartments, selectedClubs));
  }, [selectedRegions, selectedDepartments, selectedClubs]);

  useEffect(() => {
    debug && console.debug('ClubFilters, changed current list...', currentList, prevCurrentListRef.current);
    if (!currentList) return;

    if (prevCurrentListRef.current === 'clubs' && currentList === 'departments') {
      setData(departmentsList.departments);
    }
    if (prevCurrentListRef.current === 'departments' && currentList === 'regions') {
      setData(regionsList.regions);
    }
    if (prevCurrentListRef.current === 'departments' && currentList === 'clubs') {
      setData(clubsList.clubs);
    }

    setTitle(I18n.t(`app.${currentList}List`));

    prevCurrentListRef.current = currentList;
  }, [currentList]);

  useEffect(() => {
    debug && console.debug('ClubFilters, changed departments index...', departmentsList);
    if (departmentsList.index === -1) return;

    const index = departmentsList.index;

    const viewingSubItems = departmentsList.departments[index].viewingSubItems;
    setDepartmentsList({
      index,
      departments: [
        ...departmentsList.departments.map((item, index) => {
          if (index === departmentsList.index) {
            return { ...item, viewingSubItems: !viewingSubItems };
          }
          return item;
        }),
      ],
    });

    // View department's clubs
    setData(clubsList.clubs);
    setCurrentList('clubs');
  }, [departmentsList.index]);

  useEffect(() => {
    debug && console.debug('ClubFilters, changed regions index...', regionsList);
    if (regionsList.index === -1) return;

    const index = regionsList.index;

    const viewingSubItems = regionsList.regions[index].viewingSubItems;
    setRegionsList({
      index,
      regions: [
        ...regionsList.regions.map((item, index) => {
          if (index === regionsList.index) {
            return { ...item, viewingSubItems: !viewingSubItems };
          }
          return item;
        }),
      ],
    });

    // View region's departments
    setData(departmentsList.departments);
    setCurrentList('departments');
  }, [regionsList.index]);

  const getSearchableClubs = () => {
    debug && console.debug('ClubFilters, getSearchableClubs');

    setLoading(true);

    ClubsAPI.getAllClubsWithNoParameters().then((response) => {
      if (response && response.success) {
        setSearchableClubs(response.result.clubs);
      }

      setLoading(false);
    });
  };

  const getRegions = () => {
    debug && console.debug('ClubFilters, getRegions');

    setLoading(true);

    NewsAPI.getAllLeagues().then((response) => {
      if (response && response.success) {
        setRegionsList({
          index: -1,
          regions: response.result,
        });
        setData(response.result);

        setLoading(false);
      }
    });
  };

  const getDepartments = (region, regionIndex, callback = () => {}) => {
    debug && console.debug('ClubFilters, getDepartments');

    setLoading(true);

    NewsAPI.getCommitteeByLeague(region.id).then((response) => {
      if (response && response.success) {
        callback(response.success, response.result);
      } else {
        callback(false, []);
      }
      setLoading(false);
    });
  };

  const onPressRegion = (region, regionIndex) => {
    debug && console.debug('ClubFilters, onPressRegion', region, regionIndex, regionsList);

    if (region.viewingSubItems === undefined) {
      getDepartments(region, regionIndex, (success, data) => {
        if (!success) return;
        debug && console.debug('ClubFilters, onPressRegion, got data:', success, data);

        setDepartmentsList({
          index: -1,
          departments: data,
        });
        setRegionsList({
          ...regionsList,
          index: regionIndex,
        });
      });
    } else {
      setRegionsList({
        ...regionsList,
        index: regionIndex,
      });
    }
  };

  const onSelectRegion = (selected, regionIndex) => {
    debug && console.debug('ClubFilters, onSelectRegion', selected, regionIndex);

    const foundIndex = selectedRegions.findIndex((item) => selected.id === item.id);
    if (foundIndex === -1) {
      setSelectedRegions([selected, ...selectedRegions]);
    } else {
      setSelectedRegions([...selectedRegions.slice(0, foundIndex), ...selectedRegions.slice(foundIndex + 1)]);
    }
  };

  const getClubs = (department, departmentIndex, callback = () => {}) => {
    debug && console.debug('ClubFilters, getClubs');

    setLoading(true);

    ClubsAPI.getAllClubsNoPageNoLimit('', department.id).then((response) => {
      if (response && response.success) {
        callback(response.success, response.result.clubs);
      } else {
        callback(false, []);
      }
      setLoading(false);
    });
  };

  const onPressDepartment = (department, departmentIndex) => {
    debug && console.debug('ClubFilters, onPressDepartment', department, departmentIndex);

    if (department.viewingItsClubs === undefined) {
      getClubs(department, departmentIndex, (success, data) => {
        if (!success) return;
        debug && console.debug('ClubFilters, onPressDepartment, got data:', success, data);

        setClubsList({
          index: -1,
          clubs: data,
        });
        setDepartmentsList({
          ...departmentsList,
          index: departmentIndex,
        });
      });
    } else {
      setDepartmentsList({
        ...departmentsList,
        index: departmentIndex,
      });
    }
  };

  const onSelectDepartment = (selected) => {
    debug && console.debug('ClubFilters, onSelectDepartment', selected);

    const foundIndex = selectedDepartments.findIndex((item) => selected.id === item.id);
    if (foundIndex === -1) {
      setSelectedDepartments([selected, ...selectedDepartments]);
    } else {
      setSelectedDepartments([
        ...selectedDepartments.slice(0, foundIndex),
        ...selectedDepartments.slice(foundIndex + 1),
      ]);
    }
  };

  /**
   * Get more information about a club to store in the Redux (select a club)
   * @param club
   */
  const onViewClub = (club) => {
    debug && console.debug('ClubFilters, onViewClub', club);

    if (!club) {
      return null;
    }

    setLoading(true);

    ClubsAPI.getClubDetail(tokens.wsToken, club.id).then((response) => {
      if (response && response.success) {
        debug && console.debug('ClubFilters, onViewClub, got club information:', response.result);
        dispatch(setFoundClub(response.result));
        setLoading(false);
        navigation.navigate('SpaceClub');
      } else if (response.success === false) {
        dispatch(setFoundClub(null));

        alert(I18n.t('app.oups'), 'Une erreur est survenue lors de sélection du club.');
      }
      setLoading(false);
    });
  };

  /**
   * Add a club to the list of selected clubs (choose favorites)
   * @param selected
   */
  const onSelectClub = (selected) => {
    debug && console.debug('ClubFilters, onSelectClub', selected);

    const foundIndex = selectedClubs.findIndex((item) => selected.id === item.id);
    if (foundIndex === -1) {
      setSelectedClubs([selected, ...selectedClubs]);
    } else {
      setSelectedClubs([...selectedClubs.slice(0, foundIndex), ...selectedClubs.slice(foundIndex + 1)]);
    }
  };

  /**
   * Choose a club for the user registration
   * @param selected
   */
  const onChooseClub = (selected) => {
    debug && console.debug('ClubFilters, onChooseClub', selected);

    TeamAPI.getTeamsList(null, selected.id).then((response) => {
      if (response?.success === true) {
        debug && console.debug('ClubFilters, got teams:', response.result.teams);

        dispatch({ type: ACCOUNT_CREATION_SET_SELECTED_CLUB, club: selected, teams: response.result.teams });
      }
    });

    navigation.goBack();
  };

  const handleSearch = (text) => {
    debug && console.debug('ClubFilters, searching...', text);

    if (!text) {
      removeSearch();
      return;
    }

    const found = [];
    searchableClubs.map((club) => {
      if (club.name.toLowerCase().includes(text.toLowerCase()) === true) {
        return found.push(club);
      }
    });

    debug && console.debug('ClubFilters, found:', found);
    setFoundClubs(found);
  };

  // handle back request when user is searching a club
  const removeSearch = () => {
    setFoundClubs([]);
  };

  const onBack = () => {
    debug && console.debug('ClubFilters, onBack');

    if (currentList === 'clubs') {
      setCurrentList('departments');
    }
    if (currentList === 'departments') {
      setCurrentList('regions');
    }
    if (currentList === 'regions') {
      navigation.goBack();
    }
  };

  if (!data) {
    return null;
  }

  return (
    <View style={{ flex: 1 }}>
      {/* header */}
      <HeaderBanner
        leftIcon="md-arrow-back"
        onLeftButton={() => {
          onBack();
        }}
        rightIcon="md-close"
        onRightButton={() => navigation.goBack()}
        title={title}
      />

      {/* buttons cancel and validate */}
      <View style={styles.buttonsBar}>
        <ButtonChoice
          disabled={!favorites.someExists}
          label="icon-refresh"
          style={styles.choiceButton}
          onPress={() => {
            // Clear all selected items
            setSelectedRegions([]);
            setSelectedDepartments([]);
            setSearchableClubs([]);

            // Clear all favorite items
            dispatch(razFavorites());
          }}
        />

        <SearchBar
          style={{ width: sw - 105 }}
          value=""
          buttonColor
          deleteButton
          placeholder={I18n.t('createAccount.searchClub')}
          onChangeText={(text) => handleSearch(text)}
          onDeleteButton={() => removeSearch()}
        />

        <ButtonChoice
          disabled={!favorites.someExists}
          label="icon-md-checkmark"
          style={styles.choiceButton}
          onPress={() => navigation.goBack()}
        />
      </View>

      {/* Do not find my club */}
      <View style={styles.buttonsBar}>
        <Pressable
          style={{ width: '100%', alignItems: 'center' }}
          onPress={() => {
            openMail(apiConfiguration.supportMail, 'Je ne trouve pas mon club');
          }}>
          <Text>{I18n.t('clubs.notAffiliated4', { appName: Constants.expoConfig.name })}.</Text>
        </Pressable>
      </View>

      <KeyboardAvoidingView
        style={{ flex: 1, marginTop: 10 }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        // keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 0}
        // contentContainerStyle={{ paddingTop: 20, backgroundColor: Colors.red}}
      >
        <View style={{ flex: 1, paddingBottom: 20 }}>
          {foundClubs.length > 0 && (
            <View style={{ flex: 1 }}>
              <FlatList
                data={foundClubs}
                keyExtractor={(item, index) => 'Found-' + index}
                renderItem={({ item, index }) => {
                  return (
                    <View key={'Found-' + index}>
                      <RowCard
                        index={index}
                        item={item}
                        selectable={selectable}
                        selectedItemsIds={favorites.clubsIds}
                        onPress={() => {
                          if (chooseClubOnSelect) {
                            onChooseClub(item);
                          } else if (viewClubOnSelect) {
                            onViewClub(item);
                          } else {
                            onSelectClub(item);
                          }
                        }}
                        onSelect={() => {
                          if (chooseClubOnSelect || viewClubOnSelect) {
                            onViewClub(item);
                          } else {
                            onSelectClub(item);
                          }
                        }}
                      />
                    </View>
                  );
                }}
              />
              <View style={{ borderBottomColor: Colors.blueBgGradient1, borderBottomWidth: 5 }} />
            </View>
          )}

          <FlatList
            style={{ flex: 1 }}
            data={data}
            ListEmptyComponent={
              <View style={{ width: '100%', alignItems: 'center', padding: 30 }}>
                {currentList === 'regions' && <Text style={styles.appFontRegular}>Aucune région ne correspond</Text>}
                {currentList === 'departments' && (
                  <Text style={styles.appFontRegular}>Aucun département ne correspond</Text>
                )}
                {currentList === 'clubs' && <Text style={styles.appFontRegular}>Aucun club ne correspond</Text>}
              </View>
            }
            renderItem={({ item, index, separators }) => {
              return (
                <View key={'Item-' + index}>
                  {currentList === 'regions' && (
                    <RowCard
                      index={index}
                      item={item}
                      rightArrow
                      selectable={selectable}
                      selectedItemsIds={favorites.regionsIds}
                      onPress={() => {
                        onPressRegion(item, index);
                      }}
                      onSelect={() => {
                        onSelectRegion(item, index);
                      }}
                    />
                  )}

                  {currentList === 'departments' && (
                    <RowCard
                      index={index}
                      item={item}
                      selectable={selectable}
                      rightArrow
                      selectedItemsIds={favorites.departmentsIds}
                      onPress={() => {
                        onPressDepartment(item, index);
                      }}
                      onSelect={() => {
                        onSelectDepartment(item);
                      }}
                    />
                  )}

                  {currentList === 'clubs' && (
                    <RowCard
                      index={index}
                      item={item}
                      selectable={selectable}
                      selectedItemsIds={favorites.clubsIds}
                      onPress={() => {
                        if (chooseClubOnSelect) {
                          onChooseClub(item);
                        } else if (viewClubOnSelect) {
                          onViewClub(item);
                        } else {
                          onSelectClub(item);
                        }
                      }}
                      onSelect={() => {
                        onSelectClub(item);
                      }}
                    />
                  )}
                </View>
              );
            }}
          />
          {/*<View style={{ height: 60 }} />*/}
        </View>
      </KeyboardAvoidingView>

      <Loading loading={loading} />
    </View>
  );
};

const styles = StyleSheet.create({
  appFontRegular: {
    fontFamily: 'appFontRegular',
  },

  buttonsBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 0,
    paddingHorizontal: 5,
    borderBottomColor: Colors.whiteCorporate,
    borderBottomWidth: 1,
  },
  choiceButton: { justifyContent: 'center', marginHorizontal: 5, width: 40 },
});

export default ClubFilters;
