import Colors from '@configs/colors';
import { ResizeMode, Video } from 'expo-av';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import ProgressBar from 'react-native-progress/Bar';

const { width: sw, height: sh } = Dimensions.get('screen');

export const VideoNoLoader = forwardRef(({ source, onClose = () => {} }, parentRef) => {
  const debug = false;

  const refVideo = useRef(null);
  useImperativeHandle(parentRef, () => ({
    play,
    unload,
    stop,
  }));

  const [loading, setLoading] = useState(true);
  const [buffering, setBuffering] = useState(false);
  const [status, setStatus] = useState({});

  useEffect(() => {
    debug && console.debug(`VideoNoLoader, media ${source.Actuality_id}/${source.id}: `, source);

    // When the component is unmounted...
    return () => unload();
  }, []);

  useEffect(() => {
    // debug && console.debug(`VideoNoLoader, status: `, status);

    if (status.didJustFinish) {
      debug && console.debug(`VideoNoLoader, finished ${source.Actuality_id}/${source.id}: `, status);
    }

    if (status.shouldPlay) {
      debug && console.debug(`VideoNoLoader, should play ${source.Actuality_id}/${source.id}: `, status);
    }

    // setBuffering(status.isBuffering);
  }, [status]);

  /**
   * Plays the video in the component if the ref
   * of the video is not null.
   *
   * @returns {void}
   */
  const play = async () => {
    if (refVideo.current === null) {
      return;
    }

    // if video is already playing return
    const status = await refVideo.current.getStatusAsync();
    debug && console.debug(`VideoNoLoader, media ${source.Actuality_id}/${source.id} status:`, status);
    if (status?.isPlaying) {
      return;
    }
    try {
      debug && console.debug(`VideoNoLoader, start playing ${source.Actuality_id}/${source.id}.`);
      await refVideo.current.playAsync();
    } catch (e) {
      console.log('VideoNoLoader, start playing error', e);
    }
  };

  /**
   * Stops the video in the component if the ref
   * of the video is not null.
   *
   * @returns {void}
   */
  const stop = async () => {
    if (refVideo.current === null) {
      return;
    }

    // if video is already stopped return
    const status = await refVideo.current.getStatusAsync();
    debug && console.debug(`VideoNoLoader, media ${source.Actuality_id}/${source.id} status:`, status);
    if (!status?.isPlaying) {
      return;
    }
    try {
      debug && console.debug(`VideoNoLoader, stop playing ${source.Actuality_id}/${source.id}.`);
      await refVideo.current.stopAsync();
    } catch (e) {
      console.log('VideoNoLoader, stop playing error', e);
    }
  };

  /**
   * Unloads the video in the component if the ref
   * of the video is not null.
   *
   * This will make sure unnecessary video instances are
   * not in memory at all times
   *
   * @returns {void}
   */
  const unload = async () => {
    debug && console.debug(`VideoNoLoader, unloading ${source.Actuality_id}/${source.id}.`);
    if (refVideo.current === null) {
      return;
    }

    // if video is already stopped return
    try {
      debug && console.debug(`VideoNoLoader, unloading ${source.Actuality_id}/${source.id}.`);
      await refVideo.current.unloadAsync();
    } catch (e) {
      console.log('VideoNoLoader, unloading error', e);
    }
  };

  if (!source) {
    return;
  }

  return (
    <>
      {/*<Text style={{ backgroundColor: Colors.red, height: 24, color: Colors.whiteCorporate }} />*/}

      {/*<Loading loading={status && status.isBuffering} color={Colors.red} />*/}
      {/*{buffering && (*/}
      {/*  <ActivityIndicator animating={status.isBuffering} size={25} color={Colors.whiteCorporate} />*/}
      {/*)}*/}

      <Video
        ref={refVideo}
        style={[styles.video, { height: source.height, width: source.width }, { marginRight: 10 }]}
        // pointerEvents="none"
        source={{ uri: source.uri }}
        // usePoster
        // posterSource={source.thumbnail ? source.thumbnail : require('@assets/images/spinner.gif') }
        // posterStyle={{
        //   alignSelf: 'center',
        //   alignItems: 'center',
        //   justifyContent: 'center',
        //   // paddingHorizontal: sw / 2 - 60,
        //   marginLeft: sw / 2 - 60,
        //   paddingVertical: sh / 2 - 60,
        //   width: 60,
        //   height: 60,
        // }}
        useNativeControls
        resizeMode={ResizeMode.CONTAIN}
        shouldPlay={false}
        isLooping={false}
        isMuted={false}
        onReadyForDisplay={(event) => {
          // debug && console.debug(`VideoNoLoader, ready for display ${source.Actuality_id}/${source.id}`, event);
          debug &&
            console.debug(
              `VideoNoLoader, loaded ${source.Actuality_id}/${source.id}, duration: ${Date.now() - source.timeStart}`
            );
          setLoading(false);
          // For the Web, must set the absolute position!
          if (event.srcElement) {
            event.srcElement.style.position = 'initial';
          }
          event.naturalSize &&
            debug &&
            console.debug(
              `VideoNoLoader, ready for display ${source.Actuality_id}/${source.id}. Size: ${event.naturalSize.width} x ${event.naturalSize.height}. Mode: ${event.naturalSize.orientation}`
            );
          setStatus(event.status);
        }}
        onPlaybackStatusUpdate={(status) => {
          // status: {"androidImplementation": "SimpleExoPlayer", "audioPan": 0, "didJustFinish": false,
          // "durationMillis": 20683, "isBuffering": true, "isLoaded": true, "isLooping": false, "isMuted": false,
          // "isPlaying": false, "playableDurationMillis": 4670, "positionMillis": 2618,
          // "progressUpdateIntervalMillis": 500, "rate": 1, "shouldCorrectPitch": false, "shouldPlay": true,
          // "uri": "/uploads//actuality/videos/132273263c018cf5d487fe1ab242ca11.mov", "volume": 1}
          // debug && console.debug(`VideoNoLoader, media ${source.Actuality_id}/${source.id} status:`, status);
          setStatus(status);
        }}
        onLoadStart={() => {
          debug && console.debug(`VideoNoLoader, loading media ${source.Actuality_id}/${source.id}...`);
          source.timeStart = Date.now();
          setLoading(true);
        }}
        onLoadEnd={() => {
          debug &&
            console.debug(
              `VideoNoLoader, loaded ${source.Actuality_id}/${source.id}, duration: ${Date.now() - source.timeStart}`
            );
          setLoading(false);
        }}
        onError={(error) => {
          console.warn(`VideoNoLoader, error:`, error);
          setLoading(false);
        }}
      />

      <View style={{ marginVertical: 5, marginHorizontal: 0 }}>
        {status && status.isLoaded && status.shouldPlay && (
          <ProgressBar
            width={sw - 20}
            height={5}
            color={Colors.blueCorporate}
            unfilledColor={Colors.whiteCorporate}
            borderWidth={0}
            progress={(status && status.positionMillis / status.durationMillis) || 0}
          />
        )}
      </View>
    </>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.grayWhite,
  },
  video: {
    alignSelf: 'center',
    width: sw - 20,
    height: sh - 170,
    borderRadius: 10,
  },
  videoControlContainer: {
    alignSelf: 'center',
    width: sw - 80,
    height: 60,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 5,
    marginHorizontal: 0,
    padding: 2,
    backgroundColor: Colors.red,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 0,
    padding: 0,
  },
  controlButton: {
    backgroundColor: Colors.grayWhite,
    color: Colors.blueCorporate,
    width: 60,
    height: 40,
    marginVertical: 0,
  },
  controlButtonLabel: {
    fontSize: 12,
    color: Colors.iconGray,
  },
});
