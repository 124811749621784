import ShadowCard from '@components/ShadowCard/ShadowCard';
import Colors from '@configs/colors';
import { GOOGLE } from '@configs/google';
import GoogleMapReact from 'google-map-react';
import React, { forwardRef, useEffect, useState } from 'react';
import { StyleSheet, View, Pressable, Text } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';

// const { width, height } = Dimensions.get('window');
// const ASPECT_RATIO = width / height;
// const LATITUDE_DELTA = 0.006339428281933124;
// const LONGITUDE_DELTA = LATITUDE_DELTA * ASPECT_RATIO;
const DEFAULT_LATITUDE = 48.85838629870182;
const DEFAULT_LONGITUDE = 2.294496946047386;
const DEFAULT_ZOOM = 16;

const Marker = ({ pinColor = Colors.red, title, description }) => {
  const [isPressed, setIsPressed] = useState(false);

  return (
    <div>
      <Pressable
        style={[styles.userCard]}
        onPress={() => {
          setIsPressed(!isPressed);
        }}
        onLongPress={() => {
          // this.props.onLongPress();
        }}>
        <Icon name="pin" size="32" color={pinColor} />
      </Pressable>
      {isPressed && (
        <ShadowCard style={[styles.mainContainer, { width: 120 }]}>
          <Text style={styles.markerTitle}>{title}</Text>
          <Text style={styles.markerDescription}>{description}</Text>
        </ShadowCard>
      )}
    </div>
  );
};

/**
 * Displays a Google map and forward its ref to the parent component for more advanced usage
 *
 *    return <MapViewer latitude, longitude, title, description />;
 *
 * @param address, object to make a map marker with
 *  latitude
 *  longitude
 *
 * @param markers, an array of markers objects (same as marker)
 *
 *
 */
const MapViewer = forwardRef(
  (
    { address = null, marker = null, markers = null, style = {}, onPress = () => {}, onLongPress = () => {} },
    refMap
  ) => {
    const debug = false;

    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);

    useEffect(() => {
      debug && console.debug('MapViewer updated GPS', refMap);
      debug && console.debug('MapViewer updated address', address);

      if (address && address.latitude && address.longitude) {
        setLat(address.latitude);
        setLng(address.longitude);

        debug && console.debug('MapViewer updated GPS with address', address.latitude, address.longitude, refMap);
      } else {
        setLat(DEFAULT_LATITUDE);
        setLng(DEFAULT_LONGITUDE);
      }
    }, []);

    useEffect(() => {
      debug && console.debug('MapViewer updated GPS', lat, lng);
    }, [lat, lng]);

    if (!lat || !lng) {
      return;
    }

    const handleApiLoaded = (map, maps) => {
      // use map and maps objects
    };

    const createMapOptions = (maps) => {
      // next props are exposed at maps
      // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
      // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
      // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
      // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
      // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
      return {
        zoomControl: false,
        zoomControlOptions: {
          position: maps.ControlPosition.RIGHT_CENTER,
          style: maps.ZoomControlStyle.SMALL,
        },
        mapTypeControl: false,
        mapTypeControlOptions: {
          position: maps.ControlPosition.TOP_RIGHT,
        },
        scrollwheel: true,
        gestureHandling: 'greedy', // Will capture all touch events on the map towards map panning
      };
      // return {
      //   panControl: false,
      //   mapTypeControl: false,
      //   scrollwheel: true,
      //   // styles: [{ stylers: [{ 'saturation': -100 }, { 'gamma': 0.8 }, { 'lightness': 4 }, { 'visibility': 'on' }] }]
      // };
    };

    return (
      <View style={[styles.block, style]}>
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{ key: GOOGLE.API_KEY, language: 'fr', region: 'fr' }}
          center={{ lat, lng }}
          defaultCenter={{ lat: DEFAULT_LATITUDE, lng: DEFAULT_LONGITUDE }}
          defaultZoom={DEFAULT_ZOOM}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          options={createMapOptions}
          onClick={({ x, y, lat, lng, event }) => {
            debug && console.debug('MapViewer click', lat, lng, event);
            onPress(lat, lng);
          }}>
          {marker && (
            <Marker
              identifier="mk-position"
              pinColor={Colors.red}
              coordinate={{
                latitude: marker.latitude,
                longitude: marker.longitude,
              }}
              title={marker.title ?? marker.name}
              description={marker.address ?? marker.description}
              lat={address.latitude}
              lng={address.longitude}
            />
            //
            // <Marker
            //   lat={address.latitude}
            //   lng={address.longitude}
            //   text={address.title ?? address.name}
            //   description={address.description}
            // />
          )}
          {address && address.latitude && address.longitude && (
            <Marker
              lat={address.latitude}
              lng={address.longitude}
              text={address.title ?? address.name}
              description={address.description}
            />
          )}

          {markers &&
            markers.map((marker, index) => {
              return (
                <Marker
                  identifier={marker.id}
                  key={`Marker-${index}`}
                  lat={marker.latitude}
                  lng={marker.longitude}
                  text={marker.title ?? marker.name}
                  description={marker.description}
                />
              );
            })}
        </GoogleMapReact>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  block: {
    padding: 20,
    paddingTop: 10,
    borderRadius: 10,
  },
  map: {
    width: '100%',
    height: 300,
  },
  markerTitle: {
    fontStyle: 'appFontMedium',
    fontSize: 16,
  },
  markerDescription: {
    fontStyle: 'appFontRegular',
    fontSize: 12,
  },
});

export default MapViewer;
