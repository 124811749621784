import { DeviceAPI } from '@apis/device';
import Colors from '@configs/colors';
import alert from '@utils/alert';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import Constants from "expo-constants";

/**
 * Function to register user token to server
 * ---
 * This is called only once when the user is signing in on the device!
 */
export async function registerForPushNotificationsAsync(userToken) {
  let token;

  // Register channel on android device
  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('channel-e-appli', {
      name: 'Main e-Appli channel',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: Colors.redCorporate,
    });
  }

  await Device.getDeviceTypeAsync();

  // Register expo push token - not for the Web because it needs some modifications to work correctly - TBD later
  if (Platform.OS === 'android' || Platform.OS === 'ios') {
    if (Device.isDevice) {
      // console.debug('I am on a physical device');
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Veuillez activer les notifications push pour recevoir toutes les actualités');
        return;
      }
      // console.debug('Notifications permission is granted');
      token = await Notifications.getExpoPushTokenAsync({
        projectId: Constants.expoConfig.extra.eas.projectId,
      });
      // console.debug(`Expo push token is: `, token);
      token = token.data;
      await DeviceAPI.registerTempPushToken(token);
      await DeviceAPI.registerDevice(token, userToken);
    } else {
      alert('Must use physical device for Push Notifications');
    }
  }

  return token;
}
