import defaultBanner from '@assets/banner.png';
import { HEADER_ICONS_HEIGHT } from '@components/HeaderCollapsible/HeaderCollapsible';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import { useIsFocused } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Dimensions, ImageBackground, Platform, Pressable, StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { useSelector } from 'react-redux';

const { width: sw } = Dimensions.get('window');

/**
 *
 * @param navigation, react navigator instance
 * @param leftIcon
 * @param rightIcon
 * @param onLeftButton
 * @param onRightButton
 * @returns {JSX.Element}
 * @constructor
 */
const HeaderBanner = ({
  title = null,
  leftIcon = 'md-arrow-back',
  rightIcon = null,
  onTitlePress = () => {},
  onLeftButton = () => {},
  onRightButton = () => {},
}) => {
  const debug = false;

  const isFocused = useIsFocused();

  const [currentClub, setCurrentClub] = useState(null);

  const { session } = useSelector((state) => state.session);
  const { affiliatedClubInformation } = useSelector((state) => state.session);

  const [headerTitle, setHeaderTitle] = useState(title);
  const [headerBanner, setHeaderBanner] = useState(defaultBanner);

  // const [activeColor, setActiveColor] = useState(Colors.redCorporate);
  const [inactiveColor, setInactiveColor] = useState(Colors.whiteCorporate);
  const [backgroundColor, setBackgroundColor] = useState(Colors.blueCorporate);

  const [leftPressed, setLeftPressed] = useState(false);
  const [rightPressed, setRightPressed] = useState(false);

  useEffect(() => {
    debug && console.debug('HeaderBanner focused:', isFocused);
    if (isFocused) {
      setLeftPressed(false);
      setRightPressed(false);
    }
  }, [isFocused]);

  useEffect(() => {
    debug && console.debug(`HeaderBanner, affiliatedClubInformation`, affiliatedClubInformation);
    setCurrentClub(affiliatedClubInformation ?? session?.club ?? { name: 'Guest club' });
  }, [affiliatedClubInformation]);

  useEffect(() => {
    debug && console.debug(`HeaderBanner, club information changed:`, affiliatedClubInformation);
    debug && console.debug(`HeaderBanner, session information changed:`, session.club);
    const club = affiliatedClubInformation ?? session?.club ?? null;

    if (!club) {
      setHeaderBanner(defaultBanner);

      // setActiveColor(Colors.redCorporate);
      setInactiveColor(Colors.whiteCorporate);
      setBackgroundColor(Colors.blueCorporate);
    } else {
      setHeaderTitle(title || club.name);
      setHeaderBanner(club.bannerImage ? { uri: getImageApiHostOrUrl(club.bannerImage) } : defaultBanner);

      // setActiveColor(club.activeIconColor);
      setInactiveColor(club.inactiveIconColor);
      setBackgroundColor(club.mainColorBottom);
    }
  }, [affiliatedClubInformation, session]);

  useEffect(() => {
    if (!title) return;

    debug && console.debug(`HeaderBanner, title changed:`, title);
    setHeaderTitle(title);
  }, [title]);

  if (!currentClub) {
    return null;
  }

  return (
    <View style={styles.mainContainer}>
      <ImageBackground source={headerBanner} imageStyle={{ resizeMode: 'cover' }} style={styles.headerBanner}>
        <View style={[styles.headerTransparentBackground, { backgroundColor: backgroundColor + 'cc' }]}>
          <Pressable style={styles.headerIconsAndText} onPress={() => onTitlePress()}>
            {leftIcon ? (
              <Pressable
                disabled={leftPressed}
                style={({ pressed }) => [styles.headerIconLeft, pressed ? { opacity: 0.7 } : {}]}
                onPress={() => {
                  setLeftPressed(true);
                  onLeftButton();
                }}>
                <Icon size={32} name={leftIcon} color={inactiveColor} />
              </Pressable>
            ) : (
              <View style={[styles.headerIconLeft]} />
            )}

            <Text
              style={[
                styles.headerText,
                {
                  color: inactiveColor,
                  width: sw - (leftIcon ? 50 : 0) - (rightIcon ? 50 : 0),
                },
              ]}
              numberOfLines={1}
              ellipsizeMode="tail">
              {headerTitle}
            </Text>

            {rightIcon && (
              <Pressable
                disabled={rightPressed}
                style={({ pressed }) => [styles.headerIconRight, pressed ? { opacity: 0.7 } : {}]}
                onPress={() => {
                  setRightPressed(true);
                  onRightButton();
                }}>
                <Icon size={32} name={rightIcon} color={inactiveColor} />
              </Pressable>
            )}
          </Pressable>
        </View>
      </ImageBackground>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    height: Platform.OS === 'ios' ? HEADER_ICONS_HEIGHT + 20 : HEADER_ICONS_HEIGHT,
  },

  header: {
    flex: 1,
    position: 'absolute',
    left: 0,
    right: 0,
  },
  headerBanner: {
    flex: 1,
    width: '100%',
    height: '100%',
  },

  headerTransparentBackground: {
    width: '100%',
    height: Platform.OS === 'ios' ? HEADER_ICONS_HEIGHT + 20 : HEADER_ICONS_HEIGHT,
    position: 'absolute',
    top: 0,
    left: 0,
    paddingTop: Platform.OS === 'ios' ? 20 : 0,
  },
  headerIconsAndText: {
    width: '100%',
    height: 60,
    // padding: 10,
    paddingTop: Platform.OS === 'ios' ? 10 : 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerIconLeft: {
    width: 40,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerIconRight: {
    width: 40,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    width: sw - 100,
    fontFamily: 'appFontRegular',
    fontSize: 18,
    color: Colors.blueCorporate,
    textAlign: 'center',
    alignSelf: 'center',
  },
});

export default HeaderBanner;
