import { AccountAPI } from '@apis/account';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import ButtonCancel from '@components/ButtonCancel/ButtonCancel';
import ButtonSwitch from '@components/ButtonSwitch/ButtonSwitch';
import ButtonValidate from '@components/ButtonValidate/ButtonValidate';
import GradientBackground from '@components/GradientBackground/GradientBackground';
import Loading from '@components/Loading/Loading';
import Colors from '@configs/colors';
import * as actions from '@redux/actions';
import { SET_CONST_PROFILES_TYPES, ACCOUNT_CREATION_SET_NEW_USER } from '@redux/storeReducer';
import I18n from '@utils/i18n';
import React, { useEffect, useState } from 'react';
import { Dimensions, ScrollView, StyleSheet, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

const { width: sw, height: sh } = Dimensions.get('window');

/**
 * Screen used to choose user profile and register new affiliation
 * @returns // screen
 */
const ChooseProfile = ({ navigation, route }) => {
  const debug = true;

  const [initialized, setInitialized] = useState(false);
  const [existingProfile, setExistingProfile] = useState(false);
  const [signingUp, setSigningUp] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertButtons, setAlertButtons] = useState(null);

  const { constProfilesTypes, newUser } = useSelector((state) => state.app);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [clubInfo, setClubInfo] = useState(null);
  const [choiceHeight, setChoiceHeight] = useState(50);

  const { tokens } = useSelector((state) => state.app);
  const { session, foundOtherClub } = useSelector((state) => state.session);

  const dispatch = useDispatch();

  useEffect(() => {
    debug && console.debug('ChooseProfile, initial configuration:', route.params);
    if (route.params && route.params.profiles) {
      setExistingProfile(route.params.fromProfile ?? false);
      setSigningUp(route.params.signingUp ?? false);
      setSelectedProfiles(route.params.profiles);
    }
  }, []);

  useEffect(() => {
    debug && console.debug('ChooseProfile, selected profiles:', selectedProfiles);
    getAllProfilTypes();
  }, [selectedProfiles]);

  /**
   * Used to configure a new club we want to affiliate with
   */
  useEffect(() => {
    if (foundOtherClub) {
      debug && console.debug('ChooseProfile, set club info:', foundOtherClub);

      setClubInfo({
        id: foundOtherClub.id,
        logo: foundOtherClub.logo,
        name: foundOtherClub.name,
        isAffiliated: foundOtherClub.isAffiliated,
        mainColorBottom: foundOtherClub.mainColorBottom,
        activeIconColor: foundOtherClub.activeIconColor,
        inactiveIconColor: foundOtherClub.inactiveIconColor,
        bannerImage: foundOtherClub.bannerImage,
      });
    }
  }, [foundOtherClub]);

  /**
   * Get/update all possible types of profile
   */
  const getAllProfilTypes = () => {
    debug && console.debug('ChooseProfile, getAllProfilTypes');
    // Update the Redux constant profiles list
    AccountAPI.getAllProfileTypes().then((response) => {
      if (response && response.success) {
        dispatch({ type: SET_CONST_PROFILES_TYPES, value: response.result });
      }

      // Auto adjust buttons height to fit the screen height
      if (response.result.length > 0) {
        setChoiceHeight((sh - 350) / response.result.length);
      }
      debug && console.debug('ChooseProfile, getAllProfilTypes, got ', response.result.length);
      setInitialized(true);
    });
  };

  const updateSelected = (profileType, select) => {
    debug && console.debug('ChooseProfile, updateSelected:', select, profileType);
    if (!select) {
      const foundIndex = selectedProfiles.findIndex((item) => item.id === profileType.id);
      if (foundIndex > -1) {
        debug && console.debug('ChooseProfile, updateSelected:', foundIndex);
        setSelectedProfiles((profiles) => [...profiles.slice(0, foundIndex), ...profiles.slice(foundIndex + 1)]);
      }
    } else {
      setSelectedProfiles((profiles) => [...profiles, profileType]);
    }
  };

  const checkSelected = (profileType) => {
    return undefined !== selectedProfiles.find((item) => profileType.id === item.id);
  };

  const addAffiliation = () => {
    debug && console.debug('ChooseProfile, validated', selectedProfiles);

    if (session && clubInfo && session?.club.id !== clubInfo.id && selectedProfiles.length > 0 && loading === false) {
      debug && console.debug('ChooseProfile, affiliating a new club...');
      // We are affiliating a new club ...
      setLoading(true);
      const selectedPTypeId = selectedProfiles.map((profile) => Number(profile.id));
      const wsToken = tokens && (tokens.wsToken ?? null);

      const clubInformation = {
        ...clubInfo,
        userProfileIds: selectedPTypeId,
        userRoleIds: [],
      };
      dispatch(
        actions.addAffiliateClub(wsToken, clubInformation, (callback) => {
          setLoading(false);
          if (callback && callback.success === true) {
            navigation.navigate('HomeStack', { screen: 'Home', params: { affiliatedClub: clubInfo } });
          } else {
            setAlertTitle(I18n.t('app.oups'));
            setAlertMessage('Problème de communication avec le serveur. Veuillez réessayer ultérieurement.');
            setAlertButtons([
              {
                text: 'ok',
                onPress: () => {},
              },
            ]);
            setShowAlert(true);
          }
        })
      );
    } else if (selectedProfiles.length === 0) {
      // No profiles ...
      setAlertTitle(I18n.t('app.oups'));
      setAlertMessage('Vous devez sélectionner au moins un profil.');
      setAlertButtons([
        {
          text: 'ok',
          onPress: () => {},
        },
      ]);
      setShowAlert(true);
    } else {
      debug && console.debug('ChooseProfile, storing profiles in Redux...');
      // Some profiles selected, store in the Redux...
      dispatch({
        type: ACCOUNT_CREATION_SET_NEW_USER,
        value: { ...newUser, profileTypes: selectedProfiles },
      });
      if (signingUp) {
        navigation.navigate('SignUpNext', { params: { profiles: selectedProfiles } });
      } else {
        navigation.navigate('ProfileStack', { screen: 'Profile', params: { profiles: selectedProfiles } });
      }
    }
  };

  if (!initialized) {
    return;
  }

  return (
    <>
      <GradientBackground gradient>
        <View style={[styles.rootContainer]}>
          <Text style={styles.pageTitle}>{I18n.t('createAccount.chooseRoleInClub')}</Text>

          <ScrollView
            style={{ width: '90%' }}
            contentContainerStyle={{ paddingVertical: 0, alignItems: 'center' }}
            showsVerticalScrollIndicator>
            {constProfilesTypes.map((profileType, index) => {
              return (
                <ButtonSwitch
                  key={'PT-' + index}
                  style={{ height: choiceHeight }}
                  switchStyle={{ height: choiceHeight / 2 }}
                  label={profileType.label ?? profileType.name ?? 'Erreur'}
                  onPress={(isChecked) => {
                    updateSelected(profileType, isChecked);
                  }}
                  isSelected={checkSelected(profileType) === true}
                />
              );
            })}
          </ScrollView>

          <View style={[styles.buttonsContainer]}>
            <ButtonCancel onPress={() => navigation.goBack()} style={{ width: 110 }} />
            <ButtonValidate
              style={{ width: 110 }}
              label={I18n.t('createAccount.profileSelect')}
              onPress={() => addAffiliation()}
            />
          </View>
        </View>
      </GradientBackground>

      <Loading loading={loading} />

      <AlerteUI
        showAlert={showAlert}
        onClose={() => {
          setShowAlert(false);
        }}
        title={alertTitle}
        message={alertMessage}
        buttons={alertButtons}
      />
    </>
  );
};

const styles = StyleSheet.create({
  rootContainer: {
    alignItems: 'center',
  },
  pageTitle: {
    width: sw - 40,
    fontWeight: 'bold',
    fontSize: 24,
    color: Colors.blueCorporate,

    textAlign: 'center',

    marginTop: 40,
    marginBottom: 20,
  },

  shadowEffect: {
    marginBottom: 15,

    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },

  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: sw - 120,
    paddingVertical: 5,
  },
});

export default ChooseProfile;
