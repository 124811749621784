import AlerteUI from '@components/AlerteUI/AlerteUI';
import BottomUpSheet from '@components/BottomUpSheet/BottomUpSheet';
import ButtonBase from '@components/ButtonBase/ButtonBase';
import ButtonValidate from '@components/ButtonValidate/ButtonValidate';
import MediaViewer from '@components/MediaViewer/MediaViewer';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import { Picker } from '@react-native-picker/picker';
import I18n from '@utils/i18n';
import React, { Component } from 'react';
import {
  Dimensions,
  KeyboardAvoidingView,
  Modal,
  Platform,
  Pressable,
  SafeAreaView,
  ScrollView,
  Text,
  TextInput,
  TouchableWithoutFeedback,
  View,
} from 'react-native';

import Order from './Order';

const { width: sw, height: sh } = Dimensions.get('window');

class ArticleSingle extends Component {
  debug = false;
  constructor(props) {
    super(props);

    this.state = {
      product: null,
      images: [],
      showImageViewer: false,
      selectedImageIndex: 0,
      selectedSize:
        this.props.article.size !== undefined && this.props.article.size !== null ? this.props.article.size[0] : 'M',
      showIosPicker: false,
      quantity: 0,
      showOrder: false,
      orderComment: '',

      showAlert: false,
      alertTitle: '',
      alertMessage: '',
      alertButtons: null,
    };
  }

  async componentDidMount() {
    if (this.props.articleRef) {
      this.props.articleRef(this);
    }
    this.setImages(this.props.article);
  }

  setImages = (item) => {
    const images = [];
    if (item.imageFilename) {
      images.push({
        id: 0,
        type: 'picture',
        height: sh / 3,
        width: sw,
        uri: getImageApiHostOrUrl(item.imageFilename),
      });
    }

    let id = 0;
    for (const image of item.images) {
      if (image.filename !== undefined && image.filename !== null) {
        images.push({
          id: ++id,
          type: 'picture',
          height: sh / 3,
          width: sw,
          uri: getImageApiHostOrUrl(image.filename),
        });
      }
    }
    this.debug && console.debug('ArticleSingle, images', images);

    this.setState({ product: this.props.article, images });
  };
  open = () => {
    this.bottomUp.current.open();
    if (this.props.article.size && this.props.article.size.length > 0) {
      this.setState({
        selectedSize: this.props.article.size[0],
      });
    }
  };

  close = () => {
    this.bottomUp.current.close();
  };

  createOrder = () => {
    if (this.state.selectedSize !== '' && this.state.quantity > 0) {
      this.orderRef.open();
    } else if (this.state.selectedSize === '') {
      this.setState({
        loading: false,
        alertTitle: I18n.t('app.oups'),
        alertMessage: I18n.t('app.noSize'),
        alertButtons: [],
        showAlert: true,
      });
    } else if (this.state.quantity <= 0) {
      this.setState({
        loading: false,
        alertTitle: I18n.t('app.oups'),
        alertMessage: I18n.t('app.noQuantity'),
        alertButtons: [],
        showAlert: true,
      });
    }
  };

  render() {
    if (!this.state.product) {
      return null;
    }

    this.debug && console.debug('ArticleSingle, render', this.state);

    return (
      <>
        <BottomUpSheet
          onPopupRef={(ref) => {
            this.bottomUp = ref;
          }}
          onClose={() => {
            if (this.props.onClose) {
              this.props.onClose();
            }
            this.setState({ quantity: 0, orderComment: '' });
          }}
          onOpen={() => {
            this.setState({ comment: this.props.comment });
          }}>
          <ScrollView contentContainerStyle={{ alignItems: 'center', paddingBottom: 10 }}>
            <View style={{ width: sw }}>
              <Text
                style={{
                  color: Colors.blueCorporate,
                  fontFamily: 'appFontMedium',
                  fontSize: 16,
                  marginHorizontal: 20,
                }}>
                {this.state.product.name}
              </Text>

              <Text style={{ color: Colors.red, fontFamily: 'appFontRegular', fontSize: 12, marginHorizontal: 20 }}>
                {this.state.product.reference}
              </Text>

              {/* Product images */}
              <MediaViewer actualityId={this.state.product.id} medias={this.state.images} />

              {/* Product characteristics */}
              <View style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Text style={{ color: Colors.red, fontFamily: 'appFontMedium', marginTop: 20 }}>
                  {I18n.t('createActu.description')}
                </Text>
                <Text style={{ color: Colors.blueCorporate, fontFamily: 'appFontRegular', opacity: 0.8 }}>
                  {this.state.product.description}
                </Text>

                {/* Product size */}
                <Text style={{ color: Colors.red, fontFamily: 'appFontMedium', marginTop: 15 }}>
                  {I18n.t('shop.size')}
                </Text>
                {Platform.OS !== 'ios' && (
                  <View style={{ width: '100%', borderRadius: 100, backgroundColor: Colors.whiteCorporate }}>
                    <Picker
                      style={{ color: Colors.blueCorporate, fontFamily: 'appFontRegular', fontSize: 10 }}
                      mode="dropdown"
                      selectedValue={this.state.selectedSize}
                      onValueChange={(value) => this.setState({ selectedSize: value })}>
                      {this.state.product.size.map((size, indexSize) => {
                        return <Picker.Item key={indexSize} label={size} value={size} />;
                      })}
                    </Picker>
                  </View>
                )}
                {Platform.OS === 'ios' && (
                  <Pressable
                    style={{
                      width: '100%',
                      height: 32,
                      backgroundColor: Colors.whiteCorporate,
                      borderRadius: 100,
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      paddingLeft: 15,
                      paddingRight: 15,
                    }}
                    onPress={() => {
                      this.debug && console.debug('ArticleSingle, view iOS picker');
                      this.setState({ showIosPicker: true });
                    }}>
                    <Text
                      style={{
                        color: Colors.blueCorporate,
                        fontFamily: 'appFontMedium',
                        fontSize: 14,
                        marginTop: 3,
                      }}>
                      {this.state.selectedSize}
                    </Text>
                  </Pressable>
                )}

                {/* Product quantity / price */}
                <View style={{ width: '100%', marginTop: 20, marginBottom: 20 }}>
                  <View style={{ width: '100%', alignItems: 'center' }}>
                    <Text style={{ color: Colors.red, fontFamily: 'appFontMedium' }}>{I18n.t('shop.quantity')}</Text>
                  </View>
                  <View
                    style={{
                      width: sw - 20,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <ButtonBase
                      label="icon-remove"
                      style={{ marginRight: 10 }}
                      onPress={() => {
                        if (this.state.quantity > 0) {
                          this.setState({
                            quantity: this.state.quantity - 1,
                          });
                        }
                      }}
                    />
                    <View
                      style={{
                        width: 80,
                        backgroundColor: Colors.whiteCorporate,
                        borderRadius: 100,
                        height: 24,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Text
                        style={{
                          height: 24,
                          color: Colors.blueCorporate,
                          fontFamily: 'appFontRegular',
                          textAlign: 'center',
                          textAlignVertical: 'center',
                          marginTop: Platform.OS === 'ios' ? 10 : 0,
                        }}>
                        {this.state.quantity}
                      </Text>
                    </View>

                    <ButtonBase
                      label="icon-add"
                      style={{ marginLeft: 10 }}
                      onPress={() => {
                        this.setState({ quantity: this.state.quantity + 1 });
                      }}
                    />
                  </View>
                  <View style={{ width: '100%', alignItems: 'center' }}>
                    <Text style={{ color: Colors.red, fontFamily: 'appFontMedium' }}>{I18n.t('shop.totalPrice')}</Text>
                  </View>

                  <View
                    style={{
                      width: 150,
                      borderWidth: 0.5,
                      borderRadius: 100,
                      borderColor: Colors.blueCorporate,
                      padding: 7,
                      alignSelf: 'center',
                      alignItems: 'center',
                      marginTop: 5,
                    }}>
                    <Text
                      style={{
                        color: Colors.blueCorporate,
                        fontFamily: 'appFontMedium',
                      }}>
                      {this.state.quantity * this.state.product.price + ' €'}
                    </Text>
                  </View>
                </View>

                {/* Product comments */}
                <KeyboardAvoidingView
                  enabled={false}
                  behavior={Platform.OS === 'ios' ? 'padding' : 'position'}
                  contentContainerStyle={{ marginBottom: 20 }}
                  keyboardVerticalOffset={Platform.OS === 'ios' ? 50 : 120}>
                  <TextInput
                    style={{
                      width: '100%',
                      borderRadius: 100,
                      backgroundColor: Colors.whiteCorporate,
                      marginBottom: 20,
                      height: 32,
                      paddingLeft: 15,
                      paddingRight: 15,
                    }}
                    placeholder={I18n.t('shop.comment')}
                    onChangeText={(text) => {
                      this.setState({ orderComment: text });
                    }}
                    value={this.state.orderComment}
                  />
                </KeyboardAvoidingView>

                <ButtonValidate
                  style={{ width: '60%', alignSelf: 'center' }}
                  onPress={() => this.createOrder()}
                  label={I18n.t('shop.order')}
                />
              </View>
            </View>
          </ScrollView>

          {Platform.OS === 'ios' && (
            <Modal
              visible={this.state.showIosPicker}
              transparent
              onRequestClose={() => {
                this.setState({ showIosPicker: false });
              }}>
              <SafeAreaView style={{ flex: 1 }}>
                <TouchableWithoutFeedback accessible>
                  <View
                    style={{
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: Colors.black + 'A5',
                    }}>
                    <View style={{ backgroundColor: Colors.whiteCorporate, borderRadius: 10, alignItems: 'center' }}>
                      <Picker
                        style={{ height: 200, width: sw - 40 }}
                        mode="dropdown"
                        selectedValue={this.state.selectedSize}
                        onValueChange={(value) => {
                          this.setState({ selectedSize: value });
                        }}>
                        {this.state.product.size.map((size, indexSize) => {
                          return <Picker.Item key={indexSize} label={size} value={size} />;
                        })}
                      </Picker>

                      <ButtonBase
                        style={{ width: '100%' }}
                        label="Choisir"
                        onPress={() => this.setState({ showIosPicker: false })}
                      />
                    </View>
                  </View>
                </TouchableWithoutFeedback>
              </SafeAreaView>
            </Modal>
          )}

          <Order
            orderRef={(ref) => {
              this.orderRef = ref;
            }}
            close={() => {
              this.close();
            }}
            product={this.state.product}
            size={this.state.selectedSize}
            quantity={this.state.quantity}
            comment={this.state.orderComment}
          />

          <AlerteUI
            showAlert={this.state.showAlert}
            onClose={() => {
              this.setState({ showAlert: false });
            }}
            title={this.state.alertTitle}
            message={this.state.alertMessage}
            buttons={this.state.alertButtons}
          />
        </BottomUpSheet>
      </>
    );
  }
}

export default ArticleSingle;
