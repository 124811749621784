import { ShopAPI } from '@apis/shop';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import BottomUpSheet from '@components/BottomUpSheet/BottomUpSheet';
import ButtonCancel from '@components/ButtonCancel/ButtonCancel';
import ButtonValidate from '@components/ButtonValidate/ButtonValidate';
import Loading from '@components/Loading/Loading';
import Colors from '@configs/colors';
import { GOOGLE } from '@configs/google';
import I18n from '@utils/i18n';
import React, { Component } from 'react';
import {
  Dimensions,
  Keyboard,
  Modal,
  Platform,
  Pressable,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { connect } from 'react-redux';

import styles from './styles';

const { width: sw, height: sh } = Dimensions.get('window');

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSize: 'L',
      quantity: 0,
      showCongratulation: false,

      firstName: '',
      lastName: '',
      address: '',
      postalCode: '',
      isPostalCode: true,
      city: '',
      phoneNumber: '',
      isPhoneNumber: true,
      comment: props.comment,

      title: '',
      message: '',

      autoCompleteResult: [],
      yourCityComplete: '',
      viewAutocomplete: false,
      showActivityIndicator: false,
    };
  }

  async componentDidMount() {
    if (this.props.orderRef) {
      this.props.orderRef(this);
    }
  }

  open = () => {
    this.bottomUp.current.open();
  };

  close = () => {
    this.bottomUp.current.close();
  };

  _onSendCommand = async () => {
    const firstName = this.state.firstName;
    const lastName = this.state.lastName;
    const address = this.state.address;
    const postalCode = this.state.postalCode;
    const city = this.state.city;
    // const phoneNumber = this.state.phoneNumber;
    // const comment = this.state.comment;
    if (firstName === '' || lastName === '' || address === '' || postalCode === '' || city === '') {
      if (firstName === '') {
        this.setState({
          title: I18n.t('shop.congratulateTitleErreur'),
          message: I18n.t('shop.enterFirstName'),
          showCongratulation: true,
        });
        this.firstName.focus();
      } else if (lastName === '') {
        this.setState({
          title: I18n.t('shop.congratulateTitleErreur'),
          message: I18n.t('shop.enterLastName'),
          showCongratulation: true,
        });
        this.lastName.focus();
      } else if (address === '') {
        this.setState({
          title: I18n.t('shop.congratulateTitleErreur'),
          message: I18n.t('shop.enterAdresse'),
          showCongratulation: true,
        });
        this.address.focus();
      } else if (postalCode === '') {
        this.setState({
          title: I18n.t('shop.congratulateTitleErreur'),
          message: I18n.t('shop.enterPostalCode'),
          showCongratulation: true,
        });
        this.postalCode.focus();
      } else if (city === '') {
        this.setState({
          title: I18n.t('shop.congratulateTitleErreur'),
          message: I18n.t('shop.enterCity'),
          showCongratulation: true,
        });
      }
    } else {
      if (this.state.isPostalCode) {
        this.setState({
          title: I18n.t('shop.congratulateTitleErreur'),
          message: I18n.t('shop.invalidePostalCode'),
          showCongratulation: true,
        });
      } else {
        this.setState({ showActivityIndicator: true });
        const order = await ShopAPI.orderProduct(
          this.props.tokens.wsToken,
          this.props.product.id,
          this.props.quantity,
          this.props.size,

          this.state.lastName,
          this.state.firstName,
          this.props.session.user.email,
          this.state.address,
          this.state.postalCode,
          this.state.city,
          this.state.phoneNumber,
          this.state.comment,

          this.state.lastName,
          this.state.firstName,
          this.props.session.user.email,
          this.state.address,
          this.state.postalCode,
          this.state.city,
          this.state.phoneNumber,
          this.state.comment
        );

        if (order && order.success) {
          this.setState({
            title: I18n.t('shop.congratulateTitle'),
            message: I18n.t('shop.congratulateText'),
            firstName: '',
            lastName: '',
            address: '',
            postalCode: '',
            city: '',
            phoneNumber: '',
            comment: '',
            showCongratulation: true,
            showActivityIndicator: false,
          });
        } else if (order && !order.success) {
          if (order.error_code === 1201) {
            this.setState({
              title: I18n.t('shop.shopFailedTitle'),
              message: I18n.t('shop.shopError1201'),
              showCongratulation: true,
              showActivityIndicator: false,
            });
          } else if (order.error_code === 1) {
            this.setState({
              title: I18n.t('shop.shopFailedTitle'),
              message: I18n.t('shop.shopError1'),
              showCongratulation: true,
              showActivityIndicator: false,
            });
          } else if (order.error_code === 1701) {
            this.setState({
              title: I18n.t('shop.shopFailedTitle'),
              message: I18n.t('shop.shopError1701'),
              showCongratulation: true,
              showActivityIndicator: false,
            });
          } else if (order.error_code === 1801) {
            this.setState({
              title: I18n.t('shop.shopFailedTitle'),
              message: I18n.t('shop.shopError1801'),
              showCongratulation: true,
              showActivityIndicator: false,
            });
          } else if (order.error_code === 1802) {
            this.setState({
              title: I18n.t('shop.shopFailedTitle'),
              message: I18n.t('shop.shopError1802'),
              showCongratulation: true,
              showActivityIndicator: false,
            });
          } else {
            this.setState({
              title: I18n.t('shop.shopFailedTitle'),
              message: I18n.t('shop.shopFailedText'),
              showCongratulation: true,
              showActivityIndicator: false,
            });
          }
        } else if (order.error_code === 10) {
          console.warn('Session expired!');
          // session expired redirect to Login
        } else {
          // TODO
        }
      }
    }
  };

  _requestAutoComplete = async (text) => {
    const url = `${GOOGLE.API_AUTOCOMPLETE_URL}/json?key=${GOOGLE.API_KEY}&input=${text}&types=(cities)`;
    const prediction1 = await (
      await fetch(url, {
        method: 'GET',
      })
    ).json();

    const allPrediction = [];
    if (prediction1.predictions && prediction1.predictions.length > 0) {
      allPrediction.splice(allPrediction.length - 1, 0, ...prediction1.predictions);
    }
    this.setState({ autoCompleteResult: allPrediction });
  };

  render() {
    return (
      <BottomUpSheet
        onPopupRef={(ref) => {
          this.bottomUp = ref;
        }}
        onClose={() => {}}
        onOpen={() => {
          this.setState({ comment: this.props.comment });
        }}>
        {/* <KeyboardAvoidingView style={{ flex: 1 }} enabled behavior={"position"} keyboardVerticalOffset={(Platform.OS == "android" ? -190 : 0)}> */}
        <ScrollView
          ref={(ref) => {
            this.scrollView = ref;
          }}
          contentContainerStyle={{
            alignItems: 'center',
          }}>
          <Pressable
            style={{ flex: 1 }}
            accessible={false}
            onPress={() => {
              Keyboard.dismiss();
            }}>
            {/* <KeyboardAvoidingView> */}
            <View
              style={{
                width: '100%',
                alignItems: 'center',
              }}>
              <View style={{ width: sw, padding: 20, paddingTop: 5 }}>
                <Text
                  style={{
                    color: Colors.blueCorporate + 'A5',
                    fontSize: 18,
                    fontFamily: 'appFontMedium',
                    marginBottom: 10,
                  }}>
                  {I18n.t('app.billing')}
                </Text>
                <TextInput
                  placeholder={I18n.t('shop.firstName')}
                  placeholderTextColor={Colors.gray1}
                  style={styles.textInput}
                  ref={(component) => (this.firstName = component)}
                  onFocus={() => {
                    this.scrollView.scrollTo({ x: 0, y: 0 });
                  }}
                  onChangeText={(text) => this.setState({ firstName: text })}
                  value={this.state.firstName}
                />
                <TextInput
                  placeholder={I18n.t('shop.lastName')}
                  placeholderTextColor={Colors.gray1}
                  style={styles.textInput}
                  ref={(component) => (this.lastName = component)}
                  onFocus={() => {
                    this.scrollView.scrollTo({ x: 0, y: 0 });
                  }}
                  onChangeText={(text) => this.setState({ lastName: text })}
                  value={this.state.lastName}
                />
                <TextInput
                  placeholder={I18n.t('shop.address')}
                  placeholderTextColor={Colors.gray1}
                  style={styles.textInput}
                  ref={(component) => (this.address = component)}
                  onFocus={() => {
                    this.scrollView.scrollTo({ x: 0, y: 0 });
                  }}
                  onChangeText={(text) => this.setState({ address: text })}
                  value={this.state.address}
                />
                <TextInput
                  placeholder={I18n.t('shop.postalCode')}
                  placeholderTextColor={Colors.gray1}
                  keyboardType="phone-pad"
                  style={[styles.textInput, this.state.isPostalCode ? { color: 'red' } : { color: 'black' }]}
                  ref={(component) => (this.postalCode = component)}
                  onFocus={() => {
                    if (Platform.OS === 'android') {
                      this.scrollView.scrollTo({ x: 0, y: 50 });
                    } else {
                      this.scrollView.scrollTo({ x: 0, y: 0 });
                    }
                  }}
                  onChangeText={(text) => {
                    this.setState({ postalCode: text });
                    if (text.length < 3) {
                      this.setState({ isPostalCode: true });
                    } else {
                      this.setState({ isPostalCode: false });
                    }
                  }}
                  value={this.state.postalCode}
                />
                <TextInput
                  placeholder={I18n.t('shop.city')}
                  placeholderTextColor={Colors.gray1}
                  style={styles.textInput}
                  ref={(component) => (this.city = component)}
                  onFocus={() => {
                    if (Platform.OS === 'android') {
                      this.scrollView.scrollTo({ x: 0, y: 100 });
                    } else {
                      this.scrollView.scrollTo({ x: 0, y: 150 });
                    }
                    this.setState({ city: '' });
                    this.setState({ viewAutocomplete: true });
                  }}
                  // onChangeText={(t)=> this.setState({ viewAutocomplete: true })}
                  value={this.state.city}
                />

                <TextInput
                  placeholder={I18n.t('shop.phoneNumber') + ' (' + I18n.t('shop.optional') + ') '}
                  placeholderTextColor={Colors.gray1}
                  style={[styles.textInput, this.state.isPhoneNumber ? { color: 'red' } : { color: 'black' }]}
                  keyboardType="phone-pad"
                  onFocus={() => {
                    if (Platform.OS === 'android') {
                      this.scrollView.scrollTo({ x: 0, y: 100 });
                    } else {
                      this.scrollView.scrollTo({ x: 0, y: 50 });
                    }
                  }}
                  onChangeText={(text) => {
                    if (text.length < 10) {
                      this.setState({ isPhoneNumber: true });
                    } else {
                      this.setState({ isPhoneNumber: false });
                    }
                    this.setState({ phoneNumber: text });
                  }}
                  value={this.state.phoneNumber}
                />
                <TextInput
                  placeholder={I18n.t('shop.comment')}
                  placeholderTextColor={Colors.gray1}
                  style={styles.textInput}
                  ref={(component) => (this.comment = component)}
                  onFocus={() => {
                    if (Platform.OS === 'android') {
                      this.scrollView.scrollTo({ x: 0, y: 150 });
                    } else {
                      this.scrollView.scrollTo({ x: 0, y: 50 });
                    }
                  }}
                  onChangeText={(text) => this.setState({ comment: text })}
                  value={this.state.comment}
                />
              </View>

              <ButtonValidate onPress={() => this._onSendCommand()} />
              <ButtonCancel
                onPress={() => {
                  this.bottomUp.close();
                }}
              />
            </View>
            {/* </KeyboardAvoidingView> */}
          </Pressable>
        </ScrollView>

        {/* </KeyboardAvoidingView> */}

        <AlerteUI
          showAlert={this.state.showCongratulation}
          onClose={() => {
            this.setState({ showCongratulation: false });
            if (this.state.message === I18n.t('shop.congratulateText')) {
              this.close();
              if (this.props.close) {
                this.props.close();
              }
            }
          }}
          title={this.state.title}
          message={this.state.message}
          buttons={this.state.alertButtons}
        />

        {/* View Autocomplete */}
        <Modal
          visible={this.state.viewAutocomplete}
          transparent={false}
          onRequestClose={() => {
            this.setState({ viewAutocomplete: false });
          }}>
          <View
            style={[
              {
                position: 'absolute',
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: Colors.gray,
                padding: 40,
              },
            ]}>
            <View style={{ marginBottom: 20 }}>
              <Pressable
                style={{
                  // padding: 15,
                  paddingTop: 0,
                  alignSelf: 'flex-start',
                  marginLeft: -19,
                  borderRadius: 100,
                }}
                onPress={() => {
                  this.setState({ viewAutocomplete: false });
                }}>
                <Icon name="md-arrow-back" size={25} color="#fff" />
              </Pressable>
            </View>
            <TextInput
              placeholder={I18n.t('shop.city')}
              placeholderTextColor={Colors.gray1}
              style={styles.textInput}
              autoFocus
              onChangeText={(text) => {
                this._requestAutoComplete(text);
              }}
            />
            <View
              style={{
                backgroundColor: Colors.white + '00',
                paddingTop: Platform.OS === 'ios' ? 2 : 21,
                position: 'absolute',
                top: Platform.OS === 'ios' ? 120 : 100,
                width: '100%',
                maxHeight: sh / 2,
                left: 40,
                borderBottomLeftRadius: 25,
                borderBottomRightRadius: 25,
              }}>
              <ScrollView showsVerticalScrollIndicator={false}>
                {this.state.autoCompleteResult.map((city, index) => {
                  return (
                    <TouchableOpacity
                      key={'' + index + new Date().getTime()}
                      style={{
                        backgroundColor: Colors.blueCorporate,
                        borderBottomWidth: 0.5,
                        borderBottomColor: Colors.gray,
                        padding: 10,
                        paddingTop: 15,
                      }}
                      onPress={() => {
                        Keyboard.dismiss();
                        const result = city.description.split(',');
                        this.setState({ city: result[0] });
                        this.setState({ yourCityComplete: result });
                        this.setState({ autoCompleteResult: [] });
                        this.setState({ viewAutocomplete: false });
                      }}>
                      <Text
                        style={{
                          color: Colors.whiteCorporate,
                          fontFamily: 'appFontMedium',
                          fontSize: 12,
                        }}>
                        {city.description}
                      </Text>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            </View>
          </View>
        </Modal>
        {/* Fin View Autocomplete */}

        <Loading loading={this.state.showActivityIndicator} />
      </BottomUpSheet>
    );
  }
}

// export default Order
const mapStateToProps = (state) => {
  const { tokens } = state.app;
  const { session } = state.session;
  return {
    tokens,
    session,
  };
};

export default connect(mapStateToProps)(Order);
