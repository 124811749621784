import Colors from '@configs/colors';
import AgendaStack from '@navigation/stacks/AgendaStack';
import HomeStack from '@navigation/stacks/HomeStack';
import MessagesStack from '@navigation/stacks/MessagesStack';
import ProfileStack from '@navigation/stacks/ProfileStack';
import ResultsStack from '@navigation/stacks/ResultsStack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { SET_CURRENT_PAGE } from '@redux/storeReducer';
import I18n from '@utils/i18n';
import { openUrl } from '@utils/openLinks';
import Constants from 'expo-constants';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Image, Platform, Pressable, Text } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

const Tab = createBottomTabNavigator();

const BottomTabNavigator = ({ navigation, route }) => {
  const debug = false;

  const [activeColor, setActiveColor] = useState(Colors.redCorporate);
  const [inactiveColor, setInactiveColor] = useState(Colors.whiteCorporate);
  const [barColor, setBarColor] = useState(Colors.blueCorporate);

  const [userName, setUserName] = useState(null);

  const { apiConfiguration } = useSelector((state) => state.app);
  const notificationsStore = useSelector((state) => state.notifications);
  const { session, affiliatedClubInformation } = useSelector((state) => state.session);

  const [premium, setPremium] = useState(false);
  const [premiumAffiliated, setPremiumAffiliated] = useState(false);
  const [bottomLogo, setBottomLogo] = useState(null);
  const [bottomUrl, setBottomUrl] = useState(null);
  const [currentClub, setCurrentClub] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    debug && console.debug(`BottomTabNavigator, user session:`, session);

    if (session) {
      // Fred: user first name in the bottom bar
      setUserName(session.user.firstName);
    }
  }, [session]);

  useEffect(() => {
    debug && console.debug(`BottomTabNavigator, user affiliatedClubInformation:`, affiliatedClubInformation);

    if (affiliatedClubInformation) {
      setActiveColor(affiliatedClubInformation.activeIconColor);
      setInactiveColor(affiliatedClubInformation.inactiveIconColor);
      setBarColor(affiliatedClubInformation.mainColorBottom);

      setCurrentClub(affiliatedClubInformation ?? session?.club ?? null);
    }
  }, [affiliatedClubInformation]);

  useEffect(() => {
    if (!currentClub) {
      return;
    }
    debug && console.debug(`SignedInNavigator, currentClub:`, currentClub);

    if (currentClub) {
      setActiveColor(currentClub.activeIconColor);
      setInactiveColor(currentClub.inactiveIconColor);
      setBarColor(currentClub.mainColorBottom);

      // Premium configuration enabled or not?
      if (apiConfiguration?.premium?.enabled && apiConfiguration?.premium?.enabled === '1') {
        setPremium(true);
        if (currentClub.isAffiliated === '1' && apiConfiguration.premium?.affiliated?.url) {
          setPremiumAffiliated(true);
          if (apiConfiguration.premium?.affiliated?.logo) {
            setBottomLogo(apiConfiguration.premium.affiliated.logo);
          } else {
            setBottomLogo(require('@assets/icons/footer/logo.png'));
          }
          setBottomUrl(apiConfiguration.premium.affiliated.url);
        }
        if (currentClub.isAffiliated !== '1' && apiConfiguration.premium?.notAffiliated?.url) {
          setPremiumAffiliated(false);
          if (apiConfiguration.premium?.notAffiliated?.logo) {
            setBottomLogo(apiConfiguration.premium.notAffiliated.logo);
          } else {
            setBottomLogo(require('@assets/logoPremium.png'));
          }
          setBottomUrl(apiConfiguration.premium.notAffiliated.url);
        }
      }
    }
  }, [currentClub]);

  if (!session) return;

  return (
    <Tab.Navigator
      initialRouteName="HomeStack"
      screenOptions={({ route }) => ({
        tabBarStyle: [
          {
            position: 'absolute',
            paddingHorizontal: 5,
            backgroundColor: barColor,
            borderTopColor: Colors.whiteCorporate,
            borderTopWidth: 1,
            height: 60,
            padding: 5,
            paddingBottom: Platform.OS === 'ios' ? 17 : 8,
          },
        ],
        tabBarActiveTintColor: activeColor,
        tabBarInactiveTintColor: inactiveColor,
        tabBarBadgeStyle: { backgroundColor: barColor, color: activeColor, fontSize: 10, marginTop: -5 },
        tabBarLabelStyle: { fontFamily: 'appFontRegular', fontSize: 11 },
      })}
      screenListeners={({ navigation, route }) => ({
        tabPress: () => {
          dispatch({ type: SET_CURRENT_PAGE, value: route.name });
        },
      })}>
      <Tab.Screen
        name="HomeStack"
        component={HomeStack}
        options={{
          tabBarLabel: I18n.t('tabLabel.home'),
          headerShown: false,
          tabBarIcon: ({ color, size }) => (
            <Image
              source={require('@assets/icons/footer/home.png')}
              style={{ width: size, height: size, tintColor: color }}
            />
          ),
          tabBarBadge:
            notificationsStore.numberOfHomeNotifications > 0 ? notificationsStore.numberOfHomeNotifications : null,
        }}
      />
      <Tab.Screen
        name="ResultsStack"
        component={ResultsStack}
        options={{
          tabBarLabel: I18n.t('tabLabel.results'),
          headerShown: false,
          tabBarIcon: ({ color, size }) => (
            <Image
              source={require('@assets/icons/footer/trophy.png')}
              style={{ width: size, height: size, tintColor: color }}
            />
          ),
        }}
      />
      {premium && premiumAffiliated && (
        <Tab.Screen
          name="Logo"
          component={ResultsStack}
          options={{
            tabBarButton: () => {
              return (
                <Pressable
                  onPress={() => openUrl(bottomUrl)}
                  style={{
                    width: 82,
                    height: 52,
                    top: Platform.OS === 'ios' ? -10 : -2,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Image source={bottomLogo} style={{ width: 32, height: 32, tintColor: inactiveColor }} />
                  <Text style={{ fontFamily: 'appFontRegular', fontSize: 11, color: inactiveColor, marginTop: -4 }}>
                    {Constants.expoConfig.name.replace('e-', '')}
                  </Text>
                </Pressable>
              );
            },
            headerShown: false,
          }}
        />
      )}
      {premium && !premiumAffiliated && (
        <Tab.Screen
          name="Logo"
          component={ResultsStack}
          options={{
            tabBarButton: () => {
              return (
                <Pressable
                  onPress={() => openUrl(bottomUrl)}
                  style={{
                    width: 72,
                    height: 72,
                    top: -42,
                    borderRadius: 200,
                    backgroundColor: inactiveColor,
                    borderColor: barColor,
                    borderWidth: 6,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Image source={bottomLogo} style={{ width: 42, height: 42 }} />
                </Pressable>
              );
            },
            headerShown: false,
          }}
        />
      )}
      <Tab.Screen
        name="AgendaStack"
        component={AgendaStack}
        options={{
          tabBarLabel: I18n.t('tabLabel.agenda'),
          headerShown: false,
          tabBarIcon: ({ color, size }) => (
            <Image
              source={require('@assets/icons/footer/calendar.png')}
              style={{ width: size, height: size, tintColor: color }}
            />
          ),
          tabBarBadge:
            notificationsStore.numberOfAgendaNotifications > 0 ? notificationsStore.numberOfAgendaNotifications : null,
        }}
      />
      <Tab.Screen
        name="MessagesStack"
        component={MessagesStack}
        options={{
          tabBarLabel: I18n.t('tabLabel.messages'),
          headerShown: false,
          tabBarIcon: ({ color, size }) => (
            <Image
              source={require('@assets/icons/footer/speech.png')}
              style={{ width: size, height: size, tintColor: color }}
            />
          ),
          tabBarBadge:
            notificationsStore.numberOfMessageNotifications > 0
              ? notificationsStore.numberOfMessageNotifications
              : null,
        }}
      />
      {!premium ? (
        <Tab.Screen
          name="ProfileStack"
          component={ProfileStack}
          options={{
            tabBarLabel: userName ?? I18n.t('tabLabel.profile'),
            headerShown: false,
            tabBarIcon: ({ color, size }) => (
              <Image
                source={require('@assets/icons/footer/user.png')}
                style={{ width: size, height: size, tintColor: color }}
              />
            ),
          }}
        />
      ) : (
        <Tab.Screen
          name="ProfileStack"
          component={ProfileStack}
          options={{
            headerShown: false,
            tabBarButton: () => null,
          }}
        />
      )}
    </Tab.Navigator>
  );
};

export default BottomTabNavigator;
