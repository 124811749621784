import { getApiHost } from '@configs/host';
import createFormData from '@utils/formData';
import * as Application from 'expo-application';
import Constants from 'expo-constants';
import * as ImageManipulator from 'expo-image-manipulator';
import { Platform } from 'react-native';

import { Request } from './Request';
import { URLs } from './apisConstants';

// Message type:
// public const SIMPLE_MESSAGE    = 1;
// public const CONVOCATION       = 2;
// public const CONVOCATION_REPLY = 3;
export const MSG_SIMPLE_MESSAGE = '1';
export const MSG_CONVOCATION = '2';
export const MSG_CONVOCATION_REPLY = '3';

// Presence reply type:
// public const YES   = 1;
// public const NO    = 2;
// public const MAYBE = 3;
export const REPLY_YES = '1';
export const REPLY_NO = '2';
export const REPLY_MAY_BE = '3';

export const MessageAPI = {
  getAllConversations: (wsToken, clubId) => {
    if (clubId) {
      return Request.get(getApiHost() + URLs.getAllConversationsByClubId + '?wsToken=' + wsToken + '&clubId=' + clubId);
    }
    return Request.get(getApiHost() + URLs.getAllConversations + '?wsToken=' + wsToken);
  },
  getConversationInfo: (wsToken, conversationId) => {
    return Request.get(
      getApiHost() + URLs.getConversationInfo + '?wsToken=' + wsToken + '&conversationId=' + conversationId
    );
  },

  /**
   * Used to create a 1-to-1 conversation
   * @param wsToken
   * @param name
   * @param userIds
   * @returns {Promise<Response | void>}
   */
  addConversation: (wsToken, name, userIds) => {
    return Request.post(getApiHost() + URLs.addConversation, {
      wsToken,
      name,
      userIds,
      // Always empty!
      teamIds: [],
    });
  },
  /**
   * Used to create a normal conversation
   * @param wsToken
   * @param clubId
   * @param name
   * @param parties
   * @param photoName
   * @returns {Promise<Response | void>}
   */
  addClubConversation: (wsToken, clubId, name, parties, photoName) => {
    return Request.post(getApiHost() + URLs.addClubConversation, {
      wsToken,
      name,
      clubId,
      parties,
      photoName,
    });
  },

  editConversation: (id, wsToken, name, userIds, teamIds) => {
    const url = getApiHost() + URLs.editConversation;
    const data = {
      id,
      wsToken,
      name,
      userIds,
      teamIds,
    };

    return Request.post(url, data);
  },
  editClubConversation: (id, wsToken, name, parties, photoName) => {
    return Request.post(getApiHost() + URLs.editClubConversation, {
      id,
      wsToken,
      name,
      parties,
      photoName,
    });
  },

  sendMessage: (wsToken, conversationId, content, filename) => {
    const data = {
      wsToken,
      conversationId,
      messageType: MSG_SIMPLE_MESSAGE,
      content,
      filename,

      // Unused for a message
      presenceReplyType: null,
      convocationId: null,
      title: null,
      convocationDate: null,
      convocationHour: null,
      convocationLatitude: null,
      convocationLongitude: null,
      convocationAddress: null,
    };

    return Request.post(getApiHost() + URLs.saveMessage, data);
  },

  sendConvocation: (wsToken, conversationId, convocation) => {
    const url = getApiHost() + URLs.saveMessage;
    const {
      id: convocationId,
      date: convocationDate,
      time: convocationHour,
      latitude: convocationLatitude,
      longitude: convocationLongitude,
      address: convocationAddress,
      title: convocationTitle,
      description: content,
      reply: presenceReplyType = REPLY_MAY_BE,
      called: convocationCalled,
    } = convocation;

    const data = {
      wsToken,
      conversationId,
      messageType: MSG_CONVOCATION,
      content,
      filename: '',

      presenceReplyType,
      convocationId,
      title: convocationTitle,
      convocationDate,
      convocationHour,
      convocationLatitude,
      convocationLongitude,
      convocationAddress,
      convocationCalled,
    };

    return Request.post(url, data);
  },

  sendConvocationReply: (wsToken, conversationId, convocation) => {
    const url = getApiHost() + URLs.saveMessage;
    const { convocationId, title: convocationTitle, content, presenceReplyType } = convocation;

    const data = {
      wsToken,
      conversationId,
      messageType: MSG_CONVOCATION_REPLY,
      content,
      filename: '',

      presenceReplyType,
      convocationId,
      title: convocationTitle,
      convocationDate: '',
      convocationHour: '',
      convocationLatitude: '',
      convocationLongitude: '',
      convocationAddress: '',
    };

    return Request.post(url, data);
  },

  /**
   * Specific endpoint to upload a conversation image
   * @param file
   * @param wsToken
   * @returns {Promise<any>}
   */
  uploadConversationCover: async (file, wsToken) => {
    return MessageAPI.uploadMedia(file, wsToken, true);
  },

  /**
   * Upload a message media
   * @param file
   * @param wsToken
   * @param conversation
   * @returns {Promise<any>}
   */
  uploadMedia: async (file, wsToken, conversation = false) => {
    // Resize file if too big
    if (file.type === 'image') {
      if (conversation) {
        if (file.width > 1024 || file.height > 1024) {
          file = await ImageManipulator.manipulateAsync(
            file.localUri || file.uri,
            [{ resize: { width: 1920, height: 1920 } }],
            { compress: 0.7, format: ImageManipulator.SaveFormat.JPEG }
          );
        }
      } else {
        if (file.width > 1920 || file.height > 1920) {
          file = await ImageManipulator.manipulateAsync(
            file.localUri || file.uri,
            [{ resize: { width: 2000, height: 1500 } }],
            { compress: 0.7, format: ImageManipulator.SaveFormat.JPEG }
          );
        }
      }
    }

    // Get endpoint + route url
    const body = createFormData(file, wsToken);
    return (
      await fetch(getApiHost() + (conversation ? URLs.uploadConversationCover : URLs.uploadMessageImage), {
        method: 'POST',
        body,
        headers: Platform.select({
          web: {
            Accept: 'application/json',
            // Authorization: `Bearer ${token}`,
            'X-User-Agent': `${Constants.expoConfig.slug}/${Constants.expoConfig.version}/${Application.nativeBuildVersion}`,
          },
          default: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            // Authorization: `Bearer ${token}`,
            'X-User-Agent': `${Constants.expoConfig.slug}/${Constants.expoConfig.version}/${Application.nativeBuildVersion}`,
          },
        }),
      })
    ).json();
  },
  getMessages: (wsToken, conversationId, page, limit) => {
    const url =
      getApiHost() +
      URLs.getMessages +
      '?wsToken=' +
      wsToken +
      '&conversationId=' +
      conversationId +
      '&page=' +
      page +
      '&limit=' +
      limit;
    return Request.get(url);
  },
  getConvocationResponses: (wsToken, convocationId) => {
    const url = getApiHost() + URLs.getConvocationResponses + '?wsToken=' + wsToken + '&convocationId=' + convocationId;
    return Request.get(url);
  },

  deleteConversation: (wsToken, conversationId) => {
    return Request.post(getApiHost() + URLs.deleteConversation, { wsToken, conversationId });
  },
  deleteMessage: (wsToken, messageId) => {
    return Request.post(getApiHost() + URLs.deleteMessage, { wsToken, messageId });
  },
};
