import Colors from '@configs/colors';
import { Dimensions, StyleSheet } from 'react-native';

const { width: sw, height: sh } = Dimensions.get('window');

const styles = StyleSheet.create({
  mainContainer: {
    // flex: 1,
    maxHeight: sh - 40,
    backgroundColor: Colors.gray,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 20,
    paddingTop: 10,
  },
  appFontRegular: {
    fontFamily: 'appFontRegular',
  },

  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingHorizontal: 10,
    marginTop: -20,
    marginBottom: 10,
  },
  headerLogo: {
    width: 70,
    height: 70,
    borderRadius: 35,
    resizeMode: 'contain',
    marginRight: 10,
  },
  headerTitle: {
    width: sw - 130,
    fontSize: 18,
    fontFamily: 'appFontMedium',
    marginTop: 10,
    marginBottom: 15,
    fontWeight: '700',
    opacity: 0.6,
  },
  headerDate: {
    fontSize: 14,
    color: Colors.iconGray,
    fontFamily: 'appFontRegular',
    opacity: 0.5,
  },
  headerIcon: {
    padding: 10,
    textAlignVertical: 'top',
  },

  titleHeaderCtn: {
    width: '100%',
    alignItems: 'center',
    marginBottom: 10,
  },
  actuTypeLabel: {
    color: Colors.blueCorporate,
    fontSize: 18,
    fontFamily: 'appFontMedium',
    fontWeight: 'bold',
    opacity: 0.8,
  },
  actuAuthor: {
    fontSize: 12,
    color: Colors.iconGray,
    fontFamily: 'appFontRegular',
    opacity: 0.5,
  },

  actuContainer: {
    marginTop: 25,
  },
  actuTitle: {
    fontSize: 18,
    fontFamily: 'appFontMedium',
    marginTop: 10,
    marginBottom: 15,
    fontWeight: '700',
    opacity: 0.6,
  },
  actuDescription: {
    fontSize: 14,
    fontFamily: 'appFontRegular',
    lineHeight: 20,
    marginBottom: 20,
    color: Colors.blueCorporate,
    opacity: 1,
  },

  presenceReplyContainer: {
    width: sw - 40,
    marginVertical: 5,
    marginHorizontal: 0,
    padding: 5,
  },
  replyButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: 0,
  },
  replyButton: {
    width: 100,
    height: 25,
  },
  replyButtonLabel: {
    fontSize: 12,
    color: Colors.iconGray,
  },

  commentContainer: {
    backgroundColor: Colors.whiteCorporate,
    marginVertical: 10,
    borderRadius: 50,
    padding: 0,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  commentInput: { flex: 1, height: 32, color: Colors.blueBgGradient1, fontFamily: 'appFontRegular' },
  commentSend: {
    width: 32,
    height: 32,
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: -15,
  },

  actionButtonsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: 10,
    paddingBottom: 20,
  },
  actionButton: {
    width: 60,
    height: 60,
    borderRadius: 100,
    backgroundColor: Colors.whiteCorporate,
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 5,
    overflow: 'visible',
    shadowColor: Colors.iconGray + '77',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.8,
    shadowRadius: 7,
  },
  actionIcon: {
    tintColor: Colors.blueCorporate,
    width: 20,
    resizeMode: 'contain',
    opacity: 0.7,
  },

  scoreBody: {
    width: sw - 30,

    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',

    padding: 10,
    borderRadius: 10,
  },
  scoreColumn: {
    width: ((sw - 30) / 5) * 2,
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 10,
  },
  scoreTeamName: {
    textAlign: 'center',
    fontSize: 12,
    color: Colors.blueCorporate,
    opacity: 0.5,
    fontFamily: 'appFontMedium',
    fontWeight: 'bold',
    marginBottom: 5,
  },
  scoreClubName: {
    textAlign: 'center',
    fontSize: 12,
    color: Colors.blueCorporate,
    opacity: 0.5,
    fontFamily: 'appFontRegular',
    marginTop: 5,
  },
  scoreFooter: {},
});

export default styles;
