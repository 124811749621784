import { REPLY_NO, REPLY_YES } from '@apis/message';
import CardUser from '@components/CardUser/CardUser';
import ShadowCard from '@components/ShadowCard/ShadowCard';
import Colors from '@configs/colors';
import I18n from '@utils/i18n';
import React, { useEffect, useState } from 'react';
import { Dimensions, FlatList, Pressable, StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { useSelector } from 'react-redux';

const { width: sw, height: sh } = Dimensions.get('window');

const CardReply = ({ diary, type, style = {} }) => {
  const { session } = useSelector((state) => state.session);
  const { affiliatedClubInformation } = useSelector((state) => state.session);

  const [currentClub, setCurrentClub] = useState(null);

  const [isBlockOpened, setIsBlockOpened] = useState(false);

  const [replyIcon, setReplyIcon] = useState('help-circle-outline');
  const [replyCount, setReplyCount] = useState(diary.repliesMaybe);

  useEffect(() => {
    if (type === REPLY_YES) {
      setReplyIcon('thumbs-up');
      setReplyCount(diary.repliesYes);
    }
    if (type === REPLY_NO) {
      setReplyIcon('thumbs-down');
      setReplyCount(diary.repliesNo);
    }
  }, [diary]);

  const [textColor, setTextColor] = useState(Colors.blueCorporate);
  useEffect(() => {
    setTextColor((currentClub && currentClub.mainColorBottom) ?? Colors.gray);
  }, [currentClub]);

  useEffect(() => {
    setCurrentClub(affiliatedClubInformation ?? session.club ?? null);
  }, [session, affiliatedClubInformation]);

  if (!currentClub) {
    return null;
  }

  return (
    <ShadowCard style={[styles.mainContainer, style]}>
      <Pressable style={[styles.replyButtonsContainer]} onPress={() => setIsBlockOpened(!isBlockOpened)}>
        {replyCount > 0 ? (
          <>
            <Icon name={replyIcon} size={25} color={Colors.blueCorporate} />
            <Text style={[{ color: textColor, fontSize: 16, marginLeft: 5 }]}>
              {I18n.t('agenda.participantsCount', { count: replyCount })}
            </Text>
          </>
        ) : (
          <Text style={[{ color: textColor, fontSize: 16, marginLeft: 5 }]}>{I18n.t('agenda.noParticipants')}</Text>
        )}
      </Pressable>
      {isBlockOpened && (
        <View>
          {diary.replies
            .filter((item) => item.PresenceReplyType_id === type)
            .map((item, index) => {
              return (
                <CardUser
                  key={item.id}
                  style={{ marginLeft: 0 }}
                  member={item}
                  selectable={false}
                  callable
                  chattable={false}
                  extraIcon={<Icon name={replyIcon} size={25} color={textColor} />}
                />
              );
            })}
        </View>
      )}
    </ShadowCard>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    width: sw - 40,
    maxWidth: sw - 20,
    padding: 10,
    marginRight: 10,
  },
  convocationCard: {
    width: '100%',

    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    overflow: 'visible',
    borderColor: 'transparent',
    marginBottom: 10,
  },
  userAvatar: {
    width: 50,
    height: 50,
    borderRadius: 100,
    marginRight: 10,
    marginLeft: 0,
  },

  blockHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    paddingVertical: 0,
    paddingHorizontal: 5,
    borderBottomColor: Colors.gray,
    borderBottomWidth: 1,
  },

  replyButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 0,
  },
  replyButton: {
    width: 120,
    height: 25,
  },
  replyButtonLabel: {
    fontSize: 12,
    color: Colors.iconGray,
  },
});

export default CardReply;
