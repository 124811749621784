import { ADD_FAVORITE_LINK, UPDATE_FAVORITE_LINK } from '@redux/reducers/ResultFavoritesReducer';

export function addFavoriteLink(resultFavorite) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ADD_FAVORITE_LINK,
        resultFavorite,
      });
    } catch (error) {
      console.warn('addFavoriteLink, catch:', error);
    }
  };
}

export function updateFavoriteLink(resultFavorites) {
  return async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_FAVORITE_LINK,
        resultFavorites,
      });
    } catch (error) {
      console.warn('updateFavoriteLink, catch:', error);
    }
  };
}
