import Colors from '@configs/colors';
import I18n from '@utils/i18n';
import React from 'react';
import DateTimePickerModal from 'react-native-modal-datetime-picker';

export const DateTimePicker = ({
  value,
  mode = 'date',
  display = 'spinner',
  visible = true,
  future = true,
  onChange = () => {},
  onCancel = () => {},
  onClose = () => {},
}) => {
  if (!visible) {
    return <></>;
  }

  // Web version
  return (
    <DateTimePickerModal
      isVisible={visible}
      mode={mode}
      locale="fr_FR"
      is24Hour
      display={display}
      date={value}
      minuteInterval={5}
      minimumDate={mode === 'date' ? (future ? value : null) : null}
      maximumDate={mode === 'date' ? (future ? null : value) : null}
      onConfirm={(selectedDate) => {
        onClose();
        onChange(selectedDate);
      }}
      onCancel={() => {
        onClose();
        onCancel();
      }}
      // modalStyleIOS={{ backgroundColor: Colors.blueBtn1 + '66' }}
      pickerContainerStyleIOS={{ backgroundColor: Colors.whiteCorporate }}
      pickerStyleIOS={{ backgroundColor: Colors.whiteCorporate }}
      pickerComponentStyleIOS={{ backgroundColor: Colors.whiteCorporate }}
      headerTextIOS={mode === 'date' ? 'Sélectionner une date' : 'Sélectionner une heure'}
      cancelTextIOS={I18n.t('datePicker.cancel')}
      confirmTextIOS={I18n.t('datePicker.confirm')}
      buttonTextColorIOS={Colors.blueCorporate}
      textColor={Colors.blueCorporate}
    />
  );
};

export default DateTimePicker;
