import Colors from '@configs/colors';
import PropTypes from 'prop-types';
import React from 'react';
import { ActivityIndicator, Dimensions, Modal, Pressable, StyleSheet, View } from 'react-native';

const { width: sw, height: sh } = Dimensions.get('window');

export default function Loading({ modal = false, loading = false, color = Colors.blueCorporate, size = 'large' }) {
  if (!loading) {
    return null;
  }

  if (modal) {
    return (
      <Modal visible={loading} transparent>
        <Pressable accessible={false} style={[styles.modalContainer]}>
          <ActivityIndicator animating={loading} size={size} color={color} />
        </Pressable>
      </Modal>
    );
  }

  return (
    <View accessible={false} style={[styles.nonModalContainer]}>
      <ActivityIndicator animating={loading} size={size} color={color} />
    </View>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.black + 75,
  },
  nonModalContainer: {
    flex: 1,
    alignSelf: 'center',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: (sh - 60) / 2,
    left: (sw - 60) / 2,
    width: 60,
    height: 60,
  },
});

Loading.propTypes = {
  loading: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
};
