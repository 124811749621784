import ButtonBase from '@components/ButtonBase/ButtonBase';
import GradientBackground from '@components/GradientBackground/GradientBackground';
import Colors from '@configs/colors';
import { SET_APP_ONLINE } from '@redux/storeReducer';
import I18n from '@utils/i18n';
import React from 'react';
import {Dimensions, Image, Platform, StyleSheet, Text, View} from 'react-native';
import { useDispatch } from 'react-redux';
import {gMaxWidth} from "@configs/styles";

const { width: sw, height: sh } = Dimensions.get('window');

export default function AppOffline({ navigation }) {
  const debug = false;
  debug && console.debug('AppOffline, ...');

  const dispatch = useDispatch();

  return (
    <GradientBackground gradient>
      <View style={[styles.rootContainer]}>
        <View style={styles.imageContainer}>
          <Image source={require('@assets/logoText.png')} style={[styles.image]} />
        </View>

        <Text style={[styles.lostConnectionText]}>{I18n.t('noInternet.text')}</Text>

        <ButtonBase
          label="Réessayer"
          style={[styles.buttonStyle, { width: (Platform.OS === 'web' ? gMaxWidth : sw) - 80 }]}
          onPress={() => {
            dispatch({ type: SET_APP_ONLINE, value: true });
          }}
        />
      </View>
    </GradientBackground>
  );
}

const styles = StyleSheet.create({
  rootContainer: {
    marginTop: 10,
    alignItems: 'center',
  },

  imageContainer: {
    justifyContent: 'center',
    height: sh / 4,
    width: sw,
    marginTop: 10,
    padding: 10,
  },
  image: {
    width: '90%',
    height: '100%',
    resizeMode: 'contain',
    alignSelf: 'center',
  },

  lostConnectionText: {
    fontFamily: 'appFontMedium',
    fontSize: 30,
    color: Colors.blueCorporate,
    marginHorizontal: 20,
    textAlign: 'center',
  },

  buttonStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 60,
    width: sw - 120,
  },
});
