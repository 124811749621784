import { getApiHost } from '@configs/host';
import createFormData from '@utils/formData';
import * as Application from 'expo-application';
import Constants from 'expo-constants';

import { Request } from './Request';
import { URLs } from './apisConstants';

// Actuality type:
//     public const ACTUALITY_ID = 1;
//     public const SCORE_ID     = 2;
//     public const EVENT_ID     = 3;
export const ACT_ACTUALITY = '1';
export const ACT_SCORE = '2';
export const ACT_EVENT = '3';

export const NewsAPI = {
  addNewsByClubId: (
    wsToken,
    clubId,
    typeId,
    recipientTypeId,
    homeTeamId,
    awayTeamId,
    title,
    description,
    address,
    longitude,
    latitude,
    askReply,
    date,
    time,
    homeTeamScore,
    awayTeamScore,
    isHalftime,
    homeClubId,
    awayClubId,
    homeClubName,
    awayClubName,
    homeTeamName,
    awayTeamName,
    scoreComment,
    photos = []
  ) => {
    return Request.post(getApiHost() + URLs.addNewsByClubId, {
      wsToken,
      clubId,
      typeId,
      recipientTypeId,
      homeTeamId,
      awayTeamId,
      title,
      description,
      address,
      longitude,
      latitude,
      askReply,
      date,
      time,
      homeTeamScore,
      awayTeamScore,
      isHalftime,
      homeClubId,
      awayClubId,
      homeClubName,
      awayClubName,
      homeTeamName,
      awayTeamName,
      scoreComment,
      photos,
    });
  },

  deleteNews: (wsToken, id) => {
    return Request.post(getApiHost() + URLs.deleteNews, {
      wsToken,
      id,
    });
  },
  validateNews: (wsToken, id) => {
    return Request.post(getApiHost() + URLs.validateNews, {
      wsToken,
      id,
    });
  },
  uploadActualityPicture: async (file, wsToken) => {
    return NewsAPI.uploadMedia(file, wsToken, URLs.uploadActualityPicture);
  },
  uploadActualityVideo: async (file, wsToken) => {
    return NewsAPI.uploadMedia(file, wsToken, URLs.uploadActualityVideo);
  },
  uploadMedia: (file, wsToken, url = null) => {
    const theUrl = url ?? file.type === 'video' ? URLs.uploadActualityVideo : URLs.uploadActualityPicture;
    // // Resize file if too big
    // if (file.type === 'image' && (file.width > 20000 || file.height > 1500)) {
    //   file = await ImageManipulator.manipulateAsync(
    //     file.localUri || file.uri,
    //     [{ resize: { width: file.width * 0.5, height: file.height * 0.5 } }],
    //     { compress: 0.7, format: ImageManipulator.SaveFormat.JPEG }
    //   );
    // }

    // Get endpoint + route url
    const body = createFormData(file, wsToken);
    return fetch(getApiHost() + theUrl, {
      method: 'POST',
      body,
      headers: {
        // 'Content-Type': 'multipart/form-data',
        // 'boundary': Math.random().toString().substr(2),
        'X-User-Agent': `${Constants.expoConfig.slug}/${Constants.expoConfig.version}/${Application.nativeBuildVersion}`,
      },
    })
      .then((response) => response.json())
      .catch((error) => {
        console.warn('uploadMedia, error', error);
      });
  },
  editNews: (
    type,

    wsToken,
    id,

    data
  ) => {
    if (type === Number(ACT_ACTUALITY)) {
      return Request.post(getApiHost() + URLs.editNews, {
        wsToken,
        id,
        ...data,
      });
    } else if (type === Number(ACT_SCORE)) {
      return Request.post(getApiHost() + URLs.editNews, {
        wsToken,
        id,
        ...data,
      });
    } else if (type === Number(ACT_EVENT)) {
      return Request.post(getApiHost() + URLs.editNews, {
        wsToken,
        id,
        ...data,
      });
    }
  },
  getNews: (wsToken, id) => {
    return Request.get(getApiHost() + URLs.getNews + '?wsToken=' + wsToken + '&id=' + id);
  },
  getAllNews: (wsToken, page, limit, isMyClub, clubIds = [], committeeIds = [], leagueIds = [], pendingFilter = 1) => {
    let url = getApiHost() + URLs.getAllNews;
    url += '?wsToken=' + wsToken + '&';
    url += 'page=' + page + '&';
    url += 'limit=' + limit + '&';
    url += 'isMyClub=' + isMyClub;
    url += '&isValid=' + pendingFilter;
    if (clubIds.length <= 0) {
      url += '&clubIds[]=';
    } else {
      clubIds.map((cId, index) => {
        url += '&clubIds[]=' + cId;
      });
    }

    if (leagueIds.length <= 0) {
      url += '&leagueIds[]=';
    } else {
      leagueIds.map((cId, index) => {
        url += '&leagueIds[]=' + cId;
      });
    }

    if (committeeIds.length <= 0) {
      url += '&committeeIds[]=';
    } else {
      committeeIds.map((cId, index) => {
        url += '&committeeIds[]=' + cId;
      });
    }

    return Request.get(url);
  },
  answerNews: (wsToken, id, replyId) => {
    return Request.post(getApiHost() + URLs.answerNews, {
      wsToken,
      id,
      replyId,
    });
  },
  commentNews: (wsToken, id, content) => {
    return Request.post(getApiHost() + URLs.commentNews, {
      wsToken,
      id,
      content,
    });
  },
  likeNews: (wsToken, id) => {
    return Request.post(getApiHost() + URLs.likeNews, {
      wsToken,
      id,
    });
  },
  // Used on discovery news filter, list of regions
  getAllLeagues: () => {
    return Request.get(getApiHost() + URLs.getAllLeagues);
  },
  // Used on discovery news filter, list of department by region
  getCommitteeByLeague: (leagueId) => {
    return Request.get(getApiHost() + URLs.getCommitteeByLeague + '?leagueId=' + leagueId);
  },
};
