import ClubFilters from '@containers/HomeStack/ClubFilters/ClubFilters';
import ChooseProfile from '@containers/ProfileStack/ChooseProfile';
import Profile from '@containers/TabProfile';
import ModalNotifications from '@navigation/modals/ModalNotifications';
import NotAffiliated from '@navigation/modals/NotAffiliated';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import i18n from '@utils/i18n';
import React from 'react';

const Stack = createNativeStackNavigator();

const ProfileStack = () => {
  return (
    <Stack.Navigator
      id="ProfileStack"
      initialRouteName="Profile"
      screenOptions={{ headerShown: false, title: i18n.t('pageTitle.profile') }}>
      <Stack.Group>
        <Stack.Screen name="Profile" component={Profile} options={{ headerShown: false }} />
      </Stack.Group>

      <Stack.Group screenOptions={{ headerShown: false, presentation: 'modal' }}>
        <Stack.Screen name="NotificationsList" component={ModalNotifications} />
        <Stack.Screen name="NotAffiliated" component={NotAffiliated} />
        <Stack.Screen
          name="ClubFavorites"
          component={ClubFilters}
          initialParams={{
            selectable: true,
          }}
        />
        <Stack.Screen name="ChooseProfile" component={ChooseProfile} />
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default ProfileStack;
