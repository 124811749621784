import { Dimensions, Platform, StyleSheet } from 'react-native';

const { width: sw, height: sh } = Dimensions.get('window');
const isLargeScreen = sw >= 768;

export const gMaxWidth = isLargeScreen ? 768 : sw;

export const gStyles = StyleSheet.create({
  maxWidthContainer:
    Platform.OS === 'web'
      ? {
          maxWidth: sw,
        }
      : {},
});
