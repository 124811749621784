import { ClubsAPI } from '@apis/clubs';
import { MessageAPI } from '@apis/message';
import Images from '@assets/images';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import BottomUpSheet from '@components/BottomUpSheet/BottomUpSheet';
import CardCategory from '@components/CardCategory/CardCategory';
import HeaderBanner from '@components/HeaderBanner/HeaderBanner';
import Loading from '@components/Loading/Loading';
import MapViewer from '@components/MapViewer/MapViewer';
import ShadowCard from '@components/ShadowCard/ShadowCard';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import alert from '@utils/alert';
import { checkIsAdmin, checkIsModeratorOrAdmin } from '@utils/checkUserRoles';
import I18n from '@utils/i18n';
import { openMail, openMap, openPhone } from '@utils/openLinks';
import Constants from 'expo-constants';
import React, { Component } from 'react';
import { BackHandler, Dimensions, FlatList, Image, Pressable, ScrollView, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { connect } from 'react-redux';

import styles from './styles';

const { width: sw, height: sh } = Dimensions.get('window');

class ClubInfos extends Component {
  popupRef = null;

  categories = [
    // { title: 'teams', content: 'teams' },
    // { title: 'players' },
    { title: 'board' },
    { title: 'staff' },
    { title: 'coaches' },
    { title: 'referees' },
    // { title: 'parents' },
    { title: 'volunteers' },
    // { title: 'partners', content: 'partners' },
  ];

  constructor(props) {
    super(props);

    this.state = {
      currentClub: this.props.foundOtherClub ?? this.props.affiliatedClubInformation ?? this.props.session.club ?? null,
      isModerator: false,
      isAdmin: false,
      displayTelAndChat: true,
      clubDetail: null,
      currentGymnasium: null,
      loading: false,
      showAlert: false,
      alertTitle: '',
      alertMessage: '',
    };
  }

  componentDidMount() {
    const { navigation } = this.props;
    this.getClubDetail();
    this.checkModeration();

    this.focusListener = navigation.addListener('focus', () => {
      this.handleAffiliation();
      this.checkModeration();
    });

    this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      this.props.navigation.pop();
      return true;
    });
  }

  componentWillUnmount() {
    this.backHandler.remove();
    this.focusListener();
  }

  /**
   * Function used to update the current club of this screen
   */
  handleAffiliation() {
    if (this.props.foundOtherClub) {
      this.setState({ currentClub: this.props.foundOtherClub }, () => this.getClubDetail());
    } else if (
      this.props.affiliatedClubInformation &&
      this.state.currentClub.id !== this.props.affiliatedClubInformation.id
    ) {
      this.setState({ currentClub: this.props.affiliatedClubInformation ?? this.props.session.club ?? null }, () =>
        this.getClubDetail()
      );
    } else if (!this.props.foundOtherClub && !this.props.affiliatedClubInformation) {
      this.setState({ currentClub: this.props.session.club }, () => this.getClubDetail());
    }
  }
  checkModeration = () => {
    this.setState((currentState) => ({
      isModerator: checkIsModeratorOrAdmin(currentState.currentClub),
      isAdmin: checkIsAdmin(currentState.currentClub),
    }));
  };

  /**
   * Function to start conversation with a selected user
   * @param {object} userToContact
   */
  startConversation = (userToContact = null) => {
    if (!userToContact) {
      return;
    }

    if (this.state.currentClub.isAffiliated !== '1') {
      alert(
        I18n.t('app.oups'),
        "Vous ne pouvez pas ouvrir une conversation car votre club n'est pas affilié à " + Constants.expoConfig.name
      );
      return;
    }

    if (this.props.session?.user?.id === userToContact.id) {
      alert(I18n.t('app.oups'), 'Vous ne pouvez pas vous envoyer un message à vous même -)');
      return;
    }

    this.setState({ loading: true });

    MessageAPI.addConversation(this.props.tokens.wsToken, '', [userToContact.id]).then((response) => {
      if (response && response.success) {
        this.props.navigation.navigate('MessagesStack', {
          screen: 'Conversation',
          params: { chat: response.result },
        });
      } else if (response?.success === false) {
        alert(I18n.t('app.oups'), 'Erreur lors de la création de la conversation.');
      } else {
        alert(I18n.t('app.oups'), 'Une erreur est survenue.');
      }
      this.setState({ loading: false });
    });
  };

  renderBottomUpPanelGymnasium = () => {
    const currentGymnasium = this.state.currentGymnasium;
    if (!currentGymnasium) return;

    // Set visible to open immediately on mount
    return (
      <BottomUpSheet
        visible
        onPopupRef={(ref) => {
          this.popupRef = ref;
        }}>
        <View style={{ alignItems: 'center' }}>
          <Text
            style={{
              fontFamily: 'appFontMedium',
              color: Colors.blueCorporate,
              fontSize: 20,
            }}>
            {currentGymnasium.name
              ? `${Constants.expoConfig.extra.stadiumName} ${currentGymnasium.name}`
              : `${Constants.expoConfig.extra.stadiumName}`}
          </Text>

          <Image
            source={
              currentGymnasium.imageFilename
                ? {
                    uri: getImageApiHostOrUrl(currentGymnasium.imageFilename),
                  }
                : Images.bannerPlaceholder
            }
            style={[styles.clubGymBottomUpPanel, { width: 120, height: 120 }]}
          />

          {currentGymnasium.phoneNumber && (
            <Pressable
              style={{ flexDirection: 'row', marginTop: 10 }}
              onPress={() => {
                openPhone(currentGymnasium.phoneNumber);
              }}>
              <View style={[{ marginRight: 10, marginBottom: 10 }, styles.actionButton]}>
                <Icon name="call" size={25} color={this.state.clubDetail.mainColorBottom} />
              </View>
              <Text style={[{ marginTop: 10 }, styles.contactText]}>{currentGymnasium.phoneNumber}</Text>
            </Pressable>
          )}

          {currentGymnasium.address && (
            <Pressable
              style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10, marginHorizontal: 20 }}
              onPress={() => {
                if (!currentGymnasium.latitude && !currentGymnasium.longitude) {
                  openMap(currentGymnasium.address);
                } else {
                  openMap(currentGymnasium.address, currentGymnasium.latitude, currentGymnasium.longitude);
                }
              }}>
              <View style={[{ marginRight: 10 }, styles.actionButton]}>
                <Icon name="map" size={25} color={this.state.clubDetail.mainColorBottom} />
              </View>
              <Text
                style={{
                  fontFamily: 'appFontMedium',
                  color: Colors.blueCorporate,
                  fontSize: 12,
                  padding: 10,
                  maxWidth: '90%',
                }}>
                {currentGymnasium.address}
              </Text>
            </Pressable>
          )}

          {currentGymnasium.latitude && currentGymnasium.longitude && (
            <View
              style={{
                width: sw - 40,
                height: sh / 2,
                borderRadius: 10,
                overflow: 'hidden',
              }}>
              <MapViewer
                marker={{
                  latitude: parseFloat(currentGymnasium.latitude),
                  longitude: parseFloat(currentGymnasium.longitude),
                  title: currentGymnasium.name,
                  description: currentGymnasium.address,
                }}
                address={{
                  latitude: parseFloat(currentGymnasium.latitude),
                  longitude: parseFloat(currentGymnasium.longitude),
                  title: currentGymnasium.name,
                  description: currentGymnasium.address,
                }}
                style={{ height: sh / 2 }}
              />
            </View>
          )}
        </View>
      </BottomUpSheet>
    );
  };

  renderDataPage = () => {
    if (!this.state.clubDetail) {
      return (
        <View style={{ padding: 50 }}>
          <Loading size="small" />
        </View>
      );
    }

    const phoneNumbers = this.state.clubDetail.phoneNumber ? this.state.clubDetail.phoneNumber.split(';') : [];

    return (
      <ScrollView>
        <View style={[styles.clubOverviewCtn]}>
          <Text style={[styles.clubOverviewTitle]}>{I18n.t('clubInfos.clubOverview')}</Text>
          <Text style={[styles.descriptionText]}>{this.state.clubDetail.description}</Text>
        </View>

        <View style={[styles.contactBlock]}>
          <Text style={[styles.redTitle]}>{I18n.t('clubInfos.clubContact')}</Text>
          <View>
            <Text style={[styles.contactText]}>{this.state.clubDetail.name}</Text>
            <Text style={[{ marginTop: 10 }, styles.contactText]}>{this.state.clubDetail.address}</Text>
            <Text style={[{ marginTop: 10 }, styles.contactText]}>
              {this.state.clubDetail.postalCode} {this.state.clubDetail.city}
            </Text>

            {this.state.clubDetail.email && this.state.clubDetail.email.trim() !== '' && (
              <Pressable
                style={{ flexDirection: 'row', marginTop: 10 }}
                onPress={() => openMail(this.state.clubDetail.email)}>
                <View style={[{ marginRight: 10, marginBottom: 10 }, styles.actionButton]}>
                  <Icon name="mail" size={25} color={this.state.currentClub.mainColorBottom} />
                </View>
                <Text style={[{ marginTop: 10 }, styles.contactText]}>{this.state.clubDetail.email}</Text>
              </Pressable>
            )}
          </View>

          <View style={{ flex: 1, marginTop: 10 }}>
            {phoneNumbers.length > 0
              ? phoneNumbers.map((phoneNumber, index) => {
                  return (
                    <Pressable
                      key={`Phone-${index}`}
                      onPress={() => openPhone(phoneNumber)}
                      style={{ flexDirection: 'row', marginTop: 10 }}>
                      <View style={[{ marginRight: 10, marginBottom: 10 }, styles.actionButton]}>
                        <Icon name="call" size={25} color={this.state.clubDetail.mainColorBottom} />
                      </View>
                      <Text style={[{ marginTop: 10 }, styles.contactText]}>{phoneNumber}</Text>
                    </Pressable>
                  );
                })
              : null}
          </View>
        </View>

        <View style={[styles.contactBlock]}>
          <Text style={[styles.redTitle]}>{I18n.t('clubInfos.practicalInfos')}</Text>
          <View>
            {!this.state.clubDetail.praticalInfo && (
              <Text style={[styles.contactText]}>{this.state.clubDetail.praticalInfo}</Text>
            )}
            {!this.state.clubDetail.praticalInfo && (
              <Text style={[styles.contactText]}>{I18n.t('clubInfos.unknown')}</Text>
            )}
          </View>
        </View>

        <View style={[styles.contactBlock]}>
          <Text style={[styles.redTitle]}>{Constants.expoConfig.extra.stadiumName}s</Text>
          <View>
            {this.state.clubDetail.gymnasiums.length <= 0 && (
              <Text style={[styles.contactText]}>{I18n.t('clubInfos.unknown')}</Text>
            )}
            {this.state.clubDetail.gymnasiums.length > 0 && (
              <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                {this.state.clubDetail.gymnasiums.map((currentGymnasium, index) => {
                  const imageGymnase = currentGymnasium.imageFilename
                    ? {
                        uri: getImageApiHostOrUrl(currentGymnasium.imageFilename),
                      }
                    : Images.bannerPlaceholder;

                  return (
                    <Pressable
                      key={`Place-${index}`}
                      onPress={() => {
                        this.setState({ currentGymnasium }, () => {
                          this.popupRef && this.popupRef.current.open();
                        });
                      }}
                      style={[styles.clubGymCtn]}>
                      <ShadowCard>
                        <View
                          style={[styles.clubGymCtnShadow, { backgroundColor: this.state.clubDetail.mainColorBottom }]}>
                          <Text
                            style={[styles.clubGymName, { color: this.state.clubDetail.inactiveIconColor }]}
                            numberOfLines={1}
                            ellipsizeMode="tail">
                            {currentGymnasium.name}
                          </Text>
                          <View style={[styles.imageCtn]}>
                            <Image source={imageGymnase} style={[styles.clubGymThumbnail]} />
                          </View>
                          <Text
                            style={[styles.clubAdress, { color: this.state.clubDetail.inactiveIconColor }]}
                            numberOfLines={2}>
                            {currentGymnasium.address}
                          </Text>
                        </View>
                      </ShadowCard>
                    </Pressable>
                  );
                })}
              </ScrollView>
            )}
          </View>
        </View>

        {/* All other individual categories - only for my club! */}
        {this.state.currentClub.id === this.props.session.club.id && (
          <>
            <Text style={[styles.clubOverviewTitle, { marginTop: 20, marginLeft: 20 }]}>
              {I18n.t('clubInfos.clubComposition')}
            </Text>
            <View>
              {/* All other individual categories */}
              <FlatList
                data={this.categories}
                keyExtractor={(item, index) => item.title}
                renderItem={({ item, index }) => {
                  if (!this.props.clubMembers[item.title].length) return;

                  return (
                    <CardCategory
                      club={this.state.currentClub}
                      category={item.title}
                      members={this.props.clubMembers[item.title]}
                      content={item.content ?? 'users'}
                      opened
                      selected={item.selected}
                      allSelectable={false}
                      selectable={false}
                      callable={this.state.displayTelAndChat}
                      chattable={this.state.displayTelAndChat}
                      onOpenChat={(member) => {
                        this.startConversation(member);
                      }}
                    />
                  );
                }}
              />
              <View style={{ height: 60 }} />
            </View>
          </>
        )}
      </ScrollView>
    );
  };

  getClubDetail = () => {
    this.setState({ loading: true });

    ClubsAPI.getClubDetail(this.props.tokens.wsToken, this.state.currentClub?.id).then((response) => {
      if (response && response.success) {
        this.setState({
          clubDetail: response.result,
          // Display phone and chat icons only for my club
          displayTelAndChat: response.result.id === this.props.session.user.Club_id,
          loading: false,
        });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  render() {
    return (
      <>
        <HeaderBanner
          leftIcon="md-arrow-back"
          onLeftButton={() => this.props.navigation.goBack()}
          rightIcon="md-menu"
          onRightButton={async () => {
            this.props.navigation.goBack();
            this.props.navigation.openDrawer();
          }}
        />

        <View style={{ flex: 1, marginTop: 0 }}>
          <this.renderDataPage />
        </View>

        {this.renderBottomUpPanelGymnasium()}

        <Loading loading={this.state.loading} />

        <AlerteUI
          showAlert={this.state.showAlert}
          onClose={() => {
            this.setState({ showAlert: false });
          }}
          title={this.state.alertTitle}
          message={this.state.alertMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { tokens } = state.app;
  const { session, foundOtherClub, affiliatedClubInformation } = state.session;
  const { members, partners } = state.club;

  return {
    session,
    tokens,
    foundOtherClub,
    affiliatedClubInformation,
    clubMembers: { ...members, partners },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClubInfos);
