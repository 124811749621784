const FRLanguage = {
  tabLabel: {
    home: 'Accueil',
    results: 'Results',
    agenda: 'Agenda',
    messages: 'Messages',
    profile: 'My account',
  },
  pageTitle: {
    home: 'Accueil',
    results: 'Results',
    agenda: 'Agenda',
    messages: 'Messages',
    profile: 'My account',
  },
  loading: {
    text: 'Merci de patienter quelques instants...\n\n\n\n\n\nprépare votre application.',
  },
  app: {
    oups: 'Sorry',
    success: 'Success',
    confirm: 'Confirmation',
    information: 'Information',
    error: 'Error',
    yes: 'Yes',
    no: 'No',
    close: 'Close',
    actions: {
      addActuality: 'Add an actuality',
      searchClubs: 'Search a club',
      favorites: 'My favorites',
      filterPendingActualities: 'Filter on pending actualities',
      removePendingFilter: 'Remove filter',
      clubFilter: 'Filter on my club',
      removeClubFilter: 'Remove filter',
    },

    noRecipient: 'Sélectionner le destinataire',
    noEventTypeSelected: "Sélectionner un type d'événement",
    noActuTypeSelected: "Sélectionner un type d'actualité",
    invalidTitle: 'Titre invalide',
    invalidDescription: 'Description invalide',
    invalidAddress: 'Adresse invalide',
    invalidAddressLength: 'Adresse invalide - la longueur ne peut pas excéder 255 caractères.',
    invalidLongitude: 'Longitude invalide',
    invalidLatitude: 'Latitude invalide',
    invalidHomeTeam: 'Équipe domicile invalide',
    invalidAwayTeam: 'Équipe visiteurs invalide',
    invalidHomeClub: 'Club domicile invalide',
    invalidAwayClub: 'Club visiteurs invalide',
    invalidDate: 'Date invalide',
    invalidTime: 'Heure invalide',

    invalidHomeTeamScore: 'Score intérieur invalide',
    invalidAwayTeamScore: 'Score extérieur invalide',
    invalidIsHalfTime: 'Score mi-temps invalide',
    invalidScoreComment: 'Commentaire invalide',

    noProfileType: 'Veuillez sélectionner vos rôles dans le club',
    invalidPhoneNumber: 'Invalid phone number (e.g. 10 digits)',
    invalidBirthdate: 'Invalid birthdate',
    invalidCity: 'Invalid city',
    invalidPostalCode: 'Invalid postcode (e.g. 5 digits)',

    successfulPWChanged: 'Mot de passe modifié',

    invalidLastname: "Le nom n'est pas valide",
    invalidFirstname: "Le prénom n'est pas valide",
    invalidBirthDate: "La date de naissance n'est pas valide",
    invalidEmailAddress: "L'adresse email n'est pas valide",
    invalidPassword: 'Mot de passe invalide',
    noMatchingClub: 'Aucun club trouvé',
    sessionStarterIssue: "Problème d'ouverture de votre session",
    saveEdit: 'Modifier',
    add: 'Ajouter',
    city: 'Ville',
    postalCode: 'Code postal',
    leftHand: 'Gaucher',
    rightHand: 'Droitier',
    front: 'Pivot',
    back: 'Arrière',
    trainNotEditable: 'Les entrainements ne sont pas modifiables',
    convocationNotEditable: 'Les convocations ne sont pas modifiables',
    confirmAgendaDelete: 'Êtes-vous sûr de vouloir supprimer cet agenda',
    possiblePosition: [
      'Gardien de but',
      'Arrière droit',
      'Ailier droit',
      'Pivot',
      'Ailier gauche',
      'Arrière gauche',
      'Demi-centre',
    ],

    notModerator: "Vous n'êtes pas modérateur du club.",
    noFavoriteClub: "Vous n'avez pas encore d'équipe favorite",

    loginPlease: 'Veuillez vous connecter pour continuer',
    noClub: 'Veuillez sélectionner votre club',
    noClubOrLicense: 'Veuillez saisir un numéro de licence ou sélectionner votre club',

    confirmDeleteActu: "Voulez-vous vraiment supprimer l'actualité ?",
    confirmValidateActu: "Voulez-vous vraiment valider l'actualité ?",
    confirmDeleteAgenda: 'Voulez-vous vraiment supprimer cet événement ?',

    noResult: 'Aucun résultat',
    noResultFound: 'Aucun résultat trouvé',

    noSize: 'Sélectionnez une taille',
    noQuantity: 'Veuillez choisir une quantité',
    billing: 'Facturation',
    discoveryMessage: 'Veuillez vous connecter pour profiter au maximum de',
    discoveryMode: "Découvrez l'application",
    notAffiliateMessage:
      "Ce club n'est pas affilié %{app}, vous pouvez lui demander de nous contacter ou nous envoyer ses coordonnées en cliquant ci-dessous",
    notAffiliateMessageMail: 'Affiliation de mon club',

    region: 'Région',
    department: 'Département',
    noClubs: 'Aucun club',

    regionsList: 'Liste des régions',
    departmentsList: 'Liste des départements',
    clubsList: 'Liste des clubs',

    generalError: 'Une erreur est survenue, veuillez réessayer plus tard.',
  },
  account: {
    firstName: 'Prénom',
    lastName: 'Nom',
    email: 'Email',
    password: 'Mot de passe',
    birthDate: 'Date de naissance',
    notAffiliated: "Votre club n'est pas affilié ?",
  },
  login: {
    pageTitle: 'Connexion',
    saveAccess: 'Sauvegarder mes accès',
    signIn: 'Se connecter',
    failed: 'Connexion échouée. Vérifiez vos identifiants et essayez à nouveau.',
    signInWithoutVersion: 'Se connecter',
    lostPassword: 'Forgot your password?',
    newAccount: 'Join us!',
  },
  lostPassword: {
    guide: 'Veuillez saisir votre adresse mail pour\nrecevoir un nouveau mot de passe',
    send: 'Envoyer',
  },
  datePicker: {
    confirm: 'Sélectionner',
    cancel: 'Annuler',
  },
  home: {
    general: 'Général',
    club: 'Club',
    pub: 'PUB',
  },
  results: {
    myClub: 'Mon Club',
    favorites: 'Favoris',
    competition: 'Compétitions',
  },
  message: {
    newMessage: 'Nouveau message',
    to: 'À : ',
    cancel: 'icon-md-close',
    add: 'icon-checkmark-outline',
    searchPlaceholder: 'Search a member...',
    categories: {
      undefined: 'xxx',

      teams: 'Teams',
      teamsAll: 'all the teams',

      selectedPartners: 'Selected partners',
      selectedMembers: 'Selected members',
      selectedCategories: 'Selected categories',
      selectedTeams: 'Selected teams',

      found: 'Search results',

      partners: 'Partners',
      partnersAll: 'all the partners',
      referees: 'Referees',
      refereesAll: 'all the referees',
      volunteers: 'Volunteers',
      volunteersAll: 'all the volunteers',
      parents: 'Parents',
      parentsAll: 'all the parents',
      players: 'Players',
      playersAll: 'all the players',
      coaches: 'Coaches',
      coachesAll: 'all the coaches',
      staff: 'Staff',
      staffAll: 'all the staff',
      managers: 'Managers',
      managersAll: 'all the managers',
      board: 'Directors',
      boardAll: 'all the directors',
      others: 'Others',
      othersAll: 'all the others',
    },
    select: 'Sélectionner %{all}',
    deselect: 'Désélectionner %{all}',

    newConvocation: 'New convocation',
    team: 'Équipe',
    groupName: 'Nom de la conversation',
    addGroupName: 'Nom (optionnel)',
    groupCreator: 'Créateur du groupe',
    noParticipants: 'No participants',
    participants: 'Current members: ',
    countParticipants: '%{users} members',
    countCalled: '%{users} called',
    sentConvocation: 'Convocation émise à',
    writeMessage: 'Écrivez votre message',
    newContact: 'Ajout contact',
    confirm: 'Confirmer',
    convocationResponse: 'Reply to a convocation',
    responseYes: 'Yes',
    responseNo: 'No',
    textResponseYes: 'I will attend',
    textResponseNo: 'I will not attend',
    manageGroup: 'Group',
    noConversation: 'Pas de conversations',
    noMessages: 'Aucun message',
  },
  createAccount: {
    pageTitle: 'Account creation',
    previousStep: 'Previous step',
    nextStep: 'Next step',
    profilType: 'Profile type',
    profileSelect: 'Confirm',
    licenseNumber: 'License number',
    photo: 'Photo',
    multiChoice: 'Several choices are possible',
    myClub: 'My club',
    dontHaveLNumber: 'I do not have my license number',
    haveLNumber: 'I have a license number',
    lookForMyClub: 'Search my club',
    createMyAccount: 'Create my account',
    search: 'Search',
    searchClub: 'Search a club',
    searchTeam: 'Search a team',
    chooseRoleInClub: 'Your profiles in the club',
    chooseTeamInClub: 'In which team(s)?',
  },
  addLicenceNumber: {
    pageTitle: 'Veuillez choisir votre club',
  },
  footerTabLabel: {
    home: 'Home',
    result: 'Résultats',
    agenda: 'Agenda',
    message: 'Messages',
    account: 'Mon compte',
  },
  menu: {
    clubInfo: 'Infos Club',
    club: 'Équipes',
    agenda: 'Agenda',
    document: 'Documents',
    shop: 'Boutique',
    partner: 'Partenaires',
    clubSpace: 'Espace Club',
    JoinClub: "S'affilier à ce club",
  },
  actuList: {
    replyYes: 'Oui',
    replyNo: 'Non',
    replyMayBe: 'Peut-être',
    typeActu: 'Actualité',
    typeEvent: 'Événement',
    typeScore: 'Score',
    publishedBy: 'publié par',
    seeMore: 'Voir plus',
    comments: 'Commentaires',
    participants: 'Participants',
    commentPlaceholder: 'Écrire votre commentaire',
    noActualities: 'No news to display',
  },
  createActu: {
    newActuality: 'New actuality',
    editActuality: 'Edit actuality',
    typeActuality: "Type d'actualité",
    title: 'Titre',
    description: 'Description',
    place: 'Quand et où ?',
    addPhoto: 'Ajouter des photos/vidéos :',
    necessaryDocs: 'Documents nécessaires ?',
    askReply: 'Demande de réponse',
    date: 'Date',
    hour: 'Time',
    recipient: 'Recipients',
    create: 'Post',
    photoLimit: 'Vous avez dépassé la limite de 30\nphotos, merci de faire une autre\npublication',
    sentForValidation:
      "Votre demande a bien été prise en compte,\nelle est en attente de validation par\nl'administrateur de votre club.",
    sendActuForModerator: 'votre demande a bien été prise en compte.',
    club1PlaceHolder: 'Team 1 (home)',
    club2PlaceHolder: 'Team 2 (visitors)',
    score: 'Score',
    commentPlaceholder: 'Commentaire',
    halfTime: 'Mi-temps',
    endOfMatch: 'Fin du match',
    mayReply: "(j'y participe, pas, peut-être)",
    everyWeek: 'Récurrent (hebdomadaire)',
    homeClub: 'Home club',
    homeClubTitle: 'Which club hosts at home ?',
    awayClub: 'Visitors club',
    awayClubTitle: 'What is the visiting club ?',
    club: 'Équipe',
    homeTeam: 'Home team',
    homeTeamTitle: 'Which team in the host club ?',
    awayTeam: 'Visitors team',
    awayTeamTitle: 'Which team in the visiting club ?',
    startDate: 'Date de début',
    endDate: 'End of recurrence',
    invalidSameTeam: 'The home team and the visiting team must be different',
  },
  place: {
    add: 'Confirm',
  },
  takePhoto: {
    camera: 'Take a photo/video',
    photo: 'Take a photo',
    video: 'Take a video',
    gallery: 'Choose in the gallery',
    cancel: 'Cancel',
  },
  createEvent: {
    newEvent: 'New event',
    editEvent: 'Event edition',
    eventTypes: "Type d'événements",
    askReply: 'Propose to indicate attending',
    sendNotification: 'Send notifications',
    recipientsAllTeams: 'All teams',
    recipientTeam: 'Which team?',
    recipients: 'Which team(s)?',
  },
  agenda: {
    filter: 'filtre',
    all: 'Terminé(s)',
    future: 'A venir',
    filterEvents: 'Events',
    filterTeams: 'Teams',
    detail: 'Détails',
    impossibleAction: 'Action impossible',
    dontHaveRights: "Vous n'avez pas les droits pour faire cette action.",
    participants: 'Will certainly be present:',
    participantsCount: '%{count} participants',
    noParticipants: 'Nobody answered yet as attendee',
    futureEventDate: 'Next %{day} %{time} ',
    pastEventDate: 'Last %{day} %{time} ',
    noPastEvents: 'No events in the agenda',
    noFutureEvents: 'No events in the agenda',
  },
  match: {
    resume: 'Résumé',
    classify: 'Classement',
    stat: 'Stats',
    matchSheet: 'Feuille de match',
    top: 'Top',
  },
  convocation: {
    confirmPresent: 'Please confirm attending',
    indicatePresent: 'You can informa about your attending to the event',
    closed: 'Convocation dépassée',
    members: 'Membres convoqués',
    showConvoc: 'voir la convocation',
    date: 'Date',
    hour: 'Heure',
    title: 'Titre',
    description: 'Description de la convocation',
    send: 'Envoyer',
    userWillCome: 'Participe',
    maybeWillCome: 'Peut être',
    willNotCome: 'Ne participe pas',
    hoursOfConvocation: 'Date et heure de la convocation',
  },
  myAccount: {
    information: 'Please confirm',
    messageAlertLogout: 'Are you sure that you want to sign-out?',
    messageAlertDeleteAccount: 'Are you sure that you want to delete definitely your %{appName} account for this club?',
    yes: 'Oui',
    no: 'No',
    modifyTheProfile: 'modifier la photo',
    addAvatarProfil: 'Ajouter une photo',
    moderator: 'Moderator',
    firstName: 'First name',
    lastName: 'Last name',
    phone: 'Tél',
    mail: 'Email',
    address: 'Address',
    city: 'City',
    postalCode: 'Zip code',
    licence: 'License',
    allDocuments: 'All the documents',
    identifiers: 'Identifiers',
    password: 'Password',
    signOut: 'Logout',
    deleteMyAccount: 'Sign Out',
    myProfiles: 'My profiles',
    noClubProfiles: 'You have no profiles for the club',
    myClub: 'My club',
    myTeams: 'My teams',
    noTeamsMembership: 'You have no team membership',
    personalInformation: 'Personal information',
    backLeft: 'Arrière gauche',
    backRight: 'Arrière droite',
    usePhoto: 'Choose a photo',
    ok: 'OK',
    retake: 'Take a photo',
    validate: 'Confirm',
    cancel: 'Cancel',

    emailNotValid: 'Adresse email non valide',
    emailAddress: 'Adresse email',
    edit: 'Modifier',
    myProfile: 'My profile',
    settings: 'My settings',

    passwordChange: 'Modification of your password',
    oldPassword: 'Current password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm the new password',
    save: 'Save',

    moderation: {
      title: 'Moderation settings',
      conversations: 'See all conversations',
    },

    favorites: {
      title: 'My favorites',
      regions: 'Regions',
      departments: 'Departments',
      clubs: 'Clubs',
    },

    agreements: 'You must accept the terms of use to log in to the application',
    failed: 'Failed to login. Check your credentials and try again.',
  },
  clubs: {
    searchBarPlaceholder: 'Rechercher une équipe ou un joueur',
    searchBarUserPlaceHolder: 'Find a user',
    notAffiliated1: 'It looks like your club is not yet affiliated to %{appName}',
    notAffiliated2: 'Contact your club to benefit the features of %{appName}',
    notAffiliated3: 'You can signup and use %{appName} features in limited mode',
    notAffiliated4: 'You do not find your club?\nClick here to contact %{appName}',
    contact: 'Contactez',
    coach: 'Entraineurs',
    manager: 'Dirigeants',
    player: 'Joueurs',
    parents: 'Parents',
    agenda: 'Agenda',
  },
  clubInfos: {
    unknown: 'Non renseigné',
    clubOverview: 'Club introduction',
    clubComposition: 'Club composition',
    clubContact: 'Contacts',
    practicalInfos: 'Infos pratiques',
    gymnasium: 'Gymnases',
    gym: 'Gymnase',
    manager: 'Dirigeants',
  },
  player: {
    teammate: 'Équipiers',
    type: 'Joueur',
    agenda: 'Agenda',
    licence: 'Licence',
    licenceNumber: 'Numéro de licence',
    isLicenseConfirmed: 'Licence vérifié',
    askLicenseRenewal: 'Licence à renouveler',
  },
  notification: {
    pageTitle: 'NOTIFICATIONS',
  },
  partner: {
    viewWebSite: 'voir le site web',
  },
  shop: {
    productReference: 'référence du produits',
    size: 'Taille',
    amount: 'Quantité',
    totalPrice: 'Total price',
    order: 'Commander',
    sendOrder: 'Envoyer la commande',
    cancelTheOrder: 'Annuler la commande',
    billing: 'Facturation',
    lastName: 'Nom',
    firstName: 'Prénom',
    address: 'Adresse',
    postalCode: 'Code postal',
    phoneNumber: 'N° de téléphone',
    optional: 'facultatif',
    city: 'Ville',
    comment: 'Commentaire',
    enterFirstName: 'Entrer votre prénom',
    enterLastName: 'Entrer votre nom',
    enterAdresse: 'Entrer votre Adresse',
    enterCity: 'Entrer votre code ville',
    enterPostalCode: 'Entrer votre code postal',
    enterComment: 'Entrer votre commentaire',
    quantity: 'Quantity',
    closePicker: 'Fermer',
    congratulateTitle: 'Félicitations',
    congratulateTitleErreur: 'Erreur',
    invalidePostalCode: 'Code Postal invalide',
    invalidePhoneNumber: 'N° de téléphone invalide',
    congratulateText:
      "Votre commande vient d'être envoyée au club. Il vous contactera dès reception pour récupérer et régler votre commande.",
    shopFailedTitle: 'Désolé',
    shopFailedText: "Votre commande n'a pas pu être prise en compte",
    shopError1201: "L'email de facturation est invalide",
    shopError1: "Il semblerait qu'un des champs n'est pas rempli",
    shopError1701: 'Le numéro de phone pour la facturation est invalide',
    shopError1801: 'Nous ne retrouvons plus ce produit',
    shopError1802: "La taille demandée n'est plus disponible",
  },
  documentUpload: {
    titleDocument: 'Titre document',
    information: 'Information',
    yes: 'Oui',
    cancel: 'Annuler',
    messageInfosDeleteDocument: 'Êtes-vous sûr de vouloir supprimer ce document?',
  },
  noInternet: {
    text: 'La connexion Internet semble perdue.\n\nMerci de réessayer ultérieurement.',
  },
  alerts: {
    accept: 'Accept',
    cancel: 'Cancel',
    add: 'Add',
    update: 'Update',
    remove: 'Remove',
    teamSelection: {
      title: 'Add a player to the team',
      message: 'Do you want to add this player to your team?',
    },
    removeTeamUser: {
      title: 'Removing a user from the team',
      message: 'Do you want to remove this player from your team?',
    },
    acceptTeamUser: {
      title: 'Validate a player in the team',
      message: 'Do you want to confirm that this player is joining your team?',
    },
    updateTeam: {
      title: 'Update team',
      message: 'Do you want to update your team?',
      error: 'Sorry, an error occured while updating your team. Please try again later.',
    },
  },
};

export default FRLanguage;
