import Images from '@assets/images';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import ShadowCard from '@components/ShadowCard/ShadowCard';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import { gMaxWidth } from '@configs/styles';
import alert from '@utils/alert';
import { checkIsModeratorOrAdmin, checkIsTeamManager } from '@utils/checkUserRoles';
import I18n from '@utils/i18n';
import { openPhone } from '@utils/openLinks';
import { useDimensions } from '@utils/useDimensions';
import Constants from 'expo-constants';
import React, { useEffect, useState } from 'react';
import { Image, Platform, Pressable, StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { useSelector } from 'react-redux';

/**
 *
 * @param club
 * @param member
 * @param onSelectMember
 * @param roleOverride - if set, overrides the member role/profile
 * @param insideTeam - if the card user is displayed for a team
 * @param selectable - to make the user selectable or not
 * @param callable - to show and activate the phone number if any
 * @param chattable - to make the user chattable (create a conversation with)
 * @param extraIcon - an extra icon to insert in the card
 * @param selectionIcon - default is a square box
 * @param selectedIcon - default is a checkbox
 * @param pendingStatus - if the user has a pending status in the team
 * @param isEditing
 * @param style
 * @param onOpenChat
 * @param onConfirmUserTeam
 * @param onDeleteUserTeam
 * @returns {JSX.Element}
 * @constructor
 */
export default function CardUser({
  club,
  member,
  roleOverride = null,
  insideTeam = false,
  selectable = true,
  callable = true,
  chattable = false,
  extraIcon = null,
  selectionIcon = 'square-outline',
  selectedIcon = 'checkbox-outline',
  pendingStatus = false,
  isEditing = false,
  style = {},
  onSelectMember,
  onOpenChat = () => {},
  onConfirmUserTeam = () => {},
  onDeleteUserTeam = () => {},
}) {
  const debug = false;

  const { session } = useSelector((state) => state.session);
  const { tokens } = useSelector((state) => state.app);

  const { width } = useDimensions().window;

  const [cardWidth, setCardWidth] = useState(1);

  const [hasAccess, setHasAccess] = useState(false);
  const [role, setRole] = useState();

  const [teamIsConfirmed, setTeamIsConfirmed] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertButtons, setAlertButtons] = useState([]);

  useEffect(() => {
    debug && console.debug('CardUser, member', member);
    if (member.confirmed === '1') {
      setTeamIsConfirmed(true);
    }
  }, []);

  useEffect(() => {
    setHasAccess(checkIsModeratorOrAdmin(session.user));
  }, [session]);

  useEffect(() => {
    if (!hasAccess) return;

    let role = member.selectionCategory === 'partners' || member.category === 'partners' ? 'Partenaire' : roleOverride;
    if (!role) {
      role = member.roleLabels ? member.roleLabels : null;
      if (!role) {
        role = member.profileLabels ? member.profileLabels : '-';
      }
    }

    if (member.userId === session.user.id || member.id === session.user.id) {
      role = 'Moi-même';
    }

    if (member.profileIds === '1' || member.profileIds === '2') {
      if (hasAccess && member.licenseNumber) {
        role = `${role} (${member.licenseNumber})`;
      }
    }

    setRole(role);
  }, [hasAccess]);

  useEffect(() => {
    let theWidth = Platform.OS === 'web' ? gMaxWidth : width - 30;
    theWidth = theWidth >= 768 ? gMaxWidth / 2 : width - 20;
    theWidth = insideTeam ? theWidth - 30 : theWidth;
    theWidth = extraIcon ? theWidth - 55 : theWidth;
    theWidth = selectable ? theWidth - 20 : theWidth;
    setCardWidth(theWidth);
  }, [width]);

  return (
    <ShadowCard style={[styles.mainContainer, { width: cardWidth }, style]}>
      <View style={[styles.userCard]}>
        <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Pressable
            disabled={!selectable}
            onPress={() => {
              onSelectMember(member);
            }}
            style={({ pressed }) => [pressed ? { opacity: 0.7 } : {}]}>
            <Image
              style={[styles.userAvatar]}
              source={
                member.logoFilename
                  ? { uri: getImageApiHostOrUrl(member.logoFilename) }
                  : member.avatarPath
                  ? { uri: getImageApiHostOrUrl(member.avatarPath) }
                  : Images.userPlaceholder
              }
            />
          </Pressable>
          <View style={{ flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Pressable
              disabled={!selectable}
              onPress={() => {
                onSelectMember(member);
              }}
              style={({ pressed }) => [pressed ? { opacity: 0.7 } : {}]}>
              <Text style={[styles.userName, { width: cardWidth - 110 }]} numberOfLines={1} ellipsizeMode="tail">
                {member.name ?? `${member.firstName} ${member.lastName}`}
              </Text>

              <Text style={[styles.userRole, { width: cardWidth - 110 }]} numberOfLines={1} ellipsizeMode="tail">
                {role}
              </Text>
            </Pressable>

            {callable && hasAccess && member.showPhoneNumber === '1' && member.phoneNumber && (
              <Pressable
                onPress={() => {
                  if (club.isAffiliated !== '1') {
                    alert(
                      I18n.t('app.oups'),
                      "Vous ne pouvez pas lancer un appel car votre club n'est pas affilié à " +
                        Constants.expoConfig.name
                    );
                    return;
                  }

                  openPhone(member.phoneNumber);
                }}
                style={({ pressed }) => [
                  { flexDirection: 'row', alignItems: 'center' },
                  pressed ? { opacity: 0.7 } : {},
                ]}>
                <View style={[styles.actionButton]}>
                  <Icon name="call" size={16} color={(club && club.mainColorBottom) || Colors.blueCorporate} />
                </View>
                <Text style={[styles.userContact, { color: (club && club.mainColorBottom) || Colors.blueCorporate }]}>
                  {member.phoneNumber}
                </Text>
              </Pressable>
            )}
          </View>
        </View>

        <View style={[styles.userSelection]}>
          {(member.Team_id || member.selectionTeam) && (
            <Icon size={25} color={Colors.gray} name={member.selected ? 'people-outline' : 'people-outline'} />
          )}

          {selectable && (
            <Pressable
              disabled={!selectable}
              onPress={() => onSelectMember(member)}
              style={({ pressed }) => [pressed ? { opacity: 0.7 } : {}]}>
              <Icon
                size={25}
                color={(club && club.mainColorBottom) || Colors.blueCorporate}
                name={member.selected ? selectedIcon : selectionIcon}
              />
            </Pressable>
          )}

          {pendingStatus && !isEditing && checkIsTeamManager(session?.club) && !teamIsConfirmed && (
            <Pressable
              onPress={() => {
                setShowAlert(true);
                setAlertTitle(I18n.t('alerts.acceptTeamUser.title'));
                setAlertMessage(I18n.t('alerts.acceptTeamUser.message'));
                setAlertButtons([
                  {
                    text: I18n.t('alerts.accept'),
                    onPress: () => {
                      setShowAlert(false);
                      onConfirmUserTeam(tokens.wsToken, member.id, member.responsibleTeamId, true);
                    },
                  },
                  {
                    text: I18n.t('alerts.cancel'),
                  },
                ]);
              }}>
              <Icon
                size={25}
                color={(club && club.mainColorBottom) || Colors.blueCorporate}
                name="person-add-outline"
              />
            </Pressable>
          )}

          {!isEditing && chattable && member.id !== session.user.id && (
            <Pressable
              onPress={() => {
                onOpenChat(member);
              }}
              style={({ pressed }) => [
                { flexDirection: 'row', alignItems: 'center' },
                pressed ? { opacity: 0.7 } : {},
              ]}>
              <Icon
                size={25}
                color={(club && club.mainColorBottom) || Colors.blueCorporate}
                name="chatbox-ellipses-outline"
              />
            </Pressable>
          )}
          {pendingStatus && isEditing && checkIsTeamManager(session?.club) && (
            <Pressable
              onPress={() => {
                setShowAlert(true);
                setAlertTitle(I18n.t('alerts.removeTeamUser.title'));
                setAlertMessage(I18n.t('alerts.removeTeamUser.message'));
                setAlertButtons([
                  {
                    text: I18n.t('alerts.remove'),
                    onPress: () => {
                      console.debug(member);
                      onDeleteUserTeam(tokens.wsToken, 'remove', member.responsibleTeamId, [member.id]);
                    },
                  },
                  {
                    text: I18n.t('alerts.cancel'),
                  },
                ]);
              }}
              style={({ pressed }) => [
                { flexDirection: 'row', alignItems: 'center' },
                pressed ? { opacity: 0.7 } : {},
              ]}>
              <Icon
                size={25}
                color={(club && club.mainColorBottom) || Colors.blueCorporate}
                name="person-remove-outline"
              />
            </Pressable>
          )}

          {extraIcon}
        </View>

        {showAlert && (
          <AlerteUI
            showAlert={showAlert}
            onClose={() => {
              setShowAlert(false);
            }}
            title={alertTitle}
            message={alertMessage}
            buttons={alertButtons}
          />
        )}
      </View>
    </ShadowCard>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    marginHorizontal: 10,
    marginTop: 0,
  },
  userCard: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    overflow: 'visible',
    borderColor: 'transparent',
  },
  userAvatar: {
    height: 40,
    width: 40,
    borderRadius: 100,
    marginRight: 5,
  },
  userName: {
    fontFamily: 'appFontMedium',
    color: 'rgba(0,0,0 ,0.6)',
    fontSize: 16,
  },
  userRole: {
    fontFamily: 'appFontRegular',
    color: Colors.red,
    fontSize: 12,
  },
  actionButton: {
    width: 20,
    height: 20,
    borderRadius: 50,
    backgroundColor: Colors.whiteCorporate,
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 5,
  },
  userContact: {
    fontFamily: 'appFontMedium',
    fontSize: 11,
  },
  userSelection: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: Colors.whiteCorporate,
    marginLeft: 'auto',
  },
});
