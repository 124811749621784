import Colors from '@configs/colors';
import React, { useEffect, useState } from 'react';
import { Dimensions, Pressable, StyleSheet, Text } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';

const { width: sw } = Dimensions.get('window');

export const RowCard = ({
  item,
  index,
  rightArrow,
  selectable,
  selectedItemsIds,
  onPress = () => {},
  onSelect = () => {},
}) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (!selectedItemsIds) return;

    setSelected(selectedItemsIds.findIndex((id) => item.id === id) !== -1);
  }, [selectedItemsIds]);

  return (
    <Pressable
      style={({ pressed }) => [
        styles.userInfoCtn,
        pressed ? { opacity: 0.7 } : {},
        styles.rowContainer,
        { backgroundColor: index % 2 !== 0 ? Colors.white : null },
      ]}
      onPress={() => onPress()}>
      <Pressable
        disabled={!selectable}
        style={({ pressed }) => [styles.rowIcon, pressed ? { opacity: 0.5 } : {}]}
        onPress={() => onSelect()}>
        {selectable ? (
          <Icon size={25} color={Colors.black} name={selected ? 'checkbox-outline' : 'square-outline'} />
        ) : (
          <Icon size={25} color={Colors.gray1} name={selected ? 'checkmark' : ''} />
        )}
      </Pressable>

      <Text style={[styles.rowLabel, { color: Colors.blueCorporate }]} numberOfLines={2}>
        {item.label ?? item.name}
      </Text>
      {rightArrow && <Icon name="md-arrow-forward" color={Colors.red} size={32} />}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  rowContainer: {
    borderBottomColor: Colors.gray,
    borderBottomWidth: 1,

    paddingVertical: 5,

    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 10,
  },
  rowIcon: {
    width: 35,
    height: 32,
  },
  rowLabel: {
    fontFamily: 'appFontMedium',
    color: Colors.whiteCorporate,
    fontSize: 16,
    width: sw - 85,
  },
  rightArrow: {},
});
