const Colors = {
  white: '#ffffff',
  black: '#000000',
  blueSky: '#2482F5',
  red: '#F44040',
  green: '#32CD32',
  yellow: '#FF8C00',
  blueBgGradient1: '#061A3E',
  blueBgGradient2: '#1E478E',
  gray: '#e0e0e0',
  gray1: '#aaaaaa',
  grayWhite: '#f7f7f7',
  grayPage: '#f2f2f2',
  iconGray: '#7b7b7b',
  blueBtn1: '#55c9ff',
  blueBtn2: '#1b7fff',
  topBarLightGray: '#d8d8d8',
  notAffiliateOrange: '#FF4500',
  blueCorporate: '#012896',
  redCorporate: '#fe0e00',
  whiteCorporate: '#ffffff',
  textLink: '#813d9c',
};

export default Colors;
