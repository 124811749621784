import { MessageAPI } from '@apis/message';
import Images from '@assets/images';
import ButtonChoice from '@components/ButtonChoice/ButtonChoice';
import CardUser from '@components/CardUser/CardUser';
import HeaderBanner from '@components/HeaderBanner/HeaderBanner';
import Loading from '@components/Loading/Loading';
import TakePhoto from '@components/TakePhoto/TakePhoto';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import { useIsFocused } from '@react-navigation/native';
import { addConversation, editConversation } from '@redux/actions';
import alert from '@utils/alert';
import I18n from '@utils/i18n';
import { useDimensions } from '@utils/useDimensions';
import React, { useEffect, useState } from 'react';
import {Dimensions, FlatList, Image, Pressable, ScrollView, StyleSheet, Text, TextInput, View} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { useDispatch, useSelector } from 'react-redux';

const { width: sw, height: sh } = Dimensions.get('window');

const ChatDetails = ({ navigation, route }) => {
  const debug = false;

  const { width } = useDimensions().window;
  const [numColumns, setNumColumns] = useState(1);
  useEffect(() => {
    setNumColumns(width >= 768 ? 2 : 1);
  }, [width]);

  const isFocused = useIsFocused();

  // For an existing conversation
  const [edition, setEdition] = useState(false);
  const [creation, setCreation] = useState(false);

  const [editing, setEditing] = useState(false);

  const { tokens } = useSelector((state) => state.app);
  const { session, affiliatedClubInformation } = useSelector((state) => state.session);
  const [currentClub, setCurrentClub] = useState(null);

  useEffect(() => {
    debug && console.debug('ChatDetails update current club', affiliatedClubInformation);

    setCurrentClub(affiliatedClubInformation ?? session.club ?? null);
  }, [session, affiliatedClubInformation]);

  const [textColor, setTextColor] = useState(Colors.black);
  useEffect(() => {
    setTextColor((currentClub && currentClub.mainColorBottom) ?? Colors.gray);
  }, [currentClub]);

  const { myConversation } = useSelector((state) => state.chat);

  const [id, setId] = useState(null);
  const [chatName, setChatName] = useState(null);
  const [chatLogo, setChatLogo] = useState(null);
  const [chatCoverFilename, setChatCoverFilename] = useState(null);
  const [parties, setParties] = useState({});
  const [participants, setParticipants] = useState([]);

  const [uploading, setUploading] = useState(false);
  const [showTakePhoto, setShowTakePhoto] = useState(false);

  const [updating, setUpdating] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    debug && console.debug('ChatDetails focused:', isFocused);
    // isFocused && getConversationInfo();
  }, [isFocused]);

  useEffect(() => {
    debug && console.debug('ChatDetails route parameters:', route.params);
    if (!route.params.chat) {
      console.warn('ChatDetails route parameters missing chat!');
      navigation.goBack();
      return;
    }

    if (route.params.chat.id) {
      debug && console.debug('ChatDetails route parameters, update an existing conversation');
      setEdition(route.params.edition ?? false);
      setCreation(false);

      // Existing conversation
      setId(route.params.chat.id);
    } else {
      debug && console.debug('ChatDetails route parameters, create a new conversation');
      // New conversation
      setEdition(false);
      setCreation(true);
    }

    setChatName(route.params.chat.name);
    setParties(route.params.chat.membersRoles);
    setParticipants(route.params.chat.participants);
    debug && console.debug('ChatDetails, participants', route.params.chat.participants);

    // One-to-one conversation ?
    if (route.params.chat.participants.length === 1) {
      const userAvatarPath = route.params.chat.participants[0].avatarPath;
      setChatLogo(userAvatarPath ? { uri: getImageApiHostOrUrl(userAvatarPath) } : Images.userPlaceholder);
      setChatCoverFilename(userAvatarPath);
      debug && console.debug('ChatDetails, set chat cover as1st participant avatar');
    } else {
      setChatLogo(Images.imagePlaceholder);
      setChatCoverFilename(null);
    }
  }, [route.params]);

  useEffect(() => {
    debug && console.debug('ChatDetails update current chat:', myConversation);
    if (!myConversation) {
      return;
    }

    setChatLogo({ uri: getImageApiHostOrUrl(myConversation.photoUrl) });
    setChatCoverFilename(myConversation.photoUrl);
    debug && console.debug('ChatDetails, set chat cover as:', { uri: getImageApiHostOrUrl(myConversation.photoUrl) });
  }, [myConversation]);

  /**
   * Function to upload an image as the conversation's logo
   * @param {string} image - filename as returned by the image API
   */
  const uploadConversationLogo = (image) => {
    debug && console.debug('ChatDetails uploadConversationLogo');

    setUploading(true);
    MessageAPI.uploadConversationCover(image, tokens.wsToken)
      .then((response) => {
        if (response && response.success) {
          setChatLogo({ uri: getImageApiHostOrUrl(response.result.file_url) });
          setChatCoverFilename(response.result.file_name);
        } else {
          setChatLogo(Images.userPlaceholder);
          setChatCoverFilename(null);
          alert(I18n.t('app.oups'), "Une erreur est survenue lors de l'envoi de l'image");
        }
        setUploading(false);
      })
      .catch((error) => {
        alert(I18n.t('app.oups'), "Erreur lors de l'envoi de la photo");
      });
  };

  /**
   *  function called when the user wants to create/update the conversation
   */
  const updateConversation = () => {
    if (updating) return;

    debug && console.debug('ChatDetails updateConversation', edition);
    setUpdating(true);

    if (edition) {
      if (participants.length > 0) {
        debug && console.debug('ChatDetails updateConversation update', id, chatName, parties, chatCoverFilename);

        dispatch(
          editConversation(id, tokens.wsToken, chatName, parties, chatCoverFilename, (hasUpdatedConversation, chat) => {
            debug && console.debug('ChatDetails updateConversation updated', hasUpdatedConversation, chat);

            if (hasUpdatedConversation) {
              navigation.navigate('MessagesStack', {
                screen: 'Conversation',
                params: { chat },
              });
            } else {
              setUpdating(false);
              alert(I18n.t('app.oups'), "La conversation n'a pas pu être enregistrée!");
            }
          })
        );
      } else {
        setUpdating(false);
        alert(I18n.t('app.oups'), 'Il faut au moins une personne dans une conversation');
      }
    } else {
      if (participants.length > 0) {
        debug &&
          console.debug('ChatDetails updateConversation creation', session.user, currentClub.id, chatName, parties);

        dispatch(
          addConversation(
            tokens.wsToken,
            currentClub.id,
            chatName,
            parties,
            chatCoverFilename,
            (hasAddedConversation, chat) => {
              debug && console.debug('ChatDetails updateConversation added', hasAddedConversation, chat);

              if (hasAddedConversation) {
                navigation.popToTop();
              } else {
                setUpdating(false);
                alert(I18n.t('app.oups'), "La conversation n'a pas pu être enregistrée!");
              }
            }
          )
        );
      } else {
        setUpdating(false);
        alert(I18n.t('app.oups'), 'Veuillez sélectionner une ou plusieurs personnes pour commencer une conversation');
      }
    }
  };

  if (!currentClub) {
    return;
  }

  return (
    <View style={{ flex: 1 }}>
      <ScrollView style={{ flex: 1 }}>
        {/* header */}
        <HeaderBanner
          leftIcon="md-arrow-back"
          onLeftButton={() => navigation.goBack()}
          title={chatName || I18n.t('message.manageGroup')}
        />

        {(creation || edition) && (
          <View style={styles.buttonsBar}>
            <ButtonChoice
              label="icon-refresh"
              style={{ justifyContent: 'center', marginHorizontal: 10, width: 60 }}
              onPress={() => {
                if (myConversation) {
                  setChatName(myConversation.name);
                } else {
                  setChatName(route.params.chat.name);
                }
              }}
            />

            {/*{myConversation && <CardChat chat={myConversation} style={{ width: sw - 160, marginHorizontal: 0 }} />}*/}

            <ButtonChoice
              disabled={updating || !chatName}
              label={I18n.t('message.add')}
              style={{ justifyContent: 'center', marginHorizontal: 10, width: 60 }}
              textStyle={{ color: textColor, fontWeight: 'bold' }}
              onPress={() => {
                updateConversation();
              }}
            />
          </View>
        )}

        <View
          style={{
            paddingVertical: 10,
            paddingHorizontal: 10,
            borderBottomColor: creation || edition ? Colors.red : Colors.whiteCorporate,
            borderBottomWidth: 1,
          }}>
          <Text style={[styles.titleText, { color: textColor }]}>{`${I18n.t('message.groupName')} :`}</Text>
          {creation || edition ? (
            <>
              <TextInput
                style={[styles.textInputInfos, { fontSize: edition ? 20 : 18 }]}
                // autoFocus
                underlineColorAndroid="transparent"
                keyboardType="default"
                placeholderTextColor={Colors.iconGray}
                placeholder={chatName}
                value={chatName}
                onChangeText={(text) => {
                  setChatName(text);
                }}
                editable={creation || edition}
              />
              {editing && (
                <Pressable
                  onPress={() => {
                    setEditing(!editing);
                  }}>
                  <Icon size={25} name="return-down-back" color={textColor} />
                </Pressable>
              )}
            </>
          ) : (
            <Text style={[styles.textInputInfos, { fontSize: 18 }]}>{chatName}</Text>
          )}

          {chatLogo && (
            <>
              <Pressable
                disabled={!creation && !edition}
                onPress={() => {
                  setShowTakePhoto(true);
                }}>
                <View style={[styles.photoContainer]}>
                  <Image source={chatLogo} style={[styles.photo]} />
                </View>
              </Pressable>

              <TakePhoto
                visible={showTakePhoto}
                onPhotos={(assets) => {
                  uploadConversationLogo(assets[0]);
                  setShowTakePhoto(false);
                }}
                onCancel={() => {
                  setShowTakePhoto(false);
                }}
              />
            </>
          )}
        </View>

        <View style={{ borderBottomColor: Colors.blueCorporate, borderBottomWidth: 1, paddingVertical: 10 }}>
          <Text style={[styles.titleText, { color: textColor }]}>{`${I18n.t('message.groupCreator')} :`}</Text>
          <CardUser
            club={currentClub}
            roleOverride={I18n.t('message.groupCreator')}
            selectable={false}
            member={myConversation ? myConversation.creator : session.user}
          />
        </View>

        <View>
          <Text style={[styles.titleText, { color: textColor }]}>
            {I18n.t('message.countParticipants', { users: participants.length })}
          </Text>

          <FlatList
            style={{ height: sh / 2 }}
            data={participants}
            key={numColumns}
            numColumns={numColumns}
            keyExtractor={(item, index) => item.id + index}
            nestedScrollEnabled
            renderItem={({ item, index }) => (
              <CardUser key={`Participant-${item.id}-${index}`} club={currentClub} selectable={false} member={item} />
            )}
          />
        </View>
      </ScrollView>

      <View style={{ height: 60 }} />

      <Loading loading={uploading} />
    </View>
  );
};

const styles = StyleSheet.create({
  buttonsBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 0,
    paddingHorizontal: 0,
    borderBottomColor: Colors.whiteCorporate,
    borderBottomWidth: 1,
  },

  titleContainer: {
    width: sw - 40,
    padding: 10,
    paddingTop: 0,
    backgroundColor: Colors.red,
  },
  titleText: {
    fontFamily: 'appFontMedium',
    fontSize: 18,
    marginLeft: 10,
    marginBottom: 5,
    textDecorationLine: 'underline',
  },
  textInputInfos: {
    width: '95%',
    backgroundColor: Colors.grayWhite,
    height: 32,
    borderRadius: 100,

    marginVertical: 10,
    paddingHorizontal: 10,
    fontFamily: 'appFontLight',
    justifyContent: 'center',
  },

  photoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: sw - 20,
    height: 150,
    overflow: 'hidden',
  },
  photo: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },

  appFontRegular: {
    fontFamily: 'appFontRegular',
  },
  appFontMedium: {
    fontFamily: 'appFontMedium',
  },
  whiteText: {
    color: Colors.whiteCorporate,
  },
  redText: {
    color: Colors.red,
  },
  borderBottomGray: {
    borderBottomColor: Colors.gray,
    borderBottomWidth: 0.5,
    padding: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },
  userListItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  userInfoCtn: {
    marginLeft: 15,
  },
});

export default ChatDetails;
