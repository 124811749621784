import CardChat from '@components/CardChat/CardChat';
import HeaderCollapsible, {
  HEADER_MAX_HEIGHT,
  HEADER_MIN_HEIGHT,
} from '@components/HeaderCollapsible/HeaderCollapsible';
import Colors from '@configs/colors';
import { gMaxWidth } from '@configs/styles';
import { useIsFocused } from '@react-navigation/native';
import { getAllConversations, deleteConversation, handleBadgeNotification } from '@redux/actions';
import { UNCHECK_CLUB_MEMBERS, UNCHECK_CLUB_PARTNERS } from '@redux/reducers';
import { SET_MY_CONVERSATION } from '@redux/reducers/ChatReducer';
import alert from '@utils/alert';
import { checkIsAdmin } from '@utils/checkUserRoles';
import I18n from '@utils/i18n';
import React, { useEffect, useRef, useState } from 'react';
import { Image, Platform, View } from 'react-native';
import { FloatingAction } from 'react-native-floating-action';
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import { useDispatch, useSelector } from 'react-redux';

const TabMessage = ({ navigation, route }) => {
  const debug = false;

  const dispatch = useDispatch();

  const isFocused = useIsFocused();

  const floatingAction = useRef();

  const [currentClub, setCurrentClub] = useState(null);

  const { tokens } = useSelector((state) => state.app);
  const { session, affiliatedClubInformation, allAffiliatedClubs, localParameters } = useSelector(
    (state) => state.session
  );
  const { myConversations } = useSelector((state) => state.chat);

  const [hasAdmin, setHasAdmin] = useState(false);

  useEffect(() => {
    debug && console.debug('Message, local parameters', localParameters);
  }, [session]);

  const scrollY = useSharedValue(0);
  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (event) => {
      scrollY.value = event.contentOffset.y;
    },
  });
  const listStyle = useAnimatedStyle(() => {
    return {
      marginTop: interpolate(
        scrollY.value,
        [0, HEADER_MAX_HEIGHT],
        [HEADER_MAX_HEIGHT, HEADER_MIN_HEIGHT],
        Extrapolation.CLAMP
      ),
    };
  });

  useEffect(() => {
    debug && console.debug('Message got focus, route parameters:', isFocused, route.params);
    if (!isFocused || !currentClub) return;

    if (route.params && route.params.notification) {
      const foundIndex = myConversations.findIndex((item) => item.id === route.params.notification.targetId);
      if (foundIndex >= 0) {
        // // TODO - this requires to implement getItemLayout for the FlatList ... else there is no scroll, as of now!
        debug && console.debug('Home, found notification target conversation', route.params.notification.targetId);
        // this.flatListRef.current.scrollToIndex({ index: foundIndex, animated: true, viewOffset: 0, viewPosition: 0.5 });
        // {
        //           action: notificationType === NEW_MESSAGE_TYPE ? 'newMessage' : 'deleteMessage',
        //           message: JSON.parse(notification.data.messageData),
        //           chat: { id: notification.targetId },
        //         }
      }
    } else {
      getMyConversations();
    }

    dispatch(handleBadgeNotification('message', false));
  }, [isFocused]);

  useEffect(() => {
    debug && console.debug('TabMessage update current club', affiliatedClubInformation);

    setCurrentClub(affiliatedClubInformation ?? session.club ?? null);
  }, [session, affiliatedClubInformation]);

  useEffect(() => {
    if (!currentClub) return;
    debug && console.debug('TabMessage updated currentClub');

    if (currentClub.isAffiliated !== '1') {
      navigation.navigate('NotAffiliated');
    } else {
      setHasAdmin(checkIsAdmin(currentClub));

      isFocused && getMyConversations();
    }
  }, [currentClub]);

  const getMyConversations = () => {
    debug && console.debug('TabMessage getAllConversations');
    if (!tokens || !currentClub) {
      debug && console.debug('TabMessage getAllConversations - ignored!', tokens, currentClub);
      return;
    }

    // Do not set the club identifier to get personal AND club conversations
    dispatch(getAllConversations(tokens.wsToken, null, true));
  };

  const newConversation = () => {
    dispatch({
      type: SET_MY_CONVERSATION,
      conversation: null,
    });

    dispatch({ type: UNCHECK_CLUB_MEMBERS });
    dispatch({ type: UNCHECK_CLUB_PARTNERS });

    // No route parameters for a new conversation
    navigation.navigate('MessagesStack', { screen: 'MembersSelection' });
  };

  const viewConversation = (chat) => {
    dispatch({
      type: SET_MY_CONVERSATION,
      conversation: chat,
    });

    // dispatch(getConversationInformation(tokens.wsToken, chat.id));

    navigation.navigate('MessagesStack', {
      screen: 'Conversation',
      params: { chat },
    });
  };

  const alertDeleteConversation = (chatId, isConversation1To1) => {
    if (!hasAdmin && !isConversation1To1) {
      return null;
    }

    alert(I18n.t('app.confirm'), 'Voulez-vous supprimer cette conversation ?', [
      {
        text: 'Annuler',
        onPress: () => null,
      },
      {
        text: 'Ok',
        onPress: () => {
          dispatch(
            deleteConversation(tokens.wsToken, chatId, (hasRemovedConversation) => {
              if (hasRemovedConversation) {
                getMyConversations();
              } else {
                alert(I18n.t('app.oups'), 'Vous ne pouvez pas supprimer cette conversation');
              }
            })
          );
        },
      },
    ]);
  };

  const handleAffiliation = () => {
    debug && console.debug('TabMessage, handleAffiliation');

    dispatch(handleBadgeNotification('message', false));
    getMyConversations();
  };

  const renderItem = ({ item, index }) => {
    if (!item.iAmParticipating) {
      if (localParameters && !localParameters.allConversations) {
        debug && console.debug('TabMessage, ignoring a conversation', item.id);
        return <></>;
      }
    }

    // debug && console.debug('TabMessage, proposing a conversation', item.id);
    return (
      <CardChat
        key={'Chat-' + index}
        chat={item}
        onShowChatting={() => {
          debug && console.debug('TabMessage, request to view a conversation', item.id);
          viewConversation(item);
        }}
        onLongPress={() => {
          debug && console.debug('TabMessage, request to delete a conversation', item.id);
          debug && console.debug(item);
          alertDeleteConversation(item.id, item.participants.length === 1);
        }}
      />
    );
  };

  if (!currentClub) return;

  return (
    <View style={[{ flex: 1, marginBottom: 60 }, Platform.OS === 'web' ? { width: '100%', maxWidth: gMaxWidth } : {}]}>
      <HeaderCollapsible
        navigation={navigation}
        route={route}
        headerTitle={session && (currentClub.name ?? 'Guest')}
        contentScrolled={scrollY}
        displayExchangeIcon={allAffiliatedClubs.length > 1}
        onExchange={(clubId) => handleAffiliation(clubId)}
      />

      {currentClub.isAffiliated === '1' && (
        <>
          <Animated.FlatList
            style={listStyle}
            contentContainerStyle={{ marginTop: 10, marginBottom: 60 }}
            keyExtractor={(item) => `News-${item.id}`}
            // estimatedItemSize={400}
            // initialNumToRender={30}
            maxToRenderPerBatch={2}
            windowSize={3}
            scrollEnabled
            scrollEventThrottle={1}
            showsVerticalScrollIndicator
            onScroll={scrollHandler}
            data={myConversations}
            // leftOpenValue={0}
            // rightOpenValue={-100}
            // useNativeDriver={false}
            renderItem={renderItem}
            refreshing={false}
            onRefresh={() => getMyConversations()}
            onEndReachedThreshold={0.5}
            onEndReached={() => {
              // loadNextPage();
            }}
          />

          <FloatingAction
            ref={floatingAction}
            floatingIcon={
              <Image
                source={require('@assets/icons/fabMenu/menu.png')}
                style={{
                  tintColor: currentClub?.inactiveIconColor ?? Colors.whiteCorporate,
                  width: 20,
                  height: 20,
                  resizeMode: 'contain',
                }}
              />
            }
            color={currentClub?.mainColorBottom ?? Colors.blueCorporate}
            distanceToEdge={{ vertical: 70, horizontal: 30 }}
            visible
            position="right"
            showBackground={false}
            onOpen={() => {
              floatingAction.current.reset();
              newConversation();
            }}
          />
        </>
      )}
    </View>
  );
};

export default TabMessage;
