import Colors from '@configs/colors';
import Constants from 'expo-constants';
import * as WebBrowser from 'expo-web-browser';
import React from 'react';
import { Switch, Platform, View, Text, StyleSheet, Pressable } from 'react-native';

const TosSwitch = ({ color, backgroundColor, title, value, onValueChange }) => {
  return (
    <View style={styles.tosContainer}>
      <Pressable
        style={[styles.tosLink]}
        onPress={() => {
          WebBrowser.openBrowserAsync(Constants.expoConfig.extra.tosUrl);
        }}>
        <Text style={[styles.tosText]}>{title}</Text>
      </Pressable>
      {Platform.OS === 'ios' ? (
        <Switch trackColor={{ false: null, true: color }} value={value} onValueChange={onValueChange} />
      ) : (
        <Switch
          style={styles.tosSwitch}
          thumbColor={color}
          trackColor={{ false: null, true: backgroundColor }}
          value={value}
          onValueChange={onValueChange}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  tosContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 0,
    marginBottom: 0,
    paddingVertical: 5,
  },

  tosLink: {
    width: '80%',
  },

  tosText: {
    fontFamily: 'appFontRegular',
    fontSize: 14,
    color: Colors.whiteCorporate,
    textDecorationLine: 'underline',
  },

  tosSwitch: {
    height: 32,
    // width: '15%',
    transform: [{ scaleX: 0.7 }, { scaleY: 0.7 }],
  },
});

export default TosSwitch;
