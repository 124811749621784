export const RAZ_ACTUALITY = 'RAZ_ACTUALITY';
export const SET_ACTUALITY_LOADING = 'SET_ACTUALITY_LOADING';
export const SET_ACTUALITY_NOT_LOADING = 'SET_ACTUALITY_NOT_LOADING';
export const SET_ACTUALITY_ERROR = 'SET_ACTUALITY_ERROR';
export const SET_ACTUALITIES = 'SET_ACTUALITIES';
export const DELETE_ACTUALITY = 'DELETE_ACTUALITY';
export const VALIDATE_ACTUALITY = 'VALIDATE_ACTUALITY';
export const UPDATE_ACTUALITY = 'UPDATE_ACTUALITY';
// // todo: abandoned trial to update media after actuality loading
// export const UPDATE_ACTUALITY_MEDIA = 'UPDATE_ACTUALITY_MEDIA';

const initialState = {
  loading: false,
  errorCode: '',

  actualities: [],
  inViewActualities: [],
  actualitiesLimit: 10,
  hasNextPage: false,
};

const debug = false;

export default function actualityReducer(state = initialState, payload) {
  switch (payload.type) {
    case 'RESET_FOR_LOGOUT':
      debug && console.debug('ActualityReducer RESET_FOR_LOGOUT');
      return initialState;

    case RAZ_ACTUALITY:
      return {
        ...initialState,
      };

    case SET_ACTUALITY_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SET_ACTUALITY_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };

    case SET_ACTUALITY_ERROR:
      return {
        ...state,
        errorCode: payload.errorCode,
      };

    case SET_ACTUALITIES: {
      debug && console.debug('ActualityReducer SET_ACTUALITIES:');

      if (payload.forceUpdate) {
        debug && console.debug('ActualityReducer SET_ACTUALITIES, force update');
        return {
          ...state,
          actualities: [...payload.data],
          hasNextPage: payload.hasNextPage,
        };
      }

      // Right difference
      const newActualities = payload.data.filter((newItem) => !state.actualities.some((y) => y.id === newItem.id));
      if (newActualities.length) {
        debug && console.debug('ActualityReducer SET_ACTUALITIES, add', newActualities);
        return {
          ...state,
          actualities: [...state.actualities, ...newActualities],
          hasNextPage: payload.hasNextPage,
        };
      }

      return state;
    }

    case UPDATE_ACTUALITY:
      debug && console.debug('ActualityReducer UPDATE_ACTUALITY, add:', payload.data);
      return {
        ...state,
        actualities: state.actualities.map((item) => {
          if (item.id !== payload.data.id) {
            return item;
          }
          return payload.data;
        }),
      };

    // // todo: abandoned trial to update media after actuality loading
    // case UPDATE_ACTUALITY_MEDIA:
    //   debug && console.debug('ActualityReducer UPDATE_ACTUALITY, add:', payload.data);
    //   return {
    //     ...state,
    //     actualities: state.actualities.map((item) => {
    //       if (item.id !== payload.data.id) {
    //         return item;
    //       }
    //       return { ...item, images: payload.data.images };
    //     }),
    //   };
    //
    case DELETE_ACTUALITY: {
      debug && console.debug('ActualityReducer DELETE_ACTUALITY, remove:', payload.actualityId);

      const actualityIndex = state.actualities.findIndex((item) => item.id === payload.actualityId);
      if (actualityIndex >= 0) {
        return {
          ...state,
          actualities: [...state.actualities.slice(0, actualityIndex), ...state.actualities.slice(actualityIndex + 1)],
        };
      }
      return state;
    }

    case VALIDATE_ACTUALITY:
      debug && console.debug('ActualityReducer VALIDATE_ACTUALITY, remove:', payload.actualityId);

      return {
        ...state,
        actualities: state.actualities.map((item) => {
          if (item.id !== payload.actualityId) {
            return item;
          }
          return { ...item, isValid: '1' };
        }),
      };

    case 'ACTUALITY_IS_IN_VIEW': {
      // debug && console.debug('ActualityReducer ACTUALITY_IS_IN_VIEW, add:', payload);
      const actualityIndex = state.inViewActualities
        ? state.inViewActualities.findIndex((actualityId) => actualityId === payload.id)
        : -1;
      if (actualityIndex >= 0 && payload.state === false) {
        return {
          ...state,
          inViewActualities: [
            ...state.inViewActualities.slice(0, actualityIndex),
            ...state.inViewActualities.slice(actualityIndex + 1),
          ],
        };
      }
      if (actualityIndex < 0) {
        return {
          ...state,
          inViewActualities: [...(state.inViewActualities ?? []), payload.id],
        };
      }
      return state;
    }

    case 'RESET_ACTUALITY_IS_IN_VIEW':
      debug && console.debug('ActualityReducer RESET_ACTUALITY_IS_IN_VIEW');
      return {
        ...state,
        inViewActualities: [],
      };

    default:
      return state;
  }
}
