/**
 * All actions about notifications
 */
import {
  ACTUALITY_ANSWER_TYPE,
  ACTUALITY_COMMENT_TYPE,
  ACTUALITY_LIKE_TYPE,
  CONVOCATION_ANSWER_TYPE,
  DELETE_MESSAGE_TYPE,
  LICENSE_TO_RENEW_TYPE,
  NEED_RELAY_ACTUALITY,
  NEED_VALIDATION_ACTUALITY,
  NEED_VALIDATION_DIARY,
  NEW_ACTUALITY_TYPE,
  NEW_CONVOCATION_TYPE,
  NEW_DOCUMENT_TYPE,
  NEW_EVENT_TYPE,
  NEW_MESSAGE_TYPE,
  NotificationAPI,
  PROFILE_VALIDATED_TYPE,
} from '@apis/notification';
import {
  SET_NOTIFICATIONS_ERROR,
  SET_NOTIFICATIONS_LOADING,
  SET_NOTIFICATIONS_NOT_LOADING,
  SET_NOTIFICATIONS,
  SAVE_PUSH_NOTIFICATION_COME_OUT_APPLICATION,
  HANDLE_BADGE_NOTIFICATION,
  DELETE_NOTIFICATION,
  DELETE_ALL_NOTIFICATIONS,
  SORT_NOTIFICATIONS,
} from '@redux/reducers/NotificationsReducer';
import moment from 'moment/moment';

const debug = false;

export function updateNotification(notification) {
  const updatedNotification = { ...notification };

  // Date/time
  updatedNotification.eventDate = moment(notification.createdAt, 'YYYY-MM-DD HH:mm:ss');

  delete updatedNotification.createdAt;
  delete updatedNotification.elapsedSecond;

  return updatedNotification;
}

export function getAllNotifications(token) {
  return async (dispatch) => {
    try {
      const start = Date.now();
      dispatch({ type: SET_NOTIFICATIONS_LOADING });

      NotificationAPI.getAllNotifications(token).then((response) => {
        debug && console.debug(`NotificationsActions getAllNotifications, got:`, response);

        if (response && response.success) {
          dispatch({
            type: SET_NOTIFICATIONS,
            receivedNotifications: response.result.map((item) => updateNotification(item)),
          });
          dispatch({
            type: SORT_NOTIFICATIONS,
          });
        }

        const duration = Date.now() - start;
        setTimeout(() => {
          dispatch({ type: SET_NOTIFICATIONS_NOT_LOADING });
        }, Math.max(1000, duration));
      });
    } catch (error) {
      console.warn('getAllNotifications, catch:', error);
      if (error) {
        dispatch({
          type: SET_NOTIFICATIONS_ERROR,
          errorGetNotification: true,
        });
      }
    }
  };
}

export function handleBadgeNotification(badgeType, increment = false) {
  return async (dispatch) => {
    debug && console.debug(`NotificationsActions handleBadgeNotification, got:`, badgeType, increment);

    dispatch({
      type: HANDLE_BADGE_NOTIFICATION,
      increment,
      badgeType,
    });
  };
}

export function handleBadgeAllNotifications(notificationType, notificationIsOpened) {
  return async (dispatch) => {
    debug &&
      console.debug(`NotificationsActions handleBadgeAllNotifications, got:`, notificationType, notificationIsOpened);

    if (notificationType === -1) {
      dispatch({
        type: HANDLE_BADGE_NOTIFICATION,
        increment: true,
        badgeType: 'notification',
      });
      return;
    }

    if (
      [
        NEW_ACTUALITY_TYPE,
        NEW_EVENT_TYPE,
        ACTUALITY_LIKE_TYPE,
        ACTUALITY_COMMENT_TYPE,
        ACTUALITY_ANSWER_TYPE,
        NEED_RELAY_ACTUALITY,
        NEED_VALIDATION_ACTUALITY,
      ].includes(notificationType)
    ) {
      // type of notification about home screen
      dispatch({
        type: HANDLE_BADGE_NOTIFICATION,
        increment: !notificationIsOpened,
        badgeType: 'home',
      });
    } else if (
      [NEW_CONVOCATION_TYPE, CONVOCATION_ANSWER_TYPE, NEW_EVENT_TYPE, NEED_VALIDATION_DIARY].includes(notificationType)
    ) {
      // type of notification about agenda screen
      dispatch({
        type: HANDLE_BADGE_NOTIFICATION,
        increment: !notificationIsOpened,
        badgeType: 'agenda',
      });
    } else if ([NEW_MESSAGE_TYPE, DELETE_MESSAGE_TYPE].includes(notificationType)) {
      // type of notification about chat screen and messageTab screen
      dispatch({
        type: HANDLE_BADGE_NOTIFICATION,
        increment: !notificationIsOpened,
        badgeType: 'message',
      });
    } else if ([NEW_DOCUMENT_TYPE].includes(notificationType)) {
      // type of notification about document screen
      dispatch({
        type: HANDLE_BADGE_NOTIFICATION,
        increment: !notificationIsOpened,
        badgeType: 'document',
      });
    } else if ([PROFILE_VALIDATED_TYPE, LICENSE_TO_RENEW_TYPE].includes(notificationType)) {
      // type of notification about profilTab screen
      dispatch({
        type: HANDLE_BADGE_NOTIFICATION,
        increment: !notificationIsOpened,
        badgeType: 'profile',
      });
    }
  };
}

export function saveNotificationComeOutApplication(notification) {
  return async (dispatch) => {
    try {
      debug && console.debug(`NotificationActions saveNotificationComeOutApplication, got:`, notification);
      dispatch({
        type: SAVE_PUSH_NOTIFICATION_COME_OUT_APPLICATION,
        notification,
      });
    } catch (error) {
      console.warn('saveNotificationComeOutApplication, catch:', error);
      return null;
    }
  };
}

/**
 * Function to delete a notification
 * @param {*} token
 * @param {*} notificationId
 * @returns
 */
export function deleteNotification(token, notificationId) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_NOTIFICATIONS_LOADING });

      NotificationAPI.deleteNotification(token, notificationId).then((response) => {
        debug && console.debug(`NotificationActions deleteNotification, got:`, response);

        dispatch({ type: SET_NOTIFICATIONS_NOT_LOADING });

        if (response && response.success) {
          dispatch({
            type: DELETE_NOTIFICATION,
            notificationId,
          });
        } else {
          dispatch({ type: SET_NOTIFICATIONS_ERROR });
        }
      });
    } catch (error) {
      console.warn('NotificationActions deleteNotification, catch:', error);
      if (error) {
        dispatch({
          type: SET_NOTIFICATIONS_ERROR,
          errorCode: error,
        });
      }
    }
  };
}

/**
 * Function to delete all notifications
 * @param {*} token
 * @returns
 */
export function deleteAllNotifications(token) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_NOTIFICATIONS_LOADING });

      NotificationAPI.deleteAllNotifications(token).then((response) => {
        debug && console.debug(`NotificationActions deleteAllNotifications, got:`, response);

        dispatch({ type: SET_NOTIFICATIONS_NOT_LOADING });

        if (response && response.success) {
          dispatch({
            type: DELETE_ALL_NOTIFICATIONS,
          });
        } else {
          dispatch({ type: SET_NOTIFICATIONS_ERROR });
        }
      });
    } catch (error) {
      console.warn('NotificationActions deleteAllNotifications, catch:', error);
      if (error) {
        dispatch({
          type: SET_NOTIFICATIONS_ERROR,
          errorCode: error,
        });
      }
    }
  };
}
