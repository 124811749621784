export const URLs = {
  // Application configuration
  getConfig: 'services/request/configuration',

  // Account WS URLs
  getAllProfileTypes: 'services/request/getAllProfileTypes',
  register: 'services/request/registration',
  authenticate: 'services/request/authentification',
  authRefresh: 'services/request/authentificationRefresh',
  logout: 'services/request/disconnect',
  deleteAccount: 'services/request/deleteAccount',
  updateUserAcceptedAgreements: 'services/request/updateUserAcceptedAgreements',
  reportUser: 'services/request/reportUser',
  blockUserPost: 'services/request/blockUserPost',
  reportUserComment: 'services/request/reportUserComment',
  blockUser: 'services/request/blockUser',
  getMyAccount: 'services/request/getMyAccount',
  editMyInfo: 'services/request/editMyInfo',
  editPassword: 'services/request/editPassword',
  forgotPassword: 'services/request/forgotPassword',
  addClubAffiliation: 'services/request/addNewAffiliateByClubIdAndProfileIds',

  // News WS URLs
  addNews: 'services/request/addNews',
  addNewsByClubId: 'services/request/addNewsByClubId',
  deleteNews: 'services/request/deleteNews',
  validateNews: 'services/request/validateNews',
  editNews: 'services/request/editNews',
  getNews: 'services/request/getNews',
  getAllNews: 'services/request/getAllNews',
  answerNews: 'services/request/answerNews',
  commentNews: 'services/request/commentNews',
  likeNews: 'services/request/likeNews',
  getAllPublicNews: 'services/request/getAllPublicNews',
  getPublicNews: 'services/request/getPublicNews',
  uploadActualityPicture: 'services/request/uploadActualityPicture',
  uploadActualityVideo: 'services/request/uploadActualityVideo',

  // Name auto-complete
  getNameAutocompletion: 'services/request/getNameAutocompletion',

  // Diaries WS URLs
  getAllDiaries: 'services/request/getAllDiaries',
  addDiary: 'services/request/addDiary',
  addDiaryByClubId: 'services/request/addDiaryByClubId',
  editDiary: 'services/request/editDiary',
  deleteDiary: 'services/request/deleteDiary',
  getDiary: 'services/request/getDiary',
  answerDiary: 'services/request/answerDiary',
  diariesAutocomplete: 'services/request/clubAutocompletion',
  getDiaryTeams: 'services/request/getTeamInDiaryFilter',
  getDiaryTeamsByClubId: 'services/request/getTeamInDiaryFilterByClubId',
  getScheduledDiariesByClubId: 'services/request/getScheduledDiariesByClubId',
  getAllDiariesByClubId: 'services/request/getAllDiariesByClubId',

  // Clubs
  listAllClubs: 'services/request/getAllClubs',

  // Get club infos
  getClubDetail: 'services/request/getClubDetail',
  getClubComposition: 'services/request/clubComposition',

  // Team URLs
  getTeamsList: 'services/request/getTeamList',
  getOpenTeamList: 'services/request/getOpenTeamList',
  getTeamDetail: 'services/request/getTeamDetail',
  addFavoriteTeam: 'services/request/addFavoriteTeam',
  removeFavoriteTeam: 'services/request/removeFavoriteTeam',
  confirmUserTeam: 'services/request/confirmUserTeam',
  editTeam: 'services/request/editTeam',
  uploadTeamBanner: 'services/request/uploadTeamBanner',

  // ClubPartners URLs
  getAllPartners: 'services/request/getAllPartners',
  getAllPartnersByClubId: 'services/request/getAllPartnersByClubId',

  // Shop page
  getAllProducts: 'services/request/getAllProducts',
  getAllProductsByClubId: 'services/request/getProductsByClubId',
  orderProduct: 'services/request/orderProduct',

  getAllLeagues: 'services/request/getAllLeagues',
  getCommitteeByLeague: 'services/request/getCommitteeByLeague',
  getCommitees: 'services/request/committees',

  // Results
  getFavoriteCompetitions: 'services/request/getFavoriteCompetIds',
  likeDislikeCompetition: 'services/request/likeDislikeCompetition',

  // Device
  registerTempPushToken: 'services/request/registerTempPushToken',
  registerDevice: 'services/request/registerDevice',

  // User avatar
  editAvatar: 'services/request/editAvatar',
  uploadAvatar: 'services/request/uploadAvatar',

  // Notifications
  getAllNotifications: 'services/request/getAllNotifications',
  deleteNotification: 'services/request/deleteNotification',
  updateExpoToken: 'services/request/updateExpoToken',

  // Message
  getAllConversations: 'services/request/getAllConversations',
  getAllConversationsByClubId: 'services/request/getAllConversationsByClubId',
  editConversation: 'services/request/editConversation',
  editClubConversation: 'services/request/editClubConversation',
  addConversation: 'services/request/addConversation',
  addClubConversation: 'services/request/addClubConversation',
  getConversationInfo: 'services/request/getConversationInfo',
  saveMessage: 'services/request/saveMessage',
  deleteMessage: 'services/request/deleteMessage',
  uploadConversationCover: 'services/request/uploadConversationCover',
  uploadMessageImage: 'services/request/uploadMessageImage',
  getMessages: 'services/request/getMessages',
  getConvocationResponses: 'services/request/convocationResponses',
  deleteConversation: 'services/request/deleteConversation',

  // Add Document in user
  addDocument: 'services/request/addDocument',

  // Upload Document file
  uploadDocument: 'services/request/uploadDocument',

  // Documents
  getAllDocuments: 'services/request/documents',
  deleteDocument: 'services/request/deleteDocument',
  submitDocument: 'services/request/submitDocument',
  getAllDocumentsBuClubId: 'services/request/getDocumentsByClubId',

  // Image/video storage server
  s3GetObjectUrl: 'services/request/s3GetObjectUrl',
  s3GetPreSignedUrl: 'services/request/s3GetPreSignedUrl',
};

export const Types = {
  /*
  public const NATIONAL_TYPE        = 1;
  public const REGIONAL_TYPE        = 2;
  public const DEPARTEMENTAL        = 3;
  public const COUPE_DE_FRANCE_TYPE = 4;
 */
  COMPETITION_TYPE: {
    NATIONAL: 1,
    REGIONAL: 2,
    DEPARTEMENTAL: 3,
    COUPE_DE_FRANCE: 4,
  },
};
