import { URLs } from '@apis/apisConstants';
import { getApiHost } from '@configs/host';
import { store } from '@redux/store';
import { SET_APP_ONLINE } from '@redux/storeReducer';
import * as Application from 'expo-application';
import Constants from 'expo-constants';

export const Request = {
  // This only to be able to test something with the old legacy API behaviour
  // Do not change unless you know what you are doing -)
  legacy: false,

  authorizationHeader: true,
  removeWsToken: false,

  debug: false,
  debugHeaders: false,
  debugResponse: false,

  get defaultHeaders() {
    /** Legacy (OLD) mobile application headers are:
     (
     ...
     [Cookie] => ci_session=vqs00iv24bdoo54vk1h0ta0kasjlo6qp
     [Content-Type] => application/json
     [Accept-Language] => fr-FR,fr;q=0.9
     [Accept-Encoding] => gzip, deflate, br
     [Accept] => application/json
     [User-Agent] => eHand/1 CFNetwork/1404.0.5 Darwin/22.3.0
     ...
     )

     We set an X-User-Agent extra header for some information about the application

     */
    return this.legacy
      ? {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      : {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-User-Agent': `${Constants.expoConfig.slug}/${Constants.expoConfig.version}/${Application.nativeBuildVersion}`,
        };
  },

  // Redux store state
  state: store.getState(),

  // get the JWT token out of the store
  // (obviously depends on how your store is structured)
  // authToken: state.currentUser.token;

  get: async (url, headers = [], options = null) => {
    let sentHeaders = headers.length
      ? {
          ...Request.defaultHeaders,
          headers,
        }
      : Request.defaultHeaders;

    /* This is not React native compatible code!
     * -----
    const realURL = new URL(url);
    const queryParams = new URLSearchParams(realURL.search);
    if (queryParams.get('wsToken')) {
      if (Request.authorizationHeader) {
        sentHeaders = {
          ...sentHeaders,
          Authorization: `Bearer ${queryParams.get('wsToken')}`,
        };
      }
      if (Request.removeWsToken) {
        queryParams.delete('wsToken');
        realURL.search = queryParams.toString();
      }
    }
    */
    let realURL = url;
    if (realURL.includes('wsToken')) {
      const regex = new RegExp('[?&]wsToken(=([^&#]*)|&|#|$)');
      const matches = regex.exec(url);
      if (Request.authorizationHeader) {
        sentHeaders = {
          ...sentHeaders,
          Authorization: `Bearer ${matches[2]}`,
        };
      }
      if (Request.removeWsToken) {
        realURL = url.replace(matches[0], '');
      }
    }
    Request.debug && console.debug(`API get '${realURL}'`);
    Request.debug && Request.debugHeaders && console.debug(`API get headers:`, sentHeaders);

    return fetch(realURL, {
      method: 'GET',
      headers: sentHeaders,
      ...options,
    })
      .then((response) => {
        if (!Request.state['app'].online) {
          store.dispatch({ type: SET_APP_ONLINE, value: true });
        }

        if (response.ok) {
          return response.json();
        }

        if (response.status === 401) {
          // Elipce/Fred - refresh token management!
        }

        console.warn(`API get '${url}', status error: ${response.status}, response: `, response);
        throw new Error(response.status.toString());
      })
      .then((jsonResponse) => {
        Request.debugResponse &&
          console.debug(`API get '${url}', got:`, JSON.stringify(jsonResponse).replace(/(.{2047})..+/, '$1 ...'));

        return jsonResponse;
      })
      .catch((error) => {
        if (error instanceof TypeError) {
          // Networking error
          console.warn(`API get '${url}', network error:`, error);

          store.dispatch({ type: SET_APP_ONLINE, value: false });
        } else {
          console.log(`API get '${url}', there was an error: ${error.message}`);
        }
      });
  },

  post: async (url, data, headers = [], options = null) => {
    Request.debug && console.debug(`API post '${url}'`);

    let sentHeaders = headers.length
      ? {
          ...Request.defaultHeaders,
          headers,
        }
      : Request.defaultHeaders;

    let realURL = url;
    if (data['wsToken'] !== undefined) {
      sentHeaders = {
        ...sentHeaders,
        Authorization: `Bearer ${data['wsToken']}`,
      };
      if (Request.removeWsToken) {
        delete data['wsToken'];
      }
    } else if (realURL.includes('wsToken')) {
      const regex = new RegExp('[?&]wsToken(=([^&#]*)|&|#|$)');
      const matches = regex.exec(url);
      if (Request.authorizationHeader) {
        sentHeaders = {
          ...sentHeaders,
          Authorization: `Bearer ${matches[2]}`,
        };
      }
      if (Request.removeWsToken) {
        realURL = url.replace(matches[0], '');
      }
    }
    Request.debug && console.debug(`API post '${realURL}'`, data);
    Request.debug && Request.debugHeaders && console.debug(`API post headers:`, sentHeaders);

    return fetch(realURL, {
      method: 'POST',
      headers: sentHeaders,
      body: JSON.stringify(data),
      ...options,
    })
      .then((response) => {
        if (!Request.state['app'].online) {
          store.dispatch({ type: SET_APP_ONLINE, value: true });
        }

        if (response.ok) {
          return response.json();
        }

        if (response.status === 401) {
          // Do not fail on logout!
          if (url === getApiHost() + URLs.logout) {
            return Promise.resolve(response.json());
          }

          // Elipce/Fred - refresh token management!
        }

        console.warn(`API post '${url}', status error: ${response.status}, response: `, response);
        throw new Error(response.status.toString());
      })
      .then((jsonResponse) => {
        Request.debugResponse &&
          console.debug(`API post '${url}', got:`, JSON.stringify(jsonResponse).replace(/(.{2047})..+/, '$1 ...'));

        return Promise.resolve(jsonResponse);
      })
      .catch((error) => {
        if (error instanceof TypeError) {
          // Networking error
          console.warn(`API post '${url}', network error:`, error);

          store.dispatch({ type: SET_APP_ONLINE, value: false });
        } else {
          console.log(`API post '${url}', there was an error: ${error.message}`);
        }
      });
  },

  put: async (url, data, headers = [], options = null) => {
    Request.debug && console.debug(`API put '${url}'`);

    let sentHeaders = headers.length
      ? {
          ...Request.defaultHeaders,
          headers,
        }
      : Request.defaultHeaders;

    let realURL = url;
    if (data['wsToken'] !== undefined) {
      sentHeaders = {
        ...sentHeaders,
        Authorization: `Bearer ${data['wsToken']}`,
      };
      if (Request.removeWsToken) {
        delete data['wsToken'];
      }
    } else if (realURL.includes('wsToken')) {
      const regex = new RegExp('[?&]wsToken(=([^&#]*)|&|#|$)');
      const matches = regex.exec(url);
      if (Request.authorizationHeader) {
        sentHeaders = {
          ...sentHeaders,
          Authorization: `Bearer ${matches[2]}`,
        };
      }
      if (Request.removeWsToken) {
        realURL = url.replace(matches[0], '');
      }
    }
    Request.debug && console.debug(`API put '${realURL}'`);
    Request.debug && Request.debugHeaders && console.debug(`API put headers:`, sentHeaders);

    return fetch(realURL, {
      method: 'PUT',
      headers: sentHeaders,
      body: JSON.stringify(data),
      ...options,
    })
      .then((response) => {
        if (!Request.state['app'].online) {
          store.dispatch({ type: SET_APP_ONLINE, value: true });
        }

        if (response.ok) {
          return response.json();
        }

        if (response.status === 401) {
          // Elipce/Fred - refresh token management!
        }

        console.warn(`API put '${url}', status error: ${response.status}, response: `, response);
        throw new Error(response.status.toString());
      })
      .then((jsonResponse) => {
        Request.debugResponse &&
          console.debug(`API put '${url}', got:`, JSON.stringify(jsonResponse).replace(/(.{2047})..+/, '$1 ...'));

        return jsonResponse;
      })
      .catch((error) => {
        if (error instanceof TypeError) {
          // Networking error
          console.warn(`API put '${url}', network error:`, error);

          store.dispatch({ type: SET_APP_ONLINE, value: false });
        } else {
          console.log(`API put '${url}', there was an error: ${error.message}`);
        }
      });
  },
};
