import ButtonBase from '@components/ButtonBase/ButtonBase';
import { VideoNoLoader } from '@components/Video/VideoNoLoader';
import { VideoWithLoader } from '@components/Video/VideoWithLoader';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import { ImageZoom } from '@likashefqet/react-native-image-zoom';
import React, { useEffect, useMemo } from 'react';
import { StyleSheet, Image, Modal, View, Pressable, Dimensions, Platform } from 'react-native';
import Swiper from 'react-native-web-swiper';

const { width: sw, height: sh } = Dimensions.get('window');

/**
 * Displays a list of images / videos as fullscreen in a modal window
 *
 *    return <MediaZoomViewer medias={mediaList} index={firstImageIndex} visible />;
 *
 * @param medias
 *  list of medias as fetched from the API in actu.images
 * @param index
 *  index of the first images to view
 * @param visible
 *  show/hide the component
 * @param onClose
 *  callback function called on viewer closing
 */
const MediaZoomViewer = ({ medias = [], index = 0, visible = false, onClose = () => {} }) => {
  const debug = false;

  const toView = useMemo(() => {
    // debug && console.debug('MediaZoomViewer, memo medias', medias);
    // Does not re-run unless medias change
    const toView = [];
    medias.map((media) => {
      if (!media.type) {
        if (typeof media === 'string' && media.startsWith('http')) {
          toView.push({ type: 'picture', uri: media });
        } else {
          toView.push({ type: 'picture', media });
        }
      } else {
        if (media.type === 'picture') {
          toView.push({ id: media.id, type: 'picture', uri: media.uri ?? getImageApiHostOrUrl(media.filename) });
        }
        if (media.type === 'video') {
          toView.push({
            id: media.id,
            type: 'video',
            uri: media.uri ?? getImageApiHostOrUrl(media.filename),
            thumbnail: media.thumbnail,
          });
        }
      }
    });
    // debug && console.debug('MediaZoomViewer, toView', toView);
    return toView;
  }, [medias]);

  useEffect(() => {
    debug && console.debug(`MediaZoomViewer, to view list...`, toView);
  }, []);

  if (!visible || toView.length <= 0) {
    return null;
  }

  debug && console.debug(`MediaZoomViewer, visible: ${visible}, index: ${index}`);
  return (
    <Modal visible={visible} transparent>
      <View style={[styles.container, { width: sw, height: sh }]}>
        <Swiper
          containerStyle={styles.swiperContainer}
          vertical={false}
          from={index}
          loop={false}
          timeout={0}
          controlsProps={{
            prevPos: false,
            nextPos: false,
            // prevPos: 'bottom-left',
            // prevTitle: 'Prev',
            // prevTitleStyle: { fontSize: 14, backgroundColor: Colors.green, color: Colors.white },
            // PrevComponent: () => {
            //   return (
            //     <ButtonBase
            //       label="icon-return-down-back"
            //       style={[styles.controlButton]}
            //       textStyle={styles.controlButtonLabel}
            //     />
            //   );
            // },
            // nextPos: 'bottom-right',
            // nextTitle: 'Next',
            // NextComponent: () => {
            //   return (
            //     <ButtonBase
            //       label="icon-return-down-forward"
            //       style={[styles.controlButton]}
            //       textStyle={styles.controlButtonLabel}
            //     />
            //   );
            // },
            dotsTouchable: true,
            dotsPos: 'bottom',
            dotProps: { badgeStyle: { width: 20, height: 40, backgroundColor: Colors.blueCorporate } },
            dotActiveStyle: { backgroundColor: Colors.redCorporate },
            dotsWrapperStyle: { marginBottom: 10 },
            // DotComponent: () => { return (<View style={{ backgroundColor: Colors.blueSky, width: 20, height: 20, borderRadius: 50 }} />)},
            // cellsStyle: {
            //   top: { padding: 5, backgroundColor: 'rgba(0, 0, 0, .3)' },
            //   'top-left': {
            //     /* any custom placeholder style */
            //   },
            // },
            cellsContent: {
              'bottom-right': (
                <ButtonBase
                  label="icon-close"
                  style={[styles.controlButton]}
                  textStyle={styles.controlButtonLabel}
                  onPress={() => {
                    onClose();
                  }}
                />
              ),
            },
          }}>
          {toView.map((item, index) => (
            <View key={'media-' + item.id + index} style={[styles.slideContainer]}>
              {item.type === 'video' && <VideoWithLoader source={item} onClose={() => onClose()} />}
              {/*{item.type === 'video' && <VideoNoLoader source={item} onClose={() => onClose()} />}*/}
              {item.type !== 'video' && (
                <Pressable
                  style={item.type === 'video' ? styles.video : styles.image}
                  onPress={() => {
                    onClose();
                  }}>
                  {item.type === 'picture' && item.media ? (
                    Platform.OS === 'web' ? (
                      <Image source={item.media} style={styles.image} resizeMode="contain" />
                    ) : (
                      <ImageZoom uri={item.media} minScale={0.5} maxScale={3} resizeMode="contain" />
                    )
                  ) : Platform.OS === 'web' ? (
                    <Image source={{ uri: item.uri }} style={styles.image} resizeMode="contain" />
                  ) : (
                    <ImageZoom uri={item.uri} minScale={0.5} maxScale={3} resizeMode="contain" />
                  )}
                </Pressable>
              )}
            </View>
          ))}
        </Swiper>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.grayWhite,
  },
  swiperContainer: {
    width: sw - 10,
    height: sh - 20,
    marginHorizontal: 5,
    marginVertical: 10,
  },
  slideContainer: {
    flex: 1,
    // backgroundColor: Colors.blueSky,
    padding: 1,
    borderRadius: 10,
    // flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  controlButton: {
    backgroundColor: Colors.grayWhite,
    color: Colors.blueCorporate,
    width: 40,
    height: 30,
    marginVertical: 0,
    paddingHorizontal: 5,
  },
  controlButtonLabel: {
    fontSize: 10,
    color: Colors.iconGray,
  },
});

export default MediaZoomViewer;
