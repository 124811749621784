import { clubCategories, teamCategories, ClubsAPI } from '@apis/clubs';
import { PartnerAPI } from '@apis/partner';
import {
  RAZ_CLUB,
  SET_CLUB_ERROR,
  SET_CLUB_LOADING,
  SET_CLUB_NOT_LOADING,
  SET_CLUB_MEMBERS,
  SET_CLUB_UNIQUE_MEMBERS,
  SET_CLUB_PARTNERS,
  SET_ALL_CLUBS,
} from '@redux/reducers';

const debug = false;

export function resetStateClub() {
  return async (dispatch) => {
    dispatch({ type: RAZ_CLUB });
  };
}

/**
 * Get all the clubs
 *
 * @param favorites
 * @param currentClub
 */
export function getAllClubs(favorites = false, currentClub = null) {
  return async (dispatch, getState) => {
    try {
      debug && console.debug(`getAllClubs, favorites: ${favorites}, current club:`, currentClub);

      // Avoid too many requests -> very heavy! download 1.5 Mb
      const favoriteClubs = getState().session.favorites.clubs;
      const allClubs = getState().club.allClubs;
      if (allClubs.length > favoriteClubs.length) {
        debug && console.debug(`getAllClubs, I already know all clubs`);
        // callback(true, allClubs.length);
        return;
      }

      const start = Date.now();
      dispatch({ type: SET_CLUB_LOADING });

      ClubsAPI.getAllClubsWithNoParameters().then((response) => {
        if (response && response.success) {
          debug && console.debug(`getAllClubs, got: ${response.result?.clubs?.length} clubs`);

          let result = [];
          const allClubs = response.result.clubs;
          if (favorites) {
            if (currentClub) {
              result = [{ id: currentClub.id, name: '⭐ ' + currentClub.name }];
            }
            result = [
              ...result,
              ...favoriteClubs.clubs.map((club) => {
                return { id: club.id, name: '⭐ ' + club.name };
              }),
            ];
            result = [
              ...result,
              ...allClubs.filter((club) => {
                if (!result.find((aClub) => aClub.id === club.id)) {
                  return { id: club.id, name: club.name };
                }
              }),
            ];
          } else {
            result = allClubs;
          }

          dispatch({ type: SET_ALL_CLUBS, list: result });

          // callback(true, result.length);
        } else {
          // callback(false);

          dispatch({ type: SET_CLUB_ERROR });
        }
        const duration = Date.now() - start;
        setTimeout(() => {
          dispatch({ type: SET_CLUB_NOT_LOADING });
        }, Math.max(1000, duration));
      });
    } catch (error) {
      console.warn('getAllClubs, catch:', error);
      dispatch({ type: SET_CLUB_ERROR });
    }
  };
}

/**
 * Get the club composition from the API
 *
 * @param {string} token
 * @param {string} clubId
 */
export function getClubComposition(token, clubId) {
  return async (dispatch) => {
    const manageInformationAboutClub = (clubData) => {
      debug && console.debug('getClubComposition, manageInformationAboutClub', clubData);

      // API provides some players outside of teams and some inside teams!
      const allPlayers = clubData.players ?? [];
      debug && console.debug('getClubComposition, players count', allPlayers.length);
      // const filteredPlayers = [...new Map(allPlayers.map((item) => [item['User_id'], item])).values()];
      const teams = clubData.teams ?? [];
      teams.map((team) => {
        if (team.players) {
          allPlayers.push(...team.players);
        } else {
          allPlayers.push(...team.users);
        }
      });
      debug && console.debug('getClubComposition, players + teams players count', allPlayers.length);
      const allFilteredPlayers = [...new Map(allPlayers.map((item) => [item['User_id'], item])).values()];
      debug && console.debug('getClubComposition, filtered players count', allFilteredPlayers.length);

      const coaches = clubData.coaches ?? [];
      const referees = clubData.referees ?? [];
      const volunteers = clubData.volunteers ?? [];
      const parents = clubData.parents ?? [];
      const staff = clubData.staff ?? [];
      const directorDesk = clubData.directorDesk ?? [];
      const caMembers = clubData.caMember ?? [];
      const managers = clubData.managers ?? [];
      const others = clubData.others ?? [];
      const composition = {
        teams,
        members: {
          players: allFilteredPlayers,
          coaches,
          referees,
          staff: [...staff, ...managers],
          board: [...directorDesk, ...caMembers],
          parents,
          volunteers,
          others,
        }
      };

      // Add/set category and name for all the members
      for (const category of clubCategories) {
        if (!composition[category.title]) continue;
        for (const user of composition[category.title]) {
          user.category = category.title;
          if (!user.name) {
            user.name = `${user.firstName} ${user.lastName}`;
          }
        }
      }
      for (const team of composition.teams) {
        for (const category of teamCategories) {
          if (!team[category.title]) continue;
          for (const user of team[category.title]) {
            user.category = category.title;
            if (!user.name) {
              user.name = `${user.firstName} ${user.lastName}`;
            }
          }
        }
      }
      debug && console.debug('ClubActions, manageInformationAboutClub', composition);

      dispatch({
        type: SET_CLUB_MEMBERS,
        data: composition,
      });

      const allMembers = [
        ...directorDesk,
        ...caMembers,
        ...managers,
        ...volunteers,
        ...referees,
        ...staff,
        ...coaches,
        ...clubData.players,
        ...others,
      ];
      dispatch({ type: SET_CLUB_UNIQUE_MEMBERS, data: allMembers });
    };

    try {
      const start = Date.now();
      dispatch({ type: SET_CLUB_LOADING });

      ClubsAPI.getClubComposition(token, clubId).then((response) => {
        debug && console.debug('getClubComposition, got: ', response.result);

        if (response && response.success) {
          // Not needed anywhere - avoid overloading the Redux store!
          // // Store user's club data
          // dispatch({ type: SET_CLUB_COMPOSITION, data: response.result });

          manageInformationAboutClub(response.result);
        }
      });

      const duration = Date.now() - start;
      setTimeout(() => {
        dispatch({ type: SET_CLUB_NOT_LOADING });
      }, Math.max(1000, duration));
    } catch (error) {
      console.warn('getClubComposition, catch:', error);
      dispatch({ type: SET_CLUB_ERROR });
    }
  };
}

/**
 * Get the club partners from the API
 *
 * NOTE that page and limit are useless because ignored in the backend!
 *
 * @param {string} token
 * @param {string} clubId
 * @param page
 * @param limit
 */
export function getClubPartners(token, clubId, page, limit) {
  return async (dispatch) => {
    try {
      const start = Date.now();
      dispatch({ type: SET_CLUB_LOADING });

      // Elipce/Fred: page and limit are useless, because ignored by the backend!
      PartnerAPI.getAllPartnersByCLubId(token, 1, 200, clubId).then((response) => {
        if (response && response.success) {
          debug && console.debug('getClubPartners, got: ', response.result);

          // Store user's club partners
          for (const user of response.result.partners) {
            user.category = 'partners';
            user.avatarPath = user.logoFilename;
          }

          dispatch({ type: SET_CLUB_PARTNERS, data: response.result.partners });
        }

        const duration = Date.now() - start;
        setTimeout(() => {
          dispatch({ type: SET_CLUB_NOT_LOADING });
        }, Math.max(1000, duration));
      });
    } catch (error) {
      console.warn('getClubPartners, catch:', error);
      dispatch({ type: SET_CLUB_ERROR });
    }
  };
}
