import Colors from '@configs/colors';
import React, { useState } from 'react';
import { Pressable, StyleSheet, Text } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';

export default function ButtonValidate({
  isSelected = false,
  showIcon = false,
  label = 'Valider',
  disabled = false,
  style = {},
  textStyle = {},
  onPress = () => {},
}) {
  const [selected, setSelected] = useState(isSelected);

  return (
    <Pressable
      disabled={disabled}
      style={({ pressed }) => [styles.defaultButton, style, pressed ? { opacity: 0.7 } : {}]}
      onPress={() => {
        setSelected(!selected);
        onPress();
      }}>
      <Text style={[styles.buttonLabel, textStyle]}>{label}</Text>

      {showIcon && <Icon name="checkmark" color={Colors.red} size={32} />}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  defaultButton: {
    paddingHorizontal: 15,
    height: 48,
    backgroundColor: Colors.blueCorporate,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    marginVertical: 15,

    overflow: 'visible',
    elevation: 5,
    shadowColor: Colors.iconGray,
    shadowOffset: {
      width: 3,
      height: 5,
    },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  buttonLabel: {
    fontFamily: 'appFontRegular',
    color: Colors.whiteCorporate,
  },
});
