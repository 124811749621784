import { AccountAPI } from '@apis/account';
import { REPLY_MAY_BE, REPLY_NO, REPLY_YES } from '@apis/message';
import Images from '@assets/images';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import BottomUpSheet from '@components/BottomUpSheet/BottomUpSheet';
import ButtonBase from '@components/ButtonBase/ButtonBase';
import MapViewer from '@components/MapViewer/MapViewer';
import MediaViewer from '@components/MediaViewer/MediaViewer';
import SelectModerate from '@components/SelectModerate/SelectModerate';
import ShadowCard from '@components/ShadowCard/ShadowCard';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import { gMaxWidth } from '@configs/styles';
import alert from '@utils/alert';
import I18n from '@utils/i18n';
import { openMap } from '@utils/openLinks';
import { getTimeAgo } from '@utils/time';
import moment from 'moment/moment';
import React, {Component, useState} from 'react';
import {
  ActivityIndicator,
  Dimensions,
  FlatList,
  Image,
  KeyboardAvoidingView,
  Platform,
  Pressable,
  ScrollView,
  Text,
  TextInput,
  View,
} from 'react-native';
import { Autolink } from 'react-native-autolink';
import Icon from 'react-native-vector-icons/Ionicons';
import { connect } from 'react-redux';

import styles from './styles';

const { width: sw, height: sh } = Dimensions.get('window');

class PopupEvent extends Component {
  debug = true;

  popupRef = null;

  commentScrollView = React.createRef();
  scrollToBottom = 0;

  clubLogo =
    this.props.actu.clubLogo !== ''
      ? { uri: getImageApiHostOrUrl(this.props.actu.clubLogo) }
      : require('@assets/logo.png');

  state = {
    visible: false,

    showAlert: false,
    alertTitle: '',
    alertMessage: '',
    alertButtons: null,

    commentText: '',
    writingComment: false,
    sendingComment: false,
    showActionReport: false,

    liking: false,

    loadingYes: false,
    loadingNo: false,

    selectedImageIndex: 0,
    showImageViewer: false,

    currentSelectedComment: null,
  };

  async componentDidMount() {
    if (this.props.popupRef) {
      await this.props.popupRef(this);
    }

    if (this.props.action === 'openDetails') {
      this.open();
    }
  }

  open = (isFromComment = false) => {
    this.setState({ visible: true });

    setTimeout(() => {
      this.popupRef.current.open();
      this.props.onOpen();

      if (this.commentScrollView) {
        if (isFromComment) {
          this.commentScrollView.scrollToEnd();
        }
      }
    }, 500);
  };

  close = () => {
    this.setState({ visible: false });
    this.popupRef.current.close();
  };

  renderComments() {
    if (this.props.actu.comments.length <= 0) {
      return (
        <View style={{ paddingRight: 10, paddingBottom: 30, flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ fontSize: 12, fontFamily: 'appFontRegular', marginTop: 5, color: Colors.blueCorporate }}>
            Aucun commentaire
          </Text>
        </View>
      );
    }

    return (
      <FlatList
        style={{ paddingRight: 10, paddingBottom: 20 }}
        // contentContainerStyle={{ backgroundColor: Colors.green }}
        nestedScrollEnabled
        initialNumToRender={3}
        maxToRenderPerBatch={2}
        windowSize={3}
        removeClippedSubviews
        data={this.props.actu.comments}
        keyExtractor={(item, index) => `Comment-${index}`}
        scrollEnabled
        scrollEventThrottle={0}
        showsVerticalScrollIndicator
        renderItem={({ item, index }) => {
          return (
            <View
              key={'Comment-' + index}
              style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10 }}>
              <Image
                style={{ width: 36, height: 36, borderRadius: 18, marginRight: 5 }}
                source={
                  item.authorAvatar && item.authorAvatar !== ''
                    ? { uri: getImageApiHostOrUrl(item.authorAvatar) }
                    : Images.userPlaceholder
                }
              />
              <View style={{ width: Platform.OS === 'web' ? gMaxWidth : sw - 110, minHeight: 40 }}>
                <View style={{ flexDirection: 'row', marginBottom: 10, height: 25 }}>
                  <Text style={{ color: Colors.red }}>
                    {item.authorFirstname.trim() + ' ' + item.authorLastname.trim()}
                  </Text>
                  <Text style={{ color: Colors.iconGray, opacity: 0.7, fontSize: 12 }}>
                    {' - '}
                    {getTimeAgo(item, 'createdAt')}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <Text
                    style={{
                      color: item.content === 'Message signalé' ? Colors.gray1 : Colors.blueCorporate,
                      fontStyle: item.content === 'Message signalé' ? 'italic' : 'normal',
                      fontSize: 13,
                      lineHeight: 16,
                      marginTop: -20,
                    }}
                    // numberOfLines={3}
                    ellipsizeMode="tail">
                    {item.content}
                  </Text>
                </View>
              </View>
              {item.userId !== this.props.actu.userId && (
                <Pressable
                  style={[styles.headerIcon, { alignSelf: 'flex-end' }]}
                  onPress={() => {
                    this.setState({ showActionReport: true, currentSelectedComment: item });
                  }}>
                  <Icon name="ellipsis-vertical" size={25} color={Colors.blueCorporate} />
                </Pressable>
              )}
            </View>
          );
        }}
      />
    );
  }

  renderClubTeamsScore() {
    const homeTeamLogo = this.props.actu.hClubLogo
      ? {
          uri: getImageApiHostOrUrl(this.props.actu.hClubLogo),
        }
      : Images.imagePlaceholderRounded;

    const awayTeamLogo = this.props.actu.aClubLogo
      ? {
          uri: getImageApiHostOrUrl(this.props.actu.aClubLogo),
        }
      : Images.imagePlaceholderRounded;

    return (
      <ShadowCard overflowStyle={styles.scoreBody}>
        <View style={styles.teamColumn}>
          <Text style={styles.scoreTeamName}>
            {this.props.actu.homeTeamName || this.props.actu.selectedHomeTeamName}
          </Text>

          <Image
            source={homeTeamLogo}
            style={{ width: 48, height: 48 }}
            alt="Home team logo"
            loadingIndicatorSource={Images.loadingSpinner}
            onError={(error) => {
              console.debug(`Loading failed for home team logo ${homeTeamLogo}`, error.nativeEvent);
            }}
          />

          <Text style={styles.scoreClubName} numberOfLines={1} ellipsizeMode="tail">
            {this.props.actu.hClubName.trim()}
          </Text>
        </View>
        <View style={styles.scoreColumn}>
          <>
            {/*<Text style={[styles.scoreText, { alignSelf: 'flex-start' }]}>*/}
            {/*  {this.props.actu.homeTeamScore}*/}
            {/*</Text>*/}
            <Text style={[styles.scoreText, { alignSelf: 'center', marginVertical: 5 }]}>
              {this.props.actu.homeTeamScore}
              {' - '}
              {this.props.actu.awayTeamScore}
            </Text>
            {/*<Text style={[styles.scoreText, { alignSelf: 'flex-end' }]}>*/}
            {/*  {this.props.actu.awayTeamScore}*/}
            {/*</Text>*/}
          </>
        </View>
        <View style={styles.teamColumn}>
          <Text style={styles.scoreTeamName}>
            {this.props.actu.awayTeamName || this.props.actu.selectedAwayTeamName}
          </Text>
          <Image
            source={awayTeamLogo}
            style={{ width: 48, height: 48 }}
            alt="Away team logo"
            loadingIndicatorSource={Images.loadingSpinner}
            onError={(error) => {
              console.debug(`Loading failed for away team logo ${awayTeamLogo}`, error.nativeEvent);
            }}
          />
          <Text style={styles.scoreClubName} numberOfLines={1} ellipsizeMode="tail">
            {this.props.actu.aClubName.trim()}
          </Text>
        </View>
      </ShadowCard>
    );
  }

  render() {
    if (!this.state.visible) {
      return;
    }

    this.debug && console.debug('PopupEvent actuality:', this.state.visible, this.props.actu);

    return (
      <BottomUpSheet
        onPopupRef={(ref) => (this.popupRef = ref)}
        onClose={() => {
          this.setState({ visible: false });
          this.props.onClose();
        }}
        onOpen={() => {
          this.props.onOpen();
        }}>
        <View style={{ flex: 1 }}>
          {/*Header*/}
          <View style={styles.headerContainer}>
            <Image style={styles.headerLogo} source={this.clubLogo} />

            <View>
              <Text style={[styles.headerTitle]} numberOfLines={2} ellipsizeMode="tail">
                {this.props.actu.title}
              </Text>
              <Text style={[styles.headerDate]}>{getTimeAgo(this.props.actu, 'releaseDate')}</Text>
            </View>
          </View>

          <KeyboardAvoidingView
            style={{ flex: 1 }}
            behavior={Platform.OS === 'ios' ? 'position' : 'position'}
            contentContainerStyle={{ marginBottom: 60 }}>
            <ScrollView
              ref={(ref) => {
                this.commentScrollView = ref;
              }}
              nestedScrollEnabled
              keyboardShouldPersistTaps="handled"
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{ padding: 20, paddingTop: 0 }}
              onContentSizeChange={(contentWidth, contentHeight) => {
                if (this.state.writingComment) {
                  if (this.commentScrollView !== undefined && this.commentScrollView !== null) {
                    this.commentScrollView.scrollToEnd();
                  }
                }
                this.scrollToBottom = contentHeight;
              }}>
              {/*Main content*/}
              <View>
                <View style={[styles.actuContainer]}>
                  <Text style={[styles.actuTitle]}>{this.props.actu.title}</Text>

                  {this.props.actu.description && this.props.actu.description !== '' && (
                    <Autolink
                      style={styles.actuDescription}
                      linkStyle={{ padding: 2, margin: 0, color: Colors.textLink, fontWeight: 'bold' }}
                      text={this.props.actu.description}
                      email
                      hashtag="instagram"
                      mention="twitter"
                      phone
                      url
                    />
                  )}

                  {this.props.actu.images.length > 0 && (
                    <MediaViewer actualityId={this.props.actu.id} medias={this.props.actu.images} />
                  )}
                </View>

                <View style={[styles.actuContainer]}>
                  <Text style={[styles.actuTitle]}>{I18n.t('createActu.place')}</Text>

                  <Text style={styles.actuEventDateTime}>
                    {'Le ' +
                      moment(this.props.actu.actualityDate + ' ' + this.props.actu.time, 'DD/MM/YYYY HH:mm').format(
                        'dddd Do MMMM YYYY à HH[h]mm'
                      )}
                  </Text>

                  <Pressable
                    style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10, marginHorizontal: 20 }}
                    onPress={() => {
                      if (!this.props.actu.latitude && !this.props.actu.longitude) {
                        openMap(this.props.actu.address);
                      } else {
                        openMap(this.props.actu.address, this.props.actu.latitude, this.props.actu.longitude);
                      }
                    }}>
                    <View style={[{ marginRight: 10 }, styles.actionButton]}>
                      <Icon name="map" size={25} color={Colors.blueCorporate} />
                    </View>
                    <View style={{ width: sw - 130 }}>
                      <Text
                        style={{
                          fontFamily: 'appFontMedium',
                          color: Colors.blueCorporate,
                          fontSize: 12,
                        }}
                        numberOfLines={1}
                        ellipsizeMode="tail">
                        {`À ${this.props.actu.addressSimple}`}
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'appFontRegular',
                          fontStyle: 'italic',
                          color: Colors.blueCorporate,
                          fontSize: 11,
                        }}
                        numberOfLines={3}
                        ellipsizeMode="tail">
                        {this.props.actu.addressExtended}
                      </Text>
                    </View>
                  </Pressable>

                  <MapViewer
                    marker={{
                      latitude: parseFloat(this.props.actu.latitude),
                      longitude: parseFloat(this.props.actu.longitude),
                      title: this.props.actu.title,
                      address: this.props.actu.description,
                    }}
                    address={{
                      latitude: parseFloat(this.props.actu.latitude),
                      longitude: parseFloat(this.props.actu.longitude),
                      title: this.props.actu.title,
                      description: this.props.actu.description,
                    }}
                    style={{ height: sh / 2 }}
                  />
                </View>

                {this.props.actu.askReply === '1' && (
                  <>
                    <ShadowCard style={[styles.presenceReplyContainer]}>
                      <Text style={{ color: Colors.blueCorporate, opacity: 0.7 }}>
                        {I18n.t('convocation.confirmPresent') + ' :'}
                      </Text>

                      <View style={[styles.replyButtonsContainer]}>
                        <ButtonBase
                          disabled={this.state.loadingYes}
                          loading={this.state.loadingYes}
                          label={I18n.t('actuList.replyYes')}
                          style={[
                            styles.replyButton,
                            {
                              backgroundColor:
                                this.props.actu.myReply === REPLY_YES ? Colors.blueCorporate : Colors.white,
                            },
                          ]}
                          textStyle={{
                            color: this.props.actu.myReply === REPLY_YES ? Colors.white : Colors.blueCorporate,
                          }}
                          onPress={async () => {
                            this.setState({ loadingYes: true }, () => {
                              this.props.onReply(REPLY_YES, () => {
                                this.setState({ loadingYes: false });
                              });
                            });
                          }}
                        />
                        <ButtonBase
                          disabled={this.state.loadingMayBe}
                          loading={this.state.loadingMayBe}
                          label={I18n.t('actuList.replyMayBe')}
                          style={[
                            styles.replyButton,
                            {
                              backgroundColor:
                                this.props.actu.myReply === REPLY_MAY_BE ? Colors.blueCorporate : Colors.white,
                            },
                          ]}
                          textStyle={{
                            color: this.props.actu.myReply === REPLY_MAY_BE ? Colors.white : Colors.blueCorporate,
                          }}
                          onPress={async () => {
                            this.setState({ loadingMayBe: true }, () => {
                              this.props.onReply(REPLY_MAY_BE, () => {
                                this.setState({ loadingMayBe: false });
                              });
                            });
                          }}
                        />
                        <ButtonBase
                          disabled={this.state.loadingNo}
                          loading={this.state.loadingNo}
                          label={I18n.t('actuList.replyNo')}
                          style={[
                            styles.replyButton,
                            {
                              backgroundColor:
                                this.props.actu.myReply === REPLY_NO ? Colors.blueCorporate : Colors.white,
                            },
                          ]}
                          textStyle={{
                            color: this.props.actu.myReply === REPLY_NO ? Colors.white : Colors.blueCorporate,
                          }}
                          onPress={async () => {
                            this.setState({ loadingNo: true }, () => {
                              this.props.onReply(REPLY_NO, () => {
                                this.setState({ loadingNo: false });
                              });
                            });
                          }}
                        />
                      </View>
                    </ShadowCard>

                    <View>
                      <View style={{ borderBottomColor: Colors.blueCorporate, borderBottomWidth: 2 }} />

                      <Text style={[styles.actuTitle]}>{I18n.t('actuList.participants')}</Text>

                      <View style={[styles.replyButtonsContainer]}>
                        <View style={[styles.replyButtonsContainer]}>
                          <Text style={[{ color: Colors.blueCorporate, fontSize: 16 }]}>{`${I18n.t(
                            'actuList.replyYes'
                          )} : ${this.props.actu.actuRepliesYes ?? 0}`}</Text>
                        </View>

                        <View style={[styles.replyButtonsContainer]}>
                          <Text style={[{ color: Colors.blueCorporate, fontSize: 16 }]}>
                            {`${I18n.t('actuList.replyMayBe')} : ${this.props.actu.actuRepliesMaybe ?? 0}`}
                          </Text>
                        </View>

                        <View style={[styles.replyButtonsContainer]}>
                          <Text style={[{ color: Colors.blueCorporate, fontSize: 16 }]}>{`${I18n.t(
                            'actuList.replyNo'
                          )} : ${this.props.actu.actuRepliesNo ?? 0}`}</Text>
                        </View>
                      </View>

                      <View style={{ borderBottomColor: Colors.blueCorporate, borderBottomWidth: 2 }} />
                    </View>
                  </>
                )}
              </View>

              {/*Comments*/}
              <View style={[styles.actuContainer]}>
                <View
                  nestedScrollEnabled
                  keyboardShouldPersistTaps="handled"
                  showsVerticalScrollIndicator={false}
                  style={{ paddingHorizontal: 0, paddingVertical: 0 }}>
                  <Text style={[styles.actuTitle]}>{I18n.t('actuList.comments')}</Text>

                  {this.renderComments()}

                  <View style={[styles.commentContainer, { paddingHorizontal: 20, paddingVertical: 0 }]}>
                    <TextInput
                      placeholder={I18n.t('actuList.commentPlaceholder')}
                      style={styles.commentInput}
                      multiline
                      placeholderTextColor={Colors.blueCorporate + '55'}
                      value={this.state.commentText}
                      onChangeText={(text) => {
                        this.setState({ commentText: text, writingComment: true });
                        if (this.commentScrollView) {
                          this.commentScrollView.scrollToEnd();
                        }
                      }}
                      onFocus={() => {
                        if (this.commentScrollView) {
                          this.commentScrollView.scrollToEnd();
                        }
                      }}
                    />
                    <ActivityIndicator animating={this.state.sendingComment} size={25} color={Colors.blueCorporate} />
                    <Pressable
                      disabled={this.state.sendingComment}
                      style={styles.commentSend}
                      onPress={() => {
                        if (this.state.commentText && this.state.commentText.trim() !== '') {
                          this.setState({ sendingComment: true }, () => {
                            this.debug && console.debug('PopupActuality sending comment...', this.state.commentText);
                            this.props.sendComment(this.state.commentText, () => {
                              this.debug && console.debug('PopupActuality comment sent', this.state.sendingComment);
                              this.setState({ commentText: '', writingComment: false, sendingComment: false });
                              if (this.commentScrollView) {
                                this.commentScrollView.scrollToEnd();
                              }
                            });
                          });
                        } else {
                          alert(I18n.t('app.oups'), "Veuillez saisir un message avant de demander l'envoi");
                        }
                      }}>
                      <Icon size={25} name="paper-plane" color={Colors.blueCorporate} />
                    </Pressable>
                  </View>
                </View>
              </View>

              {/*Buttons*/}
              <View style={[styles.actionButtonsContainer]}>
                <Pressable
                  disabled={this.state.liking}
                  style={[styles.actionButton]}
                  onPress={() => {
                    this.setState({ liking: true }, () => {
                      this.debug && console.debug('PopupActuality liking...');
                      this.props.addToLike(() => {
                        this.setState({ liking: false });
                      });
                    });
                  }}>
                  <Icon
                    size={25}
                    name={this.props.actu.isLikedByUser ? 'heart' : 'heart-outline'}
                    color={Colors.blueCorporate}
                  />
                </Pressable>
                <ActivityIndicator animating={this.state.liking} size={25} color={Colors.blueCorporate} />
                <Pressable style={[styles.actionButton]} onPress={() => this.props.onShare()}>
                  <Icon size={25} name="share-outline" color={Colors.blueCorporate} />
                </Pressable>
              </View>
            </ScrollView>
          </KeyboardAvoidingView>
        </View>

        <SelectModerate
          visible={this.state.showActionReport}
          actuality={this.props.actu}
          comment={this.state.currentSelectedComment}
          onSelected={(choice) => {
            this.setState({ showActionReport: false });
            if (!this.props.tokens) {
              return;
            }
            if (choice.id === 'signalComment') {
              alert(
                I18n.t('app.information'),
                "Etes-vous sûr de vouloir signaler ce commentaire (l'administrateur de l'application sera notifié et le commentaire ne sera plus visible) ?",
                [
                  {
                    text: 'Annuler',
                    onPress: () => null,
                  },
                  {
                    text: 'Ok',
                    onPress: async () => {
                      const userHasReportComment = await AccountAPI.userReportComment(
                        this.props.tokens.wsToken,
                        this.state.currentSelectedComment.id
                      );

                      if (userHasReportComment) {
                        alert(
                          I18n.t('app.information'),
                          "Le commentaire a été signalé et l'administrateur a été informé.",
                          [
                            {
                              text: 'Ok',
                              onPress: async () => {
                                this.props.onRefresh();
                                this.setState({
                                  showActionReport: false,
                                  currentSelectedComment: null,
                                });
                              },
                            },
                          ],
                          {
                            cancelable: false,
                          }
                        );
                      } else {
                        alert(
                          I18n.t('app.information'),
                          'Erreur lors du signalement, veuillez contacter un administrateur.'
                        );
                      }
                    },
                  },
                ]
              );
            }
            if (choice.id === 'signalUser') {
              alert(
                I18n.t('app.information'),
                "Etes-vous sûr de vouloir signaler cet utilisateur (l'administrateur de l'application sera notifié) ?",
                [
                  {
                    text: 'Annuler',
                    onPress: () => null,
                  },
                  {
                    text: 'Ok',
                    onPress: async () => {
                      const userHasReportComment = await AccountAPI.userReportUser(
                        this.props.tokens.wsToken,
                        this.state.currentSelectedComment.userId
                      );

                      if (userHasReportComment) {
                        alert(
                          I18n.t('app.information'),
                          "L'utilisateur a été signalé et l'administrateur a été informé.",
                          [
                            {
                              text: 'Ok',
                              onPress: async () => {
                                this.props.onRefresh();
                                this.setState({
                                  showActionReport: false,
                                  currentSelectedComment: null,
                                });
                              },
                            },
                          ],
                          {
                            cancelable: false,
                          }
                        );
                      } else {
                        alert(
                          I18n.t('app.information'),
                          'Erreur lors du signalement, veuillez contacter un administrateur.'
                        );
                      }
                    },
                  },
                ]
              );
            }
            if (choice.id === 'blockUser') {
              alert(
                I18n.t('app.information'),
                'Etes-vous sûr de vouloir Bloquer cet utilisateur (vous ne pourrez plus voir ses publications/commentaires et il ne pourra plus voir les vôtres) ?',
                [
                  {
                    text: 'Annuler',
                    onPress: () => null,
                  },
                  {
                    text: 'Ok',
                    onPress: async () => {
                      const userHasReportComment = await AccountAPI.blockUser(
                        this.props.tokens.wsToken,
                        this.state.currentSelectedComment.userId
                      );

                      if (userHasReportComment) {
                        alert(
                          I18n.t('app.information'),
                          "L'utilisateur a été bloqué, vous ne pouvez plus voir ses publications/commentaires et il ne peut plus voir les vôtres.",
                          [
                            {
                              text: 'Ok',
                              onPress: async () => {
                                this.props.onRefresh();
                                this.setState({
                                  showActionReport: false,
                                  currentSelectedComment: null,
                                });
                              },
                            },
                          ],
                          {
                            cancelable: false,
                          }
                        );
                      } else {
                        alert(
                          I18n.t('app.information'),
                          'Erreur lors du signalement, veuillez contacter un administrateur.'
                        );
                      }
                    },
                  },
                ]
              );
            }
            if (choice.id === 'blockComment') {
              alert(
                I18n.t('app.information'),
                "Etes-vous sûr de vouloir bloquer ce commentaire (l'administrateur de l'application sera notifié et le commentaire ne sera plus visible) ?",
                [
                  {
                    text: 'Annuler',
                    onPress: () => null,
                  },
                  {
                    text: 'Ok',
                    onPress: async () => {
                      const userHasReportComment = await AccountAPI.userReportComment(
                        this.props.tokens.wsToken,
                        this.state.currentSelectedComment.id
                      );

                      if (userHasReportComment) {
                        alert(
                          I18n.t('app.information'),
                          "Le commentaire a été bloqué et l'administrateur a été informé.",
                          [
                            {
                              text: 'Ok',
                              onPress: async () => {
                                this.props.onRefresh();
                                this.setState({
                                  showActionReport: false,
                                  currentSelectedComment: null,
                                });
                              },
                            },
                          ],
                          {
                            cancelable: false,
                          }
                        );
                      } else {
                        alert(
                          I18n.t('app.information'),
                          'Erreur lors du blocage, veuillez contacter un administrateur.'
                        );
                      }
                    },
                  },
                ]
              );
            }
            if (choice.id === 'blockContent') {
              alert(
                I18n.t('app.information'),
                'Etes-vous sûr de vouloir bloquer ce contenu (vous ne pourrez plus le voir) ?',
                [
                  {
                    text: 'Annuler',
                    onPress: () => null,
                  },
                  {
                    text: 'Ok',
                    onPress: async () => {
                      const isPostBlocked = await AccountAPI.blockUserPost(
                        this.props.tokens.wsToken,
                        this.props.actu.id
                      );

                      if (isPostBlocked) {
                        alert(
                          I18n.t('app.information'),
                          'Ce contenu est bloqué et vous ne le verrez plus.',
                          [
                            {
                              text: 'Ok',
                              onPress: async () => {
                                this.props.onRefresh();
                                this.close();
                              },
                            },
                          ],
                          {
                            cancelable: false,
                          }
                        );
                      } else {
                        alert(
                          I18n.t('app.information'),
                          'Erreur lors du blocage du contenu, veuillez contacter un administrateur.'
                        );
                      }
                    },
                  },
                ]
              );
            }
          }}
          onClose={() => {
            this.setState({ showActionReport: false });
          }}
        />

        <AlerteUI
          showAlert={this.state.showAlert}
          onClose={() => {
            this.setState({ showAlert: false });
          }}
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          buttons={this.state.alertButtons}
        />
      </BottomUpSheet>
    );
  }
}

const mapStateToProps = (state) => {
  const { discovery, tokens } = state.app;
  const { session } = state.session;
  return {
    discovery,
    tokens,
    session,
  };
};

export default connect(mapStateToProps)(PopupEvent);
