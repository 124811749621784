import Colors from '@configs/colors';
import React, { useState } from 'react';
import { Pressable, StyleSheet, Switch, Text, View } from 'react-native';

export default function ButtonSwitch({
  isSelected = false,
  label = 'Bouton',
  style = {},
  switchStyle = {},
  onPress = () => {},
}) {
  const [selected, setSelected] = useState(isSelected);

  return (
    <Pressable
      style={[styles.checkButton, style]}
      onPress={() => {
        onPress(!selected);
        setSelected(!selected);
      }}>
      <Text style={[styles.buttonLabel, styles.appFontRegular]} numberOfLines={2}>
        {label}
      </Text>

      <View pointerEvents="none">
        <Switch
          style={[styles.buttonSwitch, switchStyle]}
          trackColor={{ true: Colors.blueCorporate, false: 'grey' }}
          thumbColor={Colors.grayWhite}
          value={selected}
        />
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  checkButton: {
    width: '90%',
    maxWidth: 500,
    backgroundColor: Colors.whiteCorporate,
    color: Colors.gray,
    borderRadius: 100,
    height: 48,
    paddingHorizontal: 15,
    paddingVertical: 0,
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  buttonLabel: {
    fontFamily: 'appFontRegular',
    fontSize: 18,
    marginRight: 5,
  },
  buttonSwitch: {},
});
