import {
  ACTUALITY_ANSWER_TYPE,
  ACTUALITY_COMMENT_TYPE,
  ACTUALITY_LIKE_TYPE,
  CONVOCATION_ANSWER_TYPE,
  DELETE_MESSAGE_TYPE,
  LICENSE_TO_RENEW_TYPE,
  NEED_VALIDATION_ACTUALITY,
  NEED_VALIDATION_DIARY,
  NEW_ACTUALITY_TYPE,
  NEW_CONVOCATION_TYPE,
  NEW_EVENT_TYPE,
  NEW_MESSAGE_TYPE,
  PROFILE_VALIDATED_TYPE,
} from '@apis/notification';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import GradientBackground from '@components/GradientBackground/GradientBackground';
import Loading from '@components/Loading/Loading';
import NotificationItem from '@components/NotificationItem/NotificationItem';
import Colors from '@configs/colors';
import {
  deleteAllNotifications,
  deleteNotification,
  getAllNotifications,
  handleBadgeNotification,
} from '@redux/actions';
import { SET_NOTIFICATIONS, SORT_NOTIFICATIONS } from '@redux/reducers';
import { UPDATE_INFORMATION_AFFILIATED_CLUB } from '@redux/reducers/SessionReducer';
import I18n from '@utils/i18n';
import * as Notifications from 'expo-notifications';
import React, { useEffect, useState } from 'react';
import { Platform, Pressable, StyleSheet, Text, View } from 'react-native';
import { SwipeListView } from 'react-native-swipe-list-view';
import Icon from 'react-native-vector-icons/Ionicons';
import { useDispatch, useSelector } from 'react-redux';

const ModalNotifications = (props) => {
  const debug = true;

  const [showConfirmDeleteAll, setShowConfirmDeleteAll] = useState(false);

  const { tokens } = useSelector((state) => state.app);
  const { loading, notifications } = useSelector((state) => state.notifications);
  const { allAffiliatedClubs } = useSelector((state) => state.session);

  const dispatch = useDispatch();

  useEffect(() => {
    debug && console.debug('ModalNotifications, mounted');

    setTimeout(() => {
      debug && console.debug('ModalNotifications, initialisation');

      updateNotifications();
    }, 100);

    const notificationListener = Notifications.addNotificationReceivedListener(_handleNotification);
    const responseListener = Notifications.addNotificationResponseReceivedListener((notification) =>
      handleNotificationOpening(notification)
    );

    return () => {
      notificationListener.remove();
      responseListener.remove();
    };
  }, []);

  const updateNotifications = () => {
    debug && console.debug('ModalNotifications, updateNotifications');

    dispatch(getAllNotifications(tokens.wsToken));
    dispatch(handleBadgeNotification('notification', false));
  };

  /**
   * Called when a notification is received by the application and the modal is opened
   * @param notifications
   * @returns {Promise<void>}
   * @private
   */
  const _handleNotification = async (notifications) => {
    debug && console.debug('ModalNotifications, _handleNotification', notifications);
    const notification = await notifications.request.content;
    debug && console.debug('ModalNotifications, _handleNotification', notification);

    dispatch({
      type: SET_NOTIFICATIONS,
      receivedNotification: notification,
    });
    dispatch({
      type: SORT_NOTIFICATIONS,
    });
  };

  const handleNotificationOpening = (notifications) => {
    const notification = notifications.notification.request.content;
    debug && console.debug(`ModalNotifications, handleNotificationOpening:`, notification);

    const clubId = notification && notification.data && notification.data.clubId ? notification.data.clubId : null;
    if (!clubId) {
      console.warn('ModalNotifications, no club id in the notification');
      return;
    }

    const foundClub = allAffiliatedClubs.find((club) => Number(club.id) === Number(clubId));
    if (foundClub) {
      dispatch({ type: UPDATE_INFORMATION_AFFILIATED_CLUB, club: foundClub });
    }

    const notificationType = Number(notification.data.type);

    if (
      notificationType === NEW_ACTUALITY_TYPE ||
      notificationType === ACTUALITY_LIKE_TYPE ||
      notificationType === ACTUALITY_COMMENT_TYPE
    ) {
      debug && console.debug(`ModalNotifications, actuality - redirecting to home`);
      props.navigation.navigate('HomeStack', {
        screen: 'Home',
        params: { notificationToOpen: notification.targetId, notification },
      });
    } else if (notificationType === NEW_MESSAGE_TYPE || notificationType === DELETE_MESSAGE_TYPE) {
      if (!foundClub) {
        console.warn('ModalNotifications, no conversation 8 in a non-affiliated club');
        return null;
      }
      debug && console.debug(`ModalNotifications, conversation - redirecting to messages`);

      // // Get the most recent information about the concerned conversation
      // MessageAPI.getConversationInfo(tokens.wsToken, notification.targetId).then((response) => {
      //   if (response && response.success) {
      //     debug && console.debug(`ModalNotifications, handleNotificationOpening, got conversation:`, response.result);
      //
      props.navigation.navigate('MessagesStack', {
        screen: 'Conversation',
        params: {
          action: notificationType === 8 ? 'newMessage' : 'deleteMessage',
          message: JSON.parse(notification.data.messageData),
          chat: { id: notification.targetId },
        },
      });
      //   }
      // });
    } else if (notificationType === LICENSE_TO_RENEW_TYPE) {
      if (!foundClub) {
        console.warn('ModalNotifications, no conversation 2 in a non-affiliated club');
        return null;
      }

      debug && console.debug(`ModalNotifications, 2 - redirecting to profile`);

      props.navigation.navigate('Profile');
    } else if (notificationType === 6 || notificationType === 7 || notificationType === 10) {
      if (!foundClub) {
        console.warn('ModalNotifications, no agenda in a non-affiliated club');
        return null;
      }

      debug && console.debug(`ModalNotifications, agenda event - redirecting to calendar`);

      props.navigation.navigate('AgendaStack', { screen: 'Agenda' });
    } else if (notificationType === 9) {
      if (!foundClub) {
        console.warn('ModalNotifications, no documents in a non-affiliated club');
        return null;
      }

      debug && console.debug(`ModalNotifications, document - redirecting to documents`);

      props.navigation.navigate('Documents');
    } else {
      props.navigation.navigate('HomeStack', { screen: 'Home' });
    }
  };

  const _handleSingleNotification = (notification) => {
    debug && console.debug(`ModalNotifications, handleSingleNotification:`, notification);

    const clubId = notification && notification.data && notification.data.clubId ? notification.data.clubId : '0';
    const foundClub = allAffiliatedClubs.find((club) => Number(club.id) === Number(clubId));
    if (foundClub) {
      dispatch({ type: UPDATE_INFORMATION_AFFILIATED_CLUB, club: foundClub });
    }

    const notificationType = Number(notification.Notification_type_id);

    dispatch(deleteNotification(tokens.wsToken, notification.id));

    if ([PROFILE_VALIDATED_TYPE, LICENSE_TO_RENEW_TYPE].includes(notificationType)) {
      debug && console.debug(`ModalNotifications, navigate to profile page`);
      props.navigation.goBack();
      props.navigation.navigate('Profile');
    } else if ([NEW_MESSAGE_TYPE, DELETE_MESSAGE_TYPE].includes(notificationType)) {
      debug && console.debug(`ModalNotifications, navigate to message page`);
      props.navigation.goBack();
      props.navigation.navigate('MessagesStack', {
        screen: 'Conversation',
        params: {
          action: notificationType === NEW_MESSAGE_TYPE ? 'newMessage' : 'deleteMessage',
          chat: { id: notification.targetId },
        },
      });
    } else if (
      [NEW_CONVOCATION_TYPE, CONVOCATION_ANSWER_TYPE, NEW_EVENT_TYPE, NEED_VALIDATION_DIARY].includes(notificationType)
    ) {
      debug && console.debug(`ModalNotifications, navigate to agenda page`);
      props.navigation.goBack();
      props.navigation.navigate('AgendaStack', { screen: 'Agenda' });
    } else {
      debug && console.debug(`ModalNotifications, navigate to home page`);
      // {"notification": {"Notification_type_id": "4", "content": "Jean TUARET a ajouté un like sur votre actu ...",
      // "data": null, "eventDate": "2023-08-10T14:28:33.000Z", "id": "753", "isSeen": "0", "targetId": "4971",
      // "title": "Like sur une actualité"}}
      props.navigation.goBack();
      props.navigation.navigate('HomeStack', {
        screen: 'Home',
        params: {
          notification: {
            notificationType,
            targetId: notification.targetId,
          },
        },
      });
    }
  };

  const _deleteNotification = async (id) => {
    dispatch(deleteNotification(tokens.wsToken, id));
  };

  const _deleteAllNotifications = async () => {
    dispatch(deleteAllNotifications(tokens.wsToken));
    props.navigation.goBack();
  };

  return (
    <GradientBackground gradient>
      <View style={[styles.headerTop]}>
        <Pressable
          style={({ pressed }) => [styles.headerButton, pressed ? { backgroundColor: Colors.grayWhite } : {}]}
          onPress={() => {
            props.navigation.goBack();
          }}>
          <Icon name="md-arrow-back" size={25} color={Colors.blueCorporate} />
        </Pressable>

        <Text style={[styles.pageTitle]}>{I18n.t('notification.pageTitle')}</Text>

        <Pressable
          style={({ pressed }) => [styles.headerButton, pressed ? { backgroundColor: Colors.grayWhite } : {}]}
          onPress={() => {
            setShowConfirmDeleteAll(true);
          }}>
          <Icon name="trash-outline" size={25} color={Colors.blueCorporate} />
        </Pressable>
      </View>

      <SwipeListView
        useFlatList
        data={notifications}
        keyExtractor={(item) => item.id}
        contentContainerStyle={{ paddingHorizontal: 20 }}
        leftOpenValue={0}
        rightOpenValue={-70}
        useNativeDriver={false}
        disableRightSwipe
        closeOnRowOpen
        closeOnRowPress
        renderItem={(rowData, rowMap) => {
          return (
            <NotificationItem
              key={rowData.item.id}
              notification={rowData.item}
              onPress={() => {
                setTimeout(() => {
                  _handleSingleNotification(rowData.item);
                }, 500);
              }}
            />
          );
        }}
        renderHiddenItem={(rowData, rowMap) => (
          <View style={styles.hiddenItem}>
            <Pressable
              style={({ pressed }) => [styles.deleteButton, pressed ? { opacity: 0.7 } : {}]}
              onPress={() => {
                setTimeout(() => {
                  _deleteNotification(rowData.item.id);
                }, 500);
              }}>
              <Icon name="trash-outline" color={Colors.whiteCorporate} size={25} />
            </Pressable>
          </View>
        )}
        // onRowOpen={(rowKey, rowMap) => {
        //   // Auto close opened rows
        //   setTimeout(() => {
        //     rowMap[rowKey].closeRow();
        //   }, 3000);
        // }}
      />

      <View style={{ height: 60 }} />

      <Loading loading={loading} />

      <AlerteUI
        showAlert={showConfirmDeleteAll}
        close={() => {
          setShowConfirmDeleteAll(false);
        }}
        title={I18n.t('app.information')}
        message="Êtes-vous sûr de vouloir supprimer toutes les notifications ?"
        buttons={[
          {
            text: I18n.t('app.no'),
            onPress: () => {
              setShowConfirmDeleteAll(false);
            },
          },
          {
            text: I18n.t('app.yes'),
            onPress: () => {
              setShowConfirmDeleteAll(false);
              _deleteAllNotifications();
            },
          },
        ]}
      />
    </GradientBackground>
  );
};

const styles = StyleSheet.create({
  headerTop: {
    flexDirection: 'row',
    paddingTop: Platform.OS === 'ios' ? 20 : 5,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  pageTitle: {
    color: Colors.blueCorporate,
    fontFamily: 'appFontMedium',
  },
  headerButton: {
    width: 60,
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 10,
    borderRadius: 5,
    // backgroundColor: Colors.grayWhite,
  },
  hiddenItem: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 17,
    borderRadius: 10,
    marginLeft: 10,
  },
  viewButton: {
    backgroundColor: Colors.blueCorporate,
    marginTop: 5,
    marginRight: 5,
    width: 50,
    height: 50,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteButton: {
    backgroundColor: Colors.redCorporate,
    marginTop: 5,
    width: 50,
    height: 50,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ModalNotifications;
