import { DiariesAPI, DIARY_CONVOCATION, DIARY_EVENT, DIARY_MATCH, DIARY_TRAINING } from '@apis/diaries';
import Images from '@assets/images';
import ShadowCard from '@components/ShadowCard/ShadowCard';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import PopupAgenda from '@containers/AgendaStack/AgendaPopups/PopupAgenda';
import { updateDiary } from '@redux/actions';
import { SET_AGENDA_ERROR, UPDATE_DIARY } from '@redux/reducers/AgendaReducer';
import moment from 'moment/moment';
import React, { Component } from 'react';
import { Image, Pressable, Text, View } from 'react-native';
import { Autolink } from 'react-native-autolink';
import Icon from 'react-native-vector-icons/Ionicons';
import { connect } from 'react-redux';

import styles from './styles';

class AgendaCard extends Component {
  debug = false;

  popupRef = null;

  colorType =
    this.props.diary.DiaryType_id === DIARY_CONVOCATION
      ? Colors.blueCorporate
      : this.props.diary.DiaryType_id === DIARY_EVENT
      ? Colors.yellow
      : this.props.diary.DiaryType_id === DIARY_TRAINING
      ? Colors.redCorporate
      : this.props.diary.DiaryType_id === DIARY_MATCH
      ? Colors.green
      : Colors.black;

  homeClubLogo = this.props.diary.homeClubLogo
    ? { uri: getImageApiHostOrUrl(this.props.diary.homeClubLogo) }
    : Images.imagePlaceholderRounded;
  awayClubLogo = this.props.diary.awayClubLogo
    ? { uri: getImageApiHostOrUrl(this.props.diary.awayClubLogo) }
    : Images.imagePlaceholderRounded;

  getDiary = async (callback) => {
    this.debug && console.debug('AgendaCard, getDiary:', this.props.diary.id);

    const response = await DiariesAPI.getDiary(this.props.tokens.wsToken, this.props.diary.id);

    this.debug && console.debug(`AgendaCard getDiary ${this.props.diary.isFuture}, got:`, response);
    if (response && response.success) {
      response.result.isFuture = this.props.diary.isFuture;
      this.props.dispatch({
        type: UPDATE_DIARY,
        data: updateDiary(response.result),
      });

      callback && callback(response.success, response.result);
    } else {
      callback && callback(false);

      this.props.dispatch({ type: SET_AGENDA_ERROR });
    }
  };

  reply = async (reply, callback) => {
    this.debug && console.debug('AgendaCard, reply:', reply);
    const responseAnswer = await DiariesAPI.answerDiary(this.props.tokens.wsToken, this.props.diary.id, reply);
    this.debug && console.debug(`AgendaCard reply, got:`, responseAnswer);

    // todo: this is asynchronous ... but why ?
    // await this.getDiary(callback && callback(response.success, response.result));
    const response = await DiariesAPI.getDiary(this.props.tokens.wsToken, this.props.diary.id);

    this.debug && console.debug(`AgendaCard getDiary ${this.props.diary.isFuture}, got:`, response);
    if (response && response.success) {
      response.result.isFuture = this.props.diary.isFuture;
      this.props.dispatch({
        type: UPDATE_DIARY,
        data: updateDiary(response.result),
      });

      callback && callback(response.success, response.result);
    } else {
      callback && callback(false);

      this.props.dispatch({ type: SET_AGENDA_ERROR });
    }
  };

  render() {
    return (
      <>
        <ShadowCard style={[styles.mainContainer]}>
          <Pressable
            style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between' }}
            onPress={() => this.popupRef.open(false)}
            onLongPress={() => this.props.onDeleteDiary(this.props.diary.id)}>
            <View style={[styles.diaryLabel, { backgroundColor: this.colorType }]}>
              {this.props.diary.isRecurrent === '1' ? (
                <>
                  <Text style={styles.diaryEventFormattedDate}>
                    {moment(this.props.diary.eventDate).calendar({ sameElse: 'dddd Do MMMM, HH[h]mm' })}
                  </Text>
                  <Icon name="refresh-circle-outline" size={24} color={Colors.whiteCorporate} />
                </>
              ) : (
                <Text style={styles.diaryEventFormattedDate}>{this.props.diary.theDate}</Text>
              )}
            </View>
            <View style={styles.diaryMain}>
              <Text style={[styles.diaryTitle]} numberOfLines={1} ellipsizeMode="tail">
                {this.props.diary.title}
              </Text>
              {this.props.diary.DiaryType_id === DIARY_MATCH ? (
                <>
                  <View style={styles.scoreBody}>
                    <View style={styles.scoreColumn}>
                      <Text style={styles.scoreTeamName}>{this.props.diary.homeTeamName}</Text>

                      <Image
                        source={this.homeClubLogo}
                        alt="Home team logo"
                        loadingIndicatorSource={Images.loadingSpinner}
                        onError={(error) => {
                          console.debug(`Loading failed for home team logo ${this.homeClubLogo}`, error.nativeEvent);
                        }}
                        style={{ width: 64, height: 64 }}
                      />

                      <Text style={styles.scoreClubName} numberOfLines={2} ellipsizeMode="tail">
                        {this.props.diary.homeClubName || this.props.diary.selectedHomeClubName}
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'column', alignItems: 'center', paddingTop: 10 }}>
                      <Text
                        style={{
                          alignSelf: 'center',
                          marginVertical: '15%',
                          fontFamily: 'appFontMedium',
                          fontSize: 26,
                          color: Colors.blueCorporate,
                        }}
                        numberOfLines={1}
                        ellipsizeMode="tail">
                        {' vs '}
                      </Text>
                    </View>
                    <View style={styles.scoreColumn}>
                      <Text style={styles.scoreTeamName}>{this.props.diary.awayTeamName}</Text>
                      <Image
                        source={this.awayClubLogo}
                        alt="Away team logo"
                        loadingIndicatorSource={Images.loadingSpinner}
                        onError={(error) => {
                          console.debug(`Loading failed for away team logo ${this.awayClubLogo}`, error.nativeEvent);
                        }}
                        style={{ width: 64, height: 64 }}
                      />
                      <Text style={styles.scoreClubName} numberOfLines={2} ellipsizeMode="tail">
                        {this.props.diary.awayClubName || this.props.diary.selectedAwayClubName}
                      </Text>
                    </View>
                  </View>
                </>
              ) : (
                <>
                  {this.props.diary.description && (
                    <Autolink
                      numberOfLines={3}
                      ellipsizeMode="tail"
                      style={styles.diaryDescription}
                      linkStyle={{ padding: 2, margin: 0, color: Colors.textLink, fontWeight: 'bold' }}
                      text={this.props.diary.description}
                      email
                      hashtag="instagram"
                      mention="twitter"
                      phone
                      url
                    />
                  )}
                </>
              )}
            </View>
          </Pressable>
        </ShadowCard>

        <PopupAgenda
          popupRef={(ref) => {
            this.popupRef = ref;
          }}
          diary={this.props.diary}
          onOpen={() => this.getDiary()}
          onClose={() => this.getDiary()}
          addToLike={null}
          onConvocationReply={(value, callback) => this.reply(value, callback)}
          navigation={this.props.navigation}
          action={this.props.action}
          reloadAgenda={() => this.props.reloadAgenda(this.props.diary.isFuture)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { tokens } = state.app;
  const { session } = state.session;

  return { session, tokens };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    // getDiary: (token, id, isFuture) => actions.getDiary(token, id, isFuture),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgendaCard);
