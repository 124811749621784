import { getApiHost } from '@configs/host';

import { Request } from './Request';
import { URLs } from './apisConstants';

export const ShopAPI = {
  getAllProductsByClubId: (wsToken, clubId) => {
    const url = getApiHost() + URLs.getAllProductsByClubId + '?wsToken=' + wsToken + '&clubId=' + clubId;
    return Request.get(url);
  },
  orderProduct: (
    wsToken,
    productId,
    quantity,
    size,

    lastnameFacturation,
    firstnameFacturation,
    emailFacturation,
    addressFacturation,
    codePostalFacturation,
    cityFacturation,
    phoneFacturation,
    commentFacturation,

    lastnameExpedition,
    firstnameExpedition,
    emailExpedition,
    addressExpedition,
    codePostalExpedition,
    cityExpedition,
    phoneExpedition,
    commentExpedition
  ) => {
    const url = getApiHost() + URLs.orderProduct;
    const data = {
      wsToken,
      productId,
      quantity,
      size,

      lastnameFacturation,
      firstnameFacturation,
      emailFacturation,
      addressFacturation,
      codePostalFacturation,
      cityFacturation,
      phoneFacturation,
      commentFacturation,

      lastnameExpedition,
      firstnameExpedition,
      emailExpedition,
      addressExpedition,
      codePostalExpedition,
      cityExpedition,
      phoneExpedition,
      commentExpedition,
    };

    return Request.post(url, data);
  },
};
