import { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';

// custom hook to handle the screen/window dimensions change
export function useDimensions() {
  const [dimensions, setDimensions] = useState({
    window: Dimensions.get('window'),
    screen: Dimensions.get('window'),
  });

  const debug = false;

  useEffect(() => {
    const unsubscribeListener = Dimensions.addEventListener('change', ({ window, screen }) => {
      const { height, width, scale, fontScale } = window;
      debug &&
        console.debug(
          `App, window dimensions changed - width: ${width}, height: ${height}, scale:${scale}, font scale: ${fontScale}`
        );
      setDimensions({ window, screen });
    });
    return () => {
      unsubscribeListener?.remove();
    };
  }, []);

  return dimensions;
}
