import {
  ACTUALITY_ANSWER_TYPE,
  ACTUALITY_COMMENT_TYPE,
  ACTUALITY_LIKE_TYPE,
  CONVOCATION_ANSWER_TYPE,
  DELETE_MESSAGE_TYPE,
  LICENSE_TO_RENEW_TYPE,
  NEED_RELAY_ACTUALITY,
  NEED_VALIDATION_ACTUALITY,
  NEED_VALIDATION_DIARY,
  NEW_ACTUALITY_TYPE,
  NEW_CONVOCATION_TYPE,
  NEW_DOCUMENT_TYPE,
  NEW_EVENT_TYPE,
  NEW_MESSAGE_TYPE,
  PROFILE_VALIDATED_TYPE,
} from '@apis/notification';

export const RAZ_NOTIFICATIONS = 'RAZ_NOTIFICATIONS';
export const SET_NOTIFICATIONS_ERROR = 'SET_NOTIFICATIONS_ERROR';
export const SET_NOTIFICATIONS_LOADING = 'SET_NOTIFICATIONS_LOADING';
export const SET_NOTIFICATIONS_NOT_LOADING = 'SET_NOTIFICATIONS_NOT_LOADING';
// payload notifications
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SORT_NOTIFICATIONS = 'SORT_NOTIFICATIONS';
export const GET_ALL_NOTIFICATIONS_IN_BACKGROUND = 'GET_ALL_NOTIFICATIONS_IN_BACKGROUND';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const RESET_PROPS_NOTIFICATIONS_REDUCER = 'RESET_PROPS_NOTIFICATIONS_REDUCER';
export const ERROR_NOTIFICATION_REDUCER = 'ERROR_NOTIFICATION_REDUCER';
export const DELETE_ALL_NOTIFICATIONS = 'DELETE_ALL_NOTIFICATIONS';
export const GET_MORE_INFORMATION_ABOUT_ELEMENT_IN_AGENDA = 'GET_MORE_INFORMATION_ABOUT_ELEMENT_IN_AGENDA ';
export const SAVE_PUSH_NOTIFICATION_COME_OUT_APPLICATION = 'SAVE_PUSH_NOTIFICATION_COME_OUT_APPLICATION';
export const HANDLE_BADGE_NOTIFICATION = 'HANDLE_BADGE_NOTIFICATION';
export const SAVE_ACTUALITY_TO_SHOW_IN_MODAL = 'SAVE_ACTUALITY_TO_SHOW_IN_MODAL';

export const NOTIFICATION_NEW_ACTUALITY = 'NOTIFICATION_NEW_ACTUALITY';
export const NOTIFICATION_LIKE_ACTUALITY = 'NOTIFICATION_LIKE_ACTUALITY';
export const NOTIFICATION_COMMENT_ACTUALITY = 'NOTIFICATION_COMMENT_ACTUALITY';

const initialState = {
  loading: false,

  notifications: [],
  hasReceivedNotification: false,
  hasRemoveNotification: false,
  errorRemoveNotification: false,
  errorGetNotification: false,
  notificationComeOutOfApplication: null,
  notificationNewActualityId: null,
  notificationLikeActualityId: null,
  notificationCommentActualityId: null,
  numberOfNotifications: 0,
  numberOfHomeNotifications: 0,
  numberOfAgendaNotifications: 0,
  numberOfMessageNotifications: 0,
  actualityToShowInModal: null,
};

const debug = false;

/**
 * Function to get the screen that is concerned by the notification type
 * @param {Number} notificationType
 */
export function getConcernedScreen(notificationType) {
  switch (notificationType) {
    case PROFILE_VALIDATED_TYPE:
    case LICENSE_TO_RENEW_TYPE:
      return 'profile';
    case NEW_ACTUALITY_TYPE:
    case ACTUALITY_LIKE_TYPE:
    case ACTUALITY_COMMENT_TYPE:
    case ACTUALITY_ANSWER_TYPE:
    case NEED_VALIDATION_ACTUALITY:
    case NEED_RELAY_ACTUALITY:
      return 'home';
    case NEW_CONVOCATION_TYPE:
    case CONVOCATION_ANSWER_TYPE:
    case NEW_EVENT_TYPE:
    case NEED_VALIDATION_DIARY:
      return 'agenda';
    case NEW_MESSAGE_TYPE:
    case DELETE_MESSAGE_TYPE:
      return 'message';
    case NEW_DOCUMENT_TYPE:
      return 'document';

    default:
      return null;
  }
}

const notificationsReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case 'RESET_FOR_LOGOUT':
      debug && console.debug('NotificationsReducer RESET_FOR_LOGOUT');
      return initialState;

    case RAZ_NOTIFICATIONS:
      return {
        ...initialState,
      };

    case SET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SET_NOTIFICATIONS_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };

    case SET_NOTIFICATIONS: {
      if (payload.receivedNotifications.length <= 0) {
        return state;
      }

      let counterNewNotifications = 0;
      let counterNewNotificationsHome = 0;
      let counterNewNotificationsAgenda = 0;
      let counterNewNotificationsMessages = 0;

      payload.receivedNotifications.forEach((notification, index) => {
        const foundIndex = state.notifications.findIndex((former) => Number(former.id) === Number(notification.id));
        if (foundIndex === -1) {
          counterNewNotifications++;

          const targetScreen = getConcernedScreen(notification.Notification_type_id);
          if (targetScreen) {
            counterNewNotificationsHome += targetScreen === 'home' ? 1 : 0;
            counterNewNotificationsAgenda += targetScreen === 'agenda' ? 1 : 0;
            counterNewNotificationsMessages += targetScreen === 'message' ? 1 : 0;
          }
        }
      });

      return {
        ...state,
        numberOfNotifications: state.numberOfNotifications + counterNewNotifications,
        numberOfHomeNotifications: state.numberOfHomeNotifications + counterNewNotificationsHome,
        numberOfAgendaNotifications: state.numberOfAgendaNotifications + counterNewNotificationsAgenda,
        numberOfMessageNotifications: state.numberOfMessageNotifications + counterNewNotificationsMessages,
        notifications: [...payload.receivedNotifications],
      };
    }

    case SET_NOTIFICATION: {
      let counterNewNotifications = 0;
      let counterNewNotificationsHome = 0;
      let counterNewNotificationsAgenda = 0;
      let counterNewNotificationsMessages = 0;

      const foundIndex = state.notifications.findIndex(
        (former) => Number(former.id) === Number(payload.receivedNotification.id)
      );
      if (foundIndex === -1) {
        counterNewNotifications++;

        const targetScreen = getConcernedScreen(payload.receivedNotification.Notification_type_id);
        if (targetScreen) {
          counterNewNotificationsHome += targetScreen === 'home' ? 1 : 0;
          counterNewNotificationsAgenda += targetScreen === 'agenda' ? 1 : 0;
          counterNewNotificationsMessages += targetScreen === 'message' ? 1 : 0;
        }
      }

      return {
        ...state,
        numberOfNotifications: state.numberOfNotifications + counterNewNotifications,
        numberOfHomeNotifications: state.numberOfHomeNotifications + counterNewNotificationsHome,
        numberOfAgendaNotifications: state.numberOfAgendaNotifications + counterNewNotificationsAgenda,
        numberOfMessageNotifications: state.numberOfMessageNotifications + counterNewNotificationsMessages,
        notifications: [payload.receivedNotification, ...state.notifications],
      };
    }

    case SORT_NOTIFICATIONS:
      return {
        ...state,
        notifications: state.notifications.slice().sort((a, b) => {
          return a.timestamp - b.timestamp;
        }),
      };

    case ERROR_NOTIFICATION_REDUCER:
      return {
        ...state,
        errorGetNotification: payload.errorGetNotification ? payload.errorGetNotification : false,
        errorRemoveNotification: payload.errorRemoveNotification ? payload.errorRemoveNotification : false,
      };

    case DELETE_NOTIFICATION: {
      debug && console.debug('NotificationsReducer DELETE_NOTIFICATION, remove:', payload.notificationId);

      const notificationIndex = state.notifications.findIndex(
        (notitication) => notitication.id === payload.notificationId
      );
      if (notificationIndex >= 0) {
        return {
          ...state,
          notifications: [
            ...state.notifications.slice(0, notificationIndex),
            ...state.notifications.slice(notificationIndex + 1),
          ],
        };
      }
      return state;
    }

    case DELETE_ALL_NOTIFICATIONS:
      if (payload.hasRemoveNotification !== false) {
        return {
          ...state,
          hasRemoveNotification: true,
          notifications: [],
        };
      }
      return state;

    // Todo: remove this, replaced with the 3 NOTIFICATION_ * _ACTUALITY
    case SAVE_PUSH_NOTIFICATION_COME_OUT_APPLICATION:
      if (payload.notification !== undefined && payload.notification !== null) {
        return {
          ...state,
          notificationComeOutOfApplication: payload.notification,
        };
      }
      return state;

    case NOTIFICATION_NEW_ACTUALITY:
      debug && console.debug('NotificationsReducer NOTIFICATION_NEW_ACTUALITY, payload:', payload);
      return {
        ...state,
        notificationNewActualityId: payload.actualityId,
      };

    case NOTIFICATION_LIKE_ACTUALITY:
      debug && console.debug('NotificationsReducer NOTIFICATION_LIKE_ACTUALITY, payload:', payload);
      return {
        ...state,
        notificationLikeActualityId: payload.actualityId,
      };

    case NOTIFICATION_COMMENT_ACTUALITY:
      debug && console.debug('NotificationsReducer NOTIFICATION_COMMENT_ACTUALITY, payload:', payload);
      return {
        ...state,
        notificationCommentActualityId: payload.actualityId,
      };

    case HANDLE_BADGE_NOTIFICATION:
      if (payload.badgeType) {
        if (payload.increment === true) {
          if (payload.badgeType === 'home' && state) {
            return {
              ...state,
              numberOfNotifications: state.numberOfNotifications + 1,
              numberOfHomeNotifications: state.numberOfHomeNotifications + 1,
            };
          } else if (payload.badgeType === 'agenda' && state) {
            return {
              ...state,
              numberOfNotifications: state.numberOfNotifications + 1,
              numberOfAgendaNotifications: state.numberOfAgendaNotifications + 1,
            };
          } else if (payload.badgeType === 'message' && state) {
            return {
              ...state,
              numberOfNotifications: state.numberOfNotifications + 1,
              numberOfMessageNotifications: state.numberOfMessageNotifications + 1,
            };
          } else if (payload.badgeType === 'notification' && state) {
            return {
              ...state,
              numberOfNotifications: state.numberOfNotifications + 1,
            };
          }
        }

        if (payload.badgeType === 'home' && state) {
          return {
            ...state,
            numberOfHomeNotifications: 0,
          };
        } else if (payload.badgeType === 'agenda' && state) {
          return {
            ...state,
            numberOfAgendaNotifications: 0,
          };
        } else if (payload.badgeType === 'message' && state) {
          return {
            ...state,
            numberOfMessageNotifications: 0,
          };
        } else if (payload.badgeType === 'notification' && state) {
          return {
            ...state,
            numberOfNotifications: 0,
          };
        }
      }
      return state;

    case SAVE_ACTUALITY_TO_SHOW_IN_MODAL:
      return {
        ...state,
        actualityToShowInModal:
          payload.actuality !== undefined && payload.actuality !== null ? payload.actuality : null,
      };

    default:
      return state;
  }
};

export default notificationsReducer;
