import Colors from '@configs/colors';
import { UPDATE_MY_CLUB_FILTER, UPDATE_PENDING_FILTER } from '@redux/reducers';
import I18n from '@utils/i18n';
import React from 'react';
import { Image } from 'react-native';
import { FloatingAction } from 'react-native-floating-action';
import { useDispatch, useSelector } from 'react-redux';

const FabMenu = ({
  navigation,
  currentClub,
  isAdministrator = false,
  isMyClub = false,
  pendingFilter = false,
  toggleClubFilter = (isMyClub) => {},
  toggleActualitiesFilter = (pendingFilter) => {},
}) => {
  const dispatch = useDispatch();

  const { favorites, myClubFilter } = useSelector((state) => state.session);

  const { apiConfiguration } = useSelector((state) => state.app);

  const actions = [
    {
      text: I18n.t('app.actions.addActuality'),
      textBackground: Colors.whiteCorporate,
      textColor: currentClub.mainColorBottom ?? Colors.blueCorporate,
      textElevation: 4,
      color: currentClub.mainColorBottom ?? Colors.blueCorporate,
      icon: require('@assets/icons/fabMenu/menuAdd.png'),
      buttonSize: 56,
      size: 48,
      margin: 0,
      name: 'btn_add_actuality',
      position: 1,
    },
    {
      text: I18n.t('app.actions.searchClubs'),
      textBackground: Colors.whiteCorporate,
      textColor: currentClub.mainColorBottom ?? Colors.blueCorporate,
      textElevation: 4,
      color: currentClub.mainColorBottom ?? Colors.blueCorporate,
      icon: require('@assets/icons/fabMenu/menuSearch.png'),
      buttonSize: 56,
      size: 48,
      margin: 0,
      name: 'btn_search_clubs',
      position: 2,
    },
    {
      text: isMyClub ? I18n.t('app.actions.removeClubFilter') : I18n.t('app.actions.clubFilter'),
      textBackground: Colors.whiteCorporate,
      textColor: isMyClub
        ? currentClub.activeIconColor ?? Colors.redCorporate
        : currentClub.mainColorBottom ?? Colors.blueCorporate,
      textElevation: 4,
      color: isMyClub
        ? currentClub.activeIconColor ?? Colors.redCorporate
        : currentClub.mainColorBottom ?? Colors.blueCorporate,
      icon: require('@assets/icons/fabMenu/menuFilter.png'),
      buttonSize: 56,
      size: 48,
      margin: 0,
      name: 'btn_filter_my_club',
      position: 3,
    },
    {
      text: I18n.t('app.actions.favorites'),
      textBackground: Colors.whiteCorporate,
      textColor: currentClub.mainColorBottom ?? Colors.blueCorporate,
      textElevation: 4,
      color:
        !isMyClub && favorites?.someExists
          ? currentClub.activeIconColor ?? Colors.redCorporate
          : currentClub.mainColorBottom ?? Colors.blueCorporate,
      icon: require('@assets/icons/fabMenu/menuFavorites.png'),
      buttonSize: 56,
      size: 48,
      margin: 0,
      name: 'btn_favorites',
      position: 4,
    },
  ];

  if (isAdministrator && apiConfiguration?.validateActualities) {
    actions.push({
      text: pendingFilter ? I18n.t('app.actions.removePendingFilter') : I18n.t('app.actions.filterPendingActualities'),
      textBackground: Colors.whiteCorporate,
      textColor: currentClub.mainColorBottom ?? Colors.blueCorporate,
      textElevation: 4,
      color: !pendingFilter
        ? currentClub.mainColorBottom ?? Colors.blueCorporate
        : currentClub.activeIconColor ?? Colors.redCorporate,
      icon: require('@assets/icons/fabMenu/menuFilter.png'),
      buttonSize: 56,
      size: 48,
      margin: 0,
      name: 'btn_filter_pending',
      position: 5,
    });
  }

  if (!navigation || !currentClub) {
    return null;
  }

  return (
    <FloatingAction
      floatingIcon={
        <Image
          source={require('@assets/icons/fabMenu/menu.png')}
          style={{
            tintColor: currentClub.inactiveIconColor ?? Colors.whiteCorporate,
            width: 20,
            height: 20,
            resizeMode: 'contain',
          }}
        />
      }
      actions={actions}
      color={currentClub.mainColorBottom ?? Colors.blueCorporate}
      distanceToEdge={{ vertical: 70, horizontal: 30 }}
      visible
      position="right"
      showBackground
      onPressItem={(name) => {
        if (name === 'btn_add_actuality') {
          if (currentClub.isAffiliated !== '1') {
            navigation.navigate('NotAffiliated');
          } else {
            navigation.navigate('CreateActuality', { mode: 'add' });
          }
        }
        if (name === 'btn_search_clubs') {
          navigation.navigate('ClubSearch');
        }
        if (name === 'btn_filter_my_club') {
          if (currentClub.isAffiliated !== '1') {
            navigation.navigate('NotAffiliated');
          } else {
            dispatch({ type: UPDATE_MY_CLUB_FILTER, myClubFilter: !myClubFilter });

            toggleClubFilter(!isMyClub);
          }
        }
        if (name === 'btn_favorites') {
          if (currentClub.isAffiliated !== '1') {
            navigation.navigate('NotAffiliated');
          } else {
            navigation.navigate('ClubFavorites');
          }
        }
        if (name === 'btn_filter_pending') {
          if (currentClub.isAffiliated !== '1') {
            navigation.navigate('NotAffiliated');
          } else {
            if (!pendingFilter) {
              // Force update current club filtering!
              dispatch({ type: UPDATE_MY_CLUB_FILTER, myClubFilter: true });
              toggleClubFilter(true);
            }

            dispatch({ type: UPDATE_PENDING_FILTER, pendingFilter: !pendingFilter });
            toggleActualitiesFilter(!pendingFilter);
          }
        }
      }}
    />
  );
};

export default FabMenu;
