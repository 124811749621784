import Colors from '@configs/colors';
import { Dimensions, StyleSheet } from 'react-native';

const { width: sw, height: sh } = Dimensions.get('window');

const styles = StyleSheet.create({
  rootContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  imageContainer: {
    justifyContent: 'center',
    height: sh / 4,
    width: sw - 200,
    // marginTop: 10,
    padding: 10,
  },
  image: {
    width: '90%',
    height: '100%',
    resizeMode: 'contain',
    alignSelf: 'center',
  },

  // CA1 - input container

  // CA2 - user profile
  panelProfil: {
    alignItems: 'center',
    marginTop: 10,
  },
  buttonProfil: {
    alignItems: 'center',
  },
  avatarContainer: {
    width: 120,
    height: 120,
    borderRadius: 60,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgProfil: {
    width: '100%',
    height: '100%',
    borderRadius: 60,
  },
  textEditProfil: {
    color: Colors.red,
    fontSize: 20,
    // marginTop: sh * 0.008,
    fontFamily: 'appFontMedium',
  },

  inputContainer: {
    justifyContent: 'center',
    // marginTop: 20,
    marginBottom: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    backgroundColor: Colors.blueCorporate,
  },
  buttonsContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    // width: sw / 1.2,
    // marginVertical: 5,
    paddingHorizontal: 20,
    borderRadius: 10,
    // backgroundColor: Colors.blueSky,
  },
  tosButtonsContainer: {
    justifyContent: 'center',
    marginVertical: 5,
    paddingVertical: 0,
    paddingHorizontal: 20,
    borderRadius: 10,
    backgroundColor: Colors.blueCorporate,
  },

  shadowEffect: {
    marginBottom: 5,

    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },

  logoStyle: {
    height: '45%',
    resizeMode: 'contain',
  },
  appFontRegular: {
    fontFamily: 'appFontRegular',
  },
  appFontMedium: {
    fontFamily: 'appFontMedium',
  },
  whiteText: {
    color: Colors.whiteCorporate,
  },
  redText: {
    color: Colors.red,
  },
  textInput: {
    width: (sw / 4) * 3,
    maxWidth: 600,
    backgroundColor: Colors.whiteCorporate,
    borderRadius: 50,
    height: 34,
    paddingHorizontal: 15,
    marginVertical: 5,
  },
  textInputDate: {
    width: (sw / 4) * 3 - 30,
    maxWidth: 600,
    backgroundColor: Colors.whiteCorporate,
    borderRadius: 50,
    height: 34,
    paddingHorizontal: 15,
    marginVertical: 5,
    marginBottom: 10,
  },

  buttonCenterLabel: {
    alignItems: 'center',
    justifyContent: 'center',
    selfAlign: 'center',
  },
  nextStepButton: {
    width: sw / 2,
    height: 40,
    backgroundColor: Colors.blueSky,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
  },
  swipeBarButton: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
  },

  clubGlobalContainer: {
    backgroundColor: Colors.whiteCorporate,
    borderRadius: 20,
    marginTop: 20,
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 20,
  },

  clubInfosContainer: {
    borderRadius: 20,
    marginTop: 20,
    alignItems: 'center',
    backgroundColor: Colors.grayWhite,
  },
  buttonCancel: {
    marginTop: sh * 0.01,
  },
  search: {
    borderWidth: 1,
    borderColor: 'rgba(128,128,128 ,1)',
  },
});

export default styles;
