import Colors from '@configs/colors';
import React from 'react';
import { Pressable, StyleSheet, Text } from 'react-native';

export default function ButtonCancel({
  label = 'Annuler',
  disabled = false,
  style = {},
  textStyle = {},
  onPress = () => {},
}) {
  return (
    <Pressable
      disabled={disabled}
      style={({ pressed }) => [styles.defaultButton, style, pressed ? { opacity: 0.7 } : {}]}
      onPress={() => {
        onPress();
      }}>
      <Text style={[styles.buttonLabel, textStyle]} numberOfLines={1} ellipsizeMode="tail">
        {label}
      </Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  defaultButton: {
    paddingHorizontal: 15,
    height: 48,
    backgroundColor: Colors.gray,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    marginVertical: 15,

    overflow: 'visible',
    elevation: 5,
    shadowColor: Colors.iconGray,
    shadowOffset: {
      width: 3,
      height: 5,
    },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  buttonLabel: {
    fontFamily: 'appFontRegular',
    textAlign: 'center',
    color: Colors.blueCorporate,
  },
});
