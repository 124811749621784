import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import DocumentsScreen from '@containers/DrawerStack/ClubDocuments';
import ClubScreen from '@containers/DrawerStack/ClubInfos';
import PartnersScreen from '@containers/DrawerStack/ClubPartners';
import ShopScreen from '@containers/DrawerStack/ClubShop';
import TeamsScreen from '@containers/DrawerStack/ClubTeams';
import BottomTabNavigator from '@navigation/BottomTabNavigator';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItem, DrawerItemList } from '@react-navigation/drawer';
import I18n from '@utils/i18n';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { View, Text, Image, Linking, StyleSheet, Platform, Dimensions } from 'react-native';
import { useSelector } from 'react-redux';

const Drawer = createDrawerNavigator();
const { width: sw, height: sh, scale, fontScale } = Dimensions.get('window');

const SignedInNavigator = ({ navigation, route }) => {
  const debug = false;

  const defaultLogo = null;

  const [activeColor, setActiveColor] = useState(Colors.redCorporate);
  const [inactiveColor, setInactiveColor] = useState(Colors.whiteCorporate);
  const [barColor, setBarColor] = useState(Colors.blueCorporate);

  const [highScreen, setHighScreen] = useState(false);

  const [headerLogo, setHeaderLogo] = useState(defaultLogo);
  const [currentClub, setCurrentClub] = useState(null);

  const { signedIn, userStatus, apiConfiguration } = useSelector((state) => state.app);
  const { session, foundOtherClub, affiliatedClubInformation } = useSelector((state) => state.session);

  useEffect(() => {
    debug && console.debug(`SignedInNavigator, API configuration`, apiConfiguration);
  }, []);

  useEffect(() => {
    debug && console.debug(`SignedInNavigator, route '${route.name}', user signed-in: ${signedIn}`);
    if (!signedIn) {
      // Let the LocalCredentials do the job...
      // // Navigate to the signed-out stack!
      // navigation.navigate('SignedOut');
    }
    // Android galaxy A: 360x692
    // iPhone 11: 414x896
    debug && console.debug(`SignedInNavigator, dimensions`, sw, sh, scale, fontScale);
    if (sh > 692) {
      setHighScreen(true);
    }
  }, [signedIn]);

  useEffect(() => {
    debug && console.debug(`SignedInNavigator, route '${route.name}', user status: ${userStatus}`);
  }, [userStatus]);

  useEffect(() => {
    debug && console.debug(`SignedInNavigator, club information changed, foundOtherClub:`, foundOtherClub);
    debug && console.debug(`SignedInNavigator, club information changed:`, affiliatedClubInformation);
    debug && console.debug(`SignedInNavigator, club information changed:`, session?.club);

    setCurrentClub(foundOtherClub ?? affiliatedClubInformation ?? session?.club ?? null);
  }, [foundOtherClub, affiliatedClubInformation, session]);

  useEffect(() => {
    if (!currentClub) {
      return;
    }
    debug && console.debug(`SignedInNavigator, currentClub:`, currentClub);

    if (currentClub) {
      setActiveColor(currentClub.activeIconColor);
      setInactiveColor(currentClub.inactiveIconColor);
      setBarColor(currentClub.mainColorBottom);

      setHeaderLogo(currentClub.logo ? { uri: getImageApiHostOrUrl(currentClub.logo) } : defaultLogo);
    }
  }, [currentClub]);

  const DrawerHeaderContent = (props) => {
    if (!currentClub) {
      return;
    }

    return (
      <>
        <View style={[styles.topLogo, { borderBottomColor: inactiveColor }]}>
          {headerLogo ? (
            <Image source={headerLogo} style={[styles.topLogoImage, { height: highScreen ? 150 : 100 }]} />
          ) : (
            <Text style={[styles.topLogoText, { color: inactiveColor }]} numberOfLines={2} ellipsizeMode="tail">
              {I18n.t('menu.clubSpace')}
            </Text>
          )}
        </View>

        <DrawerContentScrollView contentContainerStyle={styles.mainMenu}>
          <View style={[{ marginTop: 0, padding: 0, flexGrow: 1 }]}>
            <DrawerItemList {...props} />
            <View style={{ borderBottomColor: Colors.whiteCorporate, borderBottomWidth: 2, marginBottom: 5 }} />

            {currentClub.clubUrls &&
              currentClub.clubUrls.map((link, index) => {
                if (!link.url || !link.label) {
                  return;
                }
                return (
                  <DrawerItem
                    key={index}
                    style={{
                      height: highScreen ? 54 : 38,
                      width: '100%',
                      paddingTop: 0,
                      marginVertical: 0,
                    }}
                    labelStyle={{
                      fontFamily: 'appFontMedium',
                      fontSize: highScreen ? 18 : 16,
                      lineHeight: highScreen ? 22 : 16,
                      color: activeColor,
                      padding: 0,
                      margin: 0,
                    }}
                    label={link.label}
                    onPress={() => Linking.openURL(link.url)}
                  />
                );
              })}
          </View>
        </DrawerContentScrollView>
      </>
    );
  };

  // Let the LocalCredentials do the job...
  if (userStatus !== 'signed-in') {
    return null;
  }

  return (
    <Drawer.Navigator
      id="signedInNavigator"
      initialRouteName="HomeScreen"
      screenOptions={{
        headerShown: false,
        drawerStyle: {
          backgroundColor: barColor,
          paddingTop: 0,
          marginTop: 0,
        },
        drawerContentContainerStyle: {
          paddingVertical: 0,
          marginVertical: 0,
        },
        drawerPosition: Platform.OS === 'web' ? 'right' : 'right',
        drawerType: Platform.OS === 'web' ? 'front' : 'right',
        drawerItemStyle: {
          height: highScreen ? 60 : 50,
          width: '100%',
          paddingTop: 0,
          marginVertical: 0,
        },
        drawerLabelStyle: {
          fontFamily: 'appFontMedium',
          fontSize: highScreen ? 22 : 18,
          lineHeight: highScreen ? 22 : 20,
        },
        drawerActiveTintColor: activeColor,
        drawerInactiveTintColor: inactiveColor,
      }}
      drawerContent={DrawerHeaderContent}>
      <Drawer.Screen
        name="HomeScreen"
        component={BottomTabNavigator}
        options={{
          headerShown: false,
          drawerLabel: 'Page courante',
          // Hide the link to this screen in the drawer menu
          drawerItemStyle: { height: 0 },
        }}
      />
      <Drawer.Screen name="ClubScreen" component={ClubScreen} options={{ drawerLabel: 'Infos club' }} />
      <Drawer.Screen name="TeamsScreen" component={TeamsScreen} options={{ drawerLabel: 'Équipes' }} />
      <Drawer.Screen name="DocumentsScreen" component={DocumentsScreen} options={{ drawerLabel: 'Documents' }} />
      <Drawer.Screen name="ShopScreen" component={ShopScreen} options={{ drawerLabel: 'Boutique' }} />
      <Drawer.Screen name="PartnersScreen" component={PartnersScreen} options={{ drawerLabel: 'Partenaires' }} />
    </Drawer.Navigator>
  );
};

const styles = StyleSheet.create({
  topLogo: {
    marginTop: Platform.OS === 'ios' ? 30 : 0,
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 2,
  },
  topLogoImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  topLogoText: {
    fontFamily: 'appFontMedium',
    fontSize: 48,
    textAlign: 'center',
    width: '90%',
  },

  mainMenu: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: 10,
  },

  bottomLogo: {
    height: 80,
    padding: 5,
    margin: 10,
    marginBottom: Platform.OS === 'ios' ? 15 : 2,
    borderRadius: 10,
    backgroundColor: Colors.whiteCorporate,
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomLogoImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
});

export default SignedInNavigator;
