import { PartnerAPI } from '@apis/partner';
import CardPartner from '@components/CardPartner/CardPartner';
import HeaderBanner from '@components/HeaderBanner/HeaderBanner';
import Colors from '@configs/colors';
import React, { Component } from 'react';
import { BackHandler, ScrollView, Text, View } from 'react-native';
import { connect } from 'react-redux';

class ClubPartners extends Component {
  headerState = 'open';
  header = React.createRef();

  page = 1;
  limit = 10;

  hasNextPage = false;
  nextPageLoading = false;

  constructor(props) {
    super(props);
    this.state = {
      currentClub: this.props.foundOtherClub ?? this.props.affiliatedClubInformation ?? this.props.session.club ?? null,
      listPartners: [],
      refreshing: false,
      displayActivityIndicator: false,
    };
  }
  componentDidMount() {
    this.getAllPartners();

    this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      this.props.navigation.pop();
      return true;
    });
    this.focusListener = this.props.navigation.addListener('focus', () => {
      this.handleAffiliation();
    });
  }

  componentWillUnmount() {
    this.backHandler.remove();
    this.focusListener();
  }

  /**
   * Function used to update the current club of this screen
   */
  handleAffiliation() {
    if (this.props.foundOtherClub) {
      this.setState(
        {
          currentClub: this.props.foundOtherClub,
        },
        () => this.getAllPartners()
      );
    } else if (
      this.props.affiliatedClubInformation &&
      this.state.currentClub.id !== this.props.affiliatedClubInformation.id
    ) {
      this.setState(
        {
          currentClub: this.props.affiliatedClubInformation
            ? this.props.affiliatedClubInformation
            : this.props.session.club,
        },
        () => this.getAllPartners()
      );
    } else if (!this.props.foundOtherClub && !this.props.affiliatedClubInformation) {
      this.setState(
        {
          currentClub: this.props.session.club,
        },
        () => this.getAllPartners()
      );
    }
  }

  getAllPartners = () => {
    this.setState({ refreshing: true });

    // Elipce/Fred: page and limit are useless, because ignored by the backend!
    PartnerAPI.getAllPartnersByCLubId(
      this.props.tokens.wsToken,
      this.page,
      this.limit,
      this.state.currentClub?.id
    ).then((response) => {
      if (response && response.success) {
        this.hasNextPage = false;
        this.setState({
          listPartners: response.result.partners,
          refreshing: false,
        });
      } else {
        this.setState({ refreshing: false });
      }
    });
  };

  refresh = async () => {
    this.setState({ refreshing: true });
    this.page = 1;
    this.getAllPartners();
  };

  nextPage = async () => {
    if (this.hasNextPage && !this.nextPageLoading && !this.state.refreshing) {
      this.nextPageLoading = true;
      this.page += 1;
      const listPartners = await PartnerAPI.getAllPartnersByCLubId(
        this.props.tokens.wsToken,
        this.page,
        this.limit,
        this.state.currentClub?.id
      );
      if (listPartners.success) {
        this.hasNextPage = listPartners.result.hasNextPage;
        if (listPartners.result.partners != null && listPartners.result.partners.length > 0) {
          const list = [...this.state.listPartners];
          list.splice(this.state.listPartners.length, 0, ...listPartners.result.partners);
          this.setState({ listPartners: list });
        }
      } else if (listPartners.error_code === 10) {
        console.warn('Session expired!');
        // session expired redirect to Login
      } else {
      }
      this.nextPageLoading = false;
    }
  };

  renderDataPage = () => {
    if (this.state.listPartners.length <= 0) {
      return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text
            style={{ marginTop: 20, color: Colors.blueCorporate + 'CC', fontFamily: 'appFontRegular', fontSize: 16 }}>
            Le club n'a pas déclaré de partenaires
          </Text>
        </View>
      );
    }

    return (
      <ScrollView>
        {this.state.listPartners.map((partner, index) => {
          return (
            <CardPartner
              key={'Partner-' + index}
              club={this.state.currentClub}
              partner={partner}
              selectable={false}
              selectedMembers={null}
            />
          );
        })}
      </ScrollView>
    );
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        <HeaderBanner
          leftIcon="md-arrow-back"
          onLeftButton={() => this.props.navigation.goBack()}
          rightIcon="md-menu"
          onRightButton={async () => {
            this.props.navigation.goBack();
            this.props.navigation.openDrawer();
          }}
        />

        <View style={{ flex: 1, marginTop: 0 }}>
          <this.renderDataPage />
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { tokens } = state.app;
  const { session, affiliatedClubInformation, foundOtherClub } = state.session;
  return {
    session,
    tokens,
    affiliatedClubInformation,
    foundOtherClub,
  };
};

export default connect(mapStateToProps)(ClubPartners);
