import alert from '@utils/alert';
import I18n from '@utils/i18n';
import Constants from 'expo-constants';
import { Linking, Platform } from 'react-native';

const openPhone = (phoneNumber) => {
  Linking.canOpenURL(encodeURI('tel://' + phoneNumber)).then((supported) => {
    if (supported) {
      Linking.openURL(encodeURI('tel://' + phoneNumber));
    } else {
      alert(I18n.t('app.oups'), "Téléphoner n'est pas possible");
    }
  });
};

const openMail = (mail, subject = null, body = null) => {
  let url = `mailto:${mail}`;
  if (subject && subject !== '') {
    url += '?subject=' + encodeURIComponent(subject);
  } else {
    url += '?subject=' + encodeURIComponent(`Contact depuis l'application ${Constants.expoConfig.name}`);
  }

  let mailBody = body ?? '';
  mailBody += `\n---\nMail envoyé depuis l'application ${Constants.expoConfig.name}`;
  url = url + (url.includes('?') ? '&' : '?') + 'body=' + encodeURIComponent(mailBody);

  Linking.canOpenURL(url)
    .then((supported) => {
      if (supported) {
        console.log('Mail:', url);
        Linking.openURL(url)
          .then((response) => {
            console.debug('Sent mail: ' + url, response);
          })
          .catch((error) => {
            console.error('Sent mail error: ' + url, error);
          });
      } else {
        alert(I18n.t('app.oups'), "Envoyer un mail n'est pas possible");
      }
    })
    .catch((error) => {
      alert(I18n.t('app.oups'), 'Envoi de mail impossible: ' + error);
    });
};

const openUrl = (url) => {
  Linking.canOpenURL(encodeURI(url))
    .then((supported) => {
      if (supported) {
        Linking.openURL(url);
      } else {
        alert(I18n.t('app.oups'), "Naviguer vers ce lien n'est pas possible");
      }
    })
    .catch((error) => {
      alert(I18n.t('app.oups'), 'Envoi de mail impossible: ' + error);
    });
};

const openMap = (address = null, lat = null, lng = null, zoom = 15) => {
  if (lat && lng) {
    const url =
      Platform.OS === 'ios'
        ? `http://maps.apple.com/?address=${address}`
        : Platform.OS === 'android'
        ? `https://www.google.com/maps/@?api=1&map_action=map&center=${lat},${lng}&zoom=${zoom}&basemap=terrain`
        : // Web
          `https://www.google.com/maps/@?api=1&map_action=map&center=${lat},${lng}&zoom=${zoom}&basemap=terrain`;

    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      } else {
        alert(I18n.t('app.oups'), "Naviguer vers cette carte n'est pas possible");
      }
    });
  }

  if (address) {
    const url =
      Platform.OS === 'ios'
        ? `http://maps.apple.com/?address=${address}`
        : Platform.OS === 'android'
        ? `https://www.google.com/maps/search/?api=1&query=${address}`
        : // Web
          `https://www.google.com/maps/search/?api=1&query=${address}`;

    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      } else {
        alert(I18n.t('app.oups'), "Naviguer vers cette carte n'est pas possible");
      }
    });
  }
};

export { openMail, openUrl, openPhone, openMap };
