import { MSG_CONVOCATION, MSG_CONVOCATION_REPLY, MSG_SIMPLE_MESSAGE } from '@apis/message';
import Images from '@assets/images';
import ShadowCard from '@components/ShadowCard/ShadowCard';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import { gMaxWidth } from '@configs/styles';
import moment from 'moment-timezone';
import React from 'react';
import { Dimensions, Image, Platform, Pressable, StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { useSelector } from 'react-redux';

const sw = Dimensions.get('window').width;

const CardChat = ({ chat, style = {}, onShowChatting = () => {}, onLongPress = () => {} }) => {
  const { allAffiliatedClubs } = useSelector((state) => state.session);

  const { session } = useSelector((state) => state.session);

  // Creator's club logo
  let clubLogo = null;
  if (chat.creator.Club_id && allAffiliatedClubs) {
    const foundClubLogoToDisplay = allAffiliatedClubs.find((club) => club.id === chat.creator.Club_id);
    if (foundClubLogoToDisplay) {
      clubLogo = { uri: getImageApiHostOrUrl(foundClubLogoToDisplay.logo) };
    }
  }

  // Conversation image
  let avatar = chat.photoUrl ? { uri: getImageApiHostOrUrl(chat.photoUrl) } : null;
  if (!avatar) {
    avatar = clubLogo ?? Images.userPlaceholder;
  }

  let message = '';
  let date = '';

  const lastMessage = chat.lastMessage;
  if (!lastMessage) {
    // case there is not text message
    message = 'pas encore de message';

    if (chat.updatedAt) {
      // "updatedAt": "2023-04-14 07:04" If you get all the conversations
      // "updatedAt": "14/04/2023 07:04" If you get only a conversation detail !!!
      // Let moment.js guess the correct formatting...
      const updatedAt = moment(chat.updatedAt, 'DD/MM/YYYY HH:mm');
      date = updatedAt.fromNow();
      if (date === 'Invalid date') {
        const updatedAt = moment(chat.updatedAt);
        date = updatedAt.fromNow();
      }
    }
  } else {
    // case there is text message
    if (lastMessage.MessageType_id === MSG_SIMPLE_MESSAGE) {
      // case type of message is " texte message " or "image"
      if (lastMessage.content && lastMessage.content.trim() !== '') {
        // case type of message is "text message"
        message = lastMessage.content;
      } else if (lastMessage.filename) {
        // case type of message is "image"
        message = 'une image a été partagée';
      } else {
        // case there are not message
        message = 'pas de message';
      }
    } else if (lastMessage.MessageType_id === MSG_CONVOCATION) {
      // case type of message is "convocation"
      message = 'une convocation a été émise';
    } else if (lastMessage.MessageType_id === MSG_CONVOCATION_REPLY) {
      // case type of message is " response of convocation"
      message = 'une personne a répondu a une convocation';
    }

    // case there is text message
    if (lastMessage?.createdAt) {
      // "createdAt": "2023-04-14 07:04:34" !!!
      const createdAt = moment(lastMessage.createdAt);
      date = createdAt.fromNow();
    }
  }

  if (!session) return;

  return (
    <ShadowCard style={[styles.mainContainer, style]}>
      <Pressable
        style={({ pressed }) => [
          styles.chatCard,
          session.user.id === chat.creator.id ? { backgroundColor: Colors.grayWhite } : null,
          pressed ? { opacity: 0.5 } : {},
        ]}
        onPress={() => {
          onShowChatting();
        }}
        onLongPress={() => {
          onLongPress();
        }}>
        <Image style={styles.userAvatar} source={avatar} />
        <View style={styles.textContent}>
          <View style={{ flexDirection: 'row', alignItems: 'center', width: sw - 160 }}>
            {chat.teamConversation && <Icon size={25} color={Colors.gray1} name="people-outline" />}
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={{
                width: '100%',
                fontFamily: chat.hasSeenLastMessage ? 'appFontRegular' : 'appFontMedium',
                fontSize: chat.hasSeenLastMessage ? 12 : 14,
              }}>
              {chat.name}
            </Text>
          </View>

          <Text
            numberOfLines={1}
            style={[
              {
                fontFamily: chat.hasSeenLastMessage ? 'appFontRegular' : 'appFontMedium',
                fontSize: chat.hasSeenLastMessage ? 10 : 11,
                color: Colors.blueBgGradient1,
                opacity: 0.5,
              },
            ]}>
            {date}
          </Text>

          <Text
            numberOfLines={2}
            ellipsizeMode="tail"
            style={{
              width: '100%',
              fontFamily: chat.hasSeenLastMessage ? 'appFontRegular' : 'appFontMedium',
              fontSize: chat.hasSeenLastMessage ? 10 : 12,
              color: Colors.blueBgGradient1,
              opacity: 0.7,
            }}>
            {message}
          </Text>
        </View>
        <View style={styles.rightColumn}>
          <Image style={styles.clubLogo} source={clubLogo} />
          {chat.badgeCount > 0 && (
            <View style={styles.badgeCount}>
              <Text>{chat.badgeCount}</Text>
            </View>
          )}
        </View>
      </Pressable>
    </ShadowCard>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    width: (Platform.OS === 'web' ? gMaxWidth : sw) - 20,
    padding: 10,
    margin: 10,
    marginTop: 0,
  },
  chatCard: {
    width: '100%',

    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'visible',
    borderColor: 'transparent',

    padding: 5,
  },

  userAvatar: {
    width: 60,
    height: 60,
    borderRadius: 30,
    marginRight: 15,
    marginLeft: 5,
  },
  textContent: {
    maxWidth: sw - 180,
  },
  rightColumn: {
    width: 50,
    height: 60,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  badgeCount: {
    width: 24,
    height: 24,
    fontSize: 12,
    borderRadius: 12,
    borderTopEndRadius: 0,
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.whiteCorporate,

    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },
  clubLogo: {
    width: 32,
    height: 32,
    borderRadius: 16,
    alignSelf: 'flex-end',
  },
});

export default CardChat;
