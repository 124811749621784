import ButtonBase from '@components/ButtonBase/ButtonBase';
import ButtonCancel from '@components/ButtonCancel/ButtonCancel';
import Colors from '@configs/colors';
import { gMaxWidth } from '@configs/styles';
import I18n from '@utils/i18n';
import PropTypes from 'prop-types';
import React from 'react';
import { Dimensions, FlatList, Modal, Platform, StyleSheet, Text, View } from 'react-native';
import { useSelector } from 'react-redux';

const { width: sw } = Dimensions.get('window');

/**
 * Screen used to choose between actuality types
 * @returns // screen
 */
const SelectActualityType = ({ visible = false, onClose = () => {}, onSelected = (item) => {} }) => {
  const { constActuTypes: actualityTypes } = useSelector((state) => state.app);

  if (!visible) {
    return;
  }

  return (
    <>
      <Modal visible={visible} transparent style={[styles.mainContainer]}>
        <View style={[styles.mainContainer]}>
          <View style={[styles.centered, styles.shadowEffect]}>
            <Text style={styles.pageTitle}>Choisissez un type d'actualité</Text>

            <FlatList
              data={actualityTypes ?? []}
              keyExtractor={(item, index) => item.id}
              showsVerticalScrollIndicator={false}
              renderItem={({ item, index, separators }) => {
                return (
                  <View style={[styles.itemContainer]}>
                    <ButtonBase style={{ width: '100%' }} label={item.name} onPress={() => onSelected(item)} />
                  </View>
                );
              }}
            />

            <View style={[styles.buttonsContainer]}>
              <ButtonCancel label={I18n.t('app.close')} onPress={() => onClose()} />
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    width: Platform.OS === 'web' ? gMaxWidth : sw,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.black + '77',
  },
  centered: {
    backgroundColor: Colors.grayWhite,
    paddingVertical: 10,
    paddingHorizontal: 20,
    width: Platform.OS === 'web' ? gMaxWidth - 40 : sw - 40,
    borderRadius: 10,
    height: 380,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  shadowEffect: {
    marginBottom: 15,

    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },
  pageTitle: {
    // width: '80%',
    fontWeight: 'bold',
    fontSize: 18,
    color: Colors.blueCorporate,

    textAlign: 'center',

    marginTop: 40,
    marginBottom: 20,
  },
  itemContainer: {
    width: sw - 80,
    height: 60,
    // borderBottomWidth: 0.4,
    // borderBottomColor: Colors.iconGray,

    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    paddingVertical: 10,
    paddingHorizontal: 25,
  },
  itemText: {
    paddingHorizontal: 10,
    width: sw - 140,
    borderRadius: 100,
  },
  buttonsContainer: {
    marginTop: 20,
    width: 120,
  },
});

SelectActualityType.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSelected: PropTypes.func,
};

export default SelectActualityType;
