import Colors from '@configs/colors';
import { Dimensions, Platform, StyleSheet } from 'react-native';
import {gMaxWidth} from "@configs/styles";

const { width: sw, height: sh } = Dimensions.get('window');

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  dayAndHour: {
    color: Colors.red,
    fontSize: 12,
    marginTop: 25,
  },
  mesReply: {
    color: Colors.blueBgGradient1,
    fontSize: 12,
    opacity: 0.4,
  },
  mesTextContainer: {
    borderRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  mesText: {
    color: Colors.blueCorporate + '99',
    lineHeight: 16,
    fontSize: 13,
    opacity: 1,
    marginBottom: 10,
    borderBottomColor: Colors.gray,
    borderBottomWidth: 1,
  },
  mesAuthor: {
    color: Colors.blueCorporate + '99',
    lineHeight: 14,
    fontSize: 12,
    fontStyle: 'italic',
    opacity: 1,
    marginHorizontal: 20,
  },
  mesTextRemoved: {
    color: 'rgba(192,192,192 ,1)',
    lineHeight: 16,
    fontSize: 13,
    opacity: 0.2,
    fontStyle: 'italic',
  },
  mesImageContainer: {
    padding: 5,
    maxWidth: sw,
    borderRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  mesImage: {
    resizeMode: 'cover',
    marginBottom: 10,
    borderRadius: 10,
  },

  convocationHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottomColor: Colors.blueCorporate,
    borderBottomWidth: 1,
  },
  convocationContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomColor: Colors.blueCorporate,
    borderBottomWidth: 1,
    padding: 1,
  },
  convocationTitle: {
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: 12,
    color: Colors.blueCorporate,
    // textAlign: 'right',
  },

  convocationReplyTitle: {
    fontStyle: 'italic',
    // fontWeight: 'bold',
    fontSize: 12,
    color: Colors.blueCorporate,
    margin: 0,
    textAlign: 'center',
  },
  replyButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottomColor: Colors.blueCorporate,
    borderBottomWidth: 1,
  },
  replyButton: {
    width: '48%',
    marginHorizontal: '1%',
    height: 25,
  },
  replyButtonLabel: {
    fontSize: 12,
    color: Colors.iconGray,
  },

  closerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 20,
  },
  closerLabel: {
    color: Colors.whiteCorporate,
    // fontFamily: "appFontMedium",
    fontSize: 18,
  },
  closerIcon: {
    width: 20,
    height: 20,
    marginLeft: 20,
  },

  avatarContainer: {
    position: 'absolute',
    bottom: 0,
    borderRadius: 100,
    overflow: 'hidden',
    zIndex: 9999,
  },
  userAvatar: {
    width: 50,
    height: 50,
    borderRadius: 100,
    resizeMode: 'cover',
  },

  userType: {
    fontFamily: 'appFontRegular',
    color: Colors.red,
    fontSize: 12,
    marginHorizontal: 7,
  },

  selected: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.red,
    borderRadius: 100,
  },
  userInfoCtn: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  // Input text bar
  inputContainer: {
    paddingVertical: 1,
    paddingHorizontal: 20,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 60,
    minHeight: 42,
    // height: sh - 100,
    borderTopWidth: 2,
    borderTopColor: Colors.gray + '55',
    borderBottomColor: Colors.gray + '55',
  },
  buttonCamera: {
    width: 36,
    height: 36,
    borderRadius: 18,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonConvocation: {
    width: 36,
    height: 36,
    borderRadius: 18,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonSend: {
    width: 36,
    height: 36,
    borderRadius: 18,
    // marginLeft: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputZone: {
    flex: 1,
    width: Platform.OS === 'web' ? gMaxWidth - 120 : sw - 70,
    flexDirection: 'row',
    paddingVertical: 1,
    paddingHorizontal: 2,
    borderRadius: 100,
    backgroundColor: Colors.whiteCorporate,
    marginLeft: 10,
  },
  inputText: {
    flex: 1,
    height: 40,
    marginLeft: 15,
    paddingLeft: 10,
    marginRight: 5,
    paddingRight: 10,
    fontFamily: 'appFontRegular',
    color: Colors.blueBgGradient1,
  },
});

export default styles;
