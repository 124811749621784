import { AccountAPI } from '@apis/account';
import { REPLY_MAY_BE, REPLY_NO, REPLY_YES } from '@apis/message';
import { ACT_ACTUALITY, ACT_EVENT, ACT_SCORE, NewsAPI } from '@apis/news';
import Images from '@assets/images';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import ButtonBase from '@components/ButtonBase/ButtonBase';
import MediaViewer from '@components/MediaViewer/MediaViewer';
import SelectModerate from '@components/SelectModerate/SelectModerate';
import ShadowCard from '@components/ShadowCard/ShadowCard';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import { gMaxWidth } from '@configs/styles';
import PopupActuality from '@containers/HomeStack/ActualityPopups/PopupActuality';
import PopupEvent from '@containers/HomeStack/ActualityPopups/PopupEvent';
import PopupScore from '@containers/HomeStack/ActualityPopups/PopupScore';
import * as actions from '@redux/actions';
import alert from '@utils/alert';
import I18n from '@utils/i18n';
import { getTimeAgo } from '@utils/time';
import Constants from 'expo-constants';
import moment from 'moment';
import React, { forwardRef, useEffect, useState } from 'react';
import { Dimensions, Image, Platform, Pressable, Share, StyleSheet, Text, View } from 'react-native';
import { Autolink } from 'react-native-autolink';
import Icon from 'react-native-vector-icons/Ionicons';
import { useDispatch, useSelector } from 'react-redux';

const { width: sw } = Dimensions.get('window');

const ActualityCard = forwardRef(
  ({ navigation, index, actu, action, onValidate = () => {}, onDelete = () => {}, onRefresh = () => {} }, ref) => {
    const debug = false;

    const [alertTitle, setAlertTitle] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertButtons, setAlertButtons] = useState(null);
    const [showAlert, setShowAlert] = useState(false);

    const [myReply, setMyReply] = useState('');
    const [loadingYes, setLoadingYes] = useState(false);
    const [loadingNo, setLoadingNo] = useState(false);
    const [loadingMayBe, setLoadingMayBe] = useState(false);

    const [popupRef, setPopupRef] = useState(null);

    const clubLogo = actu.clubLogo ? { uri: getImageApiHostOrUrl(actu.clubLogo) } : require('@assets/logo.png');

    const homeTeamLogo = actu.hClubLogo
      ? { uri: getImageApiHostOrUrl(actu.hClubLogo) }
      : require('@assets/images/no_club.png');
    const awayTeamLogo = actu.aClubLogo
      ? { uri: getImageApiHostOrUrl(actu.aClubLogo) }
      : require('@assets/images/no_club.png');

    const { tokens } = useSelector((state) => state.app);
    const { actualityToShowInModal } = useSelector((state) => state.notifications);

    const dispatch = useDispatch();

    const [showActionReport, setShowActionReport] = useState(false);

    useEffect(() => {
      debug && console.debug('ActualityCard mounted:', actu.id);

      return () => {
        debug && console.debug(`ActualityCard, unmounting: ${actu.id}`);
      };
    }, []);

    const getNews = (callback) => {
      debug && console.debug('ActualityCard, getNews:', actu.id);

      dispatch(
        actions.getNews(tokens.wsToken, actu.id, (success, result) => {
          if (success) {
            callback && callback(success, result);
          } else {
            callback && callback(false);
          }
        })
      );
    };

    const sendComment = async (actu, commentMessage, callback) => {
      debug && console.debug('ActualityCard sendComment...', actu.id);

      const response = await NewsAPI.commentNews(tokens.wsToken, actu.id, commentMessage);
      setTimeout(() => {
        getNews(callback && callback(response.success, response.result));
        debug && console.debug('ActualityCard comment sent');
      }, 200);
    };

    const addToLike = async (callback) => {
      debug && console.debug('ActualityCard addToLike', actu.id);

      // Change actu isLikedByUser status
      const response = await NewsAPI.likeNews(tokens.wsToken, actu.id);
      setTimeout(() => {
        getNews(callback && callback(response.success, response.result));
        debug && console.debug('ActualityCard comment sent');
      }, 200);
    };

    const shareActuality = async (actu) => {
      debug && console.debug('ActualityCard sharing...', actu.id);

      Share.share(
        {
          message: Constants.expoConfig.extra.shareUrl + actu?.id,
          url: Constants.expoConfig.extra.shareUrl + actu?.id,
          title: "Découvre l'application " + Constants.expoConfig.name,
        },
        {
          subject: "Découvre l'application " + Constants.expoConfig.name,
        }
      ).then(
        (result) => {
          debug && console.debug(`ActualityCard ${actu.id}, share result: `, result);
        },
        (error) => {
          console.warn(`ActualityCard ${actu.id}, share error: `, error);
        }
      );
    };

    const reply = async (reply, callback) => {
      debug && console.debug('ActualityCard reply', actu.id, reply);

      const response = NewsAPI.answerNews(tokens.wsToken, actu.id, reply);
      setTimeout(() => {
        getNews(callback && callback(response.success, response.result));
        debug && console.debug('ActualityCard reply sent');
      }, 200);
    };

    const renderActualityFooter = (score = false) => {
      return (
        <View style={score ? styles.scoreFooter : styles.actuFooter}>
          <View style={styles.buttonsContainer}>
            <View>
              <Pressable style={[styles.favComUpButton]} onPress={() => addToLike()}>
                <Icon size={25} name={actu.isLikedByUser ? 'heart' : 'heart-outline'} color={Colors.blueCorporate} />
                <Text style={{ color: Colors.blueCorporate, marginLeft: 8 }}>{actu.likeNumber}</Text>
              </Pressable>
            </View>

            <View style={{ alignItems: 'center' }}>
              <Pressable style={[styles.favComUpButton]} onPress={() => popupRef.open(true)}>
                <Icon
                  size={25}
                  name={actu.commentNumber > 0 ? 'chatbox' : 'chatbox-outline'}
                  color={Colors.blueCorporate}
                />
                <Text style={{ color: Colors.blueCorporate, marginLeft: 8 }}>{actu.commentNumber}</Text>
              </Pressable>
            </View>

            <View style={{ alignItems: 'flex-end' }}>
              <Pressable style={[styles.favComUpButton]} onPress={() => shareActuality(actu)}>
                <Icon size={25} name="md-share-outline" color={Colors.blueCorporate} />
              </Pressable>
            </View>
          </View>
        </View>
      );
    };

    return (
      <View style={[styles.mainContainer, { backgroundColor: actu.isValid !== '1' ? Colors.gray1 : null }]}>
        {actu.ActualityType_id !== ACT_SCORE ? (
          <>
            <View key={actu.id} style={[styles.actuContainer]}>
              <View style={styles.actuHeader}>
                <Pressable
                  style={{ flexDirection: 'row', alignSelf: 'flex-start' }}
                  onPress={() => popupRef.open(false)}>
                  <Image style={styles.actuHeaderLogo} source={clubLogo} />

                  <View style={styles.actuHeaderView}>
                    <Text style={styles.actuHeaderTitle} numberOfLines={1} ellipsizeMode="tail">
                      {actu.ActualityType_id === ACT_ACTUALITY
                        ? I18n.t('actuList.typeActu')
                        : I18n.t('actuList.typeEvent')}
                    </Text>

                    <Text style={styles.actuHeaderTime} numberOfLines={1} ellipsizeMode="tail">
                      {getTimeAgo(actu, 'releaseDate')}
                    </Text>
                  </View>
                </Pressable>
                <Pressable style={styles.actuButton} onPress={() => setShowActionReport(true)}>
                  <Icon name="ellipsis-vertical" size={25} color={Colors.blueCorporate} />
                </Pressable>
              </View>

              <Pressable style={{ flexDirection: 'row' }} onPress={() => popupRef.open(false)}>
                <View style={styles.actuBody}>
                  <Text style={styles.actuBodyText}>{actu.title ? actu.title : 'Titre '}</Text>

                  {actu.ActualityType_id === ACT_EVENT && (
                    <View style={{ justifyContent: 'space-between' }}>
                      <Text style={styles.actuEventDateTime}>
                        {'Le ' +
                          moment(actu.actualityDate + ' ' + actu.time, 'DD/MM/YYYY HH:mm').format(
                            'dddd Do MMMM YYYY à HH[h]mm'
                          )}
                      </Text>
                      <Text style={[styles.actuEventDateTime, { marginBottom: 10 }]}>{`À ${actu.addressSimple}`}</Text>
                    </View>
                  )}

                  {actu.description && actu.description !== '' && (
                    <>
                      <Autolink
                        numberOfLines={3}
                        ellipsizeMode="tail"
                        style={styles.actuDescriptionText}
                        linkStyle={{ padding: 2, margin: 0, color: Colors.textLink, fontWeight: 'bold' }}
                        text={actu.description}
                        email
                        hashtag="instagram"
                        mention="twitter"
                        phone
                        url
                      />
                    </>
                  )}
                </View>
              </Pressable>

              {actu.images && actu.images.length > 0 && <MediaViewer actualityId={actu.id} medias={actu.images} />}

              {actu.ActualityType_id === ACT_EVENT && actu.askReply === '1' && (
                <ShadowCard style={[styles.presenceReplyContainer]}>
                  <Text style={{ color: Colors.blueCorporate, opacity: 0.7 }}>
                    {I18n.t('convocation.confirmPresent') + ' :'}
                  </Text>

                  <View style={[styles.replyButtonsContainer]}>
                    <ButtonBase
                      disabled={loadingYes}
                      loading={loadingYes}
                      label={I18n.t('actuList.replyYes')}
                      style={[
                        styles.replyButton,
                        { backgroundColor: actu.myReply === REPLY_YES ? Colors.blueCorporate : Colors.white },
                      ]}
                      textStyle={{ color: actu.myReply === REPLY_YES ? Colors.white : Colors.blueCorporate }}
                      onPress={() => {
                        setLoadingYes(true);
                        reply(REPLY_YES, () => {
                          setLoadingYes(false);
                          setMyReply(REPLY_YES);
                        });
                      }}
                    />
                    <ButtonBase
                      disabled={loadingMayBe}
                      loading={loadingMayBe}
                      label={I18n.t('actuList.replyMayBe')}
                      style={[
                        styles.replyButton,
                        { backgroundColor: actu.myReply === REPLY_MAY_BE ? Colors.blueCorporate : Colors.white },
                      ]}
                      textStyle={{ color: actu.myReply === REPLY_MAY_BE ? Colors.white : Colors.blueCorporate }}
                      onPress={() => {
                        setLoadingMayBe(true);
                        reply(REPLY_MAY_BE, () => {
                          setLoadingMayBe(false);
                          setMyReply(REPLY_MAY_BE);
                        });
                      }}
                    />
                    <ButtonBase
                      disabled={loadingNo}
                      loading={loadingNo}
                      label={I18n.t('actuList.replyNo')}
                      style={[
                        styles.replyButton,
                        { backgroundColor: actu.myReply === REPLY_NO ? Colors.blueCorporate : Colors.white },
                      ]}
                      textStyle={{ color: actu.myReply === REPLY_NO ? Colors.white : Colors.blueCorporate }}
                      onPress={() => {
                        setLoadingNo(true);
                        reply(REPLY_NO, () => {
                          setLoadingNo(false);
                          setMyReply(REPLY_NO);
                        });
                      }}
                    />
                  </View>
                </ShadowCard>
              )}
            </View>

            {renderActualityFooter(false)}
          </>
        ) : (
          <>
            <View key={actu.id} style={[styles.actuContainer]}>
              <View style={styles.scoreHeader}>
                <Pressable style={{ flexDirection: 'row' }} onPress={() => popupRef.open(false)}>
                  <View style={styles.scoreHeaderView}>
                    <Text style={styles.scoreHeaderTitle}>
                      {`${I18n.t('actuList.typeScore')} : `}
                      {actu.isHalfTime === '1' ? I18n.t('createActu.halfTime') : I18n.t('createActu.endOfMatch')}
                    </Text>
                    <Text style={styles.scoreHeaderTime}>{getTimeAgo(actu, 'releaseDate')}</Text>
                  </View>
                </Pressable>
                <Pressable style={styles.scoreButton} onPress={() => setShowActionReport(true)}>
                  <Icon name="ellipsis-vertical" size={25} color={Colors.blueCorporate} />
                </Pressable>
              </View>

              <Pressable onPress={() => popupRef.open(false)}>
                <ShadowCard overflowStyle={styles.scoreBody}>
                  <View style={styles.teamColumn}>
                    <Text style={styles.scoreTeamName}>{actu.homeTeamName || actu.selectedHomeTeamName}</Text>

                    <Image
                      source={homeTeamLogo}
                      style={{ width: 48, height: 48 }}
                      alt="Home team logo"
                      loadingIndicatorSource={Images.loadingSpinner}
                      onError={(error) => {
                        console.debug(`Loading failed for home team logo ${homeTeamLogo}`, error.nativeEvent);
                      }}
                    />

                    <Text style={styles.scoreClubName} numberOfLines={2} ellipsizeMode="tail">
                      {actu.homeClubName ? actu.homeClubName.trim() : actu.hClubName.trim()}
                    </Text>
                  </View>
                  <View style={styles.scoreColumn}>
                    <>
                      {/*<Text style={[styles.scoreText, { alignSelf: 'flex-start' }]}>*/}
                      {/*  {actu.homeTeamScore}*/}
                      {/*</Text>*/}
                      <Text style={[styles.scoreText, { alignSelf: 'center', marginVertical: 5 }]}>
                        {actu.homeTeamScore}
                        {' - '}
                        {actu.awayTeamScore}
                      </Text>
                      {/*<Text style={[styles.scoreText, { alignSelf: 'flex-end' }]}>*/}
                      {/*  {actu.awayTeamScore}*/}
                      {/*</Text>*/}
                    </>
                  </View>
                  <View style={styles.teamColumn}>
                    <Text style={styles.scoreTeamName}>{actu.awayTeamName || actu.selectedAwayTeamName}</Text>
                    <Image
                      source={awayTeamLogo}
                      style={{ width: 48, height: 48 }}
                      alt="Away team logo"
                      loadingIndicatorSource={Images.loadingSpinner}
                      onError={(error) => {
                        console.debug(`Loading failed for away team logo ${awayTeamLogo}`, error.nativeEvent);
                      }}
                    />
                    <Text style={styles.scoreClubName} numberOfLines={2} ellipsizeMode="tail">
                      {actu.awayClubName ? actu.awayClubName.trim() : actu.aClubName.trim()}
                    </Text>
                  </View>
                </ShadowCard>
              </Pressable>
            </View>

            {renderActualityFooter(true)}
          </>
        )}

        {actu.ActualityType_id === ACT_ACTUALITY && (
          <PopupActuality
            popupRef={(ref) => setPopupRef(ref)}
            actu={actu}
            onOpen={() => {
              getNews();
            }}
            onClose={() => {
              getNews();
            }}
            addToLike={async (callback) => {
              await addToLike(() => {
                if (callback) {
                  callback();
                }
              });
            }}
            sendComment={async (comment, callback) => {
              await sendComment(actu, comment, () => {
                if (callback) {
                  callback();
                }
              });
            }}
            onShare={() => shareActuality(actu)}
            navigation={navigation}
            action={action}
            onRefresh={() => onRefresh()}
          />
        )}

        {actu.ActualityType_id === ACT_SCORE && (
          <PopupScore
            popupRef={(ref) => setPopupRef(ref)}
            actu={actu}
            elapsedSecond={actu.elapsedSecond}
            onOpen={() => {
              getNews();
            }}
            onClose={() => {
              getNews();
            }}
            addToLike={async (callback) => {
              await addToLike(() => {
                if (callback) {
                  callback();
                }
              });
            }}
            sendComment={async (comment, callback) => {
              await sendComment(actu, comment, () => {
                if (callback) {
                  callback();
                }
              });
            }}
            onShare={() => shareActuality(actu)}
            navigation={navigation}
            action={action}
            onRefresh={() => onRefresh()}
          />
        )}

        {actu.ActualityType_id === ACT_EVENT && (
          <PopupEvent
            popupRef={(ref) => setPopupRef(ref)}
            actu={actu}
            onOpen={() => {
              getNews();
            }}
            onClose={() => {
              getNews();
            }}
            addToLike={async (callback) => {
              await addToLike(() => {
                if (callback) {
                  callback();
                }
              });
            }}
            sendComment={async (comment, callback) => {
              await sendComment(actu, comment, () => {
                if (callback) {
                  callback();
                }
              });
            }}
            onReply={async (value, callback) => {
              await reply(value, () => {
                if (callback) {
                  callback();
                }
              });
            }}
            onShare={() => shareActuality(actu)}
            navigation={navigation}
            action={action}
            onRefresh={() => onRefresh()}
          />
        )}

        <SelectModerate
          visible={showActionReport}
          actuality={actu}
          onSelected={(choice) => {
            setShowActionReport(false);
            if (!tokens) {
              return;
            }
            if (choice.id === 'validate') {
              if (!onDelete) {
                return;
              }
              setAlertTitle(I18n.t('app.information'));
              setAlertMessage(I18n.t('app.confirmValidateActu'));
              setAlertButtons([{ text: I18n.t('app.no') }, { text: I18n.t('app.yes'), onPress: () => onValidate() }]);
              setShowAlert(true);
            }
            if (choice.id === 'modify') {
              navigation.navigate('HomeStack', {
                screen: 'CreateActuality',
                params: { mode: 'edit', item: actu },
              });
            }
            if (choice.id === 'delete') {
              if (!onDelete) {
                return;
              }
              setAlertTitle(I18n.t('app.information'));
              setAlertMessage(I18n.t('app.confirmDeleteActu'));
              setAlertButtons([{ text: I18n.t('app.no') }, { text: I18n.t('app.yes'), onPress: () => onDelete() }]);
              setShowAlert(true);
            }
            if (choice.id === 'signalUser' || choice.id === 'signalContent') {
              alert(
                I18n.t('app.information'),
                "Etes-vous sûr de vouloir signaler cet utilisateur (l'administrateur de l'application sera notifié) ?",
                [
                  {
                    text: 'Annuler',
                  },
                  {
                    text: 'Ok',
                    onPress: async () => {
                      const userHasReportComment = await AccountAPI.userReportUser(tokens.wsToken, actu.userId);
                      if (userHasReportComment?.success) {
                        alert(
                          I18n.t('app.information'),
                          "L'utilisateur a été signalé et l'administrateur a été informé.",
                          [
                            {
                              text: 'Ok',
                              onPress: async () => {
                                setShowActionReport(false);
                              },
                            },
                          ],
                          {
                            cancelable: false,
                          }
                        );
                      } else {
                        alert(
                          I18n.t('app.information'),
                          'Erreur lors du signalement, veuillez contacter un administrateur.'
                        );
                      }
                    },
                  },
                ]
              );
            }
            if (choice.id === 'blockUser') {
              alert(
                I18n.t('app.information'),
                'Etes-vous sûr de vouloir Bloquer cet utilisateur (vous ne pourrez plus voir ses publications/commentaires et il ne pourra plus voir les vôtres) ?',
                [
                  {
                    text: 'Annuler',
                  },
                  {
                    text: 'Ok',
                    onPress: async () => {
                      const userHasReportComment = await AccountAPI.blockUser(tokens.wsToken, actu.userId);

                      if (userHasReportComment) {
                        alert(
                          I18n.t('app.information'),
                          "L'utilisateur a été bloqué, vous ne pouvez plus voir ses publications/commentaires et il ne peut plus voir les vôtres.",
                          [
                            {
                              text: 'Ok',
                              onPress: () => {
                                onRefresh();
                                setShowActionReport(false);
                              },
                            },
                          ],
                          {
                            cancelable: false,
                          }
                        );
                      } else {
                        alert(
                          I18n.t('app.information'),
                          'Erreur lors du signalement, veuillez contacter un administrateur.'
                        );
                      }
                    },
                  },
                ]
              );
            }
            if (choice.id === 'blockContent') {
              alert(
                I18n.t('app.information'),
                'Etes-vous sûr de vouloir bloquer ce contenu (vous ne pourrez plus le voir) ?',
                [
                  {
                    text: 'Annuler',
                  },
                  {
                    text: 'Ok',
                    onPress: async () => {
                      const isPostBlocked = await AccountAPI.blockUserPost(tokens.wsToken, actu.id);

                      if (isPostBlocked) {
                        alert(
                          I18n.t('app.information'),
                          'Ce contenu est bloqué et vous ne le verrez plus.',
                          [
                            {
                              text: 'Ok',
                              onPress: () => {
                                onRefresh();
                                setShowActionReport(false);
                              },
                            },
                          ],
                          {
                            cancelable: false,
                          }
                        );
                      } else {
                        alert(
                          I18n.t('app.information'),
                          'Erreur lors du blocage du contenu, veuillez contacter un administrateur.'
                        );
                      }
                    },
                  },
                ]
              );
            }
          }}
          onClose={() => setShowActionReport(false) }
        />

        <AlerteUI
          showAlert={showAlert}
          onClose={() => setShowAlert(false)}
          title={alertTitle}
          message={alertMessage}
          buttons={alertButtons}
        />
      </View>
    );
  }
);

const styles = StyleSheet.create({
  appFontRegular: {
    fontFamily: 'appFontRegular',
  },
  whiteText: {
    color: Colors.whiteCorporate,
  },
  redText: {
    color: Colors.red,
  },

  mainContainer: {
    flex: 1,
    width: Platform.OS === 'web' ? gMaxWidth : sw,
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  actuContainer: {
    width: Platform.OS === 'web' ? gMaxWidth : sw,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 10,
  },

  actuHeader: {
    width: Platform.OS === 'web' ? gMaxWidth - 20 : sw,
    backgroundColor: Colors.whiteCorporate,

    flexDirection: 'row',
    alignItems: 'flex-start',
    //
    // borderTopStartRadius: 10,
    // borderTopEndRadius: 10,
  },
  actuHeaderLogo: {
    width: 36,
    height: 36,
    borderRadius: 18,
  },
  actuHeaderView: {
    width: (Platform.OS === 'web' ? gMaxWidth : sw) - 85,
    flexDirection: 'column',
    overflow: 'hidden',
    alignItems: 'flex-start',
    textAlign: 'left',
    paddingLeft: 10,
  },
  actuHeaderTitle: {
    fontFamily: 'appFontMedium',
    color: Colors.red,
    fontSize: 18,
  },
  actuHeaderTime: {
    fontFamily: 'appFontRegular',
    color: Colors.blueCorporate,
    fontSize: 12,
    maxWidth: '48%',
    opacity: 0.5,
    marginLeft: 5,
  },

  actuBody: {
    width: Platform.OS === 'web' ? gMaxWidth - 20 : sw - 20,

    marginVertical: 0,
    marginHorizontal: 0,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    color: Colors.green,
    overflow: 'hidden',
  },
  actuBodyText: {
    fontFamily: 'appFontMedium',
    fontSize: 16,
    color: Colors.blueCorporate,
    marginBottom: 7,
    textAlign: 'justify',
  },

  actuButton: {
    width: 40,
    height: 40,
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actuFooter: {
    width: Platform.OS === 'web' ? gMaxWidth - 20 : sw,
    backgroundColor: Colors.grayWhite,
    paddingHorizontal: 10,
    //
    // borderBottomStartRadius: 10,
    // borderBottomEndRadius: 10,
  },

  scoreHeader: {
    width: Platform.OS === 'web' ? gMaxWidth - 20 : sw,
    backgroundColor: Colors.whiteCorporate,

    paddingHorizontal: 10,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  scoreHeaderView: {
    width: (Platform.OS === 'web' ? gMaxWidth : sw) - 60,
    flexDirection: 'column',
    overflow: 'hidden',
    alignItems: 'center',
    paddingLeft: 10,
  },
  scoreHeaderTitle: {
    fontFamily: 'appFontMedium',
    color: Colors.blueCorporate,
    fontSize: 18,
  },
  scoreHeaderTime: {
    fontFamily: 'appFontRegular',
    color: Colors.blueCorporate,
    fontSize: 12,
    opacity: 0.5,
    alignSelf: 'center',
    marginHorizontal: 5,
  },

  scoreBody: {
    width: Platform.OS === 'web' ? gMaxWidth - 30 : sw - 30,

    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',

    padding: 5,
    borderRadius: 10,
    marginHorizontal: 0,
  },
  teamColumn: {
    width: ((Platform.OS === 'web' ? gMaxWidth : sw) - 60) / 3 + 15,
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 10,
    backgroundColor: Colors.whiteCorporate,
  },
  scoreColumn: {
    width: ((Platform.OS === 'web' ? gMaxWidth : sw) - 60) / 3 - (Platform.OS === 'web' ? 40 : 20),
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 10,
    marginHorizontal: 5,
  },
  scoreText: {
    fontFamily: 'appFontMedium',
    fontSize: 20,
    color: Colors.blueCorporate,
  },
  scoreTeamName: {
    textAlign: 'center',
    fontSize: 10,
    color: Colors.blueCorporate,
    opacity: 0.5,
    fontFamily: 'appFontMedium',
    fontWeight: 'bold',
    marginBottom: 5,
  },
  scoreClubName: {
    textAlign: 'center',
    fontSize: 10,
    color: Colors.blueCorporate,
    opacity: 0.5,
    fontFamily: 'appFontRegular',
    marginTop: 5,
  },
  scoreButton: {
    width: 40,
    height: 40,
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
  },
  scoreFooter: {
    width: Platform.OS === 'web' ? gMaxWidth - 20 : sw,
    backgroundColor: Colors.grayWhite,
    paddingHorizontal: 10,
  },

  actuEventDateTime: {
    color: Colors.blueCorporate,
    opacity: 0.7,
    fontWeight: 'bold',
    marginBottom: 3,
  },
  actuDescriptionText: {
    // width: sw - 20,
    fontFamily: 'appFontRegular',
    color: Colors.blueCorporate,
    // backgroundColor: Colors.blueCorporate,
    opacity: 1,
    lineHeight: 20,
  },
  renderSeeMore: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    top: -30,
    left: -10,
  },

  buttonsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  presenceReplyContainer: {
    width: Platform.OS === 'web' ? gMaxWidth - 20 : sw - 20,

    marginVertical: 5,
    marginHorizontal: 0,
    padding: 5,
  },
  replyButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: 0,
  },
  replyButton: {
    width: 100,
    height: 25,
  },
  replyButtonLabel: {
    fontSize: 12,
    color: Colors.iconGray,
  },

  favComUpButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 0,
    marginLeft: 5,
    marginTop: 0,
  },
});

export default ActualityCard;
