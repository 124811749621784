export const ADD_AFFILIATE_CLUB = 'ADD_AFFILIATE_CLUB';
export const SET_AFFILIATED_CLUBS = 'SET_AFFILIATED_CLUBS';
export const SET_FAVORITES = 'SET_FAVORITES';
export const UPDATE_INFORMATION_AFFILIATED_CLUB = 'UPDATE_INFORMATION_AFFILIATED_CLUB';
export const UPDATE_INFORMATION_CLUB_SEARCHED = 'UPDATE_INFORMATION_CLUB_SEARCHED';
export const UPDATE_MY_CLUB_FILTER = 'UPDATE_MY_CLUB_FILTER';
export const UPDATE_PENDING_FILTER = 'UPDATE_PENDING_FILTER';

export const UPDATE_EDITING_TEAM = 'UPDATE_EDITING_TEAM';
export const SET_LOCAL_PARAMETERS = 'SET_LOCAL_PARAMETERS';

const initialState = {
  // All the clubs the current user is affiliated
  allAffiliatedClubs: [],
  // The currently selected club
  affiliatedClubInformation: null,
  // a club found in the search club page
  foundOtherClub: null,

  // Favorites selected in the clubs filter
  favorites: {
    clubs: [],
    departments: [],
    regions: [],
  },

  localParameters: {
    allConversations: false,
  },

  myClubFilter: false,
  pendingFilter: false,

  editingTeam: null,
};

const debug = false;

const sessionReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case 'RESET_FOR_LOGOUT':
      debug && console.debug('SessionReducer RESET_FOR_LOGOUT');
      return initialState;

    case 'SET_SESSION': {
      return {
        ...state,
        session: payload.value,
      };
    }

    case ADD_AFFILIATE_CLUB: {
      if (!payload.club) {
        return state;
      }
      const allActifAffiliatedClubinState = state.allAffiliatedClubs;
      const checkIfAfiliateClubHasBeenSave = allActifAffiliatedClubinState.some((club) => club.id === payload.club.id);

      /**
       * case it's the first time user add new club as affiliated
       */
      if (state.allAffiliatedClubs.length === 0) {
        const newAllActifAffiliatedClub = [payload.club, ...state.allAffiliatedClubs];
        return {
          ...state,
          allAffiliatedClubs: newAllActifAffiliatedClub,
          affiliatedClubInformation: payload.club,
        };
      }

      /**
       * case user has already the affiliated and add new club as affiliated
       */
      if (!checkIfAfiliateClubHasBeenSave) {
        const newAllActifAffiliatedClub = [payload.club, ...state.allAffiliatedClubs];
        const orderDataByAlphabet = newAllActifAffiliatedClub.sort((a, b) => a.name.localeCompare(b.name));
        return {
          ...state,
          allAffiliatedClubs: orderDataByAlphabet,
          affiliatedClubInformation: payload.club,
        };
      }
      /**
       * case user selected a club already affiliated
       */
      return {
        ...state,
        affiliatedClubInformation: payload.club,
      };
    }

    case SET_AFFILIATED_CLUBS:
      if (!payload.clubs || payload.clubs.length === 0) {
        return state;
      }

      return {
        ...state,
        allAffiliatedClubs: payload.clubs,
        affiliatedClubInformation: payload.clubs.find((club) => club.id === payload.clubId) ?? payload.clubs[0],
      };

    case SET_FAVORITES:
      return {
        ...state,
        favorites: {
          someExists: payload.someExists,
          regions: payload.regions,
          regionsIds: payload.regions.map((item) => item.id),
          departments: payload.departments,
          departmentsIds: payload.departments.map((item) => item.id),
          clubs: payload.clubs,
          clubsIds: payload.clubs.map((item) => item.id),
        },
      };

    case SET_LOCAL_PARAMETERS:
      return {
        ...state,
        localParameters: payload.parameters,
      };

    case UPDATE_INFORMATION_CLUB_SEARCHED:
      return {
        ...state,
        foundOtherClub: payload.club,
      };

    case UPDATE_INFORMATION_AFFILIATED_CLUB:
      return {
        ...state,
        affiliatedClubInformation: payload.club ?? null,
      };

    case UPDATE_MY_CLUB_FILTER:
      return {
        ...state,
        myClubFilter: payload.myClubFilter ?? false,
      };

    case UPDATE_PENDING_FILTER:
      return {
        ...state,
        pendingFilter: payload.pendingFilter ?? false,
      };

    case UPDATE_EDITING_TEAM:
      return {
        ...state,
        editingTeam: payload.editingTeam ?? null,
      };

    default:
      return state;
  }
};
export default sessionReducer;
