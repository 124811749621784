import ButtonCancel from '@components/ButtonCancel/ButtonCancel';
import ButtonValidate from '@components/ButtonValidate/ButtonValidate';
import GradientBackground from '@components/GradientBackground/GradientBackground';
import Colors from '@configs/colors';
import I18n from '@utils/i18n';
import { openMail } from '@utils/openLinks';
import Constants from 'expo-constants';
import React, { Component } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { connect } from 'react-redux';

class ClubNotAffiliatedPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <GradientBackground>
        <View style={styles.rootContainer}>
          <Text style={[styles.oupsText, styles.oupsTextTitle]}>{I18n.t('app.information')}</Text>
          <Text style={[styles.oupsText]}>
            {I18n.t('clubs.notAffiliated1', { appName: Constants.expoConfig.name })}
          </Text>
          <Text style={[styles.oupsText]}>
            {I18n.t('clubs.notAffiliated2', { appName: Constants.expoConfig.name })}
          </Text>

          <Pressable
            style={{
              backgroundColor: Colors.gray,
              justifyContent: 'center',
              alignItems: 'center',
              padding: 20,
              borderRadius: 10,
            }}
            onPress={() => {
              openMail(this.props.apiConfiguration.supportMail, 'Enregistrement pour un club non affilié');
            }}>
            <Text style={[styles.oupsText, { fontSize: 16 }]}>
              {I18n.t('clubs.contact') + '  ' + Constants.expoConfig.name + ':'}
              <Text style={{ fontSize: 14 }}>{'\n' + this.props.apiConfiguration.supportMail}</Text>
            </Text>
          </Pressable>

          <Pressable
            style={{ padding: 15 }}
            onPress={() => {
              this.props.okButton();
            }}>
            <Text style={[styles.oupsText, { textDecorationLine: 'underline' }]}>
              {I18n.t('clubs.notAffiliated3', { appName: Constants.expoConfig.name })}
            </Text>
          </Pressable>

          <View style={[styles.buttonsContainer]}>
            <ButtonCancel onPress={() => this.props.closePopup()} style={{ width: 110 }} />
            <ButtonValidate
              label="Continuer"
              onPress={() => this.props.okButton()}
              style={{ width: 110, marginLeft: 10 }}
            />
          </View>
        </View>
      </GradientBackground>
    );
  }
}

const styles = StyleSheet.create({
  rootContainer: {
    // flex: 1,
    marginTop: 100,
    alignItems: 'center',
  },
  mainContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  profileTypesContainer: {
    alignSelf: 'center',
    width: '90%',
    height: '90%',
    borderRadius: 20,
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 40,
    paddingBottom: 20,
  },
  buttonsContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  oupsText: {
    fontFamily: 'appFontRegular',
    color: Colors.blueCorporate,
    lineHeight: 16,
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 10,
  },
  oupsTextTitle: {
    fontSize: 20,
    lineHeight: 24,
  },
});

const mapStateToProps = (state) => {
  const { apiConfiguration } = state.app;
  return {
    apiConfiguration,
  };
};

export default connect(mapStateToProps)(ClubNotAffiliatedPopup);
