import { ImageNoLoader } from '@components/Image/ImageNoLoader';
import MediaZoomViewer from '@components/MediaZoomViewer/MediaZoomViewer';
import { VideoNoLoader } from '@components/Video/VideoNoLoader';
import { VideoWithLoader } from '@components/Video/VideoWithLoader';
import Colors from '@configs/colors';
import { gMaxWidth } from '@configs/styles';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Pressable, FlatList, Text, Platform, Dimensions } from 'react-native';

const { width: sw } = Dimensions.get('window');

/**
 * Displays a list of images in a list swiper
 *
 *    return <MediaViewer medias={mediaList} />;
 *
 * @param actualityId
 *  identifier of the concerned actuality (mainly for log purpose)
 * @param medias
 *  list of medias as fetched from the API in actu.images and updated in the actuality Redux actions
 * @param isInView
 */
const MediaViewer = ({ actualityId, medias, isInView = true }) => {
  const debug = false;

  // Media references
  const mediaRefs = useRef([]);

  const [showImageZoomViewer, setShowImageZoomViewer] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    debug && console.debug(`MediaViewer, ${actualityId} medias:`, medias);

    setInitialized(true);
  }, []);

  useEffect(() => {
    return () => {
      debug && console.debug(`MediaViewer, ${actualityId} unmounting !`);
      for (const mediaRef of mediaRefs.current) {
        if (mediaRef) {
          debug && console.debug(`MediaViewer, ${actualityId} media reference:`, mediaRef);
          mediaRef.stop();
          mediaRef.unload();
        }
      }
    };
  }, []);

  /**
   * Called any time a new media is shown when a user scrolls
   * the list, when this happens we should start playing
   * the post that is viewable and stop all the others
   */
  const onViewableItemsChanged = useRef(({ changed }) => {
    changed.forEach((element) => {
      const cell = mediaRefs.current[element.key];
      if (cell) {
        debug && console.debug(`MediaViewer, ${actualityId} onViewableItemsChanged`, element);
        // debug && console.debug(`MediaViewer, ${actualityId} onViewableItemsChanged mediaRefs`, mediaRefs);
        if (element.isViewable) {
          // cell.play();
        } else {
          cell.stop();
        }
      }
    });
  });

  const renderItem = ({ item, index }) => {
    if (!item) {
      return null;
    }

    debug && console.debug(`MediaViewer, ${actualityId} rendering ${item.id}...`, item);
    if (item.type === 'video') {
      if (Platform.OS === 'web') {
        return (
          <VideoNoLoader source={item} ref={(VideoNoLoaderRef) => (mediaRefs.current[item.id] = VideoNoLoaderRef)} />
        );
      }
      return (
        <VideoWithLoader source={item} ref={(VideoNoLoaderRef) => (mediaRefs.current[item.id] = VideoNoLoaderRef)} />
      );
    }

    return (
      <Pressable
        style={[styles.mediaContainer, { marginLeft: item.margin }]}
        onPress={() => {
          debug && console.debug(`MediaViewer, ${actualityId} selected to zoom ${item.id}, index ${index}...`);
          setSelectedImageIndex(index);
          setShowImageZoomViewer(true);
        }}>
        <ImageNoLoader source={item} />
      </Pressable>
    );
  };

  if (!medias || medias.length <= 0 || !initialized || !isInView) {
    return;
  }

  debug && console.debug(`MediaViewer, ${actualityId} rendering...`);
  return (
    <>
      <View style={[styles.mainContainer]}>
        <FlatList
          horizontal
          data={medias}
          keyExtractor={(item) => item.id}
          contentContainerStyle={[styles.listContentContainer, { justifyContent: 'flex-start' }]}
          showsHorizontalScrollIndicator
          windowSize={2}
          initialNumToRender={1}
          maxToRenderPerBatch={2}
          removeClippedSubviews
          ListEmptyComponent={
            <Text style={{ alignSelf: 'center', color: Colors.blueCorporate }}>Chargement en cours...</Text>
          }
          renderItem={renderItem}
          viewabilityConfig={{
            itemVisiblePercentThreshold: 80,
          }}
          onViewableItemsChanged={onViewableItemsChanged.current}
        />
      </View>

      <MediaZoomViewer
        medias={medias}
        index={selectedImageIndex}
        visible={showImageZoomViewer}
        onClose={() => {
          setShowImageZoomViewer(false);
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    width: Platform.OS === 'web' ? gMaxWidth : sw,
    marginHorizontal: -10,
  },
  listContentContainer: {
    padding: 0,
    margin: 0,
  },
  mediaContainer: {
    flex: 1,
    padding: 0,
    backgroundColor: Colors.grayWhite,
  },
  slideContainer: {
    flex: 1,
    backgroundColor: Colors.blueSky,
    padding: 1,
    borderRadius: 10,
    // flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    borderRadius: 10,
  },
  video: {
    borderRadius: 10,
    backgroundColor: Colors.grayWhite,
  },
});

export default MediaViewer;
