import Images from '@assets/images';
import Loading from '@components/Loading/Loading';
import Colors from '@configs/colors';
import { ResizeMode, Video } from 'expo-av';
import * as Crypto from 'expo-crypto';
import * as FileSystem from 'expo-file-system';
import { Image } from 'expo-image';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ActivityIndicator, Dimensions, Pressable, StyleSheet, Text, View } from 'react-native';
import ProgressBar from 'react-native-progress/Bar';
import Icon from 'react-native-vector-icons/Ionicons';

const { width: sw, height: sh } = Dimensions.get('window');

export const VideoWithLoader = forwardRef(({ source, onClose = () => {} }, parentRef) => {
  const debug = false;

  const refVideo = useRef(null);
  useImperativeHandle(parentRef, () => ({
    play,
    unload,
    stop,
  }));

  const [failure, setFailure] = useState(false);

  const [videoURI, setVideoURI] = useState({});
  const [loading, setLoading] = useState(true);
  const [buffering, setBuffering] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [paused, setPaused] = useState(false);
  const [played, setPlayed] = useState(false);
  const [finished, setFinished] = useState(false);

  const [status, setStatus] = useState({});

  useEffect(() => {
    const getExtension = (remoteUri) => {
      const ext = remoteUri.split('.').pop()?.replace('?alt=media', '');
      if (ext) {
        return ext;
      }
      return 'mov';
    };

    const getImageFilesystemKey = async (remoteURI) => {
      try {
        const hashed = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA256, remoteURI);
        const ext = getExtension(remoteURI);
        return `${FileSystem.cacheDirectory}${hashed}.${ext}`;
      } catch (error) {
        console.warn(`VideoWithLoader, ${source.Actuality_id}/${source.id} getImageFilesystemKey error:`, error);
        return null;
      }
    };

    const loadVideo = async (fileSystemURI, remoteURI) => {
      debug && console.debug(`VideoWithLoader, getting video: ${fileSystemURI}`);

      FileSystem.getInfoAsync(fileSystemURI)
        .then((response) => {
          debug && console.debug('VideoWithLoader, Local FS metadata: ', response);
          if (response.exists) {
            refVideo.current.loadAsync({ uri: remoteURI });
            setVideoURI(fileSystemURI);
          } else {
            debug && console.debug('VideoWithLoader, Downloading video...', response);
            FileSystem.downloadAsync(remoteURI, fileSystemURI).then((response) => {
              debug &&
                console.debug(`VideoWithLoader, ${source.Actuality_id}/${source.id} Downloaded video: `, response);
              setVideoURI(response.uri);
            });
          }
        })
        .catch((error) => {
          console.warn(`VideoWithLoader,  ${source.Actuality_id}/${source.id} FileSystem.getInfoAsync error:`, error);
          setVideoURI(remoteURI);
        });
    };

    const launch = async () => {
      const fileSystemURI = await getImageFilesystemKey(source.uri);
      debug && console.debug(`VideoWithLoader, File system URI: ${fileSystemURI}`);

      if (source.uri === videoURI || fileSystemURI === videoURI) {
        debug && console.debug(`VideoWithLoader, still present in cache`);
        if (status.isLoaded === false) {
          try {
            debug && console.debug(`VideoWithLoader, start loading ${source.Actuality_id}/${source.id}.`);
            // loadMedia();
            refVideo.current.loadAsync({ uri: source.uri });
          } catch (e) {
            console.log('VideoWithLoader, status media loading error', e);
          }
        }

        return null;
      }
      await loadVideo(fileSystemURI, source.uri);
    };
    launch();
  }, []);

  useEffect(() => {
    debug && console.debug(`VideoWithLoader, media ${source.Actuality_id}/${source.id}: `, source);

    // When the component is unmounted...
    return () => unload();
  }, []);

  useEffect(() => {
    if (!refVideo || !status) {
      return;
    }
    // debug && console.debug(`VideoWithLoader, status: `, status);

    if (status.didJustFinish) {
      debug && console.debug(`VideoWithLoader, finished ${source.Actuality_id}/${source.id}!`);
      setPlayed(true);
      return;
    }

    if (status.shouldPlay && status.isBuffering) {
      debug && console.debug(`VideoWithLoader, buffering...`);
      setBuffering(true);
      return;
    }

    if (!status.isBuffering) {
      debug && console.debug(`VideoWithLoader, no more buffering...`);
      setBuffering(false);
    }

    if (status.shouldPlay && status.isPlaying) {
      debug && console.debug(`VideoWithLoader, playing: `, status.positionMillis / status.durationMillis);
      setPlaying(true);
      return;
    }

    debug && console.debug(`VideoWithLoader, status ${source.Actuality_id}/${source.id}: `, status);
  }, [status]);

  /**
   * Plays the video in the component if the ref
   * of the video is not null.
   *
   * @returns {void}
   */
  const play = async () => {
    if (refVideo.current === null) {
      return;
    }
    debug && console.debug(`VideoWithLoader, media play ${source.Actuality_id}/${source.id} status:`, status);

    // if video is already playing return
    const mediaStatus = await refVideo.current.getStatusAsync();
    debug && console.debug(`VideoWithLoader, media play ${source.Actuality_id}/${source.id} status:`, mediaStatus);
    if (mediaStatus?.isPlaying) {
      return;
    }
    if (mediaStatus?.isLoaded === false) {
      try {
        debug && console.debug(`VideoWithLoader, start loading ${source.Actuality_id}/${source.id}.`);
        await refVideo.current.loadAsync({ uri: source.uri });
      } catch (e) {
        console.log(`VideoWithLoader, ${source.Actuality_id}/${source.id} start playing loading error`, e);
        return;
      }
    }
    try {
      debug && console.debug(`VideoWithLoader, start playing ${source.Actuality_id}/${source.id}.`);
      if (played) {
        debug && console.debug(`VideoWithLoader, replay from beginning.`);
        await refVideo.current.playFromPositionAsync(0);
        // await refVideo.current.replayAsync();
      } else if (paused) {
        debug && console.debug(`VideoWithLoader, play after pause.`);
        await refVideo.current.playAsync();
      } else {
        debug && console.debug(`VideoWithLoader, play.`);
        await refVideo.current.playAsync();
      }
    } catch (e) {
      console.log(`VideoWithLoader, ${source.Actuality_id}/${source.id} start playing error`, e);
    }
  };

  /**
   * Pauses the video in the component if the ref
   * of the video is not null.
   *
   * @returns {void}
   */
  const pause = async () => {
    if (refVideo.current === null) {
      return;
    }

    // if video is already stopped return
    const mediaStatus = await refVideo.current.getStatusAsync();
    debug && console.debug(`VideoWithLoader, media ${source.Actuality_id}/${source.id} status:`, mediaStatus);
    // debug && console.debug(`VideoWithLoader, media ${source.Actuality_id}/${source.id} status:`, status);
    if (!mediaStatus?.isPlaying) {
      return;
    }
    try {
      debug && console.debug(`VideoWithLoader, pause playing ${source.Actuality_id}/${source.id}.`);
      await refVideo.current.pauseAsync();
      setPaused(true);
    } catch (e) {
      console.log(`VideoWithLoader, ${source.Actuality_id}/${source.id} pause playing error`, e);
    }
  };

  /**
   * Stops the video in the component if the ref
   * of the video is not null.
   *
   * @returns {void}
   */
  const stop = async () => {
    if (refVideo.current === null) {
      return;
    }

    // if video is already stopped return
    const mediaStatus = await refVideo.current.getStatusAsync();
    debug && console.debug(`VideoWithLoader, media ${source.Actuality_id}/${source.id} status:`, mediaStatus);
    if (!mediaStatus?.isPlaying) {
      return;
    }
    try {
      debug && console.debug(`VideoWithLoader, stop playing ${source.Actuality_id}/${source.id}.`);
      await refVideo.current.stopAsync();
    } catch (e) {
      console.log(`VideoWithLoader, ${source.Actuality_id}/${source.id} stop playing error`, e);
    }
  };

  /**
   * Makes the video full screen.
   *
   * @returns {void}
   */
  const fullScreen = async (enabled = true) => {
    if (refVideo.current === null) {
      return;
    }

    try {
      debug && console.debug(`VideoWithLoader, full screen for ${source.Actuality_id}/${source.id}.`);
      if (enabled) {
        await refVideo.current.presentFullscreenPlayer();
      } else {
        await refVideo.current.dismissFullscreenPlayer();
      }
    } catch (e) {
      console.log('VideoWithLoader, fullscreen error', e);
    }
  };

  /**
   * Unloads the video in the component if the ref
   * of the video is not null.
   *
   * This will make sure unnecessary video instances are
   * not in memory at all times
   *
   * @returns {void}
   */
  const unload = async () => {
    debug && console.debug(`VideoWithLoader, unloading ${source.Actuality_id}/${source.id}.`);
    if (refVideo.current === null) {
      return;
    }

    // if video is already stopped return
    try {
      debug && console.debug(`VideoWithLoader, unloading ${source.Actuality_id}/${source.id}.`);
      await refVideo.current.unloadAsync();
    } catch (e) {
      console.log('VideoWithLoader, unloading error', e);
    }
  };

  if (!source) {
    return;
  }

  if (failure) {
    return (
      <View style={styles.container}>
        <Image
          source={Images.videoLogo}
          alt="Image for a failing video"
          onError={(error) => {
            console.debug(`Loading failed for image`, error.nativeEvent);
          }}
          style={{ width: 128, height: 128 }}
        />
        <Text>Cette vidéo ne peut pas être lue</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Video
        ref={refVideo}
        style={[styles.video, { height: source.height, width: source.width }, { marginRight: 10 }]}
        // pointerEvents="none"
        source={{ uri: videoURI }}
        usePoster={!status.isLoaded}
        posterSource={source.thumbnail ? source.thumbnail : Images.videoLogo}
        posterStyle={{
          // alignSelf: 'center',
          // alignItems: 'center',
          // justifyContent: 'center',
          // paddingHorizontal: sw / 2 - 60,
          marginHorizontal: sw / 4,
          // paddingVertical: (sh - 120) / 2,
          // opacity: loading ? 0.5 : 0,
          opacity: 0.5,
          width: sw / 2,
          height: 240,
          bottom: 60,
        }}
        useNativeControls={false}
        resizeMode={ResizeMode.CONTAIN}
        shouldPlay={false}
        isLooping={false}
        isMuted={false}
        onReadyForDisplay={(event) => {
          // debug && console.debug(`VideoNoLoader, ready for display ${source.Actuality_id}/${source.id}`, event);
          debug &&
            console.debug(
              `VideoWithLoader, loaded ${source.Actuality_id}/${source.id}, duration: ${Date.now() - source.timeStart}`
            );
          setLoading(false);
          // For the Web, must set the absolute position!
          if (event.srcElement) {
            event.srcElement.style.position = 'initial';
          }
          event.naturalSize &&
            debug &&
            console.debug(
              `VideoWithLoader, ready for display ${source.Actuality_id}/${source.id}, duration: ${
                Date.now() - source.timeStart
              }. Size: ${event.naturalSize.width} x ${event.naturalSize.height}. Mode: ${event.naturalSize.orientation}`
            );
          setStatus(event.status);
        }}
        onPlaybackStatusUpdate={(status) => {
          // status: {"androidImplementation": "SimpleExoPlayer", "audioPan": 0, "didJustFinish": false,
          // "durationMillis": 20683, "isBuffering": true, "isLoaded": true, "isLooping": false, "isMuted": false,
          // "isPlaying": false, "playableDurationMillis": 4670, "positionMillis": 2618,
          // "progressUpdateIntervalMillis": 500, "rate": 1, "shouldCorrectPitch": false, "shouldPlay": true,
          // "uri": "/uploads//actuality/videos/132273263c018cf5d487fe1ab242ca11.mov", "volume": 1}
          // debug && console.debug(`VideoNoLoader, media ${source.Actuality_id}/${source.id} status:`, status);
          setStatus(status);
        }}
        onLoadStart={() => {
          debug && console.debug(`VideoWithLoader, loading media ${source.id}...`);
          source.timeStart = Date.now();
          setLoading(true);
        }}
        onLoad={() => {
          debug &&
            console.debug(
              `VideoWithLoader, loaded ${source.Actuality_id}/${source.id}, duration: ${
                Date.now() - source.timeStart
              }, duration: ${Date.now() - source.timeStart}`
            );
          setLoading(false);
        }}
        onLoadEnd={() => {
          debug &&
            console.debug(
              `VideoWithLoader, loaded ${source.Actuality_id}/${source.id}, duration: ${
                Date.now() - source.timeStart
              }, duration: ${Date.now() - source.timeStart}`
            );
          setLoading(false);
        }}
        onError={(error) => {
          console.warn(`VideoWithLoader ${source.Actuality_id}/${source.id}, error:`, error);
          setLoading(false);
          setFailure(true);
        }}
      />

      <View style={styles.videoControlContainer}>
        <Pressable
          style={({ pressed }) => [styles.controlButton, { opacity: pressed ? 0.7 : 1 }]}
          onPress={() => fullScreen(true)}>
          <Icon size={25} name="expand-outline" color={Colors.blueCorporate} />
        </Pressable>

        <Pressable
          style={({ pressed }) => [styles.controlButton, { opacity: pressed ? 0.7 : 1 }]}
          onPress={() => pause()}>
          <Icon size={25} name="pause" color={Colors.blueCorporate} />
        </Pressable>

        <Loading loading={loading || buffering} />

        {loading || buffering ? (
          <ActivityIndicator size="small" color={Colors.redCorporate} />
        ) : (
          <Pressable
            disabled={status.isPlaying}
            style={({ pressed }) => [
              styles.controlButton,
              { opacity: pressed ? 0.7 : 1 },
              { backgroundColor: status.isPlaying ? Colors.gray : Colors.whiteCorporate },
            ]}
            onPress={() => play()}>
            <Icon size={25} name="play-outline" color={Colors.blueCorporate} />
          </Pressable>
        )}

        <Pressable
          style={({ pressed }) => [styles.controlButton, { opacity: pressed ? 0.7 : 1 }]}
          onPress={() => stop()}>
          <Icon size={25} name="square-outline" color={Colors.blueCorporate} />
        </Pressable>
      </View>

      <View style={{ marginVertical: 5, marginHorizontal: 0 }}>
        <ProgressBar
          width={sw - 40}
          height={5}
          color={status?.isPlaying ? Colors.blueCorporate : status?.isBuffering ? Colors.redCorporate : Colors.black}
          unfilledColor={Colors.whiteCorporate}
          borderWidth={0}
          progress={(status && status.positionMillis / status.durationMillis) || 0}
        />
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // marginLeft: -5,
    width: sw,
  },
  video: {
    alignSelf: 'center',
    width: sw - 20,
    height: sh - 170,
    borderRadius: 10,
  },
  videoControlContainer: {
    alignSelf: 'center',
    width: sw - 80,
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginVertical: 5,
    marginHorizontal: 0,
    padding: 2,
    backgroundColor: Colors.whiteCorporate,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 0,
    padding: 0,
  },
  controlButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // color: Colors.blueCorporate,
    width: 60,
    height: 40,
    marginVertical: 0,
    backgroundColor: Colors.grayWhite,
  },
  controlButtonLabel: {
    fontSize: 12,
    color: Colors.iconGray,
  },
});
