import NotificationIcon from '@components/NotificationIcon/NotificationIcon';
import SelectAffiliatedClub from '@components/SelectAffiliatedClub/SelectAffiliatedClub';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import { getAllConversations, getClubComposition, getClubPartners } from '@redux/actions';
import { UPDATE_INFORMATION_AFFILIATED_CLUB, UPDATE_INFORMATION_CLUB_SEARCHED } from '@redux/reducers/SessionReducer';
import { ResizeMode } from 'expo-av';
import React, { forwardRef, useEffect, useState } from 'react';
import {
  BackHandler,
  Dimensions,
  Image,
  ImageBackground,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import Animated, { Extrapolation, interpolate, useAnimatedStyle, useDerivedValue } from 'react-native-reanimated';
import Icon from 'react-native-vector-icons/Ionicons';
import { useDispatch, useSelector } from 'react-redux';

const { width: sw } = Dimensions.get('window');

export const HEADER_MAX_HEIGHT = Platform.OS === 'ios' ? 160 : 140;
export const HEADER_MIN_HEIGHT = Platform.OS === 'ios' ? 70 : 50;
export const HEADER_SCROLL_DISTANCE = HEADER_MAX_HEIGHT - HEADER_MIN_HEIGHT;
export const HEADER_ICONS_HEIGHT = 50;

const HeaderCollapsible = forwardRef(function (props, ref) {
  const debug = false;

  const defaultLogo = require('@assets/logo.png');
  const defaultBanner = require('@assets/banner.png');

  const dispatch = useDispatch();

  // Animated styles
  const contentScrolled = useDerivedValue(() => {
    return Math.abs(props.contentScrolled.value);
  });
  const backgroundImageStyle = useAnimatedStyle(() => {
    return {
      height: interpolate(
        contentScrolled.value,
        [0, HEADER_MAX_HEIGHT],
        [HEADER_MAX_HEIGHT, HEADER_MIN_HEIGHT],
        Extrapolation.CLAMP
      ),
    };
  });
  const headerLogoStyle = useAnimatedStyle(() => {
    return {
      width: interpolate(
        contentScrolled.value,
        [0, HEADER_MAX_HEIGHT],
        [HEADER_SCROLL_DISTANCE - 4, 0],
        Extrapolation.CLAMP
      ),
      height: interpolate(
        contentScrolled.value,
        [0, HEADER_MAX_HEIGHT],
        [HEADER_SCROLL_DISTANCE - 4, 0],
        Extrapolation.CLAMP
      ),
      borderWidth: interpolate(contentScrolled.value, [0, HEADER_SCROLL_DISTANCE], [3, 0], Extrapolation.CLAMP),
    };
  });
  const headerExchangeStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: interpolate(
            contentScrolled.value,
            [HEADER_MIN_HEIGHT, HEADER_MAX_HEIGHT],
            [40, 70 - sw / 2],
            Extrapolation.CLAMP
          ),
        },
        {
          translateY: interpolate(
            contentScrolled.value,
            [HEADER_MIN_HEIGHT, HEADER_SCROLL_DISTANCE],
            [-80, Platform.OS === 'ios' ? -40 : -30],
            Extrapolation.CLAMP
          ),
        },
      ],
    };
  });
  const bannerIconsStyle = useAnimatedStyle(() => {
    if (Platform.OS === 'ios') {
      return {
        marginTop: interpolate(
          contentScrolled.value,
          [0, HEADER_MAX_HEIGHT],
          [HEADER_SCROLL_DISTANCE + 20, 0],
          Extrapolation.CLAMP
        ),
        paddingTop: interpolate(contentScrolled.value, [0, HEADER_MAX_HEIGHT], [0, 20], Extrapolation.CLAMP),
        height: interpolate(
          contentScrolled.value,
          [0, HEADER_MAX_HEIGHT],
          [HEADER_ICONS_HEIGHT, HEADER_ICONS_HEIGHT + 20],
          Extrapolation.CLAMP
        ),
      };
    }

    return {
      marginTop: interpolate(
        contentScrolled.value,
        [0, HEADER_SCROLL_DISTANCE],
        [HEADER_SCROLL_DISTANCE, 0],
        Extrapolation.CLAMP
      ),
    };
  });

  const [headerTitle, setHeaderTitle] = useState(props.headerTitle);
  const [headerLogo, setHeaderLogo] = useState(defaultLogo);
  const [headerBanner, setHeaderBanner] = useState(defaultBanner);

  // const [activeColor, setActiveColor] = useState(Colors.redCorporate);
  const [inactiveColor, setInactiveColor] = useState(Colors.whiteCorporate);
  const [backgroundColor, setBackgroundColor] = useState(Colors.blueCorporate);

  const { tokens } = useSelector((state) => state.app);
  const [currentClub, setCurrentClub] = useState(null);

  const { session, affiliatedClubInformation, foundOtherClub } = useSelector((state) => state.session);

  const [viewAffiliatedSelection, setViewAffiliatedSelection] = useState(false);

  useEffect(() => {
    debug &&
      console.debug(
        'HeaderCollapsible initial state (min, max, distance, icons)',
        HEADER_MIN_HEIGHT,
        HEADER_MAX_HEIGHT,
        HEADER_SCROLL_DISTANCE,
        HEADER_ICONS_HEIGHT
      );
  }, []);

  useEffect(() => {
    debug && console.debug(`HeaderCollapsible, club information changed, foundOtherClub:`, foundOtherClub);
    debug && console.debug(`HeaderCollapsible, club information changed:`, affiliatedClubInformation);
    debug && console.debug(`HeaderCollapsible, club information changed:`, session?.club);
    const club = foundOtherClub ?? affiliatedClubInformation ?? session?.club ?? null;
    setCurrentClub(club);

    if (!club) {
      setHeaderLogo(defaultLogo);
      setHeaderBanner(defaultBanner);
      setHeaderTitle(props.headerTitle ?? 'GUEST');

      // setActiveColor(Colors.redCorporate);
      setInactiveColor(Colors.whiteCorporate);
      setBackgroundColor(Colors.blueCorporate);
    } else {
      setHeaderLogo(club.logo ? { uri: getImageApiHostOrUrl(club.logo) } : defaultLogo);
      setHeaderBanner(club.bannerImage ? { uri: getImageApiHostOrUrl(club.bannerImage) } : defaultBanner);
      setHeaderTitle(club.name);

      // setActiveColor(club.activeIconColor);
      setInactiveColor(club.inactiveIconColor);
      setBackgroundColor(club.mainColorBottom);
    }
  }, [foundOtherClub, affiliatedClubInformation, session]);

  useEffect(() => {
    debug && console.debug(`HeaderCollapsible, current route:`, props.route);
  }, [props.route]);

  // Elipce/Fred: to be confirmed?
  BackHandler.addEventListener('hardwareBackPress', () => {
    // console.log('HeaderCollapsible, should have gone back', props.navigation.canGoBack(), props.navigation);
    // console.log(`HeaderCollapsible, current route:`, props.route);
    if (props.navigation.canGoBack()) {
      props.navigation.goBack();
    }
    return true;
  });

  const handleSelectedAffiliatedClub = (club) => {
    debug && console.debug(`HeaderCollapsible handleSelectedAffiliatedClub, club: `, club);

    setViewAffiliatedSelection(false);
    if (club) {
      // Changed current club !
      if (club.id !== currentClub.id) {
        dispatch({ type: UPDATE_INFORMATION_CLUB_SEARCHED, club: null });
        dispatch({ type: UPDATE_INFORMATION_AFFILIATED_CLUB, club });

        // Update current club information
        dispatch(getClubComposition(tokens.wsToken, club.id));
        dispatch(getClubPartners(tokens.wsToken, club.id, 1, 5));
        dispatch(getAllConversations(tokens.wsToken, club.id, true));

        props.onExchange && props.onExchange(club.id);
      }
    }
  };

  if (!currentClub) return;

  // props.onHeaderHeight && props.onHeaderHeight(headerHeight.value);

  return (
    <>
      <View style={[styles.mainContainer]}>
        <Animated.View style={[styles.header, backgroundImageStyle]}>
          <ImageBackground
            source={headerBanner}
            imageStyle={{ resizeMode: ResizeMode.COVER }}
            style={styles.headerBanner}>
            <Animated.View style={[styles.headerLogoContainer, headerLogoStyle]}>
              <Image source={headerLogo} style={[styles.headerLogo, { width: '100%', height: '100%' }]} />
            </Animated.View>

            <Animated.View
              style={[
                styles.headerTransparentBackground,
                bannerIconsStyle,
                { backgroundColor: backgroundColor + 'cc' },
              ]}>
              <Pressable style={styles.headerIconsAndText} onPress={() => props.onTitlePress && props.onTitlePress()}>
                {props.leftIcon ? (
                  <Pressable
                    style={({ pressed }) => [styles.headerIconLeft, pressed ? { opacity: 0.7 } : {}]}
                    onPress={() => {
                      debug && console.debug('HeaderCollapsible, pressed left icon');

                      if (!session) {
                        return;
                      }

                      if (props.openHeaderLeftButton) {
                        props.openHeaderLeftButton();
                      } else {
                        props.navigation.navigate('HomeStack', { screen: 'NotificationsList' });
                      }
                    }}>
                    <Icon size={32} name={props.leftIcon} color={inactiveColor} />
                  </Pressable>
                ) : (
                  <NotificationIcon navigation={props.navigation} />
                )}

                <Text style={[styles.headerText, { color: inactiveColor }]} numberOfLines={1} ellipsizeMode="tail">
                  {headerTitle}
                </Text>

                <Pressable
                  style={({ pressed }) => [styles.headerIconRight, pressed ? { opacity: 0.7 } : {}]}
                  onPress={() => {
                    props.navigation.openDrawer();
                  }}>
                  <Icon size={32} name="md-menu" color={inactiveColor} />
                </Pressable>
              </Pressable>
            </Animated.View>

            {props.displayExchangeIcon ? (
              <Animated.View
                style={[
                  styles.headerExchangeLogoContainer,
                  styles.shadowEffect,
                  { backgroundColor },
                  headerExchangeStyle,
                ]}>
                <Pressable onPress={() => setViewAffiliatedSelection(true)}>
                  <Image
                    source={require('@assets/icons/header/switch.png')}
                    style={[styles.headerExchangeLogo, { tintColor: inactiveColor ?? Colors.whiteCorporate }]}
                  />
                </Pressable>
              </Animated.View>
            ) : (
              <View style={{ width: 40, height: 40 }} />
            )}
          </ImageBackground>
        </Animated.View>
      </View>

      <SelectAffiliatedClub
        visible={viewAffiliatedSelection}
        onSelected={(data) => handleSelectedAffiliatedClub(data)}
        onClose={() => setViewAffiliatedSelection(false)}
      />
    </>
  );
});

const styles = StyleSheet.create({
  mainContainer: {
    maxHeight: Platform.OS === 'ios' ? HEADER_ICONS_HEIGHT + 20 : HEADER_ICONS_HEIGHT,
  },

  header: {
    flex: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerBanner: {
    flex: 1,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  headerLogoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    marginTop: Platform.OS === 'ios' ? 35 : 5,
    borderColor: Colors.whiteCorporate,
    backgroundColor: Colors.whiteCorporate,
  },
  headerLogo: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
    borderRadius: 50,
    borderColor: Colors.whiteCorporate,
    backgroundColor: Colors.whiteCorporate,
  },

  headerExchangeLogoContainer: {
    position: 'relative',
    top: 40,
    left: 0,
    width: 35,
    height: 35,
    borderRadius: 30,
    borderWidth: 3,
    borderColor: Colors.whiteCorporate,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  headerExchangeLogo: {
    width: 25,
    height: 25,
    resizeMode: 'contain',
  },

  headerTransparentBackground: {
    width: '100%',
    height: HEADER_ICONS_HEIGHT,
    position: 'absolute',
    top: 0,
    left: 0,
    // paddingTop: Platform.OS === 'ios' ? 30 : 0,
  },
  headerIconsAndText: {
    width: '100%',
    height: 60,
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerIconLeft: {
    width: 40,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerIconRight: {
    width: 40,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    width: sw - 100,
    fontFamily: 'appFontRegular',
    fontSize: 18,
    color: Colors.blueCorporate,
    textAlign: 'center',
    alignSelf: 'center',
  },

  shadowEffect: {
    marginBottom: 15,

    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },
});

export default HeaderCollapsible;
