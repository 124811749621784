import Colors from '@configs/colors';
import React, { useState } from 'react';
import { Pressable, StyleSheet, Text } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';

/**
 * Displays a button with:
 * - a text label or an icon label (if label starts with icon-)
 * - a selection mark if showIcon is true
 *
 *
 * @param isSelected
 * @param showIcon
 * @param choice
 * @param label: if starts with 'icon-' replace the label with the icon (e.g. icon-checkmark)
 * @param style: style appended to the Pressable
 * @param textStyle: style appended to the text label
 * @param onClose
 * @param onPress: receives the choice props and called on button press
 * @param onOk
 * @param disabled
 * @returns {JSX.Element}
 * @constructor
 */
export default function ButtonChoice({
  isSelected = false,
  showIcon = false,
  choice = 0,
  label = 'Bouton',
  disabled = false,
  style = {},
  textStyle = {},
  iconStyle = {},
  onClose = () => {},
  onPress = () => {},
  onOk = () => {},
}) {
  const [selected, setSelected] = useState(isSelected);

  return (
    <Pressable
      disabled={disabled}
      style={({ pressed }) => [styles.defaultButton, style, pressed ? { opacity: 0.7 } : {}]}
      onPress={() => {
        setSelected(!selected);
        onPress(choice);
      }}>
      {label.startsWith('icon-') ? (
        <Icon
          style={[{ color: disabled ? Colors.gray : Colors.black }, iconStyle]}
          name={label.replace('icon-', '')}
          size={25}
        />
      ) : (
        <Text style={[styles.buttonLabel, textStyle]}>{label}</Text>
      )}

      {showIcon && selected && <Icon name="checkmark" color={Colors.red} size={25} />}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  defaultButton: {
    width: 120,
    height: 48,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.whiteCorporate,
    borderRadius: 10,
    elevation: 3,
    overflow: 'visible',
    shadowColor: Colors.iconGray + '77',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    marginVertical: 10,
    marginHorizontal: 10,
    paddingHorizontal: 10,
  },
  buttonLabel: {
    fontFamily: 'appFontRegular',
    textAlign: 'center',
    color: Colors.blueCorporate,
  },
});
