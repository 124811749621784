import {
  ACTUALITY_COMMENT_TYPE,
  ACTUALITY_LIKE_TYPE,
  CONVOCATION_ANSWER_TYPE,
  DELETE_MESSAGE_TYPE,
  NEED_VALIDATION_DIARY,
  NEW_ACTUALITY_TYPE,
  NEW_CONVERSATION_TYPE,
  NEW_CONVOCATION_TYPE,
  NEW_EVENT_TYPE,
  NEW_MESSAGE_TYPE,
} from '@apis/notification';
import * as actions from '@redux/actions';
import { handleBadgeAllNotifications } from '@redux/actions';
import { DELETE_MESSAGE, ADD_NEW_MESSAGE_FROM_NOTIFICATION } from '@redux/reducers/ChatReducer';
import {
  NOTIFICATION_COMMENT_ACTUALITY,
  NOTIFICATION_LIKE_ACTUALITY,
  NOTIFICATION_NEW_ACTUALITY,
} from '@redux/reducers/NotificationsReducer';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';

const debug = false;

/**
 * Function to handle notification received in this application
 *
 * This function is the main entry point for the outside push notification.
 * Indeed, everything starts here!
 *
 * @param {Object} notifications
 * @param store
 */
export async function handleNotificationReceived(notifications, store) {
  const notification = await notifications.request.content;

  debug &&
    console.log(
      'handleNotificationReceived, I am:',
      Device.brand,
      Device.modelName,
      Device.deviceName,
      Device.osName,
      Device.osVersion
    );
  debug && console.log('handleNotificationReceived, notification:', notification);

  if (!notification) return;

  Notifications.getBadgeCountAsync().then((count) => {
    debug && console.log('handleNotificationReceived, current badge count:', count);
    Notifications.setBadgeCountAsync(count + 1);
  });

  store.dispatch(handleBadgeAllNotifications(notification.data.type, false));

  await handleNotification(notification, store);
}

/**
 * Function used to handle action with notification
 * @param {*} notifications
 * @param store
 */
export async function handleNotificationOpened(notifications, store) {
  const notification = await notifications.request.content;

  debug && console.log('handleNotificationOpened', notification);

  if (notification) {
    const globalState = store.getState();
    debug && console.log('handleNotificationOpened, current page:', globalState.app.currentPage);

    await Notifications.setBadgeCountAsync(0);

    if (!globalState.app.currentPage || globalState.app.currentPage === 'HomeStack') {
      await store.dispatch(actions.saveNotificationComeOutApplication(notification));
    }

    store.dispatch(handleBadgeAllNotifications(notification.data.type, true));

    handleNotification(notification, store);
  }
}

/**
 * Function to update the data store depending on the received notification
 * @param {Object} notification
 * @param store
 */
async function handleNotification(notification, store) {
  const globalState = store.getState();

  // message contained in the notification
  const messageType = parseInt(notification.data.type, 10);
  debug && console.log('handleNotification, notification type:', messageType);

  let notificationData = null;
  try {
    notificationData = JSON.parse(notification.data);
  } catch (e) {
    notificationData = notification.data;
  }
  debug && console.log('handleNotification, notification data:', notificationData);

  //
  // if (messageType === NEW_MESSAGE_TYPE) {
  //   debug && console.log('handleNotification1, message:', notificationData);
  // }
  //
  // if (messageType === NEW_CONVOCATION_TYPE) {
  //   debug && console.log('handleNotification2, message:', notificationData);
  // }
  //
  // if ([NEW_MESSAGE_TYPE, NEW_CONVOCATION_TYPE].includes(messageType)) {
  //   debug && console.log('handleNotification3, message:', notificationData);
  // }

  // Switch notification type
  if (NEW_ACTUALITY_TYPE === messageType) {
    debug && console.log('handleNotification, new actuality');
    store.dispatch({
      type: NOTIFICATION_NEW_ACTUALITY,
      actualityId: notificationData.targetId,
    });
  } else if (ACTUALITY_LIKE_TYPE === messageType) {
    debug && console.log('handleNotification, like an actuality');
    store.dispatch({
      type: NOTIFICATION_LIKE_ACTUALITY,
      actualityId: notificationData.targetId,
    });
  } else if (ACTUALITY_COMMENT_TYPE === messageType) {
    debug && console.log('handleNotification, comment an actuality');
    store.dispatch({
      type: NOTIFICATION_COMMENT_ACTUALITY,
      actualityId: notificationData.targetId,
    });
  } else if (
    [NEW_CONVERSATION_TYPE, NEW_MESSAGE_TYPE, NEW_CONVOCATION_TYPE, CONVOCATION_ANSWER_TYPE].includes(messageType)
  ) {
    const notifiedMessage = notificationData.messageData ? JSON.parse(notificationData.messageData) : null;
    if (notifiedMessage) {
      debug && console.log('handleNotification, message:', notifiedMessage);
      store.dispatch({
        type: ADD_NEW_MESSAGE_FROM_NOTIFICATION,
        message: { ...notifiedMessage, targetId: notificationData.targetId },
        notificationType: messageType,
      });
      debug && console.log('handleNotification, added a message:', notifiedMessage);
    }
  } else if (DELETE_MESSAGE_TYPE === messageType) {
    store.dispatch({
      type: DELETE_MESSAGE,
      messageRemoved: true,
      messageId: notificationData.targetId,
    });
    debug && console.log('handleNotification, deleted a message:', notificationData.targetId);
  } else if ([NEW_EVENT_TYPE, NEED_VALIDATION_DIARY].includes(messageType)) {
    // => Notification type agenda
    const storeAgenda = await globalState.agenda;

    const lengthAgendaFutur = storeAgenda.futureAgenda.length > 0 ? storeAgenda.futureAgenda.length : 10;
    const lengthPastAgenda = storeAgenda.pastAgenda.length > 0 ? storeAgenda.pastAgenda.length : 10;

    // const page
    actions.getAgenda(
      this.props.tokens.wsToken,
      this.state.currentClub.id,
      1,
      lengthAgendaFutur,
      true,
      [],
      [],
      (success, result) => {
        console.log('handleNotification, updated future agenda');
      }
    );

    actions.getAgenda(
      this.props.tokens.wsToken,
      this.state.currentClub.id,
      1,
      lengthPastAgenda,
      false,
      [],
      [],
      (success, result) => {
        console.log('handleNotification, updated future agenda');
      }
    );
  }
}
