import { getApiHost } from '@configs/host';
import Constants from 'expo-constants';
import * as ImageManipulator from 'expo-image-manipulator';

import { Request } from './Request';
import { URLs } from './apisConstants';
import createFormData from "@utils/formData";
import * as Application from "expo-application";

const debug = false;

export const TeamAPI = {
  getTeamsList: (wsToken, clubId, keyword = '', page = 1, limit = 10) => {
    if (!wsToken) {
      const url = getApiHost() + URLs.getOpenTeamList + '?clubId=' + clubId;
      return Request.get(url);
    }

    const url =
      getApiHost() +
      URLs.getTeamsList +
      '?wsToken=' +
      wsToken +
      '&clubId=' +
      clubId +
      '&keyword=' +
      keyword +
      '&page=' +
      page +
      '&limit=' +
      limit;
    return Request.get(url);
  },
  getTeamDetail: (wsToken, teamId) => {
    const url = getApiHost() + URLs.getTeamDetail + '?wsToken=' + wsToken + '&teamId=' + teamId;

    return Request.get(url);
  },
  addFavoriteTeam: (wsToken, teamId) => {
    const url = getApiHost() + URLs.addFavoriteTeam;
    const data = {
      wsToken,
      teamId,
    };
    return Request.post(url, data);
  },
  removeFavoriteTeam: (wsToken, teamId) => {
    const url = getApiHost() + URLs.removeFavoriteTeam + '?wsToken=' + wsToken + '&teamId=' + teamId;
    const data = {
      wsToken,
      teamId,
    };

    return Request.post(url, data);
  },

  confirmUserTeam(wsToken, userId, teamId, Confirmed) {
    const confirmed = Confirmed ? '1' : '0';
    const url =
      getApiHost() +
      URLs.confirmUserTeam +
      '?wsToken=' +
      wsToken +
      '&userId=' +
      userId +
      '&teamId=' +
      teamId +
      '&confirmed=' +
      confirmed;
    const data = {
      wsToken,
      userId,
      teamId,
      confirmed,
    };
    return Request.post(url, data);
  },

  editTeam(wsToken, action, teamId, userIds, teamName = null, teamPhoto = null) {
    let url =
      getApiHost() +
      URLs.editTeam +
      '?wsToken=' +
      wsToken +
      '&action=' +
      action +
      '&teamId=' +
      teamId +
      '&userIds=' +
      userIds;

    const data = {
      wsToken,
      action,
      teamId,
      userIds,
    };

    if (action === 'update') {
      if (teamName) {
        data.name = teamName;
        url += '&name=' + teamName;
      }
      if (teamPhoto) {
        data.photoName = teamPhoto;
        url += '&photoName=' + teamPhoto;
      }
    }

    return Request.post(url, data);
  },

  async uploadTeamBanner(file, wsToken, apiConfiguration) {
    if (file.width > 1920 || file.height > 1024) {
      file = await ImageManipulator.manipulateAsync(file.localUri || file.uri, [{ resize: { width: 1920 } }], {
        compress: 0.5,
        format: ImageManipulator.SaveFormat.JPEG,
      });
    }

    if (Constants.expoConfig.extra.mediaS3 ?? false) {
      debug && console.debug('uploadTeamBanner, API configuration:', apiConfiguration);

      const body = createFormData(file, wsToken, 'file');
      debug && console.debug('uploadTeamBanner, API configuration:', body);
      const objectLocation = apiConfiguration?.imagesLocations ? apiConfiguration.imagesLocations?.teams.banners : '';
      debug && console.debug('uploadTeamBanner, object location:', objectLocation);
      try {
        const objectName = body.get('file_name') ?? 'unknown';
        debug && console.debug('uploadTeamBanner, object name:', objectName);
      } catch (e) {
        console.warn('Exception', e);
        const objectName = body._parts[0].get('file');
        debug && console.debug('uploadTeamBanner, object name:', objectName);
      }
    } else {
      const body = createFormData(file, wsToken, 'file_data');

      return await fetch(getApiHost() + URLs.uploadTeamBanner, {
        method: 'POST',
        body,
        headers: {
          // 'Content-Type': 'multipart/form-data',
          // 'boundary': Math.random().toString().substr(2),
          'X-User-Agent': `${Constants.expoConfig.slug}/${Constants.expoConfig.version}/${Application.nativeBuildVersion}`,
        },
      })
        .then((response) => response.json())
        .catch((error) => {
          console.warn('uploadTeamBanner, error', error);
        });
    }
  },
};
