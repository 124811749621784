import AlerteUI from '@components/AlerteUI/AlerteUI';
import ButtonCancel from '@components/ButtonCancel/ButtonCancel';
import ButtonValidate from '@components/ButtonValidate/ButtonValidate';
import DateTimePicker from '@components/DateTimePicker/DateTimePicker';
import GradientBackground from '@components/GradientBackground/GradientBackground';
import Colors from '@configs/colors';
import { ACCOUNT_CREATION_SET_NEW_USER } from '@redux/storeReducer';
import I18n from '@utils/i18n';
import { Validators } from '@utils/validators';
import moment from 'moment';
import React, { Component } from 'react';
import { Keyboard, KeyboardAvoidingView, Platform, Pressable, TextInput, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { connect } from 'react-redux';

import styles from './styles';

class CA1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAlert: false,
      alertTitle: '',
      alertMessage: '',
      alertButtons: null,

      firstName: '',
      lastName: '',
      email: '',
      password: '',
      birthDate: null,
      valueBirthdate: moment().format('YYYY-MM-DD'),

      isFirstNameValid: false,
      isLastNameValid: false,
      isBirthDateValid: false,
      isEmailValid: false,
      isPasswordValid: false,

      showDatePicker: false,
      passwordSecure: true,
    };
  }

  onChangeFirstName = (firstName) => {
    const value = firstName.trim();
    this.setState({ firstName: value, isFirstNameValid: Validators.validName(value) });
  };

  onChangeLastName = (lastName) => {
    const value = lastName.trim();
    this.setState({ lastName: value, isLastNameValid: Validators.validName(value) });
  };

  onChangeEmail = (email) => {
    const value = email.trim();
    this.setState({ email: value, isEmailValid: Validators.validEmail(value) });
  };

  onChangePassword = (password) => {
    const value = password.trim();
    this.setState({ password: value, isPasswordValid: Validators.validPassword(value) });
  };

  nextStep = async () => {
    this.setState({ loading: true });

    if (!this.state.isFirstNameValid) {
      this.setState({
        loading: false,
        alertTitle: I18n.t('app.oups'),
        alertMessage: I18n.t('app.invalidFirstname'),
        alertButtons: [],
        showAlert: true,
      });
      return;
    }
    if (!this.state.isLastNameValid) {
      this.setState({
        loading: false,
        alertTitle: I18n.t('app.oups'),
        alertMessage: I18n.t('app.invalidLastname'),
        alertButtons: [],
        showAlert: true,
      });
      return;
    }
    if (!this.state.isBirthDateValid) {
      this.setState({
        loading: false,
        alertTitle: I18n.t('app.oups'),
        alertMessage: I18n.t('app.invalidBirthDate'),
        alertButtons: [],
        showAlert: true,
      });
      return;
    }
    if (!this.state.isEmailValid) {
      this.setState({
        loading: false,
        alertTitle: I18n.t('app.oups'),
        alertMessage: I18n.t('app.invalidEmailAddress'),
        alertButtons: [],
        showAlert: true,
      });
      return;
    }
    if (!this.state.isPasswordValid) {
      this.setState({
        loading: false,
        alertTitle: I18n.t('app.oups'),
        alertMessage: I18n.t('app.invalidPassword'),
        alertButtons: [],
        showAlert: true,
      });
      return;
    }

    // All valid fields, go to next page...
    this.props.dispatch({
      type: ACCOUNT_CREATION_SET_NEW_USER,
      value: {
        firstname: this.state.firstName,
        lastname: this.state.lastName,
        birthdate: moment(this.state.birthDate).format('DD/MM/YYYY'),
        email: this.state.email,
        password: this.state.password,
        clubReference: '',
        licenseNumber: '',
        language: this.props.languageCode,
        profileTypes: [],
      },
    });
    this.props.navigation.navigate('SignUpNext');
  };

  render() {
    return (
      <>
        <GradientBackground>
          <KeyboardAvoidingView
            style={[styles.rootContainer, Platform.OS === 'android' ? { paddingTop: 160 } : { paddingTop: 250 }]}
            behavior={Platform.OS === 'ios' ? 'padding' : 'position'}>
            <View style={[styles.inputContainer, styles.shadowEffect]}>
              <TextInput
                // autoFocus
                inputMode="text"
                style={[
                  styles.textInput,
                  { color: this.state.isFirstNameValid ? Colors.blueCorporate : Colors.redCorporate },
                ]}
                value={this.state.firstName}
                placeholder={I18n.t('account.firstName')}
                onChangeText={(firstName) => {
                  this.onChangeFirstName(firstName);
                }}
              />

              <TextInput
                inputMode="text"
                style={[
                  styles.textInput,
                  { color: this.state.isLastNameValid ? Colors.blueCorporate : Colors.redCorporate },
                ]}
                value={this.state.lastName}
                placeholder={I18n.t('account.lastName')}
                onChangeText={(lastName) => {
                  this.onChangeLastName(lastName);
                }}
              />

              {Platform.OS !== 'web' ? (
                <>
                  <Pressable
                    onPress={() => {
                      Keyboard.dismiss();
                      this.setState({ showDatePicker: true });
                    }}>
                    <View pointerEvents="none">
                      <TextInput
                        inputMode="text"
                        editable={false}
                        style={[
                          styles.textInput,
                          { color: this.state.isBirthDateValid ? Colors.blueCorporate : Colors.redCorporate },
                        ]}
                        value={
                          this.state.birthDate
                            ? moment(this.state.birthDate).format('Do MMMM YYYY')
                            : 'Date de naissance'
                        }
                        placeholder={I18n.t('account.birthDate')}
                      />
                    </View>
                  </Pressable>

                  <DateTimePicker
                    visible={this.state.showDatePicker}
                    value={this.state.birthDate ?? new Date()}
                    mode="date"
                    future={false}
                    onClose={() => {
                      this.setState({ showDatePicker: false });
                    }}
                    onChange={(selectedDate) => {
                      const birthDate = moment(selectedDate);
                      this.setState({
                        birthDate: selectedDate,
                        valueBirthdate: birthDate.format('YYYY-MM-DD'),
                        isBirthDateValid: Validators.validBirthdate(birthDate.year()),
                      });
                    }}
                  />
                </>
              ) : (
                <input
                  type="date"
                  style={styles.textInputDate}
                  value={this.state.valueBirthdate}
                  max={moment().format('YYYY-MM-DD')}
                  onInput={(event) => {
                    const birthDate = moment(event.target.value);
                    this.setState({
                      birthDate,
                      valueBirthdate: birthDate.format('YYYY-MM-DD'),
                      isBirthDateValid: Validators.validBirthdate(birthDate.year()),
                    });
                  }}
                />
              )}

              <TextInput
                inputMode="email"
                style={[
                  styles.textInput,
                  { color: this.state.isEmailValid ? Colors.blueCorporate : Colors.redCorporate },
                ]}
                value={this.state.email}
                placeholder={I18n.t('account.email')}
                onChangeText={(email) => {
                  this.onChangeEmail(email);
                }}
                keyboardType="email-address"
                autoCapitalize="none"
                autoComplete="email"
                textContentType="emailAddress"
                autoCorrect={false}
              />

              <View
                style={[
                  { margin: 0, padding: 0 },
                  { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
                ]}>
                <TextInput
                  ref={(ref) => {
                    this.passwordInput = ref;
                  }}
                  style={[
                    styles.textInput,
                    { color: this.state.isPasswordValid ? Colors.blueCorporate : Colors.redCorporate, width: '85%' },
                  ]}
                  value={this.state.password}
                  placeholder={I18n.t('account.password')}
                  onChangeText={(password) => {
                    this.onChangePassword(password);
                  }}
                  secureTextEntry={this.state.passwordSecure}
                  autoCapitalize="none"
                  autoComplete="password"
                  textContentType="password"
                  autoCorrect={false}
                />

                <Pressable
                  style={{ padding: 5, alignItems: 'center', justifyContent: 'center' }}
                  onPress={() => {
                    this.setState({
                      passwordSecure: !this.state.passwordSecure,
                    });
                  }}>
                  <Icon
                    name="md-eye"
                    size={24}
                    color={Colors.gray}
                    style={{
                      position: 'relative',
                      top: 0.5,
                      left: '50%',
                      transform: [{ translateX: Platform.OS === 'ios' ? -6 : -2 }],
                    }}
                  />
                  {!this.state.passwordSecure && (
                    <Icon
                      name="md-close"
                      size={32}
                      color={Colors.red}
                      style={{
                        position: 'absolute',
                        top: 0.5,
                        left: '50%',
                        transform: [{ translateX: Platform.OS === 'android' ? -2 : -6 }],
                      }}
                    />
                  )}
                </Pressable>
              </View>
            </View>

            <View style={styles.buttonsContainer}>
              <ButtonCancel style={styles.cancelButton} onPress={() => this.props.navigation.goBack()} />
              <ButtonValidate
                style={{ marginLeft: 10 }}
                label={I18n.t('createAccount.nextStep')}
                onPress={() => this.nextStep()}
              />
            </View>
          </KeyboardAvoidingView>
        </GradientBackground>

        <AlerteUI
          showAlert={this.state.showAlert}
          onClose={() => {
            this.setState({ showAlert: false });
          }}
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          buttons={this.state.alertButtons}
        />
      </>
    );
  }
}

export default connect()(CA1);
