import Colors from '@configs/colors';
import { Dimensions, StyleSheet } from 'react-native';

const { width: sw } = Dimensions.get('window');

const styles = StyleSheet.create({
  mainContainer: {
    marginHorizontal: 10,
    marginTop: 0,
    paddingTop: 0,
    paddingRight: 0,
    minHeight: 100,
  },
  appFontRegular: {
    fontFamily: 'appFontRegular',
  },

  // Left and right parts of a diary
  diaryMain: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minHeight: 90,
  },

  diaryLabel: {
    width: sw - 100,
    maxWidth: sw / 2,
    height: 30,
    borderBottomStartRadius: 100,
    borderBottomEndRadius: 100,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  // Left part - training / event / convocation...
  diaryTitle: {
    width: '100%',
    padding: 10,
    fontFamily: 'appFontRegular',
    fontWeight: 'bold',
    fontSize: 18,
    color: Colors.blueCorporate,
    lineHeight: 20,
  },
  diaryDescription: {
    width: '98%',
    marginLeft: 5,
    padding: 5,
    fontFamily: 'appFontRegular',
    fontSize: 14,
    color: Colors.blueCorporate,
    lineHeight: 21,
    backgroundColor: Colors.grayWhite,
    borderRadius: 10,
    alignSelf: 'baseline',
    height: 70,
  },

  // Left part - score...
  scoreBody: {
    width: sw - 30,

    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',

    padding: 10,
    paddingTop: 0,
    borderRadius: 10,
  },
  scoreColumn: {
    width: ((sw - 30) / 5) * 2,
    flexDirection: 'column',
    alignItems: 'center',
  },
  scoreTeamName: {
    textAlign: 'center',
    fontSize: 12,
    color: Colors.blueCorporate,
    opacity: 0.5,
    fontFamily: 'appFontMedium',
    fontWeight: 'bold',
    marginBottom: 5,
  },
  scoreClubName: {
    textAlign: 'center',
    fontSize: 12,
    color: Colors.blueCorporate,
    opacity: 0.5,
    fontFamily: 'appFontRegular',
    marginTop: 5,
  },

  clubTeam: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
    height: 35,
  },
  clubName: {
    width: sw - 80,
    maxWidth: '60%',
    fontFamily: 'appFontRegular',
    color: Colors.blueCorporate,
    fontSize: 12,
    marginLeft: 10,
  },
  teamName: {
    width: sw - 80,
    maxWidth: '60%',
    fontFamily: 'appFontRegular',
    color: Colors.red,
    fontSize: 11,
    marginLeft: 10,
  },

  diaryEventFormattedDate: {
    fontFamily: 'appFontMedium',
    fontSize: 12,
    color: Colors.whiteCorporate,
  },
  diaryEventDate: {
    marginTop: 5,
    fontFamily: 'appFontMedium',
    fontSize: 12,
    color: Colors.whiteCorporate,
  },

  presenceReplyContainer: {
    width: sw - 40,
    marginVertical: 5,
    marginHorizontal: 0,
    padding: 5,
  },
  replyButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: 0,
  },
  replyButton: {
    width: 120,
    height: 25,
  },
  replyButtonLabel: {
    fontSize: 12,
    color: Colors.iconGray,
  },
});

export default styles;
