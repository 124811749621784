import { AccountAPI } from '@apis/account';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import ButtonCancel from '@components/ButtonCancel/ButtonCancel';
import ButtonValidate from '@components/ButtonValidate/ButtonValidate';
import GradientBackground from '@components/GradientBackground/GradientBackground';
import Loading from '@components/Loading/Loading';
import Colors from '@configs/colors';
import I18n from '@utils/i18n';
import { Validators } from '@utils/validators';
import md5 from 'md5';
import React, { Component } from 'react';
import {
  Dimensions,
  KeyboardAvoidingView,
  Modal,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { connect } from 'react-redux';

const { width: sw, height: sh } = Dimensions.get('window');

class EditPassword extends Component {
  state = {
    showAlert: false,
    alertTitle: '',
    alertMessage: '',
    alertButtons: null,

    oldPassword: '',
    isOldPasswordValid: false,

    newPassword: '',
    isNewPasswordValid: false,

    confirmNewPassword: '',
    isConfirmNewPasswordValid: false,

    loading: false,
  };

  _clickSavePassword = async () => {
    this.setState({ loading: true });
    if (!this.state.isOldPasswordValid || !this.state.isNewPasswordValid || !this.state.isConfirmNewPasswordValid) {
      this.setState({
        loading: false,
        alertTitle: I18n.t('app.oups'),
        alertMessage: 'Vérifiez les données que vous avez saisies. Certaines sont invalides.',
        alertButtons: [],
        showAlert: true,
      });

      return;
    }

    if (this.state.newPassword !== this.state.confirmNewPassword) {
      this.setState({
        loading: false,
        alertTitle: I18n.t('app.oups'),
        alertMessage: 'Le nouveau mot de passe et sa confirmation ne sont pas identiques !',
        alertButtons: [],
        showAlert: true,
      });

      return;
    }

    AccountAPI.editPassword(this.props.tokens.wsToken, md5(this.state.oldPassword), md5(this.state.newPassword)).then(
      (response) => {
        if (response && response.success) {
          this.props.onUpdatedPassword && this.props.onUpdatedPassword(this.state.newPassword);

          this.setState({
            loading: false,
            alertTitle: I18n.t('app.information'),
            alertMessage: I18n.t('app.successfulPWChanged'),
            alertButtons: [
              {
                text: I18n.t('myAccount.yes'),
                onPress: () => {
                  this.props.onClose();
                },
              },
            ],
            showAlert: true,
          });
        } else {
          this.setState({
            loading: false,
            alertTitle: I18n.t('app.oups'),
            alertMessage: response.error,
            alertButtons: [],
            showAlert: true,
          });
        }
      }
    );
  };

  render() {
    if (!this.props.visible) {
      return <></>;
    }
    return (
      <>
        <Modal visible={this.props.visible} transparent>
          <GradientBackground>
            <View style={styles.rootContainer}>
              <KeyboardAvoidingView
                behavior={Platform.OS === 'ios' ? 'position' : 'position'}
                contentContainerStyle={{ marginTop: Platform.OS === 'web' ? 300 : sh <= 692 ? 0 : 0 }}
                keyboardVerticalOffset={Platform.OS === 'ios' ? 50 : 80}>
                <View style={[styles.inputContainer, styles.shadowEffect]}>
                  <Text style={styles.labelPassword}>{I18n.t('myAccount.password')}</Text>

                  <Text style={styles.textInfos}>{I18n.t('myAccount.oldPassword')}</Text>
                  <TextInput
                    secureTextEntry
                    autoCapitalize="none"
                    autoComplete="password"
                    textContentType="password"
                    style={[
                      styles.textInput,
                      styles.appFontRegular,
                      {
                        color: this.state.isOldPasswordValid ? Colors.blueCorporate : Colors.whiteCorporate,
                        backgroundColor: this.state.isOldPasswordValid
                          ? Colors.whiteCorporate
                          : Colors.redCorporate + 'cc',
                      },
                    ]}
                    value={this.state.oldPassword}
                    placeholder={I18n.t('myAccount.oldPassword')}
                    onChangeText={(text) => {
                      this.setState({ oldPassword: text, isOldPasswordValid: Validators.validPassword(text) });
                    }}
                  />

                  <Text style={styles.labelEdit}>{I18n.t('myAccount.newPassword')}</Text>
                  <TextInput
                    secureTextEntry
                    textContentType="password"
                    autoCapitalize="none"
                    style={[
                      styles.textInput,
                      styles.appFontRegular,
                      {
                        color: this.state.isNewPasswordValid ? Colors.blueCorporate : Colors.whiteCorporate,
                        backgroundColor: this.state.isNewPasswordValid
                          ? Colors.whiteCorporate
                          : Colors.redCorporate + 'cc',
                      },
                    ]}
                    placeholder={I18n.t('myAccount.newPassword')}
                    value={this.state.newPassword}
                    onChangeText={(text) => {
                      this.setState({
                        newPassword: text,
                        isNewPasswordValid: Validators.validPassword(text),
                      });
                    }}
                  />

                  <Text style={styles.labelEdit}>{I18n.t('myAccount.confirmNewPassword')}</Text>
                  <TextInput
                    secureTextEntry
                    textContentType="password"
                    autoCapitalize="none"
                    style={[
                      styles.textInput,
                      styles.appFontRegular,
                      {
                        color: this.state.isConfirmNewPasswordValid ? Colors.blueCorporate : Colors.whiteCorporate,
                        backgroundColor: this.state.isConfirmNewPasswordValid
                          ? Colors.whiteCorporate
                          : Colors.redCorporate + 'cc',
                      },
                    ]}
                    placeholder={I18n.t('myAccount.confirmNewPassword')}
                    value={this.state.confirmNewPassword}
                    onChangeText={(text) => {
                      this.setState({
                        confirmNewPassword: text,
                        isConfirmNewPasswordValid: Validators.validPassword(text),
                      });
                    }}
                  />
                </View>

                <View style={[styles.buttonsContainer]}>
                  <ButtonCancel onPress={() => this.props.onClose()} />
                  <ButtonValidate label={I18n.t('myAccount.save')} onPress={() => this._clickSavePassword()} />
                </View>
              </KeyboardAvoidingView>

              <Pressable
                style={styles.buttomBack}
                onPress={() => {
                  this.props.goBack();
                }}>
                <Icon name="md-arrow-back" size={25} color="#fff" />
              </Pressable>
            </View>
          </GradientBackground>

          <Loading
            loading={this.state.loading}
            requestCloseLoading={() => {
              this.setState({ loading: false });
            }}
          />

          <AlerteUI
            showAlert={this.state.showAlert}
            onClose={() => {
              this.setState({ showAlert: false });
            }}
            title={this.state.alertTitle}
            message={this.state.alertMessage}
            buttons={this.state.alertButtons}
          />
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  const { tokens } = state.app;
  return {
    tokens,
  };
};

const styles = StyleSheet.create({
  rootContainer: {
    marginTop: sh / 3,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  inputContainer: {
    justifyContent: 'center',
    marginVertical: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    backgroundColor: Colors.blueCorporate,
  },
  shadowEffect: {
    marginBottom: 15,

    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },

  panelParams: {
    marginTop: 120,
    justifyContent: 'center',
    marginVertical: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    backgroundColor: Colors.blueCorporate,
  },
  labelPassword: {
    fontFamily: 'appFontMedium',
    textAlign: 'center',
    fontSize: 18,
    marginBottom: sh * 0.023,
    color: Colors.whiteCorporate,
  },
  textInfos: {
    // width: '100%',
    color: Colors.white + '99',
    fontFamily: 'appFontRegular',
  },
  textInput: {
    width: (sw / 4) * 3,
    maxWidth: 600,
    backgroundColor: Colors.whiteCorporate,
    borderRadius: 1000,
    height: 48,
    paddingLeft: 15,
    paddingRight: 15,
    marginVertical: 10,
  },
  textInputInfos: {
    width: sw - 40,
    backgroundColor: Colors.whiteCorporate,
    height: sh * 0.05,
    borderRadius: (sh * 0.05) / 2,
    paddingHorizontal: sw * 0.045,
    marginBottom: sh * 0.023,
    // fontFamily: "appFontRegular",
    color: Colors.black,
  },
  labelEdit: {
    color: Colors.white + '99',
    fontFamily: 'appFontRegular',
  },

  buttonsContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: sw / 1.2,
    marginVertical: 5,
    paddingHorizontal: 20,
    borderRadius: 10,
    // backgroundColor: Colors.blueSky,
  },
});

export default connect(mapStateToProps)(EditPassword);
