import Constants from 'expo-constants';

export function getApiHost() {
  // g_apiUrl is defined in the index.html file for the Web version
  try {
    // eslint-disable-next-line no-undef
    if (g_apiUrl !== undefined && g_apiUrl !== '__APP_API_URL__') {
      // eslint-disable-next-line no-undef
      return g_apiUrl;
    }
    // eslint-disable-next-line no-unused-vars
  } catch (error) {
    // console.debug('g_apiUrl is not defined.', error);
  }
  return `${Constants.expoConfig.extra.apiUrl}/`;
}

export function getMediaUrl() {
  // g_mediaUrl is defined in the index.html file for the Web version
  try {
    // eslint-disable-next-line no-undef
    if (g_mediaUrl !== undefined && g_mediaUrl !== '__APP_MEDIA_URL__') {
      // eslint-disable-next-line no-undef
      return g_mediaUrl;
    }
    // eslint-disable-next-line no-unused-vars
  } catch (error) {
    // console.debug('g_mediaUrl is not defined.', error);
  }
  return `${Constants.expoConfig.extra.mediaUrl}/`;
}

export const getImageApiHostOrUrl = (urlOrFilename) => {
  if (Request.legacy) {
    if (!urlOrFilename.startsWith('http')) {
      return `${getApiHost()}${urlOrFilename}`;
    }
    return urlOrFilename;
  }

  // Specific case for local images
  if (urlOrFilename && (urlOrFilename.startsWith('data:image') || urlOrFilename.startsWith('file:'))) {
    return urlOrFilename;
  }

  // Specific case to prepare S3 uploads
  if (urlOrFilename && urlOrFilename.startsWith('uploads')) {
    const newUrl = urlOrFilename.replace('uploads', '');
    return `${getMediaUrl()}${newUrl}`;
  }

  if (urlOrFilename && !urlOrFilename.startsWith('http')) {
    return `${getMediaUrl()}${urlOrFilename}`;
  }

  return urlOrFilename;
};
