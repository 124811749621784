import Colors from '@configs/colors';
import { Dimensions, Platform, StyleSheet } from 'react-native';

const { width: sw, height: sh } = Dimensions.get('window');

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.gray,
  },
  appFontRegular: {
    fontFamily: 'appFontRegular',
    paddingTop: Platform.OS === 'ios' ? 3 : 0,
  },
  whiteText: {
    color: Colors.whiteCorporate,
  },
  redText: {
    color: Colors.red,
  },
  blueText: {
    color: Colors.blueCorporate + 'AA',
  },
  formContainer: {
    padding: 20,
    paddingBottom: 10,
    alignItems: 'center',
  },
  pickerEvents: {
    // flex: 1,
    color: Colors.blueCorporate,
    fontSize: 16,
    // height: 50,
    width: sw - 200,
    minWidth: 120,
    marginVertical: 10,
    // transform: [
    //   { scaleX: 1 },
    //   { scaleY: 1 },
    // ]
  },
  selectionButton: {
    width: (sw - 40) / 2,
    height: 40,
    marginVertical: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.whiteCorporate,
    borderRadius: 10,
    elevation: 3,
    overflow: 'visible',
    shadowColor: Colors.iconGray + '77',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.8,
    shadowRadius: 2,
  },
  textInput: {
    width: sw - 40,
    borderRadius: 10,
    paddingHorizontal: 15,
    marginVertical: 5,
    backgroundColor: Colors.whiteCorporate,
    height: 32,
    color: Colors.blueCorporate,
    fontFamily: 'appFontRegular',
  },
  textInputClub: {
    width: sw - 40,
    borderRadius: 10,
    paddingHorizontal: 15,
    marginVertical: 5,
    backgroundColor: Colors.whiteCorporate,
    height: 32,
    color: Colors.blueCorporate,
    fontFamily: 'appFontRegular',
  },
  textInputMultiline: {
    width: sw - 40,
    borderRadius: 10,
    paddingHorizontal: 15,
    marginHorizontal: 20,
    marginVertical: 5,
    backgroundColor: Colors.whiteCorporate,
    color: Colors.blueCorporate,
    fontFamily: 'appFontRegular',
    height: 120,
    justifyContent: 'flex-start',
    textAlignVertical: 'top',
    paddingTop: 10,
    paddingBottom: 10,
    lineHeight: 16,
  },
  dateTime: {
    width: sw - 40,
    height: 32,
    backgroundColor: Colors.whiteCorporate,
    borderRadius: 10,
    paddingHorizontal: 15,
    marginVertical: 5,
    justifyContent: 'center',
  },

  replyButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 10,
  },
  replyButton: {
    width: 70,
    height: 25,
    marginHorizontal: 5,
  },
  replyButtonSelected: {
    backgroundColor: Colors.blueCorporate,
  },
  replyButtonUnselected: {
    backgroundColor: Colors.whiteCorporate,
  },
  replyButtonLabel: {
    fontSize: 12,
    color: Colors.iconGray,
  },

  alignLeftContent: {
    width: '100%',
    flexDirection: 'row',
  },
  photoCtn: {
    width: (sw - 60) / 3 + 40,
    // height: (sh - 60) / 4,
    aspectRatio: 1,
    backgroundColor: Colors.whiteCorporate,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 7,
    overflow: 'hidden',
    padding: 5,
    marginRight: 10,
  },
  responseButton: {
    width: 60,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.whiteCorporate,
    elevation: 2,
    marginLeft: 10,
    borderRadius: 50,
  },

  inputCtn: {
    width: sw,
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputCtnButton: {
    width: sw,
    flexDirection: 'row',
    paddingHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  inputClubName: {
    width: ((sw - 40) / 10) * 8 - 20,
    backgroundColor: Colors.whiteCorporate,
    borderRadius: 50,
    height: 32,
    marginLeft: 10,
    marginRight: 0,
    paddingHorizontal: 10,
    color: Colors.blueCorporate,
    fontFamily: 'appFontRegular',
    marginVertical: 10,
  },
  inputScore: {
    width: ((sw - 40) / 10) * 2,
    backgroundColor: Colors.whiteCorporate,
    borderRadius: 50,
    height: 32,
    paddingHorizontal: 10,
    textAlign: 'center',
    fontSize: 17,
  },

  inputComment: {
    width: sw - 40,
    backgroundColor: Colors.whiteCorporate,
    borderRadius: 10,
    height: 150,
    padding: 10,
    textAlignVertical: 'top',
    alignSelf: 'center',
  },

  choiceButton: {
    width: (sw - 80) / 2,
    backgroundColor: Colors.whiteCorporate,
    height: 32,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  activeTimeButton: {
    backgroundColor: Colors.blueCorporate,
  },
  inActiveTimeButton: {
    borderWidth: 1,
    borderColor: Colors.blueCorporate,
  },
  buttonText: {
    color: Colors.blueCorporate,
    fontFamily: 'appFontRegular',
  },
  activeTextButton: {
    color: Colors.whiteCorporate,
  },
  inActiveTextButton: {
    color: Colors.blueCorporate,
  },

  autoCompleteBlock: {
    marginHorizontal: 5,
    width: sw - 20,
    maxHeight: 240,
    marginTop: 0,
    overflow: 'hidden',
  },
  shadowEffect: {
    marginBottom: 15,

    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },
  autoCompleteHeader: {
    // alignSelf: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  autoCompleteTitle: {
    fontFamily: 'appFontRegular',
    fontSize: 16,
    // color: Colors.blueCorporate,
    alignSelf: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  autoCompleteIcon: {
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
