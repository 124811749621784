import { ACT_ACTUALITY, ACT_EVENT, ACT_SCORE, NewsAPI } from '@apis/news';
import { TeamAPI } from '@apis/team';
import Images from '@assets/images';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import ButtonValidate from '@components/ButtonValidate/ButtonValidate';
import ChoosePlace from '@components/ChoosePlace/ChoosePlace';
import DateTimePicker from '@components/DateTimePicker/DateTimePicker';
import HeaderBanner from '@components/HeaderBanner/HeaderBanner';
import Loading from '@components/Loading/Loading';
import MediaZoomViewer from '@components/MediaZoomViewer/MediaZoomViewer';
import SelectActualityType from '@components/SelectActualityType/SelectActualityType';
import SelectRecipientsType from '@components/SelectRecipientsType/SelectRecipientsType';
import ShadowCard from '@components/ShadowCard/ShadowCard';
import TakePhoto from '@components/TakePhoto/TakePhoto';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import { Picker } from '@react-native-picker/picker';
import { getNews } from '@redux/actions';
import alert from '@utils/alert';
import { checkIsModeratorOrAdmin } from '@utils/checkUserRoles';
import I18n from '@utils/i18n';
import moment from 'moment';
import React, { Component } from 'react';
import { Dimensions, Image, Platform, Pressable, ScrollView, Text, TextInput, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/Ionicons';
import { connect } from 'react-redux';

import styles from './styles';

const { width: sw } = Dimensions.get('window');

// // Actuality type:
// //     public const ACTUALITY_ID = 1;
// //     public const SCORE_ID     = 2;
// //     public const EVENT_ID     = 3;
// const ACT_ACTUALITY = Number('1');
// const ACT_SCORE = Number('2');
// const ACT_EVENT = Number('3');

class CreateActuality extends Component {
  debug = true;

  // mode: add or edit
  mode = '';
  itemToEdit = null;
  itemToEditIndex = -1;

  page = 1;
  limit = 10;

  constructor(props) {
    super(props);

    this.mode = props.route.params.mode ?? 'add';
    this.itemToEdit = props.route.params.item ?? null;
    this.itemToEditIndex = props.route.params.index ?? -1;

    this.debug && console.debug('CreateActuality constructor, mode:', this.mode, this.itemToEdit);

    // const itemToEdit = props.itemToEdit;
    const actuTypeId = (this.itemToEdit && this.itemToEdit.ActualityType_id) ?? 1;
    const currentClub = this.props.affiliatedClubInformation ?? this.props.session.club ?? null;

    this.state = {
      currentClub,

      loading: false,
      hasPrivilegeAccess: checkIsModeratorOrAdmin(currentClub),

      showAlert: false,
      alertTitle: '',
      alertMessage: '',
      alertButtons: null,

      selectedActualityType: props.constActuTypes[parseInt(actuTypeId, 10) - 1],

      recipient:
        this.mode === 'edit' && this.itemToEdit.ActualityRecipientType_id !== null
          ? props.constRecipientsTypes[parseInt(this.itemToEdit.ActualityRecipientType_id, 10) - 1]
          : props.session !== null && checkIsModeratorOrAdmin(currentClub)
          ? { id: 1, name: 'Club' }
          : { id: 1, name: 'Club' },

      title: this.mode === 'edit' ? this.itemToEdit.title : '',
      description: this.mode === 'edit' ? this.itemToEdit.description : '',

      latitude: this.mode === 'edit' ? this.itemToEdit.latitude : '',
      longitude: this.mode === 'edit' ? this.itemToEdit.longitude : '',
      address: this.mode === 'edit' ? this.itemToEdit.address : '',

      photos: this.mode === 'edit' ? this.itemToEdit.images : [],

      askReply: this.mode === 'edit' ? this.itemToEdit.askReply : 1,

      // Score
      homeClubId: this.mode === 'edit' ? this.itemToEdit.HomeClub_id : '',
      homeClubName: this.mode === 'edit' ? this.itemToEdit.selectedHomeClubName : '',
      selectedHomeClub: null,
      homeClubKeyword: this.mode === 'edit' ? this.itemToEdit.hClubName : '',
      homeTeamId: this.mode === 'edit' ? this.itemToEdit.homeTeam_Id : '',
      homeTeamName: this.mode === 'edit' ? this.itemToEdit.selectedHomeTeamName : '',

      awayClubId: this.mode === 'edit' ? this.itemToEdit.AwayClub_id : '',
      awayClubName: this.mode === 'edit' ? this.itemToEdit.selectedAwayClubName : '',
      selectedAwayClub: null,
      awayClubKeyword: this.mode === 'edit' ? this.itemToEdit.aClubName : '',
      awayTeamId: this.mode === 'edit' ? this.itemToEdit.awayTeam_Id : '',
      awayTeamName: this.mode === 'edit' ? this.itemToEdit.selectedAwayTeamName : '',

      selectedHomeTeam: null,
      selectedAwayTeam: null,

      homeTeamScore: this.mode === 'edit' ? this.itemToEdit.homeTeamScore : '',
      awayTeamScore: this.mode === 'edit' ? this.itemToEdit.awayTeamScore : '',
      isHalfTime: this.mode === 'edit' ? Number(this.itemToEdit.isHalfTime) : 0,
      scoreComment: this.mode === 'edit' ? this.itemToEdit.description : '',

      showActuTypes: false,
      showRecipients: false,
      showTakePhoto: false,
      showChoosePlace: false,

      // Date / time
      date: this.mode === 'edit' ? this.itemToEdit.actualityDate : moment().format('L'),
      time: this.mode === 'edit' ? this.itemToEdit.time : moment().format('LT'),

      showDatePicker: false,
      selectedDate:
        this.mode === 'edit'
          ? this.itemToEdit.actualityDate
            ? moment(this.itemToEdit.actualityDate, 'DD/MM/YYYY').toDate()
            : new Date()
          : new Date(),
      valueDate:
        this.mode === 'edit'
          ? this.itemToEdit.actualityDate
            ? moment(this.itemToEdit.actualityDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),

      showHourPicker: false,
      selectedTime:
        this.mode === 'edit'
          ? this.itemToEdit.time
            ? moment(this.itemToEdit.time, 'HH:mm:ss').toDate()
            : new Date()
          : new Date(),
      valueTime:
        this.mode === 'edit'
          ? this.itemToEdit.time
            ? moment(this.itemToEdit.time, 'HH:mm').format('HH:mm')
            : moment().format('HH:mm')
          : moment().format('HH:mm'),

      buttonAddress: this.mode === 'edit' && this.itemToEdit.address ? this.itemToEdit.address.split('|')[0] : null,
      completeAddress:
        this.mode === 'edit' && this.itemToEdit.address
          ? {
              name: this.itemToEdit.address.split('|')[0],
              longitude: this.itemToEdit.longitude,
              latitude: this.itemToEdit.latitude,
              address: this.itemToEdit.address.split('|')[1],
            }
          : null,

      showImageViewer: false,
      selectedImageIndex: 0,

      // Club/ team
      autoClubID: -1,

      showTeam1: true,
      showTeam2: true,
      showClub1: true,
      showClub2: true,

      team1Focused: false,
      team2Focused: false,
      club1Focused: false,
      club2Focused: false,

      allTeamsInHomeClub: [],
      allTeamsInAwayClub: [],

      allClubsForSearch: [],
      resultSearchHomeClub: [],
      resultSearchAwayClub: [],

      resultSearchHomeTeam: [],
      resultSearchAwayTeam: [],

      homeClubNameSearched: this.mode === 'edit' ? this.itemToEdit.hClubName : '',
      homeTeamNameSearched: this.mode === 'edit' ? this.itemToEdit.selectedHomeTeamName : '',
      awayClubNameSearched: this.mode === 'edit' ? this.itemToEdit.aClubName : '',
      awayTeamNameSearched: this.mode === 'edit' ? this.itemToEdit.selectedAwayTeamName : '',
      homeTeam: null,
      awayTeam: null,
    };

    this.debug && console.debug('CreateActuality constructor, state:', this.mode, this.state, this.props.route);
  }

  async componentDidMount() {
    this.debug && console.debug('CreateActuality componentDidMount');

    this.setState((currentState) => ({
      hasPrivilegeAccess: checkIsModeratorOrAdmin(currentState.currentClub),
    }));

    this.getClubsList();

    if (this.mode === 'edit') {
      if (this.state.awayClubId && this.state.awayClubId.trim() !== '') {
        this.getAllTeamsInClub(this.state.awayClubId, false);
      }
      if (this.state.homeClubId && this.state.homeClubId.trim() !== '') {
        this.getAllTeamsInClub(this.state.homeClubId, true);
      }
    }
  }

  getClubsList = () => {
    this.debug && console.debug('CreateActuality getClubsList');

    // Get all known clubs and prepend the current club and our favorites
    const allClubs = this.props.allClubs;
    let result = [{ id: this.state.currentClub.id, name: '⭐ ' + this.state.currentClub.name }];
    result = [
      ...result,
      ...this.props.favorites.clubs.map((club) => {
        return { id: club.id, name: '⭐ ' + club.name };
      }),
    ];
    result = [
      ...result,
      ...allClubs.filter((club) => {
        if (!result.find((aClub) => aClub.id === club.id)) {
          return { id: club.id, name: club.name };
        }
      }),
    ];

    this.setState({
      allClubsForSearch: result,
      resultSearchHomeClub: result,
      resultSearchAwayClub: result,
    });
  };

  searchClub = async (searchText, home = true) => {
    this.debug && console.debug('CreateActuality searchClub', home, searchedText, this.state.allClubsForSearch);

    // ES6 - remove diacritics -)
    const searchedText = searchText.normalize('NFD').replace(/\p{Diacritic}/gu, '');

    if (searchedText && searchedText.trim() !== '') {
      const filteredClubs = this.state.allClubsForSearch.filter((club) => {
        const clubName = club.name.normalize('NFD').replace(/\p{Diacritic}/gu, '');
        return clubName.toLowerCase().includes(searchedText.toLowerCase());
      });
      this.debug && console.debug('CreateActuality searchClub', filteredClubs);
      this.setState(home ? { resultSearchHomeClub: filteredClubs } : { resultSearchAwayClub: filteredClubs });
    } else {
      this.setState((currentState) =>
        home
          ? { resultSearchHomeClub: currentState.allClubsForSearch }
          : { resultSearchAwayClub: currentState.allClubsForSearch }
      );
    }
  };

  getAllTeamsInClub = (clubId, home = true) => {
    TeamAPI.getTeamsList(this.props.tokens.wsToken, clubId, '', 1, 10).then((response) => {
      if (response?.success === true) {
        this.setState(
          home
            ? {
                allTeamsInHomeClub: response.result.teams,
                resultSearchHomeTeam: response.result.teams,
              }
            : {
                allTeamsInAwayClub: response.result.teams,
                resultSearchAwayTeam: response.result.teams,
              }
        );
      }
    });
  };

  searchTeamsInClub = (text, home = true) => {
    if (text && text.trim !== '') {
      if (home) {
        const filteredTeams = this.state.allTeamsInHomeClub.filter((team) =>
          team.name.toLowerCase().includes(text.toLowerCase())
        );
        this.setState({ resultSearchHomeTeam: filteredTeams });
      } else {
        const filteredTeams = this.state.allTeamsInAwayClub.filter((team) =>
          team.name.toLowerCase().includes(text.toLowerCase())
        );
        this.setState({ resultSearchAwayTeam: filteredTeams });
      }
    } else {
      if (home) {
        this.setState({ resultSearchHomeTeam: this.state.allTeamsInHomeClub });
      } else {
        this.setState({
          resultSearchAwayTeam: this.state.allTeamsInAwayClub,
        });
      }
    }
  };

  managePhotosActusAPI = async () => {
    const finalPhotosActus = [];

    this.setState({ loading: true });

    for (const photo of this.state.photos) {
      this.debug && console.debug('managePhotosActusAPI, photo:', photo);
      if (photo.uri) {
        const responseUploadMedia = await NewsAPI.uploadMedia(photo, this.props.tokens.wsToken);
        this.debug && console.debug('managePhotosActusAPI, response:', responseUploadMedia);
        if (responseUploadMedia?.success && responseUploadMedia?.result?.file_name) {
          finalPhotosActus.push({
            filename: responseUploadMedia.result.file_name,
            type: photo.type || responseUploadMedia.result.file_type,
          });
        } else {
          console.warn('managePhotosActusAPI, response:', responseUploadMedia);
          alert(I18n.t('app.oups'), `Une erreur s'est produite pendant le transfert d'un media.`);
        }
      } else if (photo.filename) {
        // todo: WTF ?!?
        finalPhotosActus.push({
          filename: photo.filename.split('\\').pop().split('/').pop(),
          type: photo.type,
        });
      }
    }

    this.setState({ loading: false });

    return finalPhotosActus;
  };

  addNews = async () => {
    this.debug && console.debug('CreateActuality, addNews...');

    // Manage photo actus
    const uploadableMedia = await this.managePhotosActusAPI();
    this.debug && console.debug('CreateActuality, photos:', uploadableMedia);
    if (this.state.photos.length > uploadableMedia.length) {
      console.warn('Missed uploading photos', this.state.photos, uploadableMedia);
      return;
    }

    const addNewsResponse = await NewsAPI.addNewsByClubId(
      this.props.tokens.wsToken,
      this.state.currentClub.id,
      this.state.selectedActualityType.id,
      this.state.recipient !== null ? this.state.recipient.id : 1,
      this.state.homeTeamId,
      this.state.awayTeamId,
      this.state.title,
      this.state.description,
      this.state.address,
      this.state.longitude,
      this.state.latitude,
      this.state.askReply,
      this.state.date,
      this.state.time,
      this.state.homeTeamScore,
      this.state.awayTeamScore,
      this.state.isHalfTime,
      this.state.homeClubId,
      this.state.awayClubId,
      this.state.homeClubName,
      this.state.awayClubName,
      this.state.homeTeamName,
      this.state.awayTeamName,
      this.state.scoreComment,
      uploadableMedia
    );
    this.debug && console.debug('CreateActuality, creation response:', addNewsResponse);

    this.setState({
      loading: false,
      selectedActualityType: this.props.constActuTypes[0],
      recipient: null,
      title: '',
      description: '',
      address: '',
      longitude: '',
      latitude: '',
      photos: [],
      askReply: 1,
      date: '',
      time: '',
      homeClubId: '',
      awayClubId: '',
      homeTeamId: '',
      awayTeamId: '',
      homeTeamName: '',
      awayTeamName: '',
      scoreComment: '',
      homeTeamScore: '',
      awayTeamScore: '',
      isHalfTime: 0,

      autoClubID: 1,

      buttonAddress: null,
    });

    if (this.state.hasPrivilegeAccess === false && addNewsResponse.success) {
      this.setState({
        loading: false,
        alertTitle: I18n.t('app.information'),
        alertMessage: I18n.t('createActu.sentForValidation'),
        alertButtons: [
          {
            text: 'Ok',
            onPress: () => {
              this.props.navigation.goBack();
            },
          },
        ],
        showAlert: true,
      });
    } else if (this.state.hasPrivilegeAccess === true && addNewsResponse?.success) {
      this.setState({
        loading: false,
        alertTitle: I18n.t('app.information'),
        alertMessage: 'Actualité enregistrée',
        alertButtons: [
          {
            text: 'Ok',
            onPress: () => {
              this.props.navigation.goBack();
            },
          },
        ],
        showAlert: true,
      });
    } else if (!addNewsResponse?.success) {
      this.setState({
        loading: false,
        alertTitle: I18n.t('app.information'),
        alertMessage: addNewsResponse.error
          ? addNewsResponse.error
          : 'Une erreur est survenue.\n Veuillez réessayer plus tard.',
        alertButtons: [
          {
            text: 'Ok',
            onPress: () => {
              // this.props.navigation.goBack();
            },
          },
        ],
        showAlert: true,
      });
    }
    return addNewsResponse;
  };

  onAddingActuality = async () => {
    this.debug && console.debug('CreateActuality, onAddingActuality...');

    if (this.state.recipient === null) {
      alert(I18n.t('app.oups'), I18n.t('app.noRecipient'));
    } else if (!this.state.title) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidTitle'));
      // } else if (this.state.description === '') {
      //   alert(I18n.t('app.oups'), I18n.t('app.invalidDescription'));
    } else {
      await this.addNews();
    }
    this.setState({ loading: false });
  };

  onAddingScore = async () => {
    this.debug && console.debug('CreateActuality, onAddingScore...');

    if (!this.state.homeClubName || !this.state.homeTeamName) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidHomeTeam'));
    } else if (!this.state.awayClubName || !this.state.awayTeamName) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAwayTeam'));
    } else if (this.state.homeTeamScore === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidHomeTeamScore'));
    } else if (this.state.awayTeamScore === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAwayTeamScore'));
    } else if (this.state.isHalfTime === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidIsHalfTime'));
      // } else if (this.state.scoreComment === '') {
      //   alert(I18n.t('app.oups'), I18n.t('app.invalidScoreComment'));
    } else {
      await this.addNews();
    }
    this.setState({ loading: false });
  };

  onAddingEvent = async () => {
    this.debug && console.debug('CreateActuality, onAddingEvent...');

    if (!this.state.recipient) {
      alert(I18n.t('app.oups'), I18n.t('app.noRecipient'));
    } else if (!this.state.title) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidTitle'));
    } else if (!this.state.description) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidDescription'));
    } else if (this.state.address === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAddress'));
    } else if (this.state.address.length > 255) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAddressLength'));
    } else if (this.state.longitude === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidLongitude'));
    } else if (this.state.latitude === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidLatitude'));
    } else if (this.state.date === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidDate'));
    } else if (this.state.time === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidTime'));
    } else {
      await this.addNews();
    }
    this.setState({ loading: false });
  };

  onAddNews = async () => {
    this.debug && console.debug('CreateActuality, onAddNews...', this.state.selectedActualityType);

    if (!this.state.selectedActualityType) {
      alert(I18n.t('app.oups'), I18n.t('app.noActuTypeSelected'));
      return;
    }

    this.setState({ loading: true });
    if (this.state.selectedActualityType.id === Number(ACT_ACTUALITY)) {
      this.setState(
        {
          date: moment().format('DD/MM/yyyy'),
          time: moment().format('LT'),
        },
        () => {
          this.onAddingActuality();
        }
      );
    } else if (this.state.selectedActualityType.id === Number(ACT_SCORE)) {
      this.setState(
        {
          date: moment().format('DD/MM/yyyy'),
          time: moment().format('LT'),
          description: this.state.scoreComment,
        },
        () => {
          this.onAddingScore();
        }
      );
    } else if (this.state.selectedActualityType.id === Number(ACT_EVENT)) {
      await this.onAddingEvent();
    }
    this.setState({ loading: false });
    this.debug && console.debug('CreateActuality, onAddNews created');
  };

  // Edit functions go from here
  editNews = async (data) => {
    this.debug && console.debug('CreateActuality, editNews...');

    const editNewsResponse = await NewsAPI.editNews(
      this.state.selectedActualityType.id,
      this.props.tokens.wsToken,
      this.itemToEdit.id,
      data
    );
    if (editNewsResponse && editNewsResponse.success) {
      this.setState({
        loading: false,
        alertTitle: I18n.t('app.information'),
        alertMessage: editNewsResponse.result,
        alertButtons: [
          {
            text: 'Ok',
            onPress: () => {
              if (this.props.updateNews) {
                this.props.updateNews();
              }

              this.setState({
                loading: false,
                selectedActualityType: this.props.constActuTypes[0],
                recipient: null,
                homeTeamId: '',
                homeTeamName: '',
                awayTeamId: '',
                awayTeamName: '',
                homeClubId: '',
                homeClubName: '',
                awayClubId: '',
                awayClubName: '',

                title: '',
                description: '',
                latitude: '',
                longitude: '',
                address: '',
                photos: [],
                askReply: 1,
                date: '',
                time: '',

                buttonAddress: null,

                homeTeamScore: '',
                awayTeamScore: '',
                isHalfTime: 0,
                scoreComment: '',

                autoClubID: -1,
              });

              this.props.dispatch(getNews(this.props.tokens.wsToken, this.itemToEdit.id));
              this.props.navigation.goBack();
            },
          },
        ],
        showAlert: true,
      });
    } else {
      this.debug && console.debug('CreateActuality, editNews error:', editNewsResponse);

      this.setState({
        loading: false,
        alertTitle: I18n.t('app.oups'),
        alertMessage: editNewsResponse ? editNewsResponse.error : ' Une erreur est survenue lors de la sauvegarde',
        alertButtons: [],
        showAlert: true,
      });
    }
    return editNewsResponse;
  };

  onEditingActuality = async () => {
    this.debug && console.debug('CreateActuality, onEditingActuality...');

    if (!this.state.recipient) {
      alert(I18n.t('app.oups'), I18n.t('app.noRecipient'));
    } else if (!this.state.title) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidTitle'));
      // } else if (this.state.description === '') {
      //   alert(I18n.t('app.oups'), I18n.t('app.invalidDescription'));
    } else {
      const finalPhotosActus = await this.managePhotosActusAPI();
      await this.editNews({
        title: this.state.title,
        description: this.state.description,
        photos: finalPhotosActus,
      });
    }
  };

  onEditingScore = async () => {
    this.debug && console.debug('CreateActuality, onEditingScore...');

    if (!this.state.homeClubName || !this.state.homeTeamId) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidHomeTeam'));
    } else if (!this.state.awayClubName || !this.state.awayTeamId) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAwayTeam'));
    } else if (this.state.homeTeamScore === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidHomeTeamScore'));
    } else if (this.state.awayTeamScore === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAwayTeamScore'));
    } else if (this.state.isHalfTime === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidIsHalfTime'));
      // } else if (this.state.scoreComment === '') {
      //   alert(I18n.t('app.oups'), I18n.t('app.invalidScoreComment'));
    } else {
      this.setState({ description: this.state.scoreComment }, () => {
        this.editNews({
          title: '',
          description: this.state.description,
          address: '',
          latitude: 0.0,
          longitude: 0.0,
          date: this.state.date,
          time: this.state.time,

          homeClubId: this.state.homeClubId,
          homeClubName: this.state.homeClubName,
          homeTeamId: this.state.homeTeamId,
          homeTeamName: this.state.homeTeamName,
          homeTeamScore: this.state.homeTeamScore,

          awayClubId: this.state.awayClubId,
          awayClubName: this.state.awayClubName,
          awayTeamId: this.state.awayTeamId,
          awayTeamName: this.state.awayTeamName,
          awayTeamScore: this.state.awayTeamScore,

          isHalfTime: this.state.isHalfTime,
        });
      });
    }
  };

  onEditingEvent = async () => {
    this.debug && console.debug('CreateActuality, onEditingEvent...');

    if (!this.state.recipient) {
      alert(I18n.t('app.oups'), I18n.t('app.noRecipient'));
    } else if (!this.state.title) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidTitle'));
    } else if (!this.state.description) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidDescription'));
    } else if (this.state.address === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAddress'));
    } else if (this.state.address.length > 255) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAddressLength'));
    } else if (this.state.longitude === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidLongitude'));
    } else if (this.state.latitude === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidLatitude'));
    } else if (this.state.date === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidDate'));
    } else if (this.state.time === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidTime'));
    } else {
      const finalPhotosActus = await this.managePhotosActusAPI();
      await this.editNews({
        title: this.state.title,
        description: this.state.description,
        address: this.state.address,
        latitude: this.state.latitude,
        longitude: this.state.longitude,

        askReply: this.state.askReply,
        date: this.state.date,
        time: this.state.time,

        photos: finalPhotosActus,
      });
    }
  };

  onEditNews = async () => {
    this.debug && console.debug('CreateActuality, onEditNews...', this.state.selectedActualityType);

    if (!this.state.selectedActualityType) {
      alert(I18n.t('app.oups'), I18n.t('app.noActuTypeSelected'));
      return;
    }

    this.setState({ loading: true });

    if (this.state.selectedActualityType.id === Number(ACT_ACTUALITY)) {
      this.setState({ date: moment().format('DD/MM/yyyy') }, () => {
        this.onEditingActuality();
      });
    } else if (this.state.selectedActualityType.id === Number(ACT_SCORE)) {
      this.setState(
        {
          date: moment().format('DD/MM/yyyy'),
          time: moment().format('LT'),
          description: this.state.scoreComment,
        },
        () => {
          this.onEditingScore();
        }
      );
    } else if (this.state.selectedActualityType.id === Number(ACT_EVENT)) {
      await this.onEditingEvent();
    }

    this.setState({ loading: false });
  };

  removeMedia(file, comeFromServer) {
    alert(
      'Attention',
      "Etes-vous sûr de vouloir supprimer l'image sélectionnée ?",
      [
        {
          text: 'Annuler',
          onPress: () => null,
          style: 'cancel',
        },
        {
          text: 'Supprimer',
          onPress: () => this.systemRemoveMedia(file, comeFromServer),
        },
      ],
      { cancelable: true }
    );
  }

  systemRemoveMedia(file, keys) {
    if (!file || !keys) {
      return;
    }

    const allPhotos = this.state.photos;
    if (keys === 'cellPhone') {
      // if element is correctly removed le variable "removeConvocation" will not be empty
      const newPhotos = allPhotos.filter((photo) => photo.uri !== file);
      this.setState({ photos: newPhotos });
    } else if (keys === 'server') {
      const newPhotos = allPhotos.filter((photo) => photo.filename !== file);
      this.setState({ photos: newPhotos });
    }
  }

  // async generateThumbnail(filename) {
  //   this.debug && console.debug('generateThumbnail, filename:', filename);
  //   try {
  //     const { uri } = await VideoThumbnails.getThumbnailAsync(
  //       'http://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4',
  //       {
  //         time: 15000,
  //       }
  //     );
  //     this.debug && console.debug('generateThumbnail, uri:', uri);
  //     return uri;
  //   } catch (e) {
  //     console.warn(e);
  //   }
  // }

  renderSelection(old = false) {
    if (old) {
      return (
        <>
          <Pressable
            style={[styles.selectionButton]}
            onPress={() => {
              this.scrollView.scrollTo({ x: 0, y: 0 });
              if (this.mode === 'add') {
                this.setState({ showActuTypes: true });
              }
            }}>
            <Text style={[styles.appFontRegular, styles.redText]}>
              {this.state.selectedActualityType
                ? this.state.selectedActualityType.name
                : I18n.t('createActu.typeActuality')}
            </Text>
          </Pressable>

          <SelectActualityType
            visible={this.state.showActuTypes}
            onSelected={(choice) => {
              this.setState({ selectedActualityType: choice });
              this.setState({ showActuTypes: false });
            }}
            onClose={() => this.setState({ showActuTypes: false })}
          />
        </>
      );
    }

    return (
      <>
        {/* Select actuality type... */}
        {Platform.OS === 'ios' && this.state.selectedActualityType && (
          <Pressable style={styles.selectionButton} onPress={() => this.setState({ selectedActualityType: null })}>
            <Text style={[styles.appFontRegular, styles.redText]}>
              {this.state.selectedActualityType
                ? this.state.selectedActualityType.name
                : I18n.t('createActu.typeActuality')}
            </Text>
          </Pressable>
        )}
        {Platform.OS === 'ios' && !this.state.selectedActualityType && (
          <Picker
            mode="dropdown"
            style={styles.pickerEvents}
            prompt={"Choisir un type d'actualité"}
            onFocus={() => this.setState({ pickerFocused: true })}
            onBlur={() => this.setState({ pickerFocused: true })}
            selectedValue={this.state.selectedActualityType ? this.state.selectedActualityType.id : undefined}
            onValueChange={(value) => {
              this.setState({
                selectedActualityType: this.props.constActuTypes.find((item) => Number(item.id) === Number(value)),
              });
            }}>
            <Picker.Item value="" label={"Choisir un type d'��vénement"} enabled={!this.state.pickerFocused} />
            {this.props.constActuTypes.map((event, index) => {
              return <Picker.Item key={index} label={event.name} value={event.id} fontFamily="appFontRegular" />;
            })}
          </Picker>
        )}
        {Platform.OS !== 'ios' && (
          <Picker
            mode="dialog"
            style={styles.pickerEvents}
            prompt={"Choisir un type d'actualité"}
            onFocus={() => this.setState({ pickerFocused: true })}
            onBlur={() => this.setState({ pickerFocused: true })}
            selectedValue={this.state.selectedActualityType ? this.state.selectedActualityType.id : undefined}
            onValueChange={(value) => {
              this.debug && console.debug('onValueChange', value);
              this.setState({
                selectedActualityType: this.props.constActuTypes.find((item) => Number(item.id) === Number(value)),
              });
            }}>
            {this.props.constActuTypes.map((event, index) => {
              return <Picker.Item key={index} label={event.name} value={event.id} fontFamily="appFontRegular" />;
            })}
          </Picker>
        )}
      </>
    );
  }

  renderRecipientsChoice() {
    return (
      <>
        {this.props.session != null && this.state.hasPrivilegeAccess === true && (
          <>
            <Pressable
              style={[styles.selectionButton, { marginTop: 20 }]}
              onPress={() => {
                this.setState({ showRecipients: true });
              }}>
              <Text style={[styles.appFontRegular, styles.redText]}>
                {this.state.recipient != null ? this.state.recipient.name : I18n.t('createActu.recipient')}
              </Text>
            </Pressable>

            <SelectRecipientsType
              visible={this.state.showRecipients}
              allSelectable={false}
              onSelected={(choice) => {
                this.setState({ recipient: choice });
                this.setState({ showRecipients: false });
              }}
              onClose={() => this.setState({ showRecipients: false })}
            />
          </>
        )}
      </>
    );
  }

  renderImagesScroller() {
    return (
      <>
        <View style={[styles.alignLeftContent, { marginVertical: 10 }]}>
          <ScrollView
            ref={(ref) => {
              this.photoSV = ref;
            }}
            horizontal
            onContentSizeChange={(contentWidth, contentHeight) => {
              if (this.state.photos.length > 2) {
                this.photoSV.scrollTo({ x: contentWidth, y: 0 });
              }
            }}
            showsHorizontalScrollIndicator={false}>
            {this.state.photos.map((photo, index) => {
              let photoUri = '';
              if (photo.uri) {
                photoUri = photo;
              } else if (photo.filename) {
                photoUri = { uri: getImageApiHostOrUrl(photo.filename) };
              }
              if (photo.type === 'video') {
                this.debug && console.debug('Video Uri:', photo);
                // todo: video thumbnail fails!
                // photoUri = this.generateThumbnail(photo.uri);
                photoUri = Images.videoLogo;
              }

              return (
                <Pressable
                  key={'Media-' + index}
                  style={[styles.photoCtn]}
                  onLongPress={() => {
                    if (photo.uri) {
                      this.removeMedia(photo.uri, 'cellPhone');
                    } else if (photo.filename) {
                      this.removeMedia(photo.filename, 'server');
                    }
                  }}
                  onPress={() => {
                    if (photo.type !== 'image') {
                      return;
                    }

                    if (this.state.photos.filter((photo) => photo.type === 'image').length > 0) {
                      this.setState({ showImageViewer: true });
                    }
                  }}>
                  <Image source={photoUri} resizeMode="contain" style={{ width: '100%', height: '100%' }} />
                </Pressable>
              );
            })}

            <Pressable
              style={[styles.photoCtn]}
              onPress={() => {
                if (this.state.photos.length < 30) {
                  this.setState({ showTakePhoto: true });
                } else {
                  this.setState({
                    loading: false,
                    alertTitle: I18n.t('app.oups'),
                    alertMessage: I18n.t('createActu.photoLimit'),
                    alertButtons: [{ text: 'Ok' }],
                    showAlert: true,
                  });
                }
              }}>
              <Icon
                size={25}
                name="camera-outline"
                color={this.state.currentClub?.mainColorBottom ?? Colors.blueCorporate}
              />
            </Pressable>
          </ScrollView>
        </View>
      </>
    );
  }

  renderAutoCompletion(club = true, home = true, title = '') {
    const searchList = club
      ? home
        ? this.state.resultSearchHomeClub
        : this.state.resultSearchAwayClub
      : home
      ? this.state.resultSearchHomeTeam
      : this.state.resultSearchAwayTeam;

    // Keyboard.dismiss();

    return (
      <ShadowCard overflowStyle={styles.autoCompleteBlock} style={[styles.shadowEffect]}>
        <View style={styles.autoCompleteHeader}>
          <Text style={styles.autoCompleteTitle}>{title}</Text>
          <Pressable
            style={styles.autoCompleteIcon}
            onPress={() => {
              this.setState({
                team1Focused: false,
                team2Focused: false,
                club1Focused: false,
                club2Focused: false,
              });
            }}>
            <Icon size={25} name="md-close" color={Colors.black} />
          </Pressable>
        </View>
        <FlatList
          data={searchList}
          keyExtractor={(item) => item.id}
          scrollEnabled
          nestedScrollEnabled
          renderItem={({ item, index }) => {
            return (
              <Pressable
                style={{
                  padding: 15,
                  borderTopWidth: 0.5,
                  borderTopColor: Colors.blueCorporate,
                }}
                onPress={() => {
                  if (club) {
                    if (home) {
                      this.setState(
                        {
                          homeClubId: item.id,
                          homeClubNameSearched: item.name,
                          homeClubName: item.name,
                          team1Focused: true,
                          team2Focused: false,
                          club1Focused: false,
                          club2Focused: false,
                        },
                        () => this.getAllTeamsInClub(item.id)
                      );
                    } else {
                      this.setState(
                        {
                          awayClubId: item.id,
                          awayClubNameSearched: item.name,
                          awayClubName: item.name,
                          team1Focused: false,
                          team2Focused: true,
                          club1Focused: false,
                          club2Focused: false,
                        },
                        () => this.getAllTeamsInClub(item.id, false)
                      );
                    }
                  } else {
                    if (home) {
                      this.setState({
                        homeTeamId: item.id,
                        homeTeamNameSearched: item.name,
                        homeTeamName: item.name,
                        team1Focused: false,
                        team2Focused: false,
                        club1Focused: false,
                        club2Focused: true,
                        homeTeam: item.id,
                      });

                      if (this.state.awayTeamId === item.id) {
                        alert(I18n.t('app.oups'), I18n.t('createActu.invalidSameTeam'));
                        this.setState({
                          homeTeamId: '',
                          homeTeamName: '',
                          homeTeamNameSearched: '',
                          homeTeam: '',
                        });
                      }
                    } else {
                      this.setState({
                        awayTeamId: item.id,
                        awayTeamName: item.name,
                        awayTeamNameSearched: item.name,
                        team1Focused: false,
                        team2Focused: false,
                        club1Focused: false,
                        club2Focused: false,
                        awayTeam: item.id,
                      });

                      if (this.state.homeTeamId === item.id) {
                        alert(I18n.t('app.oups'), I18n.t('createActu.invalidSameTeam'));
                        this.setState({
                          awayTeamId: '',
                          awayTeamName: null,
                          awayTeamNameSearched: '',
                          awayTeam: null,
                        });
                      }
                    }
                  }
                }}>
                <Text style={[styles.appFontRegular]}>{item.clubName ?? item.name}</Text>
              </Pressable>
            );
          }}
        />
      </ShadowCard>
    );
  }

  renderClubTeam(home = true, score = true) {
    return (
      <>
        <View style={[styles.inputCtn]}>
          <View>
            <TextInput
              value={home ? this.state.homeClubNameSearched : this.state.awayClubNameSearched}
              placeholder={I18n.t(`createActu.${home ? 'home' : 'away'}Club`)}
              style={[styles.inputClubName, !score ? { width: sw - 40 } : {}]}
              placeholderTextColor={Colors.blueCorporate + '55'}
              onFocus={async () => {
                this.scrollView.scrollTo({ x: 0, y: 100 });
                this.setState({
                  autoClubID: home ? 1 : 2,
                  showTeam1: false,
                  club1Focused: home,
                  club2Focused: !home,
                  team1Focused: false,
                  team2Focused: false,
                });
              }}
              onChangeText={(text) => {
                this.setState(
                  home
                    ? { homeClubName: text, homeClubNameSearched: text }
                    : { awayClubName: text, awayClubNameSearched: text }
                );
                this.searchClub(text, home);
              }}
              onEndEditing={() => {
                if (home) {
                  this.setState({ showTeam1: true });
                } else {
                  this.setState({ showTeam2: true });
                }
              }}
            />

            <TextInput
              value={home ? this.state.homeTeamNameSearched : this.state.awayTeamNameSearched}
              placeholder={I18n.t(`createActu.${home ? 'home' : 'away'}Team`)}
              style={[
                styles.inputClubName,
                !score ? { width: sw - 40 } : {},
                {
                  display: home
                    ? this.state.club1Focused && this.state.resultSearchHomeClub.length > 0
                      ? 'none'
                      : null
                    : this.state.club2Focused && this.state.resultSearchAwayClub.length > 0
                    ? 'none'
                    : 'flex',
                  marginTop: home
                    ? this.state.team1Focused && this.state.resultSearchHomeTeam.length > 0
                      ? 10
                      : 0
                    : this.state.team2Focused && this.state.resultSearchAwayTeam.length > 0
                    ? 10
                    : 0,
                },
              ]}
              placeholderTextColor={Colors.blueCorporate + '55'}
              onFocus={async () => {
                this.scrollView.scrollTo({ x: 0, y: 100 });

                this.setState({
                  autoClubID: home ? 1 : 2,
                  showTeam1: false,
                  club1Focused: false,
                  club2Focused: false,
                  team1Focused: home,
                  team2Focused: !home,
                });
              }}
              onChangeText={(text) => {
                if (home) {
                  this.setState({
                    homeTeamNameSearched: text,
                    homeTeamName: text,
                    homeTeamId: '',
                  });
                } else {
                  this.setState({
                    awayTeamNameSearched: text,
                    awayTeamName: text,
                    awayTeamId: '',
                  });
                }
                this.searchTeamsInClub(text, home);
              }}
            />
          </View>

          {score && (
            <View style={{ height: '100%', width: '100%', paddingTop: 10, paddingLeft: 21 }}>
              <TextInput
                value={home ? this.state.homeTeamScore : this.state.awayTeamScore}
                placeholder={I18n.t('createActu.score')}
                placeholderTextColor={Colors.blueCorporate + '55'}
                style={[
                  styles.inputScore,
                  {
                    color: Colors.blueCorporate,
                    fontFamily: 'appFontRegular',
                    display: home
                      ? (this.state.club1Focused && this.state.resultSearchHomeClub.length > 0) ||
                        (this.state.team1Focused && this.state.resultSearchHomeTeam.length > 0)
                        ? 'none'
                        : null
                      : (this.state.club2Focused && this.state.resultSearchAwayClub.length > 0) ||
                        (this.state.team2Focused && this.state.resultSearchAwayTeam.length > 0)
                      ? 'none'
                      : null,
                  },
                ]}
                onChangeText={(text) => {
                  this.setState(home ? { homeTeamScore: text } : { awayTeamScore: text });
                }}
                keyboardType="numeric"
                onFocus={async () => {
                  this.scrollView.scrollTo({ x: 0, y: 100 });
                  this.setState({ autoCompletePredict: [] });
                }}
              />
            </View>
          )}
        </View>
      </>
    );
  }

  render() {
    return (
      <>
        <HeaderBanner
          onLeftButton={() => {
            this.props.navigation.goBack();
          }}
          title={this.mode === 'edit' ? I18n.t('createActu.editActuality') : I18n.t('createActu.newActuality')}
        />

        <ScrollView
          scrollEventThrottle={0}
          ref={(ref) => {
            this.scrollView = ref;
          }}
          style={{ width: '100%' }}
          contentContainerStyle={{
            paddingBottom: Platform.OS === 'android' ? 30 : 0,
            // backgroundColor: Colors.gray,
          }}
          alwaysBounceVertical={false}
          bounces={false}>
          <View style={[styles.formContainer]}>
            {this.renderSelection(true)}

            {this.state.selectedActualityType && this.state.selectedActualityType.id === Number(ACT_SCORE) && (
              <>
                {/* Home... */}
                <View style={{ minHeight: 32, marginBottom: 10 }}>
                  {/* auto complete club choice */}
                  {this.state.autoClubID === 1 &&
                    this.state.club1Focused === true &&
                    this.state.resultSearchHomeClub.length > 0 &&
                    this.renderAutoCompletion(true, true, I18n.t('createActu.homeClubTitle'))}

                  {/* auto complete team choice */}
                  {this.state.autoClubID === 1 &&
                    this.state.team1Focused === true &&
                    this.state.resultSearchHomeTeam.length > 0 &&
                    this.renderAutoCompletion(false, true, I18n.t('createActu.homeTeamTitle'))}

                  {/* Display home club/team */}
                  {this.renderClubTeam(true, true)}
                </View>

                {/* Away... */}
                <View style={{ minHeight: 32, marginBottom: 10 }}>
                  {this.state.autoClubID === 2 &&
                    this.state.club2Focused === true &&
                    this.state.resultSearchAwayClub.length > 0 &&
                    this.renderAutoCompletion(true, false, I18n.t('createActu.awayClubTitle'))}

                  {/* autoComplete for team 2 */}
                  {this.state.autoClubID === 2 &&
                    this.state.team2Focused === true &&
                    this.state.resultSearchAwayTeam.length > 0 &&
                    this.renderAutoCompletion(false, false, I18n.t('createActu.awayTeamTitle'))}

                  {/* Display away club/team */}
                  {this.renderClubTeam(false, true)}
                </View>

                <TextInput
                  multiline
                  value={this.state.scoreComment}
                  style={[styles.textInputMultiline]}
                  placeholder={I18n.t('createActu.commentPlaceholder')}
                  placeholderTextColor={Colors.blueCorporate + '55'}
                  onChangeText={(text) => {
                    this.setState({ scoreComment: text });
                  }}
                  onFocus={() => {
                    this.scrollView.scrollTo({ x: 0, y: 360 });
                    this.setState({ autoCompletePredict: [] });
                  }}
                />

                <View style={[styles.inputCtnButton]}>
                  {/* halfTime score */}
                  <Pressable
                    style={[
                      styles.choiceButton,
                      this.state.isHalfTime === 1 ? styles.activeTimeButton : styles.inActiveTimeButton,
                    ]}
                    onPress={() => {
                      this.setState({ isHalfTime: 1 });
                    }}>
                    <Text
                      style={[
                        styles.buttonText,
                        this.state.isHalfTime === 1 ? styles.activeTextButton : styles.inActiveTextButton,
                      ]}>
                      {I18n.t('createActu.halfTime')}
                    </Text>
                  </Pressable>

                  {/* final score */}
                  <Pressable
                    style={[
                      styles.choiceButton,
                      this.state.isHalfTime === 0 ? styles.activeTimeButton : styles.inActiveTimeButton,
                    ]}
                    onPress={() => {
                      this.setState({ isHalfTime: 0 });
                    }}>
                    <Text
                      style={[
                        styles.buttonText,
                        this.state.isHalfTime === 0 ? styles.activeTextButton : styles.inActiveTextButton,
                      ]}>
                      {I18n.t('createActu.endOfMatch')}
                    </Text>
                  </Pressable>
                </View>
              </>
            )}

            {this.state.selectedActualityType && this.state.selectedActualityType.id === Number(ACT_EVENT) && (
              <>
                <TextInput
                  style={styles.textInput}
                  value={this.state.title}
                  placeholder={I18n.t('createActu.title')}
                  placeholderTextColor={Colors.blueCorporate + '55'}
                  onChangeText={(text) => {
                    this.setState({ title: text });
                  }}
                />
                <TextInput
                  style={styles.textInputMultiline}
                  value={this.state.description}
                  placeholder={I18n.t('createActu.description')}
                  placeholderTextColor={Colors.blueCorporate + '55'}
                  multiline
                  onChangeText={(text) => {
                    this.setState({ description: text });
                  }}
                />

                <Pressable
                  style={styles.selectionButton}
                  onPress={() => {
                    this.setState({ showChoosePlace: true });
                  }}>
                  <Text
                    style={[styles.appFontRegular, styles.redText, { marginLeft: 5, marginRight: 5 }]}
                    numberOfLines={1}
                    ellipsizeMode="tail">
                    {this.state.buttonAddress !== null ? this.state.buttonAddress : I18n.t('myAccount.address')}
                  </Text>
                </Pressable>

                <View style={[styles.alignLeftContent]}>
                  <Text style={[styles.appFontRegular, styles.blueText]}>{I18n.t('createActu.addPhoto')}</Text>
                </View>
                {this.renderImagesScroller()}

                {/* Date and time */}
                {Platform.OS !== 'web' ? (
                  <>
                    <Pressable onPress={() => this.setState({ showDatePicker: true })} style={styles.dateTime}>
                      <Text
                        style={{
                          fontFamily: 'appFontRegular',
                          color: this.state.date ? Colors.blueCorporate : Colors.blueCorporate + '55',
                        }}>
                        {this.state.date && this.state.date.trim() !== '' ? this.state.date : I18n.t('createActu.date')}
                      </Text>
                    </Pressable>

                    <DateTimePicker
                      mode="date"
                      visible={this.state.showDatePicker}
                      value={this.state.selectedDate}
                      onClose={() => {
                        this.setState({ showDatePicker: false });
                      }}
                      onChange={(selectedDate) => {
                        this.setState({ date: moment(selectedDate).format('DD/MM/YYYY'), selectedDate });
                      }}
                    />
                  </>
                ) : (
                  <>
                    <input
                      type="date"
                      style={styles.dateTime}
                      value={this.state.valueDate}
                      min={moment().format('YYYY-MM-DD')}
                      onInput={(event) => {
                        const selectedDate = event.target.value;
                        this.setState({
                          date: moment(selectedDate).format('DD/MM/YYYY'),
                          selectedDate,
                          valueDate: moment(selectedDate).format('YYYY-MM-DD'),
                        });
                      }}
                    />
                  </>
                )}

                {Platform.OS !== 'web' ? (
                  <>
                    <Pressable onPress={() => this.setState({ showHourPicker: true })} style={styles.dateTime}>
                      <Text
                        style={{
                          fontFamily: 'appFontRegular',
                          color: this.state.time ? Colors.blueCorporate : Colors.blueCorporate + '55',
                        }}>
                        {this.state.time && this.state.time.trim() !== '' ? this.state.time : I18n.t('createActu.hour')}
                      </Text>
                    </Pressable>

                    <DateTimePicker
                      mode="time"
                      visible={this.state.showHourPicker}
                      value={this.state.selectedTime}
                      onClose={() => {
                        this.setState({ showHourPicker: false });
                      }}
                      onChange={(selectedTime) => {
                        this.setState({ time: moment(selectedTime).format('LT'), selectedTime });
                      }}
                    />
                  </>
                ) : (
                  <>
                    <input
                      type="time"
                      style={styles.dateTime}
                      value={this.state.valueTime}
                      // step={5}
                      onInput={(event) => {
                        const selectedTime = event.target.value;
                        this.setState({
                          time: selectedTime,
                          selectedTime,
                          valueTime: selectedTime,
                        });
                      }}
                    />
                  </>
                )}

                {this.renderRecipientsChoice()}
              </>
            )}

            {((this.state.selectedActualityType &&
              this.state.selectedActualityType.id !== Number(ACT_SCORE) &&
              this.state.selectedActualityType.id !== Number(ACT_EVENT)) ||
              this.state.selectedActualityType === null) && (
              <>
                <TextInput
                  placeholder={I18n.t('createActu.title')}
                  value={this.state.title}
                  style={styles.textInput}
                  placeholderTextColor={Colors.blueCorporate + '55'}
                  onChangeText={(text) => {
                    this.setState({ title: text });
                  }}
                  onFocus={() => {
                    if (Platform.OS === 'android') {
                      this.scrollView.scrollTo({ x: 0, y: 100 });
                    } else {
                      this.scrollView.scrollTo({ x: 0, y: 50 });
                    }
                  }}
                />
                <TextInput
                  placeholder={I18n.t('createActu.description')}
                  value={this.state.description}
                  style={styles.textInputMultiline}
                  placeholderTextColor={Colors.blueCorporate + '55'}
                  multiline
                  onChangeText={(text) => {
                    this.setState({ description: text });
                  }}
                  onFocus={() => {
                    if (Platform.OS === 'android') {
                      this.scrollView.scrollTo({ x: 0, y: 200 });
                    } else {
                      this.scrollView.scrollTo({ x: 0, y: 100 });
                    }
                  }}
                />

                {/* News media */}
                <View style={[styles.alignLeftContent]}>
                  <Text style={[styles.appFontRegular, styles.blueText]}>{I18n.t('createActu.addPhoto')}</Text>
                </View>
                {this.renderImagesScroller()}

                {this.renderRecipientsChoice()}
              </>
            )}

            <ButtonValidate
              style={{ width: sw - 200, minWidth: 120 }}
              label={this.mode === 'add' ? I18n.t('createActu.create') : I18n.t('app.saveEdit')}
              onPress={() => {
                if (this.mode === 'add') {
                  this.onAddNews();
                } else if (this.mode === 'edit') {
                  this.onEditNews();
                }
              }}
            />
          </View>
          <View style={{ height: 60 }} />
        </ScrollView>

        <Loading loading={this.state.loading} />

        <TakePhoto
          visible={this.state.showTakePhoto}
          onPhotos={(photos) => {
            this.setState({ loading: true });
            this.setState((currentState) => {
              return {
                loading: false,
                photos: [...currentState.photos, ...photos],
                showTakePhoto: false,
              };
            });
          }}
          onCancel={() => {
            this.setState({ showTakePhoto: false });
          }}
          allowVideo
        />

        <ChoosePlace
          visible={this.state.showChoosePlace}
          fullAddress={this.state.completeAddress}
          setAddress={(address) => {
            this.debug && console.debug('CreateActuality place chosen', address);
            this.setState({
              fullAddress: address,
              buttonAddress: address.name,
              longitude: address.longitude,
              latitude: address.latitude,
              address: `${address.name}|${address.description}`,
            });
          }}
          onClose={(address) => {
            if (address) {
              this.debug && console.debug('CreateActuality place chosen', address);
              this.setState({
                fullAddress: address,
                buttonAddress: address.name,
                longitude: address.longitude,
                latitude: address.latitude,
                address: `${address.name}|${address.description}`,
              });
            }
            this.setState({showChoosePlace: false});
          }}
        />

        <MediaZoomViewer
          medias={this.state.photos.filter((photo) => photo.type === 'image')}
          index={0}
          visible={this.state.showImageViewer}
          onClose={() => {
            this.setState({ showImageViewer: false });
          }}
        />

        <AlerteUI
          showAlert={this.state.showAlert}
          onClose={() => {
            this.setState({ showAlert: false });
          }}
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          buttons={this.state.alertButtons}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { tokens, constActuTypes, constRecipientsTypes } = state.app;
  const { session, affiliatedClubInformation, favorites } = state.session;
  const { allClubs } = state.club;
  return {
    tokens,
    session,
    constActuTypes,
    constRecipientsTypes,
    affiliatedClubInformation,
    favorites,
    allClubs,
  };
};

export default connect(mapStateToProps)(CreateActuality);
