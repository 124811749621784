import moment from 'moment';

const regexUrl =
  /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;
const regexMail =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexMailTo = /(mailto:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+[\s|?|&])/gim;
const regexFrenchPhone = /^(?:(?:(?:\+|00)33[ ]?(?:\(0\)[ ]?)?)|0){1}[1-9]{1}([ .-]?)(?:\d{2}\1?){3}\d{2}$/gi;

const Validators = {
  validUrl: (email) => {
    return regexUrl.test(email);
  },
  validEmail: (email) => {
    return regexMail.test(email);
  },
  validPhoneNumber: (number) => {
    return regexFrenchPhone.test(number);
  },
  validPassword: (password) => {
    return /^(.){8,32}$/.test(password);
  },
  validName: (name) => {
    return /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s-]{1,60}$/.test(name);
  },
  validBirthdate: (date) => {
    const now = moment();
    const other = moment(date, 'yyyy');
    const difference = now.diff(other, 'years');

    // Older than 5!
    return difference > 5;
  },
};

// export default Validators;
export { regexUrl, regexFrenchPhone, regexMail, regexMailTo, Validators };
