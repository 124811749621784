export const ADD_FAVORITE_LINK = 'ADD_FAVORITE_LINK';
export const UPDATE_FAVORITE_LINK = 'UPDATE_FAVORITE_LINK';

const initialState = {
  resultFavorites: [],
};

const resultFavoritesReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case ADD_FAVORITE_LINK:
      return {
        ...state,
        resultFavorites: [...state.resultFavorites, actions.resultFavorite],
      };
    case UPDATE_FAVORITE_LINK:
      return {
        ...state,
        resultFavorites: actions.resultFavorites !== null ? actions.resultFavorites : [...state.resultFavorites],
      };
    default:
      return state;
  }
};

export default resultFavoritesReducer;
