import { TeamAPI } from '@apis/team';
import Images from '@assets/images';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import ButtonChoice from '@components/ButtonChoice/ButtonChoice';
import CardCategory from '@components/CardCategory/CardCategory';
import ShadowCard from '@components/ShadowCard/ShadowCard';
import TakePhoto from '@components/TakePhoto/TakePhoto';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import { gMaxWidth } from '@configs/styles';
import { UPDATE_TEAM, UPDATE_EDITING_TEAM } from '@redux/reducers';
import alert from '@utils/alert';
import { checkIsTeamManager } from '@utils/checkUserRoles';
import I18n from '@utils/i18n';
import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, FlatList, Pressable, Image, Platform, Dimensions, TextInput } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { useDispatch, useSelector } from 'react-redux';

const sw = Dimensions.get('window').width;

/**
 * A club members category as a FlatList element
 *
 * @param club
 * @param category
 * @param team - the current team, null for the main categories
 * @param selected - whether the category is selected or not
 * @param selectable - to make the category content items selectable or not
 * @param onSelectMember
 * @param onSelectCategory
 * @param onDeselectCategory
 * @param onSelectTeam
 * @param onDeselectTeam
 * @param onOpenChat
 * @param onConfirmUserTeam
 * @returns {JSX.Element}
 * @constructor
 */
export default function CardTeam({
  navigation,
  club,
  team,
  opened = false,
  imageHeader = false,
  selected = false,
  allSelectable = true,
  showCategories = false,
  likable = true,
  callable = true,
  chattable = true,
  selectable = true,
  pendingStatus = false,
  onSelectMember,
  onSelectCategory,
  onDeselectCategory,
  onSelectTeam,
  onDeselectTeam,
  onOpenChat = () => {},
  onConfirmUserTeam = () => {},
  onDeleteUserTeam = () => {},
}) {
  const debug = false;

  const dispatch = useDispatch();

  const [isBlockOpened, setIsBlockOpened] = useState(opened);
  const [teamSelected, setTeamSelected] = useState(selected);
  const [teamLiked, setTeamLiked] = useState(team.isLikedByUser);

  const [theTeam, setTheTeam] = useState(null);
  const [teamName, setTeamName] = useState(team.name);
  const [teamBannerIsUpdated, setTeamBannerIsUpdated] = useState(false);
  const [teamNameIsUpdated, setTeamNameIsUpdated] = useState(false);
  const [teamBanner, setTeamBanner] = useState('');

  const [showTakePhoto, setShowTakePhoto] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [isSelectedMember, setIsSelectedMember] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertButtons, setAlertButtons] = useState([]);

  const { editingTeam, session } = useSelector((state) => state.session);
  const { members, teams } = useSelector((state) => state.club);
  const { tokens, apiConfiguration } = useSelector((state) => state.app);
  const { selectedTeams } = useSelector((state) => state.chat);

  useEffect(() => {
    if (team.players) {
      setTheTeam(team);
    } else {
      TeamAPI.getTeamDetail(tokens.wsToken, team.id).then((response) => {
        if (response && response.success) {
          setTheTeam(response.result);
        }
      });
    }

    setTeamName(team.name);
    setTeamBanner(team.photo ? { uri: getImageApiHostOrUrl(team.photo) } : Images.teamPlaceholder);
  }, []);

  useEffect(() => {
    if (!teams) {
      return;
    }

    const foundTeam = teams.find((item) => item.id === team.id);
    debug && console.debug('CardTeam, foundTeam', foundTeam);
    if (foundTeam) {
      setTheTeam(foundTeam);
      setTeamName(foundTeam.name);
    }
  }, [teams]);

  useEffect(() => {
    if (isBlockOpened) {
      debug && console.debug('CardTeam, isBlockOpened', isBlockOpened);
      TeamAPI.getTeamDetail(tokens.wsToken, team.id).then((response) => {
        if (response && response.success) {
          setTheTeam(response.result);
        }
      });
    }
  }, [isBlockOpened]);

  useEffect(() => {
    if (!theTeam) {
      return;
    }
    const foundIndex = selectedTeams.findIndex((item) => item.id === theTeam.id);
    setTeamSelected(foundIndex >= 0);
  }, [selectedTeams]);

  useEffect(() => {
    if (isEditing) {
      debug && console.debug('CardTeam, isEditing', isEditing);
      dispatch({ type: UPDATE_EDITING_TEAM, editingTeam: theTeam });
    } else {
      dispatch({ type: UPDATE_EDITING_TEAM, editingTeam: null });
      setTeamBannerIsUpdated(false);
      setTeamNameIsUpdated(false);

      if (!theTeam) {
        setTeamName(team.name);
        setTeamBanner(team.photo ? { uri: getImageApiHostOrUrl(team.photo) } : Images.teamPlaceholder);
      } else {
        setTeamName(theTeam.name);
        setTeamBanner(theTeam.photo ? { uri: getImageApiHostOrUrl(theTeam.photo) } : Images.teamPlaceholder);
      }
    }
  }, [isEditing]);

  useEffect(() => {
    debug && console.debug('CardTeam, editingTeam: ', editingTeam);

    // turn off or turn on editing mode depending on the editingTeam
    if (editingTeam === null || editingTeam === undefined) {
      setIsEditing(false);
    } else if (editingTeam.id !== team.id) {
      setIsEditing(false);
    } else {
      debug && console.debug('CardTeam, I am editing the team', team);
      setIsEditing(true);
    }
  }, [editingTeam]);

  useEffect(() => {
    if (!isSelectedMember) {
      return;
    }

    const players = members.players.filter((item) => !theTeam.players.some((player) => player.id === item.id));
    navigation.navigate('MessagesStack', {
      screen: 'UserTeamSelection',
      params: {
        members: players,
        team: theTeam,
      },
    });

    debug && console.debug('CardTeam, teamPlayers', players);
  }, [isSelectedMember]);

  useEffect(() => {
    if (!isEditing) {
      return;
    }

    if (!teamName || !theTeam || teamName === '' || teamName === theTeam.name) {
      setTeamNameIsUpdated(false);
    } else {
      setTeamNameIsUpdated(true);
      debug && console.debug('CardTeam, newTeamName', teamName);
    }
    if (
      !teamBanner ||
      !theTeam ||
      teamBanner === theTeam.photo ||
      teamBanner === Images.teamPlaceholder ||
      teamBanner === '' ||
      teamBanner.uri.endsWith(theTeam.photo.replace('uploads', ''))
    ) {
      setTeamBannerIsUpdated(false);
    } else {
      setTeamBannerIsUpdated(true);
      debug && console.debug('CardTeam, newTeamBanner', teamBanner);
    }
  }, [teamName, teamBanner]);

  if (!theTeam) {
    return null;
  }

  const renderOpenedBlock = () => {
    return (
      <View style={styles.blockContainer}>
        {!imageHeader && (
          <View style={[styles.photoContainer]}>
            <Image
              source={theTeam.photo ? { uri: getImageApiHostOrUrl(theTeam.photo) } : Images.teamPlaceholder}
              style={[styles.photo]}
            />
          </View>
        )}
        {showCategories && (
          <FlatList
            data={[{ title: 'players' }, { title: 'coaches' }, { title: 'managers' }, { title: 'parents' }]}
            keyExtractor={(item, index) => item.title}
            renderItem={({ item, index }) => {
              if (!theTeam[item.title].length) return;

              return (
                <CardCategory
                  club={club}
                  category={item.title}
                  content={item.content ?? 'users'}
                  opened={opened}
                  callable={callable}
                  chattable={chattable}
                  selectable={selectable}
                  allSelectable={allSelectable}
                  members={theTeam[item.title]}
                  team={theTeam}
                  pendingStatus={pendingStatus}
                  onConfirmUserTeam={onConfirmUserTeam}
                  isEditing={isEditing}
                  onDeleteUserTeam={onDeleteUserTeam}
                  onSelectMember={(member) => {
                    onSelectMember(member, theTeam);
                  }}
                  onSelectCategory={(category, team) => {
                    onSelectCategory(item.title, theTeam);
                  }}
                  onDeselectCategory={(category, team) => {
                    onDeselectCategory(item.title, theTeam);
                  }}
                  onOpenChat={onOpenChat}
                />
              );
            }}
          />
        )}
      </View>
    );
  };
  const renderAlert = () => {
    return (
      <AlerteUI
        showAlert={showAlert}
        onClose={() => {
          setShowAlert(false);
        }}
        title={alertTitle}
        message={alertMessage}
        buttons={alertButtons}
      />
    );
  };

  const uploadTeamBanner = async () => {
    debug && console.debug('CardTeam uploadTeamBanner: ', teamBanner);

    return TeamAPI.uploadTeamBanner(teamBanner, tokens.wsToken, apiConfiguration).then((response) => {
      debug && console.debug('CardTeam uploadTeamBanner, response:', response);
      if (response && response.success) {
        return response.result;
      } else {
        return null;
      }
    });
  };

  const renderTakePhoto = () => {
    return (
      <TakePhoto
        visible={showTakePhoto}
        resize
        onPhotos={(assets) => {
          setTeamBanner(assets[0]);
          setShowTakePhoto(false);
        }}
        onCancel={() => {
          setShowTakePhoto(false);
        }}
      />
    );
  };

  const updateTeam = () => {
    debug && console.debug('CardTeam, updateTeam');

    setShowAlert(true);
    setAlertTitle(I18n.t('alerts.updateTeam.title'));
    setAlertMessage(I18n.t('alerts.updateTeam.message'));
    setAlertButtons([
      {
        text: I18n.t('alerts.update'),
        onPress: async () => {
          let newTeamBanner = null;
          let newTeamName = null;

          if (teamBannerIsUpdated) {
            const banner = await uploadTeamBanner();
            debug && console.debug('CardTeam, updateTeam, banner:', banner);

            if (!banner) {
              alert(I18n.t('alerts.updateTeam.error'));
              return;
            }
            newTeamBanner = banner.file_url;
          }

          if (teamNameIsUpdated) {
            newTeamName = teamName;
          }

          TeamAPI.editTeam(tokens.wsToken, 'update', theTeam.id, [], newTeamName, newTeamBanner).then((response) => {
            if (response && response.success) {
              dispatch({ type: UPDATE_TEAM, team: response.result, id: theTeam.id });
              setIsEditing(false);
            } else {
              alert(I18n.t('alerts.updateTeam.error'));
            }
          });
        },
      },
      {
        text: I18n.t('alerts.cancel'),
      },
    ]);
  };

  return (
    <>
      {imageHeader ? (
        <ShadowCard
          style={[styles.mainContainer, { width: (Platform.OS === 'web' ? gMaxWidth : sw) - 40, padding: 0 }]}>
          <Pressable
            style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}
            onPress={() => setIsBlockOpened(!isBlockOpened)}>
            <Pressable style={[styles.photoContainer]} disabled={!isEditing} onPress={() => setShowTakePhoto(true)}>
              <Image style={[styles.photo, {}]} source={teamBanner} />
              {isEditing && (
                <View
                  style={[
                    styles.iconUpdateAvatar,
                    styles.shadowEffect,
                    { backgroundColor: club.mainColorBottom ?? Colors.blueCorporate },
                  ]}>
                  <Icon size={20} name="camera-outline" color={club.activeIconColor} />
                </View>
              )}
            </Pressable>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 32,
                width: (Platform.OS === 'web' ? gMaxWidth : sw) - 60,
              }}>
              <View
                style={({ pressed }) => [
                  styles.closerRow,
                  { width: (Platform.OS === 'web' ? gMaxWidth : sw) - 90 },
                  pressed ? { opacity: 0.7 } : {},
                ]}>
                <TextInput
                  style={[styles.closerLabel, { color: club.mainColorBottom, width: '100%' }]}
                  type="text"
                  value={teamName}
                  editable={isEditing}
                  name={theTeam.name}
                  onChangeText={(text) => {
                    setTeamName(text);
                  }}
                />
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}>
                {isEditing && (teamNameIsUpdated || teamBannerIsUpdated) && (
                  <ButtonChoice label={I18n.t('message.add')} style={styles.buttonRow} onPress={() => updateTeam()} />
                )}
                {pendingStatus && checkIsTeamManager(session?.club) && (
                  // edit team button
                  <ButtonChoice
                    label="icon-pencil"
                    style={styles.buttonRow}
                    iconStyle={isEditing ? { color: Colors.red } : {}}
                    onPress={() => setIsEditing(!isEditing)}
                  />
                )}

                {isEditing && checkIsTeamManager(session?.club) && (
                  <ButtonChoice
                    label="icon-person-add-outline"
                    style={styles.buttonRow}
                    onPress={() => setIsSelectedMember(!isSelectedMember)}
                  />
                )}

                {/* setup the form */}

                {likable && !isEditing && (
                  <ButtonChoice
                    label={teamLiked ? 'icon-heart' : 'icon-heart-outline'}
                    style={styles.buttonRow}
                    onPress={() => {
                      if (teamLiked) {
                        TeamAPI.removeFavoriteTeam(tokens.wsToken, theTeam.id).then((response) => {
                          if (response && response.success) {
                            setTeamLiked(!teamLiked);
                          }
                        });
                      } else {
                        TeamAPI.addFavoriteTeam(tokens.wsToken, theTeam.id).then((response) => {
                          if (response && response.success) {
                            setTeamLiked(!teamLiked);
                          }
                        });
                      }
                    }}
                  />
                )}
              </View>
            </View>
          </Pressable>

          {isBlockOpened && renderOpenedBlock()}

          {renderAlert()}
          {renderTakePhoto()}
        </ShadowCard>
      ) : (
        <ShadowCard style={[styles.mainContainer, { width: (Platform.OS === 'web' ? gMaxWidth : sw) - 20 }]}>
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: 40 }}>
            <Pressable
              style={({ pressed }) => [
                styles.closerRow,
                { width: (Platform.OS === 'web' ? gMaxWidth : sw) - 90 },
                pressed ? { opacity: 0.7 } : {},
              ]}
              onPress={() => {
                setIsBlockOpened(!isBlockOpened);
              }}>
              <Icon
                style={styles.closerIcon}
                name={isBlockOpened ? 'chevron-up' : 'chevron-down'}
                color={club?.mainColorBottom ?? Colors.black}
                size={25}
              />
              <Text
                style={[
                  styles.closerLabel,
                  { color: club.mainColorBottom, width: (Platform.OS === 'web' ? gMaxWidth : sw) - 90 },
                ]}>{`${theTeam.name}`}</Text>
            </Pressable>

            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                height: 32,
                width: 60,
              }}>
              {pendingStatus && !isEditing && (
                // edit team button
                <Pressable
                  onPress={() => {
                    setIsEditing(true);
                  }}>
                  <Icon
                    style={styles.teamSelectionIcon}
                    size={25}
                    color={club.mainColorBottom ?? Colors.black}
                    name="pencil"
                  />
                </Pressable>
              )}

              {likable && (
                <Pressable
                  onPress={() => {
                    if (teamLiked) {
                      TeamAPI.removeFavoriteTeam(tokens.wsToken, theTeam.id).then((response) => {
                        if (response && response.success) {
                          setTeamLiked(!teamLiked);
                        }
                      });
                    } else {
                      TeamAPI.addFavoriteTeam(tokens.wsToken, theTeam.id).then((response) => {
                        if (response && response.success) {
                          setTeamLiked(!teamLiked);
                        }
                      });
                    }
                  }}>
                  <Icon
                    style={styles.teamSelectionIcon}
                    size={25}
                    color={club.mainColorBottom ?? Colors.black}
                    name={teamLiked ? 'heart' : 'heart-outline'}
                  />
                </Pressable>
              )}
            </View>

            {selectable && (
              <Pressable
                disabled={!selectable}
                onPress={() => {
                  if (teamSelected) {
                    onDeselectTeam(theTeam);
                  } else {
                    onSelectTeam(theTeam);
                  }
                  setTeamSelected(!teamSelected);
                }}>
                <Text style={styles.teamSelectionLabel} numberOfLines={1} ellipsizeMode="tail">
                  {teamSelected ? I18n.t('message.selectTeam') : I18n.t('message.deselectTeam')}
                </Text>
                <Icon
                  style={styles.teamSelectionIcon}
                  size={25}
                  color={club.mainColorBottom ?? Colors.black}
                  name={teamSelected ? 'checkbox-outline' : 'square-outline'}
                />
              </Pressable>
            )}
          </View>

          {isBlockOpened && renderOpenedBlock()}

          {renderAlert()}
          {renderTakePhoto()}
        </ShadowCard>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    marginHorizontal: 0,
    // marginLeft: -10,
    marginTop: 0,
    // backgroundColor: Colors.blueSky,
  },
  buttonRow: {
    width: 48,
    height: 30,
    justifyContent: 'center',
    marginHorizontal: 5,
    marginVertical: 1,
  },

  closerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    margin: 0,
    padding: 0,
  },
  closerIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  closerLabel: {
    fontFamily: 'appFontRegular',
    fontSize: 18,
  },
  closerLabelWithSelection: {
    fontFamily: 'appFontMedium',
    fontSize: 18,
  },

  photoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 200,
    overflow: 'hidden',
  },
  photo: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: 0,
  },

  blockContainer: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: Colors.whiteCorporate,
    marginBottom: 5,
  },

  teamSelection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'top',

    marginRight: 10,
  },

  teamSelectionLabel: {
    color: Colors.iconGray,
    display: 'none',
    marginRight: 10,
  },

  teamSelectionIcon: {
    // alignSelf: 'center',
    // color: Colors.iconGray,
  },
  iconUpdateAvatar: {
    width: 35,
    height: 35,
    borderRadius: 18,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'absolute',
    top: 60,
    right: 10,
  },
  shadowEffect: {
    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },
});
