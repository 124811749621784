import Images from '@assets/images';
import ShadowCard from '@components/ShadowCard/ShadowCard';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import { gMaxWidth } from '@configs/styles';
import I18n from '@utils/i18n';
import { openMail, openMap, openPhone, openUrl } from '@utils/openLinks';
import { useDimensions } from '@utils/useDimensions';
import React, { useEffect, useRef, useState } from 'react';
import { View, Text, Image, StyleSheet, Platform, Pressable, Dimensions, ScrollView } from 'react-native';
import { Autolink } from 'react-native-autolink';
import RBSheet from 'react-native-raw-bottom-sheet';
import Icon from 'react-native-vector-icons/Ionicons';

const { width: sw, height: sh } = Dimensions.get('window');

export default function CardPartner({
  club,
  partner,
  selectable = true,
  selectionIcon = 'square-outline',
  selectedIcon = 'checkbox-outline',
  onSelectPartner,
}) {
  const debug = false;

  const { width } = useDimensions().window;

  const [partnerLogo, setPartnerLogo] = useState(null);
  const [viewPartnerSheet, setViewPartnerSheet] = useState(false);

  useEffect(() => {
    if (!partner) {
      return;
    }
    debug && console.debug('CardPartner current:', partner);
    setPartnerLogo(
      partner.logoFilename ? { uri: getImageApiHostOrUrl(partner.logoFilename) } : Images.bannerPlaceholder
    );
  }, []);

  const refBottomSheet = useRef();

  useEffect(() => {
    if (!refBottomSheet || !refBottomSheet.current) {
      return;
    }

    setTimeout(() => {
      viewPartnerSheet && refBottomSheet.current.open();
      !viewPartnerSheet && refBottomSheet.current.close();
    }, 100);
  }, [viewPartnerSheet]);

  const _renderCoordinates = (address = false, phone = true, mail = true, url = false, web = false) => {
    return (
      <>
        <View style={[styles.verticalContainer]}>
          {address && partner.address && (
            <Pressable
              style={[styles.contactContainer]}
              onPress={() => {
                openMap(partner.address);
              }}>
              <View style={[styles.actionButton, styles.shadowEffect]}>
                <Icon name="map" size={25} color={club.mainColorBottom} />
              </View>
              <Text style={[styles.userContact, { padding: 3 }]} numberOfLines={5} ellipsizeMode="tail">
                {partner.address}
              </Text>
            </Pressable>
          )}

          {phone && partner.phoneNumber && (
            <Pressable
              style={[styles.contactContainer]}
              onPress={() => {
                openPhone(partner.phoneNumber);
              }}>
              <View style={[styles.actionButton, styles.shadowEffect]}>
                <Icon name="call" size={25} color={club.mainColorBottom} />
              </View>
              <Text style={[styles.userContact]}>{partner.phoneNumber}</Text>
            </Pressable>
          )}

          {mail && partner.email && partner.email.trim() !== '' && (
            <Pressable style={[styles.contactContainer]} onPress={() => openMail(partner.email)}>
              <View style={[styles.actionButton, styles.shadowEffect]}>
                <Icon name="mail" size={25} color={club.mainColorBottom} />
              </View>
              <Text style={[styles.userContact]}>{partner.email}</Text>
            </Pressable>
          )}

          {url && partner.url && partner.url.trim() !== '' && (
            <Pressable style={[styles.contactContainer]} onPress={() => openUrl(partner.url)}>
              <View style={[styles.actionButton, styles.shadowEffect]}>
                <Icon name="globe" size={25} color={club.mainColorBottom} />
              </View>
              <Text style={[styles.userContact]}>{partner.url}</Text>
            </Pressable>
          )}

          {web && partner.url && (
            <Pressable
              style={[styles.wwwContainer, { backgroundColor: club.mainColorBottom }]}
              onPress={() => {
                openUrl(partner.url);
              }}>
              <View style={[styles.roundBorderWhite]}>
                <View style={[styles.cutter, { backgroundColor: club.mainColorBottom }]} />
                <Text style={[styles.www, { color: club.inactiveIconColor }]}>www</Text>
              </View>

              <Text style={[styles.viewWebSite, { color: club.inactiveIconColor }]}>
                {I18n.t('partner.viewWebSite')}
              </Text>
            </Pressable>
          )}
        </View>
      </>
    );
  };

  return (
    <>
      {viewPartnerSheet && (
        <RBSheet
          ref={refBottomSheet}
          height={sh - 50}
          openDuration={250}
          closeOnDragDown
          closeOnPressMask
          onClose={() => {
            setViewPartnerSheet(false);
          }}
          // Web
          closeOnPressBack
          customStyles={{
            wrapper: {
              backgroundColor: Colors.blueBgGradient1 + '00',
            },
            container: {
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              width: '100%',
              maxWidth: Platform.OS === 'web' ? gMaxWidth : sw,
              marginHorizontal: Platform.OS === 'web' ? (sw - gMaxWidth) / 2 : 0,
            },
            draggableIcon: {
              backgroundColor: Colors.iconGray + '88',
              width: 60,
              margin: 15,
            },
          }}>
          <View style={{ alignItems: 'center', marginLeft: '4%', marginRight: '4%' }}>
            <Text
              style={{
                color: club.mainColorBottom ?? Colors.blueCorporate,
                fontFamily: 'appFontMedium',
                fontSize: 20,
                marginVertical: 15,
              }}
              numberOfLines={3}
              ellipsizeMode="tail">
              {partner.name}
            </Text>

            <Image source={partnerLogo} style={[styles.clubPartnerLogo]} />
          </View>

          <ScrollView showsVerticalScrollIndicator>
            <View style={{ alignItems: 'center', marginLeft: '4%', marginRight: '4%' }}>
              {partner.address && partner.address.trim() !== '' && (
                <Pressable
                  style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}
                  onPress={() => {
                    openMap(partner.address);
                  }}>
                  <View style={[{ marginRight: 10 }, styles.actionButton]}>
                    <Icon name="map" size={25} color={club.mainColorBottom ?? Colors.blueCorporate} />
                  </View>
                  <Text
                    style={{
                      fontFamily: 'appFontMedium',
                      color: Colors.blueCorporate,
                      marginBottom: '5%',
                      fontSize: 14,
                    }}
                    numberOfLines={3}>
                    {partner.address}
                  </Text>
                </Pressable>
              )}

              <Autolink
                style={{
                  marginTop: 10,
                  marginBottom: '5%',
                  color: Colors.blueCorporate + 'CC',
                  fontFamily: 'appFontRegular',
                  fontSize: 14,
                }}
                linkStyle={{ padding: 2, margin: 0, color: Colors.textLink, fontWeight: 'bold' }}
                text={partner.description}
                email
                hashtag="instagram"
                mention="twitter"
                phone
                url
              />
            </View>
          </ScrollView>

          {_renderCoordinates(false, true, true, true)}
        </RBSheet>
      )}

      <ShadowCard style={[styles.mainContainer, { width: (Platform.OS === 'web' ? gMaxWidth : sw) - 20 }]}>
        <Pressable
          style={[styles.userCard]}
          onPress={() => {
            setViewPartnerSheet(true);
          }}
          onLongPress={() => {
            // this.props.onLongPress();
          }}>
          <View style={[styles.partnerCard]}>
            <View style={[styles.headerContainer]}>
              <Pressable
                onPress={() => {
                  setViewPartnerSheet(true);
                }}>
                <Image source={partnerLogo} style={[styles.clubPartnerLogo]} />
              </Pressable>

              {_renderCoordinates(false, false, false, false, true)}
            </View>

            <View style={[styles.presentationContainer]}>
              <Pressable
                onPress={() => {
                  setViewPartnerSheet(true);
                }}>
                <Text style={[styles.name]} numberOfLines={1}>
                  {partner.name}
                </Text>
                <Text style={[styles.presentation, { width: width - 40 }]} numberOfLines={3}>
                  {partner.description}
                </Text>
              </Pressable>
            </View>

            <View style={[styles.footerContainer]}>{_renderCoordinates(true, true, true, false)}</View>

            {selectable && (
              <Pressable
                disabled={!selectable}
                onPress={() => {
                  onSelectPartner(partner);
                }}
                style={({ pressed }) => [pressed ? { opacity: 0.7 } : {}]}>
                <Icon
                  size={25}
                  color={(club && club.mainColorBottom) || Colors.blueCorporate}
                  name={partner.selected ? selectedIcon : selectionIcon}
                />
              </Pressable>
            )}
          </View>
        </Pressable>
      </ShadowCard>
    </>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    marginHorizontal: 10,
    marginTop: 0,
  },
  partnerCard: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    overflow: 'visible',
    borderColor: 'transparent',
  },
  headerContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
  },
  presentationContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 10,
    paddingTop: 0,
  },
  name: {
    color: Colors.blueCorporate + 'CC',
    fontWeight: 'bold',
    fontSize: 15,
    marginBottom: 4,
  },
  presentation: {
    color: Colors.blueCorporate + 'CC',
    fontFamily: 'appFontRegular',
    fontSize: 13,
    width: '90%',
  },
  contactContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 5,
  },
  userContact: {
    fontFamily: 'appFontMedium',
    fontSize: 11,
    color: Colors.blueCorporate,
    marginTop: -10,
  },

  footerContainer: {
    width: '100%',
    paddingHorizontal: 0,
  },

  verticalContainer: {
    justifyContent: 'space-between',

    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: 10,
  },

  wwwContainer: {
    width: 140,
    height: 40,
    marginRight: -18,
    borderTopLeftRadius: 100,
    borderBottomLeftRadius: 100,
    borderTopRightRadius: Platform.OS === 'ios' ? 5 : 15,
    borderBottomRightRadius: Platform.OS === 'ios' ? 5 : 15,
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'visible',
  },
  roundBorderWhite: {
    width: 36,
    height: 36,
    borderWidth: 2,
    borderColor: Colors.whiteCorporate,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    marginLeft: 2,
  },
  www: {
    fontFamily: 'appFontMedium',
    fontSize: 10,
  },
  viewWebSite: {
    fontFamily: 'appFontRegular',
    fontSize: 10,
    marginLeft: 10,
  },
  cutter: {
    width: 10,
    height: 10,
    position: 'absolute',
    top: 0,
    right: 0,
    transform: [{ rotateZ: '45deg' }],
  },

  clubPartnerLogo: {
    marginVertical: 5,
    marginHorizontal: 50,
    width: 100,
    height: 128,
    resizeMode: 'contain',
  },
  actionButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginVertical: 5,
  },
  actionButton: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: Colors.whiteCorporate,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },

  shadowEffect: {
    marginBottom: 15,

    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },

  selected: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.red,
    borderRadius: 100,
  },
});
