/**
 * Function used to check if user is an administrator
 * @param {Object} userInformation
 * @returns boolean
 */
export function checkIsAdmin(userInformation) {
  if (!userInformation) {
    return false;
  }
  if (!userInformation.userRoleIds || !Array.isArray(userInformation.userRoleIds)) {
    if (!userInformation.roleIds || !Array.isArray(userInformation.roleIds)) {
      console.warn('checkIsAdmin should use data with userRoleIds or roleIds!', userInformation);
      return false;
    }

    // Admin: 1, Moderator: 3
    return userInformation.roleIds.includes(1) || userInformation.roleIds.includes('1');
  }

  // Admin: 1, Moderator: 3
  return userInformation.userRoleIds.includes(1) || userInformation.userRoleIds.includes('1');
}

/**
 * Function used to check if user is moderator or admin
 * @param {Object} userInformation
 * @returns boolean
 */
export function checkIsModeratorOrAdmin(userInformation) {
  if (!userInformation) {
    return false;
  }
  if (userInformation.isModerator === true) {
    return true;
  }

  if (!userInformation.userRoleIds || !Array.isArray(userInformation.userRoleIds)) {
    if (!userInformation.roleIds || !Array.isArray(userInformation.roleIds)) {
      console.warn('checkIsModeratorOrAdmin should use data with userRoleIds or roleIds!', userInformation);
      return false;
    }

    // Admin: 1, Moderator: 3
    return (
      userInformation.roleIds.includes(1) ||
      userInformation.roleIds.includes('1') ||
      userInformation.roleIds.includes(3) ||
      userInformation.roleIds.includes('3')
    );
  }

  // Admin: 1, Moderator: 3
  return (
    userInformation.userRoleIds.includes(1) ||
    userInformation.userRoleIds.includes('1') ||
    userInformation.userRoleIds.includes(3) ||
    userInformation.userRoleIds.includes('3')
  );
}

/**
 * Function used to check if user is an administrator
 * @param {Object} userInformation
 * @returns boolean
 */
export function checkIsTeamManager(userInformation) {
  if (!userInformation) {
    return false;
  }
  if (!userInformation.userRoleIds || !Array.isArray(userInformation.userRoleIds)) {
    if (!userInformation.roleIds || !Array.isArray(userInformation.roleIds)) {
      console.warn('checkIsTeamManager should use data with userRoleIds or roleIds!', userInformation);
      return false;
    }

    // TeamManager: 20
    return userInformation.roleIds.includes(20) || userInformation.roleIds.includes('20');
  }

  // TeamManager: 20
  return userInformation.userRoleIds.includes(20) || userInformation.userRoleIds.includes('20');
}
