import { ClubsAPI } from '@apis/clubs';
import {
  ADD_AFFILIATE_CLUB,
  SET_AFFILIATED_CLUBS,
  SET_FAVORITES,
  UPDATE_INFORMATION_CLUB_SEARCHED,
} from '@redux/reducers/SessionReducer';

export function addAffiliateClub(wsToken, club, callback) {
  return async (dispatch) => {
    try {
      if (!club || !wsToken) {
        throw new Error('Missing parameters in action addAffiliateClub');
      }
      const clubIds = club.id;
      const profileIds = club.userProfileIds;
      const addNewClubAffiliation = await ClubsAPI.addNewClubAffiliation(wsToken, clubIds, profileIds);

      if (!addNewClubAffiliation || addNewClubAffiliation.success === false) {
        callback({ success: false });
      } else {
        dispatch({
          type: ADD_AFFILIATE_CLUB,
          club,
        });
        callback({ success: true });
      }
    } catch (error) {
      console.warn('addAffiliateClub, catch:', error);
      callback({
        success: false,
      });
    }
  };
}

export function setAffiliatedClubs(clubs, clubId = null) {
  return async (dispatch) => {
    dispatch({
      type: SET_AFFILIATED_CLUBS,
      clubs,
      clubId,
    });
  };
}

export function setFoundClub(club, callback) {
  return async (dispatch) => {
    if (club) {
      dispatch({
        type: UPDATE_INFORMATION_CLUB_SEARCHED,
        club,
        hasSuccess: true,
      });
      callback &&
        callback({
          success: true,
        });
    } else {
      callback &&
        callback({
          success: false,
        });
      dispatch({
        type: UPDATE_INFORMATION_CLUB_SEARCHED,
        club: null,
        hasSuccess: false,
      });
    }
  };
}

export function setFavorites(regions, departments, clubs) {
  return async (dispatch) => {
    const theRegions = regions ?? [];
    const theDepartments = departments ?? [];
    const theClubs = clubs ?? [];

    dispatch({
      type: SET_FAVORITES,
      someExists: theRegions.length + theDepartments.length + theClubs.length > 0,
      regions: theRegions,
      departments: theDepartments,
      clubs: theClubs,
    });
  };
}

export function razFavorites() {
  return async (dispatch) => {
    dispatch({
      type: SET_FAVORITES,
      someExists: false,
      regions: [],
      departments: [],
      clubs: [],
    });
  };
}
