// ShadowCard
import Colors from '@configs/colors';
import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';

class ShadowCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <View style={[styles.mainContainer, this.props.style]}>
        <View style={[this.props.overflowStyle]}>{this.props.children}</View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    marginBottom: 10,
    borderRadius: 10,

    elevation: 5,
    shadowColor: Colors.iconGray,
    shadowOffset: {
      width: 3,
      height: 5,
    },
    shadowOpacity: 0.2,
    shadowRadius: 3,

    padding: 5,
    backgroundColor: Colors.whiteCorporate,
  },
});

export default ShadowCard;
