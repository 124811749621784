import { getApiHost } from '@configs/host';

import { Request } from './Request';
import { URLs } from './apisConstants';

export const DocumentAPI = {
  getAllDocuments: (wsToken, page, limit, getAll = 1) => {
    const url =
      getApiHost() +
      URLs.getAllDocuments +
      '?wsToken=' +
      wsToken +
      '&page=' +
      page +
      '&limit=' +
      limit +
      '&getAll=' +
      getAll;
    return Request.get(url);
  },
  getAllDocumentsByClubId: (wsToken, page, limit, getAll = 1, clubId) => {
    const url =
      getApiHost() +
      URLs.getAllDocumentsBuClubId +
      '?wsToken=' +
      wsToken +
      '&clubId=' +
      clubId +
      '&page=' +
      page +
      '&limit=' +
      limit +
      '&getAll=' +
      getAll;
    return Request.get(url);
  },
  deleteDocument: (wsToken, documentId) => {
    const url = getApiHost() + URLs.deleteDocument;
    const data = {
      wsToken,
      documentId,
    };
    return Request.post(url, data);
  },
  submitDocument: (wsToken) => {
    const url = getApiHost() + URLs.submitDocument;
    const data = {
      wsToken,
    };
    return Request.post(url, data);
  },
};
