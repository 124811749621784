import { DIARY_CONVOCATION, DIARY_EVENT, DIARY_MATCH, DIARY_TRAINING } from '@apis/diaries';
import Constants from 'expo-constants';

// API - configuration
export const SET_API_CONFIGURATION = 'SET_API_CONFIGURATION';
export const SET_LANGUAGE_CODE = 'SET_LANGUAGE_CODE';
// User session
export const SET_SESSION = 'SET_SESSION';
export const SET_TOKENS = 'SET_TOKENS';
// Application status
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_APP_ONLINE = 'SET_APP_ONLINE';
export const SET_SIGNED_IN = 'SET_SIGNED_IN';
export const SET_DISCOVERY_MODE = 'SET_DISCOVERY_MODE';
export const SET_USER_STATUS = 'SET_USER_STATUS';
// Account creation
export const ACCOUNT_CREATION_SET_NEW_USER = 'ACCOUNT_CREATION_SET_NEW_USER';
export const ACCOUNT_CREATION_SET_SELECTED_CLUB = 'ACCOUNT_CREATION_SET_SELECTED_CLUB';
// Update pseudo-constant values
export const SET_CONST_PROFILES_TYPES = 'SET_CONST_PROFILES_TYPES';
// Global to all reducers
export const RESET_FOR_LOGOUT = 'RESET_FOR_LOGOUT';

export const constRolesTypes = [
  {
    id: 1,
    name: 'Admin club',
  },
  {
    id: 2,
    name: 'Super admin',
  },
  {
    id: 3,
    name: 'Modérateur',
  },
  {
    id: 4,
    name: 'Président',
  },
  {
    id: 5,
    name: 'Trésorier',
  },
  {
    id: 6,
    name: 'Secrétaire',
  },
  {
    id: 7,
    name: 'Vice Président',
  },
  {
    id: 9,
    name: 'Membre du CA',
  },
  {
    id: 10,
    name: 'Responsable technique',
  },
  {
    id: 11,
    name: 'Responsable communication',
  },
  {
    id: 12,
    name: 'Responsable sponsoring',
  },
  {
    id: 13,
    name: 'Responsable événements',
  },
  {
    id: 14,
    name: 'Responsable équipement et boutique',
  },
  {
    id: 15,
    name: 'Responsable arbitrage',
  },
  {
    id: 16,
    name: 'Responsable commission de discipline',
  },
  {
    id: 17,
    name: 'Responsable des entraîneurs',
  },
  {
    id: 18,
    name: 'Salarié',
  },
  {
    id: 19,
    name: 'Responsable du back-office',
  },
  {
    id: 20,
    name: "Responsable d'équipe",
  },
];

export const constProfilesTypes = [
  {
    id: 1,
    name: 'Joueur',
  },
  {
    id: 2,
    name: 'Entraineur',
  },
  {
    id: 3,
    name: 'Dirigeant club',
  },
  {
    id: 4,
    name: 'Dirigeant équipe',
  },
  {
    id: 5,
    name: 'Bénévole',
  },
  {
    id: 6,
    name: 'Supporter',
  },
  {
    id: 7,
    name: 'Ancien joueur',
  },
  {
    id: 8,
    name: 'Ancien du club',
  },
  {
    id: 9,
    name: 'Arbitre',
  },
  {
    id: 10,
    name: 'Parent',
  },
  {
    id: 11,
    name: 'Partenaire',
  },
];

export const constActuTypes = [
  {
    id: 1,
    name: 'Actualité',
  },
  {
    id: 2,
    name: 'Score',
  },
  {
    id: 3,
    name: 'Événement',
  },
];

export const constEventTypes = [
  {
    id: 1,
    name: 'Entrainement',
  },
  {
    id: 2,
    name: 'Match',
  },
  {
    id: 3,
    name: 'Événement',
  },
];

export const constRecipientsTypes =
  Constants.expoConfig.extra.appSport === 'hand'
    ? [
        {
          id: 1,
          name: 'Club',
        },
        {
          id: 2,
          name: 'Comité',
        },
        {
          id: 3,
          name: 'Ligue',
        },
        // {
        //     id: "R4",
        //     name: "Public"
        // },
        {
          id: 4,
          name: 'Fédération',
        },
      ]
    : [
        {
          id: 1,
          name: 'Club',
        },
        {
          id: 2,
          name: 'District',
        },
        {
          id: 3,
          name: 'Ligue',
        },
        // {
        //     id: "R4",
        //     name: "Public"
        // },
        {
          id: 4,
          name: 'Fédération',
        },
      ];

export const constAgendaFilters = [
  {
    id: DIARY_TRAINING,
    name: 'Entrainements',
  },
  {
    id: DIARY_MATCH,
    name: 'Matchs',
  },
  {
    id: DIARY_EVENT,
    name: 'Événement',
  },
  {
    id: DIARY_CONVOCATION,
    name: 'Convocations',
  },
];

const INITIAL_STATE = {
  online: true,

  apiConfiguration: null,

  currentPage: 'HomeStack',

  // language: FRLanguage,
  languageCode: 'fr-FR',
  signedIn: false,
  discovery: false,
  userStatus: null,
  tokens: null,

  accountCreation: {
    newUser: null,
    selectedClub: null,
    selectedClubTeams: null,
  },

  constProfilesTypes,
  constActuTypes,
  constRecipientsTypes,
  constAgendaFilters,
  constEventTypes,

  expoPushToken: null,
};

const appReducer = (state = INITIAL_STATE, payload) => {
  switch (payload.type) {
    // Indicate if a network connection exists
    case SET_APP_ONLINE: {
      return {
        ...state,
        online: payload.value,
      };
    }
    case SET_API_CONFIGURATION: {
      return {
        ...state,
        apiConfiguration: payload.value,
      };
    }
    case SET_LANGUAGE_CODE: {
      return {
        ...state,
        languageCode: payload.value,
      };
    }

    case SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: payload.value,
      };
    }

    // Dynamic Data come from here //
    // Indicate if a user is logged-in
    case SET_SIGNED_IN: {
      return payload.value
        ? {
            ...state,
            signedIn: payload.value,
            userStatus: 'signed-in',
          }
        : {
            ...state,
            signedIn: payload.value,
            userStatus: 'signed-out',
          };
    }
    // Indicate if a user is logged-in
    case SET_DISCOVERY_MODE: {
      return payload.value
        ? {
            ...state,
            discovery: payload.value,
            userStatus: 'discovery',
          }
        : {
            ...state,
            discovery: payload.value,
            userStatus: 'signing-in',
          };
    }
    // Indicate the current user status
    // - choice
    // - discovery
    // - creating
    // - lost-password
    // - signing-up
    // - signing-in
    // - re-signing-in
    // - signed-in
    // - sign-out
    // - validating-tos
    case SET_USER_STATUS: {
      return {
        ...state,
        userStatus: payload.value,
      };
    }

    case SET_TOKENS: {
      return {
        ...state,
        tokens: payload.value,
      };
    }
    case SET_CONST_PROFILES_TYPES: {
      return {
        ...state,
        constProfilesTypes: payload.value,
      };
    }

    case ACCOUNT_CREATION_SET_SELECTED_CLUB: {
      return {
        ...state,
        selectedClub: payload.club,
        selectedClubTeams: payload.teams,
      };
    }
    case ACCOUNT_CREATION_SET_NEW_USER: {
      return {
        ...state,
        newUser: { ...state.newUser, ...payload.value },
      };
    }
    default:
      return state;
  }
};

export default appReducer;
