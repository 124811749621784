import { clubCategories } from '@apis/clubs';
import { MSG_SIMPLE_MESSAGE } from '@apis/message';
import { CONVOCATION_ANSWER_TYPE, NEW_CONVOCATION_TYPE } from '@apis/notification';

export const RAZ_CHAT = 'RAZ_CHAT';
export const SET_CHAT_ERROR = 'SET_CHAT_ERROR';
export const SET_CHAT_LOADING = 'SET_CHAT_LOADING';
export const SET_CHAT_NOT_LOADING = 'SET_CHAT_NOT_LOADING';
export const SET_MY_CONVERSATIONS = 'SET_MY_CONVERSATIONS';
export const SET_MY_CONVERSATION = 'SET_MY_CONVERSATION';
export const SET_MY_MESSAGES = 'SET_MY_MESSAGES';
export const RAZ_MY_MESSAGE = 'RAZ_MY_MESSAGE';
export const SET_MY_CONVOCATIONS = 'SET_MY_CONVOCATIONS';
export const SET_CONVOCATION_RESPONSES = 'SET_CONVOCATION_RESPONSES';
export const ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE';

export const DELETE_CONVERSATION = 'DELETE_CONVERSATION';
export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const ADD_NEW_MESSAGE_FROM_NOTIFICATION = 'ADD_NEW_MESSAGE_FROM_NOTIFICATION';
export const ADD_CONVOCATION_FROM_NOTIFICATION = 'ADD_CONVOCATION_FROM_NOTIFICATION';
export const ADD_CONVOCATION_REPLY_FROM_NOTIFICATION = 'ADD_CONVOCATION_REPLY_FROM_NOTIFICATION';

export const SELECT_INDIRECT_MEMBER = 'SELECT_INDIRECT_MEMBER';
export const UNSELECT_INDIRECT_MEMBER = 'UNSELECT_INDIRECT_MEMBER';
export const SELECT_MEMBER = 'SELECT_MEMBER';
export const UNSELECT_MEMBER = 'UNSELECT_MEMBER';
export const SELECT_PARTNER = 'SELECT_PARTNER';
export const UNSELECT_PARTNER = 'UNSELECT_PARTNER';

export const SELECT_TEAM = 'SELECT_TEAM';
export const UNSELECT_TEAM = 'UNSELECT_TEAM';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const UNSELECT_CATEGORY = 'UNSELECT_CATEGORY';

export const UPDATE_MEMBERS_ROLES = 'UPDATE_MEMBERS_ROLES';

const initialState = {
  loading: false,
  errorCode: '',

  pageNumber: 1,
  pageLimit: 10,

  myConversations: [],
  // myMessages: [],
  myConvocations: [],
  myConvocationResponses: [],
  hasMoreMessages: false,
  myConversation: null,
  myMessage: {
    messageType: MSG_SIMPLE_MESSAGE,
    presenceReplyType: '',
    content: '',
    filename: '',
    convocationId: '',
    title: '',
    convocationDate: '',
    convocationHour: '',
    convocationLatitude: 0.0,
    convocationLongitude: 0.0,
    convocationAddress: '',
  },

  // Members individually selected
  selectedPartners: [],
  selectedMembers: [],
  selectedTeams: [],
  selectedCategories: [],
  // Members of a selected category/team
  indirectMembers: [],
};

const debug = false;

const chatReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case 'RESET_FOR_LOGOUT':
      debug && console.debug('ChatReducer RESET_FOR_LOGOUT');
      return initialState;

    case RAZ_CHAT:
      return {
        ...initialState,
      };

    case SET_CHAT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SET_CHAT_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };

    case SET_CHAT_ERROR:
      return {
        ...state,
        errorCode: payload.errorCode,
      };

    case SET_MY_CONVERSATIONS:
      return {
        ...state,
        myConversations: payload.conversations,
      };

    case SET_MY_CONVERSATION:
      debug && console.debug('chatReducer SET_MY_CONVERSATION:', payload.conversation);

      return {
        ...state,
        myConversation: payload.conversation,
        hasMoreMessages: payload.hasMoreMessages ?? false,
        selectedMembers: [],
        indirectMembers: [],
        selectedTeams: [],
        selectedCategories: [],
      };

    case UPDATE_CONVERSATION:
      debug && console.debug('chatReducer UPDATE_CONVERSATION, conversation:', payload.conversation.id);

      if (state.myConversations.findIndex((item) => item.id === payload.conversation.id) >= 0) {
        if (state.myConversation?.id === payload.conversation.id) {
          debug && console.debug('chatReducer UPDATE_CONVERSATION, updated current conversation');

          return {
            ...state,
            myConversation: payload.conversation,
            myConversations: state.myConversations.map((item) => {
              if (item.id !== payload.conversation.id) {
                return item;
              }
              // todo: Whereas it should, it does not update myConversations :( !
              return payload.conversation;
            }),
          };
        }

        debug && console.debug('chatReducer UPDATE_CONVERSATION, updated conversation');
        return {
          ...state,
          myConversations: state.myConversations.map((item) => {
            if (item.id !== payload.conversation.id) {
              return item;
            }
            // todo: Whereas it should, it does not update myConversations :( !
            return payload.conversation;
          }),
        };
      }
      return state;

    case DELETE_CONVERSATION: {
      debug && console.debug('chatReducer DELETE_CONVERSATION, conversation:', payload.conversationId);

      const foundConversationIndex = state.myConversations.findIndex((item) => item.id === payload.conversationId);
      if (foundConversationIndex >= 0) {
        return {
          ...state,
          myConversations: [
            ...state.myConversations.slice(0, foundConversationIndex),
            ...state.myConversations.slice(foundConversationIndex + 1),
          ],
        };
      }
      return state;
    }

    case SET_MY_MESSAGES:
      debug && console.debug('chatReducer SET_MY_MESSAGES, messages:', payload.messages);

      return {
        ...state,
        // myMessages: payload.messages,
        myConversation: {
          ...state.myConversation,
          messages: payload.messages,
        },
        hasMoreMessages: payload.hasMoreMessages,
      };

    case RAZ_MY_MESSAGE:
      // messageType:
      //     public const SIMPLE_MESSAGE    = 1;
      //     public const CONVOCATION       = 2;
      //     public const CONVOCATION_REPLY = 3;
      // presenceReplyType:
      //     public const YES   = 1;
      //     public const NO    = 2;
      //     public const MAYBE = 3;
      return {
        ...state,
        myMessage: {
          messageType: 1,
          presenceReplyType: 0,
          title: '',
          content: '',
          filename: '',
          convocationId: '',
          convocationDate: '',
          convocationHour: '',
          convocationLatitude: 0.0,
          convocationLongitude: 0.0,
          convocationAddress: '',
        },
      };

    /**
     * Used to add a message in the current conversation
     */
    case ADD_NEW_MESSAGE:
      debug && console.debug('chatReducer ADD_NEW_MESSAGE, message:', payload.notificationType, payload.message);

      if (state.myConversation?.messages.some((message) => message.id === payload.message.id)) {
        return state;
      }

      if (payload.notificationType === NEW_CONVOCATION_TYPE) {
        debug && console.debug('chatReducer ADD_NEW_MESSAGE, adding convocation to the current conversation');
        return {
          ...state,
          myConversation: {
            ...state.myConversation,
            badgeCount: state.myConversation.badgeCount + 1,
            convocations: [...state.myConversation.convocations, payload.message],
          },
        };
      }

      debug && console.debug('chatReducer ADD_NEW_MESSAGE, adding message to the current conversation');
      return {
        ...state,
        myConversation: {
          ...state.myConversation,
          badgeCount: state.myConversation.badgeCount + 1,
          messages: [...state.myConversation.messages, payload.message],
        },
      };

    case ADD_NEW_MESSAGE_FROM_NOTIFICATION:
      debug &&
        console.debug(
          'chatReducer ADD_NEW_MESSAGE_FROM_NOTIFICATION, message:',
          payload.notificationType,
          payload.message
        );

      // Message is not for the current conversation
      if (state.myConversation?.id !== payload.message.Conversation_id) {
        return state;
      }

      if (payload.notificationType === CONVOCATION_ANSWER_TYPE) {
        debug && console.debug('chatReducer ADD_NEW_MESSAGE_FROM_NOTIFICATION, adding convocation reply');
        return {
          ...state,
          myConversation: {
            ...state.myConversation,
            convocations: state.myConversation.convocations.map((item) => {
              if (item.convocationId !== payload.message.targetId) {
                console.log('Return', item.convocationId);
                return item;
              }
              console.log('Found', item.responses);
              return { ...item, responses: [...item.responses, payload.message] };
            }),
          },
        };
      }

      if (payload.notificationType === NEW_CONVOCATION_TYPE) {
        debug &&
          console.debug(
            'chatReducer ADD_NEW_MESSAGE_FROM_NOTIFICATION, adding convocation to the current conversation'
          );
        return {
          ...state,
          myConversation: {
            ...state.myConversation,
            convocations: [...state.myConversation.convocations, payload.message],
          },
        };
      }

      debug &&
        console.debug('chatReducer ADD_NEW_MESSAGE_FROM_NOTIFICATION, adding message to the current conversation');
      return {
        ...state,
        myConversation: {
          ...state.myConversation,
          badgeCount: state.myConversation.badgeCount + 1,
          messages: [...state.myConversation.messages, payload.message],
        },
      };

    case DELETE_MESSAGE: {
      debug && console.debug('chatReducer DELETE_MESSAGE, remove:', payload.messageId);

      const messageIndex = state.myConversation.messages.findIndex((item) => item.id === payload.messageId);
      if (messageIndex >= 0) {
        debug && console.debug('chatReducer DELETE_MESSAGE, removed');
        return {
          ...state,
          // myMessages: [...state.myMessages.slice(0, messageIndex), ...state.myMessages.slice(messageIndex + 1)],
          myConversation: {
            ...state.myConversation,
            messages: [
              ...state.myConversation.messages.slice(0, messageIndex),
              ...state.myConversation.messages.slice(messageIndex + 1),
            ],
          },
        };
      }
      return state;
    }

    case SET_MY_CONVOCATIONS:
      return {
        ...state,
        myConvocations: payload.convocations,
      };

    case SET_CONVOCATION_RESPONSES:
      return {
        ...state,
        myConvocations: state.myConvocations.map((item) => {
          if (item.id !== payload.convocationId) {
            return item;
          }
          return { ...item, responses: payload.responses };
        }),
      };

    case SELECT_INDIRECT_MEMBER: {
      debug &&
        console.debug(
          'chatReducer SELECT_INDIRECT_MEMBER, member/team:',
          payload.category,
          payload.teamId,
          payload.member
        );

      // Team and its categories
      if (payload.teamId) {
        const memberIndex = state.indirectMembers.findIndex(
          (item) => item.uniqueId === `${payload.teamId}-${payload.category}-${payload.member.id}`
        );
        if (memberIndex < 0) {
          debug && console.debug('chatReducer SELECT_INDIRECT_MEMBER, team');
          return {
            ...state,
            indirectMembers: [
              ...state.indirectMembers,
              { ...payload.member, uniqueId: `${payload.teamId}-${payload.category}-${payload.member.id}` },
            ],
          };
        } else {
          return state;
        }
      }

      // Only a category
      if (payload.category) {
        const memberIndex = state.indirectMembers.findIndex(
          (item) => item.uniqueId === `0-${payload.category}-${payload.member.id}`
        );
        if (memberIndex < 0) {
          debug && console.debug('chatReducer SELECT_INDIRECT_MEMBER, category');
          return {
            ...state,
            indirectMembers: [
              ...state.indirectMembers,
              { ...payload.member, uniqueId: `0-${payload.category}-${payload.member.id}` },
            ],
          };
        } else {
          return state;
        }
      }

      // Only a member
      const memberIndex = state.indirectMembers.findIndex((item) => item.uniqueId === `0-_-${payload.member.id}`);
      if (memberIndex < 0) {
        return {
          ...state,
          indirectMembers: [
            ...(state.indirectMembers ?? []),
            { ...payload.member, uniqueId: `0-_-${payload.member.id}` },
          ],
        };
      }

      return state;
    }

    case UNSELECT_INDIRECT_MEMBER: {
      debug &&
        console.debug(
          'chatReducer UNSELECT_INDIRECT_MEMBER, member:',
          payload.category,
          payload.teamId,
          payload.member
        );

      // Team and its categories
      if (payload.teamId) {
        const memberIndex = state.indirectMembers.findIndex(
          (item) => item.uniqueId === `${payload.teamId}-${payload.category}-${payload.member.id}`
        );
        if (memberIndex >= 0) {
          debug && console.debug('chatReducer UNSELECT_INDIRECT_MEMBER, team');
          return {
            ...state,
            indirectMembers: [
              ...state.indirectMembers.slice(0, memberIndex),
              ...state.indirectMembers.slice(memberIndex + 1),
            ],
          };
        } else {
          return state;
        }
      }

      // Only a category
      if (payload.category) {
        const memberIndex = state.indirectMembers.findIndex(
          (item) => item.uniqueId === `0-${payload.category}-${payload.member.id}`
        );
        if (memberIndex >= 0) {
          debug && console.debug('chatReducer UNSELECT_INDIRECT_MEMBER, category');
          return {
            ...state,
            indirectMembers: [
              ...state.indirectMembers.slice(0, memberIndex),
              ...state.indirectMembers.slice(memberIndex + 1),
            ],
          };
        } else {
          return state;
        }
      }

      // Only a member
      const memberIndex = state.indirectMembers.findIndex((item) => item.uniqueId === `0-_-${payload.member.id}`);
      if (memberIndex >= 0) {
        return {
          ...state,
          indirectMembers: [
            ...state.indirectMembers.slice(0, memberIndex),
            ...state.indirectMembers.slice(memberIndex + 1),
          ],
        };
      }

      return state;
    }

    case SELECT_PARTNER: {
      debug && console.debug('chatReducer SELECT_PARTNER, partner:', payload.partner);

      const partnerIndex = state.selectedPartners.findIndex((item) => item.id === payload.partner.id);
      if (partnerIndex < 0) {
        return {
          ...state,
          selectedPartners: [...(state.selectedPartners ?? []), payload.partner],
        };
      }

      return state;
    }

    case UNSELECT_PARTNER: {
      debug && console.debug('chatReducer UNSELECT_PARTNER, member:', payload.partner);

      const memberIndex = state.selectedPartners.findIndex((item) => item.id === payload.partner.id);
      if (memberIndex >= 0) {
        return {
          ...state,
          selectedPartners: [
            ...state.selectedPartners.slice(0, memberIndex),
            ...state.selectedPartners.slice(memberIndex + 1),
          ],
        };
      }

      return state;
    }

    case SELECT_MEMBER: {
      debug && console.debug('chatReducer SELECT_MEMBER, member/team:', payload.member, payload.teamId);

      // Team and its categories
      if (payload.teamId) {
        const memberIndex = state.selectedMembers.findIndex(
          (item) => item.uniqueId === `${payload.teamId}-${payload.category}-${payload.member.id}`
        );
        if (memberIndex < 0) {
          debug && console.debug('chatReducer SELECT_MEMBER, team', payload.teamId);
          return {
            ...state,
            selectedMembers: [
              ...state.selectedMembers,
              { ...payload.member, uniqueId: `${payload.teamId}-${payload.category}-${payload.member.id}` },
            ],
          };
        } else {
          return state;
        }
      }

      // Only a category
      if (payload.category) {
        const memberIndex = state.selectedMembers.findIndex(
          (item) => item.uniqueId === `0-${payload.category}-${payload.member.id}`
        );
        if (memberIndex < 0) {
          debug && console.debug('chatReducer SELECT_MEMBER, category', payload.category);
          return {
            ...state,
            selectedMembers: [
              ...state.selectedMembers,
              { ...payload.member, uniqueId: `0-${payload.category}-${payload.member.id}` },
            ],
          };
        } else {
          return state;
        }
      }

      // Only a member
      const memberIndex = state.selectedMembers.findIndex((item) => item.uniqueId === `0-_-${payload.member.id}`);
      if (memberIndex < 0) {
        return {
          ...state,
          selectedMembers: [
            ...(state.selectedMembers ?? []),
            { ...payload.member, uniqueId: `0-_-${payload.member.id}` },
          ],
        };
      }

      return state;
    }

    case UNSELECT_MEMBER: {
      debug && console.debug('chatReducer UNSELECT_MEMBER, member:', payload.member);

      // Team and its categories
      if (payload.teamId) {
        const memberIndex = state.selectedMembers.findIndex(
          (item) => item.uniqueId === `${payload.teamId}-${payload.category}-${payload.member.id}`
        );
        if (memberIndex >= 0) {
          return {
            ...state,
            selectedMembers: [
              ...state.selectedMembers.slice(0, memberIndex),
              ...state.selectedMembers.slice(memberIndex + 1),
            ],
          };
        } else {
          return state;
        }
      }

      // Only a category
      if (payload.category) {
        const memberIndex = state.selectedMembers.findIndex(
          (item) => item.uniqueId === `0-${payload.category}-${payload.member.id}`
        );
        if (memberIndex >= 0) {
          debug && console.debug('chatReducer UNSELECT_MEMBER, category');
          return {
            ...state,
            selectedMembers: [
              ...state.selectedMembers.slice(0, memberIndex),
              ...state.selectedMembers.slice(memberIndex + 1),
            ],
          };
        } else {
          return state;
        }
      }

      // Only a member
      const memberIndex = state.selectedMembers.findIndex((item) => item.uniqueId === `0-_-${payload.member.id}`);
      if (memberIndex >= 0) {
        debug && console.debug('chatReducer UNSELECT_MEMBER');
        return {
          ...state,
          selectedMembers: [
            ...state.selectedMembers.slice(0, memberIndex),
            ...state.selectedMembers.slice(memberIndex + 1),
          ],
        };
      }

      return state;
    }

    case SELECT_TEAM: {
      debug && console.debug('chatReducer SELECT_TEAM, team:', payload.team.id, state.selectedTeams);

      const memberIndex = state.selectedTeams.findIndex((item) => item.id === payload.team.id);
      if (memberIndex < 0) {
        return {
          ...state,
          selectedTeams: [...(state.selectedTeams ?? []), payload.team],
        };
      }
      return state;
    }

    case UNSELECT_TEAM: {
      debug && console.debug('chatReducer UNSELECT_TEAM, team:', payload.teamId);

      const teamIndex = state.selectedTeams.findIndex((item) => item.id === payload.teamId);
      if (teamIndex >= 0) {
        debug && console.debug('chatReducer UNSELECT_TEAM, removed');
        return {
          ...state,
          selectedTeams: [...state.selectedTeams.slice(0, teamIndex), ...state.selectedTeams.slice(teamIndex + 1)],
        };
      }
      return state;
    }

    case SELECT_CATEGORY: {
      debug && console.debug('chatReducer SELECT_CATEGORY, category:', payload.category, state.selectedCategories);

      const categoryIndex = state.selectedCategories.findIndex((item) => item.title === payload.category);
      if (categoryIndex < 0) {
        return {
          ...state,
          selectedCategories: [
            ...(state.selectedCategories ?? []),
            clubCategories.filter((item) => item.title === payload.category)[0],
          ],
        };
      }
      return state;
    }

    case UNSELECT_CATEGORY: {
      debug && console.debug('chatReducer UNSELECT_CATEGORY, category:', payload.category);

      const categoryIndex = state.selectedCategories.findIndex((item) => item.title === payload.category);
      if (categoryIndex >= 0) {
        debug && console.debug('chatReducer UNSELECT_CATEGORY, removed');
        return {
          ...state,
          selectedCategories: [
            ...state.selectedCategories.slice(0, categoryIndex),
            ...state.selectedCategories.slice(categoryIndex + 1),
          ],
        };
      }
      return state;
    }

    case UPDATE_MEMBERS_ROLES:
      debug && console.debug('chatReducer UPDATE_MEMBERS_ROLES, value:', payload.membersRoles);

      return {
        ...state,
        // myMessages: [...state.myMessages.slice(0, messageIndex), ...state.myMessages.slice(messageIndex + 1)],
        myConversation: {
          ...state.myConversation,
          membersRoles: payload.membersRoles,
        },
      };

    default:
      return state;
  }
};

export default chatReducer;
