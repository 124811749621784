import { ClubsAPI } from '@apis/clubs';
import ButtonChoice from '@components/ButtonChoice/ButtonChoice';
import ButtonValidate from '@components/ButtonValidate/ButtonValidate';
import DismissKeyboard from '@components/DismissKeyboard';
import HeaderBanner from '@components/HeaderBanner/HeaderBanner';
import MapViewer from '@components/MapViewer/MapViewer';
import Colors from '@configs/colors';
import { GOOGLE } from '@configs/google';
import I18n from '@utils/i18n';
import * as Location from 'expo-location';
import React, { useEffect, useRef, useState } from 'react';
import {
  Dimensions,
  FlatList,
  Keyboard,
  Modal,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { useSelector } from 'react-redux';

const { width: sw, height: sh } = Dimensions.get('window');

const ASPECT_RATIO = sw / sh;
const LATITUDE_DELTA = 0.006339428281933124;
const LONGITUDE_DELTA = LATITUDE_DELTA * ASPECT_RATIO;
const DEFAULT_LATITUDE = 48.85838629870182;
const DEFAULT_LONGITUDE = 2.294496946047386;
// const DEFAULT_ZOOM = 7;

/**
 *
 * @param visible
 * @param fullAddress
 *    name, location, latitude, longitude
 * @param setAddress
 * @param onClose
 * @returns {JSX.Element|null}
 * @constructor
 */
const ChoosePlace = ({ visible, fullAddress = null, setAddress = () => {}, onClose = () => {} }) => {
  const debug = false;

  const refMap = useRef();

  const { session } = useSelector((state) => state.session);
  const { tokens } = useSelector((state) => state.app);

  const { affiliatedClubInformation } = useSelector((state) => state.session);
  const { composition: clubComposition } = useSelector((state) => state.club);

  const [pristine, setPristine] = useState(true);

  const [location, setLocation] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const [inputKey, setInputKey] = useState('');
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  // Address components
  const [addressName, setAddressName] = useState('');
  const [selectedAddress, setSelectedAddress] = useState(null);

  // Current position
  const [myLatitude, setMyLatitude] = useState(null);
  const [myLongitude, setMyLongitude] = useState(null);

  // Markers
  const [marker, setMarker] = useState(null);
  const [markers, setMarkers] = useState([]);

  // Address to provide
  const [yourAddress, setYourAddress] = useState(null);

  useEffect(() => {
    (async () => {
      const { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        setErrorMsg('Permission to access location was denied');
        return;
      }

      const fetchedLocation = await Location.getCurrentPositionAsync({});
      setLocation(fetchedLocation);
      setMyLatitude(fetchedLocation.coords.latitude);
      setMyLongitude(fetchedLocation.coords.longitude);
    })();
  }, []);

  useEffect(() => {
    if (!myLatitude || !myLongitude) {
      return;
    }
    debug && console.debug('ChoosePlace updated my position: ', myLatitude, myLongitude);
  }, [myLatitude, myLongitude]);

  useEffect(() => {
    debug && console.debug('ChoosePlace initialization: ', fullAddress);

    const club = affiliatedClubInformation ?? session.club ?? null;

    // Default are club address / location
    let theAddress = fullAddress;
    const pois = [];
    ClubsAPI.getClubDetail(tokens.wsToken, club.id).then((response) => {
      if (response && response.success) {
        debug && console.debug('ChoosePlace club gymnasiums', response.result.gymnasiums);

        if (response.result.gymnasiums.length > 0) {
          for (const poi of response.result.gymnasiums) {
            pois.push({
              id: poi.id,
              name: poi.name,
              description: poi.address,
              latitude: parseFloat(poi.latitude) || DEFAULT_LATITUDE,
              longitude: parseFloat(poi.longitude) || DEFAULT_LONGITUDE,
            });
            if (pois.length >= 3) {
              break;
            }
          }
        }
        setMarkers(pois);
      }

      if (!fullAddress) {
        // Priority, 1st gymnasium
        if (pois.length > 0) {
          theAddress = pois[0];
        } else {
          // Else, the club name and address with the current location
          theAddress = {
            name: response.result.name,
            description: `${clubComposition.address}, ${clubComposition.postalCode} ${clubComposition.city}`,
            latitude: myLatitude,
            longitude: myLongitude,
          };
        }
      } else {
        theAddress = {
          name: fullAddress.name || 'Nom du lieu',
          description: fullAddress.address || '',
          latitude: fullAddress.latitude || myLatitude,
          longitude: fullAddress.longitude || myLongitude,
        };
      }
      theAddress['marker'] = true;
      setSelectedAddress(theAddress);
      setAddressName(theAddress.name);

      setYourAddress({ ...theAddress, name: theAddress.name });

      debug && console.debug('ChoosePlace updated address:', theAddress);
    });
  }, []);

  useEffect(() => {
    if (!selectedAddress || !selectedAddress.description) {
      return undefined;
    }
    debug && console.debug('ChoosePlace selectedAddress updated: ', selectedAddress);

    if (selectedAddress.latitude && selectedAddress.longitude) {
      setYourAddress({ ...selectedAddress, name: addressName });
    } else {
      fetch(`${GOOGLE.API_GEOCODE_URL}/json?key=${GOOGLE.API_KEY}&address=${selectedAddress.description}`, {
        method: 'GET',
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error(response.status.toString());
        })
        .then((resGeocode) => {
          debug && console.debug('ChoosePlace geo coded address: ', resGeocode);

          if (resGeocode.status === 'OK' && resGeocode.results.length > 0) {
            const theAddress = {
              name: addressName,
              description: selectedAddress.description,
              latitude: resGeocode.results[0].geometry.location.lat,
              longitude: resGeocode.results[0].geometry.location.lng,
            };
            setYourAddress({ ...theAddress, name: addressName });

            debug && console.debug('ChoosePlace new address:', theAddress);
          }
        });
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (!addressName || addressName === '') {
      return undefined;
    }
    debug && console.debug('ChoosePlace new address name: ', addressName);

    setYourAddress({ ...yourAddress, name: addressName });
  }, [addressName]);

  useEffect(() => {
    if (!yourAddress || !refMap || !refMap.current) {
      return undefined;
    }
    debug && console.debug('ChoosePlace new chosen address: ', yourAddress);

    setMarker(yourAddress);

    refMap &&
      refMap.current.animateToRegion(
        {
          latitude: yourAddress.latitude,
          longitude: yourAddress.longitude,
          latitudeDelta: LATITUDE_DELTA,
          longitudeDelta: LONGITUDE_DELTA,
        },
        1000
      );
  }, [yourAddress]);

  const requestAutoComplete = async (text) => {
    debug && console.debug('ChoosePlace requestAutoComplete', text);

    //
    // Search a location around me!
    //
    const url = `${GOOGLE.API_AUTOCOMPLETE_URL}/json?key=${GOOGLE.API_KEY}&input=${text}&language=${GOOGLE.PREFERRED_LANGUAGE}&location=${myLatitude},${myLongitude}&radius=50000`;
    const predictions = await (await fetch(url, { method: 'GET' })).json();
    debug && console.debug('ChoosePlace predictions', predictions);
    setAutoCompleteResult(predictions.predictions);

    //
    // Search France and all DOM-TOM!
    //
    // // fr: France, gp: Guadeloupe, mq: Martinique, re: Réunion: pm: St Pierre
    // // gf: Guyane
    // // yt: Mayotte, nc: Nouvelle Calédonie, pf: Polynésie, mf: St Martin, tf: terres du Sud
    // const url = `${GOOGLE.API_AUTOCOMPLETE_URL}/json?key=${GOOGLE.API_KEY}&input=${text}&language=${GOOGLE.PREFERRED_LANGUAGE}&components=country:fr|country:gp|country:mq|country:re|country:pm`;
    // const urlGF = `${GOOGLE.API_AUTOCOMPLETE_URL}/json?key=${GOOGLE.API_KEY}&input=${text}&language=${GOOGLE.PREFERRED_LANGUAGE}&components=country:gf`;
    // const url2 = `${GOOGLE.API_AUTOCOMPLETE_URL}/json?key=${GOOGLE.API_KEY}&input=${text}&language=${GOOGLE.PREFERRED_LANGUAGE}&components=country:yt|country:nc|country:pf|country:mf|country:tf`;
    //
    // const prediction1 = await (await fetch(url, { method: 'GET' })).json();
    // debug && console.debug('ChoosePlace predictions 1', prediction1);
    //
    // const predictionGF = await (await fetch(urlGF, { method: 'GET' })).json();
    // debug && console.debug('ChoosePlace predictions GF', predictionGF);
    //
    // const prediction2 = await (await fetch(url2, { method: 'GET' })).json();
    // debug && console.debug('ChoosePlace predictions 2', prediction2);
    //
    // const allPredictions = [];
    // if (prediction1.predictions && prediction1.predictions.length > 0) {
    //   allPredictions.splice(allPredictions.length - 1, 0, ...prediction1.predictions);
    // }
    // if (predictionGF.predictions && predictionGF.predictions.length > 0) {
    //   allPredictions.splice(allPredictions.length - 1, 0, ...predictionGF.predictions);
    // }
    // if (prediction2.predictions && prediction2.predictions.length > 0) {
    //   allPredictions.splice(allPredictions.length - 1, 0, ...prediction2.predictions);
    // }
    // setAutoCompleteResult(allPredictions);
  };

  // const geocodeAddress = async (address) => {
  //   const url = `${GOOGLE.API_GEOCODE_URL}/json?key=${GOOGLE.API_KEY}&address=${address}`;
  //   const resGeocode = await (
  //     await fetch(url, {
  //       method: 'GET',
  //     })
  //   ).json();
  //
  //   if (resGeocode.status === 'OK' && resGeocode.results.length > 0) {
  //     const coordinates = {
  //       latitude: resGeocode.results[0].geometry.location.lat,
  //       longitude: resGeocode.results[0].geometry.location.lng,
  //     };
  //     const marker = {
  //       latitude: resGeocode.results[0].geometry.location.lat,
  //       longitude: resGeocode.results[0].geometry.location.lng,
  //       title: 'Your address',
  //       address,
  //     };
  //
  //     setYourAddress(marker);
  //   }
  // };

  const reverseGeocodeAddress = async (lat, lng) => {
    fetch(`${GOOGLE.API_GEOCODE_URL}/json?key=${GOOGLE.API_KEY}&latlng=${lat},${lng}&result_type=street_address`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(response.status.toString());
      })
      .then((resGeocode) => {
        debug && console.debug('ChoosePlace geo coded address: ', resGeocode);

        if (resGeocode.status === 'OK' && resGeocode.results.length > 0) {
          const allPredictions = [];
          resGeocode.results.map(async (address, index) => {
            debug && console.debug('ChoosePlace reverseGeocodeAddress', address);

            allPredictions.push({
              id: `Address-${index}`,
              place_id: address.place_id,
              name: address.formatted_address,
              description: address.formatted_address,
              latitude: address.geometry.location.lat,
              longitude: address.geometry.location.lng,
            });
          });
          setAutoCompleteResult(allPredictions);
        }
      });
  };

  if (!selectedAddress || !visible) {
    return null;
  }

  return (
    <Modal visible={visible} transparent animationType={'fade'}>
      {/* header */}
      <HeaderBanner leftIcon="md-close" onLeftButton={() => onClose()} title="Choisissez un lieu" />

      <View style={[styles.mainContainer]}>
        <View style={[styles.inputContainer]}>
          <View style={[styles.inputWithIcon]}>
            <TextInput
              autoFocus
              placeholder="Nom du lieu"
              value={addressName}
              onChangeText={(text) => {
                if (text && text.trim() !== '') {
                  setAddressName(text);
                  setPristine(false);
                }
              }}
              style={[styles.inputText, { color: Colors.blueBgGradient1, fontFamily: 'appFontRegular', width: '100%' }]}
              placeholderTextColor={Colors.blueBgGradient1 + '55'}
            />

            <Pressable onPress={() => setAddressName('')} style={[styles.searchButton]}>
              <Icon size={25} name="md-close" color={Colors.black} />
            </Pressable>
          </View>
          <View style={[styles.inputWithIcon]}>
            <TextInput
              placeholder="Adresse du lieu"
              value={inputKey}
              onChangeText={(text) => {
                setInputKey(text);
                if (text && text.trim() !== '') {
                  requestAutoComplete(text);
                } else {
                  setAutoCompleteResult([]);
                }
              }}
              style={[styles.inputText, { color: Colors.blueBgGradient1, fontFamily: 'appFontRegular', width: '100%' }]}
              placeholderTextColor={Colors.blueBgGradient1 + '55'}
            />

            <Pressable onPress={() => setInputKey('') && setAutoCompleteResult([])} style={[styles.searchButton]}>
              <Icon size={25} name="md-close" color={Colors.black} />
            </Pressable>
          </View>
        </View>

        <DismissKeyboard style={{ flex: 1 }}>
          <View style={styles.autocompleteContainer}>
            <FlatList
              data={autoCompleteResult}
              renderItem={({ item, index }) => (
                <Pressable
                  key={'Result' + index}
                  style={{
                    backgroundColor: Colors.blueCorporate,
                    borderBottomWidth: 0.5,
                    borderBottomColor: Colors.gray,
                    padding: 10,
                  }}
                  onPress={() => {
                    debug && console.debug('ChoosePlace selected address', item);

                    Keyboard.dismiss();
                    const addressName = item.structured_formatting && item.structured_formatting.main_text;
                    setAddressName(addressName);
                    setInputKey(item.description);
                    setSelectedAddress({ ...item, name: addressName });
                    setAutoCompleteResult([]);
                  }}>
                  <Text style={{ color: Colors.whiteCorporate, fontFamily: 'appFontMedium', fontSize: 12 }}>
                    {item.description}
                  </Text>
                </Pressable>
              )}
            />
          </View>

          {markers && markers.length > 0 && (
            <View style={styles.poisBar}>
              {markers.map((marker, index) => {
                return (
                  <ButtonChoice
                    key={index}
                    label={marker.name || marker.description}
                    style={{ justifyContent: 'center', width: (sw - 40 - 20 * markers.length) / markers.length }}
                    onPress={() => {
                      debug && console.debug('ChoosePlace selected gymnasium', marker);

                      Keyboard.dismiss();
                      // pristine && setAddressName(marker.name);
                      setAddressName(marker.name);
                      setSelectedAddress(marker);
                      setYourAddress(marker);
                      // setAddress(yourAddress);
                      // onClose(marker);
                    }}
                  />
                );
              })}
            </View>
          )}

          <MapViewer
            ref={refMap}
            address={selectedAddress}
            marker={marker}
            markers={markers}
            onPress={(latitude, longitude) => {
              reverseGeocodeAddress(latitude, longitude);
            }}
            onLongPress={(latitude, longitude) => {
              reverseGeocodeAddress(latitude, longitude);
            }}
            style={{ height: sh / 2 }}
          />

          <View style={[styles.buttonContainer]}>
            <ButtonValidate
              style={{ width: sw - 120 }}
              label={I18n.t('place.add')}
              onPress={() => {
                debug && console.debug('ChoosePlace provided address', selectedAddress);
                setAddress(yourAddress);
                onClose(selectedAddress);
              }}
            />
          </View>
        </DismissKeyboard>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    padding: 20,
    backgroundColor: Colors.gray,
  },

  inputContainer: {
    // position: 'absolute',
    // top: 0,
    // left: 0,
    width: '100%',
    // backgroundColor: Colors.black,
    padding: 5,
  },
  inputWithIcon: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: 40,
    marginVertical: 5,
    borderRadius: 100,
    backgroundColor: Colors.whiteCorporate,
  },
  inputText: {
    flex: 1,
    paddingHorizontal: 15,
  },

  poisBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginVertical: 0,
    paddingHorizontal: 20,
    borderBottomColor: Colors.whiteCorporate,
    borderBottomWidth: 1,
    borderTopColor: Colors.whiteCorporate,
    borderTopWidth: 1,
  },

  autocompleteContainer: {
    backgroundColor: Colors.white + '00',
    paddingTop: Platform.OS === 'ios' ? 2 : 21,
    width: sw - 40,
    maxHeight: sh / 2,
    left: 10,
  },

  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

// export default ChoosePlace
export default ChoosePlace;
