import resultAPI from '@apis/result';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import HeaderCollapsible from '@components/HeaderCollapsible/HeaderCollapsible';
import Loading from '@components/Loading/Loading';
import Colors from '@configs/colors';
import * as actions from '@redux/actions';
import React, { Component } from 'react';
import { Dimensions, Pressable, StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { connect } from 'react-redux';

const { width } = Dimensions.get('window');

class ResultsFavorites extends Component {
  debug = false;

  constructor(props) {
    super(props);

    this.debug && console.debug(`ResultsFavorites, props`, props);
    Object.keys(props).map((key, value) => {
      this.debug && console.debug(`- prop '${key}':`, props[key]);
    });

    this.state = {
      currentClub: this.props.affiliatedClubInformation ?? this.props.session.club ?? null,

      loading: false,

      showAlert: false,
      alertTitle: '',
      alertMessage: '',

      listLiked: [],
    };
  }

  componentDidMount() {
    this.getFavoriteCompetitions().then(() => {
      // Map the redux action!
      this.setState({ loading: false });
    });

    // BackHandler.addEventListener('hardwareBackPress', () => {
    //   this.props.navigation.goBack();
    //   return true;
    // });
  }

  getFavoriteCompetitions = async () => {
    this.debug && console.debug(`ResultsWebView, getting favorites...`);

    this.setState({ loading: true });
    resultAPI.getFavoriteCompetitions(this.props.tokens.wsToken, '', 0).then((listLiked) => {
      if (listLiked?.success) {
        this.debug && console.debug(`ResultsWebView, got:`, listLiked);

        this.setState({ listLiked: listLiked.result });
      } else if (listLiked.error_code === 10) {
        // Session expired redirect to Login
        console.warn('Session expired!');
      }
      this.setState({ loading: false });
    });
  };

  likeDislikeCompetition = async (competition) => {
    await resultAPI.likeDislikeCompetition(
      this.props.tokens.wsToken,
      competition.competitionId,
      competition.competitionName,
      competition.competitionType
    );

    const newListLiked = [...this.state.listLiked];
    const foundInLiked = await this.state.listLiked.find((eventId, index) => {
      return competition === eventId;
    });
    if (foundInLiked === undefined || foundInLiked === null) {
      newListLiked.push('' + competition.competitionId + '');
    } else {
      this.state.listLiked.map((likedId, indexLiked) => {
        if (competition === likedId) {
          newListLiked.splice(indexLiked, 1);
        }
      });
    }

    this.setState({ listLiked: newListLiked });
  };

  backSubHeader = () => {
    return (
      <View style={styles.backSubHeader}>
        <Pressable style={styles.backBar} onPress={() => this.props.navigation.goBack()}>
          <Icon style={styles.backIcon} size={25} name="md-arrow-back" color={Colors.blueCorporate} />
          <Text style={styles.backText}>Retour au choix des compétitions</Text>
        </Pressable>
      </View>
    );
  };

  showFavorites = () => {
    if (!this.props.favoriteResults || this.props.favoriteResults.length < 1) {
      return (
        <View style={{ paddingTop: 150, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ color: Colors.blueCorporate, alignSelf: 'center', fontFamily: 'appFontRegular' }}>
            Vous n'avez pas encore de favoris.
          </Text>
        </View>
      );
    }

    return this.props.favoriteResults.map((item, id) => {
      return (
        <View
          key={id}
          style={{
            justifyContent: 'space-between',
            flexDirection: 'row',
            height: 60,
            backgroundColor: id % 2 === 0 ? null : Colors.whiteCorporate,
          }}>
          <Pressable
            style={otherStyles.containerImage}
            onPress={() => {
              const updatedData = this.props.favoriteResults.filter(
                (itema) => itema?.currentPageTitle !== item.currentPageTitle
              );
              this.props.updateFavoriteLink(updatedData);
            }}>
            <Icon size={25} name="heart" color={Colors.blueCorporate} />
          </Pressable>
          <Pressable
            style={otherStyles.containerName}
            onPress={() => {
              this.props.navigation.navigate('ListingUrl', {
                title: item.currentPageTitle,
                url: item.currentPageUrl,
              });
            }}>
            <View style={{ width: '85%', alignSelf: 'center' }}>
              <Text style={{ color: Colors.blueCorporate, fontWeight: 'bold' }}>{item.currentPageTitle}</Text>
            </View>
            <View style={{ width: '5%', alignSelf: 'center' }}>
              <Icon name="md-arrow-forward" color={Colors.red} size={25} />
            </View>
          </Pressable>
        </View>
      );
    });
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        <HeaderCollapsible
          navigation={this.props.navigation}
          route={this.props.route}
          headerTitle={this.props.route.params.title}
          headerScrollView={<this.backSubHeader />}
          contentScrollView={[<this.showFavorites />]}
        />

        <Loading loading={this.state.loading} />

        <AlerteUI
          showAlert={this.state.showAlert}
          onClose={() => {
            this.setState({ showAlert: false });
          }}
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          buttons={this.state.alertButtons}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  backSubHeader: {
    width,
    height: 60,
    alignItems: 'center',
    zIndex: 99999,
  },
  backBar: {
    flex: 1,
    width,
    height: 50,
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'left',
    alignSelf: 'flex-end',
  },
  backIcon: {
    alignItems: 'center',
    marginLeft: 20,
    height: 25,
  },
  backText: {
    fontFamily: 'appFontMedium',
    fontSize: 18,
    // alignItems: 'left',
    height: 30,
    padding: 5,
  },
  titleText: {
    fontFamily: 'appFontRegular',
    fontSize: 16,
    alignItems: 'center',
    height: 20,
    padding: 5,
    paddingTop: 0,
  },

  iframe: {
    width: 800,
    height: 600,
  },
});

const otherStyles = {
  containerSearch: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: Colors.grayWhite,
    width: '100%',
    alignItems: 'center',
    padding: 10,
    paddingTop: 0,
    paddingBottom: 0,
    borderBottomColor: Colors.gray,
    borderBottomWidth: 2,
  },
  containerImage: {
    width: '15%',
    alignSelf: 'center',
    padding: 5,
    marginLeft: 15,
  },
  containerName: {
    flex: 1,
    alignSelf: 'center',
    padding: 5,
    flexDirection: 'row',
    // marginLeft: 30
  },
};

const mapStateToProps = (state) => {
  const { favoriteResults } = state.favorites;
  const { tokens } = state.app;
  const { session, affiliatedClubInformation } = state.session;
  return {
    tokens,
    session,
    favoriteResults,
    affiliatedClubInformation,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addFavoriteLink: (resultFavorite) => dispatch(actions.addFavoriteLink(resultFavorite)),
    updateFavoriteLink: (favoriteResults) => dispatch(actions.updateFavoriteLink(favoriteResults)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResultsFavorites);
