import { ClubsAPI } from '@apis/clubs';
import ButtonValidate from '@components/ButtonValidate/ButtonValidate';
import CardUser from '@components/CardUser/CardUser';
import ChoosePlace from '@components/ChoosePlace/ChoosePlace';
import DateTimePicker from '@components/DateTimePicker/DateTimePicker';
import HeaderBanner from '@components/HeaderBanner/HeaderBanner';
import Colors from '@configs/colors';
import { gMaxWidth } from '@configs/styles';
import { sendConvocation } from '@redux/actions';
import alert from '@utils/alert';
import I18n from '@utils/i18n';
import { useDimensions } from '@utils/useDimensions';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Dimensions,
  FlatList,
  KeyboardAvoidingView,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

const { width: sw, height: sh } = Dimensions.get('window');

const CreateConvocation = ({ navigation, route }) => {
  const debug = false;

  const { width } = useDimensions().window;
  const [numColumns, setNumColumns] = useState(1);
  useEffect(() => {
    setNumColumns(width >= 768 ? 2 : 1);
  }, [width]);

  const { tokens } = useSelector((state) => state.app);
  const { session, affiliatedClubInformation } = useSelector((state) => state.session);
  const { myConversation } = useSelector((state) => state.chat);

  const [currentClub, setCurrentClub] = useState(null);

  const dispatch = useDispatch();

  const [chatId, setchatId] = useState(null);

  const theDate = moment().add(1, 'days');
  const [date, setDate] = useState(theDate.format('DD/MM/YYYY'));
  const [selectedDate, setSelectedDate] = useState(theDate.toDate());
  const [valueDate, setValueDate] = useState(theDate.format('YYYY-MM-DD'));
  const [showDatePicker, setShowDatePicker] = useState(false);

  const theTime = moment();
  const [time, setTime] = useState(theTime.format('LT'));
  const [selectedTime, setSelectedTime] = useState(theTime.toDate());
  const [valueTime, setValueTime] = useState(theTime.format('HH:mm'));
  const [showTimePicker, setShowTimePicker] = useState(false);

  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);

  const [address, setAddress] = useState(null);
  const [fullAddress, setFullAddress] = useState(null);
  const [addressLatitude, setAddressLatitude] = useState(null);
  const [addressLongitude, setAddressLongitude] = useState(null);
  const [showPlace, setShowPlace] = useState(false);

  const [called, setCalled] = useState([]);
  const [countCalled, setCountCalled] = useState(0);

  const [textColor, setTextColor] = useState(Colors.black);

  useEffect(() => {
    debug && console.debug('CreateConvocation route:', route);
    if (route && route.params.chatId) {
      debug && console.debug('CreateConvocation for conversation:', route.params.chatId);
      setchatId(route.params.chatId);

      // As per default, all participants are called, except me
      setCalled(
        myConversation.participants.map((item) => {
          if (item.id === session.user.id) {
            return { ...item, selected: false };
          }
          // Only players are selected as called members
          if (item.category !== 'players') {
            return { ...item, selected: false };
          }
          return { ...item, selected: true };
        })
      );
    }
  }, []);

  useEffect(() => {
    debug && console.debug('CreateConvocation update current club');

    const club = affiliatedClubInformation ?? session.club ?? null;

    setCurrentClub(club);
    setTextColor((club && club.mainColorBottom) ?? Colors.gray);

    ClubsAPI.getClubDetail(tokens.wsToken, club.id).then((response) => {
      if (response && response.success) {
        debug && console.debug('CreateConvocation club gymnasiums', response.result.gymnasiums);
        if (response.result.gymnasiums.length > 0) {
          const place = response.result.gymnasiums[0];
          setAddressLatitude(place.latitude);
          setAddressLongitude(place.longitude);
          setAddress(place.name);
          setFullAddress({
            name: place.name,
            description: place.address,
            latitude: parseFloat(place.latitude),
            longitude: parseFloat(place.longitude),
          });
        }
      }
    });

    debug && console.debug('CreateConvocation current club updated', club);
  }, [session, affiliatedClubInformation]);

  useEffect(() => {
    setCountCalled(called.filter((item) => item.selected === true).length);
  }, [called]);

  const addConvocation = () => {
    // Test des champs
    if (date === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidDate'));
      return;
    }

    if (moment(date, 'DD/MM/YYYY').isSameOrBefore(moment())) {
      alert(
        I18n.t('app.oups'),
        I18n.t('app.invalidDate') + '. La date indiquée pour la convocation ne doit pas être passée.'
      );
    }

    if (time === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidTime'));
      return;
    }

    if (!title) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidTitle'));
      return;
    }

    if (!description) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidDescription'));
      return;
    }

    if (!address || address === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAddress'));
      return;
    }

    debug &&
      console.debug(
        'CreateConvocation sendConvocation:',
        chatId,
        date,
        time,
        title,
        description,
        address,
        addressLatitude,
        addressLongitude,
        called
      );

    dispatch(
      sendConvocation(
        tokens.wsToken,
        chatId,
        {
          date,
          time,
          title,
          description,
          address,
          latitude: addressLatitude,
          longitude: addressLongitude,
          called: called.filter((member) => member.selected).map((member) => member.id),
        },
        (success) => {
          if (!success) {
            alert(I18n.t('app.oups'), "Problème rencontré lors de l'enregistrement de la convocation!");
          }
          navigation.goBack();
        }
      )
    );
  };

  const onSelectMember = (member) => {
    debug && console.debug(`CreateConvocation, onSelectMember`, member);
    debug && console.debug(`CreateConvocation, called`, called);

    setCalled(
      called.map((item) => {
        if (item.id === member.id) {
          return { ...item, selected: !item.selected };
        }
        return item;
      })
    );
  };

  if (!chatId) {
    return;
  }

  return (
    <>
      <HeaderBanner
        leftIcon="md-arrow-back"
        onLeftButton={() => {
          navigation.goBack();
        }}
        title={I18n.t('message.CreateConvocation')}
      />

      <ScrollView
        scrollEventThrottle={0}
        style={{ flex: 1, width: Platform.OS === 'web' ? gMaxWidth : sw }}
        contentContainerStyle={{
          paddingBottom: Platform.OS === 'android' ? 30 : 0,
        }}
        alwaysBounceVertical={false}
        bounces={false}>
        <View style={styles.formContainer}>
          {/* Date and time */}
          {Platform.OS !== 'web' ? (
            <>
              <Pressable onPress={() => setShowDatePicker(true)} style={styles.dateTime}>
                <Text
                  style={{
                    fontFamily: 'appFontRegular',
                    color: date ? Colors.blueCorporate : Colors.blueCorporate + '55',
                  }}>
                  {date ?? I18n.t('convocation.date')}
                </Text>
              </Pressable>

              <DateTimePicker
                mode="date"
                visible={showDatePicker}
                value={selectedDate}
                onClose={() => {
                  setShowDatePicker(false);
                }}
                onChange={(selectedDate) => {
                  setSelectedDate(selectedDate);
                  setDate(moment(selectedDate).format('DD/MM/YYYY'));
                }}
              />
            </>
          ) : (
            <>
              <input
                type="date"
                style={styles.dateTime}
                value={valueDate}
                min={moment().format('YYYY-MM-DD')}
                onInput={(event) => {
                  const selectedDate = event.target.value;
                  setSelectedDate(selectedDate);
                  setDate(moment(selectedDate).format('DD/MM/YYYY'));
                  setValueDate(moment(selectedDate).format('YYYY-MM-DD'));
                }}
              />
            </>
          )}

          {Platform.OS !== 'web' ? (
            <>
              <Pressable onPress={() => setShowTimePicker(true)} style={styles.dateTime}>
                <Text
                  style={{
                    fontFamily: 'appFontRegular',
                    color: time ? Colors.blueCorporate : Colors.blueCorporate + '55',
                  }}>
                  {time ?? I18n.t('convocation.hour')}
                </Text>
              </Pressable>

              <DateTimePicker
                mode="time"
                visible={showTimePicker}
                value={selectedTime}
                onClose={() => {
                  setShowTimePicker(false);
                }}
                onChange={(selectedTime) => {
                  setSelectedTime(selectedTime);
                  setTime(moment(selectedTime).format('LT'));
                }}
              />
            </>
          ) : (
            <>
              <input
                type="time"
                style={styles.dateTime}
                value={valueTime}
                // step={5}
                onInput={(event) => {
                  const selectedTime = event.target.value;
                  setTime(selectedTime);
                  setSelectedTime(selectedTime);
                  setValueTime(selectedTime);
                }}
              />
            </>
          )}

          <Pressable style={styles.selectionButton} onPress={() => setShowPlace(true)}>
            <Text
              style={[styles.appFontRegular, styles.redText, { marginLeft: 5, marginRight: 5 }]}
              numberOfLines={1}
              ellipsizeMode="tail">
              {fullAddress
                ? fullAddress.name || fullAddress.description || fullAddress.formatted_address
                : I18n.t('myAccount.address')}
            </Text>
          </Pressable>

          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            keyboardVerticalOffset={Platform.OS === 'ios' ? 50 : 80}>
            <TextInput
              style={styles.textInput}
              placeholderTextColor={Colors.blueBgGradient1 + '55'}
              placeholder={I18n.t('convocation.title')}
              onChangeText={(text) => {
                setTitle(text);
              }}
            />

            <TextInput
              style={styles.textInputMultiline}
              placeholderTextColor={Colors.blueBgGradient1 + '55'}
              placeholder={I18n.t('convocation.description')}
              multiline
              numberOfLines={6}
              onChangeText={(text) => {
                setDescription(text);
              }}
            />
          </KeyboardAvoidingView>
        </View>

        <View style={styles.formContainer}>
          <View style={{ borderTopColor: textColor, borderTopWidth: 3 }} />

          <Text style={[styles.titleText, { color: textColor }]}>
            {I18n.t('message.countParticipants', { users: called.length })} -{' '}
            {I18n.t('message.countCalled', { users: countCalled })}
          </Text>

          <ScrollView style={{ marginTop: 10, marginBottom: 10 }}>
            <FlatList
              data={called}
              key={numColumns}
              numColumns={numColumns}
              keyExtractor={(item, index) => `Called-${item.id}-${index}`}
              renderItem={({ item, index }) => (
                <CardUser
                  key={`Called-${item.id}-${index}`}
                  style={{ marginLeft: 0 }}
                  club={currentClub}
                  selectable
                  onSelectMember={(item) => {
                    onSelectMember(item);
                  }}
                  member={item}
                />
              )}
            />
            <View style={{ borderBottomColor: textColor, borderBottomWidth: 3 }} />
          </ScrollView>
        </View>

        <ButtonValidate
          style={{
            marginTop: 5,
            marginBottom: 20,
            alignSelf: 'center',
            width: (Platform.OS === 'web' ? gMaxWidth : sw) - 80,
            minWidth: 120,
          }}
          label={I18n.t('convocation.send')}
          onPress={() => {
            addConvocation();
          }}
        />

        <View style={{ height: Platform.OS === 'android' ? 30 : 60 }} />
      </ScrollView>

      <ChoosePlace
        visible={showPlace}
        fullAddress={fullAddress}
        setAddress={(address) => {
          setFullAddress(address);
          setAddress(address.name || address.description);
        }}
        onClose={(address) => {
          if (address) {
            setFullAddress(address);
            setAddress(address.name || address.description);
          }
          setShowPlace(false);
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  formContainer: {
    padding: 10,
    alignItems: 'center',
  },
  selectionButton: {
    width: (sw - 40) / 2,
    height: 40,
    marginVertical: 20,
    marginHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.whiteCorporate,
    borderRadius: 10,
    elevation: 3,
    overflow: 'visible',
    shadowColor: Colors.iconGray + '77',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.8,
    shadowRadius: 2,
  },
  textInput: {
    width: (Platform.OS === 'web' ? gMaxWidth : sw) - 40,
    borderRadius: 10,
    paddingHorizontal: 15,
    marginVertical: 5,
    marginHorizontal: 20,
    backgroundColor: Colors.whiteCorporate,
    height: 32,
    color: Colors.blueBgGradient1,
    fontFamily: 'appFontRegular',
  },
  textInputMultiline: {
    width: (Platform.OS === 'web' ? gMaxWidth : sw) - 40,
    borderRadius: 10,
    paddingHorizontal: 15,
    marginHorizontal: 20,
    marginVertical: 5,
    backgroundColor: Colors.whiteCorporate,
    color: Colors.blueBgGradient1,
    fontFamily: 'appFontRegular',
    height: 120,
    justifyContent: 'flex-start',
    textAlignVertical: 'top',
    paddingTop: 10,
    paddingBottom: 10,
    lineHeight: 16,
  },
  dateTime: {
    width: (Platform.OS === 'web' ? gMaxWidth : sw) - 40,
    height: 32,
    backgroundColor: Colors.whiteCorporate,
    borderRadius: 10,
    paddingHorizontal: 15,
    marginVertical: 5,
    marginHorizontal: 20,
    justifyContent: 'center',
  },
  appFontRegular: {
    fontFamily: 'appFontRegular',
  },
  redText: {
    color: Colors.red,
  },
});

export default CreateConvocation;
