const Images = {
  loadingSpinner: require('@assets/images/spinner-64.gif'),

  imagePlaceholder: require('@assets/images/placeholder_image.png'),
  teamPlaceholder: require('@assets/defaultTeam.jpg'),
  userPlaceholder: require('@assets/images/placeholder_user.png'),
  imagePlaceholderRounded: require('@assets/images/placeholder_logo_rounded.png'),

  videoLogo: require('@assets/images/video_logo.png'),
};

export default Images;
