import { ShopAPI } from '@apis/shop';
import Images from '@assets/images';
import ButtonBase from '@components/ButtonBase/ButtonBase';
import HeaderBanner from '@components/HeaderBanner/HeaderBanner';
import ShadowCard from '@components/ShadowCard/ShadowCard';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import { gMaxWidth } from '@configs/styles';
import { openUrl } from '@utils/openLinks';
import React, { Component } from 'react';
import {
  ActivityIndicator,
  BackHandler,
  Dimensions,
  Image,
  Platform,
  Pressable,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { connect } from 'react-redux';

import ArticleSingle from './ArticleSingle';

const { width: sw } = Dimensions.get('window');

class ClubShop extends Component {
  debug = false;

  constructor(props) {
    super(props);
    this.state = {
      currentClub: this.props.foundOtherClub ?? this.props.affiliatedClubInformation ?? this.props.session.club ?? null,
      showArticle: false,
      articleToShow: { name: '', description: '', reference: '', images: [], size: [], price: 0 },
      keyword: '',
      externalShop: false,
      productsList: [],
      loading: false,
      noResultFound: false,
      productsListForFlatList: [],
    };
  }

  componentDidMount() {
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      this.props.navigation.pop();
      return true;
    });
    this.focusListener = this.props.navigation.addListener('focus', () => {
      this.handleAffiliation();
    });
  }

  componentWillUnmount() {
    this.backHandler.remove();
    this.focusListener();
  }

  handleAffiliation() {
    this.debug && console.debug('Shop.js, handleAffiliation');

    this.setState(
      {
        currentClub:
          this.props.foundOtherClub ?? this.props.affiliatedClubInformation ?? this.props.session.club ?? null,
        productsList: [],
        loading: false,
        noResultFound: false,
        productsListForFlatList: [],
      },
      () => {
        if (
          this.state.currentClub &&
          this.state.currentClub.external_shop &&
          this.state.currentClub.external_shop !== ''
        ) {
          this.setState({ externalShop: true });
        }
        this.getAllProducts('');
      }
    );
  }

  getAllProducts = async (keyword) => {
    this.setState({ loading: true });
    if (!keyword) {
      keyword = '';
    }

    const response = await ShopAPI.getAllProductsByClubId(this.props.tokens.wsToken, this.state.currentClub?.id);

    this.debug && console.debug('Shop.js, getAllProducts, got: ', response);
    if (!response) {
      this.setState({ loading: false, productsList: [], noResultFound: true });
      this.debug && console.debug('Shop.js, getAllProducts, no response!');
      return;
    }

    if (response && response.success) {
      this.setState({
        productsList: response.result,
        noResultFound: response.result.length === 0,
      });

      if (response.result.length === 0 && this.state.externalShop) {
        openUrl(this.state.currentClub.external_shop);
      }
    } else if (response.error_code === 10) {
      console.warn('Session expired!');
      // session expired redirect to Login
    }
    this.setState({ loading: false });
  };

  renderDataPage = () => {
    if (this.state.loading) {
      return (
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <ActivityIndicator size="large" color={Colors.blueCorporate} />
        </View>
      );
    }

    if (this.state.noResultFound && !this.state.externalShop) {
      return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text
            style={{
              marginTop: 20,
              color: Colors.blueCorporate + 'CC',
              fontFamily: 'appFontRegular',
              fontSize: 16,
            }}>
            {' '}
            Le club ne propose pas de produits
          </Text>
        </View>
      );
    }

    return (
      <>
        {this.state.externalShop && (
          <View style={{ marginHorizontal: 10, justifyContent: 'center', alignItems: 'center' }}>
            <Text style={{ marginTop: 10, textAlign: 'center', color: Colors.blueCorporate + 'CC', fontSize: 18 }}>
              Votre club a sa propre boutique en ligne. Si la page ne s'est pas ouverte automatiquement, cliquez sur ce
              bouton.
            </Text>

            <ButtonBase label="Boutique en ligne" onPress={() => openUrl(this.state.currentClub.external_shop)} />
          </View>
        )}

        <ScrollView
          contentContainerStyle={{ padding: 5 }}
          scrollEventThrottle={0}
          onMomentumScrollEnd={async ({ nativeEvent }) => {}}
          showsVerticalScrollIndicator={false}>
          {this.state.productsList.map((category, index) => {
            return (
              <View key={'Cat-' + category.label + '-' + index}>
                {category.products && category.products.length > 0 && (
                  <>
                    <Text
                      style={{
                        marginHorizontal: 15,
                        color: Colors.blueCorporate,
                        fontSize: 18,
                        fontFamily: 'appFontMedium',
                      }}>
                      {category.label}
                    </Text>
                    <ScrollView horizontal contentContainerStyle={{ paddingTop: 5 }} showsHorizontalScrollIndicator>
                      {category.products.map((item, index) => {
                        return (
                          <Pressable
                            style={{ overflow: 'visible', alignItems: 'center' }}
                            onPress={async () => {
                              this.setState({ articleToShow: item }, () => {
                                this.debug && console.debug('Shop.js, Selected an item:', item);
                                this.articleRef.setImages(item);
                                this.articleRef.open();
                              });
                            }}
                            key={'Prod-' + item.name + index}>
                            <ShadowCard style={{ width: sw / 3, padding: 10, margin: 10, alignItems: 'center' }}>
                              <View style={{ flexDirection: 'column', alignItems: 'center' }}>
                                <Image
                                  source={
                                    item.imageFilename !== undefined && item.imageFilename !== null
                                      ? { uri: getImageApiHostOrUrl(item.imageFilename) }
                                      : Images.bannerPlaceholder
                                  }
                                  style={{
                                    width: sw / 3 - 20,
                                    aspectRatio: 1,
                                  }}
                                  imageStyle={{ resizeMode: 'contain' }}
                                />

                                <View
                                  style={{
                                    backgroundColor: Colors.whiteCorporate + '33',
                                    width: (sw / 5 - 10) * 2,
                                    alignItems: 'center',
                                  }}>
                                  <Text
                                    style={{
                                      color: Colors.blueCorporate,
                                      fontFamily: 'appFontRegular',
                                      opacity: 0.7,
                                      width: '100%',
                                      marginVertical: 10,
                                      textAlign: 'center',
                                    }}
                                    numberOfLines={1}
                                    ellipsizeMode="tail">
                                    {item.name}
                                  </Text>
                                  <Text style={{ color: Colors.red, fontFamily: 'appFontRegular' }}>
                                    {item.price + '€'}
                                  </Text>
                                </View>
                              </View>
                            </ShadowCard>
                          </Pressable>
                        );
                      })}
                    </ScrollView>
                  </>
                )}
              </View>
            );
          })}
        </ScrollView>
      </>
    );
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        <HeaderBanner
          leftIcon="md-arrow-back"
          onLeftButton={() => this.props.navigation.goBack()}
          rightIcon="md-menu"
          onRightButton={async () => {
            this.props.navigation.goBack();
            this.props.navigation.openDrawer();
          }}
        />

        <View style={[{ flex: 1, marginBottom: 60 }, Platform.OS === 'web' ? { width: '100%', maxWidth: gMaxWidth } : {}]}>
          <this.renderDataPage />
        </View>

        <ArticleSingle
          articleRef={(ref) => {
            this.articleRef = ref;
          }}
          close={() => {
            // this.setState({ showArticle: false, articleToShow: null })
          }}
          article={this.state.articleToShow}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { tokens } = state.app;
  const { session, affiliatedClubInformation, foundOtherClub } = state.session;
  return {
    session,
    tokens,
    affiliatedClubInformation,
    foundOtherClub,
  };
};

export default connect(mapStateToProps, null)(ClubShop);
