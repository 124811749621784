import { getApiHost } from '@configs/host';

import { Request } from './Request';
import { URLs } from './apisConstants';

export const clubCategories = [
  { title: 'teams', profile: 0, profileName: '', roles: [], content: 'teams' },
  { title: 'players', profile: 1, profileName: 'Joueur', roles: [] },
  { title: 'coaches', profile: 2, profileName: 'Entraîneur', roles: [] },
  { title: 'referees', profile: 9, profileName: 'Arbitre', roles: [] },
  { title: 'staff', profile: 0, profileName: 'Dirigeant équipe', roles: [10, 11, 12, 13, 14] },
  { title: 'board', profile: 3, profileName: 'Dirigeant Club', roles: [4, 5, 6, 7, 9] },
  { title: 'parents', profile: 10, profileName: 'Parent', roles: [] },
  { title: 'volunteers', profile: 5, profileName: 'Bénévole', roles: [] },
  {
    title: 'others',
    profiles: [
      { id: 6, name: 'Supporter' },
      { id: 7, name: 'Ancien joueur' },
      { id: 8, name: 'Ancien du club' },
    ],
    roles: [],
  },
  { title: 'partners', profile: 11, profileName: 'Partenaire', roles: [], content: 'partners' },
];

export const teamCategories = [
  { title: 'players', profile: 1, roles: [] },
  { title: 'coaches', profile: 2, roles: [] },
  { title: 'managers', profile: 4, roles: [] },
  { title: 'parents', profile: 10, roles: [] },
];

export const ClubsAPI = {
  getCommitees: (keyword = '', page = 1, limit = 10) => {
    const url = getApiHost() + URLs.getCommitees + '?keyword=' + keyword + '&page=' + page + '&limit=' + limit;
    return Request.get(url);
  },
  getAllClubs: (keyword = '', page = 1, limit = 10, committeeId = 5) => {
    const url =
      getApiHost() +
      URLs.listAllClubs +
      '?keyword=' +
      keyword +
      '&page=' +
      page +
      '&limit=' +
      limit +
      '&committeeId=' +
      committeeId;
    return Request.get(url);
  },
  getAllClubsNoPageNoLimit: (keyword = '', committeeId) => {
    const url = getApiHost() + URLs.listAllClubs + '?keyword=' + keyword + '&committeeId=' + committeeId;
    return Request.get(url);
  },
  getClubDetail: (wsToken, clubId) => {
    const url = getApiHost() + URLs.getClubDetail + '?wsToken=' + wsToken + '&clubId=' + clubId;
    return Request.get(url);
  },
  getClubComposition: (wsToken, clubId) => {
    const url = getApiHost() + URLs.getClubComposition + '?wsToken=' + wsToken + '&clubId=' + clubId;
    return Request.get(url);
  },
  getAllClubsWithNoParameters: () => {
    const url = getApiHost() + URLs.listAllClubs;
    return Request.get(url);
  },
  addNewClubAffiliation: (wsToken, clubId, profileIds) => {
    const url = getApiHost() + URLs.addClubAffiliation;
    const data = {
      wsToken,
      clubId,
      profileIds,
    };
    return Request.post(url, data);
  },
};
