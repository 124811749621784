// Uncomment for better error messages on development builds
// import 'expo-dev-client';
// This breaks the Web compatibility - do not use!
// import 'react-native-url-polyfill/auto';
import 'react-native-gesture-handler';

import { AccountAPI } from '@apis/account';
import { ConfigAPI } from '@apis/config';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import Loading from '@components/Loading/Loading';
import Colors from '@configs/colors';
import { handleNotificationOpened, handleNotificationReceived } from '@configs/notification/handleNotification';
import { SENTRY } from '@configs/sentry';
import { gMaxWidth } from '@configs/styles';
import AppNavigation from '@navigation/Navigation';
import * as actions from '@redux/actions';
import { persistor, store } from '@redux/store';
import { SET_API_CONFIGURATION, SET_CONST_PROFILES_TYPES, SET_USER_STATUS } from '@redux/storeReducer';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { useDimensions } from '@utils/useDimensions';
import * as Application from 'expo-application';
import Constants from 'expo-constants';
import { useFonts } from 'expo-font';
import * as Localization from 'expo-localization';
import * as Notifications from 'expo-notifications';
import * as ScreenOrientation from 'expo-screen-orientation';
import * as SplashScreen from 'expo-splash-screen';
import React, { useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  AppState,
  Dimensions,
  ImageBackground,
  Platform,
  StatusBar,
  StyleSheet,
  useColorScheme,
  View,
} from 'react-native';
import { MenuProvider } from 'react-native-popup-menu';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from 'sentry-expo';
import 'expo-splash-screen';
import * as Updates from 'expo-updates';

const { width: sw, height: sh } = Dimensions.get('window');
const debug = true;

const sentryRelease =
  Platform.OS === 'android'
    ? `${Constants.expoConfig.android.package}@${Constants.expoConfig.version}+${Application.nativeBuildVersion}`
    : Platform.OS === 'ios'
    ? `${Constants.expoConfig.ios.bundleIdentifier}@${Constants.expoConfig.version}+${Application.nativeBuildVersion}`
    : `${Constants.expoConfig.slug}@${Constants.expoConfig.version}`; // Web

Platform.OS === 'web' && console.log('Application:', sentryRelease);

Sentry.init({
  dsn: `https://${SENTRY.DSN}`,
  release: sentryRelease,
  dist: `${Application.nativeBuildVersion}`,
  enableInExpoDevelopment: false,
  integrations: [
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      // Only log, warn and error are reported
      levels: SENTRY.LOG_LEVELS,
    }),
  ],
  debug: false,
});

SplashScreen.preventAutoHideAsync();

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

export default function App() {
  const notificationListener = useRef();
  const responseListener = useRef();
  const appState = useRef(AppState.currentState);
  const [appIsReady, setAppIsReady] = useState(false);
  const [newVersionAvailable, setNewVersionAvailable] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  SplashScreen.preventAutoHideAsync();

  const colorScheme = useColorScheme();
  debug && console.debug(`App, color scheme: ${colorScheme}`);

  // Elipce, block screen orientation in portrait mode
  const setScreenOrientation = async () => {
    try {
      // Default orientation lock policy
      await ScreenOrientation.unlockAsync();
      // Force set portrait orientation
      await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
      debug && console.debug('App.js, forced screen orientation');
    } catch (error) {
      console.warn('App.js, setScreenOrientation, error:', error);
      alert('Erreur', `Une erreur est survenue lors du chargement de l'application`);
    }
  };

  const [fontsLoaded, error] = useFonts({
    appFontLight: require('@assets/fonts/Montserrat-Light.ttf'),
    appFontRegular: require('@assets/fonts/Montserrat-Regular.ttf'),
    appFontMedium: require('@assets/fonts/Montserrat-Medium.ttf'),
  });
  debug && fontsLoaded && console.debug('App, application fonts loaded');
  error && console.warn('App, application fonts loading raised an error', error);

  // // Elipce/Fred: manage this
  // useEffect(() => {
  //   const backAction = () => {
  //     Alert.alert('Hold on!', 'Are you sure you want to go back?', [
  //       {
  //         text: 'Cancel',
  //         onPress: () => null,
  //         style: 'cancel',
  //       },
  //       { text: 'YES', onPress: () => BackHandler.exitApp() },
  //     ]);
  //     return true;
  //   };
  //
  //   const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);
  //
  //   return () => backHandler.remove();
  // }, []);

  // Let the LocalCredentials do the job to check if a user is still authenticated...
  store.dispatch({ type: SET_USER_STATUS, value: 're-signing-in' });

  useEffect(() => {
    async function fetchOrientation() {
      const orientation = await ScreenOrientation.getOrientationAsync();
      debug && console.debug(`App, screen orientation is: ${orientation}`);
    }
    fetchOrientation();
  }, []);

  const { height, width, scale, fontScale } = useDimensions().window;
  debug &&
    console.debug(
      `App, window dimensions changed - width: ${width}, height: ${height}, scale:${scale}, font scale: ${fontScale}`
    );

  useEffect(() => {
    const unsubscribeListener = ScreenOrientation.addOrientationChangeListener((orientation) => {
      debug && console.debug(`App, orientation changed: ${orientation}`);
    });
    return () => {
      unsubscribeListener?.remove();
    };
  }, []);

  useEffect(() => {
    const unsubscribeListener = AppState.addEventListener('change', (nextAppState) => {
      debug && console.debug('App, application state changed: ', nextAppState);

      if (appState.current.match(/background/) && nextAppState === 'active') {
        if (store?.getState()?.app?.access?.wsToken) {
          store.dispatch(actions.getAllNotifications(store.getState().app.access.wsToken));
        }
      }
      appState.current = nextAppState;
    });
    return () => {
      unsubscribeListener?.remove();
    };
  }, []);

  useEffect(() => {
    async function prepareApplication() {
      // function to handle notification opened by clicking in notification push
      notificationListener.current = Notifications.addNotificationResponseReceivedListener((notification) => {
        handleNotificationOpened(notification.notification, store);
      });
      // function to handle notification coming in this application
      responseListener.current = Notifications.addNotificationReceivedListener((notification) =>
        handleNotificationReceived(notification, store)
      );

      // Elipce, block screen orientation in portrait mode
      Platform.OS !== 'web' && (await setScreenOrientation());

      // Get API configuration - synchronous API call!
      const preferredLanguage = Localization.getLocales()[0].languageCode;
      debug && console.debug('App, application preferred language: ', preferredLanguage);
      const apiConfiguration = await ConfigAPI.getDefaultConfig(preferredLanguage);
      apiConfiguration &&
        apiConfiguration.success &&
        store.dispatch({
          type: SET_API_CONFIGURATION,
          value: apiConfiguration.result,
        });

      // Load users profiles types
      AccountAPI.getAllProfileTypes().then((response) => {
        if (response && response.success) {
          debug && console.debug('App, got the list of the profiles types');
          store.dispatch({ type: SET_CONST_PROFILES_TYPES, value: response.result });
        } else {
          console.warn('App, error when getting profiles types from the backend.', response);
        }
      });

      // // Load committees -> very heavy! download 1.5 Mb
      // ClubsAPI.getCommitees().then((response) => {
      //   if (response && response.success) {
      //     debug && console.debug('App, got the list of the committees', response);
      //     // store.dispatch({ type: SET_CONST_PROFILES_TYPES, value: response.result });
      //   } else {
      //     console.warn('App, error when getting committees from the backend.', response);
      //   }
      // });

      // Load all clubs (if not yet known!)
      store.dispatch(
        actions.getAllClubs(false, null, (result, count) => {
          debug && console.debug(`App, got the list of all clubs: ${count} clubs.`, result);
        })
      );

      await checkForNewVersion();

      // Tell the application to render
      setAppIsReady(true);
      await SplashScreen.hideAsync();
    }

    prepareApplication();

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  useEffect(() => {
    if (newVersionAvailable) {
      setShowAlert(true);
    }
  }, [newVersionAvailable]);

  const downloadExpoUpdate = async () => {
    try {
      debug && console.debug('App, downloading new version...');
      await Updates.fetchUpdateAsync();
      debug && console.debug('App, new version downloaded');
      // ... notify user
      setShowAlert(false);
      // ... and reload the application
      await Updates.reloadAsync();
    } catch (e) {
      console.warn('App, error when downloading new version:', e);
    }
  };

  const checkForNewVersion = async () => {
    try {
      const Update = await Updates.checkForUpdateAsync();

      if (!Update.isAvailable) {
        debug && console.debug('App, no new version available');
        return;
      }

      debug && console.debug('App, new version available');
      setNewVersionAvailable(true);
    } catch (error) {
      console.warn('App, error when checking for new version:', error);
    }
  };

  if (!appIsReady || !fontsLoaded) {
    return (
      <ImageBackground
        source={require('@assets/splash.png')}
        alt="Background image while splash if off..."
        style={[styles.mainContainerBG, { width, height }]}
        imageStyle={{ resizeMode: 'cover', width: '100%', height: '100%' }}>
        <ActivityIndicator size={48} color={Colors.white} />
      </ImageBackground>
    );
  }

  if (newVersionAvailable) {
    return (
      <ImageBackground
        source={require('@assets/splash.png')}
        alt="Background image while splash if off..."
        style={styles.mainContainerBG}>
        <AlerteUI
          showAlert={showAlert}
          title="Nouvelle version disponible"
          message="Une nouvelle version de l'application est disponible. Voulez-vous la télécharger ?"
          buttons={[
            {
              text: 'Télécharger',
              onPress: () => {
                downloadExpoUpdate();
              },
            },
            {
              text: 'Annuler',
              onPress: () => {
                setShowAlert(false);
              },
            },
          ]}
        />
      </ImageBackground>
    );
  }

  return (
    <>
      <MenuProvider>
        <Provider store={store}>
          <PersistGate loading={<Loading />} persistor={persistor}>
            <StatusBar barStyle="light-content" />

            <View
              style={[
                { flex: 1 },
                Platform.OS === 'web'
                  ? { width: '100%', maxWidth: gMaxWidth, marginHorizontal: (sw - gMaxWidth) / 2 }
                  : {},
              ]}>
              <AppNavigation />
            </View>
          </PersistGate>
        </Provider>
      </MenuProvider>
    </>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    paddingVertical: 0,
    alignItems: 'center',
    backgroundColor: Colors.black + 77,
  },
  container: {
    flex: 1,
    width: '100%',
    maxWidth: 768,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContainerBG: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
