import ButtonBase from '@components/ButtonBase/ButtonBase';
import ButtonCancel from '@components/ButtonCancel/ButtonCancel';
import Loading from '@components/Loading/Loading';
import Colors from '@configs/colors';
import { gMaxWidth } from '@configs/styles';
import I18n from '@utils/i18n';
import * as ImagePicker from 'expo-image-picker';
import { UIImagePickerControllerQualityType } from 'expo-image-picker';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, Modal, Platform, Dimensions } from 'react-native';

const { width: sw, height: sh } = Dimensions.get('window');

export default function TakePhoto({ visible, resize = false, allowVideo = false, onPhotos, onCancel }) {
  const debug = false;
  const [loading, setLoading] = useState(false);

  const [mediaLibraryPermissions, requestLibraryPermission] = ImagePicker.useMediaLibraryPermissions();
  const [cameraPermissions, requestCameraPermission] = ImagePicker.useCameraPermissions();

  useEffect(() => {
    if (!mediaLibraryPermissions || mediaLibraryPermissions.granted) {
      return;
    }
    debug && console.debug('TakePhoto, library permissions', mediaLibraryPermissions);
    requestLibraryPermission().then((response) => {
      debug && console.debug('TakePhoto, library permissions response', response);
    });
  }, [mediaLibraryPermissions]);

  useEffect(() => {
    if (!cameraPermissions || cameraPermissions.granted) {
      return;
    }
    debug && console.debug('TakePhoto, camera permissions', cameraPermissions);
    requestCameraPermission().then((response) => {
      debug && console.debug('TakePhoto, camera permissions response', response);
    });
  }, [cameraPermissions]);

  const takePhotoFromCamera = async () => {
    debug && console.debug('TakePhoto, take a photo');
    setLoading(true);

    ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: resize,
      exif: true,
      quality: 0.2, // Default value
    })
      .then((result) => {
        debug && console.debug('TakePhoto, Got camera result', result);
        setLoading(false);
        if (!result.canceled) {
          debug && console.debug('TakePhoto, media Exif:', result.assets[0].exif);
          if (Platform.OS === 'web') {
            const updated = result.assets.map((asset) => {
              if (asset.type) return asset;
              return { ...asset, type: 'photo' };
            });
            onPhotos(updated);
          } else {
            onPhotos(result.assets);
          }
        } else {
          onPhotos([]);
        }
      })
      .catch((error) => {
        console.warn('takeVideoFromCamera error', error);
        setLoading(false);
      });
  };

  const takeVideoFromCamera = async () => {
    debug && console.debug('TakePhoto, take a photo');
    setLoading(true);

    ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Videos,
      videoMaxDuration: 15,
      videoQuality: UIImagePickerControllerQualityType.Medium,
      allowsEditing: false,
      exif: true,
    })
      .then((result) => {
        debug && console.debug('TakePhoto, Got camera result', result);
        setLoading(false);
        if (!result.canceled) {
          if (Platform.OS === 'web') {
            const updated = result.assets.map((asset) => {
              if (asset.type) return asset;
              return { ...asset, type: 'video' };
            });
            onPhotos(updated);
          } else {
            onPhotos(result.assets);
          }
        } else {
          onPhotos([]);
        }
      })
      .catch((error) => {
        console.warn('takeVideoFromCamera error', error);
        setLoading(false);
      });
  };

  const takeFromLibrary = async () => {
    debug && console.debug('TakePhoto, pick a photo/video');
    setLoading(true);

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: allowVideo ? ImagePicker.MediaTypeOptions.All : ImagePicker.MediaTypeOptions.Images,
      allowsEditing: resize,
      allowsMultipleSelection: true,
      exif: true,
      quality: 0.2, // Default value
    });

    debug && console.debug('TakePhoto, Got library result', result);
    setLoading(false);
    if (!result.canceled) {
      if (Platform.OS === 'web') {
        const updated = result.assets.map((asset) => {
          if (asset.type) return asset;
          if (asset.uri.startsWith('data:video')) return { ...asset, type: 'video' };
          return { ...asset, type: 'image' };
        });
        onPhotos(updated);
      } else {
        // // Todo - to be tested
        // const pickedImage = result.assets[0].localUri || result.assets[0].uri;
        // const manipResult = await manipulateAsync(pickedImage, [{ rotate: 90 }, { flip: FlipType.Vertical }], {
        //   compress: 1,
        //   format: SaveFormat.PNG,
        // });
        onPhotos(result.assets);
      }
    } else {
      onPhotos([]);
    }
  };

  if (!cameraPermissions || !mediaLibraryPermissions || !visible) {
    return null;
  }
  return (
    <Modal visible={visible} transparent>
      <View style={[styles.mainContainer]}>
        <View style={[styles.centered, styles.shadowEffect]}>
          {cameraPermissions.granted && (
            <View style={[styles.photoSourceContainer]}>
              <ButtonBase label={I18n.t('takePhoto.photo')} onPress={() => takePhotoFromCamera()} />
            </View>
          )}
          {cameraPermissions.granted && allowVideo && (
            <View style={[styles.photoSourceContainer]}>
              <ButtonBase label={I18n.t('takePhoto.video')} onPress={() => takeVideoFromCamera()} />
            </View>
          )}
          {mediaLibraryPermissions.granted && (
            <View style={[styles.photoSourceContainer]}>
              <ButtonBase label={I18n.t('takePhoto.gallery')} onPress={() => takeFromLibrary()} />
            </View>
          )}
          <View style={[styles.buttonsContainer]}>
            {cameraPermissions.granted && allowVideo && (
              <Text
                numberOfLines={2}
                style={{ marginHorizontal: 30, color: Colors.blueCorporate, textAlign: 'center', fontStyle: 'italic' }}>
                La durée maximale d'une vidéo est de 30 secondes.
              </Text>
            )}
            <ButtonCancel
              label={I18n.t('takePhoto.cancel')}
              onPress={() => {
                setLoading(false);
                onCancel();
              }}
            />
          </View>
        </View>
      </View>

      <Loading loading={loading} />
    </Modal>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    width: Platform.OS === 'web' ? gMaxWidth : sw,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.black + 'AA',
  },
  centered: {
    backgroundColor: Colors.gray1,
    paddingVertical: 10,
    paddingHorizontal: 20,
    width: Platform.OS === 'web' ? gMaxWidth - 120 : 320,
    borderRadius: 10,
  },
  shadowEffect: {
    marginBottom: 15,

    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },
  photoSourceContainer: {
    borderBottomWidth: 0.4,
    borderBottomColor: Colors.iconGray,
  },
  buttonsContainer: {
    marginTop: 20,
  },
});
