import CardTeam from '@components/CardTeam/CardTeam';
import Colors from '@configs/colors';
import I18n from '@utils/i18n';
import { useDimensions } from '@utils/useDimensions';
import React, { useState } from 'react';
import { View, Text, StyleSheet, FlatList, Pressable } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';

/**
 * A FlatList element for all the teams
 *
 * @param club
 * @param category
 * @param members - a list of club members
 * @param content - teams, users or partners
 * @param opened - create the element in opened mode
 * @param selected - whether the category is selected or not
 * @param allSelectable - true if all the category is selectable
 * @param selectable - to make the category content items selectable or not
 * @param onSelectMember
 * @param onSelectCategory
 * @param onDeselectCategory
 * @param onSelectTeam
 * @param onDeselectTeam
 * @param onSelectAllTeams
 * @param onDeselectAllTeams
 * @returns {JSX.Element}
 * @constructor
 */
export default function CardTeams({
  club,
  category,
  showCategories = false,
  members,
  opened = false,
  selected = false,
  allSelectable = true,
  selectable = true,
  chattable = false,
  onSelectMember,
  onSelectCategory,
  onDeselectCategory,
  onSelectTeam,
  onDeselectTeam,
  onSelectAllTeams,
  onDeselectAllTeams,
  onOpenChat = () => {},
}) {
  const [isBlockOpened, setIsBlockOpened] = useState(opened);
  const [categorySelected, setCategorySelected] = useState(selected);

  const { width } = useDimensions().window;

  if (members.length <= 0) {
    return (
      <View style={styles.membersCategory}>
        <View style={styles.blockHeader}>
          <Text style={styles.closerLabel} numberOfLines={1} ellipsizeMode="tail">
            {`${I18n.t('message.categories.' + category)} (pas de membres !)`}
          </Text>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.membersCategory}>
      <View style={styles.blockHeader}>
        <Pressable
          style={({ pressed }) => [styles.closerRow, { width: width - 80 }, pressed ? { opacity: 0.7 } : {}]}
          onPress={() => {
            setIsBlockOpened(!isBlockOpened);
          }}>
          <Icon
            style={styles.closerIcon}
            name={isBlockOpened ? 'chevron-up' : 'chevron-down'}
            color={club?.mainColorBottom ?? Colors.black}
            size={25}
          />
          <Text style={[styles.closerLabel, { color: club.mainColorBottom, width: '100%' }]}>
            {I18n.t('message.categories.' + category)}
          </Text>
        </Pressable>

        {isBlockOpened && allSelectable && (
          <Pressable
            disabled={!selectable}
            style={styles.categorySelection}
            onPress={() => {
              if (categorySelected) {
                onDeselectAllTeams();
              } else {
                onSelectAllTeams();
              }
              setCategorySelected(!categorySelected);
            }}>
            <Text style={styles.categorySelectionLabel}>
              {categorySelected
                ? I18n.t('message.deselect', { all: I18n.t('message.categories.' + category + 'All') })
                : I18n.t('message.select', { all: I18n.t('message.categories.' + category + 'All') })}
            </Text>
            {selectable && (
              <Icon
                style={styles.categorySelectionIcon}
                size={25}
                color={club.mainColorBottom ?? Colors.black}
                name={categorySelected ? 'checkbox-outline' : 'square-outline'}
              />
            )}
          </Pressable>
        )}
      </View>

      {isBlockOpened && (
        <View style={[styles.blockContainer, { marginLeft: 10 }]}>
          <FlatList
            data={members}
            keyExtractor={(item, index) => item.id + index}
            renderItem={({ item, index }) => (
              <CardTeam
                club={club}
                team={item}
                selected={item.selected}
                selectable={selectable}
                chattable={chattable}
                allSelectable={allSelectable}
                showCategories={showCategories}
                onSelectMember={(category, member) => {
                  onSelectMember(category, member, item);
                }}
                onSelectCategory={onSelectCategory}
                onDeselectCategory={onDeselectCategory}
                onSelectTeam={onSelectTeam}
                onDeselectTeam={onDeselectTeam}
                onOpenChat={onOpenChat}
              />
            )}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  membersCategory: {},
  blockHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    paddingVertical: 0,
    paddingHorizontal: 5,
    borderBottomColor: Colors.gray,
    borderBottomWidth: 1,
  },

  closerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    margin: 0,
    padding: 0,
  },
  closerIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
  },
  closerLabel: {
    fontFamily: 'appFontRegular',
    fontSize: 18,
  },
  closerLabelWithSelection: {
    fontFamily: 'appFontMedium',
    fontSize: 18,
  },

  blockContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',

    borderBottomWidth: 1,
    borderBottomColor: Colors.gray,
  },

  categorySelection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    marginRight: 10,

    borderBottomWidth: 1,
    borderBottomColor: Colors.gray,
  },

  categorySelectionLabel: {
    // alignSelf: 'center',
    color: Colors.iconGray,
    display: 'none',
    marginRight: 10,
  },

  categorySelectionIcon: {
    // alignSelf: 'center',
    color: Colors.iconGray,
  },
});
