import { getApiHost } from '@configs/host';

import { Request } from './Request';
import { URLs } from './apisConstants';

// Diary type:
// public const TRAINING_TYPE_ID = 1;
// public const MATCH_TYPE_ID = 2;
// public const EVENT_TYPE_ID = 3;
// public const CONVOCATION_TYPE_ID = 4;
export const DIARY_TRAINING = '1';
export const DIARY_MATCH = '2';
export const DIARY_EVENT = '3';
export const DIARY_CONVOCATION = '4';

export const DiariesAPI = {
  getDiaryTeam: (wsToken) => {
    const url = getApiHost() + URLs.getDiaryTeams + '?wsToken=' + wsToken;
    return Request.get(url);
  },
  getDiaryTeamsByClubId: (wsToken, clubId) => {
    const url = getApiHost() + URLs.getDiaryTeamsByClubId + '?wsToken=' + wsToken + '&clubId=' + clubId;
    return Request.get(url);
  },
  getScheduledDiariesByClubId: (wsToken, clubId, page, limit, typeIds = [], recipientIds = []) => {
    let url =
      getApiHost() +
      URLs.getScheduledDiariesByClubId +
      '?wsToken=' +
      wsToken +
      '&clubId=' +
      clubId +
      '&page=' +
      page +
      '&limit=' +
      limit;

    typeIds.map((id, index) => {
      url += '&typeIds[]=' + id;
    });

    recipientIds.map((id, index) => {
      url += '&recipientIds[]=' + id;
    });

    return Request.get(url);
  },
  getAllDiariesByClubId: (wsToken, clubId, page, limit, isFuture = 1, typeIds = [], recipientIds = []) => {
    let url =
      getApiHost() +
      URLs.getAllDiariesByClubId +
      '?wsToken=' +
      wsToken +
      '&clubId=' +
      clubId +
      '&page=' +
      page +
      '&limit=' +
      limit +
      '&isFuture=' +
      isFuture;

    typeIds.map((id, index) => {
      url += '&typeIds[]=' + id;
    });

    recipientIds.map((id, index) => {
      url += '&recipientIds[]=' + id;
    });

    return Request.get(url);
  },
  getAllDiaries: (wsToken, page, limit, isFuture = 1, recipientIds = [], typeId = []) => {
    let url =
      getApiHost() +
      URLs.getAllDiaries +
      '?wsToken=' +
      wsToken +
      '&page=' +
      page +
      '&limit=' +
      limit +
      '&isFuture=' +
      isFuture;

    typeId.map((tid, index) => {
      url += '&typeIds[]=' + tid;
    });

    recipientIds.map((rid, index) => {
      url += '&recipientIds[]=' + rid;
    });

    return Request.get(url);
  },
  getDiary: (wsToken, id) => {
    const url = getApiHost() + URLs.getDiary + '?wsToken=' + wsToken + '&id=' + id;
    return Request.get(url);
  },
  addDiary: (
    wsToken,
    diaryTypeId,
    homeClubId,
    awayClubId,
    homeTeamId,
    homeTeamName,
    awayTeamId,
    awayTeamName,
    title,
    description,
    latitude,
    longitude,
    address,
    askReply,
    date,
    time,
    isRecurrent,
    recipientIds,
    isSendNotif,
    recurrenceEndDate
  ) => {
    const url = getApiHost() + URLs.addDiary;
    const data = {
      wsToken,
      diaryTypeId,
      homeClubId,
      awayClubId,
      homeTeamId,
      homeTeamName,
      awayTeamId,
      awayTeamName,
      title,
      description,
      latitude,
      longitude,
      address,
      askReply,
      date,
      time,
      isRecurrent,
      recipientIds,
      isSendNotif,
      recurrenceEndDate,
    };
    return Request.post(url, data);
  },
  addDiaryByClubId: (wsToken, clubId, diaryTypeId, parameters) => {
    const {
      homeClubId,
      homeClubName,
      awayClubId,
      awayClubName,
      homeTeamId,
      homeTeamName,
      awayTeamId,
      awayTeamName,
      title,
      description,
      latitude,
      longitude,
      address,
      askReply,
      date,
      time,
      isRecurrent,
      selectedTeamIds,
      sendNotification,
      endDate,
    } = parameters;
    const url = getApiHost() + URLs.addDiaryByClubId;
    const data = {
      wsToken,
      diaryTypeId,
      homeClubId,
      homeClubName,
      awayClubId,
      awayClubName,
      homeTeamId,
      homeTeamName,
      awayTeamId,
      awayTeamName,
      title,
      description,
      latitude,
      longitude,
      address,
      askReply,
      date,
      time,
      isRecurrent,
      recipientIds: selectedTeamIds,
      isSendNotif: sendNotification,
      recurrenceEndDate: endDate,
      clubId,
    };
    return Request.post(url, data);
  },
  editDiary: (
    wsToken,
    id,
    homeClubId,
    homeClubName,
    awayClubId,
    awayClubName,
    homeTeamId,
    homeTeamName,
    awayTeamId,
    awayTeamName,
    title,
    description,
    latitude,
    longitude,
    address,
    askReply,
    date,
    time,
    isRecurrent,
    isSendNotif
  ) => {
    const url = getApiHost() + URLs.editDiary;
    const data = {
      wsToken,
      id,
      homeClubId,
      homeClubName,
      awayClubId,
      awayClubName,
      homeTeamId,
      homeTeamName,
      awayTeamId,
      awayTeamName,
      title,
      description,
      latitude,
      longitude,
      address,
      askReply,
      date,
      time,
      isRecurrent,
      isSendNotif,
    };

    return Request.post(url, data);
  },
  deleteDiary: (wsToken, id) => {
    const url = getApiHost() + URLs.deleteDiary;
    const data = {
      wsToken,
      id,
    };

    return Request.post(url, data);
  },
  answerDiary: (wsToken, id, replyId) => {
    const url = getApiHost() + URLs.answerDiary;
    const data = {
      wsToken,
      id,
      replyId,
    };
    return Request.post(url, data);
  },
  getAutoComplete: (keyword, page = 1, limit = 10) => {
    const url = getApiHost() + URLs.diariesAutocomplete + '?keyword=' + keyword + '&page=' + page + '&limit=' + limit;
    return Request.get(url);
  },
  // getTeamsList: (wsToken, clubId, keyword = '', page = 1, limit = 10) => {
  //   const url =
  //     getApiHost() +
  //     URLs.getTeamsList +
  //     '?wsToken=' +
  //     wsToken +
  //     '&clubId=' +
  //     clubId +
  //     '&keyword=' +
  //     keyword +
  //     '&page=' +
  //     page +
  //     '&limit=' +
  //     limit;
  //   return Request.get(url);
  // },
};
