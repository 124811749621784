// Polyfill for alert.js react-native
import { Alert, Platform } from 'react-native';

const alertPolyfill = (title, description, options) => {
  if (options) {
    const result = window.confirm([title, description].filter(Boolean).join('\n'));

    if (result) {
      const confirmOption = options.find(({ text }) => text.toLowerCase() !== 'annuler');
      confirmOption && confirmOption.onPress();
    } else {
      const cancelOption = options.find(({ text }) => text.toLowerCase() === 'annuler');
      cancelOption && cancelOption.onPress();
    }
  } else {
    window.alert([title, description].filter(Boolean).join('\n'));
  }
};

const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert;

export default alert;
