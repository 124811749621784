import ENLanguage from '@configs/locales/en';
import FRLanguage from '@configs/locales/fr';
import * as Localization from 'expo-localization';
import I18n from 'i18n-js';

I18n.translations = {
  fr: FRLanguage,
  en: ENLanguage,
};

const preferredLanguage = Localization.getLocales()[0].languageCode;
I18n.locale = preferredLanguage;
I18n.locale = 'fr';

I18n.fallbacks = true;

export default I18n;
