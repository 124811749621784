import { teamCategories } from '@apis/clubs';
import { MessageAPI, REPLY_YES } from '@apis/message';
import { CONVOCATION_ANSWER_TYPE, NEW_CONVOCATION_TYPE, NEW_MESSAGE_TYPE } from '@apis/notification';
import { CHECK_CLUB_MEMBER, CHECK_CLUB_PARTNER } from '@redux/reducers';
import {
  RAZ_CHAT,
  SET_CHAT_ERROR,
  SET_CHAT_LOADING,
  SET_CHAT_NOT_LOADING,
  DELETE_CONVERSATION,
  DELETE_MESSAGE,
  SET_CONVOCATION_RESPONSES,
  ADD_NEW_MESSAGE_FROM_NOTIFICATION,
  SET_MY_CONVERSATION,
  SET_MY_CONVERSATIONS,
  SET_MY_MESSAGES,
  ADD_NEW_MESSAGE,
  UPDATE_CONVERSATION,
  SELECT_PARTNER,
  SELECT_MEMBER,
  UNSELECT_TEAM,
  SELECT_TEAM,
  SELECT_CATEGORY,
  UNSELECT_CATEGORY,
  UNSELECT_PARTNER,
  UNSELECT_MEMBER,
  SELECT_INDIRECT_MEMBER,
  UNSELECT_INDIRECT_MEMBER,
  UPDATE_MEMBERS_ROLES,
} from '@redux/reducers/ChatReducer';
import moment from 'moment-timezone';

const debug = false;

const INITIAL_PAGE = 1;
const INITIAL_MESSAGES_COUNT = 25;

export function resetStateChat() {
  return async (dispatch) => {
    dispatch({ type: RAZ_CHAT });
  };
}

export function updateConversation(conversation, currentSession, clubMembers, clubPartners, clubTeams) {
  const updatedChat = { ...conversation };

  updatedChat.iCreated = conversation.creator && conversation.creator.id === currentSession.user.id;

  if (!conversation.lastMessage) {
    // case there is not last message, => corresponding to the start of new chat
    conversation.hasSeenLastMessage = false;
  } else {
    // case there is a message
    if (currentSession.user.id && conversation.lastMessage.User_id === currentSession.user.id) {
      // case - the last message is from me
      conversation.hasSeenLastMessage = true;
    } else if (!conversation.lastSeenAt) {
      // case - user has never consulted the chat
      conversation.hasSeenLastMessage = false;
    } else {
      // the last message was written before the last time user has read the chat
      conversation.hasSeenLastMessage = moment(conversation.lastMessage.createdAt).isBefore(conversation.lastSeenAt);
    }
  }

  if (!conversation.badgeCount) {
    updatedChat.badgeCount = 0;
  }

  // participants received in the conversation are all the members
  if (conversation.membersRoles) {
    updatedChat.membersRoles = JSON.parse(conversation.membersRoles) ?? null;
    if (updatedChat.membersRoles) {
      updatedChat.oneToOne =
        !updatedChat.membersRoles.allTeams &&
        updatedChat.membersRoles.teams.length === 0 &&
        updatedChat.membersRoles.categories.length === 0 &&
        updatedChat.membersRoles.members.length === 1;

      updatedChat.participants = [];
      if (updatedChat.membersRoles.categories.length > 0) {
        // Some categories are selected
        for (const category of updatedChat.membersRoles.categories) {
          if (category.title === 'partners') {
            for (const partner of clubPartners) {
              updatedChat.participants.push(partner);
            }
          } else {
            for (const user of clubMembers[category.title]) {
              updatedChat.participants.push(user);
            }
          }
        }
      }
      if (updatedChat.membersRoles.teams.length > 0) {
        // Some teams are selected
        for (const team of updatedChat.membersRoles.teams) {
          for (const clubTeam of clubTeams) {
            if (team.id !== clubTeam.id) continue;

            for (const category of teamCategories) {
              if (!clubTeam[category.title] || clubTeam[category.title].length <= 0) continue;
              for (const user of clubTeam[category.title]) {
                updatedChat.participants.push(user);
              }
            }
          }
        }
      }
      if (updatedChat.membersRoles.members.length > 0) {
        // Some members are selected
        for (const member of updatedChat.membersRoles.members) {
          updatedChat.participants.push(member);
        }
      }
      if (updatedChat.membersRoles.partners && updatedChat.membersRoles.partners.length > 0) {
        // Some partners related users are selected
        for (const member of updatedChat.membersRoles.partners) {
          updatedChat.participants.push(member);
        }
      }
    }
  }
  updatedChat.oneToOne = updatedChat.participants.length === 2;

  // One-to-one conversation do not store the Json "memberRoles", rebuild it!
  if (updatedChat.oneToOne && !updatedChat.membersRoles) {
    updatedChat.membersRoles = {
      allTeams: false,
      teams: [],
      categories: [],
      partners: [],
      members: [conversation.participants[1]],
      participants: conversation.participants,
    };
  }

  updatedChat.teamConversation = updatedChat.teamConversation === '1' || updatedChat.teamConversation === true;

  updatedChat.iAmParticipating = updatedChat.iCreated;
  if (!updatedChat.iAmParticipating && updatedChat.membersRoles) {
    // I am in a conversation teams
    for (const team of updatedChat.membersRoles.teams ?? []) {
      if (currentSession.teamsAffiliated.includes(team.id)) {
        updatedChat.iAmParticipating = true;
      }
    }

    // In the participants
    for (const member of updatedChat.participants ?? []) {
      if (member.id === currentSession.user.id) {
        updatedChat.iAmParticipating = true;
      }
    }
  }

  return updatedChat;
}

export function selectPartner(partner, forceSelect = false) {
  return async (dispatch) => {
    dispatch({
      type: forceSelect ? SELECT_PARTNER : partner?.selected ? UNSELECT_PARTNER : SELECT_PARTNER,
      partner: {
        id: partner.id,
        name: partner.name,
        logoFilename: partner.logoFilename,
        selected: forceSelect ? true : !partner?.selected,
      },
    });

    dispatch({
      type: CHECK_CLUB_PARTNER,
      partnerId: partner.id,
      checkValue: forceSelect ? true : !partner?.selected,
    });
  };
}
export function unselectPartner(partner) {
  return async (dispatch) => {
    dispatch({
      type: SELECT_PARTNER,
      partner: { ...partner, selected: false },
    });

    dispatch({
      type: CHECK_CLUB_MEMBER,
      partnerId: partner.id,
      checkValue: false,
    });
  };
}

export function selectMember(category, member, teamId = null, forceSelect = false) {
  return async (dispatch) => {
    dispatch({
      type: forceSelect ? SELECT_MEMBER : member?.selected ? UNSELECT_MEMBER : SELECT_MEMBER,
      category,
      member: { ...member, selected: forceSelect ? true : !member?.selected },
      teamId,
    });

    dispatch({
      type: CHECK_CLUB_MEMBER,
      userId: member.id,
      checkValue: forceSelect ? true : !member?.selected,
    });
  };
}
export function unselectMember(category, member, teamId = null) {
  return async (dispatch) => {
    dispatch({
      type: SELECT_MEMBER,
      category,
      member: { ...member, selected: false },
      teamId,
    });

    dispatch({
      type: CHECK_CLUB_MEMBER,
      userId: member.id,
      checkValue: false,
    });
  };
}

export function selectTeam(teamId) {
  return async (dispatch, getState) => {
    const { teams } = getState().club;

    const teamIndex = teams.findIndex((item) => item.id === teamId);
    if (teamIndex < 0) {
      console.warn('ChatActions selectTeam, should have found team:', teamId);
      return;
    }

    // Those are indirect members (team/category)!
    for (const category of teamCategories) {
      if (!teams[teamIndex][category.title]) {
        continue;
      }
      teams[teamIndex][category.title].forEach((member) => {
        dispatch({
          type: SELECT_INDIRECT_MEMBER,
          teamId,
          category: category.title,
          member,
        });
      });
    }

    dispatch({
      type: SELECT_TEAM,
      team: teams[teamIndex],
    });
  };
}
export function unselectTeam(teamId) {
  return async (dispatch, getState) => {
    const { teams } = getState().club;

    const teamIndex = teams.findIndex((item) => item.id === teamId);
    if (teamIndex < 0) {
      console.warn('ChatActions unselectTeam, should have found team:', teamId);
      return;
    }

    // Those are indirect members (team/category)!
    for (const category of teamCategories) {
      if (!teams[teamIndex][category.title]) {
        continue;
      }
      teams[teamIndex][category.title].forEach((member) => {
        dispatch({
          type: UNSELECT_INDIRECT_MEMBER,
          teamId,
          category: category.title,
          member,
        });
      });
    }

    dispatch({
      type: UNSELECT_TEAM,
      teamId,
    });
  };
}

export function selectCategory(category, teamId = null) {
  return async (dispatch, getState) => {
    const { teams, members } = getState().club;

    // Those are indirect members (team/category)!
    if (teamId) {
      const teamIndex = teams.findIndex((item) => item.id === teamId);
      teams[teamIndex][category].forEach((member) => {
        dispatch({
          type: SELECT_INDIRECT_MEMBER,
          teamId,
          category,
          member,
        });
      });
    } else {
      members[category] &&
        members[category].forEach((member) => {
          dispatch({
            type: SELECT_INDIRECT_MEMBER,
            category,
            member,
          });
        });
    }

    dispatch({
      type: SELECT_CATEGORY,
      category,
      teamId,
    });
  };
}
export function unselectCategory(category, teamId = null) {
  return async (dispatch, getState) => {
    const { members, teams } = getState().club;

    // Those are indirect members (team/category)!
    if (teamId) {
      const teamIndex = teams.findIndex((item) => item.id === teamId);
      teams[teamIndex][category].forEach((member) => {
        dispatch({
          type: UNSELECT_INDIRECT_MEMBER,
          teamId,
          category,
          member,
        });
      });
    } else {
      if (members[category] === undefined) {
        dispatch({
          type: UNSELECT_PARTNER,
          category,
          teamId,
        });
      } else {
        members[category].forEach((member) => {
          dispatch({
            type: UNSELECT_INDIRECT_MEMBER,
            category,
            member,
          });
        });
      }
    }

    dispatch({
      type: UNSELECT_CATEGORY,
      category,
      teamId,
    });
  };
}

/**
 * Function used to get all the users' conversations
 *
 * If clubId is not provided then we get only the personal conversations
 *
 * @param {string} token
 * @param {string} clubId
 * @param {boolean} reset, else update only more recent
 */
export function getAllConversations(token, clubId, reset = false) {
  return async (dispatch, getState) => {
    try {
      const start = Date.now();
      dispatch({ type: SET_CHAT_LOADING });

      MessageAPI.getAllConversations(token, clubId).then((response) => {
        debug && console.debug('ChatActions getAllConversations, club conversations, got:', response);

        if (response && response.success) {
          const currentConversations = reset ? [] : getState().chat.myConversations;
          for (const conversation of currentConversations) {
            const foundIndex = response.result.findIndex((chat) => chat.id === conversation.id);
            if (foundIndex >= 0) {
              // Conversation was updated?
              if (moment(conversation.updatedAt).isBefore(moment(response.result[foundIndex].updatedAt))) {
                dispatch({
                  type: UPDATE_CONVERSATION,
                  conversation: updateConversation(
                    response.result[foundIndex],
                    getState().session.session,
                    getState().club.members,
                    getState().club.partners,
                    getState().club.teams
                  ),
                });
              }
            }
          }

          const newConversations = response.result.filter(
            (aItem) => !currentConversations.find((bItem) => aItem.id === bItem.id)
          );

          dispatch({
            type: SET_MY_CONVERSATIONS,
            conversations: [
              ...newConversations.map((item) =>
                updateConversation(
                  item,
                  getState().session.session,
                  getState().club.members,
                  getState().club.partners,
                  getState().club.teams
                )
              ),
              ...currentConversations,
            ],
          });
        } else {
          dispatch({ type: SET_CHAT_ERROR });
        }

        const duration = Date.now() - start;
        setTimeout(() => {
          dispatch({ type: SET_CHAT_NOT_LOADING });
        }, Math.max(1000, duration));
      });
    } catch (error) {
      console.warn('getAllConversations, catch:', error);
      if (error) {
        dispatch({
          type: SET_CHAT_ERROR,
          errorCode: error,
        });
      }
    }
  };
}

/**
 * Add/create a new conversation
 *
 * @param {string} token
 * @param {string} clubId
 * @param chatName
 * @param parties
 * @param chatCoverFilename
 * @param callback
 */
export function addConversation(token, clubId, chatName, parties, chatCoverFilename, callback) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_CHAT_LOADING });

      MessageAPI.addClubConversation(token, clubId, chatName, parties, chatCoverFilename).then((response) => {
        debug && console.debug('ChatActions addClubConversation, got:', response);

        if (response && response.success) {
          dispatch({
            type: SET_MY_CONVERSATION,
            conversation: updateConversation(
              response.result,
              getState().session.session,
              getState().club.members,
              getState().club.partners,
              getState().club.teams
            ),
          });

          callback(response.success, response.result);
        } else {
          callback(false);

          dispatch({ type: SET_CHAT_ERROR });
        }
        dispatch({ type: SET_CHAT_NOT_LOADING });
      });
    } catch (error) {
      console.warn('addConversation, catch:', error);
      if (error) {
        dispatch({
          type: SET_CHAT_ERROR,
          errorCode: error,
        });
      }
    }
  };
}

/**
 * Add/create a new conversation
 *
 * @param {number} conversationId
 * @param {string} token
 * @param {string} chatName
 * @param parties
 * @param {string} chatCoverFilename
 * @param callback
 */
export function editConversation(conversationId, token, chatName, parties, chatCoverFilename, callback) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SET_CHAT_LOADING });

      MessageAPI.editClubConversation(conversationId, token, chatName, parties, chatCoverFilename).then((response) => {
        if (response && response.success) {
          dispatch({
            type: SET_MY_CONVERSATION,
            conversation: updateConversation(
              response.result,
              getState().session.session,
              getState().club.members,
              getState().club.partners,
              getState().club.teams
            ),
          });

          callback(response.success, response.result);
        } else {
          callback(false);

          dispatch({ type: SET_CHAT_ERROR });
        }
        dispatch({ type: SET_CHAT_NOT_LOADING });
      });
    } catch (error) {
      console.warn('editConversation, catch:', error);
      if (error) {
        dispatch({
          type: SET_CHAT_ERROR,
          errorCode: error,
        });
      }
    }
  };
}

/**
 * Function to delete a conversation.
 *
 * @param {string} token
 * @param conversationId
 * @param callback
 */
export function deleteConversation(token, conversationId, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_CHAT_LOADING });

      MessageAPI.deleteConversation(token, conversationId).then((response) => {
        debug && console.debug('ChatActions deleteConversation, got:', response);

        if (response && response.success) {
          dispatch({
            type: DELETE_CONVERSATION,
            conversationId,
          });

          callback(response.success);
        } else {
          callback(false);

          dispatch({ type: SET_CHAT_ERROR });
        }
        dispatch({ type: SET_CHAT_NOT_LOADING });
      });
    } catch (error) {
      console.warn('deleteConversation, catch:', error);
      if (error) {
        dispatch({
          type: SET_CHAT_ERROR,
          errorCode: error,
        });
      }
    }
  };
}

/**
 *
 * @param {string} token
 * @param {number} conversationId
 * @param {number} page
 * @param {number} limit
 * Function to get all messages exchanged in some conversation
 * @param callback
 */
export function getMessages(token, conversationId, page, limit, callback) {
  return async (dispatch, getState) => {
    try {
      const start = Date.now();
      dispatch({ type: SET_CHAT_LOADING });

      MessageAPI.getMessages(token, conversationId, page, limit).then((response) => {
        debug && console.debug('ChatActions getMessages, got:', response);

        if (response && response.success) {
          const gotMessages = response.result.messages.reverse();
          if (page === 1) {
            dispatch({
              type: SET_MY_MESSAGES,
              messages: gotMessages,
              page,
              limit,
            });
          } else {
            const currentMessages = getState().chat.myConversation?.messages || [];
            const newMessages = gotMessages.filter((aItem) => !currentMessages.find((bItem) => aItem.id === bItem.id));
            if (newMessages && newMessages.length) {
              dispatch({
                type: SET_MY_MESSAGES,
                messages: [...newMessages, ...currentMessages],
                page,
                limit,
              });
            }
          }

          callback(response.success, gotMessages);
        } else {
          callback(false);

          dispatch({ type: SET_CHAT_ERROR });
        }

        const duration = Date.now() - start;
        setTimeout(() => {
          dispatch({ type: SET_CHAT_NOT_LOADING });
        }, Math.max(1000, duration));
      });
    } catch (error) {
      console.warn('getMessages, catch:', error);
      if (error) {
        dispatch({
          type: SET_CHAT_ERROR,
          errorCode: error,
        });
      }
    }
  };
}

/**
 * Function to get information about a conversation
 * @param {string} token
 * @param {number} conversationId
 * @param callback
 */
export function getConversationInformation(token, conversationId, callback) {
  return async (dispatch, getState) => {
    try {
      const start = Date.now();
      dispatch({ type: SET_CHAT_LOADING });

      MessageAPI.getConversationInfo(token, conversationId).then((response) => {
        if (response && response.success) {
          debug && console.debug('ChatActions getConversationInformation, got:', response);

          const chat = updateConversation(
            response.result,
            getState().session.session,
            getState().club.members,
            getState().club.partners,
            getState().club.teams
          );
          debug && console.debug('ChatActions getConversationInformation, chat:', chat);

          const convocations = [];
          dispatch(
            getMessages(token, conversationId, INITIAL_PAGE, INITIAL_MESSAGES_COUNT, (success, messages) => {
              debug && console.debug('ChatActions getConversationInformation, getMessages, got:', response);
              if (!success) {
                return;
              }

              // Convocations that are not in the current messages (because of many messages...)
              for (const convocation of response.result.futureConvocations) {
                const found = convocations.find((item) => item.id === convocation.id);
                if (!found) {
                  const convocationTime = moment(
                    convocation.convocationDate + ' ' + convocation.convocationTime,
                    'DD-MM-YYYY hh:mm'
                  );
                  convocation.called = JSON.parse(convocation.convocationCalled);
                  // Outdated convocation? should be No ... but check anyway!
                  convocation.outdated = moment().isAfter(convocationTime);
                  if (!convocation.outdated && convocation.convocationId) {
                    dispatch(
                      getConvocationResponses(token, convocation.convocationId, (success, responses) => {
                        if (success) {
                          convocation.responses = responses;
                        }
                      })
                    );
                  }

                  // Relate convocation with its message
                  for (const message of messages) {
                    // console.log(convocation.convocationId, message.convocationId, message.responseToConvocation)
                    if (message.convocationId === convocation.convocationId) {
                      message.convocation = convocation;
                    }
                    if (message.responseToConvocation === convocation.convocationId) {
                      message.convocation = convocation;
                    }
                  }

                  convocations.push(convocation);
                }
              }

              chat.messages = messages;
              chat.convocations = convocations;

              dispatch({
                type: SET_MY_CONVERSATION,
                conversation: chat,
                hasMoreMessages: true,
              });

              const duration = Date.now() - start;
              setTimeout(() => {
                dispatch({ type: SET_CHAT_NOT_LOADING });
              }, Math.max(1000, duration));

              callback(response.success, chat);
            })
          );
        } else {
          callback(false);

          dispatch({ type: SET_CHAT_ERROR });
        }
      });
    } catch (error) {
      console.warn('getConversationInformation, catch:', error);
      if (error) {
        dispatch({
          type: SET_CHAT_ERROR,
          errorCode: error,
        });
      }
    }
  };
}

/**
 * Function to send message
 * @param {*} token
 * @param {*} conversationId
 * @param {*} content
 *  if messageType is '1', content: { text, image }
 *  if messageType is '2', content: { convocation : id, title, date, hour, lat, lng, address }
 *  if messageType is '3', content: { convocation reply }
 * @param callback
 */
export function sendMessage(token, conversationId, content, callback = () => {}) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_CHAT_LOADING });

      MessageAPI.sendMessage(token, conversationId, content?.text ?? '', content?.image ?? '').then((response) => {
        debug && console.debug('ChatActions sendMessage, got:', response);

        if (response && response.success) {
          dispatch({
            type: ADD_NEW_MESSAGE,
            message: response.result.message ?? null,
            messageType: NEW_MESSAGE_TYPE,
          });

          callback(response.success, response.result.message);
        } else {
          callback(false);

          dispatch({ type: SET_CHAT_ERROR });
        }
        dispatch({ type: SET_CHAT_NOT_LOADING });
      });
    } catch (error) {
      console.warn('sendMessage, catch:', error);
      if (error) {
        dispatch({
          type: SET_CHAT_ERROR,
          errorCode: error,
        });
      }
    }
  };
}

export function sendConvocation(token, conversationId, content, callback = () => {}) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_CHAT_LOADING });

      MessageAPI.sendConvocation(token, conversationId, content).then((response) => {
        debug && console.debug('ChatActions sendConvocation, got:', response);

        if (response && response.success) {
          dispatch({
            type: ADD_NEW_MESSAGE,
            message: response.result.message ?? null,
            messageType: NEW_CONVOCATION_TYPE,
          });

          callback(response.success, response.result.message);
        } else {
          callback(false);

          dispatch({ type: SET_CHAT_ERROR });
        }
        dispatch({ type: SET_CHAT_NOT_LOADING });
      });
    } catch (error) {
      console.warn('sendConvocation, catch:', error);
      if (error) {
        dispatch({
          type: SET_CHAT_ERROR,
          errorCode: error,
        });
      }
    }
  };
}

export function sendConvocationReply(token, conversationId, content, callback = () => {}) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_CHAT_LOADING });

      debug && console.debug('ChatActions sendConvocationReply, data:', conversationId, content);
      MessageAPI.sendConvocationReply(token, conversationId, content).then((response) => {
        debug && console.debug('ChatActions sendConvocationReply, got:', response);

        if (response && response.success) {
          dispatch({
            type: ADD_NEW_MESSAGE,
            message: response.result.message ?? null,
            messageType: CONVOCATION_ANSWER_TYPE,
          });

          callback(response.success, response.result.message);
        } else {
          callback(false);

          dispatch({ type: SET_CHAT_ERROR });
        }
        dispatch({ type: SET_CHAT_NOT_LOADING });
      });
    } catch (error) {
      console.warn('sendConvocationReply, catch:', error);
      if (error) {
        dispatch({
          type: SET_CHAT_ERROR,
          errorCode: error,
        });
      }
    }
  };
}

/**
 *  Function to get information about a convocation
 * @param {string} token
 * @param {string} convocationId
 * @param callback
 */
export function getConvocationResponses(token, convocationId, callback) {
  return async (dispatch) => {
    try {
      const start = Date.now();
      dispatch({ type: SET_CHAT_LOADING });

      MessageAPI.getConvocationResponses(token, convocationId).then((response) => {
        if (response && response.success) {
          debug && console.debug('ChatActions getConvocationResponses, got:', response);

          for (const reply of response.result) {
            reply.convocationId = convocationId;
            reply.firstName = reply.userFirstname ?? 'John';
            reply.lastName = reply.userLastname ?? 'Doe';
            // Only Yes / No, May be i not allowed for a convocation!
            reply.accepted = reply.PresenceReplyType_id === REPLY_YES;
          }

          dispatch({
            type: SET_CONVOCATION_RESPONSES,
            convocationId,
            responses: response.result,
          });

          callback(response.success, response.result);
        } else {
          callback(false);

          dispatch({ type: SET_CHAT_ERROR });
        }
      });

      const duration = Date.now() - start;
      setTimeout(() => {
        dispatch({ type: SET_CHAT_NOT_LOADING });
      }, Math.max(1000, duration));
    } catch (error) {
      console.warn('getConvocationResponses, catch:', error);
      if (error) {
        dispatch({
          type: SET_CHAT_ERROR,
          errorCode: error,
        });
      }
    }
  };
}

// export function sendUserHasReadTheMessage(token, conversationId) {
//   return async (dispatch) => {
//     try {
//       if (token !== undefined && token !== null && conversationId !== undefined && conversationId !== null) {
//         const data = {
//           wsToken: token,
//           conversationId,
//         };
//         const request = await axios.post(getApiHost() + '/services/request/updateConversationLastSeenAt', data);
//       }
//     } catch (error) {
//       console.warn('sendUserHasReadTheMessage, catch:', error);
//       return null;
//     }
//   };
// }

export function addMessageFromNotification(message) {
  return (dispatch) => {
    try {
      if (message) {
        dispatch({
          type: ADD_NEW_MESSAGE_FROM_NOTIFICATION,
          message,
          hasReceivedNotification: true,
        });
      }
    } catch (error) {
      console.warn('addMessageFromNotification, catch:', error);
      if (error) {
        dispatch({
          type: SET_CHAT_ERROR,
          errorCode: error,
        });
      }
    }
  };
}

/**
 * Function to delete a message
 * @param {string} token
 * @param {string} messageId
 * @param callback
 */
export function removeMessage(token, messageId, callback = () => {}) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_CHAT_LOADING });

      MessageAPI.deleteMessage(token, messageId).then((response) => {
        debug && console.debug('ChatActions removeMessage, got:', response);

        if (response && response.success) {
          dispatch({
            type: DELETE_MESSAGE,
            messageId,
          });

          callback(response.success);
        } else {
          callback(false);

          dispatch({ type: SET_CHAT_ERROR });
        }
        dispatch({ type: SET_CHAT_NOT_LOADING });
      });
    } catch (error) {
      console.warn('removeMessage, catch:', error);
      if (error) {
        dispatch({
          type: SET_CHAT_ERROR,
          errorCode: error,
        });
      }
    }
  };
}

/**
 * Function to update the new data for an old format conversation (add the missing membersRoles property)
 *  if messageType is '1', content: { text, image }
 *  if messageType is '2', content: { convocation : id, title, date, hour, lat, lng, address }
 *  if messageType is '3', content: { convocation reply }
 * @param membersRoles
 */
export function updateMembersRoles(membersRoles) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_MEMBERS_ROLES,
      membersRoles,
    });
  };
}
