import ShadowCard from '@components/ShadowCard/ShadowCard';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import alert from '@utils/alert';
import * as WebBrowser from 'expo-web-browser';
import React, { Component } from 'react';
import { Dimensions, Pressable, StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';

const { width: sw } = Dimensions.get('window');

class DocumentListCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: '',
      upload: false,
      document: '',
    };
  }

  onViewDoc = async () => {
    if (this.props.itemDocument && this.props.itemDocument.model) {
      WebBrowser.openBrowserAsync(getImageApiHostOrUrl(this.props.itemDocument.model));
    } else {
      alert('Information', 'Pas de modèle pour ce document');
    }
  };

  render() {
    return (
      <ShadowCard style={[styles.mainContainer]}>
        <Pressable
          style={[styles.documentCard, { justifyContent: 'space-between' }]}
          onPress={() => {
            this.onViewDoc();
          }}>
          <View style={[{ width: sw * 0.6 }]}>
            <Text style={styles.titleDocument}>{this.props.itemDocument.label} </Text>
            <Text style={styles.numberMo}>- {}</Text>
          </View>
          <View style={[{ flexDirection: 'row', justifyContent: 'center' }]}>
            <Icon name="cloud-download-outline" size={32} color={Colors.blueCorporate} />
          </View>
        </Pressable>
      </ShadowCard>
    );
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    marginHorizontal: 10,
    marginTop: 0,
    // backgroundColor: Colors.blueSky,
  },
  documentCard: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    overflow: 'visible',
    borderColor: 'transparent',
    padding: 20,
    minHeight: 80,
  },
  viewTitle: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
  },
  titleDocument: {
    color: Colors.blueBgGradient1,
    fontFamily: 'appFontRegular',
    fontSize: 16,
  },
  numberMo: {
    fontFamily: 'appFontRegular',
    fontSize: sw * 0.02,
    color: Colors.gray1,
  },

  shadowEffect: {
    marginBottom: 15,

    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },
});

export default DocumentListCard;
