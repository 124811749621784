import Loading from '@components/Loading/Loading';
import Colors from '@configs/colors';
import { Image } from 'expo-image';
import React, { useEffect, useState } from 'react';

export const ImageNoLoader = ({ source }) => {
  const debug = false;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    debug && console.debug(`ImageNoLoader, ${source?.id || '0'} source:`, source);
  }, []);

  return (
    <>
      <Loading loading={loading} size="small" color={Colors.blueCorporate} />
      <Image
        source={source}
        style={{ borderRadius: 10, width: source.width, height: source.height, marginRight: 10 }}
        cachePolicy="disk"
        contentPosition="center"
        contentFit="contain"
        placeholder={require('@assets/images/spinner-64.gif')}
        placeholderContentFit="scale-down"
        onProgress={(event) => {
          debug &&
            console.debug(
              `ImageNoLoader, ${source?.id || '0'} progressing media ${source.uri}, size: ${event.loaded}/${
                event.total
              }...`
            );
        }}
        onLoadStart={() => {
          debug && console.debug(`ImageNoLoader, ${source?.id || '0'} loading media ${source.uri}...`);
          setLoading(false);
        }}
        onLoadEnd={() => {
          debug && console.debug(`ImageNoLoader, ${source?.id || '0'} loaded ${source.uri}`);
          setLoading(false);
        }}
        onError={(event) => {
          // console.warn(`ImageNoLoader, ${source?.id || '0'} loading error ${source.uri}`, event.error);
          debug && console.debug(`ImageNoLoader, ${source?.id || '0'} loading error ${source.uri}`, event.error);
          setLoading(false);
        }}
      />
    </>
  );
};
