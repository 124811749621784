import Colors from '@configs/colors';
import { gMaxWidth } from '@configs/styles';
import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { Dimensions, ImageBackground, Platform, View } from 'react-native';

const { width: sw, height: sh } = Dimensions.get('window');

const GradientBackground = ({
  gradient = false,
  splash = false,
  children,
  customStyle = {},
  colors = [Colors.gray, Colors.whiteCorporate, Colors.gray],
}) => {
  if (!gradient) {
    if (splash) {
      return (
        <View style={[{ flex: 1 }, customStyle]}>
          <ImageBackground
            source={require('@assets/splash.png')}
            style={{ flex: 1, width: Platform.OS === 'web' ? gMaxWidth : sw, height: sh, resizeMode: 'cover' }}>
            <>
              <View style={{ flex: 1, width: Platform.OS === 'web' ? gMaxWidth : sw, height: sh }}>{children}</View>
            </>
          </ImageBackground>
        </View>
      );
    }
    return (
      <View style={[{ flex: 1 }, customStyle]}>
        <ImageBackground
          source={require('@assets/background.png')}
          style={{ flex: 1, width: Platform.OS === 'web' ? gMaxWidth : sw, height: sh, resizeMode: 'cover' }}>
          <>
            <View style={[{ flex: 1, width: Platform.OS === 'web' ? gMaxWidth : sw, height: sh }]}>{children}</View>
          </>
        </ImageBackground>
      </View>
    );
  }
  return (
    <LinearGradient style={[{ flex: 1 }, customStyle]} colors={colors} start={[0, 0]} end={[0, 1]}>
      {children}
    </LinearGradient>
  );
};

export default GradientBackground;
