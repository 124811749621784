import ButtonBase from '@components/ButtonBase/ButtonBase';
import ButtonCancel from '@components/ButtonCancel/ButtonCancel';
import Colors from '@configs/colors';
import { gMaxWidth } from '@configs/styles';
import I18n from '@utils/i18n';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Dimensions, FlatList, Modal, Platform, Pressable, StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { useSelector } from 'react-redux';

const { width: sw, height: sh } = Dimensions.get('window');

/**
 * Screen used to choose between recipients types
 * @returns // screen
 */
const SelectRecipientsType = ({
  visible = false,
  recipients = null,
  title = 'Choisissez un destinataire',
  selectable = false,
  allSelectable = true,
  allSelectableLabel = 'Tous',
  selected = null,
  onClose = () => {},
  onSelected = (item) => {},
}) => {
  const debug = false;

  const { constRecipientsTypes: recipientsTypes } = useSelector((state) => state.app);

  const [initialized, setInitialized] = useState(false);

  const [selectableItems, setSelectableItems] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const [choicesHeight, setChoicesHeight] = useState(80);
  const [maxDisplayedChoices, setMaxDisplayedChoices] = useState(8);

  useEffect(() => {
    const items = recipients ?? recipientsTypes ?? [];
    if (allSelectable) {
      setSelectableItems([{ id: 0, name: allSelectableLabel }, ...items]);
    } else {
      setSelectableItems([...items]);
    }
  }, [recipients, recipientsTypes]);

  useEffect(() => {
    if (!selectableItems) {
      return;
    }
    debug && console.debug('SelectRecipientsType, selectable items:', selectableItems);

    // Compute choices list display space
    const maximumDisplayableChoices = Math.floor((sh - 320) / 60);
    setMaxDisplayedChoices(Math.floor((sh - 320) / 60));
    setChoicesHeight(Math.min(selectableItems.length * 60, Math.min(maximumDisplayableChoices * 60, sh - 160)));
    debug && console.debug('SelectRecipientsType, maximum choices:', maximumDisplayableChoices);

    setInitialized(true);
  }, [selectableItems]);

  useEffect(() => {
    if (!selected) {
      return;
    }
    if (allSelectable && selectableItems.length > 1) {
      setAllSelected(selected.length >= selectableItems.length - 1);
    }
  }, [selected, selectableItems]);

  if (!initialized || !visible) {
    return;
  }

  return (
    <Modal visible={visible} transparent>
      <View style={[styles.rootContainer]}>
        <View style={[styles.centered, styles.shadowEffect]}>
          <Text style={styles.pageTitle}>{title}</Text>

          <View style={{ height: 20 + choicesHeight }}>
            <FlatList
              style={{ height: choicesHeight }}
              data={selectableItems}
              keyExtractor={(item, index) => item.id}
              showsVerticalScrollIndicator={false}
              renderItem={({ item, index, separators }) => {
                if (selectable) {
                  return (
                    <Pressable
                      style={[
                        styles.itemContainer,
                        item.id === 0 ? { borderBottomColor: Colors.blueCorporate, borderBottomWidth: 1 } : null,
                      ]}
                      onPress={() => onSelected(item)}>
                      <Text style={styles.itemText} ellipsisMode="tail" numberOfLines={1}>
                        {item.name}
                      </Text>
                      <Icon
                        size={25}
                        color={Colors.black}
                        name={
                          (index === 0 && allSelectable && allSelected) ||
                          selected.find((element) => element.id === item.id)
                            ? 'checkbox-outline'
                            : 'square-outline'
                        }
                      />
                    </Pressable>
                  );
                }
                return (
                  <View style={[styles.itemContainer]}>
                    <ButtonBase style={{ width: '100%' }} label={item.name} onPress={() => onSelected(item)} />
                  </View>
                );
              }}
            />
            {selectableItems.length > maxDisplayedChoices && (
              <View style={{ height: 20, alignSelf: 'center' }}>
                <Icon size={20} name="ellipsis-horizontal" color={Colors.blueCorporate} />
              </View>
            )}
          </View>

          <View style={[styles.buttonsContainer]}>
            <ButtonCancel label={I18n.t('app.close')} onPress={() => onClose()} />
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    width: Platform.OS === 'web' ? gMaxWidth : sw,
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginVertical: 40,
  },
  centered: {
    backgroundColor: Colors.grayWhite,
    paddingVertical: 10,
    paddingHorizontal: 20,
    width: Platform.OS === 'web' ? gMaxWidth - 40 : sw - 40,
    borderRadius: 10,

    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  shadowEffect: {
    marginBottom: 15,

    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },
  pageTitle: {
    width: sw - 100,
    fontWeight: 'bold',
    fontSize: 24,
    color: Colors.blueCorporate,

    textAlign: 'center',

    marginVertical: 20,
  },
  itemContainer: {
    width: Platform.OS === 'web' ? gMaxWidth - 80 : sw - 80,
    height: 60,

    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    paddingVertical: 10,
    paddingHorizontal: 25,
  },
  itemText: {
    fontSize: 16,
    paddingHorizontal: 10,
    width: sw - 140,
    borderRadius: 100,
  },
  buttonsContainer: {
    marginTop: 20,
    width: 120,
  },
});

SelectRecipientsType.propTypes = {
  visible: PropTypes.bool,
  selectable: PropTypes.bool,
  allSelectable: PropTypes.bool,
  selected: PropTypes.array,
  onClose: PropTypes.func,
  onSelected: PropTypes.func,
};

export default SelectRecipientsType;
