import { Platform } from 'react-native';

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  const ab = new ArrayBuffer(byteString.length);
  const arr = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    arr[i] = byteString.charCodeAt(i);
  }

  return new Blob([arr], { name: 'test.png', type: mimeString || 'image/png' });
}

const createFormData = (file, token = null, fielFieldName = 'file_data') => {
  const data = new FormData();
  const body = {
    file_height: file.height.toString(),
    file_width: file.width.toString(),
  };
  if (token) {
    body.wsToken = token;
  }

  const fileName = file.uri.substring(file.uri.lastIndexOf('/') + 1, file.uri.length);

  // base64 Web browser images... specific behaviour!
  if (file.uri.startsWith('data:') && Platform.OS === 'web') {
    const imageData = dataURItoBlob(file.uri);
    body.mimeType = imageData.type || 'image/png';
    body.type = imageData.type.split('/')[0] || 'image';
    body.extension = imageData.type.split('/')[1] || 'png';
    body.file_name = fileName + '.' + body.extension;

    data.append(fielFieldName, dataURItoBlob(file.uri), body.file_name);
  } else {
    // ImagePicker saves the taken photo to disk and returns a local URI to it
    const localUri = file.uri;
    const filename = localUri.split('/').pop();

    // Infer the type of the image
    const match = /\.(\w+)$/.exec(filename);
    const type = match ? `${file.type}/${match[1]}` : `image`;
    body.mimeType = type || 'image/png';
    body.file_name = fileName;

    data.append(fielFieldName, {
      name: fileName,
      type,
      uri: localUri,
    });
  }

  Object.keys(body).forEach((key) => {
    data.append(key, body[key]);
  });

  return data;
};

export default createFormData;
