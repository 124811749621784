import { AccountAPI } from '@apis/account';
import ButtonBase from '@components/ButtonBase/ButtonBase';
import HeaderCollapsible, {
  HEADER_MAX_HEIGHT,
  HEADER_MIN_HEIGHT,
} from '@components/HeaderCollapsible/HeaderCollapsible';
import Loading from '@components/Loading/Loading';
import Colors from '@configs/colors';
import { gMaxWidth } from '@configs/styles';
import ActualityCard from '@containers/HomeStack/ActualityCard/ActualityCard';
import FabMenu from '@containers/HomeStack/FabMenu';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useIsFocused } from '@react-navigation/native';
import * as actions from '@redux/actions';
import { handleBadgeNotification } from '@redux/actions';
import { UPDATE_PENDING_FILTER } from '@redux/reducers';
import { SET_TOKENS, SET_SIGNED_IN } from '@redux/storeReducer';
import alert from '@utils/alert';
import { checkIsModeratorOrAdmin } from '@utils/checkUserRoles';
import I18n from '@utils/i18n';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Platform, Text, View } from 'react-native';
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import { useDispatch, useSelector } from 'react-redux';

const appSlug = Constants.expoConfig.slug || 'eappli';

const TabHome = ({ navigation, route }) => {
  const debug = false;

  const dispatch = useDispatch();

  const isFocused = useIsFocused();

  const [currentClub, setCurrentClub] = useState(null);

  const { apiConfiguration, tokens } = useSelector((state) => state.app);
  const { session, affiliatedClubInformation, allAffiliatedClubs, favorites, myClubFilter, pendingFilter } =
    useSelector((state) => state.session);
  const { notificationNewActualityId, notificationLikeActualityId, notificationCommentActualityId } = useSelector(
    (state) => state.notifications
  );
  const { actualities, actualitiesLimit, hasNextPage } = useSelector((state) => state.actuality);

  const [sessionRefresherInterval, setSessionRefresherInterval] = useState(null);

  const [refreshPage, setRefreshPage] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const prevFilter = useRef({ myClubFilter, pendingFilter }).current;

  const [hasAccess, setHasAccess] = useState(false);
  const [validatingActualities, setValidatingActualities] = useState(false);

  const scrollY = useSharedValue(0);
  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (event) => {
      scrollY.value = event.contentOffset.y;
    },
  });
  const listStyle = useAnimatedStyle(() => {
    return {
      marginTop: interpolate(
        scrollY.value,
        [0, HEADER_MAX_HEIGHT],
        [HEADER_MAX_HEIGHT, HEADER_MIN_HEIGHT],
        Extrapolation.CLAMP
      ),
    };
  });

  const handleAffiliation = (clubId) => {
    debug && console.debug('Home, handleAffiliation');

    dispatch(handleBadgeNotification('home', false));

    isFocused && setPageNumber(0);
  };

  useEffect(() => {
    if (!tokens || !tokens.wsToken || !tokens.refreshToken) {
      dispatch({ type: SET_SIGNED_IN, value: false });
      return;
    }

    const bootstrapAsync = async () => {
      dispatch(handleBadgeNotification('home', false));
      await Notifications.setBadgeCountAsync(0);
    };

    bootstrapAsync();
  }, []);

  useEffect(() => {
    debug && console.debug('Home got focus:', isFocused, route);
    if (!isFocused || !currentClub) return;

    if (route.params && route.params.notification) {
      dispatch(handleBadgeNotification('home', false));

      const foundIndex = actualities.findIndex((item) => item.id === route.params.notification.targetId);
      if (foundIndex >= 0) {
        // TODO - this requires to implement getItemLayout for the FlatList ... else there is no scroll, as of now!
        debug && console.debug('Home, found notification target actuality', route.params.notification.targetId);
        // this.refScroller.current.scrollToIndex({
        //   index: foundIndex,
        //   animated: true,
        //   viewOffset: 0,
        //   viewPosition: 0.5,
        // });
      } else {
        debug && console.debug('Home, did not found notification target actuality', route.params.notification.targetId);
      }
    }
  }, [isFocused]);

  useEffect(() => {
    debug && console.debug('Home update current club', affiliatedClubInformation);

    setCurrentClub(affiliatedClubInformation ?? session?.club ?? null);
  }, [session, affiliatedClubInformation]);

  useEffect(() => {
    if (!currentClub) return;
    debug && console.debug('Home updated currentClub');

    // this.sessionRefresher();
    if (!sessionRefresherInterval) {
      console.info(
        `Home, setting the session refresher interval: ${Constants.expoConfig.extra.periodicalRefresh / 1000 / 60} min`
      );

      setSessionRefresherInterval(
        setInterval(() => {
          sessionRefresher();
        }, Constants.expoConfig.extra.periodicalRefresh)
      );
    }

    if (currentClub.isAffiliated !== '1') {
      navigation.navigate('NotAffiliated');
    }

    if (currentClub.isAffiliated !== '1') {
      navigation.navigate('NotAffiliated');
    } else {
      setHasAccess(checkIsModeratorOrAdmin(currentClub));

      isFocused && handleAffiliation();
    }

    dispatch(handleBadgeNotification('home', false));

    refreshAllNews();
  }, [currentClub]);

  useEffect(() => {
    setValidatingActualities(hasAccess && apiConfiguration?.validateActualities && pendingFilter);
  }, [hasAccess, apiConfiguration, pendingFilter]);

  useEffect(() => {
    if (!notificationNewActualityId) {
      return;
    }
    debug && console.debug('Home got a notification for a new actuality');
    dispatch(
      actions.getNews(tokens.wsToken, notificationNewActualityId, (success, result) => {
        if (success) {
          // callback && callback(success, result);
        } else {
          // callback && callback(false);
        }
      })
    );
  }, [notificationNewActualityId, notificationLikeActualityId, notificationCommentActualityId]);

  useEffect(() => {
    if (!currentClub) return;

    debug && console.debug('Home updated page number:', pageNumber);

    if (prevFilter.myClubFilter !== myClubFilter || prevFilter.pendingFilter !== pendingFilter) {
      debug && console.debug('Home updated my club / pending filters:', myClubFilter, pendingFilter);
      // Just changed filtering - reset page number
      setPageNumber(0);

      return () => {
        prevFilter.myClubFilter = myClubFilter;
        prevFilter.pendingFilter = pendingFilter;
      };
    }

    if (pageNumber === 0) {
      return setPageNumber(1);
    }

    if (myClubFilter || pendingFilter) {
      // case user has selected "filter my club"
      getActualities([currentClub.id], [], []);
    } else {
      if (favorites.someExists) {
        // case user has not selected "filter my club" but has selected "favorite"
        // we make pagination with information coming from the filter made in "favorite"
        const { clubsIds, regionsIds, departmentsIds } = favorites;

        // All my affiliated clubs and my favorite clubs
        const clubIdsToSend = clubsIds ?? [];
        allAffiliatedClubs.map((club) => {
          if (!clubIdsToSend.some((id) => id === club.id)) {
            clubIdsToSend.push(club.id);
          }
        });

        getActualities(clubIdsToSend, departmentsIds, regionsIds);
      } else {
        getActualities([], [], []);
      }
    }
  }, [pageNumber, myClubFilter, pendingFilter]);

  const sessionRefresher = () => {
    debug && console.debug('Home, session refresher processing...', tokens);

    if (!tokens || !tokens.refreshToken || !tokens.wsToken) {
      dispatch({ type: SET_SIGNED_IN, value: false });
      return;
    }

    AccountAPI.authRefresh(tokens.refreshToken).then((response) => {
      if (response && response.success) {
        try {
          const newTokens = response.result;
          if (newTokens.wsToken !== tokens.wsToken || newTokens.refreshToken !== tokens.refreshToken) {
            AsyncStorage.setItem(`${appSlug}-tokens`, JSON.stringify(newTokens));

            dispatch({ type: SET_TOKENS, value: newTokens });
          }

          // // Do not refresh... user will manage by himself -)
          // this.refreshActualities(tokens.wsToken);
        } catch (error) {
          console.warn('Home, sessionRefresher, error', error);
        }
      } else if (response?.error_code === 10) {
        console.warn('Session expired while refreshing tokens!');
        // session expire => Alert before  => redirect to Login page
      }
    });
  };

  /**
   * Function to refresh all news from scratch
   * ---
   * If a club identifier is provided it is used in place of the current club identifier, used when
   * affiliated club is switched.
   */
  const refreshAllNews = useCallback((clubId = null) => {
    setPageNumber(0);
  }, []);

  /**
   * Function to get some new actualities
   */
  const getActualities = (clubIds, committeeIds, leagueIds) => {
    debug &&
      console.debug(
        `Home getActualities, isMyClub: ${myClubFilter}, hasNextPage: ${hasNextPage}, pendingActualities: ${pendingFilter}`
      );
    if (pageNumber === 0) {
      console.warn(`Home getActualities, pageNumber: ${pageNumber}`);
      return;
    }

    if (refreshPage) {
      return;
    }

    debug && console.debug(`Home getActualities, clubIds, committeeIds, leagueIds`, clubIds, committeeIds, leagueIds);

    // Once I got undefined and 0 in the Redux store, protect against this!
    let limit = pageLimit;
    if (!limit || limit <= 0) {
      setPageLimit(actualitiesLimit ?? 10);
      limit = actualitiesLimit ?? 10;
    }
    debug && console.debug(`Home getActualities, page: ${pageNumber}, limit: ${limit}`);

    setRefreshPage(true);

    const clubIdsToSend = clubIds ?? [];
    if (!myClubFilter) {
      allAffiliatedClubs.map((club) => {
        if (!clubIdsToSend.some((id) => id === club.id)) {
          clubIdsToSend.push(club.id);
        }
      });
    }

    dispatch(
      actions.getActualities(
        tokens.wsToken,
        pageNumber,
        limit,
        myClubFilter,
        clubIdsToSend,
        committeeIds,
        leagueIds,
        pageNumber === 1,
        pendingFilter,
        (success, result) => {
          if (result.news.length <= 0) {
            // No more data, current page is back one page
            const newPageNumber = pageNumber - 1;
            if (newPageNumber > 0) {
              setPageNumber(newPageNumber);
            }
          }
          debug && console.debug(`Home getActualities, refreshed, page: ${pageNumber}, count: ${result.news.length}`);

          setRefreshPage(false);
        }
      )
    );
  };

  const loadNextPage = () => {
    debug && console.debug(`Home loadNextPage, refreshPage: ${refreshPage}, hasNextPage: ${hasNextPage}`);
    if (refreshPage) {
      return;
    }

    if (!hasNextPage) {
      return;
    }

    setPageNumber(pageNumber + 1);
  };

  const deleteActuality = async (actualityId) => {
    debug && console.debug('Home, request to delete an actuality', actualityId);

    dispatch(
      actions.deleteNews(tokens.wsToken, actualityId, (success) => {
        if (!success) {
          alert(I18n.t('app.oups'), "Une erreur est survenue lors de la suppression de l'actualité");
        }
      })
    );
  };

  const validateActuality = async (actualityId) => {
    debug && console.debug('Home, request to validate an actuality', actualityId);

    dispatch(
      actions.validateNews(tokens.wsToken, actualityId, (success) => {
        if (!success) {
          alert(I18n.t('app.oups'), "Une erreur est survenue lors de la validation de l'actualité");
        }
      })
    );
  };

  const renderSeparator = useCallback(({ item, index }) => {
    return <View style={{ borderColor: Colors.iconGray + '88', borderTopWidth: 3 }} />;
  }, []);

  const renderItem = useCallback(({ item, index, target }) => {
    return (
      <ActualityCard
        // isInView={visibleItemsIds.includes(item.id)}
        actu={item}
        index={index}
        navigation={navigation}
        onRefresh={() => {
          refreshAllNews();
        }}
        onDelete={async () => {
          await deleteActuality(item.id);
        }}
        onValidate={async () => {
          await validateActuality(item.id);
        }}
      />
    );
  }, []);

  if (!currentClub) {
    return;
  }

  return (
    <View style={[{ flex: 1, marginBottom: 60 }, Platform.OS === 'web' ? { width: '100%', maxWidth: gMaxWidth } : {}]}>
      <HeaderCollapsible
        navigation={navigation}
        route={route}
        headerTitle={session && (currentClub.name ?? 'Guest')}
        contentScrolled={scrollY}
        displayExchangeIcon={allAffiliatedClubs.length > 1}
        onExchange={(clubId) => handleAffiliation(clubId)}
      />

      <Animated.FlatList
        style={listStyle}
        keyExtractor={(item, index) => `News-${item.id}`}
        estimatedItemSize={400}
        initialNumToRender={3}
        maxToRenderPerBatch={2}
        windowSize={3}
        removeClippedSubviews
        scrollEnabled
        scrollEventThrottle={1}
        showsVerticalScrollIndicator
        onScroll={scrollHandler}
        data={actualities}
        ListHeaderComponent={() => {
          if (!validatingActualities) {
            return null;
          }

          return (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ marginTop: 10, color: Colors.blueCorporate + 'CC', fontSize: 18 }}>
                {' '}
                Vous êtes en mode validation d'actualités
              </Text>

              <ButtonBase
                label="Quitter ce mode"
                onPress={() => {
                  dispatch({ type: UPDATE_PENDING_FILTER, pendingFilter: false });
                }}
              />
            </View>
          );
        }}
        ItemSeparatorComponent={renderSeparator}
        renderItem={renderItem}
        refreshing={false}
        onRefresh={() => refreshAllNews()}
        onEndReachedThreshold={0.5}
        onEndReached={() => {
          loadNextPage();
        }}
      />

      <FabMenu
        navigation={navigation}
        currentClub={currentClub}
        isMyClub={myClubFilter}
        pendingFilter={pendingFilter}
        isAdministrator={session.user.isAdministrator}
      />

      <Loading loading={refreshPage} />
    </View>
  );
};

export default TabHome;
