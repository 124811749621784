import { Types } from '@apis/apisConstants';
import ResultsFavorites from '@containers/ResultsStack/ResultsFavorites';
import ResultsWebView from '@containers/ResultsStack/ResultsWebView';
import Results from '@containers/TabResults';
import ModalNotifications from '@navigation/modals/ModalNotifications';
import NotAffiliated from '@navigation/modals/NotAffiliated';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import i18n from '@utils/i18n';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Stack = createNativeStackNavigator();

const ResultsStack = () => {
  const debug = false;

  const { apiConfiguration } = useSelector((state) => state.app);
  // const { session } = useSelector((state) => state.session);

  useEffect(() => {
    debug && console.debug(`Navigation, API configuration`, apiConfiguration);
  }, [apiConfiguration]);

  return (
    <Stack.Navigator
      id="ResultsStack"
      initialRouteName="Results"
      screenOptions={{ headerShown: false, title: i18n.t('pageTitle.results') }}>
      <Stack.Group>
        <Stack.Screen name="Results" component={Results} />
        <Stack.Screen
          name="Nation"
          component={ResultsWebView}
          initialParams={{
            title: 'Nationales',
            url: 'https://www.ffhandball.fr/fr/competitions/national',
            type: Types.COMPETITION_TYPE.NATIONAL,
          }}
        />
        <Stack.Screen
          name="Region"
          component={ResultsWebView}
          initialParams={{
            title: 'Régionales',
            url: 'https://www.ffhandball.fr/fr/competitions/regions',
            type: Types.COMPETITION_TYPE.REGIONAL,
          }}
        />
        <Stack.Screen
          name="Department"
          component={ResultsWebView}
          initialParams={{
            title: 'Départementales',
            url: 'https://www.ffhandball.fr/fr/competitions/departements',
            type: Types.COMPETITION_TYPE.DEPARTEMENTAL,
          }}
        />
        <Stack.Screen
          name="Cup"
          component={ResultsWebView}
          initialParams={{
            title: 'Coupe de France',
            url: 'https://www.ffhandball.fr/fr/competitions/coupe-de-france',
            type: Types.COMPETITION_TYPE.COUPE_DE_FRANCE,
          }}
        />
        <Stack.Screen name="Favorites" component={ResultsFavorites} initialParams={{ title: 'Mes favoris' }} />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="NotificationsList" component={ModalNotifications} />
        <Stack.Screen name="NotAffiliated" component={NotAffiliated} />
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default ResultsStack;
