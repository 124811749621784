const FRLanguage = {
  tabLabel: {
    home: 'Accueil',
    results: 'Résultats',
    agenda: 'Agenda',
    messages: 'Messages',
    profile: 'Mon compte',
  },
  pageTitle: {
    home: 'Accueil',
    results: 'Résultats',
    agenda: 'Agenda',
    messages: 'Messages',
    profile: 'Mon compte',
  },
  loading: {
    text: 'Merci de patienter quelques instants...\n\n\n\n\n\nprépare votre application.',
  },
  app: {
    oups: 'Désolé',
    success: 'Succès',
    confirm: 'Confirmation',
    information: 'Information',
    error: 'Erreur',
    yes: 'Oui',
    no: 'Non',
    close: 'Fermer',
    actions: {
      addActuality: 'Ajouter une actualité',
      searchClubs: 'Rechercher un club',
      favorites: 'Mes favoris',
      filterPendingActualities: 'Filtrer sur les actualités à valider',
      removePendingFilter: 'Enlever le filtre',
      clubFilter: 'Filtrer sur mon club',
      removeClubFilter: 'Enlever le filtre',
    },

    noRecipient: 'Sélectionner le destinataire',
    noEventTypeSelected: "Sélectionner un type d'événement",
    noActuTypeSelected: "Sélectionner un type d'actualité",
    invalidTitle: 'Le titre ne peut pas être vide',
    invalidDescription: 'La description ne peut pas être vide',
    invalidAddress: 'Adresse invalide',
    invalidAddressLength: 'Adresse invalide - la longueur ne peut pas excéder 255 caractères.',
    invalidLongitude: 'Longitude invalide',
    invalidLatitude: 'Latitude invalide',
    invalidHomeTeam: 'Équipe domicile invalide',
    invalidAwayTeam: 'Équipe visiteurs invalide',
    invalidHomeClub: 'Club domicile invalide',
    invalidAwayClub: 'Club visiteurs invalide',
    invalidDate: 'Date invalide',
    invalidTime: 'Heure invalide',

    invalidHomeTeamScore: 'Score intérieur invalide',
    invalidAwayTeamScore: 'Score extérieur invalide',
    invalidIsHalfTime: 'Score mi-temps invalide',
    invalidScoreComment: 'Commentaire invalide',

    noProfileType: 'Veuillez sélectionner vos rôles dans le club',
    invalidPhoneNumber: 'Numéro de téléphone invalide (10 chiffres)',
    invalidBirthdate: 'Date de naissance invalide',
    invalidCity: 'Ville invalide',
    invalidPostalCode: 'Code postal invalide (5 chiffres)',

    successfulPWChanged: 'Mot de passe modifié',

    invalidLastname: 'Vous devez renseigner un nom',
    invalidFirstname: 'Vous devez renseigner un prénom',
    invalidBirthDate: "La date de naissance n'est pas valide. L'âge minimum pour l'inscription est 6 ans.",
    invalidEmailAddress: "L'adresse email n'est pas valide. Par exemple: mon_adresse@mail.com",
    invalidPassword: 'Le mot de passe choisi doit comporter au moins 8 caractères',
    noMatchingClub: 'Aucun club trouvé',
    sessionStarterIssue: "Problème d'ouverture de votre session",
    saveEdit: 'Modifier',
    add: 'Ajouter',
    city: 'Ville',
    postalCode: 'Code postal',
    leftHand: 'Gaucher',
    rightHand: 'Droitier',
    front: 'Pivot',
    back: 'Arrière',
    trainNotEditable: 'Les entrainements ne sont pas modifiables',
    convocationNotEditable: 'Les convocations ne sont pas modifiables',
    confirmAgendaDelete: 'Êtes-vous sûr de vouloir supprimer cet agenda',
    possiblePosition: [
      'Gardien de but',
      'Arrière droit',
      'Ailier droit',
      'Pivot',
      'Ailier gauche',
      'Arrière gauche',
      'Demi-centre',
    ],

    notModerator: "Vous n'êtes pas modérateur du club.",
    noFavoriteClub: "Vous n'avez pas encore d'équipe favorite",

    loginPlease: 'Veuillez vous connecter pour continuer',
    noClub: 'Veuillez sélectionner votre club',
    noClubOrLicense: 'Veuillez saisir un numéro de licence ou sélectionner votre club',

    confirmDeleteActu: "Voulez-vous vraiment supprimer l'actualité ?",
    confirmValidateActu: "Voulez-vous vraiment valider l'actualité ?",
    confirmDeleteAgenda: 'Voulez-vous vraiment supprimer cet événement ?',

    noResult: 'Aucun résultat',
    noResultFound: 'Aucun résultat trouvé',

    noSize: 'Sélectionnez une taille',
    noQuantity: 'Veuillez choisir une quantité',
    billing: 'Facturation',
    discoveryMessage: 'Veuillez vous connecter pour profiter au maximum de',
    discoveryMode: "Découvrez l'application",
    notAffiliateMessage:
      "Ce club n'est pas affilié %{app}, vous pouvez lui demander de nous contacter ou nous envoyer ses coordonnées en cliquant ci-dessous",
    notAffiliateMessageMail: 'Affiliation de mon club',

    region: 'Région',
    department: 'Département',
    noClubs: 'Aucun club',

    regionsList: 'Liste des régions',
    departmentsList: 'Liste des départements',
    clubsList: 'Liste des clubs',

    generalError: 'Une erreur est survenue, veuillez réessayer plus tard.',

    confirmUserTeam: 'Voulez-vous confirmer que ce joueur rejoint votre équipe ?',
    deleteUserTeam: "Suppression d'un utilisateur de l'équipe",
    deleteUserTeamConfirm: 'Voulez-vous supprimer ce joueur de votre équipe ?',
  },
  account: {
    firstName: 'Prénom',
    lastName: 'Nom',
    email: 'Email',
    password: 'Mot de passe',
    birthDate: 'Date de naissance',
    notAffiliated: "Votre club n'est pas affilié ?",
  },
  login: {
    pageTitle: 'Connexion',
    saveAccess: 'Sauvegarder mes accès',
    signIn: 'Se connecter',
    failed: 'Connexion échouée. Vérifiez vos identifiants et essayez à nouveau.',
    signInWithoutVersion: 'Se connecter',
    lostPassword: 'Mot de passe oublié ?',
    newAccount: 'Rejoignez nous !',
  },
  lostPassword: {
    guide: 'Veuillez saisir votre adresse mail pour\nrecevoir un nouveau mot de passe',
    send: 'Envoyer',
  },
  datePicker: {
    confirm: 'Sélectionner',
    cancel: 'Annuler',
  },
  home: {
    general: 'Général',
    club: 'Club',
    pub: 'PUB',
  },
  results: {
    myClub: 'Mon Club',
    favorites: 'Favoris',
    competition: 'Compétitions',
  },
  message: {
    newMessage: 'Nouveau message',
    to: 'À : ',
    cancel: 'icon-md-close',
    add: 'icon-checkmark-outline',
    searchPlaceholder: 'Rechercher un membre...',
    categories: {
      undefined: 'xxx',

      teams: 'Équipes',
      teamsAll: 'toutes les équipes',

      selectedPartners: 'Partenaires sélectionnés',
      selectedMembers: 'Membres sélectionnés',
      selectedCategories: 'Catégories sélectionnées',
      selectedTeams: 'Équipes sélectionnées',

      found: 'Résultats de la recherche',

      partners: 'Partenaires',
      partnersAll: 'tous les partenaires',
      referees: 'Arbitres',
      refereesAll: 'tous les arbitres',
      volunteers: 'Bénévoles',
      volunteersAll: 'tous les bénévoles',
      parents: 'Parents',
      parentsAll: 'tous les parents',
      players: 'Joueurs',
      playersAll: 'tous les joueurs',
      coaches: 'Entraineurs',
      coachesAll: 'tous les entraineurs',
      staff: 'Staff',
      staffAll: 'tout le staff',
      managers: 'Dirigeants',
      managersAll: 'tous les dirigeants',
      board: 'Comité de direction',
      boardAll: 'tout le comité de direction',
      others: 'Amis du club',
      othersAll: 'tous les amis du club',
    },
    select: 'Sélectionner %{all}',
    deselect: 'Désélectionner %{all}',

    CreateConvocation: 'Nouvelle convocation',
    team: 'Équipe',
    groupName: 'Nom de la conversation',
    addGroupName: 'Nom (optionnel)',
    groupCreator: 'Créateur du groupe',
    noParticipants: 'Aucun participant',
    participants: 'Participants actuels :',
    countParticipants: '%{users} participants',
    countCalled: '%{users} convoqués',
    sentConvocation: 'Convocation émise à',
    writeMessage: 'Écrivez votre message',
    newContact: 'Ajout contact',
    confirm: 'Confirmer',
    convocationResponse: 'Réponse à une convocation',
    responseYes: 'Oui',
    responseNo: 'Non',
    textResponseYes: 'Je serai présent',
    textResponseNo: 'Je ne serai pas présent',
    manageGroup: 'Groupe',
    noConversation: 'Pas de conversations',
    noMessages: 'Aucun message',
  },
  createAccount: {
    pageTitle: 'Création de compte',
    previousStep: 'Étape précédente',
    nextStep: 'Étape suivante',
    profilType: 'Type de profil',
    profileSelect: 'Confirmer',
    licenseNumber: 'N° de licence',
    photo: 'Photo',
    multiChoice: 'Plusieurs choix possibles',
    myClub: 'Mon club',
    dontHaveLNumber: "Je n'ai pas de n° de licence",
    haveLNumber: "J'ai un n° de licence",
    lookForMyClub: 'Rechercher mon club',
    createMyAccount: 'Créer le compte',
    search: 'Rechercher',
    searchClub: 'Rechercher un club',
    searchTeam: 'Rechercher une équipe',
    chooseRoleInClub: 'Vos profils dans le club',
    chooseTeamInClub: 'Dans quelle équipe(s) ?',
  },
  addLicenceNumber: {
    pageTitle: 'Veuillez choisir votre club',
  },
  footerTabLabel: {
    home: 'Home',
    result: 'Résultats',
    agenda: 'Agenda',
    message: 'Messages',
    account: 'Mon compte',
  },
  menu: {
    clubInfo: 'Infos Club',
    club: 'Équipes',
    agenda: 'Agenda',
    document: 'Documents',
    shop: 'Boutique',
    partner: 'Partenaires',
    clubSpace: 'Espace Club',
    JoinClub: "S'affilier à ce club",
  },
  actuList: {
    replyYes: 'Oui',
    replyNo: 'Non',
    replyMayBe: 'Peut-être',
    typeActu: 'Actualité',
    typeEvent: 'Événement',
    typeScore: 'Score',
    publishedBy: 'publié par',
    seeMore: 'Voir plus',
    comments: 'Commentaires',
    participants: 'Participants',
    commentPlaceholder: 'Écrire votre commentaire',
    noActualities: 'Aucune actualité à montrer',
  },
  createActu: {
    newActuality: 'Nouvelle actualité',
    editActuality: "Modification d'actualité",
    typeActuality: "Type d'actualité",
    title: 'Titre',
    description: 'Description',
    place: 'Quand et où ?',
    addPhoto: 'Ajouter des photos/vidéos :',
    necessaryDocs: 'Documents nécessaires ?',
    askReply: 'Demande de réponse',
    date: 'Date',
    hour: 'Heure',
    recipient: 'Destinataires',
    create: 'Poster',
    photoLimit: 'Vous avez dépassé la limite de 30\nphotos, merci de faire une autre\npublication',
    sentForValidation:
      "Votre demande a bien été prise en compte,\nelle est en attente de validation par\nl'administrateur de votre club.",
    sendActuForModerator: 'votre demande a bien été prise en compte.',
    club1PlaceHolder: 'Équipe 1 (domicile)',
    club2PlaceHolder: 'Équipe 2 (visiteurs)',
    score: 'Score',
    commentPlaceholder: 'Commentaire',
    halfTime: 'Mi-temps',
    endOfMatch: 'Fin du match',
    mayReply: "(j'y participe, pas, peut-être)",
    everyWeek: 'Récurrent (hebdomadaire)',
    homeClub: 'Club domicile',
    homeClubTitle: 'Quel est le club qui reçoit à domicile ?',
    awayClub: 'Club visiteurs',
    awayClubTitle: 'Quel est le club visiteur ?',
    club: 'Équipe',
    homeTeam: 'Équipe domicile',
    homeTeamTitle: 'Quelle équipe dans le club qui reçoit ?',
    awayTeam: 'Équipe visiteurs',
    awayTeamTitle: 'Quelle équipe dans le club visiteur ?',
    startDate: 'Date de début',
    endDate: 'Fin de la récurrence',
    invalidSameTeam: 'Les deux équipes doivent être différentes',
  },
  place: {
    add: 'Valider',
  },
  takePhoto: {
    camera: 'Prendre une photo/vidéo',
    photo: 'Prendre une photo',
    video: 'Prendre une vidéo',
    gallery: 'Choisir dans la galerie',
    cancel: 'Annuler',
  },
  createEvent: {
    newEvent: 'Nouvel événement',
    editEvent: "Modification d'un événement",
    eventTypes: "Type d'événements",
    askReply: "Proposer d'indiquer sa présence?",
    sendNotification: 'Envoyer des notifications',
    recipientsAllTeams: 'Toutes les équipes',
    recipientTeam: 'Quelle équipe ?',
    recipients: 'Quelle(s) équipe(s) ?',
  },
  agenda: {
    filter: 'filtre',
    all: 'Terminé(s)',
    future: 'A venir',
    filterEvents: 'Événements',
    filterTeams: 'Équipes',
    detail: 'Détails',
    impossibleAction: 'Action impossible',
    dontHaveRights: "Vous n'avez pas les droits pour faire cette action.",
    participants: "Ont prévu d'être présents : ",
    participantsCount: '%{count} participants',
    noParticipants: "Personne n'a encore indiqué qu'il serait présent",
    futureEventDate: '%{day} prochain à %{time} ',
    pastEventDate: '%{day} dernier à %{time} ',
    noPastEvents: 'Aucun événement passé',
    noFutureEvents: 'Aucun événement à venir',
  },
  match: {
    resume: 'Résumé',
    classify: 'Classement',
    stat: 'Stats',
    matchSheet: 'Feuille de match',
    top: 'Top',
  },
  convocation: {
    confirmPresent: 'Vous pouvez confirmer votre présence',
    indicatePresent: 'Vous pouvez indiquer si vous serez présent',
    closed: 'Convocation dépassée',
    members: 'Membres convoqués',
    showConvoc: 'voir la convocation',
    date: 'Date',
    hour: 'Heure',
    title: 'Titre',
    description: 'Description de la convocation',
    send: 'Envoyer',
    userWillCome: 'Participe',
    maybeWillCome: 'Peut être',
    willNotCome: 'Ne participe pas',
    hoursOfConvocation: 'Date et heure de la convocation',
  },
  myAccount: {
    information: 'Merci de confirmer',
    messageAlertLogout: "Êtes-vous sûr de vouloir vous déconnecter de l'application ?",
    messageAlertDeleteAccount:
      'Êtes vous sûr de vouloir supprimer définitivement votre compte sur %{appName} pour ce club ?',
    yes: 'Oui',
    no: 'Non',
    modifyTheProfile: 'modifier la photo',
    addAvatarProfil: 'Ajouter une photo',
    moderator: 'Modérateur',
    firstName: 'Prénom',
    lastName: 'Nom',
    phone: 'Téléphone',
    mail: 'Email',
    address: 'Adresse',
    city: 'Ville',
    postalCode: 'Code postal',
    licence: 'Licence',
    allDocuments: 'Tous les documents',
    identifiers: 'Identifiants',
    password: 'Mot de passe',
    signOut: 'Me déconnecter',
    deleteMyAccount: 'Me désinscrire',
    myProfiles: 'Mes profils',
    noClubProfiles: "Vous n'avez pas de profils pour ce club",
    myClub: 'Mon club',
    myTeams: 'Mes équipes',
    noTeamsMembership: "Vous n'êtes membre d'aucune équipe",
    personalInformation: 'Informations personnelles',
    backLeft: 'Arrière gauche',
    backRight: 'Arrière droite',
    usePhoto: 'Choisir une photo',
    ok: 'OK',
    retake: 'Prendre une photo',
    validate: 'Valider',
    cancel: 'Annuler',

    emailNotValid: 'Adresse email non valide',
    emailAddress: 'Adresse email',
    edit: 'Modifier',
    myProfile: 'Mon profil',
    settings: 'Mes paramètres',

    passwordChange: 'Changement de mot de passe',
    oldPassword: 'Mot de passe actuel',
    newPassword: 'Nouveau mot de passe',
    confirmNewPassword: 'Confirmer le nouveau mot de passe',
    save: 'Enregistrer',

    moderation: {
      title: 'Paramètres modérateur',
      conversations: 'Voir toutes les conversations',
    },

    favorites: {
      title: 'Mes favoris',
      regions: 'Mes régions',
      departments: 'Mes départements',
      clubs: 'Mes clubs',
    },

    agreements: "Vous devez accepter les conditions d'utilisation pour vous connecter.",
    failed: 'Échec de la connexion. Vérifiez vos identifiants et essayez à nouveau.',
  },
  clubs: {
    searchBarPlaceholder: 'Rechercher une équipe ou un joueur',
    searchBarUserPlaceHolder: 'Rechercher un joueur',
    notAffiliated1: 'Il semble que votre club ne soit\npas encore affilié à %{appName}',
    notAffiliated2: 'Contactez votre club pour profiter des fonctionnalités de %{appName}',
    notAffiliated3: 'Vous pouvez vous inscrire et profiter de %{appName} en mode limité en continuant',
    notAffiliated4: 'Vous ne trouvez pas votre club ?\nCliquez ici pour contacter %{appName}',
    contact: 'Contactez',
    coach: 'Entraineurs',
    manager: 'Dirigeants',
    player: 'Joueurs',
    parents: 'Parents',
    agenda: 'Agenda',
  },
  clubInfos: {
    unknown: 'Non renseigné',
    clubOverview: 'Présentation du club',
    clubComposition: 'Composition du club',
    clubContact: 'Contacts',
    practicalInfos: 'Infos pratiques',
    gymnasium: 'Gymnases',
    gym: 'Gymnase',
    manager: 'Dirigeants',
  },
  player: {
    teammate: 'Équipiers',
    type: 'Joueur',
    agenda: 'Agenda',
    licence: 'Licence',
    licenceNumber: 'Numéro de licence',
    isLicenseConfirmed: 'Licence vérifié',
    askLicenseRenewal: 'Licence à renouveler',
  },
  notification: {
    pageTitle: 'NOTIFICATIONS',
  },
  partner: {
    viewWebSite: 'voir le site web',
  },
  shop: {
    productReference: 'référence du produits',
    size: 'Taille',
    amount: 'Quantité',
    totalPrice: 'Prix total',
    order: 'Commander',
    sendOrder: 'Envoyer la commande',
    cancelTheOrder: 'Annuler la commande',
    billing: 'Facturation',
    lastName: 'Nom',
    firstName: 'Prénom',
    address: 'Adresse',
    postalCode: 'Code postal',
    phoneNumber: 'N° de téléphone',
    optional: 'facultatif',
    city: 'Ville',
    comment: 'Commentaire',
    enterFirstName: 'Entrer votre prénom',
    enterLastName: 'Entrer votre nom',
    enterAdresse: 'Entrer votre Adresse',
    enterCity: 'Entrer votre code ville',
    enterPostalCode: 'Entrer votre code postal',
    enterComment: 'Entrer votre commentaire',
    quantity: 'Quantité',
    closePicker: 'Fermer',
    congratulateTitle: 'Félicitations',
    congratulateTitleErreur: 'Erreur',
    invalidePostalCode: 'Code Postal invalide',
    invalidePhoneNumber: 'N° de téléphone invalide',
    congratulateText:
      "Votre commande vient d'être envoyée au club. Il vous contactera dès reception pour récupérer et régler votre commande.",
    shopFailedTitle: 'Désolé',
    shopFailedText: "Votre commande n'a pas pu être prise en compte",
    shopError1201: "L'email de facturation est invalide",
    shopError1: "Il semblerait qu'un des champs n'est pas rempli",
    shopError1701: 'Le numéro de phone pour la facturation est invalide',
    shopError1801: 'Nous ne retrouvons plus ce produit',
    shopError1802: "La taille demandée n'est plus disponible",
  },
  documentUpload: {
    titleDocument: 'Titre document',
    information: 'Information',
    yes: 'Oui',
    cancel: 'Annuler',
    messageInfosDeleteDocument: 'Êtes-vous sûr de vouloir supprimer ce document?',
  },
  noInternet: {
    text: 'La connexion Internet semble perdue.\n\nMerci de réessayer ultérieurement.',
  },
  alerts: {
    cancel: 'Annuler',
    add: 'Ajouter',
    remove: 'Supprimer',
    update: 'Modifier',
    teamSelection: {
      title: 'Ajout de joueur',
      message: 'Voulez-vous ajouter ce joueur à léquipe ?',
    },
    removeTeamUser: {
      title: 'Suppression de joueur',
      message: 'Voulez-vous supprimer ce joueur de léquipe ?',
    },
    acceptTeamUser: {
      title: 'Confirmation de joueur',
      message: 'Voulez-vous confirmer ce joueur dans léquipe ?',
    },
    updateTeam: {
      title: "Modification de l'équipe",
      message: "Voulez-vous modifier l'équipe ?",
      error: "Désolé, une erreur s'est produite lors de la modification de l'équipe.",
    },
  },
};

export default FRLanguage;
