import ChatDetails from '@containers/MessagesStack/ChatDetails';
import Chatting from '@containers/MessagesStack/Chatting';
import ConvocationDetails from '@containers/MessagesStack/ConvocationDetails';
import CreateConvocation from '@containers/MessagesStack/CreateConvocation';
import MembersSelection from '@containers/MessagesStack/MembersSelection';
import UserTeamSelection from '@containers/MessagesStack/UserTeamSelection';
import Messages from '@containers/TabMessage';
import ModalNotifications from '@navigation/modals/ModalNotifications';
import NotAffiliated from '@navigation/modals/NotAffiliated';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import i18n from '@utils/i18n';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Stack = createNativeStackNavigator();

const MessagesStack = () => {
  const debug = false;

  const { userStatus } = useSelector((state) => state.app);

  useEffect(() => {
    debug && console.debug(`MessagesStack, user status`, userStatus);
  }, [userStatus]);

  return (
    <Stack.Navigator
      id="MessagesStack"
      initialRouteName="Conversations"
      screenOptions={{ headerShown: false, title: i18n.t('pageTitle.messages') }}>
      <Stack.Group>
        <Stack.Screen name="Conversations" component={Messages} options={{ headerShown: false }} />
        <Stack.Screen name="UserTeamSelection" component={UserTeamSelection} options={{ headerShown: false }} />
        <Stack.Screen name="MembersSelection" component={MembersSelection} options={{ headerShown: false }} />
        <Stack.Screen name="Conversation" component={Chatting} options={{ headerShown: false }} />
      </Stack.Group>

      <Stack.Group screenOptions={{ headerShown: false }}>
        <Stack.Screen name="NotificationsList" component={ModalNotifications} />
        <Stack.Screen name="NotAffiliated" component={NotAffiliated} />
        <Stack.Screen name="ChatDetails" component={ChatDetails} />
        <Stack.Screen name="CreateConvocation" component={CreateConvocation} />
        <Stack.Screen name="ConvocationDetails" component={ConvocationDetails} />
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default MessagesStack;
