import Images from '@assets/images';
import ButtonCancel from '@components/ButtonCancel/ButtonCancel';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import { gMaxWidth } from '@configs/styles';
import I18n from '@utils/i18n';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Dimensions, FlatList, Image, Modal, Platform, Pressable, StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { useSelector } from 'react-redux';

const { width: sw, height: sh } = Dimensions.get('window');

/**
 * Screen used to choose between several teams in the same club
 * @returns // screen
 */
const SelectClubTeams = ({ visible = false, onClose = () => {}, onSelected = (item) => {} }) => {
  const debug = false;

  const [initialized, setInitialized] = useState(false);

  const { selectedClubTeams } = useSelector((state) => state.app);

  const [choicesHeight, setChoicesHeight] = useState(80);
  const [maxDisplayed, setMaxDisplayed] = useState(8);

  useEffect(() => {
    if (!selectedClubTeams) {
      return;
    }
    debug && console.debug('SelectClubTeams, initial configuration screen height:', sh);

    // Compute clubs list display space
    const maximumDisplayable = Math.floor((sh - 320) / 80);
    setMaxDisplayed(Math.floor((sh - 320) / 80));
    setChoicesHeight(Math.min(selectedClubTeams.length * 80, Math.min(maximumDisplayable * 80, sh - 160)));
    debug && console.debug('SelectClubTeams, maximum displayable:', maximumDisplayable);

    setInitialized(true);
  }, [selectedClubTeams]);

  if (!initialized || !visible || !selectedClubTeams) {
    return;
  }

  return (
    <Modal visible={visible} transparent>
      <View style={[styles.rootContainer]}>
        <View style={[styles.centered, styles.shadowEffect]}>
          <Text style={styles.pageTitle}>Choisissez un des clubs que vous avez rejoint</Text>

          <View style={{ height: 20 + choicesHeight }}>
            <FlatList
              style={{ height: choicesHeight }}
              data={[...selectedClubTeams] ?? []}
              keyExtractor={(item, index) => index}
              showsVerticalScrollIndicator
              renderItem={({ item, index, separators }) => {
                return (
                  <Pressable style={[styles.itemContainer]} onPress={() => onSelected(item)}>
                    <View style={styles.containerImage}>
                      <Image
                        style={styles.image}
                        resizeMode="contain"
                        source={item.logo ? { uri: getImageApiHostOrUrl(item.logo) } : Images.bannerPlaceholderRounded}
                      />
                    </View>

                    <Text style={styles.clubName} ellipsisMode="tail" numberOfLines={1}>
                      {item.label ?? item.name}
                    </Text>
                  </Pressable>
                );
              }}
            />
            {selectedClubTeams.length > maxDisplayed && (
              <View style={{ height: 20, alignSelf: 'center' }}>
                <Icon size={20} name="ellipsis-horizontal" color={Colors.blueCorporate} />
              </View>
            )}
          </View>

          <View style={[styles.buttonsContainer]}>
            <ButtonCancel label={I18n.t('app.close')} onPress={() => onClose()} />
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    width: Platform.OS === 'web' ? gMaxWidth : sw,
    alignSelf: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginVertical: 40,
  },
  centered: {
    backgroundColor: Colors.grayWhite,
    paddingVertical: 10,
    paddingHorizontal: 20,
    width: Platform.OS === 'web' ? gMaxWidth - 40 : sw - 40,
    borderRadius: 10,

    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  shadowEffect: {
    marginBottom: 15,

    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },
  pageTitle: {
    width: sw - 100,
    fontWeight: 'bold',
    fontSize: 24,
    color: Colors.blueCorporate,

    textAlign: 'center',

    marginVertical: 20,
  },

  itemContainer: {
    width: Platform.OS === 'web' ? gMaxWidth - 80 : sw - 80,
    borderBottomWidth: 0.4,
    borderBottomColor: Colors.blueCorporate,

    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',

    height: 80,
    paddingVertical: 10,
  },
  containerImage: {
    height: 70,
    width: 70,
    borderRadius: 100,
  },
  image: {
    height: '100%',
    width: '100%',
    borderRadius: 100,
  },
  clubName: {
    color: Colors.blueCorporate,

    paddingHorizontal: 10,
    width: sw - 140,
    borderRadius: 100,
  },
  buttonsContainer: {
    marginTop: 20,
    width: 120,
  },
});

SelectClubTeams.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSelected: PropTypes.func,
};

export default SelectClubTeams;
