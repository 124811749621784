import { AccountAPI } from '@apis/account';
import Images from '@assets/images';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import ButtonCancel from '@components/ButtonCancel/ButtonCancel';
import ButtonValidate from '@components/ButtonValidate/ButtonValidate';
import ClubNotAffiliatedPopup from '@components/ClubNotAffiliatedPopup/ClubNotAffiliatedPopup';
import GradientBackground from '@components/GradientBackground/GradientBackground';
import Loading from '@components/Loading/Loading';
import SelectClubTeams from '@components/SelectClubTeams/SelectClubTeams';
import TakePhoto from '@components/TakePhoto/TakePhoto';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import TosSwitch from '@containers/CreateAccount/TosSwitch';
import { ACCOUNT_CREATION_SET_NEW_USER, ACCOUNT_CREATION_SET_SELECTED_CLUB } from '@redux/storeReducer';
import I18n from '@utils/i18n';
import React, { Component } from 'react';
import {
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Modal,
  Platform,
  Pressable,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { connect } from 'react-redux';

import styles from './styles';

class CA2 extends Component {
  KBOpen = null;
  KBClose = null;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      showAlert: false,
      hasAcceptedToS: false,

      profileAsset: null,

      showTakePhoto: false,
      showClubTeams: false,

      alertTitle: '',
      alertMessage: '',
      alertButtons: null,

      selectedTeam: null,

      isOupsMessage: false,
    };
  }

  componentDidMount() {
    if (Platform.OS === 'android') {
      this.KBOpen = Keyboard.addListener('keyboardDidShow', async () => {
        this.setState({ showNextButton: false });
      });
      this.KBClose = Keyboard.addListener('keyboardDidHide', async () => {
        this.setState({ showNextButton: true });
      });
    }
  }

  getProfilesTextList = () => {
    if (!this.props.newUser?.profileTypes || this.props.newUser?.profileTypes.length <= 0) {
      return I18n.t('createAccount.chooseRoleInClub');
    }
    return this.props.newUser?.profileTypes.map((t) => t.label).join(', ');
  };

  mayBeInATeam = () => {
    if (!this.props.apiConfiguration.teamSubscribe) {
      return false;
    }

    if (this.props.newUser?.profileTypes.length <= 0) {
      return false;
    }

    if (!this.props.selectedClubTeams || this.props.selectedClubTeams.length <= 0) {
      return false;
    }

    // Profile: player, coach, team manager, parent
    return this.props.newUser?.profileTypes.some((profile) => ['1', '2', '4', '10'].includes(profile.id));
  };

  getTeamsTextList = () => {
    if (this.props.newUser?.profileTypes.length <= 0 || !this.state.selectedTeam) {
      return I18n.t('createAccount.chooseTeamInClub');
    }

    return this.state.selectedTeam.name;
  };

  createAccount = async (fromOupsMessage) => {
    if (!this.props.selectedClub) {
      this.setState({
        loading: false,
        alertTitle: I18n.t('app.information'),
        alertMessage: I18n.t('app.noClub'),
        alertButtons: [{ text: 'Ok' }],
        showAlert: true,
      });

      return;
    }

    if (this.props.newUser?.profileTypes.length <= 0) {
      this.setState({
        loading: false,
        alertTitle: I18n.t('app.information'),
        alertMessage: I18n.t('app.noProfileType'),
        alertButtons: [{ text: 'Ok' }],
        showAlert: true,
      });

      return;
    }

    if (!this.state.hasAcceptedToS) {
      this.setState({
        loading: false,
        alertTitle: I18n.t('app.information'),
        alertMessage: "Vous devez accepter les Conditions Générales d'Utilisation pour pouvoir vous inscrire.",
        alertButtons: [{ text: 'Ok' }],
        showAlert: true,
      });

      return;
    }

    const profileTypesIds = [];
    this.props.newUser.profileTypes.map((profile, index) => {
      if (profile.id in profileTypesIds) {
        return;
      }
      profileTypesIds.push(profile.id);
    });

    if (
      this.props.selectedClub?.isAffiliated &&
      this.props.selectedClub.isAffiliated !== '1' &&
      fromOupsMessage === false
    ) {
      this.setState({ isOupsMessage: true });
    } else {
      let avatarPath = null;

      if (this.state.profileAsset) {
        // Upload user avatar image
        const avatarResponse = await AccountAPI.uploadAvatar(this.state.profileAsset);
        if (avatarResponse && avatarResponse.success) {
          avatarPath = avatarResponse.result.file_name;
          this.props.dispatch({
            type: ACCOUNT_CREATION_SET_NEW_USER,
            value: { avatarPath: avatarResponse.result.file_url },
          });
        }
      }

      const registerResponse = await AccountAPI.register(
        this.props.newUser,
        this.props.selectedClub,
        profileTypesIds,
        this.state.selectedTeam,
        this.state.licenseNumber,
        this.props.newUser.language,
        avatarPath,
        // Always accept to show phone number!
        true
      );
      if (registerResponse && registerResponse.success) {
        this.setState({
          loading: false,
          alertTitle: I18n.t('app.information'),
          alertMessage: registerResponse.result + '\n' + I18n.t('app.loginPlease'),
          alertButtons: [
            {
              text: 'Ok',
              onPress: async () => {
                this.props.dispatch({ type: ACCOUNT_CREATION_SET_NEW_USER, value: null });
                this.props.dispatch({ type: ACCOUNT_CREATION_SET_SELECTED_CLUB, value: null });
                this.props.navigation.navigate('SignIn');
              },
            },
          ],
          showAlert: true,
        });
      } else {
        if (!registerResponse) {
          this.setState({
            loading: false,
            alertTitle: I18n.t('app.oups'),
            alertMessage:
              'Nous avons rencontré un problème de communication avec le serveur. Merci de réessayer ultérieurement.',
            alertButtons: [{ text: 'Ok' }],
            showAlert: true,
          });
        } else if (registerResponse && registerResponse.error === 'Club non affilié') {
          this.setState({ isOupsMessage: true });
        } else {
          this.setState({
            loading: false,
            alertTitle: I18n.t('app.error'),
            alertMessage: registerResponse.error,
            alertButtons: [
              {
                text: 'Ok',
                onPress: async () => {},
              },
            ],
            showAlert: true,
          });
        }
      }
    }
  };

  render() {
    return (
      <>
        <GradientBackground>
          <ScrollView>
            <KeyboardAvoidingView
              style={[styles.rootContainer, Platform.OS === 'android' ? { paddingTop: 100 } : { paddingTop: 200 }]}
              behavior={Platform.OS === 'ios' ? 'padding' : 'position'}>
              <View style={[styles.inputContainer, styles.shadowEffect]}>
                <View style={styles.panelProfil}>
                  <Pressable
                    onPress={() => {
                      this.setState({ showTakePhoto: true });
                    }}
                    style={styles.buttonProfil}>
                    <View style={styles.avatarContainer}>
                      <Image
                        style={styles.imgProfil}
                        source={
                          this.props.newUser?.avatarPath
                            ? { uri: getImageApiHostOrUrl(this.props.newUser.avatarPath) }
                            : Images.userPlaceholder
                        }
                      />
                    </View>
                    <Text style={styles.textEditProfil}>{I18n.t('myAccount.addAvatarProfil')}</Text>
                  </Pressable>
                </View>

                <View style={[styles.clubGlobalContainer]}>
                  <Text style={[styles.appFontRegular, { color: Colors.blueCorporate, marginTop: 10 }]}>
                    {I18n.t('createAccount.myClub')}
                  </Text>

                  <View style={[styles.clubInfosContainer]}>
                    <Pressable
                      style={[styles.textInput, styles.buttonCenterLabel]}
                      onPress={() => this.props.navigation.navigate('SignUpClub')}>
                      <Text
                        style={[
                          styles.redText,
                          styles.appFontMedium,
                          this.props.selectedClub ? { color: Colors.blueCorporate } : { color: Colors.iconGray + '55' },
                        ]}
                        numberOfLines={1}
                        ellipsizeMode="tail">
                        {this.props.selectedClub ? this.props.selectedClub.name : I18n.t('createAccount.lookForMyClub')}
                      </Text>
                    </Pressable>

                    {this.props.selectedClub && (
                      <Pressable
                        style={[styles.textInput, styles.buttonCenterLabel]}
                        onPress={() =>
                          this.props.navigation.navigate('ChooseProfile', {
                            signingUp: true,
                            profiles: this.props.newUser.profileTypes,
                          })
                        }>
                        <Text style={[styles.redText, styles.appFontRegular]} numberOfLines={1} ellipsizeMode="tail">
                          {this.getProfilesTextList()}
                        </Text>
                      </Pressable>
                    )}

                    {this.props.selectedClub && this.mayBeInATeam() && (
                      <Pressable
                        style={[styles.textInput, styles.buttonCenterLabel]}
                        onPress={() => this.setState({ showClubTeams: true })}>
                        <Text style={[styles.redText, styles.appFontRegular]} numberOfLines={1} ellipsizeMode="tail">
                          {this.getTeamsTextList()}
                        </Text>
                      </Pressable>
                    )}
                  </View>
                </View>
              </View>

              <View style={[styles.tosButtonsContainer, styles.shadowEffect]}>
                <TosSwitch
                  title="J'accepte les conditions générales d'utilisation et la politique de confidentialité"
                  value={this.state.hasAcceptedToS}
                  onValueChange={() => this.setState({ hasAcceptedToS: !this.state.hasAcceptedToS })}
                />
              </View>

              <View style={[styles.buttonsContainer]}>
                <ButtonCancel
                  label={I18n.t('createAccount.previousStep')}
                  onPress={() => this.props.navigation.goBack()}
                />
                <ButtonValidate
                  style={{ marginLeft: 10 }}
                  label={I18n.t('createAccount.nextStep')}
                  onPress={() => this.createAccount(false)}
                />
              </View>
            </KeyboardAvoidingView>
          </ScrollView>
        </GradientBackground>

        <Loading loading={this.state.loading} />

        <TakePhoto
          visible={this.state.showTakePhoto}
          onPhotos={(assets) => {
            const avatarUri = assets && assets[0] ? assets[0].uri : null;
            this.setState({
              showTakePhoto: false,
              profileAsset: assets ? assets[0] : null,
            });
            this.props.dispatch({
              type: ACCOUNT_CREATION_SET_NEW_USER,
              value: { avatarPath: avatarUri },
            });
          }}
          onCancel={() => {
            this.setState({ showTakePhoto: false });
          }}
        />

        <SelectClubTeams
          visible={this.state.showClubTeams}
          onSelected={(team) => {
            this.setState({ showClubTeams: false, selectedTeam: team });
          }}
          onClose={() => this.setState({ showClubTeams: false })}
        />

        <Modal
          transparent
          visible={this.state.isOupsMessage}
          onRequestClose={() => {
            this.setState({ isOupsMessage: false });
          }}>
          <ClubNotAffiliatedPopup
            okButton={() => {
              this.setState({ isOupsMessage: false });
              this.createAccount(true);
            }}
            closePopup={() => {
              this.setState({ isOupsMessage: false });
            }}
          />
        </Modal>

        <AlerteUI
          showAlert={this.state.showAlert}
          onClose={() => {
            this.setState({ showAlert: false });
          }}
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          buttons={this.state.alertButtons}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { newUser, selectedClub, selectedClubTeams, apiConfiguration } = state.app;
  return {
    newUser,
    selectedClub,
    selectedClubTeams,
    apiConfiguration,
  };
};

export default connect(mapStateToProps)(CA2);
