import Colors from '@configs/colors';
import React from 'react';
import { ActivityIndicator, Pressable, StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';

/**
 * Displays a base button with:
 * - a text label or an icon label (if label starts with icon-)
 *
 * @param label: if starts with 'icon-' replace the label with the icon (e.g. icon-checkmark)
 * @param loading: true / false to indicate if loading or not
 * @param style: style appended to the Pressable
 * @param textStyle: style appended to the text label
 * @param onPress
 * @param disabled
 * @returns {JSX.Element}
 * @constructor
 */
export default function ButtonBase({
  label = 'Bouton',
  disabled = false,
  loading = null,
  style = {},
  textStyle = {},
  onPress = () => {},
}) {
  return (
    <Pressable
      disabled={disabled}
      style={({ pressed }) => [styles.defaultButton, style, pressed ? { opacity: 0.7 } : {}]}
      onPress={() => {
        onPress();
      }}>
      {label.startsWith('icon-') ? (
        <Icon
          style={{ color: disabled ? Colors.gray : Colors.blueCorporate }}
          name={label.replace('icon-', '')}
          size={25}
        />
      ) : (
        <View style={{ flexDirection: 'row' }}>
          {loading === true && <ActivityIndicator size="small" color={Colors.redCorporate} />}
          <Text style={[styles.buttonLabel, textStyle]} numberOfLines={1} ellipsizeMode="tail">
            {label}
          </Text>
        </View>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  defaultButton: {
    paddingHorizontal: 15,
    height: 48,
    backgroundColor: Colors.gray,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    marginVertical: 15,

    overflow: 'visible',
    elevation: 5,
    shadowColor: Colors.iconGray,
    shadowOffset: {
      width: 3,
      height: 5,
    },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  buttonLabel: {
    fontFamily: 'appFontRegular',
    color: Colors.blueCorporate,
  },
});
