import CardConvocation from '@components/CardConvocation/CardConvocation';
import HeaderBanner from '@components/HeaderBanner/HeaderBanner';
import { gMaxWidth } from '@configs/styles';
import { useIsFocused } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import { useSelector } from 'react-redux';

const ConvocationDetails = ({ navigation, route }) => {
  const debug = false;

  const isFocused = useIsFocused();

  // For an existing conversation
  const { session, affiliatedClubInformation } = useSelector((state) => state.session);
  const [currentClub, setCurrentClub] = useState(null);

  useEffect(() => {
    debug && console.debug('ConvocationDetails update current club', affiliatedClubInformation);

    setCurrentClub(affiliatedClubInformation ?? session.club ?? null);
  }, [session, affiliatedClubInformation]);

  const [convocation, setConvocation] = useState(null);

  useEffect(() => {
    debug && console.debug('ConvocationDetails focused:', isFocused);
    // isFocused && getConversationInfo();
  }, [isFocused]);

  useEffect(() => {
    debug && console.debug('ConvocationDetails route parameters:', route.params);
    if (!route.params.convocation) {
      console.warn('ConvocationDetails route parameters missing convocation!');
      navigation.goBack();
      return;
    }

    // Existing convocation
    setConvocation(route.params.convocation);
  }, [route.params]);

  if (!currentClub || !convocation) {
    return;
  }

  return (
    <>
      <HeaderBanner
        leftIcon="md-arrow-back"
        onLeftButton={() => {
          navigation.goBack();
        }}
        title={convocation.convocationTitle}
      />

      <CardConvocation fullScreen navigation={navigation} convocation={convocation} />
    </>
  );
};

export default ConvocationDetails;
