import { getApiHost } from '@configs/host';

import { Request } from './Request';
import { URLs } from './apisConstants';

const resultAPI = {
  likeDislikeCompetition: (wsToken, competitionId, competitionName, competitionType) => {
    return Request.post(getApiHost() + URLs.likeDislikeCompetition, {
      wsToken,
      competitionId,
      competitionName,
      competitionType,
    });
  },

  getFavoriteCompetitions: (wsToken, competitionType, isGetIds) => {
    return Request.get(
      getApiHost() +
        `${URLs.getFavoriteCompetitions}?wsToken=${wsToken}&competitionType=${competitionType}&isGetIds=${isGetIds}`
    );
  },
};

export default resultAPI;
