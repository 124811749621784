import Colors from '@configs/colors';
import { gMaxWidth } from '@configs/styles';
import { useDimensions } from '@utils/useDimensions';
import React, { useEffect, useRef } from 'react';
import { Dimensions, Platform, Pressable, View } from 'react-native';
import RBSheet from 'react-native-raw-bottom-sheet';
import Icon from 'react-native-vector-icons/Ionicons';

const { width: sw, height: sh } = Dimensions.get('window');

const BottomUpSheet = ({
  visible = false,
  rightIcon = null,
  onOpen = () => {},
  onClose = () => {},
  onPopupRef,
  children,
}) => {
  const { width, height } = useDimensions().window;

  const refBottomSheet = useRef();

  useEffect(() => {
    if (!refBottomSheet || !refBottomSheet.current) {
      return;
    }

    setTimeout(() => {
      if (!refBottomSheet.current) return;

      visible && refBottomSheet.current.open();
      !visible && refBottomSheet.current.close();
    }, 100);
    onPopupRef(refBottomSheet);
  }, []);

  return (
    <RBSheet
      ref={refBottomSheet}
      closeOnDragDown
      dragFromTopOnly
      closeOnPressMask
      // Web
      closeOnPressBack
      // Web
      height={height - 50}
      duration={250}
      animationType="slide"
      customStyles={{
        wrapper: {
          // backgroundColor: Colors.blueCorporate,
        },
        container: {
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          width: '100%',
          maxWidth: Platform.OS === 'web' ? gMaxWidth : sw,
          marginHorizontal: Platform.OS === 'web' ? (sw - gMaxWidth) / 2 : 0,
        },
        draggableIcon: {
          backgroundColor: Colors.iconGray + '88',
          width: 120,
          margin: 25,
          marginTop: 40,
        },
      }}
      onOpen={() => onOpen}
      onClose={() => onClose}>
      <View style={{ flex: 1 }}>
        {rightIcon && (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: -40,
              marginLeft: width - 60,
              width: 45,
            }}>
            <Pressable
              style={({ pressed }) => [{ padding: 10 }, pressed ? { opacity: 0.7 } : {}]}
              onPress={() => {
                refBottomSheet.current.close();
                onClose();
              }}>
              <Icon size={25} name={rightIcon} color={Colors.blueCorporate} />
            </Pressable>
          </View>
        )}

        {children}
      </View>
    </RBSheet>
  );
};

export default BottomUpSheet;
