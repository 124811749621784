import ChoosePlace from '@components/ChoosePlace/ChoosePlace';
import ClubFilters from '@containers/HomeStack/ClubFilters/ClubFilters';
import CreateActuality from '@containers/HomeStack/CreateActuality/CreateActuality';
import SpaceClub from '@containers/HomeStack/SpaceClub/SpaceClub';
import ChooseProfile from '@containers/ProfileStack/ChooseProfile';
import TabHome from '@containers/TabHome';
import ModalNotifications from '@navigation/modals/ModalNotifications';
import NotAffiliated from '@navigation/modals/NotAffiliated';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import i18n from '@utils/i18n';
import React from 'react';

const Stack = createNativeStackNavigator();

const HomeStack = () => {
  return (
    <Stack.Navigator
      id="HomeStack"
      initialRouteName="Home"
      screenOptions={{ headerShown: false, title: i18n.t('pageTitle.home') }}>
      <Stack.Screen name="Home" component={TabHome} options={{ headerShown: false }} />

      <Stack.Screen name="NotificationsList" component={ModalNotifications} />

      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="NotAffiliated" component={NotAffiliated} />
        <Stack.Screen
          name="ClubSearch"
          component={ClubFilters}
          initialParams={{
            selectable: false,
            viewClubOnSelect: true,
          }}
        />
        <Stack.Screen
          name="ClubFavorites"
          component={ClubFilters}
          initialParams={{
            selectable: true,
          }}
        />
        <Stack.Screen name="SpaceClub" component={SpaceClub} />
        <Stack.Screen name="ChooseProfile" component={ChooseProfile} />
      </Stack.Group>

      <Stack.Group>
        <Stack.Screen name="CreateActuality" component={CreateActuality} />
        <Stack.Screen name="ChoosePlace" component={ChoosePlace} />
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default HomeStack;
