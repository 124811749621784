import { AccountAPI } from '@apis/account';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import ButtonCancel from '@components/ButtonCancel/ButtonCancel';
import ButtonValidate from '@components/ButtonValidate/ButtonValidate';
import GradientBackground from '@components/GradientBackground/GradientBackground';
import Loading from '@components/Loading/Loading';
import Colors from '@configs/colors';
import alert from '@utils/alert';
import I18n from '@utils/i18n';
import { Validators } from '@utils/validators';
import React, { Component } from 'react';
import { Dimensions, KeyboardAvoidingView, Platform, StyleSheet, Text, TextInput, View } from 'react-native';

const { width: sw, height: sh } = Dimensions.get('window');

class LostPassword extends Component {
  state = {
    loading: false,
    email: '',
    displayMessage: false,
  };

  sendRecoveryEmail = async () => {
    console.log('sendRecoveryEmail', this.state.email);
    if (!Validators.validEmail(this.state.email)) {
      alert(I18n.t('app.oups'), 'Adresse mail invalide');
      return;
    }

    this.setState({ loading: true });

    AccountAPI.forgetPassword(this.state.email).then((result) => {
      this.setState({ loading: false });

      if (result && result.success) {
        this.setState({
          alertTitle: I18n.t('app.information'),
          alertMessage:
            "Votre demande vient d'être prise en compte.\n\nSi vous disposez d'un compte valide, vous recevrez un \nemail vous expliquant comment réinitialiser votre  mot de passe.",
          alertButtons: [{ text: 'Ok' }],
          displayMessage: true,
        });
      } else {
        if (result && result.error_code === 1902) {
          alert(I18n.t('app.oups'), 'Adresse mail inconnue');
        } else {
          alert(I18n.t('app.oups'), 'Une erreur est survenue, veuillez réessayer ultérieurement');
        }
      }
    });
  };

  render() {
    return (
      <>
        <GradientBackground>
          <View style={styles.rootContainer}>
            <KeyboardAvoidingView
              behavior={Platform.OS === 'ios' ? 'position' : 'position'}
              keyboardVerticalOffset={Platform.OS === 'ios' ? 50 : 80}>
              <View style={styles.inputContainer}>
                <Text
                  style={[
                    styles.whiteText,
                    styles.appFontRegular,
                    {
                      marginTop: '2%',
                      textAlign: 'center',
                      lineHeight: 16,
                    },
                  ]}>
                  {I18n.t('lostPassword.guide')}
                </Text>
                <TextInput
                  style={[styles.textInput, styles.appFontRegular]}
                  value={this.state.email}
                  placeholder={I18n.t('account.email')}
                  onChangeText={(email) => {
                    this.setState({ email });
                  }}
                  keyboardType="email-address"
                  autoCapitalize="none"
                  autoComplete="email"
                  textContentType="username"
                  autoCorrect={false}
                />
              </View>

              <View style={styles.buttonsContainer}>
                <ButtonCancel style={styles.cancelButton} onPress={() => this.props.navigation.goBack()} />
                <ButtonValidate
                  style={styles.nextButton}
                  label={I18n.t('lostPassword.send')}
                  onPress={() => this.sendRecoveryEmail()}
                />
              </View>
            </KeyboardAvoidingView>
          </View>
        </GradientBackground>

        <Loading loading={this.state.loading} />

        <AlerteUI
          showAlert={this.state.displayMessage}
          onClose={() => {
            this.setState({ displayMessage: false });
            this.props.navigation.goBack();
          }}
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          buttons={this.state.alertButtons}
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  rootContainer: {
    marginTop: sh / 4,
    alignItems: 'center',
  },
  inputContainer: {
    justifyContent: 'center',
    marginVertical: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    backgroundColor: Colors.blueCorporate,
  },

  whiteText: {
    color: Colors.whiteCorporate,
  },
  appFontRegular: {
    fontFamily: 'appFontRegular',
  },
  appFontMedium: {
    fontFamily: 'appFontMedium',
  },
  textInput: {
    width: (sw / 4) * 3,
    maxWidth: 600,
    backgroundColor: Colors.whiteCorporate,
    borderRadius: 1000,
    height: 48,
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 20,
  },

  shadowEffect: {
    marginBottom: 15,

    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },

  buttonsContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: sw / 1.5,
    // height: 40,
    marginVertical: 5,
    paddingHorizontal: 20,
    borderRadius: 10,
    // backgroundColor: Colors.blueSky,
  },

  signInButton: {
    color: Colors.whiteCorporate,
    alignSelf: 'center',
  },
  cancelButton: {
    color: Colors.whiteCorporate,
    alignSelf: 'center',
    alignItems: 'center',
  },
  nextButton: {
    color: Colors.whiteCorporate,
    alignSelf: 'center',
    alignItems: 'center',
  },

  linkButton: {
    paddingTop: 7,
    paddingBottom: 5,
  },
  checkButton: {
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
    margin: 10,
    padding: 10,
    paddingBottom: 0,
  },
  checkBorder: {
    borderWidth: 1.5,
    borderColor: Colors.whiteCorporate,
    borderRadius: 2,
    width: 12,
    height: 12,
    padding: 1.5,
    marginRight: 12,
  },
  checkChecked: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.whiteCorporate,
    borderRadius: 1,
  },
});

export default LostPassword;
