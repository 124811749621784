import { AccountAPI } from '@apis/account';
import Images from '@assets/images';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import ButtonBase from '@components/ButtonBase/ButtonBase';
import ButtonSwitch from '@components/ButtonSwitch/ButtonSwitch';
import ButtonValidate from '@components/ButtonValidate/ButtonValidate';
import DateTimePicker from '@components/DateTimePicker/DateTimePicker';
import HeaderCollapsible, {
  HEADER_MAX_HEIGHT,
  HEADER_MIN_HEIGHT,
} from '@components/HeaderCollapsible/HeaderCollapsible';
import Loading from '@components/Loading/Loading';
import TakePhoto from '@components/TakePhoto/TakePhoto';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import { gMaxWidth } from '@configs/styles';
import EditPassword from '@containers/ProfileStack/EditPassword';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useIsFocused } from '@react-navigation/native';
import { handleBadgeNotification } from '@redux/actions';
import { SET_LOCAL_PARAMETERS } from '@redux/reducers';
import { SET_TOKENS, SET_SESSION, SET_SIGNED_IN, SET_USER_STATUS, RESET_FOR_LOGOUT } from '@redux/storeReducer';
import alert from '@utils/alert';
import { checkIsAdmin, checkIsModeratorOrAdmin } from '@utils/checkUserRoles';
import I18n from '@utils/i18n';
import { Validators } from '@utils/validators';
import * as Application from 'expo-application';
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
import * as WebBrowser from 'expo-web-browser';
import md5 from 'md5';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Dimensions,
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import Icon from 'react-native-vector-icons/Ionicons';
import { useDispatch, useSelector } from 'react-redux';

const sw = Dimensions.get('window').width;
const { height } = Dimensions.get('window');

const appSlug = Constants.expoConfig.slug || 'eappli';

const TabProfile = ({ navigation, route }) => {
  const debug = false;

  const dispatch = useDispatch();

  const isFocused = useIsFocused();

  const { newUser } = useSelector((state) => state.app);
  const { allAffiliatedClubs, favorites, localParameters } = useSelector((state) => state.session);
  const { session, affiliatedClubInformation } = useSelector((state) => state.session);
  const { tokens, constProfilesTypes, apiConfiguration, languageCode } = useSelector((state) => state.app);

  const { teamsAffiliated } = useSelector((state) => state.session.session);
  const { teams } = useSelector((state) => state.club);

  const [showTakePhoto, setShowTakePhoto] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [editPassword, setEditPassword] = useState(false);
  const [password, setPassword] = useState();

  const [showUpdateId, setShowUpdateId] = useState(false);

  const favoriteRegions = favorites.regions.length
    ? favorites.regions.map((item, index) => item.label ?? item.name).join(', ')
    : '';
  const favoriteDepartments = favorites.departments
    ? favorites.departments.map((item, index) => item.label ?? item.name).join(', ')
    : '';
  const favoriteClubs = favorites.clubs ? favorites.clubs.map((item, index) => item.label ?? item.name).join(', ') : '';
  const affiliatedClubs = allAffiliatedClubs.map((item, index) => item.label ?? item.name).join(', ');

  const manifest =
    Platform.OS === 'ios'
      ? `${Application.applicationName}, version ${Application.nativeApplicationVersion} (${Application.nativeBuildVersion})`
      : Platform.OS === 'android'
      ? `${Application.applicationName}, version ${Application.nativeApplicationVersion} (${Application.nativeBuildVersion})`
      : `${Constants.expoConfig.name}, version ${Constants.expoConfig.version}`;

  const [currentClub, setCurrentClub] = useState(null);

  const [saveMyAccess, setSaveMyAccess] = useState(false);

  const [userData, setUserData] = useState(null);

  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertButtons, setAlertButtons] = useState(null);

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);

  const [leftPage, setLeftPage] = useState(true);

  const scrollY = useSharedValue(0);
  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (event) => {
      scrollY.value = event.contentOffset.y;
    },
  });
  const listStyle = useAnimatedStyle(() => {
    return {
      marginTop: interpolate(
        scrollY.value,
        [0, HEADER_MAX_HEIGHT],
        [HEADER_MAX_HEIGHT, HEADER_MIN_HEIGHT],
        Extrapolation.CLAMP
      ),
    };
  });

  useEffect(() => {
    debug && console.debug('TabProfile mounted');
    if (!tokens || !tokens.wsToken || !tokens.refreshToken) {
      dispatch({ type: SET_SIGNED_IN, value: false });
      return;
    }

    const bootstrapAsync = async () => {
      dispatch(handleBadgeNotification('profile', false));

      AsyncStorage.getItem(`${appSlug}-saveAccess`)
        .then((content) => {
          setSaveMyAccess(content ?? true);
        })
        .catch((error) => {
          console.warn('TabProfile saveAccess', error);
          setSaveMyAccess(true);
          AsyncStorage.setItem(`${appSlug}-saveAccess`, JSON.stringify(true));
        });
    };

    bootstrapAsync();

    // handleAffiliation();
  }, []);

  useEffect(() => {
    debug && console.debug('TabProfile got focus:', isFocused);
    if (!isFocused || !currentClub) return;

    // handleAffiliation();
  }, [isFocused]);

  useEffect(() => {
    if (!route.params) return;

    debug && console.debug('TabProfile route parameters:', route.params);
    if (route.params.profiles) {
      const profilesIds = route.params.profiles.map((profile) => profile.id);
      setUserData({ ...userData, selectedProfiles: route.params.profiles, profileTypeIds: profilesIds });
    }

    if (route.params.tab) {
      debug && console.debug('TabProfile, navigate tab:', route.params.tab);
      if (route.params.tab === 'userProfile') {
        setLeftPage(true);
      }

      if (route.params.tab === 'userParameters') {
        setLeftPage(false);
      }
    }

    if (route.params.logout) {
      setLeftPage(false);

      setLoading(false);
      setAlertTitle(I18n.t('myAccount.information'));
      setAlertMessage(I18n.t('myAccount.messageAlertLogout', { appName: Constants.expoConfig.name }));
      setAlertButtons([
        {
          text: I18n.t('myAccount.yes'),
          onPress: async () => {
            AccountAPI.logout(tokens.wsToken)
              .then(() => {
                logOut();
              })
              .catch((error) => {
                console.error('Beurk!', error);
              });
          },
        },
        { text: I18n.t('myAccount.no') },
      ]);
      setShowAlert(true);
    }
  }, [route.params]);

  useEffect(() => {
    debug && console.debug('TabProfile update current club', affiliatedClubInformation);

    setCurrentClub(affiliatedClubInformation ?? session?.club ?? null);

    resetData();
  }, [session, affiliatedClubInformation]);

  useEffect(() => {
    if (!currentClub) return;
    debug && console.debug('TabProfile updated currentClub');

    dispatch(handleBadgeNotification('profile', false));

    handleAffiliation(currentClub.id);

    // refreshAllNews();
  }, [currentClub]);

  useEffect(() => {
    debug && console.debug('TabProfile user data', userData);
    debug && console.debug('TabProfile teams', teamsAffiliated, teams);
  }, [userData]);

  const getMyProfilesAsText = (edition = false) => {
    if (edition) {
      if (newUser?.profileTypes.length <= 0) {
        return I18n.t('createAccount.chooseRoleInClub');
      }
      return newUser?.profileTypes.map((t) => t.label).join(', ');
    }

    if (userData.selectedProfiles.length <= 0) {
      return I18n.t('myAccount.noClubProfiles');
    }
    // Because one can have several times the same profile !?!
    return [...new Set(userData.selectedProfiles.map((item) => item.label ?? item.name))].join(', ');
  };

  const getMyTeamsAsText = () => {
    if (!teamsAffiliated || teamsAffiliated.length <= 0) {
      return I18n.t('myAccount.noTeamsMembership');
    }

    const myTeams = teams.filter((team) => {
      if (teamsAffiliated.includes(team.id)) {
        return team;
      }
      return null;
    });

    // Because one can have several times the same profile !?!
    return myTeams.map((item) => item.name).join(', ');
  };

  const resetData = () => {
    debug && console.debug('TabProfile resetData', session);

    const defaultBirthDate = moment(session.user.birthdate, 'YYYY-MM-DD');
    debug &&
      console.debug(
        'TabProfile resetData, birth date:',
        session.user.birthdate,
        defaultBirthDate,
        defaultBirthDate.toDate(),
        defaultBirthDate.format('YYYY-MM-DD')
      );
    const selectedProfiles = getMyProfileTypes(
      affiliatedClubInformation?.id !== session.club.id
        ? affiliatedClubInformation.userProfileIds
        : session.userProfileIds.ids ?? []
    );
    const profileTypeIds = selectedProfiles.map((profile) => profile.id);

    setUserData({
      ...userData,
      isModerator: checkIsModeratorOrAdmin(currentClub),
      isAdmin: checkIsAdmin(currentClub),
      selectedProfiles,
      profileTypeIds,
      userAvatarImage: session.user.avatarPath ?? '',
      firstName: session.user.firstName ?? '',
      lastName: session.user.lastName ?? '',
      email: session.user.email,
      birthDate: defaultBirthDate.toDate(),
      birthDay: defaultBirthDate.format('YYYY-MM-DD'),
      phoneNumber: session.user.phoneNumber ?? '',
      address: session.user.address ?? '',
      city: session.user.city ?? '',
      postalCode: session.user.postalCode ?? '',
      licenseNumber: session.user.licenseNumber ?? '',
    });
  };

  const handleAffiliation = () => {
    debug && console.debug('TabProfile handleAffiliation');

    setUserData({ ...userData, selectedProfiles: [] });

    getMyAccount(resetData);
  };

  const uploadAvatar = (asset) => {
    debug && console.debug('TabProfile uploadAvatar:', asset);
    if (!asset) {
      alert(
        I18n.t('app.oups'),
        "Une erreur est survenue lors de l'acquisition de la photo. Vous pouvez réessayer maintenant."
      );
      return;
    }

    setUploading(true);
    AccountAPI.uploadAvatar(asset, tokens.wsToken, apiConfiguration)
      .then((response) => {
        debug && console.debug('TabProfile uploadAvatar, response:', response);
        if (response && response.ok) {
          AccountAPI.editAvatar(tokens.wsToken, response.headers['location']);
          debug && console.debug('TabProfile uploaded an avatar, refreshing account information');
          getMyAccount();
        } else if (response && response.success) {
          if (response.result && response.result.file_url) {
            debug && console.debug('TabProfile uploadAvatar, url:', response.result.file_url);
          }
          if (response.result && response.result.file_name) {
            debug && console.debug('TabProfile uploadAvatar, filename:', response.result.file_name);

            AccountAPI.editAvatar(tokens.wsToken, response.result.file_url).then(() => {
              debug && console.debug('TabProfile uploaded an avatar, refreshing account information');
              getMyAccount();
            });
          }
        }
        setUploading(false);
      })
      .catch((error) => {
        setUploading(false);
        console.warn('uploadAvatar, error:', error);
        alert(
          I18n.t('app.oups'),
          "Une erreur est survenue lors de l'envoi de la photo. Vous pouvez réessayer maintenant."
        );
        return Promise.reject(Error('UploadImageFailed'));
      });
  };

  const getMyProfileTypes = (userProfilsInformation) => {
    debug && console.debug('TabProfile getMyProfileTypes:', userProfilsInformation);

    const profilesTypes = [];
    // From Redux constant profiles list
    for (const profileType of constProfilesTypes) {
      for (const userProfileId of userProfilsInformation) {
        if (Number(userProfileId) !== Number(profileType.id) || profilesTypes.includes(profileType)) {
          continue;
        }
        profilesTypes.push(profileType);
      }
    }
    debug && console.debug('TabProfile getMyProfileTypes filtered profiles:', profilesTypes);
    return profilesTypes;
  };

  const getMyAccount = (callback = () => {}) => {
    debug && console.debug('TabProfile getMyAccount');

    setRefreshPage(true);
    setLoading(true);
    AccountAPI.getMyAccount(tokens.wsToken).then((response) => {
      if (response && response.success) {
        debug && console.debug('TabProfile getMyAccount, success', response.result);
        try {
          const session = response.result;
          if (saveMyAccess) {
            AsyncStorage.setItem(`${appSlug}-session`, JSON.stringify(session));
          }
          // dispatch({ type: SET_TOKENS, value: tokens })
          dispatch({ type: SET_SESSION, value: session });
          dispatch({ type: SET_SIGNED_IN, value: true });

          callback();
        } catch (error) {
          console.warn('TabProfile getMyAccount, error:', error);
        }
      }
      setRefreshPage(false);
      setLoading(false);
      setUploading(false);
    });
  };

  const updateProfile = async () => {
    debug && console.debug('TabProfile updateProfile', userData);

    setLoading(true);

    if (userData.profileTypeIds.length < 1) {
      setLoading(false);
      setAlertTitle(I18n.t('app.oups'));
      setAlertMessage(I18n.t('app.noProfileType'));
      setAlertButtons([]);
      setShowAlert(true);
      return;
    }
    if (!Validators.validName(userData.lastName)) {
      setLoading(false);
      setAlertTitle(I18n.t('app.oups'));
      setAlertMessage(I18n.t('app.invalidLastname'));
      setAlertButtons([]);
      setShowAlert(true);
      return;
    }
    if (!Validators.validName(userData.firstName)) {
      setLoading(false);
      setAlertTitle(I18n.t('app.oups'));
      setAlertMessage(I18n.t('app.invalidFirstname'));
      setAlertButtons([]);
      setShowAlert(true);
      return;
    }
    if (!Validators.validEmail(userData.email)) {
      setLoading(false);
      setAlertTitle(I18n.t('app.oups'));
      setAlertMessage(I18n.t('app.invalidEmailAddress'));
      setAlertButtons([]);
      setShowAlert(true);
      return;
    }
    if (!Validators.validBirthdate(userData.birthDate)) {
      setLoading(false);
      setAlertTitle(I18n.t('app.oups'));
      setAlertMessage(I18n.t('app.invalidBirthdate'));
      setAlertButtons([]);
      setShowAlert(true);
      return;
    }
    if (userData.phoneNumber !== '' && !Validators.validPhoneNumber(userData.phoneNumber)) {
      setLoading(false);
      setAlertTitle(I18n.t('app.oups'));
      setAlertMessage(I18n.t('app.invalidPhoneNumber'));
      setAlertButtons([]);
      setShowAlert(true);
      return;
    }

    AccountAPI.editMyInfo(
      tokens.wsToken,
      userData.profileTypeIds,
      userData.lastName,
      userData.firstName,
      userData.phoneNumber,
      userData.email,
      moment(userData.birthDate).format('DD/MM/YYYY'),
      userData.address,
      userData.city,
      userData.postalCode,
      '',
      '',
      '',
      '',
      userData.licenseNumber,
      currentClub?.id
    ).then((response) => {
      if (response && response.success) {
        getMyAccount(() => {
          setLoading(false);
          setAlertTitle(I18n.t('app.information'));
          setAlertMessage('Vos données de profil ont bien été enregistrées');
          setAlertButtons([{ text: 'Ok' }]);
          setShowAlert(true);
        });
      } else {
        setLoading(false);
        setAlertTitle(I18n.t('app.oups'));
        setAlertMessage(
          response?.error ? response.error : 'Une erreur est survenue. Veuillez réessayer ultérieurement.'
        );
        setAlertButtons([]);
        setShowAlert(true);
      }
    });
  };

  const _clickLogout = () => {
    console.debug('TabProfile _clickLogout');

    setLoading(false);
    setAlertTitle(I18n.t('myAccount.information'));
    setAlertMessage(I18n.t('myAccount.messageAlertLogout', { appName: Constants.expoConfig.name }));
    setAlertButtons([
      {
        text: I18n.t('myAccount.yes'),
        onPress: async () => {
          AccountAPI.logout(tokens.wsToken)
            .then(() => {
              logOut();
            })
            .catch((error) => {
              console.error('Beurk!', error);
            });
        },
      },
      { text: I18n.t('myAccount.no') },
    ]);
    setShowAlert(true);
  };

  const logOut = async () => {
    try {
      // Clear all the local storage
      await AsyncStorage.clear(() => {
        dispatch({ type: SET_SIGNED_IN, value: false });
        dispatch({ type: SET_USER_STATUS, value: 'signing-in' });
        dispatch({ type: RESET_FOR_LOGOUT });
      });
    } catch (error) {
      console.warn('TabProfile logout - error:', error);
      return null;
    }
  };

  const reLogin = (newPassword) => {
    console.debug('TabProfile reLogin');

    const passwordMD5 = md5(newPassword);
    AccountAPI.authenticate(userData.email, passwordMD5, languageCode).then((response) => {
      if (response?.success) {
        try {
          if (saveMyAccess) {
            AsyncStorage.setItem(
              `${appSlug}-credentials`,
              JSON.stringify({ login: userData.email, password: passwordMD5 })
            );
          }
          dispatch({ type: SET_TOKENS, value: response.result });
        } catch (error) {
          setLoading(false);
          setAlertTitle(I18n.t('app.oups'));
          setAlertMessage(response?.error ? response.error : I18n.t('app.sessionStarterIssue'));
          setAlertButtons([]);
          setShowAlert(true);
        }
      } else {
        // todo: should not have an error because everything is ok!
        // setLoading(false);
        // setAlertTitle(I18n.t('app.oups'));
        // setAlertMessage(response?.error ? response.error : I18n.t('app.sessionStarterIssue'));
        // setAlertButtons([]);
        // setShowAlert(true);
      }
    });
  };

  const _clickDeleteAccount = () => {
    console.debug('TabProfile _clickDeleteAccount');

    setLoading(false);
    setAlertTitle(I18n.t('myAccount.information'));
    setAlertMessage(I18n.t('myAccount.messageAlertDeleteAccount', { appName: Constants.expoConfig.name }));
    setAlertButtons([
      {
        text: I18n.t('myAccount.yes'),
        onPress: async () => {
          const response = await AccountAPI.deleteAccount(tokens.wsToken);
          if (response?.success) {
            await logOut();
          } else {
            setAlertTitle(I18n.t('app.oups'));
            setAlertMessage(response.error);
            setAlertButtons([]);
            setShowAlert(true);
          }
        },
      },
      { text: I18n.t('myAccount.no') },
    ]);
    setShowAlert(true);
  };

  const _clickEditEmail = () => {
    setLoading(false);
    setAlertTitle('Modification impossible');
    setAlertMessage("Il n'est pas possible de changer l'adresse mail utilisée pour la connexion");
    setAlertButtons([]);
    setShowAlert(true);
  };

  const handleChange = (name, value) => {
    setUserData((prevState) => ({ ...prevState, [name]: value }));
  };

  if (!currentClub || !userData) {
    return;
  }
  debug && console.debug('TabProfile userData', userData);

  return (
    <View style={[{ flex: 1, marginBottom: 60 }, Platform.OS === 'web' ? { width: '100%', maxWidth: gMaxWidth } : {}]}>
      <HeaderCollapsible
        navigation={navigation}
        route={route}
        headerTitle={session && (currentClub.name ?? 'Guest')}
        contentScrolled={scrollY}
        displayExchangeIcon={allAffiliatedClubs.length > 1}
        onExchange={(clubId) => handleAffiliation(clubId)}
      />

      <Animated.View style={[styles.swiperButtons, listStyle]}>
        <View style={[styles.swipeBar, { backgroundColor: currentClub.mainColorBottom ?? Colors.blueCorporate }]}>
          <Pressable
            style={[styles.swipeBarButton, { backgroundColor: leftPage ? Colors.grayPage : null }]}
            onPress={() => {
              setLeftPage(true);
            }}>
            <Text
              style={[
                leftPage ? styles.topMenuSelected : styles.topMenuNotSelected,
                leftPage ? { color: Colors.blueCorporate ?? Colors.whiteCorporate } : null,
              ]}>
              {I18n.t('myAccount.myProfile')}
            </Text>
          </Pressable>

          <Pressable
            style={[styles.swipeBarButton, { backgroundColor: !leftPage ? Colors.grayPage : null }]}
            onPress={() => {
              setLeftPage(false);
            }}>
            <Text
              style={[
                !leftPage ? styles.topMenuSelected : styles.topMenuNotSelected,
                !leftPage ? { color: Colors.blueCorporate ?? Colors.whiteCorporate } : null,
              ]}>
              {I18n.t('myAccount.settings')}
            </Text>
          </Pressable>
        </View>
      </Animated.View>

      {leftPage ? (
        <>
          <Animated.ScrollView
            style={{ marginTop: 10, marginBottom: 60 }}
            // contentContainerStyle={{ marginTop: 10, marginBottom: 60 }}
            scrollEnabled
            showsVerticalScrollIndicator
            onScroll={scrollHandler}
            scrollEventThrottle={1}>
            <View style={styles.profilePanel}>
              <Pressable style={styles.profileButton} onPress={() => setShowTakePhoto(true)}>
                <View style={styles.imageFrame}>
                  <Image
                    style={styles.userAvatar}
                    source={
                      userData.userAvatarImage !== ''
                        ? { uri: getImageApiHostOrUrl(userData.userAvatarImage) }
                        : Images.userPlaceholder
                    }
                  />
                </View>
                <View
                  style={[
                    styles.iconUpdateAvatar,
                    styles.shadowEffect,
                    { backgroundColor: currentClub.mainColorBottom ?? Colors.blueCorporate },
                  ]}>
                  <Icon size={20} name="camera-outline" color={currentClub.activeIconColor} />
                </View>
              </Pressable>
            </View>

            <View style={styles.detailsPanel}>
              <Text style={styles.detailsPanelTitle}>{I18n.t('myAccount.myClub')}</Text>
              <Pressable onPress={() => navigation.openDrawer()}>
                <View style={styles.panelDescriptionMyClub}>
                  <View style={styles.panelImageMyClub}>
                    <Image
                      style={styles.imageMyClub}
                      source={
                        currentClub.logo ? { uri: getImageApiHostOrUrl(currentClub.logo) } : require('@assets/logo.png')
                      }
                    />
                  </View>
                  <Text style={styles.textInputClub} numberOfLines={1} ellipsizeMode="tail">
                    {currentClub.name ?? ''}
                  </Text>
                </View>
              </Pressable>

              <Text style={styles.detailsPanelTitle}>{I18n.t('myAccount.myProfiles')}</Text>
              <Pressable
                style={styles.textInputTypeProfil}
                onPress={() => {
                  navigation.navigate('ProfileStack', {
                    screen: 'ChooseProfile',
                    params: { fromProfile: true, profiles: userData.selectedProfiles },
                  });
                }}>
                {userData.isModerator && <Icon size={20} name="shield-checkmark-outline" color={Colors.redCorporate} />}
                {userData.isAdmin && <Icon size={20} name="key-outline" color={Colors.redCorporate} />}
                <Text style={styles.textInputClub} numberOfLines={1} ellipsizeMode="tail">
                  {getMyProfilesAsText()}
                </Text>
              </Pressable>

              <Text style={styles.detailsPanelTitle}>{I18n.t('myAccount.myTeams')}</Text>
              <Pressable
                style={styles.textInputTypeProfil}
                onPress={() => {
                  navigation.navigate('ProfileStack', {
                    screen: 'ChooseProfile',
                    params: { fromProfile: true, profiles: userData.selectedProfiles },
                  });
                }}>
                <Text style={styles.textInputClub} numberOfLines={1} ellipsizeMode="tail">
                  {getMyTeamsAsText()}
                </Text>
              </Pressable>
            </View>

            <View style={styles.inputContainer}>
              <KeyboardAvoidingView
                behavior={Platform.OS === 'ios' ? 'position' : 'position'}
                keyboardVerticalOffset={Platform.OS === 'ios' ? 50 : 80}>
                <View style={styles.personalPanel}>
                  <Text style={styles.panelTitle}>{I18n.t('myAccount.personalInformation')}</Text>

                  <Text style={styles.textInfos}>{I18n.t('myAccount.lastName')}</Text>
                  <TextInput
                    style={[
                      styles.textInputInfos,
                      { color: Validators.validName(userData.lastName) ? Colors.blueCorporate : Colors.redCorporate },
                    ]}
                    value={userData.lastName}
                    placeholder={I18n.t('myAccount.lastName')}
                    onChangeText={(text) => handleChange('lastName', text)}
                  />

                  <Text style={styles.textInfos}>{I18n.t('myAccount.firstName')}</Text>
                  <TextInput
                    style={[
                      styles.textInputInfos,
                      { color: Validators.validName(userData.firstName) ? Colors.blueCorporate : Colors.redCorporate },
                    ]}
                    value={userData.firstName}
                    placeholder={I18n.t('myAccount.firstName')}
                    onChangeText={(text) => handleChange('firstName', text)}
                  />

                  <Text style={styles.textInfos}>{I18n.t('account.birthDate')}</Text>
                  {Platform.OS !== 'web' ? (
                    <>
                      <Pressable
                        style={{ width: '100%' }}
                        onPress={() => {
                          Keyboard.dismiss();
                          setShowDatePicker(true);
                        }}>
                        <View pointerEvents="none">
                          <TextInput
                            inputMode="text"
                            textAlign="left"
                            editable={false}
                            style={[
                              styles.textInputInfos,
                              {
                                color: Validators.validBirthdate(userData.birthDate)
                                  ? Colors.blueCorporate
                                  : Colors.redCorporate,
                              },
                            ]}
                            value={moment(userData.birthDate).format('Do MMMM YYYY')}
                            placeholder={I18n.t('account.birthDate')}
                          />
                        </View>
                      </Pressable>

                      <DateTimePicker
                        visible={showDatePicker}
                        mode="date"
                        future={false}
                        value={userData.birthDate}
                        onClose={() => {
                          setShowDatePicker(false);
                        }}
                        onChange={(selectedDate) => {
                          const birthDate = moment(selectedDate);
                          handleChange('birthDate', selectedDate);
                          handleChange('birthDay', birthDate.format('YYYY-MM-DD'));
                        }}
                      />
                    </>
                  ) : (
                    <input
                      type="date"
                      style={styles.textInputInfos}
                      value={userData.birthDay}
                      max={moment().format('YYYY-MM-DD')}
                      onInput={(event) => {
                        const birthDate = moment(event.target.value);
                        handleChange('birthDate', birthDate);
                        handleChange('birthDay', birthDate.format('YYYY-MM-DD'));
                      }}
                    />
                  )}

                  <Text style={styles.textInfos}>{I18n.t('myAccount.phone')}</Text>
                  <TextInput
                    placeholder={I18n.t('shop.phoneNumber')}
                    style={[
                      styles.textInputInfos,
                      {
                        color: Validators.validPhoneNumber(userData.phoneNumber)
                          ? Colors.blueCorporate
                          : Colors.redCorporate,
                      },
                    ]}
                    value={userData.phoneNumber}
                    keyboardType="phone-pad"
                    onChangeText={(text) => handleChange('phoneNumber', text)}
                  />

                  <Text style={styles.textInfos}>{I18n.t('myAccount.mail')}</Text>
                  <Pressable style={{ width: '100%' }} onPress={() => _clickEditEmail()}>
                    <TextInput
                      style={[
                        styles.textInputInfos,
                        {
                          color: Validators.validEmail(userData.email) ? Colors.blueCorporate : Colors.redCorporate,
                          backgroundColor: Colors.redCorporate + '11',
                        },
                      ]}
                      value={userData.email}
                      placeholder={I18n.t('myAccount.emailAddress')}
                      editable={false}
                    />
                  </Pressable>

                  <Text style={styles.textInfos}>{I18n.t('myAccount.address')}</Text>
                  <TextInput
                    style={[styles.textInputInfos, { color: Colors.blueCorporate }]}
                    placeholder={I18n.t('myAccount.address')}
                    value={userData.address}
                    onChangeText={(text) => handleChange('address', text)}
                  />

                  <View style={styles.viewCityPostalCode}>
                    <View style={styles.viewPostalCode}>
                      <TextInput
                        keyboardType="numeric"
                        maxLength={5}
                        placeholder={I18n.t('app.postalCode')}
                        style={[styles.textInputInfos, { color: Colors.blueCorporate }]}
                        value={userData.postalCode}
                        onChangeText={(text) => handleChange('postalCode', text)}
                      />
                    </View>
                    <View style={styles.viewCity}>
                      <TextInput
                        placeholder={I18n.t('app.city')}
                        style={[styles.textInputInfos, { color: Colors.blueCorporate }]}
                        value={userData.city}
                        onChangeText={(text) => handleChange('city', text)}
                      />
                    </View>
                  </View>
                </View>

                <View style={[styles.licensePanel]}>
                  <Text style={[styles.panelTitle]}>{I18n.t('createAccount.licenseNumber')}</Text>
                  <View style={styles.viewRowLicence}>
                    <TextInput
                      style={[styles.textInputLicence]}
                      placeholder="1234567890123"
                      value={userData.licenseNumber}
                      onChangeText={(text) => handleChange('licenseNumber', text)}
                      // keyboardType="numeric"
                    />
                  </View>
                </View>
              </KeyboardAvoidingView>
            </View>

            <View style={styles.buttonsContainer}>
              <ButtonValidate label={I18n.t('myAccount.save')} onPress={() => updateProfile()} />
            </View>
          </Animated.ScrollView>

          <TakePhoto
            visible={showTakePhoto}
            resize
            onPhotos={(assets) => {
              setShowTakePhoto(false);
              uploadAvatar(assets[0]);
            }}
            onCancel={() => {
              setShowTakePhoto(false);
            }}
          />
        </>
      ) : (
        <>
          <Animated.ScrollView
            style={{ marginTop: 10, marginBottom: 60 }}
            // contentContainerStyle={{ marginTop: 10, marginBottom: 60 }}
            scrollEnabled
            showsVerticalScrollIndicator
            onScroll={scrollHandler}
            scrollEventThrottle={1}>
            <View style={styles.parametersContainer}>
              <View style={styles.inputContainer}>
                <View style={styles.personalPanel}>
                  <Text style={styles.panelTitle}>{I18n.t('myAccount.identifiers')}</Text>
                  <Pressable style={{ width: '100%' }} onPress={() => _clickEditEmail()}>
                    <Text style={styles.textInfos}>{I18n.t('myAccount.mail')}</Text>
                    <TextInput
                      style={[
                        styles.textInputInfos,
                        {
                          color: Validators.validEmail(userData.email) ? Colors.blueCorporate : Colors.redCorporate,
                          backgroundColor: Colors.redCorporate + '11',
                        },
                      ]}
                      value={userData.email}
                      placeholder={I18n.t('myAccount.emailAddress')}
                      editable={false}
                    />
                  </Pressable>

                  <Pressable style={{ width: '100%' }} onPress={() => setEditPassword(true)}>
                    <Text style={styles.textInfos}>{I18n.t('myAccount.password')}</Text>
                    <TextInput
                      style={[styles.textInputInfos, { alignItems: 'center', justifyContent: 'center' }]}
                      value="**********"
                      editable={editPassword}
                      onEndEditing={() => setEditPassword(false)}
                      onChangeText={(value) => setPassword(value)}
                    />
                  </Pressable>
                </View>

                {(favoriteRegions.length > 0 || favoriteDepartments.length > 0 || favoriteClubs.length > 0) && (
                  <View style={styles.personalPanel}>
                    <Text style={styles.panelTitle}>{I18n.t('myAccount.favorites.title')}</Text>

                    {favoriteRegions.length > 0 && (
                      <Pressable
                        style={{ width: '100%' }}
                        onPress={() => {
                          navigation.navigate('ClubFavorites');
                        }}>
                        <Text style={styles.textInfos}>{I18n.t('myAccount.favorites.regions')}</Text>
                        <TextInput style={[styles.textInputInfos]} value={favoriteRegions} editable={false} />
                      </Pressable>
                    )}

                    {favoriteDepartments.length > 0 && (
                      <Pressable
                        style={{ width: '100%' }}
                        onPress={() => {
                          navigation.navigate('ClubFavorites');
                        }}>
                        <Text style={styles.textInfos}>{I18n.t('myAccount.favorites.departments')}</Text>
                        <TextInput style={[styles.textInputInfos]} value={favoriteDepartments} editable={false} />
                      </Pressable>
                    )}

                    {favoriteClubs.length > 0 && (
                      <Pressable
                        style={{ width: '100%' }}
                        onPress={() => {
                          navigation.navigate('ClubFavorites');
                        }}>
                        <Text style={styles.textInfos}>{I18n.t('myAccount.favorites.clubs')}</Text>
                        <TextInput style={[styles.textInputInfos]} value={favoriteClubs} editable={false} />
                        {allAffiliatedClubs.length > 1 && (
                          <View style={{ flexDirection: 'row', align: 'center', justifyContent: 'flex-start' }}>
                            <Icon size={20} name="swap-horizontal" color={Colors.red} />
                            <TextInput style={[styles.textInputInfos]} value={affiliatedClubs} editable={false} />
                          </View>
                        )}
                      </Pressable>
                    )}
                  </View>
                )}

                {localParameters && userData.isAdmin && (
                  <View style={styles.personalPanel}>
                    <Text style={styles.panelTitle}>{I18n.t('myAccount.moderation.title')}</Text>

                    <ButtonSwitch
                      switchStyle={{ height: 40 }}
                      label={I18n.t('myAccount.moderation.conversations')}
                      onPress={(isChecked) => {
                        dispatch({
                          type: SET_LOCAL_PARAMETERS,
                          parameters: { allConversations: isChecked },
                        });
                      }}
                      isSelected={localParameters.allConversations}
                    />
                  </View>
                )}
              </View>

              <View style={styles.buttonsContainer}>
                <ButtonBase
                  style={{ marginHorizontal: 10, minWidth: sw / 3 }}
                  textStyle={{ fontFamily: 'appFontMedium', color: Colors.redCorporate }}
                  label={I18n.t('myAccount.deleteMyAccount')}
                  onPress={_clickDeleteAccount}
                />

                <ButtonValidate
                  style={{ marginHorizontal: 10, minWidth: sw / 3 }}
                  label={I18n.t('myAccount.signOut')}
                  onPress={_clickLogout}
                />
              </View>
            </View>

            <View style={styles.manifestContainer}>
              <Pressable onPress={() => WebBrowser.openBrowserAsync(Constants.expoConfig.extra.tosUrl)}>
                <Text style={styles.appVersion}>CGU et Politique de Confidentialité</Text>
              </Pressable>
              <Pressable onPress={() => setShowUpdateId(!showUpdateId)}>
                <Text style={styles.appVersion}>{manifest}</Text>
              </Pressable>
            </View>
            {showUpdateId && (
              <View style={styles.updateIdContainer}>
                <Text style={styles.updateId}>{Updates.updateId ?? 'No current update'}</Text>
              </View>
            )}
          </Animated.ScrollView>

          <EditPassword
            visible={editPassword}
            onClose={() => setEditPassword(false)}
            onUpdatedPassword={(newPassword) => reLogin(newPassword)}
          />
        </>
      )}

      <Loading loading={uploading || loading || refreshPage} />

      <AlerteUI
        showAlert={showAlert}
        onClose={() => setShowAlert(false)}
        title={alertTitle}
        message={alertMessage}
        buttons={alertButtons}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    alignItems: 'center',
  },
  topMenuNotSelected: {
    color: Colors.whiteCorporate,
    fontFamily: 'appFontRegular',
  },
  topMenuSelected: {
    color: Colors.whiteCorporate,
    fontFamily: 'appFontMedium',
    fontWeight: 'bold',
  },
  shadowEffect: {
    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },

  // --- Sub-header swipe
  swiperButtons: {
    width: '100%',
    height: 40,
    alignItems: 'center',
    marginBottom: 20,
  },

  swipeBar: {
    width: '100%',
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  swipeBarButton: {
    width: '50%',
    height: 40,
    marginTop: 5,
    alignItems: 'center',
    paddingTop: 10,
    borderTopStartRadius: 16,
    borderTopEndRadius: 16,
  },

  // --- User profile
  profileContainer: {
    // backgroundColor: Colors.gray1,
  },
  profilePanel: {
    alignItems: 'center',
    marginTop: 10,
  },
  profileButton: {
    alignItems: 'center',
  },

  imageFrame: {
    width: sw / 3,
    minWidth: 64,
    maxWidth: 256,
    height: sw / 3,
    maxHeight: 256,
    minHeight: 64,
    borderRadius: 128,
    borderColor: Colors.grayWhite,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  userAvatar: {
    width: '100%',
    height: '100%',
    borderRadius: 128,
    padding: 0,
    margin: 0,
  },
  updateAvatar: {
    paddingVertical: 0,
    paddingHorizontal: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: Colors.red,
    fontSize: 20,
    fontFamily: 'appFontRegular',
  },
  iconUpdateAvatar: {
    width: 35,
    height: 35,
    borderRadius: 18,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'absolute',
    top: 60,
    right: -10,
  },
  textUpdateAvatar: {
    paddingVertical: 0,
    paddingHorizontal: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: Colors.red,
    fontSize: 20,
    fontFamily: 'appFontRegular',
  },

  textInputClub: {
    fontFamily: 'appFontLight',
    paddingLeft: 10,
  },
  textInputProfile: {
    fontFamily: 'appFontLight',
    paddingLeft: 10,
  },

  detailsPanel: {
    // flex: 1,
    marginVertical: 10,
    marginHorizontal: 20,
  },
  detailsPanelTitle: {
    fontFamily: 'appFontRegular',
    fontSize: 24,
    marginTop: 10,
    color: Colors.blueCorporate,
  },
  textInputTypeProfil: {
    backgroundColor: Colors.whiteCorporate,
    width: '100%',
    height: 32,
    borderRadius: 200,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
  },
  textMyClub: {
    fontFamily: 'appFontRegular',
    fontSize: 24,
    color: Colors.blueCorporate,
    marginTop: 10,
    marginBottom: 5,
    marginLeft: 0,
  },
  panelDescriptionMyClub: {
    backgroundColor: Colors.whiteCorporate,
    width: '100%',
    height: 42,
    borderRadius: 200,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 5,
  },
  panelImageMyClub: {
    width: 60,
    height: 60,
    borderRadius: 30,
    borderColor: Colors.grayWhite,
    borderWidth: 3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageMyClub: {
    width: '99%',
    height: '99%',
    borderRadius: 30,
    padding: 0,
    margin: 0,
  },

  personalPanel: {
    marginVertical: 20,
    marginHorizontal: 20,
    paddingHorizontal: 10,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: Colors.whiteCorporate,
    borderRadius: sw * 0.04,
    alignItems: 'center',
  },
  panelTitle: {
    fontFamily: 'appFontMedium',
    fontSize: 22,
    color: Colors.blueCorporate,
    marginBottom: height * 0.04,
  },
  textInfos: {
    fontFamily: 'appFontRegular',
    fontSize: 20,
    color: Colors.blueCorporate,
    marginBottom: 5,
    alignSelf: 'flex-start',
    paddingLeft: 10,
  },

  licensePanel: {
    marginTop: 30,
    marginHorizontal: 20,
    paddingHorizontal: 10,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: Colors.whiteCorporate,
    borderRadius: sw * 0.04,
    alignItems: 'center',
  },
  viewRowLicence: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: sw * 0.03,
    flex: 1,
    backgroundColor: Colors.grayWhite,
    height: height * 0.05,
    borderRadius: (height * 0.05) / 2,
  },

  documentsPanel: {
    marginTop: 30,
    marginHorizontal: 20,
    paddingHorizontal: 10,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: Colors.whiteCorporate,
    borderRadius: sw * 0.04,
    alignItems: 'center',
  },

  panelEdit: {
    flexDirection: 'row',
    alignItems: 'center',
    height: height * 0.05,
  },
  viewButtonEdit: {
    alignItems: 'flex-end',
    flex: 1,
  },
  labelEdit: {
    color: Colors.blueBgGradient1,
    fontSize: sw * 0.038,
    fontFamily: 'appFontRegular',
    paddingLeft: sw * 0.045,
  },
  textEdit: {
    color: Colors.blueCorporate,
    fontSize: sw * 0.035,
    fontFamily: 'appFontRegular',
  },
  textInputInfos: {
    width: '100%',
    backgroundColor: Colors.grayWhite,
    height: 32,
    borderRadius: 100,
    paddingHorizontal: 15,
    marginBottom: 10,
    fontFamily: 'appFontLight',
    justifyContent: 'center',
  },
  datePicker: {
    marginTop: 0,
    height: height * 0.05,
    width: '100%',
    textAlignVertical: 'center',
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderWidth: 0,
    padding: 0,
    backgroundColor: Colors.grayWhite,
  },
  viewCityPostalCode: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  viewCity: {
    width: '70%',
  },
  viewPostalCode: {
    width: '29%',
  },
  textInputLicence: {
    width: '100%',
    backgroundColor: Colors.grayWhite,
    height: 32,
    borderRadius: 100,
    paddingHorizontal: 15,
    fontFamily: 'appFontLight',
  },

  textIncompleteFile: {
    fontSize: sw * 0.038,
    marginBottom: height * 0.038,
  },
  listDocument: {
    flex: 1,
    width: '100%',
    height: height * 0.046,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: sw * 0.05,
    paddingRight: sw * 0.03,
  },
  textListDocument: {
    fontSize: sw * 0.035,
    fontFamily: 'appFontRegular',
  },
  viewButtonUpload: {
    flex: 0.4,
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  buttonUpload: {
    height: height * 0.035,
    borderRadius: (height * 0.035) / 2,
    borderColor: Colors.blueBgGradient1,
    borderWidth: sw * 0.002,
    backgroundColor: Colors.whiteCorporate,
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
  },
  textUpload: {
    fontSize: sw * 0.032,
    color: Colors.blueBgGradient1,
  },
  buttonSendDocumentDisable: {
    width: '60%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.grayWhite,
    height: height * 0.05,
    borderRadius: (height * 0.05) / 2,
    marginTop: height * 0.035,
  },
  buttonSendDocumentEnable: {
    width: '60%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.grayWhite,
    height: height * 0.05,
    marginTop: height * 0.035,
    borderRadius: (height * 0.05) / 2,
    borderWidth: sw * 0.002,
  },
  textSendDocument: {
    fontSize: sw * 0.032,
    color: Colors.gray,
  },
  buttomAllDocument: {
    marginTop: height * 0.03,
    marginBottom: height * 0.035,
    width: '60%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.grayWhite,
    height: height * 0.05,
    borderRadius: (height * 0.05) / 2,
    borderWidth: sw * 0.002,
  },
  textAllDocument: {
    fontSize: sw * 0.032,
    color: Colors.blueBgGradient1,
  },

  // --- User parameters
  parametersContainer: {
    // backgroundColor: Colors.gray1,
    // height: height - (Platform.OS === 'ios' ? 150 : 170) - 40,
  },
  textIdentifiers: {
    fontFamily: 'appFontMedium',
    fontSize: 36,
    marginTop: 20,
    marginBottom: 20,
    color: Colors.blueBgGradient1,
  },

  manifestContainer: {
    height: 120,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    paddingTop: 80,
    marginBottom: 80,
  },
  appVersion: {
    marginHorizontal: 10,
    color: Colors.iconGray,
    fontSize: 10,
  },
  updateIdContainer: {
    backgroundColor: Colors.grayWhite,
    borderRadius: 5,
    margin: 40,
    padding: 10,
  },
  updateId: {
    marginHorizontal: 10,
    color: Colors.gray1,
    fontSize: 10,
    textAlign: 'right',
  },

  // Picture panel
  picturePanel: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 80,
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 9999999,
    backgroundColor: 'rgba(0,0,0,0.6)',
  },
  contentPicturePanel: {
    borderRadius: sw * 0.05,
    width: '86%',
    alignItems: 'center',
  },
  pictureAction: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: height * 0.07,
    borderRadius: (sw * 0.07) / 2,
    backgroundColor: Colors.whiteCorporate,
    marginBottom: height * 0.01,
  },
  pictureActionText: {
    fontSize: sw * 0.034,
  },
  pictureActionCancel: {
    marginTop: height * 0.01,
  },

  buttonsContainer: {
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    marginTop: 10,
  },
  button: {
    fontFamily: 'appFontRegular',
    fontSize: 12,
    height: 48,
    padding: 5,
    margin: 5,
    borderRadius: 100,
    elevation: 2,

    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.whiteCorporate,
    justifyContent: 'center',
  },
});

export default TabProfile;
