import ButtonBase from '@components/ButtonBase/ButtonBase';
import GradientBackground from '@components/GradientBackground/GradientBackground';
import Colors from '@configs/colors';
import I18n from '@utils/i18n';
import { openMail } from '@utils/openLinks';
import Constants from 'expo-constants';
import React, { useEffect, useState } from 'react';
import { Dimensions, Image, Pressable, StyleSheet, Text, View } from 'react-native';
import { useSelector } from 'react-redux';

const { width: sw, height: sh } = Dimensions.get('window');

const NotAffiliated = ({ navigation, route }) => {
  const debug = false;

  const { session, foundOtherClub, affiliatedClubInformation } = useSelector((state) => state.session);

  const [currentClub, setCurrentClub] = useState(null);

  useEffect(() => {
    debug && console.debug(`NotAffiliated, club information changed, foundOtherClub:`, foundOtherClub);
    debug && console.debug(`NotAffiliated, club information changed:`, session.club);
    debug && console.debug(`NotAffiliated, club information changed:`, affiliatedClubInformation);

    setCurrentClub(foundOtherClub ?? affiliatedClubInformation ?? session.club ?? null);
  }, [foundOtherClub, session, affiliatedClubInformation]);

  if (!currentClub) {
    return;
  }

  return (
    <GradientBackground gradient>
      <View style={styles.rootContainer}>
        <View style={styles.imageContainer}>
          <Image source={require('@assets/logoText.png')} style={[styles.image]} />
        </View>

        <Text style={[styles.pageTitle]}>{currentClub.name}</Text>

        <Text style={[styles.message]}>{I18n.t('app.notAffiliateMessage', { app: Constants.expoConfig.name })}</Text>

        <Pressable
          style={({ pressed }) => [pressed ? { opacity: 0.7 } : {}]}
          onPress={() => {
            openMail('contact@e-appli.com', I18n.t('app.notAffiliateMessageMail', { app: Constants.expoConfig.name }));
          }}>
          <Text
            style={[
              {
                fontSize: 20,
                textAlign: 'center',
                marginVertical: 25,
                textDecorationLine: 'underline',
              },
            ]}>
            contact@e-appli.com
          </Text>
        </Pressable>

        <ButtonBase label="Fermer" style={styles.closeButton} onPress={() => navigation.goBack()} />
      </View>
    </GradientBackground>
  );
};

const styles = StyleSheet.create({
  rootContainer: {
    // flex: 1,
    marginTop: 10,
    alignItems: 'center',
  },

  imageContainer: {
    justifyContent: 'center',
    height: sh / 4,
    width: sw,
    marginTop: 10,
    padding: 10,
  },
  image: {
    width: '90%',
    height: '100%',
    resizeMode: 'contain',
    alignSelf: 'center',
  },

  pageTitle: {
    fontFamily: 'appFontMedium',
    fontSize: 24,
    color: Colors.blueCorporate,

    marginTop: 30,
    marginHorizontal: 20,
    textAlign: 'center',
  },

  message: {
    fontFamily: 'appFontRegular',
    fontSize: 16,
    color: Colors.blueCorporate,

    marginTop: 50,
    marginHorizontal: 50,
    textAlign: 'center',
  },
});

export default NotAffiliated;
