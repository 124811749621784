import { REPLY_NO, REPLY_YES } from '@apis/message';
import ButtonBase from '@components/ButtonBase/ButtonBase';
import CardUser from '@components/CardUser/CardUser';
import MapViewer from '@components/MapViewer/MapViewer';
import ShadowCard from '@components/ShadowCard/ShadowCard';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import { gMaxWidth } from '@configs/styles';
import I18n from '@utils/i18n';
import { openMap } from '@utils/openLinks';
import { useDimensions } from '@utils/useDimensions';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Dimensions, FlatList, Image, Platform, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { useSelector } from 'react-redux';

const { width: sw, height: sh } = Dimensions.get('window');

const CardConvocation = ({
  navigation,
  convocation,
  onConvocationReply = (item, callback) => {},
  style = {},
  fullScreen = false,
}) => {
  const debug = false;

  const { session } = useSelector((state) => state.session);
  const { affiliatedClubInformation } = useSelector((state) => state.session);
  const { myConversation } = useSelector((state) => state.chat);

  const { width } = useDimensions().window;
  const [numColumns, setNumColumns] = useState(1);
  useEffect(() => {
    setNumColumns(width >= 768 ? 2 : 1);
  }, [width]);

  const [currentClub, setCurrentClub] = useState(null);

  const [avatar, setAvatar] = useState(null);
  const [dateTime, setDateTime] = useState(null);
  const [fullAddress, setFullAddress] = useState(null);

  const [isMapOpened, setIsMapOpened] = useState(false);

  const [myReply, setMyReply] = useState('');
  const [loadingYes, setLoadingYes] = useState(false);
  const [loadingNo, setLoadingNo] = useState(false);

  const [countAnsweredYes, setCountAnsweredYes] = useState(undefined);
  const [countAnsweredNo, setCountAnsweredNo] = useState(undefined);
  const [countNotAnswered, setCountNotAnswered] = useState(undefined);

  const [calledMembers, setCalledMembers] = useState([]);
  const [concerned, setConcerned] = useState(false);

  useEffect(() => {
    debug && console.debug('CardConvocation, convocation:', convocation);
    debug && console.debug('CardConvocation, convocation responses:', convocation.responses);
    debug && console.debug('CardConvocation, conversation:', myConversation);
    debug && console.debug('CardConvocation, fullScreen:', fullScreen);

    // Convocation image
    const avatar = convocation.avatarPath ? { uri: getImageApiHostOrUrl(convocation.avatarPath) } : null;
    if (!avatar) {
      // Conversation's logo
      setAvatar(myConversation.photoUrl ? { uri: getImageApiHostOrUrl(myConversation.photoUrl) } : null);
    } else {
      setAvatar(avatar);
    }

    convocation.userResponse && setMyReply(convocation.userResponse.PresenceReplyType_id);

    convocation.convocationAddress &&
      setFullAddress({
        name: convocation.convocationAddress,
        description: convocation.convocationAddress,
        latitude: parseFloat(convocation.convocationLatitude),
        longitude: parseFloat(convocation.convocationLongitude),
      });

    const convocationTime = moment(convocation.convocationDate + ' ' + convocation.convocationTime, 'DD-MM-YYYY HH:mm');
    setDateTime(convocationTime.format('Do MMM YYYY HH:mm'));
    // setDateTime(convocation.convocationDate + ' ' + convocation.convocationTime);

    const called =
      convocation.called && convocation.called.length > 0
        ? myConversation.participants.filter((user) => convocation.convocationCalled.includes(user.id))
        : myConversation.participants;
    setCalledMembers(called);

    setConcerned(called.some((user) => user.id === session.user.id));
  }, []);

  useEffect(() => {
    if (!convocation.responses || convocation.responses.length <= 0 || calledMembers.length <= 0) {
      return;
    }
    debug && console.debug('CardConvocation, responses:', convocation.responses);

    let yes = 0;
    let no = 0;
    for (const member of calledMembers) {
      debug && console.debug('CardConvocation, member:', member);
      const response = convocation.responses.find((item) => item.userId === member.id);
      if (response) {
        yes += response.accepted === true ? 1 : 0;
        no += response.accepted === false ? 1 : 0;
      }
    }

    debug && console.debug('CardConvocation, count:', calledMembers.length, yes, no);
    setCountAnsweredYes(yes);
    setCountAnsweredNo(no);
  }, [calledMembers]);

  const [textColor, setTextColor] = useState(Colors.black);
  useEffect(() => {
    setTextColor((currentClub && currentClub.mainColorBottom) ?? Colors.gray);
  }, [currentClub]);

  useEffect(() => {
    setCurrentClub(affiliatedClubInformation ?? session.club ?? null);
  }, [session, affiliatedClubInformation]);

  useEffect(() => {
    debug && console.debug('CardConvocation, final count:', calledMembers.length, countAnsweredYes, countAnsweredNo);

    setCountNotAnswered(calledMembers.length - countAnsweredNo - countAnsweredYes);
  }, [countAnsweredYes, countAnsweredNo]);

  if (!currentClub) {
    return null;
  }

  debug && console.debug('CardConvocation, count:', calledMembers.length, countAnsweredYes, countAnsweredNo);
  return (
    <ShadowCard style={[styles.mainContainer, style]}>
      <Pressable
        style={[
          styles.convocationCard,
          session.user.id === convocation.User_id ? { backgroundColor: Colors.grayWhite } : null,
        ]}
        onPress={() => {
          navigation.navigate('ConvocationDetails', { convocation });
        }}>
        <Image style={styles.userAvatar} source={avatar} />
        <View style={{ width: (sw - 100) / 2 }}>
          <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center' }}>
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={{ width: '100%', fontFamily: 'appFontMedium', fontSize: 14 }}>
              {convocation.convocationTitle}
            </Text>
          </View>

          <Text
            numberOfLines={1}
            ellipsizeMode="tail"
            style={[
              {
                fontFamily: 'appFontRegular',
                fontSize: 10,
                color: Colors.blueCorporate,
                opacity: 0.5,
              },
            ]}>
            {dateTime}
          </Text>

          <Text
            numberOfLines={!fullScreen ? 1 : 5}
            ellipsizeMode="tail"
            style={{
              width: '100%',
              fontFamily: convocation.hasSeenLastMessage ? 'appFontRegular' : 'appFontMedium',
              fontSize: convocation.hasSeenLastMessage ? 10 : 12,
              color: Colors.blueBgGradient1,
              opacity: 0.7,
            }}>
            {convocation.convocationDescription}
          </Text>
        </View>
        <View style={{ width: (sw - 100) / 2 }}>
          {fullAddress && (
            <Pressable
              style={{
                flexDirection: 'row-reverse',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
              onPress={() => {
                debug && console.debug('CardConvocation, map onPress', isMapOpened);
                if (fullScreen) {
                  setIsMapOpened(!isMapOpened);
                } else {
                  navigation.navigate('ConvocationDetails', { convocation });
                }
              }}
              onLongPress={() => {
                debug && console.debug('CardConvocation, map onLongPress', isMapOpened);
                openMap(null, fullAddress.latitude, fullAddress.longitude);
              }}>
              <View style={[{ marginLeft: 5 }, styles.actionButton]}>
                <Icon name="map" size={25} color={textColor} />
              </View>
              <Text
                style={{
                  fontFamily: 'appFontMedium',
                  color: Colors.blueCorporate,
                  fontSize: 12,
                  width: '75%',
                  textAlign: 'center',
                }}
                numberOfLines={3}
                ellipsizeMode="tail">
                {fullAddress.description}
              </Text>
            </Pressable>
          )}
        </View>
      </Pressable>

      {isMapOpened && <MapViewer marker={fullAddress} address={fullAddress} style={{ height: sh / 3 }} />}

      {concerned && (
        <View style={[styles.replyButtonsContainer, {}]}>
          <ButtonBase
            disabled={loadingYes}
            loading={loadingYes}
            label={I18n.t('message.responseYes')}
            style={[
              styles.replyButton,
              {
                backgroundColor:
                  myReply === REPLY_YES ? currentClub.mainColorBottom ?? Colors.blueCorporate : Colors.white,
              },
            ]}
            textStyle={{
              color: myReply === REPLY_YES ? Colors.white : currentClub.mainColorBottom ?? Colors.blueCorporate,
            }}
            onPress={() => {
              setLoadingYes(true);
              onConvocationReply({ ...convocation, presenceReplyType: REPLY_YES }, () => {
                setLoadingYes(false);
                setMyReply(REPLY_YES);
              });
            }}
          />
          <ButtonBase
            disabled={loadingNo}
            loading={loadingNo}
            label={I18n.t('message.responseNo')}
            style={[
              styles.replyButton,
              {
                backgroundColor:
                  myReply === REPLY_NO ? currentClub.mainColorBottom ?? Colors.blueCorporate : Colors.white,
              },
            ]}
            textStyle={{
              color: myReply === REPLY_NO ? Colors.white : currentClub.mainColorBottom ?? Colors.blueCorporate,
            }}
            onPress={() => {
              setLoadingNo(true);
              onConvocationReply({ ...convocation, presenceReplyType: REPLY_NO }, () => {
                setLoadingNo(false);
                setMyReply(REPLY_NO);
              });
            }}
          />
        </View>
      )}

      <View>
        <View style={{ borderBottomColor: textColor, borderBottomWidth: 3 }} />

        <View
          style={[
            styles.closerIcons,
            { backgroundColor: countNotAnswered <= 0 ? Colors.gray : Colors.whiteCorporate },
          ]}>
          <View style={styles.closerRow}>
            <Icon style={styles.closerIcon} name="thumbs-up" color={textColor} size={25} />
            <Text style={[styles.closerLabel, { color: textColor }]}>{countAnsweredYes ?? '-'}</Text>
          </View>

          {countNotAnswered > 0 && (
            <View style={styles.closerRow}>
              <Icon
                style={styles.closerIcon}
                name="close"
                color={countNotAnswered === 0 ? textColor : Colors.red}
                size={25}
              />
              <Text style={[styles.closerLabel, { color: countNotAnswered === 0 ? textColor : Colors.red }]}>
                {countNotAnswered}
              </Text>
            </View>
          )}

          <View style={styles.closerRow}>
            <Icon style={styles.closerIcon} name="thumbs-down" color={textColor} size={25} />
            <Text style={[styles.closerLabel, { color: textColor }]}>{countAnsweredNo ?? '-'}</Text>
          </View>
        </View>

        <View style={{ borderBottomColor: textColor, borderBottomWidth: 3 }} />
      </View>

      {fullScreen && (
        <View style={{ height: (sh * 2) / 3 }}>
          <View style={{ borderBottomColor: textColor, borderBottomWidth: 3 }} />

          <View style={styles.blockHeader}>
            <Text style={[styles.closerLabel, { color: textColor, width: '100%' }]}>
              {I18n.t('convocation.members')}
            </Text>
          </View>

          {fullScreen && (
            <ScrollView style={{ flex: 1, height: '100%', marginBottom: Platform.OS === 'ios' ? 60 : 40 }}>
              <FlatList
                data={calledMembers}
                key={numColumns}
                numColumns={numColumns}
                keyExtractor={(item, index) => item.id + index}
                renderItem={({ item, index }) => {
                  // find user response if user has already responded
                  const responseIndex = convocation.responses
                    ? convocation.responses.findIndex((response) => response.userId === item.id)
                    : -1;
                  if (responseIndex !== -1) {
                    item.answered = convocation.responses[responseIndex].accepted;
                  }
                  return (
                    <CardUser
                      key={`CR-${item.id}-${index}`}
                      club={currentClub}
                      member={item}
                      selectable={false}
                      callable
                      chattable={false}
                      extraIcon={
                        <Icon
                          name={item.answered === undefined ? 'md-close' : item.answered ? 'thumbs-up' : 'thumbs-down'}
                          size={25}
                          color={item.answered ? textColor : Colors.red}
                        />
                      }
                    />
                  );
                }}
              />
            </ScrollView>
          )}
          <View style={{ borderBottomColor: textColor, borderBottomWidth: 3 }} />
        </View>
      )}
    </ShadowCard>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    width: (Platform.OS === 'web' ? gMaxWidth : sw) - 20,
    padding: 10,
    margin: 10,
  },
  convocationCard: {
    width: '100%',

    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    overflow: 'visible',
    borderColor: 'transparent',
    marginBottom: 10,
  },
  userAvatar: {
    width: 50,
    height: 50,
    borderRadius: 100,
    marginRight: 10,
    marginLeft: 0,
  },

  blockHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    paddingVertical: 0,
    paddingHorizontal: 5,
    borderBottomColor: Colors.gray,
    borderBottomWidth: 1,
  },

  replyButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottomColor: Colors.blueCorporate,
    borderBottomWidth: 1,
  },
  replyButton: {
    width: '48%',
    marginHorizontal: '1%',
    height: 25,
  },
  replyButtonLabel: {
    fontSize: 12,
    color: Colors.iconGray,
  },

  closerIcons: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingLeft: 0,
    paddingTop: 10,
    paddingBottom: 15,
    paddingRight: 20,
  },

  closerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  closerLabel: {
    color: Colors.whiteCorporate,
    // fontFamily: "appFontMedium",
    fontSize: 18,
  },
  closerIcon: {
    width: 20,
    height: 28,
    marginHorizontal: 10,
  },
});

export default CardConvocation;
