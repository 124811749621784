import { MessageAPI } from '@apis/message';
import { TeamAPI } from '@apis/team';
import CardTeam from '@components/CardTeam/CardTeam';
import HeaderBanner from '@components/HeaderBanner/HeaderBanner';
import Loading from '@components/Loading/Loading';
import Colors from '@configs/colors';
import alert from '@utils/alert';
import { checkIsAdmin, checkIsModeratorOrAdmin } from '@utils/checkUserRoles';
import I18n from '@utils/i18n';
import React, { Component } from 'react';
import { FlatList, Text, View } from 'react-native';
import { connect } from 'react-redux';

class ClubTeams extends Component {
  page = 1;
  limit = 10;
  hasNextPage = false;
  loadingNextPage = false;

  constructor(props) {
    super(props);
    this.state = {
      isModerator: false,
      isAdmin: false,
      showDetail: false,
      keyword: '',
      teamsList: [],
      refreshing: false,
      loading: false,
      currentClub: this.props.foundOtherClub ?? this.props.affiliatedClubInformation ?? this.props.session.club ?? null,
      editingTeam: this.props.editingTeam ?? null,
      pendingStatus: this.props.apiConfiguration.teamManagement ?? false,
    };
  }

  componentDidMount() {
    this.checkModeration();

    this.getTeamsList();

    this.focusListener = this.props.navigation.addListener('focus', () => {
      this.handleAffiliation();
    });
  }

  componentWillUnmount() {
    this.focusListener();
  }

  /**
   * Function used to update the currentClub of this screen
   */
  handleAffiliation() {
    if (this.props.foundOtherClub) {
      this.setState(
        {
          currentClub: this.props.foundOtherClub,
        },
        () => this.getTeamsList()
      );
    } else if (
      this.props.affiliatedClubInformation &&
      this.state.currentClub !== this.props.affiliatedClubInformation
    ) {
      this.setState(
        {
          currentClub: this.props.affiliatedClubInformation
            ? this.props.affiliatedClubInformation
            : this.props.session.club,
        },
        () => {
          this.checkModeration();
          this.getTeamsList();
        }
      );
    } else if (!this.props.foundOtherClub && !this.props.affiliatedClubInformation) {
      this.setState(
        {
          currentClub: this.props.session.club,
        },
        () => {
          this.checkModeration();
          this.getTeamsList();
        }
      );
    }
  }

  checkModeration = () => {
    this.setState((currentState) => ({
      isModerator: checkIsModeratorOrAdmin(currentState.currentClub),
      isAdmin: checkIsAdmin(currentState.currentClub),
    }));
  };

  /**
   * Function to start conversation with a selected user
   * @param {object} userToContact
   */
  startConversation = (userToContact = null) => {
    if (!userToContact) {
      return;
    }

    if (this.props.session?.user?.id === userToContact.id) {
      alert(I18n.t('app.oups'), 'Vous ne pouvez pas vous envoyer un message à vous même -)');
      return;
    }

    this.setState({ loading: true });

    MessageAPI.addConversation(this.props.tokens.wsToken, '', [userToContact.id]).then((response) => {
      if (response && response.success) {
        this.props.navigation.navigate('MessagesStack', {
          screen: 'Conversation',
          params: { chat: response.result },
        });
      } else if (response?.success === false) {
        alert(I18n.t('app.oups'), 'Erreur lors de la création de la conversation.');
      } else {
        alert(I18n.t('app.oups'), 'Une erreur est survenue.');
      }
      this.setState({ loading: false });
    });
  };

  confirmUserTeam = (wsToken, userId, teamId, confirmed) => {
    this.setState({ loading: true });
    TeamAPI.confirmUserTeam(wsToken, userId, teamId, confirmed).then((response) => {
      if (response?.success === true) {
        alert(I18n.t('app.success'), response.result + ' avec succès !');
        this.getTeamsList();
      } else {
        alert(I18n.t('app.oups'), "Une erreur est survenue. Vous n'avez peut être pas les droits nécessaires.");
        console.warn('ClubTeam, confirmUserTeam', response);
      }
      this.setState({ loading: false });
    });
  };

  deleteUserTeam = (wsToken, action, teamId, userIds) => {
    this.setState({ loading: true });
    TeamAPI.editTeam(wsToken, action, teamId, userIds).then((response) => {
      if (response?.success === true) {
        alert(I18n.t('app.success'), "L'utilisateur a bien été supprimé de l'équipe.");
        this.setState({ teamList: response.result.teams });
      } else {
        alert(I18n.t('app.oups'), "Une erreur est survenue. Vous n'avez peut être pas les droits nécessaires.");
        console.warn('ClubTeam, deleteUserTeam', response);
      }
      this.setState({ loading: false });
    });
  };

  getTeamsList = () => {
    this.setState({ loading: true });

    TeamAPI.getTeamsList(
      this.props.tokens.wsToken,
      this.state.currentClub.id,
      this.state.keyword,
      this.page,
      this.limit
    ).then((response) => {
      if (response?.success === true) {
        this.hasNextPage = response.result.hasNextPage;
        this.hasNextPage = true;
        this.setState({ teamsList: response.result.teams });
      }
      this.setState({ refreshing: false, loading: false });
    });
  };

  nextPage = () => {
    if (!this.loadingNextPage && this.hasNextPage && !this.state.refreshing) {
      this.loadingNextPage = true;
      this.page += 1;
      TeamAPI.getTeamsList(
        this.props.tokens.wsToken,
        this.state.currentClub.id,
        this.state.keyword,
        this.page,
        this.limit
      ).then((response) => {
        if (response?.success === true) {
          this.hasNextPage = response.result.hasNextPage;

          if (response.result.teams && response.result.teams.length > 0) {
            this.setState((currentState) => ({ teamsList: [...currentState.teamsList, response.result.teams] }));
          }
        }
        this.setState({ refreshing: false, loading: false });
        this.loadingNextPage = false;
      });
    }
  };

  refresh = async () => {
    this.page = 1;
    this.setState({ refreshing: true });
    this.getTeamsList();
  };

  renderDataPage = () => {
    if (this.state.loading || this.state.refreshing) {
      return <Loading loading={this.state.loading || this.state.refreshing} />;
    }

    if (this.state.teamsList.length <= 0) {
      return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text
            style={{ marginTop: 20, color: Colors.blueCorporate + 'CC', fontFamily: 'appFontRegular', fontSize: 16 }}>
            {' '}
            Le club n'a pas encore déclaré d'équipes
          </Text>
        </View>
      );
    }

    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
        <FlatList
          data={this.state.teamsList}
          key={1}
          numColumns={1}
          keyExtractor={(item, index) => item.id + index}
          renderItem={({ item, index }) => (
            <CardTeam
              navigation={this.props.navigation}
              club={this.state.currentClub}
              team={item}
              imageHeader
              opened={false}
              chattable={this.state.isModerator}
              selectable={false}
              allSelectable={false}
              showCategories
              onOpenChat={(member) => {
                this.startConversation(member);
              }}
              pendingStatus={this.state.pendingStatus}
              onConfirmUserTeam={(wsToken, userId, teamId, confirmed) => {
                this.confirmUserTeam(wsToken, userId, teamId, confirmed);
              }}
              onDeleteUserTeam={(wsToken, action, teamId, userIds) => {
                this.deleteUserTeam(wsToken, action, teamId, userIds);
              }}
            />
          )}
        />
      </View>
    );
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        <HeaderBanner
          leftIcon="md-arrow-back"
          onLeftButton={() => this.props.navigation.goBack()}
          rightIcon="md-menu"
          onRightButton={async () => {
            this.props.navigation.goBack();
            this.props.navigation.openDrawer();
          }}
        />

        <View style={{ flex: 1, marginTop: 0 }}>
          <this.renderDataPage />
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { tokens, apiConfiguration } = state.app;
  const { session, affiliatedClubInformation, foundOtherClub, editingTeam } = state.session;
  return {
    tokens,
    apiConfiguration,
    session,
    affiliatedClubInformation,
    foundOtherClub,
    editingTeam,
  };
};

export default connect(mapStateToProps)(ClubTeams);
