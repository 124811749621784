import { NewsAPI } from '@apis/news';
import { getImageApiHostOrUrl } from '@configs/host';
import {
  RAZ_ACTUALITY,
  SET_ACTUALITY_ERROR,
  SET_ACTUALITY_LOADING,
  SET_ACTUALITY_NOT_LOADING,
  SET_ACTUALITIES,
  DELETE_ACTUALITY,
  UPDATE_ACTUALITY,
  VALIDATE_ACTUALITY,
} from '@redux/reducers/ActualityReducer';
import { getImageSize, updateSize } from '@utils/images';
import moment from 'moment/moment';
import { Dimensions } from 'react-native';

const { width: sw, height: sh } = Dimensions.get('window');

const debug = false;

export function resetStateActuality() {
  return async (dispatch) => {
    dispatch({ type: RAZ_ACTUALITY });
  };
}

const injectSizeIntoItems = async (medias) => {
  const items = [];
  debug && console.debug(`ActualityActions, window:`, sw, sh);

  for (const media of medias) {
    const start = Date.now();
    debug && console.debug(`ActualityActions, ${media.Actuality_id}/${media.id}, media:`, media);

    const uri = getImageApiHostOrUrl(media.filename);

    if (media.type === 'video') {
      // todo: maybe creating a thumbnail?
      items.push({
        ...media,
        valid: true,
        uri,
        ...updateSize(media, sw, sh, 16 / 9, medias.length),
        // ...updateSize({ ...media, sw, sh }, sw, sh, sw / sh, medias.length),
      });
    } else {
      // todo: should be replaced with getSize from expo-image on next update!
      const { width, height } = await getImageSize(uri);
      debug && console.debug(`ActualityActions, ${media.Actuality_id}/${media.id} getImageSize: ${width} x ${height}`);

      items.push({
        ...media,
        uri,
        ...updateSize({ ...media, width, height }, sw, sh, width / height, medias.length),
      });
    }
    debug &&
      console.debug(`ActualityActions, ${media.Actuality_id}/${media.id} Image.getSize, duration:`, Date.now() - start);
  }

  return items;
};

export async function updateActuality(actuality) {
  const updatedActuality = { ...actuality };
  const splitAddress = actuality.address && actuality.address.split('|');
  updatedActuality.addressSimple = splitAddress && splitAddress.length > 1 ? splitAddress[0] : actuality.address;
  updatedActuality.addressExtended = splitAddress && splitAddress.length > 1 ? splitAddress[1] : '';

  updatedActuality.myReply =
    (actuality.currentUserReply && actuality.currentUserReply.ActualityPresenceReplyType_id) || null;
  updatedActuality.comments = actuality.comments ? actuality.comments.reverse() : [];

  updatedActuality.eventDate = moment(actuality.releaseDate, 'YYYY-MM-DD HH:mm:ss');
  updatedActuality.dayOfWeek = moment(updatedActuality.eventDate).format('dddd');
  updatedActuality.theDate = moment(updatedActuality.eventDate).calendar();

  if (actuality.images.length > 0) {
    updatedActuality.images = await injectSizeIntoItems(actuality.images);
  }

  return updatedActuality;
}

/**
 * function used to get all actualities
 * @param {*} token
 * @param {*} page
 * @param {*} limit
 * @param isMyClub
 * @param clubsIds
 * @param departmentsIds
 * @param regionsIds
 * @param forceUpdate
 * @param pendingFilter
 * @param callback
 * @returns
 */
export function getActualities(
  token,
  page,
  limit,
  isMyClub = true,
  clubsIds = [],
  departmentsIds = [],
  regionsIds = [],
  forceUpdate = false,
  pendingFilter = false,
  callback
) {
  return async (dispatch) => {
    try {
      const start = Date.now();
      dispatch({ type: SET_ACTUALITY_LOADING });

      // async/await synchronous call and media management to get images size!
      const response = await NewsAPI.getAllNews(
        token,
        page,
        limit,
        isMyClub ? 1 : 0,
        clubsIds,
        departmentsIds,
        regionsIds,
        pendingFilter ? 0 : 1
      );
      if (response && response.success) {
        const newActualities = await Promise.all(response.result.news.map((item) => updateActuality(item)));
        dispatch({
          type: SET_ACTUALITIES,
          data: newActualities,
          hasNextPage: response.result.hasNextPage,
          forceUpdate,
        });

        callback(response.success, response.result);
      } else {
        callback(false);

        dispatch({ type: SET_ACTUALITY_ERROR });
      }

      const duration = Date.now() - start;
      setTimeout(() => {
        dispatch({ type: SET_ACTUALITY_NOT_LOADING });
      }, Math.max(1000, duration));
    } catch (error) {
      console.warn('getActualities, catch:', error);
      console.trace();
      if (error) {
        dispatch({
          type: SET_ACTUALITY_ERROR,
          errorCode: error,
        });
      }
    }
  };
}

/**
 * function used to get / update only one actuality
 * @param {*} token
 * @param {*} actualityId
 * @param callback
 * @returns
 */
export function getNews(token, actualityId, callback = () => {}) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ACTUALITY_LOADING });

      NewsAPI.getNews(token, actualityId).then(async (response) => {
        debug && console.debug(`ActualityActions getNews, got:`, response);

        dispatch({ type: SET_ACTUALITY_NOT_LOADING });

        if (response && response.success) {
          dispatch({
            type: UPDATE_ACTUALITY,
            data: await updateActuality(response.result),
          });

          callback(response.success);
        } else {
          callback(false);

          dispatch({ type: SET_ACTUALITY_ERROR });
        }
      });
    } catch (error) {
      console.warn('getNews, catch:', error);
      if (error) {
        dispatch({
          type: SET_ACTUALITY_ERROR,
          errorCode: error,
        });
      }
    }
  };
}

/**
 * Function to delete an actuality
 * @param {*} token
 * @param {*} actualityId
 * @param callback
 * @returns
 */
export function deleteNews(token, actualityId, callback = () => {}) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ACTUALITY_LOADING });

      NewsAPI.deleteNews(token, actualityId).then((response) => {
        debug && console.debug(`ActualityActions deleteNews, got:`, response);

        dispatch({ type: SET_ACTUALITY_NOT_LOADING });

        if (response && response.success) {
          dispatch({
            type: DELETE_ACTUALITY,
            actualityId,
          });

          callback(response.success);
        } else {
          callback(false);

          dispatch({ type: SET_ACTUALITY_ERROR });
        }
      });
    } catch (error) {
      console.warn('deleteNews, catch:', error);
      if (error) {
        dispatch({
          type: SET_ACTUALITY_ERROR,
          errorCode: error,
        });
      }
    }
  };
}

/**
 * Function to validate an actuality
 * @param {*} token
 * @param {*} actualityId
 * @param callback
 * @returns
 */
export function validateNews(token, actualityId, callback = () => {}) {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_ACTUALITY_LOADING });

      NewsAPI.validateNews(token, actualityId).then((response) => {
        debug && console.debug(`ActualityActions validateNews, got:`, response);

        dispatch({ type: SET_ACTUALITY_NOT_LOADING });

        if (response && response.success) {
          dispatch({
            type: VALIDATE_ACTUALITY,
            actualityId,
          });

          callback(response.success);
        } else {
          callback(false);

          dispatch({ type: SET_ACTUALITY_ERROR });
        }
      });
    } catch (error) {
      console.warn('validateNews, catch:', error);
      if (error) {
        dispatch({
          type: SET_ACTUALITY_ERROR,
          errorCode: error,
        });
      }
    }
  };
}
