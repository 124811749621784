import { AccountAPI } from '@apis/account';
import { DeviceAPI } from '@apis/device';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import ButtonBase from '@components/ButtonBase/ButtonBase';
import ButtonValidate from '@components/ButtonValidate/ButtonValidate';
import GradientBackground from '@components/GradientBackground/GradientBackground';
import Loading from '@components/Loading/Loading';
import Colors from '@configs/colors';
import { registerForPushNotificationsAsync } from '@configs/notification/initSystemNotification';
import { gMaxWidth } from '@configs/styles';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  getAllClubs,
  getAllNotifications,
  getClubComposition,
  getClubPartners,
  setAffiliatedClubs,
} from '@redux/actions';
import {
  RESET_FOR_LOGOUT,
  SET_CONST_PROFILES_TYPES,
  SET_CURRENT_PAGE,
  SET_DISCOVERY_MODE,
  SET_SESSION,
  SET_SIGNED_IN,
  SET_TOKENS,
  SET_USER_STATUS,
} from '@redux/storeReducer';
import alert from '@utils/alert';
import I18n from '@utils/i18n';
import { Validators } from '@utils/validators';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import md5 from 'md5';
import React, { useEffect, useState } from 'react';
import { Dimensions, KeyboardAvoidingView, Platform, Pressable, StyleSheet, Text, TextInput, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { useDispatch, useSelector } from 'react-redux';

const { width: sw } = Dimensions.get('window');

const appSlug = Constants.expoConfig.slug || 'eappli';

export default function Login({ navigation }) {
  const debug = false;

  const [loading, setLoading] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertButtons, setAlertButtons] = useState(null);
  const [alertLink, setAlertLink] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordSecure, setPasswordSecure] = useState(true);
  const [saveMyAccess, setSaveMyAccess] = useState(true);
  const [hasAcceptedAgreements, setHasAcceptedAgreements] = useState(false);
  const [userSession, setUserSession] = useState(null);

  const dispatch = useDispatch();

  const { languageCode, tokens } = useSelector((state) => state.app);

  useEffect(() => {
    debug && console.debug('Login, mounting...');

    try {
      const saveAccess = JSON.parse(AsyncStorage.getItem(`${appSlug}-saveAccess`));
      setSaveMyAccess(saveAccess ?? true);
    } catch (error) {
      setSaveMyAccess(true);
      AsyncStorage.setItem(`${appSlug}-saveAccess`, JSON.stringify(true));
    }

    try {
      // Clear all the local storage
      AsyncStorage.clear(() => {
        dispatch({ type: SET_SIGNED_IN, value: false });
        dispatch({ type: SET_USER_STATUS, value: 'signing-in' });
        dispatch({ type: RESET_FOR_LOGOUT });
      });
    } catch (error) {
      console.warn('TabProfile, logout - error:', error);
      return null;
    }

    // For sure, we are not discovering -)
    dispatch({ type: SET_DISCOVERY_MODE, value: false });

    // Update Redux constant profiles list
    AccountAPI.getAllProfileTypes().then((listProfileTypes) => {
      if (listProfileTypes && listProfileTypes.success) {
        dispatch({ type: SET_CONST_PROFILES_TYPES, value: listProfileTypes.result });
      }
    });
  }, []);

  useEffect(() => {
    if (hasAcceptedAgreements) {
      const userToken = tokens.wsToken;

      dispatch({ type: SET_CURRENT_PAGE, value: 'HomeStack' });

      dispatch(getClubComposition(userToken, userSession.club.id));

      // Elipce/Fred: page and limit are useless, because ignored by the backend!
      dispatch(getClubPartners(userToken, userSession.club.id, 1, 5));

      dispatch(getAllNotifications(userToken));

      dispatch(getAllClubs());

      registerForPushNotificationsAsync(userToken);

      dispatch({ type: SET_SESSION, value: userSession });
      dispatch({ type: SET_SIGNED_IN, value: true });

      const orderedClubs = userSession.clubsAffiliated.sort((a, b) => a.name.localeCompare(b.name));
      dispatch(setAffiliatedClubs(orderedClubs, userSession.club.id));

      setLoading(false);
    }
  }, [hasAcceptedAgreements]);

  const getMyAccount = (userToken, wsRefreshToken) => {
    debug && console.debug('Login, getting my account data...');
    AccountAPI.getMyAccount(userToken).then((response) => {
      if (response && response.success) {
        debug && console.debug('Login, getMyAccount, success', response.result);
        try {
          const session = response.result;
          if (saveMyAccess) {
            AsyncStorage.setItem(`${appSlug}-session`, JSON.stringify(session));
          }

          // Check if user accepted agreements -> if not block and display modal
          debug && console.debug(response.result.user?.hasAcceptedAgreements);
          if (response.result.user?.hasAcceptedAgreements !== '1') {
            agreements(userToken, session);
          } else {
            // User has accepted agreements
            setUserSession(session);
            setHasAcceptedAgreements(true);
          }
        } catch (error) {
          console.warn('Login, getMyAccount, error:', error);
          setLoading(false);
        }
      } else {
        console.warn('Login, getMyAccount, failure', response);
        setLoading(false);
        dispatch({ type: SET_SESSION, value: null });
        dispatch({ type: SET_SIGNED_IN, value: false });
      }
    });
  };

  const logOut = () => {
    debug && console.debug('Login, user signing out...');

    dispatch({ type: SET_TOKENS, value: null });
    dispatch({ type: SET_SESSION, value: null });

    // Clear all the local storage
    AsyncStorage.clear(() => {
      dispatch({ type: SET_SIGNED_IN, value: false });
      dispatch({ type: SET_USER_STATUS, value: 'signing-in' });
      dispatch({ type: RESET_FOR_LOGOUT });

    });
  };

  const login = () => {
    if (!Validators.validEmail(email)) {
      setLoading(false);
      setAlertTitle(I18n.t('app.oups'));
      setAlertMessage(I18n.t('app.invalidEmailAddress'));
      setAlertButtons([]);
      setShowAlert(true);
      return;
    }
    if (!Validators.validPassword(password)) {
      setLoading(false);
      setAlertTitle(I18n.t('app.oups'));
      setAlertMessage(I18n.t('app.invalidPassword'));
      setAlertButtons([]);
      setShowAlert(true);
      return;
    }

    setLoading(true);

    const md5Password = md5(password);
    debug && console.debug('Login, data ', email, md5Password, languageCode);
    AccountAPI.authenticate(email, md5(password), languageCode).then(async (response) => {
      debug && console.debug('Login, server authentication response', response);
      if (response && response.success) {
        debug && console.debug('Login, server authentication response', response.result);
        dispatch({ type: SET_TOKENS, value: response.result });

        if (saveMyAccess) {
          // Store user credentials
          await AsyncStorage.setItem(`${appSlug}-credentials`, JSON.stringify({ login: email, password: md5Password }));
          // Store user tokens
          await AsyncStorage.setItem(`${appSlug}-tokens`, JSON.stringify(response.result));
        }

        // Register expo push token - not for the Web because it needs some modifications to work correctly - TBD later
        if (Platform.OS === 'android' || Platform.OS === 'ios') {
          try {
            // Register user device push token server side
            const token = (await Notifications.getExpoPushTokenAsync()).data;

            await DeviceAPI.registerDevice(token, response.result.wsToken);
          } catch (error) {
            console.warn('Login, getExpoPushTokenAsync, error:', error);
            // return null;
          }
        }

        debug && console.debug('Login, get my account', response.result);
        await getMyAccount(response.result.wsToken, response.result.refreshToken);
      } else {
        debug && console.debug('Login, failed');

        setLoading(false);
        setAlertTitle(I18n.t('app.oups'));
        setAlertMessage(I18n.t('myAccount.failed'));
        setAlertButtons([]);
        setShowAlert(true);
      }
    });
  };

  const agreements = (userToken, session) => {
    debug && console.debug('Login, getMyAccount, user has not accepted agreements');
    dispatch({ type: SET_USER_STATUS, value: 'validating-tos' });

    setLoading(false);
    setAlertTitle(I18n.t('app.information'));
    setAlertMessage(I18n.t('myAccount.agreements'));
    setAlertButtons([
      {
        text: I18n.t('myAccount.ok'),
        onPress: () => {
          validatingToS(userToken, session);
        },
      },
      {
        text: I18n.t('myAccount.cancel'),
        onPress: async () => {
          await logOut();
        },
      },
    ]);
    setAlertLink(Constants.expoConfig.extra.tosUrl);
    setShowAlert(true);
  };

  const validatingToS = (userToken, session) => {
    debug && console.debug('login, validating terms of service');

    AccountAPI.updateUserAcceptedAgreements(userToken).then((response) => {
      if (!response) {
        // User not accepted or error -> logout
        alert(
          I18n.t('app.information'),
          'Erreur, veuillez vous reconnecter.',
          [
            {
              text: I18n.t('myAccount.ok'),
              onPress: () => {
                logOut();
              },
            },
          ],
          {
            cancelable: false,
          }
        );
      } else {
        setUserSession(session);
        setHasAcceptedAgreements(true);
      }
    });
  };

  return (
    <GradientBackground>
      <View style={styles.rootContainer}>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'position'}
          keyboardVerticalOffset={Platform.OS === 'ios' ? 50 : 50}>
          <View style={[styles.inputContainer, styles.shadowEffect]}>
            <TextInput
              style={[styles.textInput, { width: Platform.OS === 'web' ? gMaxWidth - 120 : sw - 120 }]}
              value={email}
              placeholder={I18n.t('account.email')}
              onChangeText={(email) => {
                setEmail(email);
              }}
              keyboardType="email-address"
              autoCapitalize="none"
              autoComplete="email"
              textContentType="username"
              autoCorrect={false}
            />

            <View style={[{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }]}>
              <TextInput
                style={[styles.textInput, { width: Platform.OS === 'web' ? gMaxWidth : sw - 150 }]}
                value={password}
                placeholder={I18n.t('account.password')}
                onChangeText={(password) => {
                  setPassword(password);
                }}
                secureTextEntry={passwordSecure}
                autoCapitalize="none"
                autoComplete="password"
                textContentType="password"
                autoCorrect={false}
              />

              <Pressable
                style={{ padding: 5, alignItems: 'center', justifyContent: 'center' }}
                onPress={() => {
                  setPasswordSecure(!passwordSecure);
                }}>
                <Icon
                  name="md-eye"
                  size={24}
                  color={Colors.gray}
                  style={{
                    position: 'relative',
                    top: 0.5,
                    left: '50%',
                    transform: [{ translateX: Platform.OS === 'ios' ? -6 : -2 }],
                  }}
                />
                {!passwordSecure && (
                  <Icon
                    name="md-close"
                    size={32}
                    color={Colors.red}
                    style={{
                      position: 'absolute',
                      top: 0.5,
                      left: '50%',
                      transform: [{ translateX: Platform.OS === 'android' ? -2 : -6 }],
                    }}
                  />
                )}
              </Pressable>
            </View>

            <Pressable
              style={[styles.checkButton]}
              onPress={() => {
                setSaveMyAccess(!saveMyAccess);
                AsyncStorage.setItem(`${appSlug}-saveAccess`, JSON.stringify(saveMyAccess));
              }}>
              <View style={[styles.checkBorder, { marginTop: Platform.OS === 'ios' ? -5 : 0 }]}>
                {saveMyAccess === true ? <View style={[styles.checkChecked]} /> : null}
              </View>
              <Text style={[styles.whiteText, styles.appFontRegular, { fontSize: 12 }]}>
                {I18n.t('login.saveAccess')}
              </Text>
            </Pressable>
          </View>

          <ButtonValidate style={styles.signInButton} label={I18n.t('login.signIn')} onPress={() => login()} />
        </KeyboardAvoidingView>

        <View style={styles.buttonsContainer}>
          <ButtonBase
            style={styles.joinUsButton}
            textStyle={{ color: Colors.whiteCorporate, fontFamily: 'appFontMedium' }}
            onPress={() => {
              navigation.navigate('SignUp');
            }}
            label={I18n.t('login.newAccount')}
          />

          <ButtonBase
            style={styles.lostPaswordButton}
            onPress={() => {
              navigation.navigate('LostPassword');
            }}
            label={I18n.t('login.lostPassword')}
          />
        </View>
      </View>

      <Loading loading={loading} />

      <AlerteUI
        showAlert={showAlert}
        onClose={() => {
          setShowAlert(false);
        }}
        title={alertTitle}
        message={alertMessage}
        buttons={alertButtons}
        link={alertLink}
      />
    </GradientBackground>
  );
}

const styles = StyleSheet.create({
  rootContainer: {},
  inputContainer: {
    justifyContent: 'center',
    marginTop: 240,
    marginBottom: 5,
    marginHorizontal: 40,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    backgroundColor: Colors.blueCorporate,
  },
  shadowEffect: {
    marginBottom: 15,

    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },

  whiteText: {
    color: Colors.whiteCorporate,
  },
  appFontRegular: {
    fontFamily: 'appFontRegular',
  },
  appFontMedium: {
    fontFamily: 'appFontMedium',
  },
  textInput: {
    width: Platform.OS === 'web' ? gMaxWidth : sw - 120,
    maxWidth: 600,
    backgroundColor: Colors.whiteCorporate,
    borderRadius: 20,
    height: 34,
    paddingLeft: 15,
    paddingRight: 15,
    marginVertical: 10,
    fontFamily: 'appFontRegular',
  },

  buttonsContainer: {
    alignSelf: 'center',
    flexDirection: 'column',
    // justifyContent: 'space-evenly',
    alignItems: 'center',
    width: (Platform.OS === 'web' ? gMaxWidth : sw) / 1.5,
    // height: 40,
    marginVertical: 5,
    paddingHorizontal: 20,
    borderRadius: 10,
    // backgroundColor: Colors.blueSky,
  },

  signInButton: {
    color: Colors.whiteCorporate,
    alignSelf: 'center',
  },
  lostPaswordButton: {
    alignSelf: 'center',
    alignItems: 'center',
    height: 32,
  },
  joinUsButton: {
    alignSelf: 'center',
    alignItems: 'center',
    backgroundColor: Colors.blueCorporate,
  },

  linkButton: {
    paddingTop: 7,
    paddingBottom: 5,
  },
  checkButton: {
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
    margin: 10,
    padding: 10,
    paddingBottom: 0,
  },
  checkBorder: {
    borderWidth: 1.5,
    borderColor: Colors.whiteCorporate,
    borderRadius: 2,
    width: 12,
    height: 12,
    padding: 1.5,
    marginRight: 12,
  },
  checkChecked: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.whiteCorporate,
    borderRadius: 1,
  },
});
