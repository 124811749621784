import { MSG_CONVOCATION, MSG_CONVOCATION_REPLY, MSG_SIMPLE_MESSAGE, REPLY_NO, REPLY_YES } from '@apis/message';
import Images from '@assets/images';
import ButtonBase from '@components/ButtonBase/ButtonBase';
import FullWidthImage from '@components/FullWidthImage/FullWidthImage';
import ShadowCard from '@components/ShadowCard/ShadowCard';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import alert from '@utils/alert';
import I18n from '@utils/i18n';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { Dimensions, Image, Pressable, Text, View } from 'react-native';
import { Autolink } from 'react-native-autolink';
import Icon from 'react-native-vector-icons/Ionicons';
import { useSelector } from 'react-redux';

import styles from './styles';

const { width: sw } = Dimensions.get('window');

export default function ChatMessage({
  navigation,
  moderated,
  message,
  hourDisplay,
  dateDisplay,
  onRemoveMessage,
  onDisplayImage,
  onConvocationReply,
}) {
  const [fromMe, setFromMe] = useState(false);

  const [loadingYes, setLoadingYes] = useState(false);
  const [loadingNo, setLoadingNo] = useState(false);

  const [deleted, setDeleted] = useState(false);
  const [messageImage, setMessageImage] = useState(null);
  const [messageText, setMessageText] = useState(null);
  const [messageSender, setMessageSender] = useState(null);
  const [messageType, setMessageType] = useState('1');

  const [dateTime, setDateTime] = useState(null);
  const [outdated, setOutdated] = useState(false);
  const [myReply, setMyReply] = useState('');
  const [concerned, setConcerned] = useState(true);

  const { session } = useSelector((state) => state.session);
  const { affiliatedClubInformation } = useSelector((state) => state.session);

  const [currentClub, setCurrentClub] = useState(null);

  const onLayout = useCallback(
    (event) => {
      // const containerWidth = event.nativeEvent.layout.width;
      // const containerHeight = event.nativeEvent.layout.height;
      // console.log('ChatMessage layout:', containerWidth, containerHeight);
    },
    [messageImage]
  );

  useEffect(() => {
    // debug && console.debug('ChatMessage, message:', message);

    if (session.user.id === message.User_id) {
      setFromMe(true);
    }
    if (message.isDeleted === MSG_SIMPLE_MESSAGE) {
      setDeleted(true);
    }
    setMessageType(message.MessageType_id);
    setMessageSender(`${message.senderFirstname.trim()} ${message.senderLastname.trim()}`);
    if (message.filename && message.filename !== '/messages/') {
      setMessageImage({ uri: getImageApiHostOrUrl(message.filename) });
    }
    if (message.content) {
      setMessageText(message.content);
    }
  }, []);

  useEffect(() => {
    if (!message.convocation) {
      return;
    }

    // Convocation stuff
    if (message.MessageType_id === MSG_CONVOCATION) {
      setMessageText(message.convocationTitle);

      const convocationTime = moment(message.convocationDate + ' ' + message.convocationTime, 'DD-MM-YYYY HH:mm');
      setDateTime(convocationTime.format('Do MMM YYYY HH:mm'));
      if (moment().isAfter(convocationTime)) {
        setOutdated(true);
      }

      if (message.convocation) {
        if (session.user.id === message.convocation.User_id) {
          setConcerned(true);
        } else {
          if (message.convocation?.called) {
            setConcerned(message.convocation?.called.includes(session.user.id));
          } else {
            setConcerned(false);
          }
        }
      }

      message.userResponse && setMyReply(message.userResponse.PresenceReplyType_id);
    }

    // Response to convocation stuff
    if (message.MessageType_id === MSG_CONVOCATION_REPLY) {
      setMessageText(I18n.t('message.convocationResponse'));

      if (message.convocation) {
        if (session.user.id === message.convocation.User_id) {
          setConcerned(true);
        } else {
          setConcerned(message.convocation?.called.includes(session.user.id));
        }
      }
      setMyReply(message.PresenceReplyType_id);
    }
  }, [message.convocation]);

  useEffect(() => {
    const currentClub = affiliatedClubInformation ?? session.club ?? null;
    setCurrentClub(currentClub);
  }, [session, affiliatedClubInformation]);

  if (!message) {
    return;
  }

  if (!messageImage && !messageText) {
    return;
  }

  if (deleted) {
    return (
      <View key={'Message-' + message.id} onLayout={onLayout}>
        {dateDisplay.display ? (
          <Text style={[styles.dayAndHour, { alignSelf: 'center', marginBottom: 15 }]}>{dateDisplay.date}</Text>
        ) : (
          <View style={{ marginTop: 25 }} />
        )}

        <View
          style={[
            fromMe
              ? { right: 40, alignSelf: 'flex-end' }
              : { left: 40, alignSelf: 'flex-start', alignItems: 'flex-start' },
          ]}>
          <ShadowCard
            style={[
              { alignSelf: fromMe ? 'flex-end' : 'flex-start', maxWidth: sw / 2, padding: 10 },
              messageImage ? { width: sw - 80 } : null,
            ]}>
            <Text
              style={[
                styles.mesText,
                { fontStyle: 'italic', textAlign: 'right' },
                fromMe ? { marginRight: 20 } : { marginLeft: 20 },
              ]}>
              Ce message a été supprimé
            </Text>

            <Text style={[styles.mesAuthor, { textAlign: fromMe ? 'right' : 'left' }]}>
              {fromMe ? `${messageSender} - ${hourDisplay}` : `${hourDisplay} - ${messageSender}`}
            </Text>
          </ShadowCard>
        </View>

        <View style={[styles.avatarContainer, fromMe ? { right: 10 } : { left: 10 }]}>
          <Image
            style={styles.userAvatar}
            source={message.avatarPath ? { uri: getImageApiHostOrUrl(message.avatarPath) } : Images.userPlaceholder}
          />
        </View>
      </View>
    );
  }

  return (
    <View key={'Message-' + message.id} onLayout={onLayout}>
      {dateDisplay.display ? (
        <Text style={[styles.dayAndHour, { alignSelf: 'center', marginBottom: 15 }]}>{dateDisplay.date}</Text>
      ) : (
        <View style={{ marginTop: 15 }} />
      )}

      <View
        style={[
          fromMe
            ? { right: 40, alignSelf: 'flex-end' }
            : { left: 40, alignSelf: 'flex-start', alignItems: 'flex-start' },
        ]}>
        <ShadowCard
          style={[
            { alignSelf: fromMe ? 'flex-end' : 'flex-start', maxWidth: sw / 2, padding: 10 },
            messageImage ? { width: sw - 80 } : null,
            { backgroundColor: fromMe && moderated ? Colors.gray : Colors.white },
          ]}>
          {messageType === MSG_SIMPLE_MESSAGE && messageImage && (
            <Pressable
              style={styles.mesImageContainer}
              onPress={() => {
                onDisplayImage(messageImage);
              }}
              onLongPress={() => {
                if (fromMe) {
                  onRemoveMessage(message);
                } else {
                  alert(
                    I18n.t('app.oups'),
                    "Vous ne pouvez pas supprimer un message que vous n'avez pas envoyé vous même"
                  );
                }
              }}>
              <FullWidthImage source={messageImage} />
            </Pressable>
          )}

          {messageType === MSG_SIMPLE_MESSAGE && messageText && (
            <Pressable disabled={!fromMe} style={styles.mesTextContainer} onLongPress={() => onRemoveMessage(message)}>
              <Autolink
                style={[styles.mesText, fromMe ? { marginRight: 20 } : { marginLeft: 20 }]}
                linkStyle={{ padding: 2, margin: 0, color: Colors.textLink, fontWeight: 'bold' }}
                text={messageText}
                email
                hashtag="instagram"
                mention="twitter"
                phone
                url
              />
            </Pressable>
          )}

          {messageType === MSG_CONVOCATION_REPLY && messageText && (
            <>
              <View style={styles.convocationContainer}>
                <Icon name="calendar" size={20} color={currentClub.mainColorBottom ?? Colors.blueCorporate} />
                <Text style={styles.convocationTitle}>{messageText}</Text>
              </View>
              <View style={styles.convocationContainer}>
                <Text style={styles.convocationReplyTitle}>
                  {myReply === REPLY_YES ? I18n.t('message.textResponseYes') : I18n.t('message.textResponseNo')}
                </Text>
              </View>
            </>
          )}

          {messageType === MSG_CONVOCATION && messageText && concerned && (
            <Pressable
              style={styles.mesTextContainer}
              onPress={() => {
                navigation.navigate('MessagesStack', {
                  screen: 'ConvocationDetails',
                  params: { convocation: message.convocation },
                });
              }}>
              <View style={styles.convocationHeader}>
                <Icon name="calendar" size={20} color={currentClub.mainColorBottom ?? Colors.blueCorporate} />
                <Text style={styles.convocationTitle}>
                  {dateTime}&nbsp;&ndash;&nbsp;{messageText}
                </Text>
              </View>

              {outdated ? (
                <>
                  <View style={styles.convocationContainer}>
                    <Text style={styles.convocationTitle}>{I18n.t('convocation.closed')}</Text>
                  </View>
                </>
              ) : (
                <>
                  <View style={styles.convocationContainer}>
                    <Text style={styles.convocationReplyTitle}>{I18n.t('convocation.confirmPresent')}</Text>
                  </View>
                  <View style={[styles.replyButtonsContainer, {}]}>
                    <ButtonBase
                      disabled={loadingYes}
                      loading={loadingYes}
                      label={I18n.t('message.responseYes')}
                      style={[
                        styles.replyButton,
                        {
                          backgroundColor:
                            myReply === REPLY_YES ? currentClub.mainColorBottom ?? Colors.blueCorporate : Colors.white,
                        },
                      ]}
                      textStyle={{
                        color:
                          myReply === REPLY_YES ? Colors.white : currentClub.mainColorBottom ?? Colors.blueCorporate,
                      }}
                      onPress={() => {
                        setLoadingYes(true);
                        message.presenceReplyType = REPLY_YES;
                        onConvocationReply(message, () => setLoadingYes(false));
                      }}
                    />
                    <ButtonBase
                      disabled={loadingNo}
                      loading={loadingNo}
                      label={I18n.t('message.responseNo')}
                      style={[
                        styles.replyButton,
                        {
                          backgroundColor:
                            myReply === REPLY_NO ? currentClub.mainColorBottom ?? Colors.blueCorporate : Colors.white,
                        },
                      ]}
                      textStyle={{
                        color:
                          myReply === REPLY_NO ? Colors.white : currentClub.mainColorBottom ?? Colors.blueCorporate,
                      }}
                      onPress={() => {
                        setLoadingNo(true);
                        message.presenceReplyType = REPLY_NO;
                        onConvocationReply(message, () => setLoadingNo(false));
                      }}
                    />
                  </View>
                </>
              )}
            </Pressable>
          )}

          {messageType === MSG_CONVOCATION && !concerned && (
            <View style={styles.convocationHeader}>
              <Icon name="calendar" size={20} color={currentClub.mainColorBottom ?? Colors.blueCorporate} />
              <Text style={styles.convocationTitle}>
                {dateTime}&nbsp;&ndash;&nbsp;{messageText}
              </Text>
            </View>
          )}

          <Text style={[styles.mesAuthor, { marginTop: 5, marginBottom: 0, textAlign: fromMe ? 'right' : 'left' }]}>
            {fromMe ? `${messageSender} - ${hourDisplay}` : `${hourDisplay} - ${messageSender}`}
          </Text>
        </ShadowCard>
      </View>

      <View style={[styles.avatarContainer, fromMe ? { right: 10 } : { left: 10 }]}>
        <Image
          style={styles.userAvatar}
          source={message.avatarPath ? { uri: getImageApiHostOrUrl(message.avatarPath) } : Images.userPlaceholder}
        />
      </View>
    </View>
  );
}
