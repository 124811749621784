import AlerteUI from '@components/AlerteUI/AlerteUI';
import HeaderBanner from '@components/HeaderBanner/HeaderBanner';
import Loading from '@components/Loading/Loading';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import * as actions from '@redux/actions';
import I18n from '@utils/i18n';
import React, { useState } from 'react';
import { Dimensions, Image, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

const { width: sw, height: sh } = Dimensions.get('window');

/**
 * Screen used to display Space club ( club searched before affiliation)
 * @returns
 */
export default function SpaceClub({ navigation }) {
  const [alertTitle, setAlertTitle] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertButtons, setAlertButtons] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const { session } = useSelector((state) => state.session);
  const { foundOtherClub } = useSelector((state) => state.session);
  const dispatch = useDispatch();

  const renderClubLogo = () => {
    const logo = foundOtherClub.logo ? { uri: getImageApiHostOrUrl(foundOtherClub.logo) } : require('@assets/logo.png');
    return <Image source={logo} style={styles.logo} />;
  };

  const renderButton = () => {
    return (
      <View
        style={[
          styles.buttonContainer,
          { height: 50, width: '100%', justifyContent: 'center', alignSelf: 'flex-end' },
        ]}>
        <Pressable
          style={[styles.nextStepButton, { backgroundColor: session.club.mainColorBottom ?? null }]}
          onPress={() => {
            setAlertTitle('Confirmation');
            setAlertMessage(
              `Vous êtes déjà affilié au club ${session.club.name}, voulez-vous vous affilier à ${foundOtherClub.name} également ?`
            );
            setAlertButtons([
              {
                text: 'Oui',
                onPress: () => {
                  navigation.navigate('HomeStack', { screen: 'ChooseProfile' });
                },
              },
              { text: 'Non' },
            ]);
            setShowAlert(true);
          }}>
          <Text
            style={[
              styles.whiteText,
              styles.appFontRegular,
              { textAlign: 'center', color: session.club.inactiveIconColor ?? null },
            ]}>
            {I18n.t('menu.JoinClub')}
          </Text>
        </Pressable>
      </View>
    );
  };

  if (!foundOtherClub) {
    return null;
  }

  return (
    <View style={[styles.mainContainer]}>
      {/* header */}
      <HeaderBanner
        leftIcon="md-arrow-back"
        onLeftButton={() => {
          dispatch(actions.setFoundClub(null));
          navigation.goBack();
        }}
        rightIcon="md-close"
        onRightButton={() => {
          dispatch(actions.setFoundClub(null));
          navigation.goBack();
        }}
        title={foundOtherClub.name ?? ''}
      />

      <ScrollView>
        {renderClubLogo()}

        <Text
          style={{
            textAlign: 'center',
            color: Colors.blueCorporate,
            fontSize: 24,
            fontFamily: 'appFontMedium',
            opacity: 0.6,
            marginBottom: 50,
          }}>
          {I18n.t('menu.clubSpace')}
        </Text>

        <Pressable
          style={({ pressed }) => [styles.menuItem, pressed ? { opacity: 0.7 } : {}]}
          onPress={() => {
            navigation.navigate('ClubScreen', {
              params: { club: foundOtherClub },
            });
          }}>
          <Text style={[styles.menuItemLabel]}>{I18n.t('menu.clubInfo')}</Text>
        </Pressable>

        <Pressable
          style={({ pressed }) => [styles.menuItem, pressed ? { opacity: 0.7 } : {}]}
          onPress={() => {
            navigation.navigate('TeamsScreen');
          }}>
          <Text style={[styles.menuItemLabel]}>{I18n.t('menu.club')}</Text>
        </Pressable>

        <Pressable
          style={({ pressed }) => [styles.menuItem, pressed ? { opacity: 0.7 } : {}]}
          onPress={() => {
            navigation.navigate('DocumentsScreen');
          }}>
          <Text style={[styles.menuItemLabel]}>{I18n.t('menu.document')}</Text>
        </Pressable>

        <Pressable
          style={({ pressed }) => [styles.menuItem, pressed ? { opacity: 0.7 } : {}]}
          onPress={() => {
            navigation.navigate('ShopScreen');
          }}>
          <Text style={[styles.menuItemLabel]}>{I18n.t('menu.shop')}</Text>
        </Pressable>

        <Pressable
          style={({ pressed }) => [styles.menuItem, pressed ? { opacity: 0.7 } : {}]}
          onPress={() => {
            navigation.navigate('PartnersScreen');
          }}>
          <Text style={[styles.menuItemLabel]}>{I18n.t('menu.partner')}</Text>
        </Pressable>

        {renderButton()}
      </ScrollView>

      <Loading loading={!foundOtherClub} />

      <AlerteUI
        showAlert={showAlert}
        onClose={() => {
          setShowAlert(false);
        }}
        title={alertTitle}
        message={alertMessage}
        buttons={alertButtons}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  menuItem: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  menuItemLabel: {
    textAlign: 'center',
    color: Colors.blueCorporate,
    fontSize: 14,
    fontFamily: 'appFontRegular',
    opacity: 0.6,
  },
  title: {
    textAlign: 'center',
    color: Colors.blueCorporate,
    fontSize: 14,
    fontFamily: 'appFontRegular',
    opacity: 0.6,
  },
  closeButtonIcon: {
    width: 16,
    height: 16,
  },
  closeButton: {
    marginRight: -15,
    marginTop: 5,
    width: 40,
    height: 40,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuScrollView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    height: 100,
    width: 100,
    borderRadius: 100,
    alignSelf: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  nextStepButton: {
    width: sw / 3,
    height: 40,
    backgroundColor: Colors.blueSky,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
  },
  whiteText: {
    color: Colors.whiteCorporate,
  },
});
