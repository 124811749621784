import Images from '@assets/images';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import I18n from '@utils/i18n';
import React, { useEffect, useState } from 'react';
import { Image, View, StyleSheet, Text, Pressable } from 'react-native';
import { Menu, MenuOption, MenuOptions, MenuTrigger, renderers } from 'react-native-popup-menu';
import { useSelector } from 'react-redux';

const NotificationIcon = ({ navigation }) => {
  const configAvatar = true;

  const notifications = useSelector((state) => state.notifications);
  const notificationsCount = notifications.numberOfNotifications;

  const [userAvatar, setUserAvatar] = useState(null);
  const [userName, setUserName] = useState(null);

  const [currentClub, setCurrentClub] = useState(null);
  const [activeColor, setActiveColor] = useState(null);
  const [inactiveColor, setInactiveColor] = useState(null);
  const [barColor, setBarColor] = useState(null);

  const [initialized, setInitialized] = useState(false);

  const { session, foundOtherClub, affiliatedClubInformation } = useSelector((state) => state.session);

  const { ContextMenu } = renderers;

  useEffect(() => {
    setCurrentClub(foundOtherClub ?? affiliatedClubInformation ?? session?.club ?? null);
    setUserAvatar(
      session.user.avatarPath ? { uri: getImageApiHostOrUrl(session.user.avatarPath) } : Images.userPlaceholder
    );
    setUserName(`${session.user.firstName} ${session.user.lastName}`);
  }, [session, foundOtherClub, affiliatedClubInformation]);

  useEffect(() => {
    if (!currentClub) {
      return;
    }

    setActiveColor(currentClub.activeIconColor ?? Colors.redCorporate);
    setInactiveColor(currentClub.inactiveIconColor ?? Colors.whiteCorporate);
    setBarColor(currentClub.mainColorBottom ?? Colors.blueCorporate);

    setInitialized(true);
  }, [currentClub]);

  const OptionsMenu = () => (
    <Menu renderer={ContextMenu}>
      <MenuTrigger style={styles.menuTrigger}>
        {configAvatar ? (
          <View style={styles.imageFrame}>
            <Image
              style={[styles.userAvatar, session.user.avatarPath ? {} : { tintColor: inactiveColor }]}
              source={userAvatar}
            />
            <View
              style={notificationsCount > 0 ? [styles.notificationBadge, { backgroundColor: activeColor }] : null}
            />
          </View>
        ) : (
          <>
            <Image
              source={require('@assets/icons/header/bell.png')}
              style={[styles.notificationIcon, { tintColor: inactiveColor }]}
            />
            <View
              style={notificationsCount > 0 ? [styles.notificationBadge, { backgroundColor: activeColor }] : null}
            />
          </>
        )}
      </MenuTrigger>
      <MenuOptions style={[styles.menuOptions, { backgroundColor: barColor }]}>
        {configAvatar ? (
          <View style={{ alignItems: 'center' }}>
            <View style={[styles.imageFrame, { width: 92, height: 92 }, { marginLeft: 0 }]}>
              <Image
                style={[
                  styles.userAvatar,
                  session.user.avatarPath ? {} : { tintColor: inactiveColor },
                  { width: 88, height: 88 },
                ]}
                source={userAvatar}
              />
              {/*<View*/}
              {/*  style={notificationsCount > 0 ? [styles.notificationBadge, { backgroundColor: activeColor }] : null}*/}
              {/*/>*/}
            </View>
            <Text numberOfLines={2} ellipsizeMode={"tail"} style={{color: inactiveColor}}>{userName}</Text>
          </View>
        ) : (
          <>
            <Image
              source={require('@assets/icons/footer/user.png')}
              style={[styles.notificationIcon, { tintColor: inactiveColor }]}
            />
          </>
        )}
        <View
          style={{
            height: 5,
            width: '100%',
            borderBottomWidth: 2,
            borderBottomColor: Colors.whiteCorporate,
            marginBottom: 15,
          }}
        />
        <MenuOption value={1} style={styles.menuOption}>
          <Pressable
            style={({ pressed }) => [styles.menuOption, pressed ? { opacity: 0.7 } : {}]}
            onPress={() => {
              navigation.navigate('HomeStack', { screen: 'NotificationsList' });
            }}>
            <Image
              source={require('@assets/icons/header/bell.png')}
              style={[styles.notificationIcon, { tintColor: inactiveColor }]}
            />
            <Text style={{ color: inactiveColor }}>Notifications</Text>
            <View
              style={notificationsCount > 0 ? [styles.notificationBadge, { backgroundColor: activeColor }] : null}
            />
          </Pressable>
        </MenuOption>
        <MenuOption value={2}>
          <Pressable
            style={({ pressed }) => [styles.menuOption, pressed ? { opacity: 0.7 } : {}]}
            onPress={() => navigation.navigate('ProfileStack', { screen: 'Profile', params: { tab: 'userProfile' } })}>
            <Image
              source={require('@assets/icons/footer/user.png')}
              style={[styles.notificationIcon, { tintColor: inactiveColor }]}
            />
            <Text style={{ color: inactiveColor }}>Profil utilisateur</Text>
          </Pressable>
        </MenuOption>
        <MenuOption value={3}>
          <Pressable
            style={({ pressed }) => [styles.menuOption, pressed ? { opacity: 0.7 } : {}]}
            onPress={() =>
              navigation.navigate('ProfileStack', { screen: 'Profile', params: { tab: 'userParameters' } })
            }>
            <Image
              source={require('@assets/icons/header/parameters.png')}
              style={[styles.notificationIcon, { tintColor: inactiveColor }]}
            />
            <Text style={{ color: inactiveColor }}>Paramètres</Text>
          </Pressable>
        </MenuOption>
        <MenuOption value={4}>
          <Pressable
            style={({ pressed }) => [styles.menuOption, pressed ? { opacity: 0.7 } : {}]}
            onPress={() => navigation.navigate('ProfileStack', { screen: 'Profile', params: { logout: true } })}>
            <Image
              source={require('@assets/icons/header/logout.png')}
              style={[styles.notificationIcon, { tintColor: inactiveColor }]}
            />
            <Text style={{ color: inactiveColor }}>{I18n.t('myAccount.signOut')}</Text>
          </Pressable>
        </MenuOption>
      </MenuOptions>
    </Menu>
  );

  if (!currentClub || !initialized) return;

  return (
    <View>
      {/*<Icon name="md-notifications" size={25} color={Colors.whiteCorporate} />*/}
      <OptionsMenu />
    </View>
  );
};

const styles = StyleSheet.create({
  notificationIcon: {
    width: 25,
    height: 25,
    resizeMode: 'contain',
    marginRight: 5,
  },
  notificationBadge: {
    position: 'absolute',
    right: -6,
    top: -1,
    borderRadius: 10,
    width: 12,
    height: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },

  imageFrame: {
    width: 44,
    height: 44,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 60,
    borderColor: Colors.grayWhite,
    borderWidth: 2,
    marginLeft: 10,
    marginBottom: 6,
    marginTop: -2,
    padding: 5,
  },
  userAvatar: {
    width: 40,
    height: 40,
    borderRadius: 80,
    padding: 0,
    margin: 0,
  },

  container: {
    padding: 10,
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  backdrop: {},
  menuOptions: {
    padding: 20,
  },
  menuOption: {
    height: 36,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  menuTrigger: {
    padding: 5,
  },
  triggerText: {
    fontSize: 20,
  },
  contentText: {
    fontSize: 18,
  },
});

export default NotificationIcon;
