import { getApiHost } from '@configs/host';

import { Request } from './Request';
import { URLs } from './apisConstants';

export const PartnerAPI = {
  // Elipce/Fred: page and limit are useless, because ignored by the backend!
  getAllPartnersByCLubId: (wsToken, page, limit, id) => {
    const url =
      getApiHost() +
      URLs.getAllPartnersByClubId +
      '?wsToken=' +
      wsToken +
      '&clubId=' +
      id +
      '&page=' +
      page +
      '&limit=' +
      limit;
    return Request.get(url);
  },
};
