import ButtonBase from '@components/ButtonBase/ButtonBase';
import ButtonCancel from '@components/ButtonCancel/ButtonCancel';
import Colors from '@configs/colors';
import { gMaxWidth } from '@configs/styles';
import { checkIsAdmin } from '@utils/checkUserRoles';
import I18n from '@utils/i18n';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Dimensions, FlatList, Modal, Platform, StyleSheet, Text, View } from 'react-native';
import { useSelector } from 'react-redux';

const { width: sw, height: sh } = Dimensions.get('window');

/**
 * Screen used to choose modifying or deleting an actuality
 * @returns // screen
 */
const SelectModerate = ({ visible = false, actuality, comment, onClose = () => {}, onSelected = (item) => {} }) => {
  const debug = false;

  const [currentClub, setCurrentClub] = useState(null);

  const { session, affiliatedClubInformation, pendingFilter } = useSelector((state) => state.session);
  const [initialized, setInitialized] = useState(false);

  const [choicesHeight, setChoicesHeight] = useState(80);
  const [maxDisplayedChoices, setMaxDisplayedChoices] = useState(8);

  const renderChoices = () => {
    const choices = [];

    if (actuality && !comment) {
      // Only for an actuality
      if (session.user.id === actuality.userId) {
        if (pendingFilter) {
          choices.push({
            id: 'validate',
            name: 'Valider ce contenu',
          });
        }

        choices.push({
          id: 'modify',
          name: 'Modifier ce contenu',
        });

        choices.push({
          id: 'delete',
          name: 'Supprimer ce contenu',
        });
      } else {
        if (checkIsAdmin(currentClub)) {
          if (pendingFilter) {
            choices.push({
              id: 'validate',
              name: 'Valider ce contenu',
            });
          }

          choices.push({
            id: 'delete',
            name: 'Supprimer ce contenu',
          });
        }
        choices.push({
          id: 'signalUser',
          name: 'Signaler cet utilisateur',
        });
        choices.push({
          id: 'blockUser',
          name: 'Bloquer cet utilisateur',
        });
        choices.push({
          id: 'signalContent',
          name: 'Signaler ce contenu',
        });
        choices.push({
          id: 'blockContent',
          name: 'Bloquer ce contenu',
        });
      }
    }

    if (actuality && comment) {
      if (session.user.id === actuality.userId) {
      } else {
        // For a comment of an actuality
        choices.push({
          id: 'blockContent',
          name: 'Bloquer ce contenu',
        });

        choices.push({
          id: 'signalUser',
          name: 'Signaler cet utilisateur',
        });

        if (comment && comment.content !== 'Message signalé') {
          choices.push({
            id: 'signalComment',
            name: 'Signaler ce commentaire',
          });
        } else {
          choices.push({
            id: 'signalContent',
            name: 'Signaler ce contenu',
          });
        }

        choices.push({
          id: 'blockUser',
          name: 'Bloquer cet utilisateur',
        });

        if (comment && comment.content !== 'Message signalé') {
          choices.push({
            id: 'blockComment',
            name: 'Bloquer ce commentaire',
          });
        }
      }
    }

    return choices;
  };

  useEffect(() => {
    setCurrentClub(affiliatedClubInformation ?? session?.club ?? null);

    const choices = renderChoices();

    // Compute clubs list display space
    const maximumDisplayableChoices = Math.floor((sh - 300) / 65);
    setMaxDisplayedChoices(Math.floor((sh - 320) / 60));
    setChoicesHeight(Math.min(choices.length * 60, Math.min(maximumDisplayableChoices * 60, sh - 160)));
    debug && console.debug('SelectModerate, maximum choices:', maximumDisplayableChoices);

    setInitialized(true);
  }, [affiliatedClubInformation]);

  if (!initialized || !visible || !currentClub) {
    return;
  }

  return (
    <>
      <Modal visible={visible} transparent>
        <View style={[styles.mainContainer]}>
          <View style={[styles.centered, styles.shadowEffect]}>
            <Text style={styles.pageTitle}>Choisissez une action</Text>

            <View style={{ height: 20 + choicesHeight }}>
              <FlatList
                style={{ height: choicesHeight }}
                data={renderChoices()}
                keyExtractor={(item, index) => item.id}
                showsVerticalScrollIndicator={false}
                renderItem={({ item, index, separators }) => {
                  return (
                    <View style={[styles.itemContainer]}>
                      <ButtonBase style={{ width: '100%' }} label={item.name} onPress={() => onSelected(item)} />
                    </View>
                  );
                }}
              />
            </View>

            <View style={[styles.buttonsContainer]}>
              <ButtonCancel label={I18n.t('app.close')} onPress={() => onClose()} />
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    width: Platform.OS === 'web' ? gMaxWidth : sw,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.black + '77',
  },
  centered: {
    backgroundColor: Colors.grayWhite,
    paddingVertical: 10,
    paddingHorizontal: 20,
    width: Platform.OS === 'web' ? gMaxWidth - 40 : sw - 40,
    borderRadius: 10,

    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  shadowEffect: {
    marginBottom: 15,

    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },
  pageTitle: {
    // width: '80%',
    fontWeight: 'bold',
    fontSize: 18,
    color: Colors.blueCorporate,

    textAlign: 'center',

    marginTop: 40,
    marginBottom: 20,
  },
  itemContainer: {
    width: Platform.OS === 'web' ? gMaxWidth - 80 : sw - 80,
    height: 60,

    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    paddingVertical: 10,
    paddingHorizontal: 25,
  },
  itemText: {
    paddingHorizontal: 10,
    width: sw - 140,
    borderRadius: 100,
  },
  buttonsContainer: {
    marginTop: 20,
    width: 120,
  },
});

SelectModerate.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSelected: PropTypes.func,
};

export default SelectModerate;
