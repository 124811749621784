import ShadowCard from '@components/ShadowCard/ShadowCard';
import Colors from '@configs/colors';
import { gMaxWidth } from '@configs/styles';
import I18n from '@utils/i18n';
import { useDimensions } from '@utils/useDimensions';
import React, { useEffect, useState } from 'react';
import { Platform, Pressable, StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { useSelector } from 'react-redux';

/**
 *
 * @param club
 * @param category
 * @param onSelectMember
 * @param insideTeam - if the card user is displayed for a team
 * @param selectable - to make the user selectable or not
 * @returns {JSX.Element}
 * @constructor
 */
export default function CardSimpleCategory({
  club,
  team,
  category,
  content,
  insideTeam = false,
  selectable = true,
  onSelectCategory = () => {},
  onDeselectCategory = () => {},
}) {
  const { width } = useDimensions().window;

  const [cardWidth, setCardWidth] = useState(1);

  const [title, setTitle] = useState(null);

  const [isTeam, setIsTeam] = useState(false);

  const [itemSelected, setItemSelected] = useState(false);
  const { selectedTeams, selectedCategories } = useSelector((state) => state.chat);

  useEffect(() => {
    if (content === 'teams') {
      const foundIndex = selectedTeams.findIndex((item) => item.id === category.id);
      setItemSelected(foundIndex >= 0);
    }
  }, [selectedTeams]);

  useEffect(() => {
    if (content === 'categories') {
      const foundIndex = selectedCategories.findIndex((item) => item.title === category.title);
      setItemSelected(foundIndex >= 0);
    }
  }, [selectedCategories]);

  useEffect(() => {
    if (team) {
      setIsTeam(true);
      // setTeam(category.team ?? category);
      setTitle(team.name);
    } else {
      setTitle(I18n.t('message.categories.' + category.title));
      if (category.team) {
        setIsTeam(true);
      }
    }
  }, []);

  useEffect(() => {
    let theWidth = Platform.OS === 'web' ? gMaxWidth : width;
    theWidth = theWidth >= 768 ? gMaxWidth / 2 - 20 : width - 30;
    theWidth = insideTeam ? theWidth - 30 : theWidth;
    // theWidth = extraIcon ? theWidth - 25 : theWidth;
    setCardWidth(theWidth);
  }, [width]);

  if (!title) {
    return null;
  }

  return (
    <ShadowCard style={[styles.mainContainer, { width: cardWidth }]}>
      <Pressable
        disabled={!selectable}
        style={[styles.simpleCategoryCard]}
        onPress={() => {
          if (category.selected) {
            onDeselectCategory(category.title, team);
          } else {
            onSelectCategory(category.title, team);
          }
        }}
        onLongPress={() => {
          // this.props.onLongPress();
        }}>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          <View style={[styles.nameContainer, { backgroundColor: club.mainColorBottom }]}>
            <View style={[styles.roundBorderWhite]}>
              <View style={[styles.cutter, { backgroundColor: club.mainColorBottom }]} />
              <Text style={[styles.letter, { color: club.activeIconColor }]} numberOfLines={1} ellipsizeMode="tail">
                {isTeam ? (
                  <Icon size={25} name={category.selected ? 'people-outline' : 'people-outline'} />
                ) : (
                  title.charAt(0)
                )}
              </Text>
            </View>

            {isTeam ? (
              <>
                {category.team ? (
                  <View
                    style={[
                      styles.categoryName,
                      { flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' },
                    ]}>
                    <Text style={{ color: club.inactiveIconColor }} numberOfLines={1} ellipsizeMode="tail">
                      {I18n.t('message.categories.' + category.title)}
                    </Text>
                    <Text style={{ color: club.inactiveIconColor }} numberOfLines={1} ellipsizeMode="tail">
                      {' ('}
                    </Text>
                    <Text
                      style={[{ color: club.inactiveIconColor }, { fontStyle: 'italic' }]}
                      numberOfLines={1}
                      ellipsizeMode="tail">
                      {category.team.name}
                    </Text>
                    <Text style={{ color: club.inactiveIconColor }} numberOfLines={1} ellipsizeMode="tail">
                      )
                    </Text>
                  </View>
                ) : (
                  <Text
                    style={[styles.categoryName, { color: club.inactiveIconColor, fontFamily: 'appFontMedium' }]}
                    numberOfLines={1}
                    ellipsizeMode="tail">
                    {`${team.name}`}
                  </Text>
                )}
              </>
            ) : (
              <Text
                style={[styles.categoryName, { color: club.inactiveIconColor }]}
                numberOfLines={1}
                ellipsizeMode="tail">
                {I18n.t('message.categories.' + category.title)}
              </Text>
            )}
          </View>
        </View>

        <View style={[styles.categorySelection, { backgroundColor: club?.mainColorBottom ?? Colors.black }]}>
          {selectable && (
            <Pressable
              style={({ pressed }) => [pressed ? { opacity: 0.7 } : {}]}
              onPress={() => {
                if (itemSelected) {
                  isTeam ? onDeselectCategory(team) : onDeselectCategory(category.title);
                } else {
                  isTeam ? onSelectCategory(team) : onSelectCategory(category.title);
                }
              }}>
              <Icon
                size={25}
                color={club?.inactiveIconColor ?? Colors.whiteCorporate}
                name={itemSelected ? 'checkbox-outline' : 'square-outline'}
              />
            </Pressable>
          )}
        </View>
      </Pressable>
    </ShadowCard>
  );
}

const styles = StyleSheet.create({
  mainContainer: {
    marginHorizontal: 10,
    marginTop: 0,
    // backgroundColor: Colors.blueSky,
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
  },
  simpleCategoryCard: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    overflow: 'visible',
    borderColor: 'transparent',
    // backgroundColor: Colors.blueSky,
  },
  userContact: {
    fontFamily: 'appFontMedium',
    fontSize: 11,
  },
  categorySelection: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: Colors.whiteCorporate,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    marginLeft: 'auto',
    height: 40,
    paddingRight: 5,
  },

  nameContainer: {
    width: '100%',
    height: 40,
    marginRight: -18,
    borderTopLeftRadius: 100,
    borderBottomLeftRadius: 100,
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'visible',
  },
  roundBorderWhite: {
    width: 36,
    height: 36,
    borderWidth: 2,
    borderColor: Colors.whiteCorporate,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    marginLeft: 2,
  },
  letter: {
    fontFamily: 'appFontMedium',
    fontSize: 20,
  },
  categoryName: {
    fontFamily: 'appFontRegular',
    fontSize: 12,
    marginLeft: 5,
    width: '80%',
  },
  cutter: {
    width: 10,
    height: 10,
    position: 'absolute',
    top: 0,
    right: 0,
    transform: [{ rotateZ: '45deg' }],
  },
});
