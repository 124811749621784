import { MessageAPI } from '@apis/message';
import defaultBanner from '@assets/banner.png';
import defaultLogo from '@assets/logo.png';
import 'moment/locale/fr';
import CardConvocation from '@components/CardConvocation/CardConvocation';
import FullWidthImage from '@components/FullWidthImage/FullWidthImage';
import GradientBackground from '@components/GradientBackground/GradientBackground';
import HeaderBanner from '@components/HeaderBanner/HeaderBanner';
import Loading from '@components/Loading/Loading';
import MediaZoomViewer from '@components/MediaZoomViewer/MediaZoomViewer';
import ShadowCard from '@components/ShadowCard/ShadowCard';
import TakePhoto from '@components/TakePhoto/TakePhoto';
import Colors from '@configs/colors';
import { getImageApiHostOrUrl } from '@configs/host';
import ChatMessage from '@containers/MessagesStack/ChatMessage';
import { useIsFocused } from '@react-navigation/native';
import {
  getConversationInformation,
  getMessages,
  removeMessage,
  sendMessage,
  sendConvocationReply,
  selectCategory,
  selectTeam,
  selectMember,
  updateMembersRoles,
  handleBadgeNotification,
  selectPartner,
} from '@redux/actions';
import alert from '@utils/alert';
import { checkIsModeratorOrAdmin } from '@utils/checkUserRoles';
import I18n from '@utils/i18n';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  Dimensions,
  FlatList,
  KeyboardAvoidingView,
  Platform,
  Pressable,
  Text,
  TextInput,
  View,
} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles';

const { width: sw, height: sh } = Dimensions.get('window');

const Chatting = ({ navigation, route }) => {
  const debug = false;

  const isFocused = useIsFocused();

  let storedDate = '';

  const [refreshing, setRefreshing] = useState(false);
  const [writing, setWriting] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [sending, setSending] = useState(false);

  const [message, setMessage] = useState('');
  const [messageImage, setMessageImage] = useState(null);

  const [showMessageImage, setShowMessageImage] = useState(false);
  const [messageImages, setMessageImages] = useState([]);

  const [showTakePhoto, setShowTakePhoto] = useState(false);

  const [convocationsToOpen, setConvocationsToOpen] = useState([]);
  const [isConvocationsOpened, setIsConvocationsOpened] = useState(false);

  const [currentClub, setCurrentClub] = useState(null);

  const { tokens } = useSelector((state) => state.app);
  const { session, affiliatedClubInformation } = useSelector((state) => state.session);
  const { loading, myConversation, hasMoreMessages, pageNumber, pageLimit } = useSelector((state) => state.chat);

  const [headerTitle, setHeaderTitle] = useState('GUEST');
  const [headerLogo, setHeaderLogo] = useState(defaultLogo);
  const [headerBanner, setHeaderBanner] = useState(defaultBanner);

  const [hasAccess, setHasAccess] = useState(false);

  const [iCreated, setICreated] = useState(false);
  const [iModerate, setIModerate] = useState(false);

  const [conversationInitialized, setConversationInitialized] = useState(false);

  const dispatch = useDispatch();

  const refScrollView = useRef();

  useEffect(() => {
    debug && console.debug('Chatting focused, route parameters:', isFocused, route.params);
    if (!isFocused) return;

    dispatch(handleBadgeNotification('message', false));

    if (route.params && route.params.chat) {
      getConversationDetails(route.params.chat.id);

      // if (route.params.message && route.params?.action === 'newMessage') {
      //   dispatch({ type: ADD_NEW_MESSAGE, message: route.params.message });
      // } else if (route.params.message && route.params?.action === 'deleteMessage') {
      //   dispatch({ type: DELETE_MESSAGE, messageId: route.params.message.id });
      // }
    } else {
      getConversationDetails(myConversation.id);
    }
  }, [isFocused]);

  useEffect(() => {
    debug && console.debug('Chatting update club currentClub');

    const currentClub = affiliatedClubInformation ?? session.club ?? null;
    setCurrentClub(currentClub);

    if (!currentClub) {
      setHeaderLogo(defaultLogo);
      setHeaderBanner(defaultBanner);
      setHeaderTitle('GUEST');
    } else {
      setHasAccess(checkIsModeratorOrAdmin(currentClub));

      setHeaderLogo(currentClub.logo ? { uri: getImageApiHostOrUrl(currentClub.logo) } : defaultLogo);
      setHeaderBanner(currentClub.bannerImage ? { uri: getImageApiHostOrUrl(currentClub.bannerImage) } : defaultBanner);
      setHeaderTitle(currentClub.name);
    }
  }, [session, affiliatedClubInformation]);

  useEffect(() => {
    if (!myConversation && !currentClub) {
      return;
    }
    debug && console.debug('Chatting update current chat:', myConversation && myConversation.id);
    debug && console.debug('Chatting update current chat:', myConversation);
    // Why creator?
    if (!myConversation.id || !myConversation.creator) {
      return;
    }

    setHeaderTitle(myConversation.name);

    setICreated(myConversation?.creator?.id === session.user.id);

    if (myConversation.convocations) {
      debug && console.debug('Chatting current chat convocations:', myConversation.convocations);
      setConvocationsToOpen(myConversation.convocations.filter((convocation) => !convocation.outdated));
    }

    let iAmParticipating = false;
    const membersRoles = myConversation.membersRoles;
    if (membersRoles) {
      // New conversation membership
      debug && console.debug('Chatting, existing members roles', membersRoles);

      for (const team of membersRoles.teams) {
        dispatch(selectTeam(team.id));
      }
      for (const category of membersRoles.categories) {
        dispatch(selectCategory(category.title));
      }
      for (const member of membersRoles.members) {
        dispatch(selectMember(member.category, member, null, true));
      }
      if (membersRoles.partners) {
        for (const member of membersRoles.partners) {
          dispatch(selectPartner(member, true));
        }
      }

      for (const member of myConversation.participants) {
        if (member.id === session.user.id) {
          iAmParticipating = true;
        }
      }
    } else {
      debug && console.debug('Chatting, updating members roles', membersRoles);
      for (const member of myConversation.participants) {
        if (member.id === session.user.id) {
          iAmParticipating = true;
        }
        dispatch(selectMember(member.category, member, null, true));
      }
      dispatch(
        updateMembersRoles({
          allTeams: false,
          teams: [],
          categories: [],
          members: [],
          participants: myConversation.participants,
        })
      );
    }

    debug &&
      console.debug(
        'Chatting, 1-to-1, iCreated, iModerate and hasAccess: ',
        myConversation.oneToOne,
        myConversation.creator.id === session.user.id,
        hasAccess && !iAmParticipating,
        hasAccess
      );

    // I moderate the conversation if I am admin/moderator and I do not participate
    setIModerate(hasAccess && !iAmParticipating);

    setConversationInitialized(true);
  }, [myConversation]);

  /**
   *  function to get information about conversation like , group name or member of the group
   */
  const getConversationDetails = (conversationId) => {
    debug && console.debug('Chatting getConversationDetails', conversationId);

    dispatch(
      getConversationInformation(tokens.wsToken, conversationId, (success) => {
        if (success) {
          debug && console.debug('Chatting getConversationDetails, got details');
        }
      })
    );
  };

  /**
   *  function to get messages of the current conversation
   */
  const getConversationMessages = (newPage = null, newLimit = null) => {
    debug && console.debug('Chatting getConversationMessages', newPage, newLimit);
    if (!myConversation) {
      return;
    }
    if (newPage && newPage > pageNumber && !hasMoreMessages) {
      debug && console.debug('Chatting getConversationMessages - no more messages');
      return;
    }

    dispatch(
      getMessages(
        tokens.wsToken,
        myConversation.id,
        newPage ?? pageNumber,
        newLimit ?? pageLimit,
        (result, messages) => {
          debug && console.debug('Chatting getConversationMessages, got', result, messages.length);
        }
      )
    );
  };

  /**
   * Function to get date or hours depending on what we want
   * This function will update props "date"
   * @param {string} date
   * @param {string} dataType
   */
  const manageDateAndHours = (date, dataType) => {
    if (date && dataType === 'date') {
      const theDate = moment(date).format('Do MMMM YYYY');

      if (theDate !== storedDate) {
        storedDate = theDate;
        return { date: theDate, display: true };
      }
      return { date: theDate, display: false };
    }

    if (date && dataType === 'hours') {
      // "theDate": "2023-04-14 07:04:34"
      return moment(date).format('HH:mm');
    }
  };

  /**
   *  Function to navigate to the chat details view
   */
  const viewChatDetails = () => {
    debug && console.debug('Chatting viewChatDetails');
    if (!myConversation) {
      return;
    }

    navigation.navigate('MessagesStack', {
      screen: 'ChatDetails',
      params: {
        chat: myConversation,
        edition: iCreated && !myConversation.teamConversation && !myConversation.oneToOne,
      },
    });
  };

  /**
   *  Function to navigate to the chat members view
   */
  const viewChatMembers = () => {
    debug && console.debug('Chatting viewChatMembers');
    if (!myConversation) {
      return;
    }

    navigation.navigate('MessagesStack', {
      screen: 'MembersSelection',
      params: {
        chat: myConversation,
        edition: iCreated && !myConversation.teamConversation && !myConversation.oneToOne,
      },
    });
  };

  /**
   * Function to upload an image into the conversation
   * @param {string} image - filename as returned by the image API
   * @param {string} message - current text message
   */
  const uploadMessageMedia = async (image, message = '') => {
    debug && console.debug('Chatting uploadMessageMedia');

    setUploading(true);
    const response = await MessageAPI.uploadMedia(image, tokens.wsToken);
    setUploading(false);
    setMessageImage(null);

    if (response && response.success) {
      // after saved image in server we send it in current conversation
      // saveMessage(message, response.result.file_name);
      return response.result.file_name;
    } else {
      console.warn('Chatting uploadMessageMedia error', response);
      alert(I18n.t('app.oups'), "Une erreur est survenue lors de l'envoi de l'image");
      return null;
    }
  };

  /**
   * Function to send a text/image message
   */
  const saveMessage = async (content = '') => {
    debug && console.debug('Chatting saveMessage', tokens.wsToken, myConversation.id, content, filename);

    let filename = null;
    if (messageImage) {
      filename = await uploadMessageMedia(messageImage, message);
    }

    const messageContent = {};
    if (content) {
      messageContent.text = content;
    }
    if (filename) {
      messageContent.image = filename;
    }

    setSending(true);
    await dispatch(
      sendMessage(tokens.wsToken, myConversation.id, messageContent, (success, response) => {
        debug && console.debug('Chatting message sent', success, response);
        setSending(false);
        if (success) {
          getConversationDetails(myConversation.id);
        }
      })
    );
    setMessage('');
  };

  /**
   * Function to send a convocation reply message
   */
  const saveConvocationReply = async (convocation, callback) => {
    debug && console.debug('Chatting saveConvocationReply', tokens.wsToken, myConversation.id, convocation);

    await dispatch(
      sendConvocationReply(tokens.wsToken, myConversation.id, convocation, (success) => {
        debug && console.debug('Chatting saveConvocationReply, updated', success);
        if (success) {
          getConversationDetails(myConversation.id);
          debug && console.debug('Chatting saveConvocationReply, got updated conversation');
        }
        callback && callback();
      })
    );
  };

  /**
   * Function to remove a text or image message
   * @param {object} message
   */
  const deleteMessage = (message) => {
    debug && console.debug('Chatting deleteMessage', message);

    alert('Attention', 'Voulez-vous supprimer ce message ?', [
      {
        text: 'Annuler',
      },
      {
        text: 'Ok',
        onPress: () => {
          dispatch(
            removeMessage(tokens.wsToken, message.id, (result) => {
              if (!result) {
                alert(I18n.t('app.oups'), 'Une erreur est survenue lors de la suppression du message');
              }
            })
          );
        },
      },
    ]);
  };

  if (!currentClub || !myConversation || !myConversation.messages) {
    return;
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {/* header */}
        <HeaderBanner
          leftIcon="md-arrow-back"
          onLeftButton={() => navigation.navigate('MessagesStack', { screen: 'Conversations' })}
          rightIcon={iCreated && !myConversation.teamConversation && !myConversation.oneToOne ? 'people-outline' : null}
          onRightButton={() =>
            iCreated && !myConversation.teamConversation && !myConversation.oneToOne ? viewChatMembers() : () => {}
          }
          title={headerTitle}
          onTitlePress={() => viewChatDetails()}
        />

        {/* Only the future convocations in the list */}
        {convocationsToOpen.length > 0 && (
          <>
            <Pressable
              style={({ pressed }) => [
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: currentClub.mainColorBottom ?? Colors.blueCorporate,
                  paddingHorizontal: 10,
                  height: 60,
                },
                pressed ? { opacity: 0.7 } : {},
              ]}
              onPress={() => setIsConvocationsOpened(!isConvocationsOpened)}>
              <Text
                style={{ color: currentClub.inactiveIconColor ?? Colors.whiteCorporate, fontFamily: 'appFontMedium' }}>
                {`Voir les convocations (${convocationsToOpen.length})`}
              </Text>
            </Pressable>
            {isConvocationsOpened && (
              <View style={{ maxHeight: sh / 2, borderBottomColor: Colors.whiteCorporate, borderBottomWidth: 2 }}>
                <FlatList
                  data={convocationsToOpen}
                  keyExtractor={(item, index) => item.id + index}
                  renderItem={({ item, index }) => {
                    return (
                      <CardConvocation
                        navigation={navigation}
                        convocation={item}
                        onConvocationReply={async (item, callback) => await saveConvocationReply(item, callback)}
                      />
                    );
                  }}
                />
              </View>
            )}
          </>
        )}

        {/* messages */}
        <GradientBackground gradient>
          <FlatList
            ref={refScrollView}
            inverted={false}
            nestedScrollEnabled
            estimatedItemSize={120}
            initialNumToRender={myConversation.messages.length}
            maxToRenderPerBatch={5}
            windowSize={10}
            removeClippedSubviews
            data={myConversation.messages}
            keyExtractor={(item) => item.id}
            scrollEnabled
            scrollEventThrottle={0}
            showsVerticalScrollIndicator
            contentContainerStyle={{ paddingBottom: 10 }}
            onLayout={({ nativeEvent: { layout } }) => {
              setTimeout(() => {
                if (myConversation.messages.length > 0) {
                  if (!refScrollView || !refScrollView.current) return;
                  refScrollView.current.scrollToEnd({ animated: true });
                }
              }, 1000);
            }}
            onContentSizeChange={(w, h) => {
              debug && console.debug('Chatting onContentSizeChange:', w, h);
              if (myConversation.messages.length > 0) {
                if (writing) {
                  if (!refScrollView || !refScrollView.current) return;
                  refScrollView.current.scrollToEnd({ animated: true });
                }
              }
            }}
            refreshing={refreshing}
            onRefresh={() => {
              debug && console.debug('Chatting onRefresh');
              getConversationMessages(pageNumber + 1, pageLimit);
            }}
            ListEmptyComponent={
              <View
                style={
                  loading === false
                    ? { flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 20 }
                    : { display: 'none' }
                }>
                <Text style={{ alignSelf: 'center', color: Colors.blueCorporate }}>{I18n.t('message.noMessages')}</Text>
              </View>
            }
            renderItem={({ item }) => {
              return (
                <ChatMessage
                  navigation={navigation}
                  message={item}
                  moderated={iModerate}
                  hourDisplay={manageDateAndHours(item.createdAt, 'hours')}
                  dateDisplay={manageDateAndHours(item.createdAt, 'date')}
                  onRemoveMessage={(item) => {
                    deleteMessage(item);
                  }}
                  onDisplayImage={(image) => {
                    if (image) {
                      setMessageImages([image]);
                      setShowMessageImage(true);
                    }
                  }}
                  onConvocationReply={async (item, callback) => await saveConvocationReply(item, callback)}
                />
              );
            }}
          />

          {/* input send messages */}
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            keyboardVerticalOffset={convocationsToOpen.length > 0 ? 80 : 15}
            contentContainerStyle={Platform.OS === 'ios' ? { marginBottom: 58 } : null}>
            <View
              style={[styles.inputContainer, { backgroundColor: currentClub.mainColorBottom ?? Colors.blueCorporate }]}>
              {/* if user is in process of sending image in conversation, show the image */}
              {messageImage && (
                <ShadowCard
                  style={[{ alignSelf: 'center', width: sw / 2, padding: 10 }, { backgroundColor: Colors.gray }]}>
                  <Pressable
                    style={({ pressed }) => [styles.mesImageContainer, pressed ? { opacity: 0.7 } : {}]}
                    onPress={async () => {
                      await saveMessage(message);

                      if (!refScrollView || !refScrollView.current) return;
                      if (myConversation.messages.length > 0) {
                        refScrollView.current.scrollToEnd({ animated: true });
                      }
                    }}
                    onLongPress={() => {
                      setMessageImage(null);
                    }}>
                    <FullWidthImage source={messageImage} />
                  </Pressable>
                  {uploading && (
                    <ActivityIndicator
                      animating={uploading}
                      size={25}
                      color={currentClub?.inactiveIconColor ?? Colors.whiteCorporate}
                    />
                  )}
                </ShadowCard>
              )}

              <View style={[{ flexDirection: 'row' }, uploading ? {} : { alignItems: 'flex-start' }]}>
                {!messageImage && (
                  <View style={{ flexDirection: 'row' }}>
                    <Pressable
                      style={[
                        styles.buttonCamera,
                        { backgroundColor: currentClub.inactiveIconColor ?? Colors.whiteCorporate },
                      ]}
                      onPress={() => {
                        setShowTakePhoto(true);
                      }}>
                      <Icon
                        size={25}
                        name="camera-outline"
                        color={currentClub?.mainColorBottom ?? Colors.blueCorporate}
                      />
                    </Pressable>

                    {uploading && (
                      <ActivityIndicator
                        animating={uploading}
                        size={25}
                        color={currentClub?.inactiveIconColor ?? Colors.whiteCorporate}
                      />
                    )}

                    {myConversation.teamConversation && !myConversation.oneToOne && hasAccess && (
                      <Pressable
                        style={[
                          styles.buttonConvocation,
                          { marginLeft: 10, backgroundColor: currentClub?.inactiveIconColor ?? Colors.whiteCorporate },
                        ]}
                        onPress={() => {
                          navigation.navigate('MessagesStack', {
                            screen: 'CreateConvocation',
                            params: {
                              chatId: myConversation.id,
                            },
                          });
                        }}>
                        <Icon
                          size={25}
                          name="calendar-outline"
                          color={currentClub?.mainColorBottom ?? Colors.blueCorporate}
                        />
                      </Pressable>
                    )}
                  </View>
                )}

                <View style={styles.inputZone}>
                  <TextInput
                    style={styles.inputText}
                    multiline
                    placeholderTextColor={Colors.blueBgGradient1 + '55'}
                    placeholder={I18n.t('message.writeMessage')}
                    value={message}
                    onChangeText={(text) => {
                      setWriting(true);
                      setMessage(text);

                      if (!refScrollView || !refScrollView.current) return;
                      if (myConversation.messages.length > 0) {
                        refScrollView.current.scrollToEnd({ animated: true });
                      }
                    }}
                    onFocus={() => {
                      if (!refScrollView || !refScrollView.current) return;
                      if (myConversation.messages.length > 0) {
                        refScrollView.current.scrollToEnd({ animated: true });
                      }
                    }}
                    onBlur={() => {
                      if (!refScrollView || !refScrollView.current) return;
                      if (myConversation.messages.length > 0) {
                        refScrollView.current.scrollToEnd({ animated: true });
                      }
                    }}
                  />
                  <Pressable
                    disabled={sending}
                    style={[
                      styles.buttonSend,
                      { backgroundColor: currentClub?.inactiveIconColor ?? Colors.whiteCorporate },
                    ]}
                    onPress={async () => {
                      if (message) {
                        await saveMessage(message);
                        if (!refScrollView || !refScrollView.current) return;
                        if (myConversation.messages.length > 0) {
                          refScrollView.current.scrollToEnd({ animated: true });
                        }
                      }
                    }}>
                    <Icon size={25} name="paper-plane" color={currentClub?.mainColorBottom ?? Colors.blueCorporate} />
                  </Pressable>
                </View>
              </View>
            </View>
          </KeyboardAvoidingView>
        </GradientBackground>
      </View>

      <TakePhoto
        visible={showTakePhoto}
        onPhotos={(photos) => {
          setMessageImage(photos[0]);
          // uploadMessageMedia(photos[0], message);
          setShowTakePhoto(false);
        }}
        onCancel={() => {
          setShowTakePhoto(false);
        }}
      />

      {/* display images in modal window */}
      <MediaZoomViewer
        medias={messageImages}
        index={0}
        visible={showMessageImage}
        onClose={() => setShowMessageImage(false)}
      />

      <Loading loading={loading || uploading} />
    </View>
  );
};

export default Chatting;
