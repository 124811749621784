import ButtonBase from '@components/ButtonBase/ButtonBase';
import Colors from '@configs/colors';
import { gMaxWidth } from '@configs/styles';
import I18n from '@utils/i18n';
import * as WebBrowser from 'expo-web-browser';
import React from 'react';
import { Dimensions, Modal, Platform, Pressable, StyleSheet, Text, View } from 'react-native';

const { width: sw } = Dimensions.get('window');

const AlerteUI = ({
  showAlert = false,
  title = null,
  message = null,
  link = null,
  buttons = null,
  onClose = () => {},
}) => {
  const debug = false;

  if (!showAlert) {
    return;
  }

  debug && console.debug('AlerteUI, configuration:', showAlert, title, message);

  return (
    <>
      <Modal visible={showAlert} transparent style={[styles.mainContainer]}>
        <View style={[styles.mainContainer]}>
          <View style={[styles.centered, styles.shadowEffect]}>
            {title && <Text style={styles.pageTitle}>{title}</Text>}

            {message && link && (
              <Pressable
                onPress={() => {
                  WebBrowser.openBrowserAsync(link).then((result) => {
                    console.log(`Opened: ${link}`, result);
                  });
                }}>
                <Text style={[styles.popupMessageText, { textDecorationLine: 'underline' }]}>{message}</Text>
              </Pressable>
            )}

            {message && !link && <Text style={styles.pageText}>{message}</Text>}

            {buttons && buttons.length > 0 ? (
              <View style={styles.buttonsContainer}>
                {buttons.map((button, index) => {
                  return (
                    <ButtonBase
                      key={'AlertButton-' + index}
                      label={button.text ?? 'What?'}
                      onPress={() => {
                        button.onPress && button.onPress();
                        onClose && onClose();
                      }}
                    />
                  );
                })}
              </View>
            ) : (
              <View style={styles.buttonsContainer}>
                <ButtonBase
                  key="AlertButton-0"
                  label={I18n.t('app.close')}
                  onPress={() => {
                    onClose && onClose();
                  }}
                />
              </View>
            )}
          </View>
        </View>
      </Modal>
    </>
  );
};

// export default AlerteUI
const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    width: Platform.OS === 'web' ? gMaxWidth : sw,
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.black + '77',
  },
  centered: {
    backgroundColor: Colors.grayWhite,
    paddingVertical: 10,
    paddingHorizontal: 20,
    width: Platform.OS === 'web' ? gMaxWidth - 40 : sw - 40,
    borderRadius: 10,

    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  shadowEffect: {
    marginBottom: 15,

    overflow: 'visible',
    shadowColor: Colors.black + 'AA',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 6,
  },
  pageTitle: {
    // width: '80%',
    fontWeight: 'bold',
    fontSize: 20,
    color: Colors.blueCorporate,

    textAlign: 'center',

    marginVertical: 20,
  },
  pageText: {
    // width: '80%',
    fontWeight: 'bold',
    fontSize: 16,
    color: Colors.blueCorporate,

    textAlign: 'center',

    marginTop: 40,
    marginBottom: 20,
  },

  buttonsContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: sw / 1.2,
    marginVertical: 5,
    paddingHorizontal: 20,
    borderRadius: 10,
    // backgroundColor: Colors.blueSky,
  },
});

export default AlerteUI;
