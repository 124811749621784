import { AccountAPI } from '@apis/account';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import GradientBackground from '@components/GradientBackground/GradientBackground';
import Colors from '@configs/colors';
import { registerForPushNotificationsAsync } from '@configs/notification/initSystemNotification';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getAllNotifications, getClubComposition, getClubPartners, setAffiliatedClubs } from '@redux/actions';
import {
  SET_TOKENS,
  SET_SESSION,
  SET_SIGNED_IN,
  SET_USER_STATUS,
  RESET_FOR_LOGOUT,
  SET_CURRENT_PAGE,
} from '@redux/storeReducer';
import alert from '@utils/alert';
import I18n from '@utils/i18n';
import Constants from 'expo-constants';
import * as SplashScreen from 'expo-splash-screen';
import React, { useEffect, useState } from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';
import ProgressBar from 'react-native-progress/Bar';
import { useDispatch, useSelector } from 'react-redux';

const { width: sw, height: sh } = Dimensions.get('window');

const appSlug = Constants.expoConfig.slug || 'eappli';

export default function LocalCredentials({ navigation }) {
  const debug = false;
  const start = Date.now();

  // const [loading, setLoading] = useState(true);

  const [userToken, setUserToken] = useState(null);
  // const [refreshToken, setRefreshToken] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [userAcceptedTos, setUserAcceptedTos] = useState(false);

  // const [showAlert, setShowAlert] = useState(true);

  const { userStatus } = useSelector((state) => state.app);
  // const { notificationComeOutOfApplication: notificationFromOutsideApplication } = useSelector(
  //   (state) => state.notifications
  // );

  const dispatch = useDispatch();

  // Fetch the token from the storage and then store locally
  useEffect(() => {
    const bootstrapAsync = async () => {
      debug && console.debug(`LocalCredentials, user status: ${userStatus}, trying to find local credentials...`);

      // Check if some credentials are stored locally
      const tokens = JSON.parse(await AsyncStorage.getItem(`${appSlug}-tokens`));
      debug && console.debug('LocalCredentials, tokens:', tokens);
      if (!tokens) {
        await logOut();
      } else {
        // Store user tokens
        dispatch({ type: SET_TOKENS, value: tokens });
        // setRefreshToken(tokens.refreshToken);
        setUserToken(tokens.wsToken);
      }
      // TODO: amy be store locally those parameters, else get from the BO - to be defined!
      // // Get parameters that are stored locally
      // AsyncStorage.getItem(`${appSlug}-parameters`)
      //   .then((content) => {
      //     console.log('LocalCredentials, local parameters', content);
      //     dispatch({ type: SET_LOCAL_PARAMETERS, parameters: JSON.parse(content) ?? null });
      //     // dispatch({ type: SET_FAVORITES, parameters: JSON.parse(content) ?? null });
      //   })
      //   .catch((error) => {
      //     console.warn('LocalCredentials, local parameters', error);
      //     dispatch({ type: SET_LOCAL_PARAMETERS, parameters: JSON.stringify({ conversations: false }) });
      //     AsyncStorage.setItem(`${appSlug}-parameters`, JSON.stringify({ conversations: false }));
      //   });
    };

    bootstrapAsync();
  }, []);

  useEffect(() => {
    debug && console.debug(`LocalCredentials, user status: ${userStatus}`);
    if (userStatus === 're-signing-in') {
      SplashScreen.hideAsync();
    }
  }, [userStatus]);

  // Get user profile information for each new userToken
  useEffect(() => {
    const bootstrapAsync = async () => {
      if (userToken) {
        // Get user account with the former token
        getMyAccount();
      }
    };

    bootstrapAsync();
  }, [userToken]);

  // Get user profile information for each new userToken
  useEffect(() => {
    const bootstrapAsync = async () => {
      if (userAcceptedTos) {
        // User accepted agreements -> launch connected workflow
        await connectedWorkflow();
      }
    };

    bootstrapAsync();
  }, [userAcceptedTos]);

  const getMyClub = () => {
    const clubId = userProfile.club.id;
    debug && console.debug('LocalCredentials, getMyClub:', clubId);

    dispatch(getClubComposition(userToken, clubId));

    // Elipce/Fred: page and limit are useless, because ignored by the backend!
    dispatch(getClubPartners(userToken, clubId, 1, 5));
  };

  const getMyAccount = () => {
    debug && console.debug('LocalCredentials, getMyAccount', userToken);

    AccountAPI.getMyAccount(userToken).then((response) => {
      if (response && response.success) {
        debug && console.debug('LocalCredentials, user profile debug', response.debug);
        debug && console.debug('LocalCredentials, user profile:');
        response.result &&
          Object.keys(response.result).map((key, value) => {
            debug && console.debug(`- '${key}':`, response.result[key]);
          });

        setUserProfile(response.result);

        const user = response.result.user;

        // Check if user accepted agreements -> if not block and display modal
        if (user?.hasAcceptedAgreements !== '1') {
          dispatch({ type: SET_USER_STATUS, value: 'validating-tos' });
          console.warn('LocalCredentials, user has not accepted agreements user :', user?.email);
        } else {
          setUserAcceptedTos(user?.hasAcceptedAgreements === '1');
        }
      } else {
        console.warn('LocalCredentials, could not get user profile', response);

        // User is not signed-in
        dispatch({ type: SET_SIGNED_IN, value: false });
      }
    });
  };

  const validatingToS = () => {
    debug && console.debug('LocalCredentials, validating terms of service');

    AccountAPI.updateUserAcceptedAgreements(userToken).then((response) => {
      if (response && response.success) {
        setUserAcceptedTos(true);
      } else {
        // User not accepted or error -> logout
        alert(
          I18n.t('app.information'),
          'Erreur, veuillez vous reconnecter.',
          [
            {
              text: I18n.t('myAccount.ok'),
              onPress: () => {
                logOut();
              },
            },
          ],
          {
            cancelable: false,
          }
        );
      }
    });
  };

  const connectedWorkflow = async () => {
    debug && console.debug('LocalCredentials, connectedWorkflow');

    // Force set current page to be the Home page
    dispatch({ type: SET_CURRENT_PAGE, value: 'HomeStack' });

    // User has already logged-in, we already have the club information in the Redux store!
    // // 0. Get the user's club composition
    getMyClub();

    // 1. Register device push notification token server side
    try {
      debug && console.debug('LocalCredentials, Registering for push notifications...');
      await registerForPushNotificationsAsync(userToken);
      debug && console.debug('LocalCredentials, Registered');
    } catch (error) {
      console.warn('LocalCredentials, registerForPushNotificationsAsync, error:', error);
    }

    // 2. Get all notifications (news) and store in redux
    debug && console.debug(`LocalCredentials, getAllNotifications`);
    dispatch(getAllNotifications(userToken));

    try {
      // Store user profile
      dispatch({ type: SET_SESSION, value: userProfile });
      await AsyncStorage.setItem(`${appSlug}-session`, JSON.stringify(userProfile));

      const orderedClubs = userProfile.clubsAffiliated.sort((a, b) => a.name.localeCompare(b.name));
      dispatch(setAffiliatedClubs(orderedClubs, userProfile.club.id));

      if (userProfile && userProfile.club && userProfile.club.isAffiliated === '1') {
        debug && console.debug('LocalCredentials, user is a member of an affiliated club');

        // // Moved to the home page
        // if (notificationFromOutsideApplication) {
        //   // Elipce/Fred: check this!
        //   console.log('LocalCredentials, Got an outside notification', notificationFromOutsideApplication);
        //   const routeToNavigate = await handleNotificationOutApplication(
        //     navigation,
        //     notificationFromOutsideApplication,
        //     userProfile.clubsAffiliated,
        //     dispatch
        //   );
        //   console.log('LocalCredentials, where to go ?', routeToNavigate);
        //   if (routeToNavigate) {
        //     // Not possible to navigate in this function ... routers are not yet mounted!
        //     // dispatch(actions.resetNotificationReducer);
        //     routeToNavigate;
        //   }
        // }
      }

      const duration = Date.now() - start;
      setTimeout(() => {
        // User signed-in
        debug && console.debug('LocalCredentials, indicates user is signed-in');
        dispatch({ type: SET_SIGNED_IN, value: true });
        dispatch({ type: SET_USER_STATUS, value: 'signed-in' });
      }, Math.max(3000, duration));
    } catch (error) {
      console.warn('LocalCredentials, handleNotificationOutApplication, error:', error);
      dispatch({ type: SET_USER_STATUS, value: 'signing-in' });
    }
  };

  const logOut = async () => {
    debug && console.debug('LocalCredentials, user signing out...');

    dispatch({ type: SET_TOKENS, value: null });
    dispatch({ type: SET_SESSION, value: null });

    // Clear all the local storage
    await AsyncStorage.clear(() => {
      dispatch({ type: SET_SIGNED_IN, value: false });
      dispatch({ type: SET_USER_STATUS, value: 'signing-in' });
      dispatch({ type: RESET_FOR_LOGOUT });

      // navigation.navigate('SignIn');
    });
  };

  // Let the SignIn do the job...
  if (userStatus !== 're-signing-in') {
    return null;
  }

  return (
    <GradientBackground splash>
      <View style={[styles.rootContainer]}>
        <Text style={[styles.welcomeText]} />

        <ProgressBar
          width={sw - 280}
          height={24}
          color={Colors.blueCorporate}
          unfilledColor={Colors.whiteCorporate}
          borderWidth={0}
          indeterminate
          indeterminateAnimationDuration={1000}
        />
      </View>

      {userStatus === 'validating-tos' && (
        <AlerteUI
          showAlert
          link={Constants.expoConfig.extra.tosUrl}
          title={I18n.t('app.information')}
          message={"Veuillez accepter les conditions générales d'utilisation"}
          buttons={[
            {
              text: I18n.t('myAccount.ok'),
              onPress: () => {
                validatingToS();
              },
            },
            {
              text: I18n.t('myAccount.cancel'),
              onPress: async () => {
                await logOut();
              },
            },
          ]}
        />
      )}
    </GradientBackground>
  );
}

const styles = StyleSheet.create({
  rootContainer: {
    // flex: 1,
    marginTop: 10,
    alignItems: 'center',
  },

  imageContainer: {
    justifyContent: 'center',
    height: sh / 4,
    width: sw,
    marginTop: 10,
    padding: 10,
  },
  image: {
    width: '90%',
    height: '100%',
    resizeMode: 'contain',
    alignSelf: 'center',
  },

  welcomeText: {
    marginTop: sh / 2,
    width: sw - 100,
    fontFamily: 'appFontMedium',
    fontSize: 24,
    color: Colors.blueCorporate,
    marginHorizontal: 20,
    marginBottom: 30,
    textAlign: 'center',
  },

  buttonStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 60,
    width: sw - 120,
  },
});
