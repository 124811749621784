import Colors from '@configs/colors';
import { Dimensions, StyleSheet } from 'react-native';

const { width: sw, height: sh } = Dimensions.get('window');

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.gray + 'A5',
  },
  body: {
    paddingTop: sh * 0.035,
  },
  flatListeDocuments: {},
  textInput: {
    width: '100%',
    borderRadius: 100,
    backgroundColor: Colors.whiteCorporate,
    color: Colors.black,
    marginBottom: 20,
    height: 32,
    paddingLeft: 15,
    paddingRight: 15,
  },
  clubOverviewCtn: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 30,
  },
  clubOverviewTitle: {
    color: Colors.blueCorporate,
    fontFamily: 'appFontMedium',
    fontSize: 20,
    marginBottom: 20,
  },
  blueText: {
    color: Colors.blueCorporate + '99',
    fontFamily: 'appFontRegular',
    lineHeight: 16,
    fontSize: 11,
  },
  descriptionText: {
    color: Colors.blueCorporate + '99',
    fontFamily: 'appFontRegular',
    lineHeight: 16,
    fontSize: 16,
  },
  contactText: {
    color: Colors.blueCorporate + '99',
    fontFamily: 'appFontRegular',
    lineHeight: 16,
    fontSize: 15,
  },
  contactBlock: {
    marginTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
  },
  redTitleBlockCtn: {
    marginTop: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
  redTitle: {
    color: Colors.red,
    fontFamily: 'appFontMedium',
    fontSize: 16,
    marginBottom: 10,
  },
  clubGymCtn: {
    width: (sw - 85) / 2,
    // height: ((sw - 60) / 2) + 100,
    alignItems: 'center',
    marginRight: 10,
  },
  clubGymCtnShadow: {
    alignItems: 'center',
    padding: 10,
  },
  clubGymName: {
    fontFamily: 'appFontRegular',
    fontSize: 14,
  },
  imageCtn: {
    width: (sw - 120) / 2,
    height: (sw - 180) / 2,
  },
  clubGymThumbnail: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    borderRadius: 5,
  },
  clubGymBottomUpPanel: {
    marginLeft: 50,
    marginRight: 50,
    width: '90%',
    height: sw / 2,
    borderRadius: 5,
    resizeMode: 'contain',
    /*            marginLeft: "10%",
                        marginRight: "10%",
                        width: sw - (sw/10),
                        height: sh/4,*/
  },
  clubAdress: {
    color: Colors.blueCorporate + '99',
    fontFamily: 'appFontRegular',
    fontSize: 10,
    textAlign: 'center',
    marginTop: 5,
    height: 24,
  },
  personCtn: {
    width: '100%',
    marginTop: -10,
  },
  shadowBlockCtn: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 15,
    paddingTop: 5,
    paddingBottom: 5,
  },
  personAvatar: {
    width: 50,
    height: 50,
    borderRadius: 200,
    resizeMode: 'contain',
  },
  personInfoBlock: {
    marginLeft: 10,
  },
  personName: {
    fontSize: 14,
  },
  personJobType: {
    color: Colors.blueBgGradient1 + '55',
    fontSize: 10,
    marginLeft: 20,
    fontFamily: 'appFontRegular',
  },
  chatIcon: {
    width: 16,
    height: 16,
    resizeMode: 'contain',
  },
  chatButton: {
    width: 40,
    height: 40,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: -5,
  },

  actionButton: {
    width: 30,
    height: 30,
    borderRadius: 50,
    backgroundColor: Colors.whiteCorporate,
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 5,
    overflow: 'visible',
    shadowColor: Colors.iconGray + '77',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.8,
    shadowRadius: 7,
  },
  actionIcon: {
    tintColor: Colors.blueCorporate,
    width: 15,
    resizeMode: 'contain',
    opacity: 0.7,
  },
  block: {
    padding: 20,
    paddingTop: 0,
  },
  titleBlock: {
    color: Colors.blueCorporate,
    fontSize: 18,
    marginTop: 15,
  },
  mapStyle: {
    width: sw - 40,
    height: 228,
    borderRadius: 10,
    overflow: 'hidden',
  },
});

export default styles;
