import { DiariesAPI, DIARY_CONVOCATION, DIARY_EVENT, DIARY_MATCH, DIARY_TRAINING } from '@apis/diaries';
import { TeamAPI } from '@apis/team';
import AlerteUI from '@components/AlerteUI/AlerteUI';
import ButtonValidate from '@components/ButtonValidate/ButtonValidate';
import ChoosePlace from '@components/ChoosePlace/ChoosePlace';
import DateTimePicker from '@components/DateTimePicker/DateTimePicker';
import HeaderBanner from '@components/HeaderBanner/HeaderBanner';
import Loading from '@components/Loading/Loading';
import MediaZoomViewer from '@components/MediaZoomViewer/MediaZoomViewer';
import SelectEventType from '@components/SelectEventType/SelectEventType';
import SelectRecipientsType from '@components/SelectRecipientsType/SelectRecipientsType';
import ShadowCard from '@components/ShadowCard/ShadowCard';
import Colors from '@configs/colors';
import { gMaxWidth } from '@configs/styles';
import { Picker } from '@react-native-picker/picker';
import { addDiary } from '@redux/actions';
import alert from '@utils/alert';
import { checkIsModeratorOrAdmin } from '@utils/checkUserRoles';
import I18n from '@utils/i18n';
import moment from 'moment';
import React, { Component } from 'react';
import { Dimensions, Keyboard, Platform, Pressable, ScrollView, Text, TextInput, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/Ionicons';
import { connect } from 'react-redux';

import styles from './styles';

const { width: sw } = Dimensions.get('window');

// Diary type:
//     public const TRAINING_TYPE_ID = 1;
//     public const MATCH_TYPE_ID = 2;
//     public const EVENT_TYPE_ID = 3;
//     public const CONVOCATION_TYPE_ID = 4;
const EVT_TRAINING = Number(DIARY_TRAINING);
const EVT_MATCH = Number(DIARY_MATCH);
const EVT_EVENT = Number(DIARY_EVENT);
const EVT_CONVOCATION = Number(DIARY_CONVOCATION);

class CreateEvent extends Component {
  debug = false;

  // mode: add or edit
  mode = '';
  itemToEdit = null;
  itemToEditIndex = -1;

  page = 1;
  limit = 10;

  constructor(props) {
    super(props);

    this.mode = props.route.params.mode ?? 'add';
    this.itemToEdit = props.route.params.item ?? null;
    this.itemToEditIndex = props.route.params.index ?? -1;

    this.debug && console.debug('CreateEvent constructor, mode:', this.mode, this.itemToEdit);

    // const itemToEdit = props.itemToEdit;
    const eventTypeId = (this.itemToEdit && this.itemToEdit.EventType_id) ?? 1;
    const currentClub = this.props.affiliatedClubInformation ?? this.props.session.club ?? null;

    this.state = {
      currentClub,

      loading: false,
      hasPrivilegeAccess: checkIsModeratorOrAdmin(currentClub),

      showAlert: false,
      alertTitle: '',
      alertMessage: '',
      alertButtons: null,

      selectedEventType: props.constEventTypes[parseInt(eventTypeId, 10) - 1],

      title: this.mode === 'edit' ? this.itemToEdit.title : '',
      description: this.mode === 'edit' ? this.itemToEdit.description : '',

      latitude: this.mode === 'edit' ? this.itemToEdit.latitude : '',
      longitude: this.mode === 'edit' ? this.itemToEdit.longitude : '',
      address: this.mode === 'edit' ? this.itemToEdit.address : '',

      photos: this.mode === 'edit' ? this.itemToEdit.images : [],

      askReply: this.mode === 'edit' ? this.itemToEdit.askReply : 1,

      isRecurrent: this.mode === 'edit' ? parseInt(this.itemToEdit.isRecurrent, 10) : 0,

      // Match
      homeClubId: this.mode === 'edit' ? this.itemToEdit.homeClubId : '',
      homeClubName: this.mode === 'edit' ? (this.itemToEdit.homeClubName ? this.itemToEdit.homeClubName : '') : '',
      awayClubId: this.mode === 'edit' ? this.itemToEdit.awayClubId : '',
      awayClubName: this.mode === 'edit' ? (this.itemToEdit.awayClubName ? this.itemToEdit.awayClubName : '') : '',
      selectedHomeClub: null,
      homeClubKeyword: this.mode === 'edit' ? (this.itemToEdit.homeClubName ? this.itemToEdit.homeClubName : '') : '',

      selectedAwayClub: null,
      awayClubKeyword: this.mode === 'edit' ? (this.itemToEdit.awayClubName ? this.itemToEdit.awayClubName : '') : '',

      homeTeamId: this.mode === 'edit' ? this.itemToEdit.homeTeamId : '',
      homeTeamName: this.mode === 'edit' ? (this.itemToEdit.homeTeamName ? this.itemToEdit.homeTeamName : '') : '',
      awayTeamId: this.mode === 'edit' ? this.itemToEdit.awayTeamId : '',
      awayTeamName: this.mode === 'edit' ? (this.itemToEdit.awayTeamName ? this.itemToEdit.awayTeamName : '') : '',

      selectedHomeTeam: null,
      selectedAwayTeam: null,

      teamsFilter: this.props.teams ?? [],
      selectedTeams: [...this.props.teams],

      diariesRecipients: [],
      // Always no notifications
      sendNotification: 0,

      matchComment: '',

      showEventTypes: false,
      showRecipients: false,
      showTeamsFilter: false,
      showTakePhoto: false,
      showChoosePlace: false,

      isPhotoLimit: false,
      isSentForValidation: false,

      // Date / time
      date: this.mode === 'edit' ? this.itemToEdit.date : moment().format('L'),
      endDate: this.mode === 'edit' ? this.itemToEdit.recurrenceEndDate : moment().format('L'),
      time: this.mode === 'edit' ? this.itemToEdit.time : moment().format('LT'),

      showDatePicker: false,
      selectedDate:
        this.mode === 'edit'
          ? this.itemToEdit.date
            ? moment(this.itemToEdit.date, 'DD/MM/YYYY').toDate()
            : new Date()
          : new Date(),
      valueDate:
        this.mode === 'edit'
          ? this.itemToEdit.date
            ? moment(this.itemToEdit.date, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),

      showHourPicker: false,
      selectedTime:
        this.mode === 'edit'
          ? this.itemToEdit.time
            ? moment(this.itemToEdit.time, 'HH:mm').toDate()
            : new Date()
          : new Date(),
      valueTime:
        this.mode === 'edit'
          ? this.itemToEdit.time
            ? moment(this.itemToEdit.time, 'HH:mm').format('HH:mm')
            : moment().format('HH:mm')
          : moment().format('HH:mm'),

      showEndDatePicker: false,
      selectedEndDate:
        this.mode === 'edit'
          ? this.itemToEdit.recurrenceEndDate
            ? moment(this.itemToEdit.recurrenceEndDate, 'DD/MM/YYYY').toDate()
            : new Date()
          : new Date(),
      valueEndDate:
        this.mode === 'edit'
          ? this.itemToEdit.recurrenceEndDate
            ? moment(this.itemToEdit.recurrenceEndDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),

      buttonAddress: this.mode === 'edit' && this.itemToEdit.address ? this.itemToEdit.address.split('|')[0] : null,
      completeAddress:
        this.mode === 'edit' && this.itemToEdit.address
          ? {
              name: this.itemToEdit.address.split('|')[0],
              longitude: this.itemToEdit.longitude,
              latitude: this.itemToEdit.latitude,
              address: this.itemToEdit.address.split('|')[1],
            }
          : null,

      // Club/ team
      autoClubID: -1,

      showTeam1: true,
      showTeam2: true,
      showClub1: true,
      showClub2: true,

      team1Focused: false,
      team2Focused: false,
      club1Focused: false,
      club2Focused: false,

      allTeamsInHomeClub: [],
      allTeamsInAwayClub: [],

      allClubsForSearch: [],
      resultSearchHomeClub: [],
      resultSearchAwayClub: [],

      resultSearchHomeTeam: [],
      resultSearchAwayTeam: [],

      homeTeamNameSearched: '',
      awayClubNameSearched: '',
      homeClubNameSearched: '',
      awayTeamNameSearched: '',
    };

    this.debug && console.debug('CreateEvent constructor, state:', this.mode, this.state, this.props.route);
  }

  async componentDidMount() {
    this.debug && console.debug('CreateEvent componentDidMount');

    this.setState((currentState) => ({
      hasPrivilegeAccess: checkIsModeratorOrAdmin(currentState.currentClub),
    }));

    this.getClubsList();

    if (this.mode === 'edit') {
      if (this.state.awayClubId && this.state.awayClubId.trim() !== '') {
        this.getAllTeamsInClub(this.state.awayClubId, false);
      }
      if (this.state.homeClubId && this.state.homeClubId.trim() !== '') {
        this.getAllTeamsInClub(this.state.homeClubId, true);
      }
    }
  }

  // Fred: 4 functions duplicated from createActuality !
  getClubsList = () => {
    this.debug && console.debug('CreateEvent getClubsList');

    // Get all known clubs and prepend the current club and our favorites
    const allClubs = this.props.allClubs;
    let result = [{ id: this.state.currentClub.id, name: '⭐ ' + this.state.currentClub.name }];
    result = [
      ...result,
      ...this.props.favorites.clubs.map((club) => {
        return { id: club.id, name: '⭐ ' + club.name };
      }),
    ];
    result = [
      ...result,
      ...allClubs.filter((club) => {
        if (!result.find((aClub) => aClub.id === club.id)) {
          return { id: club.id, name: club.name };
        }
      }),
    ];

    this.setState({
      allClubsForSearch: result,
      resultSearchHomeClub: result,
      resultSearchAwayClub: result,
    });
  };

  searchClub = async (searchText, home = true) => {
    this.debug && console.debug('CreateEvent searchClub', home, searchedText, this.state.allClubsForSearch);

    // ES6 - remove diacritics -)
    const searchedText = searchText.normalize('NFD').replace(/\p{Diacritic}/gu, '');

    if (searchedText && searchedText.trim() !== '') {
      const filteredClubs = this.state.allClubsForSearch.filter((club) => {
        const clubName = club.name.normalize('NFD').replace(/\p{Diacritic}/gu, '');
        return clubName.toLowerCase().includes(searchedText.toLowerCase());
      });
      this.debug && console.debug('CreateEvent searchClub', filteredClubs);
      this.setState(home ? { resultSearchHomeClub: filteredClubs } : { resultSearchAwayClub: filteredClubs });
    } else {
      this.setState((currentState) =>
        home
          ? { resultSearchHomeClub: currentState.allClubsForSearch }
          : { resultSearchAwayClub: currentState.allClubsForSearch }
      );
    }
  };

  getAllTeamsInClub = (clubId, home = true) => {
    TeamAPI.getTeamsList(this.props.tokens.wsToken, clubId, '', 1, 10).then((response) => {
      if (response?.success === true) {
        this.setState(
          home
            ? {
                allTeamsInHomeClub: response.result.teams,
                resultSearchHomeTeam: response.result.teams,
              }
            : {
                allTeamsInAwayClub: response.result.teams,
                resultSearchAwayTeam: response.result.teams,
              }
        );
      }
    });
  };

  searchTeamsInClub = (text, home = true) => {
    if (text && text.trim !== '') {
      if (home) {
        const filteredTeams = this.state.allTeamsInHomeClub.filter((team) =>
          team.name.toLowerCase().includes(text.toLowerCase())
        );
        this.setState({ resultSearchHomeTeam: filteredTeams });
      } else {
        const filteredTeams = this.state.allTeamsInAwayClub.filter((team) =>
          team.name.toLowerCase().includes(text.toLowerCase())
        );
        this.setState({ resultSearchAwayTeam: filteredTeams });
      }
    } else {
      if (home) {
        this.setState({ resultSearchHomeTeam: this.state.allTeamsInHomeClub });
      } else {
        this.setState({
          resultSearchAwayTeam: this.state.allTeamsInAwayClub,
        });
      }
    }
  };

  selectTeamFilter = (choice) => {
    this.debug && console.debug('CreateEvent, selectTeamFilter:', choice);

    const selectedTeamIds = this.state.selectedTeams.map((item) => item.id);
    const selectedAllTeams = selectedTeamIds.length >= this.state.teamsFilter.length;

    let selectedTeams = [...this.state.selectedTeams];
    if (choice.id === 0) {
      selectedTeams = selectedAllTeams ? [] : [...this.state.teamsFilter];
    } else {
      const foundIndex = selectedTeams.findIndex((item) => item.id === choice.id);
      if (foundIndex >= 0) {
        selectedTeams.splice(foundIndex, 1);
      } else {
        selectedTeams.push(choice);
      }
    }

    this.debug && console.debug('CreateEvent, selectTeamFilter, selected:', selectedTeams);
    this.setState({ selectedTeams });
  };

  formatRecipientsName = () => {
    if (this.state.selectedTeams.length === this.props.teams.length) {
      return I18n.t('createEvent.recipientsAllTeams');
    }

    return this.state.selectedTeams.length > 0
      ? this.state.selectedTeams.map((team) => team.name).join(', ')
      : I18n.t('createEvent.recipientTeam');
  };
  getAutoComplete = async (text) => {
    if (text === undefined || text === null) {
      text = '';
    }
    const autoComplete = await DiariesAPI.getAutoComplete(text, this.page, this.limit);
    if (autoComplete?.success) {
      if (this.page > 1) {
        const prevList = [...this.state.autoCompletePredict];
        prevList.splice(prevList.length, 0, ...autoComplete.result.clubs);
        this.setState({ autoCompletePredict: prevList });
      } else {
        this.setState({ autoCompletePredict: autoComplete.result.clubs });
      }
    } else {
      this.setState({ autoCompletePredict: [] });
    }
  };

  addDiary = async () => {
    const selectedTeamIds = this.state.selectedTeams.map((team) => team.id);

    this.props.dispatch(
      addDiary(
        this.props.tokens.wsToken,
        this.state.currentClub.id,
        this.state.selectedEventType.id,
        {
          homeClubId: this.state.homeClubId,
          homeClubName: this.state.homeClubName,
          awayClubId: this.state.awayClubId,
          awayClubName: this.state.awayClubName,
          homeTeamId: this.state.homeTeamId,
          homeTeamName: this.state.homeTeamName,
          awayTeamId: this.state.awayTeamId,
          awayTeamName: this.state.awayTeamName,
          title: this.state.title,
          description: this.state.description,
          latitude: this.state.latitude,
          longitude: this.state.longitude,
          address: this.state.address,
          askReply: this.state.askReply,
          date: this.state.date,
          time: this.state.time,
          isRecurrent: this.state.isRecurrent,
          selectedTeamIds,
          sendNotification: this.state.sendNotification,
          endDate: this.state.endDate,
        },
        (success, response) => {
          if (success) {
            this.setState({
              loading: false,
              alertTitle: I18n.t('app.information'),
              alertMessage: 'Evénement enregistré',
              alertButtons: [
                {
                  text: 'Ok',
                  onPress: () => {
                    this.setState({
                      selectedEventType: null,
                      homeTeamName: '',
                      homeTeamId: '',
                      awayTeamName: '',
                      awayTeamId: '',
                      homeClubId: '',
                      homeClubName: '',
                      awayClubId: '',
                      awayClubName: '',
                      title: '',
                      description: '',
                      latitude: '',
                      longitude: '',
                      address: '',
                      askReply: 0,
                      date: '',
                      time: '',
                      isRecurrent: 0,
                      photos: [],
                      fullAddress: null,
                      addressCoordinates: null,
                      selectedTeams: [],
                      matchComment: '',

                      autoClubID: -1,
                    });
                    this.props.navigation.goBack();
                  },
                },
              ],
              showAlert: true,
            });
          } else {
            this.setState({
              loading: false,
              alertTitle: I18n.t('app.information'),
              alertMessage:
                response && response.error
                  ? response.error
                  : 'Une erreur est survenue.\n Veuillez réessayer plus tard.',
              alertButtons: [
                {
                  text: 'Ok',
                },
              ],
              showAlert: true,
            });
          }
        }
      )
    );
  };

  onAddingTraining = async () => {
    this.debug && console.debug('CreateEvent onAddingTraining, state:', this.state);

    if (this.state.selectedTeams.length <= 0) {
      alert(I18n.t('app.oups'), I18n.t('app.noRecipient'));
    } else if (this.state.title === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidTitle'));
      // } else if (this.state.description === '') {
      //   alert(I18n.t('app.oups'), I18n.t('app.invalidDescription'));
    } else if (this.state.address === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAddress'));
    } else if (this.state.address.length > 255) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAddressLength'));
    } else if (this.state.longitude === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidLongitude'));
    } else if (this.state.latitude === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidLatitude'));
    } else {
      await this.addDiary();
    }
    this.setState({ loading: false });
  };
  onAddingMatch = async () => {
    this.debug && console.debug('CreateEvent onAddingMatch, state:', this.state);

    if (this.state.selectedTeams.length <= 0) {
      alert(I18n.t('app.oups'), I18n.t('app.noRecipient'));
    } else if (this.state.homeTeamName === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidHomeTeam'));
    } else if (this.state.awayTeamName === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAwayTeam'));
    } else if (this.state.homeClubName === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidHomeClub'));
    } else if (this.state.awayClubName === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAwayClub'));
    } else if (this.state.address === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAddress'));
    } else if (this.state.address.length > 255) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAddressLength'));
    } else if (this.state.longitude === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidLongitude'));
    } else if (this.state.latitude === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidLatitude'));
    } else if (this.state.date === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidDate'));
    } else if (this.state.time === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidTime'));
    } else {
      await this.addDiary();
    }
    this.setState({ loading: false });
  };
  onAddingEvent = async () => {
    this.debug && console.debug('CreateEvent onAddingEvent, state:', this.state);

    if (this.state.selectedTeams.length <= 0) {
      alert(I18n.t('app.oups'), I18n.t('app.noRecipient'));
    } else if (this.state.title === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidTitle'));
      // } else if (this.state.description === '') {
      //     alert(I18n.t('app.oups'), I18n.t('app.invalidDescription'));
    } else if (this.state.address === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAddress'));
    } else if (this.state.address.length > 255) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAddressLength'));
    } else if (this.state.longitude === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidLongitude'));
    } else if (this.state.latitude === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidLatitude'));
    } else if (this.state.date === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidDate'));
    } else if (this.state.time === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidTime'));
    } else {
      await this.addDiary();
    }
    this.setState({ loading: false });
  };
  onAddDiary = async () => {
    if (!this.state.selectedEventType) {
      alert(I18n.t('app.oups'), I18n.t('app.noEventTypeSelected'));
      return;
    }

    this.setState({ loading: true });
    if (this.state.selectedEventType) {
      if (this.state.selectedEventType.id === EVT_TRAINING) {
        await this.onAddingTraining();
      } else if (this.state.selectedEventType.id === EVT_MATCH) {
        await this.onAddingMatch();
      } else if (this.state.selectedEventType.id === EVT_EVENT) {
        await this.onAddingEvent();
      }
    }
    this.setState({ loading: false });
  };

  // edit function are here
  editDiary = async () => {
    const editDiaryResponse = await DiariesAPI.editDiary(
      this.props.tokens.wsToken,
      this.itemToEdit.id,

      this.state.homeClubId,
      this.state.awayClubId,
      this.state.homeTeamId,
      this.state.homeTeamName,
      this.state.awayTeamId,
      this.state.awayTeamName,
      this.state.title,
      this.state.description,
      this.state.latitude,
      this.state.longitude,
      this.state.address,
      this.state.askReply,
      this.state.date,
      this.state.time,
      this.state.isRecurrent,
      this.state.sendNotification
    );

    if (editDiaryResponse && editDiaryResponse.success) {
      this.setState({
        loading: false,
        alertTitle: I18n.t('app.information'),
        alertMessage: editDiaryResponse.result,
        alertButtons: [
          {
            text: 'Ok',
            onPress: () => {
              if (this.props.updateDiary) {
                this.props.updateDiary();
              }

              this.setState({
                loading: false,
                selectedEventType: this.props.constEventTypes[0],

                homeTeamId: '',
                homeTeamName: '',
                awayTeamId: '',
                awayTeamName: '',
                homeClubId: '',
                awayClubId: '',

                title: '',
                description: '',
                latitude: '',
                longitude: '',
                address: '',
                photos: [],
                askReply: 1,
                date: '',
                time: '',

                fullAddress: null,
                addressCoordinates: null,

                isRecurrent: 0,
                selectedTeams: [],
                matchComment: '',

                autoClubID: -1,
              });

              this.props.navigation.goBack();
            },
          },
        ],
        showAlert: true,
      });
    } else {
      this.debug && console.debug('CreateEvent, editNews error:', editDiaryResponse);

      this.setState({
        loading: false,
        alertTitle: I18n.t('app.oups'),
        alertMessage: editDiaryResponse ? editDiaryResponse.error : ' Une erreur est survenue lors de la sauvegarde',
        alertButtons: [],
        showAlert: true,
      });
    }
    return editDiaryResponse;
  };
  onEditingTraining = async () => {
    this.debug && console.debug('CreateEvent onAddingTraining, state:', this.state);

    if (this.state.title === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidTitle'));
    } else if (this.state.description === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidDescription'));
    } else if (this.state.address === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAddress'));
    } else if (this.state.address.length > 255) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAddressLength'));
    } else if (this.state.longitude === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidLongitude'));
    } else if (this.state.latitude === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidLatitude'));
    } else {
      await this.editDiary();
    }
  };
  onEditingMatch = async () => {
    this.debug && console.debug('CreateEvent onEditingMatch, state:', this.state);

    if (this.state.homeTeamName === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidHomeTeam'));
    } else if (this.state.awayTeamName === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAwayTeam'));
    } else if (this.state.homeClubId === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidHomeClub'));
    } else if (this.state.awayClubId === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAwayClub'));
    } else if (this.state.address === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAddress'));
    } else if (this.state.address.length > 255) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAddressLength'));
    } else if (this.state.longitude === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidLongitude'));
    } else if (this.state.latitude === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidLatitude'));
    } else if (this.state.date === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidDate'));
    } else if (this.state.time === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidTime'));
    } else {
      await this.editDiary();
    }
  };
  onEditingEvent = async () => {
    this.debug && console.debug('CreateEvent onEditingEvent, state:', this.state);

    if (this.state.title === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidTitle'));
      // } else if (this.state.description === '') {
      //   alert(I18n.t('app.oups'), I18n.t('app.invalidDescription'));
    } else if (this.state.address === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAddress'));
    } else if (this.state.address.length > 255) {
      alert(I18n.t('app.oups'), I18n.t('app.invalidAddressLength'));
    } else if (this.state.longitude === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidLongitude'));
    } else if (this.state.latitude === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidLatitude'));
    } else if (this.state.date === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidDate'));
    } else if (this.state.time === '') {
      alert(I18n.t('app.oups'), I18n.t('app.invalidTime'));
    } else {
      await this.editDiary();
    }
  };
  onEditDiary = async () => {
    this.setState({ loading: true });

    if (this.state.selectedEventType) {
      if (this.state.selectedEventType.id === EVT_TRAINING) {
        this.onEditingTraining();
      } else if (this.state.selectedEventType.id === EVT_MATCH) {
        this.onEditingMatch();
      } else if (this.state.selectedEventType.id === EVT_EVENT) {
        this.onEditingEvent();
      }
    } else {
      alert(I18n.t('app.oups'), I18n.t('app.noEventTypeSelected'));
    }
  };

  isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }, paddingToBottom) => {
    // const paddingToBottom = 20;
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
  };

  renderSelection(old = false) {
    if (old) {
      return (
        <>
          <Pressable
            style={[styles.selectionButton]}
            onPress={() => {
              this.scrollView.scrollTo({ x: 0, y: 0 });
              if (this.mode === 'add') {
                this.setState({ showEventTypes: true });
              }
            }}>
            <Text style={[styles.appFontRegular, styles.redText]}>
              {this.state.selectedEventType ? this.state.selectedEventType.name : I18n.t('createEvent.eventTypes')}
            </Text>
          </Pressable>
        </>
      );
    }

    return (
      <>
        {/* Select event type... */}
        {Platform.OS === 'ios' && this.state.selectedEventType && (
          <Pressable style={styles.selectionButton} onPress={() => this.setState({ selectedEventType: null })}>
            <Text style={[styles.appFontRegular, styles.redText]}>
              {this.state.selectedEventType ? this.state.selectedEventType.name : I18n.t('createEvent.eventTypes')}
            </Text>
          </Pressable>
        )}
        {Platform.OS === 'ios' && !this.state.selectedTypeActuality && (
          <Picker
            mode="dropdown"
            style={styles.pickerEvents}
            prompt={"Choisir un type d'événement"}
            onFocus={() => this.setState({ pickerFocused: true })}
            onBlur={() => this.setState({ pickerFocused: true })}
            selectedValue={this.state.selectedEventType ? this.state.selectedEventType.id : undefined}
            onValueChange={(value) => {
              this.setState({
                selectedEventType: this.props.constEventTypes.find((item) => Number(item.id) === Number(value)),
              });
            }}>
            <Picker.Item value="" label={"Choisir un type d'événement"} enabled={!this.state.pickerFocused} />
            {this.props.constEventTypes.map((event, index) => {
              return <Picker.Item key={index} label={event.name} value={event.id} fontFamily="appFontRegular" />;
            })}
          </Picker>
        )}
        {Platform.OS !== 'ios' && (
          <Picker
            mode="dialog"
            style={styles.pickerEvents}
            prompt={"Choisir un type d'événement"}
            onFocus={() => this.setState({ pickerFocused: true })}
            onBlur={() => this.setState({ pickerFocused: true })}
            selectedValue={this.state.selectedEventType ? this.state.selectedEventType.id : undefined}
            onValueChange={(value) => {
              this.debug && console.debug('onValueChange', value);
              this.setState({
                selectedEventType: this.props.constEventTypes.find((item) => Number(item.id) === Number(value)),
              });
            }}>
            {this.props.constEventTypes.map((event, index) => {
              return <Picker.Item key={index} label={event.name} value={event.id} fontFamily="appFontRegular" />;
            })}
          </Picker>
        )}
      </>
    );
  }
  renderProposeReply() {
    return (
      <View style={[styles.replyButtonsContainer, { marginTop: 10 }]}>
        <Text style={[styles.appFontRegular]}>
          {I18n.t('createEvent.askReply') + '\n'}
          <Text
            style={{
              fontFamily: 'appFontRegular',
              fontSize: 12,
              color: Colors.blueCorporate + '66',
              opacity: 0.7,
            }}>
            {I18n.t('createActu.mayReply')}
          </Text>
        </Text>

        <Pressable
          style={[
            styles.responseButton,
            {
              backgroundColor: this.state.askReply === 1 ? Colors.blueCorporate : Colors.whiteCorporate,
            },
          ]}
          onPress={() => {
            this.setState({ askReply: 1 });
          }}>
          <Text
            style={[
              styles.appFontRegular,
              {
                opacity: 0.7,
                color: this.state.askReply === 1 ? Colors.white : Colors.blueCorporate,
              },
            ]}>
            {I18n.t('actuList.replyYes')}
          </Text>
        </Pressable>
        <Pressable
          style={[
            styles.responseButton,
            {
              backgroundColor: this.state.askReply === 0 ? Colors.blueCorporate : Colors.whiteCorporate,
            },
          ]}
          onPress={() => {
            this.setState({ askReply: 0 });
          }}>
          <Text
            style={[
              styles.appFontRegular,
              {
                opacity: 0.7,
                color: this.state.askReply === 0 ? Colors.white : Colors.blueCorporate,
              },
            ]}>
            {I18n.t('actuList.replyNo')}
          </Text>
        </Pressable>
      </View>
    );
  }
  renderRecipientsChoice(oneTeam = false) {
    this.debug && console.debug('CreateEvent, renderRecipientsChoice, state:', this.state);

    // if (oneTeam) {
    //   return (
    //     <>
    //       <Pressable
    //         style={styles.selectionButton}
    //         onPress={() => {
    //           if (this.mode === 'edit') {
    //           } else {
    //             this.setState({ showTeamsFilter: true });
    //           }
    //         }}>
    //         <Text style={[styles.appFontRegular, styles.redText]} numberOfLines={2}>
    //           {this.formatRecipientsName()}
    //         </Text>
    //       </Pressable>
    //     </>
    //   );
    // }

    return (
      <>
        <Pressable
          style={[styles.selectionButton, { marginTop: 20 }]}
          onPress={() => {
            this.setState({ showRecipients: true });
          }}>
          <Text style={[styles.appFontRegular, styles.redText]}>{this.formatRecipientsName()}</Text>
        </Pressable>
      </>
    );
  }

  renderAutoCompletion(club = true, home = true, title = '') {
    const searchList = club
      ? home
        ? this.state.resultSearchHomeClub
        : this.state.resultSearchAwayClub
      : home
      ? this.state.resultSearchHomeTeam
      : this.state.resultSearchAwayTeam;

    // Keyboard.dismiss();

    return (
      <ShadowCard overflowStyle={styles.autoCompleteBlock} style={[styles.shadowEffect]}>
        <View style={styles.autoCompleteHeader}>
          <Text style={styles.autoCompleteTitle}>{title}</Text>
          <Pressable
            style={styles.autoCompleteIcon}
            onPress={() => {
              this.setState({
                team1Focused: false,
                team2Focused: false,
                club1Focused: false,
                club2Focused: false,
              });
            }}>
            <Icon size={25} name="md-close" color={Colors.black} />
          </Pressable>
        </View>
        <FlatList
          data={searchList}
          keyExtractor={(item) => item.id}
          scrollEnabled
          nestedScrollEnabled
          renderItem={({ item, index }) => {
            return (
              <Pressable
                style={{ padding: 15, borderTopWidth: 0.5, borderTopColor: Colors.blueCorporate }}
                onPress={() => {
                  if (club) {
                    if (home) {
                      this.setState(
                        {
                          homeClubId: item.id,
                          homeClubNameSearched: item.name,
                          homeClubName: item.name,
                          team1Focused: true,
                          team2Focused: false,
                          club1Focused: false,
                          club2Focused: false,
                        },
                        () => this.getAllTeamsInClub(item.id)
                      );
                    } else {
                      this.setState(
                        {
                          awayClubId: item.id,
                          awayClubNameSearched: item.name,
                          awayClubName: item.name,
                          team1Focused: false,
                          team2Focused: true,
                          club1Focused: false,
                          club2Focused: false,
                        },
                        () => this.getAllTeamsInClub(item.id, false)
                      );
                    }
                  } else {
                    const foundIndex = this.state.teamsFilter.findIndex((team) => team.id === item.id);
                    if (foundIndex >= 0) {
                      const foundIndex2 = this.state.selectedTeams.findIndex((team) => team.id === item.id);
                      if (foundIndex2 < 0) {
                        this.setState((currentState) => ({
                          selectedTeams:
                            foundIndex >= 0 ? [...currentState.selectedTeams, this.state.teamsFilter[foundIndex]] : [],
                        }));
                      }
                    }

                    if (home) {
                      this.setState((currentState) => ({
                        homeTeamId: item.id,
                        homeTeamNameSearched: item.name,
                        homeTeamName: item.name,
                        team1Focused: false,
                        team2Focused: false,
                        club1Focused: false,
                        club2Focused: true,
                      }));
                    } else {
                      this.setState((currentState) => ({
                        awayTeamId: item.id,
                        awayTeamName: item.name,
                        awayTeamNameSearched: item.name,
                        team1Focused: false,
                        team2Focused: false,
                        club1Focused: false,
                        club2Focused: false,
                      }));
                    }
                  }
                }}>
                <Text style={[styles.appFontRegular]}>{item.clubName ?? item.name}</Text>
              </Pressable>
            );
          }}
        />
      </ShadowCard>
    );
  }

  renderClubTeam(home = true, score = true) {
    return (
      <>
        <View style={[styles.inputCtn]}>
          <View>
            <TextInput
              value={home ? this.state.homeClubNameSearched : this.state.awayClubNameSearched}
              placeholder={I18n.t(`createActu.${home ? 'home' : 'away'}Club`)}
              style={[styles.inputClubName, !score ? { width: sw - 40 } : {}]}
              placeholderTextColor={Colors.blueCorporate + '55'}
              onFocus={async () => {
                this.scrollView.scrollTo({ x: 0, y: 100 });
                this.setState({
                  autoClubID: home ? 1 : 2,
                  showTeam1: false,
                  club1Focused: home,
                  club2Focused: !home,
                  team1Focused: false,
                  team2Focused: false,
                });
              }}
              onChangeText={(text) => {
                this.setState(
                  home
                    ? { homeClubName: text, homeClubNameSearched: text }
                    : { awayClubName: text, awayClubNameSearched: text }
                );
                this.searchClub(text, home);
              }}
              onEndEditing={() => {
                if (home) {
                  this.setState({ showTeam1: true });
                } else {
                  this.setState({ showTeam2: true });
                }
              }}
            />

            <TextInput
              value={home ? this.state.homeTeamNameSearched : this.state.awayTeamNameSearched}
              placeholder={I18n.t(`createActu.${home ? 'home' : 'away'}Team`)}
              style={[
                styles.inputClubName,
                { width: !score ? sw - 40 : null },
                {
                  display: home
                    ? this.state.club1Focused && this.state.resultSearchHomeClub.length > 0
                      ? 'none'
                      : null
                    : this.state.club2Focused && this.state.resultSearchAwayClub.length > 0
                    ? 'none'
                    : 'flex',
                  marginTop: home
                    ? this.state.team1Focused && this.state.resultSearchHomeTeam.length > 0
                      ? 10
                      : 0
                    : this.state.team2Focused && this.state.resultSearchAwayTeam.length > 0
                    ? 10
                    : 0,
                },
              ]}
              placeholderTextColor={Colors.blueCorporate + '55'}
              onFocus={async () => {
                this.scrollView.scrollTo({ x: 0, y: 100 });

                this.setState({
                  autoClubID: home ? 1 : 2,
                  showTeam1: false,
                  club1Focused: false,
                  club2Focused: false,
                  team1Focused: home,
                  team2Focused: !home,
                });
              }}
              onChangeText={(text) => {
                if (home) {
                  this.setState({
                    homeTeamNameSearched: text,
                    homeTeamName: text,
                    homeTeamId: '',
                  });
                } else {
                  this.setState({
                    awayTeamNameSearched: text,
                    awayTeamName: text,
                    awayTeamId: '',
                  });
                }
                this.searchTeamsInClub(text, home);
              }}
            />
          </View>

          {score && (
            <View style={{ height: '100%', width: '100%', paddingTop: 10, paddingLeft: 21 }}>
              <TextInput
                value={home ? this.state.homeTeamScore : this.state.awayTeamScore}
                placeholder={I18n.t('createActu.score')}
                placeholderTextColor={Colors.blueCorporate + '55'}
                style={[
                  styles.inputScore,
                  {
                    color: Colors.blueCorporate,
                    fontFamily: 'appFontRegular',
                    display: home
                      ? (this.state.club1Focused && this.state.resultSearchHomeClub.length > 0) ||
                        (this.state.team1Focused && this.state.resultSearchHomeTeam.length > 0)
                        ? 'none'
                        : null
                      : (this.state.club2Focused && this.state.resultSearchAwayClub.length > 0) ||
                        (this.state.team2Focused && this.state.resultSearchAwayTeam.length > 0)
                      ? 'none'
                      : null,
                  },
                ]}
                onChangeText={(text) => {
                  this.setState(home ? { homeTeamScore: text } : { awayTeamScore: text });
                }}
                keyboardType="numeric"
                onFocus={async () => {
                  this.scrollView.scrollTo({ x: 0, y: 100 });
                  this.setState({ autoCompletePredict: [] });
                }}
              />
            </View>
          )}
        </View>
      </>
    );
  }

  render() {
    return (
      <>
        <HeaderBanner
          onLeftButton={() => {
            this.props.navigation.goBack();
          }}
          title={this.mode === 'edit' ? I18n.t('createEvent.editEvent') : I18n.t('createEvent.newEvent')}
        />

        <ScrollView
          scrollEventThrottle={0}
          ref={(ref) => {
            this.scrollView = ref;
          }}
          style={{ width: '100%' }}
          contentContainerStyle={{ paddingBottom: Platform.OS === 'android' ? 30 : 0 }}
          alwaysBounceVertical={false}
          bounces={false}>
          <View style={[styles.formContainer]}>
            {this.renderSelection(true)}

            {/* For a match only */}
            {this.state.selectedEventType && this.state.selectedEventType.id === EVT_MATCH && (
              <>
                {/* Home... */}
                <View style={{ minHeight: 32, width: Platform.OS === 'web' ? gMaxWidth : sw }}>
                  {/* auto complete club choice */}
                  {this.state.autoClubID === 1 &&
                    this.state.club1Focused === true &&
                    this.state.resultSearchHomeClub.length > 0 &&
                    this.renderAutoCompletion(true, true, 'Quel est le club qui reçoit à domicile ?')}

                  {/* auto complete team choice */}
                  {this.state.autoClubID === 1 &&
                    this.state.team1Focused === true &&
                    this.state.resultSearchHomeTeam.length > 0 &&
                    this.renderAutoCompletion(false, true, 'Quelle équipe dans le club qui reçoit ?')}

                  {/* Display home club/team */}
                  {this.renderClubTeam(true, false)}
                </View>

                {/* Away... */}
                <View style={{ minHeight: 32, width: Platform.OS === 'web' ? gMaxWidth : sw }}>
                  {this.state.autoClubID === 2 &&
                    this.state.club2Focused === true &&
                    this.state.resultSearchAwayClub.length > 0 &&
                    this.renderAutoCompletion(true, false, 'Quel est le club visiteurs ?')}

                  {/* autoComplete for team 2 */}
                  {this.state.autoClubID === 2 &&
                    this.state.team2Focused === true &&
                    this.state.resultSearchAwayTeam.length > 0 &&
                    this.renderAutoCompletion(false, false, 'Quelle équipe dans le club visiteurs ?')}

                  {/* Display away club/team */}
                  {this.renderClubTeam(false, false)}
                </View>
              </>
            )}

            <TextInput
              style={styles.textInput}
              value={this.state.title}
              placeholder={I18n.t('createActu.title')}
              placeholderTextColor={Colors.blueCorporate + '55'}
              onChangeText={(text) => {
                this.setState({ title: text });
              }}
            />
            <TextInput
              style={styles.textInputMultiline}
              value={this.state.description}
              placeholder={I18n.t('createActu.description')}
              placeholderTextColor={Colors.blueCorporate + '55'}
              multiline
              onChangeText={(text) => {
                this.setState({ description: text });
              }}
            />

            {/* Location */}
            <Pressable
              style={styles.selectionButton}
              onPress={() => {
                this.setState({ showChoosePlace: true });
              }}>
              <Text
                style={[styles.appFontRegular, styles.redText, { marginLeft: 5, marginRight: 5 }]}
                numberOfLines={1}
                ellipsizeMode="tail">
                {this.state.buttonAddress !== null ? this.state.buttonAddress : I18n.t('myAccount.address')}
              </Text>
            </Pressable>

            {/* Date and time */}
            {Platform.OS !== 'web' ? (
              <>
                <Pressable onPress={() => this.setState({ showDatePicker: true })} style={styles.dateTime}>
                  <Text
                    style={{
                      fontFamily: 'appFontRegular',
                      color:
                        this.state.date && this.state.date.trim() !== ''
                          ? Colors.blueCorporate
                          : Colors.blueCorporate + '55',
                    }}>
                    {this.state.date && this.state.date.trim() !== ''
                      ? this.state.date
                      : this.state.selectedEventType && this.state.selectedEventType.id === EVT_TRAINING
                      ? I18n.t('createActu.startDate')
                      : I18n.t('createActu.date')}
                  </Text>
                </Pressable>

                <DateTimePicker
                  mode="date"
                  visible={this.state.showDatePicker}
                  value={this.state.selectedDate}
                  onClose={() => {
                    this.setState({ showDatePicker: false });
                  }}
                  onChange={(selectedDate) => {
                    this.setState({ date: moment(selectedDate).format('DD/MM/YYYY'), selectedDate });
                  }}
                />
              </>
            ) : (
              <>
                <input
                  type="date"
                  style={styles.dateTime}
                  value={this.state.valueDate}
                  min={moment().format('YYYY-MM-DD')}
                  onInput={(event) => {
                    const selectedDate = event.target.value;
                    this.setState({
                      date: moment(selectedDate).format('DD/MM/YYYY'),
                      selectedDate,
                      valueDate: moment(selectedDate).format('YYYY-MM-DD'),
                    });
                  }}
                />
              </>
            )}

            {Platform.OS !== 'web' ? (
              <>
                <Pressable onPress={() => this.setState({ showHourPicker: true })} style={styles.dateTime}>
                  <Text
                    style={{
                      fontFamily: 'appFontRegular',
                      color: this.state.time ? Colors.blueCorporate : Colors.blueCorporate + '55',
                    }}>
                    {this.state.time && this.state.time.trim() !== '' ? this.state.time : I18n.t('createActu.hour')}
                  </Text>
                </Pressable>

                <DateTimePicker
                  mode="time"
                  visible={this.state.showHourPicker}
                  value={this.state.selectedTime}
                  onClose={() => {
                    this.setState({ showHourPicker: false });
                  }}
                  onChange={(selectedTime) => {
                    this.setState({ time: moment(selectedTime).format('LT'), selectedTime });
                  }}
                />
              </>
            ) : (
              <>
                <input
                  type="time"
                  style={styles.dateTime}
                  value={this.state.valueTime}
                  // step={5}
                  onInput={(event) => {
                    const selectedTime = event.target.value;
                    this.setState({
                      time: selectedTime,
                      selectedTime,
                      valueTime: selectedTime,
                    });
                  }}
                />
              </>
            )}

            {/* Training only! -  Recurrence */}
            {this.state.selectedEventType && this.state.selectedEventType.id === EVT_TRAINING && (
              <>
                <Pressable
                  style={({ pressed }) => [styles.replyButtonsContainer, pressed ? { opacity: 0.7 } : {}]}
                  onPress={() =>
                    this.setState((currentState) => ({ isRecurrent: currentState.isRecurrent === 0 ? 1 : 0 }))
                  }>
                  <Icon
                    size={25}
                    color={Colors.black}
                    name={this.state.isRecurrent === 1 ? 'checkbox-outline' : 'square-outline'}
                  />
                  <Text style={{ color: Colors.blueCorporate, fontFamily: 'appFontRegular' }}>
                    {I18n.t('createActu.everyWeek')}
                  </Text>
                </Pressable>
                {this.state.isRecurrent > 0 && Platform.OS !== 'web' && (
                  <>
                    <Text style={[styles.appFontRegular, styles.blueText]}>{I18n.t('createActu.endDate')}</Text>
                    <Pressable
                      onPress={() => {
                        this.scrollView.scrollToEnd({ animated: true });
                        this.setState({ showEndDatePicker: true });
                        Keyboard.dismiss();
                      }}
                      style={styles.dateTime}>
                      <Text
                        style={{
                          fontFamily: 'appFontRegular',
                          color:
                            this.state.endDate && this.state.endDate.trim() !== ''
                              ? Colors.blueCorporate
                              : Colors.blueCorporate + '55',
                        }}>
                        {this.state.endDate && this.state.endDate.trim() !== ''
                          ? this.state.endDate
                          : I18n.t('createActu.endDate')}
                      </Text>
                    </Pressable>

                    <DateTimePicker
                      mode="date"
                      visible={this.state.showEndDatePicker}
                      value={this.state.selectedEndDate}
                      onClose={() => {
                        this.setState({ showEndDatePicker: false });
                      }}
                      onChange={(selectedEndDate) => {
                        this.setState({ endDate: moment(selectedEndDate).format('DD/MM/YYYY'), selectedEndDate });
                      }}
                    />
                  </>
                )}
                {this.state.isRecurrent > 0 && Platform.OS === 'web' && (
                  <>
                    <input
                      type="date"
                      style={styles.dateTime}
                      value={this.state.valueDate}
                      min={moment().format('YYYY-MM-DD')}
                      onInput={(event) => {
                        const selectedDate = event.target.value;
                        this.setState({
                          date: moment(selectedDate).format('DD/MM/YYYY'),
                          selectedDate,
                          valueDate: moment(selectedDate).format('YYYY-MM-DD'),
                        });
                      }}
                    />
                  </>
                )}
              </>
            )}

            {/* Recipients - which team(s) is concerned? */}
            {this.renderRecipientsChoice()}

            {/* For all events types... */}
            {this.state.selectedEventType && (
              <>
                {/* Add or update */}
                <ButtonValidate
                  style={{ width: sw - 200, minWidth: 120 }}
                  label={this.mode === 'add' ? I18n.t('createActu.create') : I18n.t('app.saveEdit')}
                  onPress={async () => {
                    if (this.mode === 'add') {
                      await this.onAddDiary();
                    } else {
                      await this.onEditDiary();
                    }
                  }}
                />
              </>
            )}
          </View>
          <View style={{ height: 60 }} />
        </ScrollView>

        <Loading loading={this.state.loading} />

        <SelectEventType
          visible={this.state.showEventTypes}
          allSelectable={false}
          onSelected={(choice) => {
            this.setState({ selectedEventType: choice });
            this.setState({ showEventTypes: false });
          }}
          onClose={() => this.setState({ showEventTypes: false })}
        />

        <SelectRecipientsType
          visible={this.state.showTeamsFilter}
          recipients={this.state.teamsFilter}
          selectable
          title="Choisissez la ou les équipes concernées"
          allSelectable={false}
          multiSelection
          selected={this.state.selectedTeams}
          onSelected={(choice) => this.selectTeamFilter(choice)}
          onClose={() => this.setState({ showTeamsFilter: false })}
        />

        <SelectRecipientsType
          visible={this.state.showRecipients}
          recipients={this.state.teamsFilter}
          selectable
          allSelectable
          title="Choisissez la ou les équipes concernées"
          allSelectableLabel="Toutes les équipes du club"
          multiSelection
          selected={this.state.selectedTeams}
          onSelected={(choice) => this.selectTeamFilter(choice)}
          onClose={() => this.setState({ showRecipients: false })}
        />

        <ChoosePlace
          visible={this.state.showChoosePlace}
          fullAddress={this.state.completeAddress}
          setAddress={(address) => {
            this.debug && console.debug('CreateEvent place chosen', address);
            this.setState({
              fullAddress: address,
              buttonAddress: address.name,
              longitude: address.longitude,
              latitude: address.latitude,
              address: `${address.name}|${address.description}`,
            });
          }}
          onClose={(address) => {
            if (address) {
              this.debug && console.debug('CreateEvent place chosen', address);
              this.setState({
                fullAddress: address,
                buttonAddress: address.name,
                longitude: address.longitude,
                latitude: address.latitude,
                address: `${address.name}|${address.description}`,
              });
            }
            this.setState({ showChoosePlace: false });
          }}
        />

        <MediaZoomViewer
          medias={this.state.photos.filter((photo) => photo.type === 'image')}
          index={0}
          visible={this.state.showImageViewer}
          onClose={() => {
            this.setState({ showImageViewer: false });
          }}
        />

        <AlerteUI
          showAlert={this.state.showAlert}
          onClose={() => {
            this.setState({ showAlert: false });
          }}
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          buttons={this.state.alertButtons}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { tokens, constEventTypes } = state.app;
  const { session, affiliatedClubInformation, favorites } = state.session;
  // Get current club members
  const { allClubs, teams } = state.club;
  return {
    tokens,
    session,
    constEventTypes,
    affiliatedClubInformation,
    favorites,
    allClubs,
    teams: teams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);
