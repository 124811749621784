import { getApiHost } from '@configs/host';

import { Request } from './Request';
import { URLs } from './apisConstants';

// From API:
export const PROFILE_VALIDATED_TYPE = 1;
export const LICENSE_TO_RENEW_TYPE = 2;
export const NEW_ACTUALITY_TYPE = 3;
export const ACTUALITY_LIKE_TYPE = 4;
export const ACTUALITY_COMMENT_TYPE = 5;
export const NEW_CONVOCATION_TYPE = 6;
export const CONVOCATION_ANSWER_TYPE = 7;
export const NEW_MESSAGE_TYPE = 8;
export const NEW_DOCUMENT_TYPE = 9;
export const NEW_EVENT_TYPE = 10;
export const ACTUALITY_ANSWER_TYPE = 11;
export const PUSH_CAMPAIGN = 12;
export const DELETE_MESSAGE_TYPE = 13;
export const NEED_VALIDATION_ACTUALITY = 14;
export const NEED_VALIDATION_DIARY = 15;
export const NEED_RELAY_ACTUALITY = 16;
export const NEW_USER_IN_TEAM = 17;
export const NEW_CONVERSATION_TYPE = 18;

export const NotificationAPI = {
  getAllNotifications: (wsToken) => {
    return Request.get(getApiHost() + URLs.getAllNotifications + '?wsToken=' + wsToken);
  },
  deleteAllNotifications: (wsToken) => {
    return Request.post(getApiHost() + URLs.deleteNotification, {
      wsToken,
    });
  },
  deleteNotification: (wsToken, id) => {
    return Request.post(getApiHost() + URLs.deleteNotification, {
      wsToken,
      id,
    });
  },
  // Never used
  // registerDevice: (wsToken, deviceUuid, pushToken) => {
  //   return Request.post(getApiHost() + URLs.registerDevice, {
  //     wsToken,
  //     deviceUuid,
  //     pushToken,
  //   });
  // },
  // updateExpoToken: (deviceUuid, pushToken) => {
  //   return Request.post(getApiHost() + URLs.updateExpoToken, {
  //     deviceUuid,
  //     pushToken,
  //   });
  // },
};
