import { getApiHost } from '@configs/host';

import { Request } from './Request';
import { URLs } from './apisConstants';

export const ConfigAPI = {
  getDefaultConfig: (language = 'fr') => {
    const url = `${getApiHost()}${URLs.getConfig}?language=${language}`;
    return Request.get(url);
  },
};
