import ChoosePlace from '@components/ChoosePlace/ChoosePlace';
import CreateEvent from '@containers/AgendaStack/CreateEvent/CreateEvent';
import Agenda from '@containers/TabAgenda';
import ModalNotifications from '@navigation/modals/ModalNotifications';
import NotAffiliated from '@navigation/modals/NotAffiliated';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import i18n from '@utils/i18n';
import React from 'react';

const Stack = createNativeStackNavigator();

const AgendaStack = () => {
  return (
    <Stack.Navigator
      id="AgendaStack"
      initialRouteName="Agenda"
      screenOptions={{ headerShown: false, title: i18n.t('pageTitle.agenda') }}>
      <Stack.Group>
        <Stack.Screen name="Agenda" component={Agenda} />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="NotificationsList" component={ModalNotifications} />
        <Stack.Screen name="NotAffiliated" component={NotAffiliated} />
      </Stack.Group>

      <Stack.Group>
        <Stack.Screen name="CreateEvent" component={CreateEvent} />
        <Stack.Screen name="ChoosePlace" component={ChoosePlace} />
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default AgendaStack;
